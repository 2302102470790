import { getContext, put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';

import { SERVICE_NAMES, FC_REQUESTS } from 'constants/index';
import * as actions from 'store/actions';

// WATCHER
export function* externalAuthTokenSaga() {
    yield takeLatest(actions.getExternalAuthTokenList, getExternalAuthTokenList);
    yield takeLatest(actions.createExternalAuthToken, createExternalAuthToken);
    yield takeLatest(actions.updateExternalAuthToken, updateExternalAuthToken);
    yield takeLatest(actions.deleteExternalAuthToken, deleteExternalAuthToken);
}

function* getExternalAuthTokenList({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const res = yield fastCityService.request(FC_REQUESTS.GET_EXTERNAL_AUTH_TOKEN_LIST, {
            companyId: payload,
            withDeleted: false
        });

        const externalAuthTokens = get(res, 'body.externalAuthTokens', []);

        yield put(actions.getExternalAuthTokenListSuccess(externalAuthTokens));
    } catch (err) {
        console.error(err);
        yield put(actions.getExternalAuthTokenListFailure());
    }
}

function* createExternalAuthToken({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const res = yield fastCityService.request(FC_REQUESTS.CREATE_EXTERNAL_AUTH_TOKEN, payload);

        const externalAuthToken = get(res, 'body', {});

        yield put(actions.createExternalAuthTokenSuccess(externalAuthToken));
    } catch (err) {
        console.error(err);
        yield put(actions.createExternalAuthTokenFailure());
    }
}

function* updateExternalAuthToken({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const res = yield fastCityService.request(FC_REQUESTS.UPDATE_EXTERNAL_AUTH_TOKEN, payload);

        const externalAuthToken = get(res, 'body', {});

        yield put(actions.updateExternalAuthTokenSuccess(externalAuthToken));
    } catch (err) {
        console.error(err);
        yield put(actions.updateExternalAuthTokenFailure());
    }
}

function* deleteExternalAuthToken({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const res = yield fastCityService.request(FC_REQUESTS.DELETE_EXTERNAL_AUTH_TOKEN, payload);

        const externalAuthTokenId = get(res, 'body.externalAuthTokenId', '');

        yield put(actions.deleteExternalAuthTokenSuccess(externalAuthTokenId));
    } catch (err) {
        console.error(err);
        yield put(actions.deleteExternalAuthTokenFailure());
    }
}
