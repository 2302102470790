import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import SuperAdminGuard from 'utils/route-guard/SuperAdminGuard';
import { routes } from 'constants/index';

// sample page routing
const CompanyList = Loadable(lazy(() => import('views/pages/CompanyList')));
const CompanyAdd = Loadable(lazy(() => import('views/pages/CompanyAdd')));
const Company = Loadable(lazy(() => import('views/pages/Company')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: routes.admin,
    element: (
        <NavMotion>
            <AuthGuard>
                <SuperAdminGuard>
                    <MainLayout />
                </SuperAdminGuard>
            </AuthGuard>
        </NavMotion>
    ),
    children: [
        {
            path: routes.adminCompanies,
            element: <CompanyList />
        },
        {
            path: routes.adminCompaniesAdd,
            element: <CompanyAdd />
        },
        {
            path: routes.adminCompaniesDetails,
            element: <Company />
        }
    ]
};

export default MainRoutes;
