/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.fastcity = (function() {

    /**
     * Namespace fastcity.
     * @exports fastcity
     * @namespace
     */
    var fastcity = {};

    fastcity.admin = (function() {

        /**
         * Namespace admin.
         * @memberof fastcity
         * @namespace
         */
        var admin = {};

        admin.Activate = (function() {

            /**
             * Properties of an Activate.
             * @memberof fastcity.admin
             * @interface IActivate
             * @property {string|null} [activationId] Activate activationId
             */

            /**
             * Constructs a new Activate.
             * @memberof fastcity.admin
             * @classdesc Represents an Activate.
             * @implements IActivate
             * @constructor
             * @param {fastcity.admin.IActivate=} [properties] Properties to set
             */
            function Activate(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Activate activationId.
             * @member {string} activationId
             * @memberof fastcity.admin.Activate
             * @instance
             */
            Activate.prototype.activationId = "";

            /**
             * Creates a new Activate instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.Activate
             * @static
             * @param {fastcity.admin.IActivate=} [properties] Properties to set
             * @returns {fastcity.admin.Activate} Activate instance
             */
            Activate.create = function create(properties) {
                return new Activate(properties);
            };

            /**
             * Encodes the specified Activate message. Does not implicitly {@link fastcity.admin.Activate.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.Activate
             * @static
             * @param {fastcity.admin.IActivate} message Activate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Activate.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.activationId != null && Object.hasOwnProperty.call(message, "activationId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.activationId);
                return writer;
            };

            /**
             * Decodes an Activate message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.Activate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.Activate} Activate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Activate.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.Activate();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.activationId = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an Activate message.
             * @function verify
             * @memberof fastcity.admin.Activate
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Activate.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.activationId != null && message.hasOwnProperty("activationId"))
                    if (!$util.isString(message.activationId))
                        return "activationId: string expected";
                return null;
            };

            /**
             * Creates an Activate message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.Activate
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.Activate} Activate
             */
            Activate.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.Activate)
                    return object;
                var message = new $root.fastcity.admin.Activate();
                if (object.activationId != null)
                    message.activationId = String(object.activationId);
                return message;
            };

            /**
             * Creates a plain object from an Activate message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.Activate
             * @static
             * @param {fastcity.admin.Activate} message Activate
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Activate.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.activationId = "";
                if (message.activationId != null && message.hasOwnProperty("activationId"))
                    object.activationId = message.activationId;
                return object;
            };

            /**
             * Converts this Activate to JSON.
             * @function toJSON
             * @memberof fastcity.admin.Activate
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Activate.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Activate;
        })();

        admin.Admin = (function() {

            /**
             * Properties of an Admin.
             * @memberof fastcity.admin
             * @interface IAdmin
             * @property {string|null} [login] Admin login
             * @property {number|Long|null} [companyId] Admin companyId
             * @property {string|null} [name] Admin name
             * @property {boolean|null} [isDeleted] Admin isDeleted
             * @property {Array.<string>|null} [externalSubCompanyId] Admin externalSubCompanyId
             * @property {string|null} [email] Admin email
             * @property {fastcity.common.Company.MapType|null} [mapType] Admin mapType
             * @property {Array.<fastcity.common.UserRole>|null} [roles] Admin roles
             */

            /**
             * Constructs a new Admin.
             * @memberof fastcity.admin
             * @classdesc Represents an Admin.
             * @implements IAdmin
             * @constructor
             * @param {fastcity.admin.IAdmin=} [properties] Properties to set
             */
            function Admin(properties) {
                this.externalSubCompanyId = [];
                this.roles = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Admin login.
             * @member {string} login
             * @memberof fastcity.admin.Admin
             * @instance
             */
            Admin.prototype.login = "";

            /**
             * Admin companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.Admin
             * @instance
             */
            Admin.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Admin name.
             * @member {string} name
             * @memberof fastcity.admin.Admin
             * @instance
             */
            Admin.prototype.name = "";

            /**
             * Admin isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.Admin
             * @instance
             */
            Admin.prototype.isDeleted = false;

            /**
             * Admin externalSubCompanyId.
             * @member {Array.<string>} externalSubCompanyId
             * @memberof fastcity.admin.Admin
             * @instance
             */
            Admin.prototype.externalSubCompanyId = $util.emptyArray;

            /**
             * Admin email.
             * @member {string} email
             * @memberof fastcity.admin.Admin
             * @instance
             */
            Admin.prototype.email = "";

            /**
             * Admin mapType.
             * @member {fastcity.common.Company.MapType} mapType
             * @memberof fastcity.admin.Admin
             * @instance
             */
            Admin.prototype.mapType = 0;

            /**
             * Admin roles.
             * @member {Array.<fastcity.common.UserRole>} roles
             * @memberof fastcity.admin.Admin
             * @instance
             */
            Admin.prototype.roles = $util.emptyArray;

            /**
             * Creates a new Admin instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.Admin
             * @static
             * @param {fastcity.admin.IAdmin=} [properties] Properties to set
             * @returns {fastcity.admin.Admin} Admin instance
             */
            Admin.create = function create(properties) {
                return new Admin(properties);
            };

            /**
             * Encodes the specified Admin message. Does not implicitly {@link fastcity.admin.Admin.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.Admin
             * @static
             * @param {fastcity.admin.IAdmin} message Admin message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Admin.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.login != null && Object.hasOwnProperty.call(message, "login"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.login);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
                if (message.externalSubCompanyId != null && message.externalSubCompanyId.length)
                    for (var i = 0; i < message.externalSubCompanyId.length; ++i)
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.externalSubCompanyId[i]);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.email);
                if (message.mapType != null && Object.hasOwnProperty.call(message, "mapType"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.mapType);
                if (message.roles != null && message.roles.length) {
                    writer.uint32(/* id 8, wireType 2 =*/66).fork();
                    for (var i = 0; i < message.roles.length; ++i)
                        writer.int32(message.roles[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes an Admin message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.Admin
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.Admin} Admin
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Admin.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.Admin();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.login = reader.string();
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        message.name = reader.string();
                        break;
                    case 4:
                        message.isDeleted = reader.bool();
                        break;
                    case 5:
                        if (!(message.externalSubCompanyId && message.externalSubCompanyId.length))
                            message.externalSubCompanyId = [];
                        message.externalSubCompanyId.push(reader.string());
                        break;
                    case 6:
                        message.email = reader.string();
                        break;
                    case 7:
                        message.mapType = reader.int32();
                        break;
                    case 8:
                        if (!(message.roles && message.roles.length))
                            message.roles = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.roles.push(reader.int32());
                        } else
                            message.roles.push(reader.int32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an Admin message.
             * @function verify
             * @memberof fastcity.admin.Admin
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Admin.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.login != null && message.hasOwnProperty("login"))
                    if (!$util.isString(message.login))
                        return "login: string expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId")) {
                    if (!Array.isArray(message.externalSubCompanyId))
                        return "externalSubCompanyId: array expected";
                    for (var i = 0; i < message.externalSubCompanyId.length; ++i)
                        if (!$util.isString(message.externalSubCompanyId[i]))
                            return "externalSubCompanyId: string[] expected";
                }
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.mapType != null && message.hasOwnProperty("mapType"))
                    switch (message.mapType) {
                    default:
                        return "mapType: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.roles != null && message.hasOwnProperty("roles")) {
                    if (!Array.isArray(message.roles))
                        return "roles: array expected";
                    for (var i = 0; i < message.roles.length; ++i)
                        switch (message.roles[i]) {
                        default:
                            return "roles: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                }
                return null;
            };

            /**
             * Creates an Admin message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.Admin
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.Admin} Admin
             */
            Admin.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.Admin)
                    return object;
                var message = new $root.fastcity.admin.Admin();
                if (object.login != null)
                    message.login = String(object.login);
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                if (object.externalSubCompanyId) {
                    if (!Array.isArray(object.externalSubCompanyId))
                        throw TypeError(".fastcity.admin.Admin.externalSubCompanyId: array expected");
                    message.externalSubCompanyId = [];
                    for (var i = 0; i < object.externalSubCompanyId.length; ++i)
                        message.externalSubCompanyId[i] = String(object.externalSubCompanyId[i]);
                }
                if (object.email != null)
                    message.email = String(object.email);
                switch (object.mapType) {
                case "GOOGLE":
                case 0:
                    message.mapType = 0;
                    break;
                case "YANDEX":
                case 1:
                    message.mapType = 1;
                    break;
                }
                if (object.roles) {
                    if (!Array.isArray(object.roles))
                        throw TypeError(".fastcity.admin.Admin.roles: array expected");
                    message.roles = [];
                    for (var i = 0; i < object.roles.length; ++i)
                        switch (object.roles[i]) {
                        default:
                        case "GLOBAL_ADMIN":
                        case 0:
                            message.roles[i] = 0;
                            break;
                        case "SUPPORT":
                        case 1:
                            message.roles[i] = 1;
                            break;
                        case "COMPANY_ADMIN":
                        case 2:
                            message.roles[i] = 2;
                            break;
                        case "COMPANY_MANAGER":
                        case 3:
                            message.roles[i] = 3;
                            break;
                        case "COMPANY_COURIER":
                        case 4:
                            message.roles[i] = 4;
                            break;
                        }
                }
                return message;
            };

            /**
             * Creates a plain object from an Admin message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.Admin
             * @static
             * @param {fastcity.admin.Admin} message Admin
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Admin.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.externalSubCompanyId = [];
                    object.roles = [];
                }
                if (options.defaults) {
                    object.login = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.isDeleted = false;
                    object.email = "";
                    object.mapType = options.enums === String ? "GOOGLE" : 0;
                }
                if (message.login != null && message.hasOwnProperty("login"))
                    object.login = message.login;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                if (message.externalSubCompanyId && message.externalSubCompanyId.length) {
                    object.externalSubCompanyId = [];
                    for (var j = 0; j < message.externalSubCompanyId.length; ++j)
                        object.externalSubCompanyId[j] = message.externalSubCompanyId[j];
                }
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.mapType != null && message.hasOwnProperty("mapType"))
                    object.mapType = options.enums === String ? $root.fastcity.common.Company.MapType[message.mapType] : message.mapType;
                if (message.roles && message.roles.length) {
                    object.roles = [];
                    for (var j = 0; j < message.roles.length; ++j)
                        object.roles[j] = options.enums === String ? $root.fastcity.common.UserRole[message.roles[j]] : message.roles[j];
                }
                return object;
            };

            /**
             * Converts this Admin to JSON.
             * @function toJSON
             * @memberof fastcity.admin.Admin
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Admin.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Admin;
        })();

        admin.GetAdminList = (function() {

            /**
             * Properties of a GetAdminList.
             * @memberof fastcity.admin
             * @interface IGetAdminList
             * @property {number|Long|null} [companyId] GetAdminList companyId
             * @property {Array.<string>|null} [login] GetAdminList login
             * @property {boolean|null} [withDeleted] GetAdminList withDeleted
             */

            /**
             * Constructs a new GetAdminList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetAdminList.
             * @implements IGetAdminList
             * @constructor
             * @param {fastcity.admin.IGetAdminList=} [properties] Properties to set
             */
            function GetAdminList(properties) {
                this.login = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetAdminList companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.GetAdminList
             * @instance
             */
            GetAdminList.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetAdminList login.
             * @member {Array.<string>} login
             * @memberof fastcity.admin.GetAdminList
             * @instance
             */
            GetAdminList.prototype.login = $util.emptyArray;

            /**
             * GetAdminList withDeleted.
             * @member {boolean} withDeleted
             * @memberof fastcity.admin.GetAdminList
             * @instance
             */
            GetAdminList.prototype.withDeleted = false;

            /**
             * Creates a new GetAdminList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetAdminList
             * @static
             * @param {fastcity.admin.IGetAdminList=} [properties] Properties to set
             * @returns {fastcity.admin.GetAdminList} GetAdminList instance
             */
            GetAdminList.create = function create(properties) {
                return new GetAdminList(properties);
            };

            /**
             * Encodes the specified GetAdminList message. Does not implicitly {@link fastcity.admin.GetAdminList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetAdminList
             * @static
             * @param {fastcity.admin.IGetAdminList} message GetAdminList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetAdminList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.login != null && message.login.length)
                    for (var i = 0; i < message.login.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.login[i]);
                if (message.withDeleted != null && Object.hasOwnProperty.call(message, "withDeleted"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.withDeleted);
                return writer;
            };

            /**
             * Decodes a GetAdminList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetAdminList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetAdminList} GetAdminList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetAdminList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetAdminList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        if (!(message.login && message.login.length))
                            message.login = [];
                        message.login.push(reader.string());
                        break;
                    case 3:
                        message.withDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetAdminList message.
             * @function verify
             * @memberof fastcity.admin.GetAdminList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetAdminList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.login != null && message.hasOwnProperty("login")) {
                    if (!Array.isArray(message.login))
                        return "login: array expected";
                    for (var i = 0; i < message.login.length; ++i)
                        if (!$util.isString(message.login[i]))
                            return "login: string[] expected";
                }
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    if (typeof message.withDeleted !== "boolean")
                        return "withDeleted: boolean expected";
                return null;
            };

            /**
             * Creates a GetAdminList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetAdminList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetAdminList} GetAdminList
             */
            GetAdminList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetAdminList)
                    return object;
                var message = new $root.fastcity.admin.GetAdminList();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.login) {
                    if (!Array.isArray(object.login))
                        throw TypeError(".fastcity.admin.GetAdminList.login: array expected");
                    message.login = [];
                    for (var i = 0; i < object.login.length; ++i)
                        message.login[i] = String(object.login[i]);
                }
                if (object.withDeleted != null)
                    message.withDeleted = Boolean(object.withDeleted);
                return message;
            };

            /**
             * Creates a plain object from a GetAdminList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetAdminList
             * @static
             * @param {fastcity.admin.GetAdminList} message GetAdminList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetAdminList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.login = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.withDeleted = false;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.login && message.login.length) {
                    object.login = [];
                    for (var j = 0; j < message.login.length; ++j)
                        object.login[j] = message.login[j];
                }
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    object.withDeleted = message.withDeleted;
                return object;
            };

            /**
             * Converts this GetAdminList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetAdminList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetAdminList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetAdminList;
        })();

        admin.AdminList = (function() {

            /**
             * Properties of an AdminList.
             * @memberof fastcity.admin
             * @interface IAdminList
             * @property {Array.<fastcity.admin.IAdmin>|null} [admin] AdminList admin
             */

            /**
             * Constructs a new AdminList.
             * @memberof fastcity.admin
             * @classdesc Represents an AdminList.
             * @implements IAdminList
             * @constructor
             * @param {fastcity.admin.IAdminList=} [properties] Properties to set
             */
            function AdminList(properties) {
                this.admin = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AdminList admin.
             * @member {Array.<fastcity.admin.IAdmin>} admin
             * @memberof fastcity.admin.AdminList
             * @instance
             */
            AdminList.prototype.admin = $util.emptyArray;

            /**
             * Creates a new AdminList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.AdminList
             * @static
             * @param {fastcity.admin.IAdminList=} [properties] Properties to set
             * @returns {fastcity.admin.AdminList} AdminList instance
             */
            AdminList.create = function create(properties) {
                return new AdminList(properties);
            };

            /**
             * Encodes the specified AdminList message. Does not implicitly {@link fastcity.admin.AdminList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.AdminList
             * @static
             * @param {fastcity.admin.IAdminList} message AdminList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AdminList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.admin != null && message.admin.length)
                    for (var i = 0; i < message.admin.length; ++i)
                        $root.fastcity.admin.Admin.encode(message.admin[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an AdminList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.AdminList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.AdminList} AdminList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AdminList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.AdminList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.admin && message.admin.length))
                            message.admin = [];
                        message.admin.push($root.fastcity.admin.Admin.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an AdminList message.
             * @function verify
             * @memberof fastcity.admin.AdminList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AdminList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.admin != null && message.hasOwnProperty("admin")) {
                    if (!Array.isArray(message.admin))
                        return "admin: array expected";
                    for (var i = 0; i < message.admin.length; ++i) {
                        var error = $root.fastcity.admin.Admin.verify(message.admin[i]);
                        if (error)
                            return "admin." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an AdminList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.AdminList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.AdminList} AdminList
             */
            AdminList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.AdminList)
                    return object;
                var message = new $root.fastcity.admin.AdminList();
                if (object.admin) {
                    if (!Array.isArray(object.admin))
                        throw TypeError(".fastcity.admin.AdminList.admin: array expected");
                    message.admin = [];
                    for (var i = 0; i < object.admin.length; ++i) {
                        if (typeof object.admin[i] !== "object")
                            throw TypeError(".fastcity.admin.AdminList.admin: object expected");
                        message.admin[i] = $root.fastcity.admin.Admin.fromObject(object.admin[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an AdminList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.AdminList
             * @static
             * @param {fastcity.admin.AdminList} message AdminList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AdminList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.admin = [];
                if (message.admin && message.admin.length) {
                    object.admin = [];
                    for (var j = 0; j < message.admin.length; ++j)
                        object.admin[j] = $root.fastcity.admin.Admin.toObject(message.admin[j], options);
                }
                return object;
            };

            /**
             * Converts this AdminList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.AdminList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AdminList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AdminList;
        })();

        admin.GetCurrentAdmin = (function() {

            /**
             * Properties of a GetCurrentAdmin.
             * @memberof fastcity.admin
             * @interface IGetCurrentAdmin
             */

            /**
             * Constructs a new GetCurrentAdmin.
             * @memberof fastcity.admin
             * @classdesc Represents a GetCurrentAdmin.
             * @implements IGetCurrentAdmin
             * @constructor
             * @param {fastcity.admin.IGetCurrentAdmin=} [properties] Properties to set
             */
            function GetCurrentAdmin(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetCurrentAdmin instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetCurrentAdmin
             * @static
             * @param {fastcity.admin.IGetCurrentAdmin=} [properties] Properties to set
             * @returns {fastcity.admin.GetCurrentAdmin} GetCurrentAdmin instance
             */
            GetCurrentAdmin.create = function create(properties) {
                return new GetCurrentAdmin(properties);
            };

            /**
             * Encodes the specified GetCurrentAdmin message. Does not implicitly {@link fastcity.admin.GetCurrentAdmin.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetCurrentAdmin
             * @static
             * @param {fastcity.admin.IGetCurrentAdmin} message GetCurrentAdmin message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCurrentAdmin.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetCurrentAdmin message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetCurrentAdmin
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetCurrentAdmin} GetCurrentAdmin
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCurrentAdmin.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetCurrentAdmin();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetCurrentAdmin message.
             * @function verify
             * @memberof fastcity.admin.GetCurrentAdmin
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetCurrentAdmin.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetCurrentAdmin message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetCurrentAdmin
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetCurrentAdmin} GetCurrentAdmin
             */
            GetCurrentAdmin.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetCurrentAdmin)
                    return object;
                return new $root.fastcity.admin.GetCurrentAdmin();
            };

            /**
             * Creates a plain object from a GetCurrentAdmin message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetCurrentAdmin
             * @static
             * @param {fastcity.admin.GetCurrentAdmin} message GetCurrentAdmin
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCurrentAdmin.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetCurrentAdmin to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetCurrentAdmin
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCurrentAdmin.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetCurrentAdmin;
        })();

        admin.CreateAdmin = (function() {

            /**
             * Properties of a CreateAdmin.
             * @memberof fastcity.admin
             * @interface ICreateAdmin
             * @property {string|null} [login] CreateAdmin login
             * @property {number|Long|null} [companyId] CreateAdmin companyId
             * @property {string|null} [name] CreateAdmin name
             * @property {string|null} [password] CreateAdmin password
             * @property {Array.<string>|null} [externalSubCompanyId] CreateAdmin externalSubCompanyId
             * @property {string|null} [email] CreateAdmin email
             * @property {Array.<fastcity.common.UserRole>|null} [roles] CreateAdmin roles
             */

            /**
             * Constructs a new CreateAdmin.
             * @memberof fastcity.admin
             * @classdesc Represents a CreateAdmin.
             * @implements ICreateAdmin
             * @constructor
             * @param {fastcity.admin.ICreateAdmin=} [properties] Properties to set
             */
            function CreateAdmin(properties) {
                this.externalSubCompanyId = [];
                this.roles = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateAdmin login.
             * @member {string} login
             * @memberof fastcity.admin.CreateAdmin
             * @instance
             */
            CreateAdmin.prototype.login = "";

            /**
             * CreateAdmin companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.CreateAdmin
             * @instance
             */
            CreateAdmin.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateAdmin name.
             * @member {string} name
             * @memberof fastcity.admin.CreateAdmin
             * @instance
             */
            CreateAdmin.prototype.name = "";

            /**
             * CreateAdmin password.
             * @member {string} password
             * @memberof fastcity.admin.CreateAdmin
             * @instance
             */
            CreateAdmin.prototype.password = "";

            /**
             * CreateAdmin externalSubCompanyId.
             * @member {Array.<string>} externalSubCompanyId
             * @memberof fastcity.admin.CreateAdmin
             * @instance
             */
            CreateAdmin.prototype.externalSubCompanyId = $util.emptyArray;

            /**
             * CreateAdmin email.
             * @member {string} email
             * @memberof fastcity.admin.CreateAdmin
             * @instance
             */
            CreateAdmin.prototype.email = "";

            /**
             * CreateAdmin roles.
             * @member {Array.<fastcity.common.UserRole>} roles
             * @memberof fastcity.admin.CreateAdmin
             * @instance
             */
            CreateAdmin.prototype.roles = $util.emptyArray;

            /**
             * Creates a new CreateAdmin instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CreateAdmin
             * @static
             * @param {fastcity.admin.ICreateAdmin=} [properties] Properties to set
             * @returns {fastcity.admin.CreateAdmin} CreateAdmin instance
             */
            CreateAdmin.create = function create(properties) {
                return new CreateAdmin(properties);
            };

            /**
             * Encodes the specified CreateAdmin message. Does not implicitly {@link fastcity.admin.CreateAdmin.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CreateAdmin
             * @static
             * @param {fastcity.admin.ICreateAdmin} message CreateAdmin message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateAdmin.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.login != null && Object.hasOwnProperty.call(message, "login"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.login);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.password);
                if (message.externalSubCompanyId != null && message.externalSubCompanyId.length)
                    for (var i = 0; i < message.externalSubCompanyId.length; ++i)
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.externalSubCompanyId[i]);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.email);
                if (message.roles != null && message.roles.length) {
                    writer.uint32(/* id 7, wireType 2 =*/58).fork();
                    for (var i = 0; i < message.roles.length; ++i)
                        writer.int32(message.roles[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes a CreateAdmin message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CreateAdmin
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CreateAdmin} CreateAdmin
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateAdmin.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CreateAdmin();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.login = reader.string();
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        message.name = reader.string();
                        break;
                    case 4:
                        message.password = reader.string();
                        break;
                    case 5:
                        if (!(message.externalSubCompanyId && message.externalSubCompanyId.length))
                            message.externalSubCompanyId = [];
                        message.externalSubCompanyId.push(reader.string());
                        break;
                    case 6:
                        message.email = reader.string();
                        break;
                    case 7:
                        if (!(message.roles && message.roles.length))
                            message.roles = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.roles.push(reader.int32());
                        } else
                            message.roles.push(reader.int32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CreateAdmin message.
             * @function verify
             * @memberof fastcity.admin.CreateAdmin
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateAdmin.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.login != null && message.hasOwnProperty("login"))
                    if (!$util.isString(message.login))
                        return "login: string expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId")) {
                    if (!Array.isArray(message.externalSubCompanyId))
                        return "externalSubCompanyId: array expected";
                    for (var i = 0; i < message.externalSubCompanyId.length; ++i)
                        if (!$util.isString(message.externalSubCompanyId[i]))
                            return "externalSubCompanyId: string[] expected";
                }
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.roles != null && message.hasOwnProperty("roles")) {
                    if (!Array.isArray(message.roles))
                        return "roles: array expected";
                    for (var i = 0; i < message.roles.length; ++i)
                        switch (message.roles[i]) {
                        default:
                            return "roles: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                }
                return null;
            };

            /**
             * Creates a CreateAdmin message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CreateAdmin
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CreateAdmin} CreateAdmin
             */
            CreateAdmin.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CreateAdmin)
                    return object;
                var message = new $root.fastcity.admin.CreateAdmin();
                if (object.login != null)
                    message.login = String(object.login);
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.password != null)
                    message.password = String(object.password);
                if (object.externalSubCompanyId) {
                    if (!Array.isArray(object.externalSubCompanyId))
                        throw TypeError(".fastcity.admin.CreateAdmin.externalSubCompanyId: array expected");
                    message.externalSubCompanyId = [];
                    for (var i = 0; i < object.externalSubCompanyId.length; ++i)
                        message.externalSubCompanyId[i] = String(object.externalSubCompanyId[i]);
                }
                if (object.email != null)
                    message.email = String(object.email);
                if (object.roles) {
                    if (!Array.isArray(object.roles))
                        throw TypeError(".fastcity.admin.CreateAdmin.roles: array expected");
                    message.roles = [];
                    for (var i = 0; i < object.roles.length; ++i)
                        switch (object.roles[i]) {
                        default:
                        case "GLOBAL_ADMIN":
                        case 0:
                            message.roles[i] = 0;
                            break;
                        case "SUPPORT":
                        case 1:
                            message.roles[i] = 1;
                            break;
                        case "COMPANY_ADMIN":
                        case 2:
                            message.roles[i] = 2;
                            break;
                        case "COMPANY_MANAGER":
                        case 3:
                            message.roles[i] = 3;
                            break;
                        case "COMPANY_COURIER":
                        case 4:
                            message.roles[i] = 4;
                            break;
                        }
                }
                return message;
            };

            /**
             * Creates a plain object from a CreateAdmin message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CreateAdmin
             * @static
             * @param {fastcity.admin.CreateAdmin} message CreateAdmin
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateAdmin.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.externalSubCompanyId = [];
                    object.roles = [];
                }
                if (options.defaults) {
                    object.login = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.password = "";
                    object.email = "";
                }
                if (message.login != null && message.hasOwnProperty("login"))
                    object.login = message.login;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                if (message.externalSubCompanyId && message.externalSubCompanyId.length) {
                    object.externalSubCompanyId = [];
                    for (var j = 0; j < message.externalSubCompanyId.length; ++j)
                        object.externalSubCompanyId[j] = message.externalSubCompanyId[j];
                }
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.roles && message.roles.length) {
                    object.roles = [];
                    for (var j = 0; j < message.roles.length; ++j)
                        object.roles[j] = options.enums === String ? $root.fastcity.common.UserRole[message.roles[j]] : message.roles[j];
                }
                return object;
            };

            /**
             * Converts this CreateAdmin to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CreateAdmin
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateAdmin.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CreateAdmin;
        })();

        admin.UpdateAdmin = (function() {

            /**
             * Properties of an UpdateAdmin.
             * @memberof fastcity.admin
             * @interface IUpdateAdmin
             * @property {string|null} [login] UpdateAdmin login
             * @property {string|null} [name] UpdateAdmin name
             * @property {string|null} [password] UpdateAdmin password
             * @property {string|null} [email] UpdateAdmin email
             * @property {Array.<string>|null} [externalSubCompanyId] UpdateAdmin externalSubCompanyId
             * @property {Array.<fastcity.common.UserRole>|null} [roles] UpdateAdmin roles
             */

            /**
             * Constructs a new UpdateAdmin.
             * @memberof fastcity.admin
             * @classdesc Represents an UpdateAdmin.
             * @implements IUpdateAdmin
             * @constructor
             * @param {fastcity.admin.IUpdateAdmin=} [properties] Properties to set
             */
            function UpdateAdmin(properties) {
                this.externalSubCompanyId = [];
                this.roles = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateAdmin login.
             * @member {string} login
             * @memberof fastcity.admin.UpdateAdmin
             * @instance
             */
            UpdateAdmin.prototype.login = "";

            /**
             * UpdateAdmin name.
             * @member {string} name
             * @memberof fastcity.admin.UpdateAdmin
             * @instance
             */
            UpdateAdmin.prototype.name = "";

            /**
             * UpdateAdmin password.
             * @member {string} password
             * @memberof fastcity.admin.UpdateAdmin
             * @instance
             */
            UpdateAdmin.prototype.password = "";

            /**
             * UpdateAdmin email.
             * @member {string} email
             * @memberof fastcity.admin.UpdateAdmin
             * @instance
             */
            UpdateAdmin.prototype.email = "";

            /**
             * UpdateAdmin externalSubCompanyId.
             * @member {Array.<string>} externalSubCompanyId
             * @memberof fastcity.admin.UpdateAdmin
             * @instance
             */
            UpdateAdmin.prototype.externalSubCompanyId = $util.emptyArray;

            /**
             * UpdateAdmin roles.
             * @member {Array.<fastcity.common.UserRole>} roles
             * @memberof fastcity.admin.UpdateAdmin
             * @instance
             */
            UpdateAdmin.prototype.roles = $util.emptyArray;

            /**
             * Creates a new UpdateAdmin instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.UpdateAdmin
             * @static
             * @param {fastcity.admin.IUpdateAdmin=} [properties] Properties to set
             * @returns {fastcity.admin.UpdateAdmin} UpdateAdmin instance
             */
            UpdateAdmin.create = function create(properties) {
                return new UpdateAdmin(properties);
            };

            /**
             * Encodes the specified UpdateAdmin message. Does not implicitly {@link fastcity.admin.UpdateAdmin.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.UpdateAdmin
             * @static
             * @param {fastcity.admin.IUpdateAdmin} message UpdateAdmin message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateAdmin.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.login != null && Object.hasOwnProperty.call(message, "login"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.login);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.password);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.email);
                if (message.externalSubCompanyId != null && message.externalSubCompanyId.length)
                    for (var i = 0; i < message.externalSubCompanyId.length; ++i)
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.externalSubCompanyId[i]);
                if (message.roles != null && message.roles.length) {
                    writer.uint32(/* id 6, wireType 2 =*/50).fork();
                    for (var i = 0; i < message.roles.length; ++i)
                        writer.int32(message.roles[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes an UpdateAdmin message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.UpdateAdmin
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.UpdateAdmin} UpdateAdmin
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateAdmin.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.UpdateAdmin();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.login = reader.string();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.password = reader.string();
                        break;
                    case 4:
                        message.email = reader.string();
                        break;
                    case 5:
                        if (!(message.externalSubCompanyId && message.externalSubCompanyId.length))
                            message.externalSubCompanyId = [];
                        message.externalSubCompanyId.push(reader.string());
                        break;
                    case 6:
                        if (!(message.roles && message.roles.length))
                            message.roles = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.roles.push(reader.int32());
                        } else
                            message.roles.push(reader.int32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateAdmin message.
             * @function verify
             * @memberof fastcity.admin.UpdateAdmin
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateAdmin.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.login != null && message.hasOwnProperty("login"))
                    if (!$util.isString(message.login))
                        return "login: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId")) {
                    if (!Array.isArray(message.externalSubCompanyId))
                        return "externalSubCompanyId: array expected";
                    for (var i = 0; i < message.externalSubCompanyId.length; ++i)
                        if (!$util.isString(message.externalSubCompanyId[i]))
                            return "externalSubCompanyId: string[] expected";
                }
                if (message.roles != null && message.hasOwnProperty("roles")) {
                    if (!Array.isArray(message.roles))
                        return "roles: array expected";
                    for (var i = 0; i < message.roles.length; ++i)
                        switch (message.roles[i]) {
                        default:
                            return "roles: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                }
                return null;
            };

            /**
             * Creates an UpdateAdmin message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.UpdateAdmin
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.UpdateAdmin} UpdateAdmin
             */
            UpdateAdmin.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.UpdateAdmin)
                    return object;
                var message = new $root.fastcity.admin.UpdateAdmin();
                if (object.login != null)
                    message.login = String(object.login);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.password != null)
                    message.password = String(object.password);
                if (object.email != null)
                    message.email = String(object.email);
                if (object.externalSubCompanyId) {
                    if (!Array.isArray(object.externalSubCompanyId))
                        throw TypeError(".fastcity.admin.UpdateAdmin.externalSubCompanyId: array expected");
                    message.externalSubCompanyId = [];
                    for (var i = 0; i < object.externalSubCompanyId.length; ++i)
                        message.externalSubCompanyId[i] = String(object.externalSubCompanyId[i]);
                }
                if (object.roles) {
                    if (!Array.isArray(object.roles))
                        throw TypeError(".fastcity.admin.UpdateAdmin.roles: array expected");
                    message.roles = [];
                    for (var i = 0; i < object.roles.length; ++i)
                        switch (object.roles[i]) {
                        default:
                        case "GLOBAL_ADMIN":
                        case 0:
                            message.roles[i] = 0;
                            break;
                        case "SUPPORT":
                        case 1:
                            message.roles[i] = 1;
                            break;
                        case "COMPANY_ADMIN":
                        case 2:
                            message.roles[i] = 2;
                            break;
                        case "COMPANY_MANAGER":
                        case 3:
                            message.roles[i] = 3;
                            break;
                        case "COMPANY_COURIER":
                        case 4:
                            message.roles[i] = 4;
                            break;
                        }
                }
                return message;
            };

            /**
             * Creates a plain object from an UpdateAdmin message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.UpdateAdmin
             * @static
             * @param {fastcity.admin.UpdateAdmin} message UpdateAdmin
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateAdmin.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.externalSubCompanyId = [];
                    object.roles = [];
                }
                if (options.defaults) {
                    object.login = "";
                    object.name = "";
                    object.password = "";
                    object.email = "";
                }
                if (message.login != null && message.hasOwnProperty("login"))
                    object.login = message.login;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.externalSubCompanyId && message.externalSubCompanyId.length) {
                    object.externalSubCompanyId = [];
                    for (var j = 0; j < message.externalSubCompanyId.length; ++j)
                        object.externalSubCompanyId[j] = message.externalSubCompanyId[j];
                }
                if (message.roles && message.roles.length) {
                    object.roles = [];
                    for (var j = 0; j < message.roles.length; ++j)
                        object.roles[j] = options.enums === String ? $root.fastcity.common.UserRole[message.roles[j]] : message.roles[j];
                }
                return object;
            };

            /**
             * Converts this UpdateAdmin to JSON.
             * @function toJSON
             * @memberof fastcity.admin.UpdateAdmin
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateAdmin.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateAdmin;
        })();

        admin.DeleteAdmin = (function() {

            /**
             * Properties of a DeleteAdmin.
             * @memberof fastcity.admin
             * @interface IDeleteAdmin
             * @property {string|null} [login] DeleteAdmin login
             */

            /**
             * Constructs a new DeleteAdmin.
             * @memberof fastcity.admin
             * @classdesc Represents a DeleteAdmin.
             * @implements IDeleteAdmin
             * @constructor
             * @param {fastcity.admin.IDeleteAdmin=} [properties] Properties to set
             */
            function DeleteAdmin(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteAdmin login.
             * @member {string} login
             * @memberof fastcity.admin.DeleteAdmin
             * @instance
             */
            DeleteAdmin.prototype.login = "";

            /**
             * Creates a new DeleteAdmin instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DeleteAdmin
             * @static
             * @param {fastcity.admin.IDeleteAdmin=} [properties] Properties to set
             * @returns {fastcity.admin.DeleteAdmin} DeleteAdmin instance
             */
            DeleteAdmin.create = function create(properties) {
                return new DeleteAdmin(properties);
            };

            /**
             * Encodes the specified DeleteAdmin message. Does not implicitly {@link fastcity.admin.DeleteAdmin.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DeleteAdmin
             * @static
             * @param {fastcity.admin.IDeleteAdmin} message DeleteAdmin message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteAdmin.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.login != null && Object.hasOwnProperty.call(message, "login"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.login);
                return writer;
            };

            /**
             * Decodes a DeleteAdmin message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DeleteAdmin
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DeleteAdmin} DeleteAdmin
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteAdmin.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DeleteAdmin();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.login = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeleteAdmin message.
             * @function verify
             * @memberof fastcity.admin.DeleteAdmin
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteAdmin.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.login != null && message.hasOwnProperty("login"))
                    if (!$util.isString(message.login))
                        return "login: string expected";
                return null;
            };

            /**
             * Creates a DeleteAdmin message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DeleteAdmin
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DeleteAdmin} DeleteAdmin
             */
            DeleteAdmin.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DeleteAdmin)
                    return object;
                var message = new $root.fastcity.admin.DeleteAdmin();
                if (object.login != null)
                    message.login = String(object.login);
                return message;
            };

            /**
             * Creates a plain object from a DeleteAdmin message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DeleteAdmin
             * @static
             * @param {fastcity.admin.DeleteAdmin} message DeleteAdmin
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteAdmin.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.login = "";
                if (message.login != null && message.hasOwnProperty("login"))
                    object.login = message.login;
                return object;
            };

            /**
             * Converts this DeleteAdmin to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DeleteAdmin
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteAdmin.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeleteAdmin;
        })();

        admin.AvailableOrderStatuses = (function() {

            /**
             * Properties of an AvailableOrderStatuses.
             * @memberof fastcity.admin
             * @interface IAvailableOrderStatuses
             * @property {number|Long|null} [companyId] AvailableOrderStatuses companyId
             * @property {string|null} [externalSubCompanyId] AvailableOrderStatuses externalSubCompanyId
             * @property {Array.<fastcity.admin.IIKOOrderStatus>|null} [orderStatus] AvailableOrderStatuses orderStatus
             * @property {boolean|null} [isDeleted] AvailableOrderStatuses isDeleted
             */

            /**
             * Constructs a new AvailableOrderStatuses.
             * @memberof fastcity.admin
             * @classdesc Represents an AvailableOrderStatuses.
             * @implements IAvailableOrderStatuses
             * @constructor
             * @param {fastcity.admin.IAvailableOrderStatuses=} [properties] Properties to set
             */
            function AvailableOrderStatuses(properties) {
                this.orderStatus = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AvailableOrderStatuses companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.AvailableOrderStatuses
             * @instance
             */
            AvailableOrderStatuses.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * AvailableOrderStatuses externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.AvailableOrderStatuses
             * @instance
             */
            AvailableOrderStatuses.prototype.externalSubCompanyId = "";

            /**
             * AvailableOrderStatuses orderStatus.
             * @member {Array.<fastcity.admin.IIKOOrderStatus>} orderStatus
             * @memberof fastcity.admin.AvailableOrderStatuses
             * @instance
             */
            AvailableOrderStatuses.prototype.orderStatus = $util.emptyArray;

            /**
             * AvailableOrderStatuses isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.AvailableOrderStatuses
             * @instance
             */
            AvailableOrderStatuses.prototype.isDeleted = false;

            /**
             * Creates a new AvailableOrderStatuses instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.AvailableOrderStatuses
             * @static
             * @param {fastcity.admin.IAvailableOrderStatuses=} [properties] Properties to set
             * @returns {fastcity.admin.AvailableOrderStatuses} AvailableOrderStatuses instance
             */
            AvailableOrderStatuses.create = function create(properties) {
                return new AvailableOrderStatuses(properties);
            };

            /**
             * Encodes the specified AvailableOrderStatuses message. Does not implicitly {@link fastcity.admin.AvailableOrderStatuses.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.AvailableOrderStatuses
             * @static
             * @param {fastcity.admin.IAvailableOrderStatuses} message AvailableOrderStatuses message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AvailableOrderStatuses.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalSubCompanyId);
                if (message.orderStatus != null && message.orderStatus.length) {
                    writer.uint32(/* id 3, wireType 2 =*/26).fork();
                    for (var i = 0; i < message.orderStatus.length; ++i)
                        writer.int32(message.orderStatus[i]);
                    writer.ldelim();
                }
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
                return writer;
            };

            /**
             * Decodes an AvailableOrderStatuses message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.AvailableOrderStatuses
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.AvailableOrderStatuses} AvailableOrderStatuses
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AvailableOrderStatuses.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.AvailableOrderStatuses();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.externalSubCompanyId = reader.string();
                        break;
                    case 3:
                        if (!(message.orderStatus && message.orderStatus.length))
                            message.orderStatus = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.orderStatus.push(reader.int32());
                        } else
                            message.orderStatus.push(reader.int32());
                        break;
                    case 4:
                        message.isDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an AvailableOrderStatuses message.
             * @function verify
             * @memberof fastcity.admin.AvailableOrderStatuses
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AvailableOrderStatuses.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                if (message.orderStatus != null && message.hasOwnProperty("orderStatus")) {
                    if (!Array.isArray(message.orderStatus))
                        return "orderStatus: array expected";
                    for (var i = 0; i < message.orderStatus.length; ++i)
                        switch (message.orderStatus[i]) {
                        default:
                            return "orderStatus: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                            break;
                        }
                }
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                return null;
            };

            /**
             * Creates an AvailableOrderStatuses message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.AvailableOrderStatuses
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.AvailableOrderStatuses} AvailableOrderStatuses
             */
            AvailableOrderStatuses.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.AvailableOrderStatuses)
                    return object;
                var message = new $root.fastcity.admin.AvailableOrderStatuses();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                if (object.orderStatus) {
                    if (!Array.isArray(object.orderStatus))
                        throw TypeError(".fastcity.admin.AvailableOrderStatuses.orderStatus: array expected");
                    message.orderStatus = [];
                    for (var i = 0; i < object.orderStatus.length; ++i)
                        switch (object.orderStatus[i]) {
                        default:
                        case "UNCOFIRMED":
                        case 0:
                            message.orderStatus[i] = 0;
                            break;
                        case "WAIT_COOKING":
                        case 1:
                            message.orderStatus[i] = 1;
                            break;
                        case "READY_FOR_COOKING":
                        case 2:
                            message.orderStatus[i] = 2;
                            break;
                        case "COOKING_STARTED":
                        case 3:
                            message.orderStatus[i] = 3;
                            break;
                        case "COOKING_COMPLETE":
                        case 4:
                            message.orderStatus[i] = 4;
                            break;
                        case "WAITING":
                        case 5:
                            message.orderStatus[i] = 5;
                            break;
                        case "ON_WAY":
                        case 6:
                            message.orderStatus[i] = 6;
                            break;
                        case "DELIVERED":
                        case 7:
                            message.orderStatus[i] = 7;
                            break;
                        case "CLOSED":
                        case 8:
                            message.orderStatus[i] = 8;
                            break;
                        case "CANCELLED":
                        case 9:
                            message.orderStatus[i] = 9;
                            break;
                        }
                }
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                return message;
            };

            /**
             * Creates a plain object from an AvailableOrderStatuses message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.AvailableOrderStatuses
             * @static
             * @param {fastcity.admin.AvailableOrderStatuses} message AvailableOrderStatuses
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AvailableOrderStatuses.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.orderStatus = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                    object.isDeleted = false;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                if (message.orderStatus && message.orderStatus.length) {
                    object.orderStatus = [];
                    for (var j = 0; j < message.orderStatus.length; ++j)
                        object.orderStatus[j] = options.enums === String ? $root.fastcity.admin.IIKOOrderStatus[message.orderStatus[j]] : message.orderStatus[j];
                }
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                return object;
            };

            /**
             * Converts this AvailableOrderStatuses to JSON.
             * @function toJSON
             * @memberof fastcity.admin.AvailableOrderStatuses
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AvailableOrderStatuses.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AvailableOrderStatuses;
        })();

        admin.AvailableOrderStatusesList = (function() {

            /**
             * Properties of an AvailableOrderStatusesList.
             * @memberof fastcity.admin
             * @interface IAvailableOrderStatusesList
             * @property {Array.<fastcity.admin.IAvailableOrderStatuses>|null} [availableOrderStatuses] AvailableOrderStatusesList availableOrderStatuses
             */

            /**
             * Constructs a new AvailableOrderStatusesList.
             * @memberof fastcity.admin
             * @classdesc Represents an AvailableOrderStatusesList.
             * @implements IAvailableOrderStatusesList
             * @constructor
             * @param {fastcity.admin.IAvailableOrderStatusesList=} [properties] Properties to set
             */
            function AvailableOrderStatusesList(properties) {
                this.availableOrderStatuses = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AvailableOrderStatusesList availableOrderStatuses.
             * @member {Array.<fastcity.admin.IAvailableOrderStatuses>} availableOrderStatuses
             * @memberof fastcity.admin.AvailableOrderStatusesList
             * @instance
             */
            AvailableOrderStatusesList.prototype.availableOrderStatuses = $util.emptyArray;

            /**
             * Creates a new AvailableOrderStatusesList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.AvailableOrderStatusesList
             * @static
             * @param {fastcity.admin.IAvailableOrderStatusesList=} [properties] Properties to set
             * @returns {fastcity.admin.AvailableOrderStatusesList} AvailableOrderStatusesList instance
             */
            AvailableOrderStatusesList.create = function create(properties) {
                return new AvailableOrderStatusesList(properties);
            };

            /**
             * Encodes the specified AvailableOrderStatusesList message. Does not implicitly {@link fastcity.admin.AvailableOrderStatusesList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.AvailableOrderStatusesList
             * @static
             * @param {fastcity.admin.IAvailableOrderStatusesList} message AvailableOrderStatusesList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AvailableOrderStatusesList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.availableOrderStatuses != null && message.availableOrderStatuses.length)
                    for (var i = 0; i < message.availableOrderStatuses.length; ++i)
                        $root.fastcity.admin.AvailableOrderStatuses.encode(message.availableOrderStatuses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an AvailableOrderStatusesList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.AvailableOrderStatusesList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.AvailableOrderStatusesList} AvailableOrderStatusesList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AvailableOrderStatusesList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.AvailableOrderStatusesList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.availableOrderStatuses && message.availableOrderStatuses.length))
                            message.availableOrderStatuses = [];
                        message.availableOrderStatuses.push($root.fastcity.admin.AvailableOrderStatuses.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an AvailableOrderStatusesList message.
             * @function verify
             * @memberof fastcity.admin.AvailableOrderStatusesList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AvailableOrderStatusesList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.availableOrderStatuses != null && message.hasOwnProperty("availableOrderStatuses")) {
                    if (!Array.isArray(message.availableOrderStatuses))
                        return "availableOrderStatuses: array expected";
                    for (var i = 0; i < message.availableOrderStatuses.length; ++i) {
                        var error = $root.fastcity.admin.AvailableOrderStatuses.verify(message.availableOrderStatuses[i]);
                        if (error)
                            return "availableOrderStatuses." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an AvailableOrderStatusesList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.AvailableOrderStatusesList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.AvailableOrderStatusesList} AvailableOrderStatusesList
             */
            AvailableOrderStatusesList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.AvailableOrderStatusesList)
                    return object;
                var message = new $root.fastcity.admin.AvailableOrderStatusesList();
                if (object.availableOrderStatuses) {
                    if (!Array.isArray(object.availableOrderStatuses))
                        throw TypeError(".fastcity.admin.AvailableOrderStatusesList.availableOrderStatuses: array expected");
                    message.availableOrderStatuses = [];
                    for (var i = 0; i < object.availableOrderStatuses.length; ++i) {
                        if (typeof object.availableOrderStatuses[i] !== "object")
                            throw TypeError(".fastcity.admin.AvailableOrderStatusesList.availableOrderStatuses: object expected");
                        message.availableOrderStatuses[i] = $root.fastcity.admin.AvailableOrderStatuses.fromObject(object.availableOrderStatuses[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an AvailableOrderStatusesList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.AvailableOrderStatusesList
             * @static
             * @param {fastcity.admin.AvailableOrderStatusesList} message AvailableOrderStatusesList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AvailableOrderStatusesList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.availableOrderStatuses = [];
                if (message.availableOrderStatuses && message.availableOrderStatuses.length) {
                    object.availableOrderStatuses = [];
                    for (var j = 0; j < message.availableOrderStatuses.length; ++j)
                        object.availableOrderStatuses[j] = $root.fastcity.admin.AvailableOrderStatuses.toObject(message.availableOrderStatuses[j], options);
                }
                return object;
            };

            /**
             * Converts this AvailableOrderStatusesList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.AvailableOrderStatusesList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AvailableOrderStatusesList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AvailableOrderStatusesList;
        })();

        admin.CreateAvailableOrderStatuses = (function() {

            /**
             * Properties of a CreateAvailableOrderStatuses.
             * @memberof fastcity.admin
             * @interface ICreateAvailableOrderStatuses
             * @property {number|Long|null} [companyId] CreateAvailableOrderStatuses companyId
             * @property {string|null} [externalSubCompanyId] CreateAvailableOrderStatuses externalSubCompanyId
             * @property {Array.<fastcity.admin.IIKOOrderStatus>|null} [orderStatus] CreateAvailableOrderStatuses orderStatus
             */

            /**
             * Constructs a new CreateAvailableOrderStatuses.
             * @memberof fastcity.admin
             * @classdesc Represents a CreateAvailableOrderStatuses.
             * @implements ICreateAvailableOrderStatuses
             * @constructor
             * @param {fastcity.admin.ICreateAvailableOrderStatuses=} [properties] Properties to set
             */
            function CreateAvailableOrderStatuses(properties) {
                this.orderStatus = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateAvailableOrderStatuses companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.CreateAvailableOrderStatuses
             * @instance
             */
            CreateAvailableOrderStatuses.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateAvailableOrderStatuses externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.CreateAvailableOrderStatuses
             * @instance
             */
            CreateAvailableOrderStatuses.prototype.externalSubCompanyId = "";

            /**
             * CreateAvailableOrderStatuses orderStatus.
             * @member {Array.<fastcity.admin.IIKOOrderStatus>} orderStatus
             * @memberof fastcity.admin.CreateAvailableOrderStatuses
             * @instance
             */
            CreateAvailableOrderStatuses.prototype.orderStatus = $util.emptyArray;

            /**
             * Creates a new CreateAvailableOrderStatuses instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CreateAvailableOrderStatuses
             * @static
             * @param {fastcity.admin.ICreateAvailableOrderStatuses=} [properties] Properties to set
             * @returns {fastcity.admin.CreateAvailableOrderStatuses} CreateAvailableOrderStatuses instance
             */
            CreateAvailableOrderStatuses.create = function create(properties) {
                return new CreateAvailableOrderStatuses(properties);
            };

            /**
             * Encodes the specified CreateAvailableOrderStatuses message. Does not implicitly {@link fastcity.admin.CreateAvailableOrderStatuses.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CreateAvailableOrderStatuses
             * @static
             * @param {fastcity.admin.ICreateAvailableOrderStatuses} message CreateAvailableOrderStatuses message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateAvailableOrderStatuses.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalSubCompanyId);
                if (message.orderStatus != null && message.orderStatus.length) {
                    writer.uint32(/* id 3, wireType 2 =*/26).fork();
                    for (var i = 0; i < message.orderStatus.length; ++i)
                        writer.int32(message.orderStatus[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes a CreateAvailableOrderStatuses message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CreateAvailableOrderStatuses
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CreateAvailableOrderStatuses} CreateAvailableOrderStatuses
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateAvailableOrderStatuses.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CreateAvailableOrderStatuses();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.externalSubCompanyId = reader.string();
                        break;
                    case 3:
                        if (!(message.orderStatus && message.orderStatus.length))
                            message.orderStatus = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.orderStatus.push(reader.int32());
                        } else
                            message.orderStatus.push(reader.int32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CreateAvailableOrderStatuses message.
             * @function verify
             * @memberof fastcity.admin.CreateAvailableOrderStatuses
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateAvailableOrderStatuses.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                if (message.orderStatus != null && message.hasOwnProperty("orderStatus")) {
                    if (!Array.isArray(message.orderStatus))
                        return "orderStatus: array expected";
                    for (var i = 0; i < message.orderStatus.length; ++i)
                        switch (message.orderStatus[i]) {
                        default:
                            return "orderStatus: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                            break;
                        }
                }
                return null;
            };

            /**
             * Creates a CreateAvailableOrderStatuses message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CreateAvailableOrderStatuses
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CreateAvailableOrderStatuses} CreateAvailableOrderStatuses
             */
            CreateAvailableOrderStatuses.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CreateAvailableOrderStatuses)
                    return object;
                var message = new $root.fastcity.admin.CreateAvailableOrderStatuses();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                if (object.orderStatus) {
                    if (!Array.isArray(object.orderStatus))
                        throw TypeError(".fastcity.admin.CreateAvailableOrderStatuses.orderStatus: array expected");
                    message.orderStatus = [];
                    for (var i = 0; i < object.orderStatus.length; ++i)
                        switch (object.orderStatus[i]) {
                        default:
                        case "UNCOFIRMED":
                        case 0:
                            message.orderStatus[i] = 0;
                            break;
                        case "WAIT_COOKING":
                        case 1:
                            message.orderStatus[i] = 1;
                            break;
                        case "READY_FOR_COOKING":
                        case 2:
                            message.orderStatus[i] = 2;
                            break;
                        case "COOKING_STARTED":
                        case 3:
                            message.orderStatus[i] = 3;
                            break;
                        case "COOKING_COMPLETE":
                        case 4:
                            message.orderStatus[i] = 4;
                            break;
                        case "WAITING":
                        case 5:
                            message.orderStatus[i] = 5;
                            break;
                        case "ON_WAY":
                        case 6:
                            message.orderStatus[i] = 6;
                            break;
                        case "DELIVERED":
                        case 7:
                            message.orderStatus[i] = 7;
                            break;
                        case "CLOSED":
                        case 8:
                            message.orderStatus[i] = 8;
                            break;
                        case "CANCELLED":
                        case 9:
                            message.orderStatus[i] = 9;
                            break;
                        }
                }
                return message;
            };

            /**
             * Creates a plain object from a CreateAvailableOrderStatuses message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CreateAvailableOrderStatuses
             * @static
             * @param {fastcity.admin.CreateAvailableOrderStatuses} message CreateAvailableOrderStatuses
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateAvailableOrderStatuses.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.orderStatus = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                if (message.orderStatus && message.orderStatus.length) {
                    object.orderStatus = [];
                    for (var j = 0; j < message.orderStatus.length; ++j)
                        object.orderStatus[j] = options.enums === String ? $root.fastcity.admin.IIKOOrderStatus[message.orderStatus[j]] : message.orderStatus[j];
                }
                return object;
            };

            /**
             * Converts this CreateAvailableOrderStatuses to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CreateAvailableOrderStatuses
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateAvailableOrderStatuses.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CreateAvailableOrderStatuses;
        })();

        admin.UpdateAvailableOrderStatuses = (function() {

            /**
             * Properties of an UpdateAvailableOrderStatuses.
             * @memberof fastcity.admin
             * @interface IUpdateAvailableOrderStatuses
             * @property {number|Long|null} [companyId] UpdateAvailableOrderStatuses companyId
             * @property {string|null} [externalSubCompanyId] UpdateAvailableOrderStatuses externalSubCompanyId
             * @property {Array.<fastcity.admin.IIKOOrderStatus>|null} [orderStatus] UpdateAvailableOrderStatuses orderStatus
             * @property {boolean|null} [isDeleted] UpdateAvailableOrderStatuses isDeleted
             */

            /**
             * Constructs a new UpdateAvailableOrderStatuses.
             * @memberof fastcity.admin
             * @classdesc Represents an UpdateAvailableOrderStatuses.
             * @implements IUpdateAvailableOrderStatuses
             * @constructor
             * @param {fastcity.admin.IUpdateAvailableOrderStatuses=} [properties] Properties to set
             */
            function UpdateAvailableOrderStatuses(properties) {
                this.orderStatus = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateAvailableOrderStatuses companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.UpdateAvailableOrderStatuses
             * @instance
             */
            UpdateAvailableOrderStatuses.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateAvailableOrderStatuses externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.UpdateAvailableOrderStatuses
             * @instance
             */
            UpdateAvailableOrderStatuses.prototype.externalSubCompanyId = "";

            /**
             * UpdateAvailableOrderStatuses orderStatus.
             * @member {Array.<fastcity.admin.IIKOOrderStatus>} orderStatus
             * @memberof fastcity.admin.UpdateAvailableOrderStatuses
             * @instance
             */
            UpdateAvailableOrderStatuses.prototype.orderStatus = $util.emptyArray;

            /**
             * UpdateAvailableOrderStatuses isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.UpdateAvailableOrderStatuses
             * @instance
             */
            UpdateAvailableOrderStatuses.prototype.isDeleted = false;

            /**
             * Creates a new UpdateAvailableOrderStatuses instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.UpdateAvailableOrderStatuses
             * @static
             * @param {fastcity.admin.IUpdateAvailableOrderStatuses=} [properties] Properties to set
             * @returns {fastcity.admin.UpdateAvailableOrderStatuses} UpdateAvailableOrderStatuses instance
             */
            UpdateAvailableOrderStatuses.create = function create(properties) {
                return new UpdateAvailableOrderStatuses(properties);
            };

            /**
             * Encodes the specified UpdateAvailableOrderStatuses message. Does not implicitly {@link fastcity.admin.UpdateAvailableOrderStatuses.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.UpdateAvailableOrderStatuses
             * @static
             * @param {fastcity.admin.IUpdateAvailableOrderStatuses} message UpdateAvailableOrderStatuses message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateAvailableOrderStatuses.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalSubCompanyId);
                if (message.orderStatus != null && message.orderStatus.length) {
                    writer.uint32(/* id 3, wireType 2 =*/26).fork();
                    for (var i = 0; i < message.orderStatus.length; ++i)
                        writer.int32(message.orderStatus[i]);
                    writer.ldelim();
                }
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
                return writer;
            };

            /**
             * Decodes an UpdateAvailableOrderStatuses message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.UpdateAvailableOrderStatuses
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.UpdateAvailableOrderStatuses} UpdateAvailableOrderStatuses
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateAvailableOrderStatuses.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.UpdateAvailableOrderStatuses();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.externalSubCompanyId = reader.string();
                        break;
                    case 3:
                        if (!(message.orderStatus && message.orderStatus.length))
                            message.orderStatus = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.orderStatus.push(reader.int32());
                        } else
                            message.orderStatus.push(reader.int32());
                        break;
                    case 4:
                        message.isDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateAvailableOrderStatuses message.
             * @function verify
             * @memberof fastcity.admin.UpdateAvailableOrderStatuses
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateAvailableOrderStatuses.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                if (message.orderStatus != null && message.hasOwnProperty("orderStatus")) {
                    if (!Array.isArray(message.orderStatus))
                        return "orderStatus: array expected";
                    for (var i = 0; i < message.orderStatus.length; ++i)
                        switch (message.orderStatus[i]) {
                        default:
                            return "orderStatus: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                            break;
                        }
                }
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                return null;
            };

            /**
             * Creates an UpdateAvailableOrderStatuses message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.UpdateAvailableOrderStatuses
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.UpdateAvailableOrderStatuses} UpdateAvailableOrderStatuses
             */
            UpdateAvailableOrderStatuses.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.UpdateAvailableOrderStatuses)
                    return object;
                var message = new $root.fastcity.admin.UpdateAvailableOrderStatuses();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                if (object.orderStatus) {
                    if (!Array.isArray(object.orderStatus))
                        throw TypeError(".fastcity.admin.UpdateAvailableOrderStatuses.orderStatus: array expected");
                    message.orderStatus = [];
                    for (var i = 0; i < object.orderStatus.length; ++i)
                        switch (object.orderStatus[i]) {
                        default:
                        case "UNCOFIRMED":
                        case 0:
                            message.orderStatus[i] = 0;
                            break;
                        case "WAIT_COOKING":
                        case 1:
                            message.orderStatus[i] = 1;
                            break;
                        case "READY_FOR_COOKING":
                        case 2:
                            message.orderStatus[i] = 2;
                            break;
                        case "COOKING_STARTED":
                        case 3:
                            message.orderStatus[i] = 3;
                            break;
                        case "COOKING_COMPLETE":
                        case 4:
                            message.orderStatus[i] = 4;
                            break;
                        case "WAITING":
                        case 5:
                            message.orderStatus[i] = 5;
                            break;
                        case "ON_WAY":
                        case 6:
                            message.orderStatus[i] = 6;
                            break;
                        case "DELIVERED":
                        case 7:
                            message.orderStatus[i] = 7;
                            break;
                        case "CLOSED":
                        case 8:
                            message.orderStatus[i] = 8;
                            break;
                        case "CANCELLED":
                        case 9:
                            message.orderStatus[i] = 9;
                            break;
                        }
                }
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                return message;
            };

            /**
             * Creates a plain object from an UpdateAvailableOrderStatuses message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.UpdateAvailableOrderStatuses
             * @static
             * @param {fastcity.admin.UpdateAvailableOrderStatuses} message UpdateAvailableOrderStatuses
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateAvailableOrderStatuses.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.orderStatus = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                    object.isDeleted = false;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                if (message.orderStatus && message.orderStatus.length) {
                    object.orderStatus = [];
                    for (var j = 0; j < message.orderStatus.length; ++j)
                        object.orderStatus[j] = options.enums === String ? $root.fastcity.admin.IIKOOrderStatus[message.orderStatus[j]] : message.orderStatus[j];
                }
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                return object;
            };

            /**
             * Converts this UpdateAvailableOrderStatuses to JSON.
             * @function toJSON
             * @memberof fastcity.admin.UpdateAvailableOrderStatuses
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateAvailableOrderStatuses.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateAvailableOrderStatuses;
        })();

        admin.GetAvailableOrderStatuses = (function() {

            /**
             * Properties of a GetAvailableOrderStatuses.
             * @memberof fastcity.admin
             * @interface IGetAvailableOrderStatuses
             * @property {number|Long|null} [companyId] GetAvailableOrderStatuses companyId
             * @property {string|null} [externalSubCompanyId] GetAvailableOrderStatuses externalSubCompanyId
             */

            /**
             * Constructs a new GetAvailableOrderStatuses.
             * @memberof fastcity.admin
             * @classdesc Represents a GetAvailableOrderStatuses.
             * @implements IGetAvailableOrderStatuses
             * @constructor
             * @param {fastcity.admin.IGetAvailableOrderStatuses=} [properties] Properties to set
             */
            function GetAvailableOrderStatuses(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetAvailableOrderStatuses companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.GetAvailableOrderStatuses
             * @instance
             */
            GetAvailableOrderStatuses.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetAvailableOrderStatuses externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.GetAvailableOrderStatuses
             * @instance
             */
            GetAvailableOrderStatuses.prototype.externalSubCompanyId = "";

            /**
             * Creates a new GetAvailableOrderStatuses instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetAvailableOrderStatuses
             * @static
             * @param {fastcity.admin.IGetAvailableOrderStatuses=} [properties] Properties to set
             * @returns {fastcity.admin.GetAvailableOrderStatuses} GetAvailableOrderStatuses instance
             */
            GetAvailableOrderStatuses.create = function create(properties) {
                return new GetAvailableOrderStatuses(properties);
            };

            /**
             * Encodes the specified GetAvailableOrderStatuses message. Does not implicitly {@link fastcity.admin.GetAvailableOrderStatuses.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetAvailableOrderStatuses
             * @static
             * @param {fastcity.admin.IGetAvailableOrderStatuses} message GetAvailableOrderStatuses message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetAvailableOrderStatuses.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalSubCompanyId);
                return writer;
            };

            /**
             * Decodes a GetAvailableOrderStatuses message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetAvailableOrderStatuses
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetAvailableOrderStatuses} GetAvailableOrderStatuses
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetAvailableOrderStatuses.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetAvailableOrderStatuses();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.externalSubCompanyId = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetAvailableOrderStatuses message.
             * @function verify
             * @memberof fastcity.admin.GetAvailableOrderStatuses
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetAvailableOrderStatuses.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                return null;
            };

            /**
             * Creates a GetAvailableOrderStatuses message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetAvailableOrderStatuses
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetAvailableOrderStatuses} GetAvailableOrderStatuses
             */
            GetAvailableOrderStatuses.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetAvailableOrderStatuses)
                    return object;
                var message = new $root.fastcity.admin.GetAvailableOrderStatuses();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                return message;
            };

            /**
             * Creates a plain object from a GetAvailableOrderStatuses message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetAvailableOrderStatuses
             * @static
             * @param {fastcity.admin.GetAvailableOrderStatuses} message GetAvailableOrderStatuses
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetAvailableOrderStatuses.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                return object;
            };

            /**
             * Converts this GetAvailableOrderStatuses to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetAvailableOrderStatuses
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetAvailableOrderStatuses.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetAvailableOrderStatuses;
        })();

        admin.GetAvailableOrderStatusesList = (function() {

            /**
             * Properties of a GetAvailableOrderStatusesList.
             * @memberof fastcity.admin
             * @interface IGetAvailableOrderStatusesList
             * @property {number|Long|null} [companyId] GetAvailableOrderStatusesList companyId
             * @property {Array.<string>|null} [externalSubCompanyIds] GetAvailableOrderStatusesList externalSubCompanyIds
             */

            /**
             * Constructs a new GetAvailableOrderStatusesList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetAvailableOrderStatusesList.
             * @implements IGetAvailableOrderStatusesList
             * @constructor
             * @param {fastcity.admin.IGetAvailableOrderStatusesList=} [properties] Properties to set
             */
            function GetAvailableOrderStatusesList(properties) {
                this.externalSubCompanyIds = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetAvailableOrderStatusesList companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.GetAvailableOrderStatusesList
             * @instance
             */
            GetAvailableOrderStatusesList.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetAvailableOrderStatusesList externalSubCompanyIds.
             * @member {Array.<string>} externalSubCompanyIds
             * @memberof fastcity.admin.GetAvailableOrderStatusesList
             * @instance
             */
            GetAvailableOrderStatusesList.prototype.externalSubCompanyIds = $util.emptyArray;

            /**
             * Creates a new GetAvailableOrderStatusesList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetAvailableOrderStatusesList
             * @static
             * @param {fastcity.admin.IGetAvailableOrderStatusesList=} [properties] Properties to set
             * @returns {fastcity.admin.GetAvailableOrderStatusesList} GetAvailableOrderStatusesList instance
             */
            GetAvailableOrderStatusesList.create = function create(properties) {
                return new GetAvailableOrderStatusesList(properties);
            };

            /**
             * Encodes the specified GetAvailableOrderStatusesList message. Does not implicitly {@link fastcity.admin.GetAvailableOrderStatusesList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetAvailableOrderStatusesList
             * @static
             * @param {fastcity.admin.IGetAvailableOrderStatusesList} message GetAvailableOrderStatusesList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetAvailableOrderStatusesList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.externalSubCompanyIds != null && message.externalSubCompanyIds.length)
                    for (var i = 0; i < message.externalSubCompanyIds.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalSubCompanyIds[i]);
                return writer;
            };

            /**
             * Decodes a GetAvailableOrderStatusesList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetAvailableOrderStatusesList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetAvailableOrderStatusesList} GetAvailableOrderStatusesList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetAvailableOrderStatusesList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetAvailableOrderStatusesList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        if (!(message.externalSubCompanyIds && message.externalSubCompanyIds.length))
                            message.externalSubCompanyIds = [];
                        message.externalSubCompanyIds.push(reader.string());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetAvailableOrderStatusesList message.
             * @function verify
             * @memberof fastcity.admin.GetAvailableOrderStatusesList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetAvailableOrderStatusesList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyIds != null && message.hasOwnProperty("externalSubCompanyIds")) {
                    if (!Array.isArray(message.externalSubCompanyIds))
                        return "externalSubCompanyIds: array expected";
                    for (var i = 0; i < message.externalSubCompanyIds.length; ++i)
                        if (!$util.isString(message.externalSubCompanyIds[i]))
                            return "externalSubCompanyIds: string[] expected";
                }
                return null;
            };

            /**
             * Creates a GetAvailableOrderStatusesList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetAvailableOrderStatusesList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetAvailableOrderStatusesList} GetAvailableOrderStatusesList
             */
            GetAvailableOrderStatusesList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetAvailableOrderStatusesList)
                    return object;
                var message = new $root.fastcity.admin.GetAvailableOrderStatusesList();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyIds) {
                    if (!Array.isArray(object.externalSubCompanyIds))
                        throw TypeError(".fastcity.admin.GetAvailableOrderStatusesList.externalSubCompanyIds: array expected");
                    message.externalSubCompanyIds = [];
                    for (var i = 0; i < object.externalSubCompanyIds.length; ++i)
                        message.externalSubCompanyIds[i] = String(object.externalSubCompanyIds[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetAvailableOrderStatusesList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetAvailableOrderStatusesList
             * @static
             * @param {fastcity.admin.GetAvailableOrderStatusesList} message GetAvailableOrderStatusesList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetAvailableOrderStatusesList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.externalSubCompanyIds = [];
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyIds && message.externalSubCompanyIds.length) {
                    object.externalSubCompanyIds = [];
                    for (var j = 0; j < message.externalSubCompanyIds.length; ++j)
                        object.externalSubCompanyIds[j] = message.externalSubCompanyIds[j];
                }
                return object;
            };

            /**
             * Converts this GetAvailableOrderStatusesList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetAvailableOrderStatusesList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetAvailableOrderStatusesList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetAvailableOrderStatusesList;
        })();

        /**
         * IIKOOrderStatus enum.
         * @name fastcity.admin.IIKOOrderStatus
         * @enum {number}
         * @property {number} UNCOFIRMED=0 UNCOFIRMED value
         * @property {number} WAIT_COOKING=1 WAIT_COOKING value
         * @property {number} READY_FOR_COOKING=2 READY_FOR_COOKING value
         * @property {number} COOKING_STARTED=3 COOKING_STARTED value
         * @property {number} COOKING_COMPLETE=4 COOKING_COMPLETE value
         * @property {number} WAITING=5 WAITING value
         * @property {number} ON_WAY=6 ON_WAY value
         * @property {number} DELIVERED=7 DELIVERED value
         * @property {number} CLOSED=8 CLOSED value
         * @property {number} CANCELLED=9 CANCELLED value
         */
        admin.IIKOOrderStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNCOFIRMED"] = 0;
            values[valuesById[1] = "WAIT_COOKING"] = 1;
            values[valuesById[2] = "READY_FOR_COOKING"] = 2;
            values[valuesById[3] = "COOKING_STARTED"] = 3;
            values[valuesById[4] = "COOKING_COMPLETE"] = 4;
            values[valuesById[5] = "WAITING"] = 5;
            values[valuesById[6] = "ON_WAY"] = 6;
            values[valuesById[7] = "DELIVERED"] = 7;
            values[valuesById[8] = "CLOSED"] = 8;
            values[valuesById[9] = "CANCELLED"] = 9;
            return values;
        })();

        admin.Company = (function() {

            /**
             * Properties of a Company.
             * @memberof fastcity.admin
             * @interface ICompany
             * @property {number|Long|null} [companyId] Company companyId
             * @property {string|null} [name] Company name
             * @property {string|null} [description] Company description
             * @property {string|null} [site] Company site
             * @property {string|null} [email] Company email
             * @property {string|null} [authToken] Company authToken
             * @property {boolean|null} [isDeleted] Company isDeleted
             * @property {string|null} [supportPhone] Company supportPhone
             * @property {number|null} [maximumDeliveryTime] Company maximumDeliveryTime
             * @property {fastcity.common.Company.MapType|null} [mapType] Company mapType
             * @property {number|null} [maximumActiveOrdersOnCourier] Company maximumActiveOrdersOnCourier
             */

            /**
             * Constructs a new Company.
             * @memberof fastcity.admin
             * @classdesc Represents a Company.
             * @implements ICompany
             * @constructor
             * @param {fastcity.admin.ICompany=} [properties] Properties to set
             */
            function Company(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Company companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.Company
             * @instance
             */
            Company.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Company name.
             * @member {string} name
             * @memberof fastcity.admin.Company
             * @instance
             */
            Company.prototype.name = "";

            /**
             * Company description.
             * @member {string} description
             * @memberof fastcity.admin.Company
             * @instance
             */
            Company.prototype.description = "";

            /**
             * Company site.
             * @member {string} site
             * @memberof fastcity.admin.Company
             * @instance
             */
            Company.prototype.site = "";

            /**
             * Company email.
             * @member {string} email
             * @memberof fastcity.admin.Company
             * @instance
             */
            Company.prototype.email = "";

            /**
             * Company authToken.
             * @member {string} authToken
             * @memberof fastcity.admin.Company
             * @instance
             */
            Company.prototype.authToken = "";

            /**
             * Company isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.Company
             * @instance
             */
            Company.prototype.isDeleted = false;

            /**
             * Company supportPhone.
             * @member {string} supportPhone
             * @memberof fastcity.admin.Company
             * @instance
             */
            Company.prototype.supportPhone = "";

            /**
             * Company maximumDeliveryTime.
             * @member {number} maximumDeliveryTime
             * @memberof fastcity.admin.Company
             * @instance
             */
            Company.prototype.maximumDeliveryTime = 0;

            /**
             * Company mapType.
             * @member {fastcity.common.Company.MapType} mapType
             * @memberof fastcity.admin.Company
             * @instance
             */
            Company.prototype.mapType = 0;

            /**
             * Company maximumActiveOrdersOnCourier.
             * @member {number} maximumActiveOrdersOnCourier
             * @memberof fastcity.admin.Company
             * @instance
             */
            Company.prototype.maximumActiveOrdersOnCourier = 0;

            /**
             * Creates a new Company instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.Company
             * @static
             * @param {fastcity.admin.ICompany=} [properties] Properties to set
             * @returns {fastcity.admin.Company} Company instance
             */
            Company.create = function create(properties) {
                return new Company(properties);
            };

            /**
             * Encodes the specified Company message. Does not implicitly {@link fastcity.admin.Company.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.Company
             * @static
             * @param {fastcity.admin.ICompany} message Company message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Company.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                if (message.site != null && Object.hasOwnProperty.call(message, "site"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.site);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.email);
                if (message.authToken != null && Object.hasOwnProperty.call(message, "authToken"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.authToken);
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isDeleted);
                if (message.supportPhone != null && Object.hasOwnProperty.call(message, "supportPhone"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.supportPhone);
                if (message.maximumDeliveryTime != null && Object.hasOwnProperty.call(message, "maximumDeliveryTime"))
                    writer.uint32(/* id 9, wireType 0 =*/72).uint32(message.maximumDeliveryTime);
                if (message.mapType != null && Object.hasOwnProperty.call(message, "mapType"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int32(message.mapType);
                if (message.maximumActiveOrdersOnCourier != null && Object.hasOwnProperty.call(message, "maximumActiveOrdersOnCourier"))
                    writer.uint32(/* id 11, wireType 0 =*/88).uint32(message.maximumActiveOrdersOnCourier);
                return writer;
            };

            /**
             * Decodes a Company message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.Company
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.Company} Company
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Company.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.Company();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.description = reader.string();
                        break;
                    case 4:
                        message.site = reader.string();
                        break;
                    case 5:
                        message.email = reader.string();
                        break;
                    case 6:
                        message.authToken = reader.string();
                        break;
                    case 7:
                        message.isDeleted = reader.bool();
                        break;
                    case 8:
                        message.supportPhone = reader.string();
                        break;
                    case 9:
                        message.maximumDeliveryTime = reader.uint32();
                        break;
                    case 10:
                        message.mapType = reader.int32();
                        break;
                    case 11:
                        message.maximumActiveOrdersOnCourier = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Company message.
             * @function verify
             * @memberof fastcity.admin.Company
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Company.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.site != null && message.hasOwnProperty("site"))
                    if (!$util.isString(message.site))
                        return "site: string expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.authToken != null && message.hasOwnProperty("authToken"))
                    if (!$util.isString(message.authToken))
                        return "authToken: string expected";
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                if (message.supportPhone != null && message.hasOwnProperty("supportPhone"))
                    if (!$util.isString(message.supportPhone))
                        return "supportPhone: string expected";
                if (message.maximumDeliveryTime != null && message.hasOwnProperty("maximumDeliveryTime"))
                    if (!$util.isInteger(message.maximumDeliveryTime))
                        return "maximumDeliveryTime: integer expected";
                if (message.mapType != null && message.hasOwnProperty("mapType"))
                    switch (message.mapType) {
                    default:
                        return "mapType: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.maximumActiveOrdersOnCourier != null && message.hasOwnProperty("maximumActiveOrdersOnCourier"))
                    if (!$util.isInteger(message.maximumActiveOrdersOnCourier))
                        return "maximumActiveOrdersOnCourier: integer expected";
                return null;
            };

            /**
             * Creates a Company message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.Company
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.Company} Company
             */
            Company.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.Company)
                    return object;
                var message = new $root.fastcity.admin.Company();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.description != null)
                    message.description = String(object.description);
                if (object.site != null)
                    message.site = String(object.site);
                if (object.email != null)
                    message.email = String(object.email);
                if (object.authToken != null)
                    message.authToken = String(object.authToken);
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                if (object.supportPhone != null)
                    message.supportPhone = String(object.supportPhone);
                if (object.maximumDeliveryTime != null)
                    message.maximumDeliveryTime = object.maximumDeliveryTime >>> 0;
                switch (object.mapType) {
                case "GOOGLE":
                case 0:
                    message.mapType = 0;
                    break;
                case "YANDEX":
                case 1:
                    message.mapType = 1;
                    break;
                }
                if (object.maximumActiveOrdersOnCourier != null)
                    message.maximumActiveOrdersOnCourier = object.maximumActiveOrdersOnCourier >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a Company message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.Company
             * @static
             * @param {fastcity.admin.Company} message Company
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Company.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.description = "";
                    object.site = "";
                    object.email = "";
                    object.authToken = "";
                    object.isDeleted = false;
                    object.supportPhone = "";
                    object.maximumDeliveryTime = 0;
                    object.mapType = options.enums === String ? "GOOGLE" : 0;
                    object.maximumActiveOrdersOnCourier = 0;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.site != null && message.hasOwnProperty("site"))
                    object.site = message.site;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.authToken != null && message.hasOwnProperty("authToken"))
                    object.authToken = message.authToken;
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                if (message.supportPhone != null && message.hasOwnProperty("supportPhone"))
                    object.supportPhone = message.supportPhone;
                if (message.maximumDeliveryTime != null && message.hasOwnProperty("maximumDeliveryTime"))
                    object.maximumDeliveryTime = message.maximumDeliveryTime;
                if (message.mapType != null && message.hasOwnProperty("mapType"))
                    object.mapType = options.enums === String ? $root.fastcity.common.Company.MapType[message.mapType] : message.mapType;
                if (message.maximumActiveOrdersOnCourier != null && message.hasOwnProperty("maximumActiveOrdersOnCourier"))
                    object.maximumActiveOrdersOnCourier = message.maximumActiveOrdersOnCourier;
                return object;
            };

            /**
             * Converts this Company to JSON.
             * @function toJSON
             * @memberof fastcity.admin.Company
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Company.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Company;
        })();

        admin.CreateCompany = (function() {

            /**
             * Properties of a CreateCompany.
             * @memberof fastcity.admin
             * @interface ICreateCompany
             * @property {string|null} [name] CreateCompany name
             * @property {string|null} [description] CreateCompany description
             * @property {string|null} [site] CreateCompany site
             * @property {string|null} [email] CreateCompany email
             * @property {string|null} [supportPhone] CreateCompany supportPhone
             * @property {number|null} [maximumDeliveryTime] CreateCompany maximumDeliveryTime
             * @property {fastcity.common.Company.MapType|null} [mapType] CreateCompany mapType
             * @property {number|null} [maximumActiveOrdersOnCourier] CreateCompany maximumActiveOrdersOnCourier
             */

            /**
             * Constructs a new CreateCompany.
             * @memberof fastcity.admin
             * @classdesc Represents a CreateCompany.
             * @implements ICreateCompany
             * @constructor
             * @param {fastcity.admin.ICreateCompany=} [properties] Properties to set
             */
            function CreateCompany(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateCompany name.
             * @member {string} name
             * @memberof fastcity.admin.CreateCompany
             * @instance
             */
            CreateCompany.prototype.name = "";

            /**
             * CreateCompany description.
             * @member {string} description
             * @memberof fastcity.admin.CreateCompany
             * @instance
             */
            CreateCompany.prototype.description = "";

            /**
             * CreateCompany site.
             * @member {string} site
             * @memberof fastcity.admin.CreateCompany
             * @instance
             */
            CreateCompany.prototype.site = "";

            /**
             * CreateCompany email.
             * @member {string} email
             * @memberof fastcity.admin.CreateCompany
             * @instance
             */
            CreateCompany.prototype.email = "";

            /**
             * CreateCompany supportPhone.
             * @member {string} supportPhone
             * @memberof fastcity.admin.CreateCompany
             * @instance
             */
            CreateCompany.prototype.supportPhone = "";

            /**
             * CreateCompany maximumDeliveryTime.
             * @member {number} maximumDeliveryTime
             * @memberof fastcity.admin.CreateCompany
             * @instance
             */
            CreateCompany.prototype.maximumDeliveryTime = 0;

            /**
             * CreateCompany mapType.
             * @member {fastcity.common.Company.MapType} mapType
             * @memberof fastcity.admin.CreateCompany
             * @instance
             */
            CreateCompany.prototype.mapType = 0;

            /**
             * CreateCompany maximumActiveOrdersOnCourier.
             * @member {number} maximumActiveOrdersOnCourier
             * @memberof fastcity.admin.CreateCompany
             * @instance
             */
            CreateCompany.prototype.maximumActiveOrdersOnCourier = 0;

            /**
             * Creates a new CreateCompany instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CreateCompany
             * @static
             * @param {fastcity.admin.ICreateCompany=} [properties] Properties to set
             * @returns {fastcity.admin.CreateCompany} CreateCompany instance
             */
            CreateCompany.create = function create(properties) {
                return new CreateCompany(properties);
            };

            /**
             * Encodes the specified CreateCompany message. Does not implicitly {@link fastcity.admin.CreateCompany.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CreateCompany
             * @static
             * @param {fastcity.admin.ICreateCompany} message CreateCompany message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateCompany.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
                if (message.site != null && Object.hasOwnProperty.call(message, "site"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.site);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.email);
                if (message.supportPhone != null && Object.hasOwnProperty.call(message, "supportPhone"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.supportPhone);
                if (message.maximumDeliveryTime != null && Object.hasOwnProperty.call(message, "maximumDeliveryTime"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.maximumDeliveryTime);
                if (message.mapType != null && Object.hasOwnProperty.call(message, "mapType"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.mapType);
                if (message.maximumActiveOrdersOnCourier != null && Object.hasOwnProperty.call(message, "maximumActiveOrdersOnCourier"))
                    writer.uint32(/* id 8, wireType 0 =*/64).uint32(message.maximumActiveOrdersOnCourier);
                return writer;
            };

            /**
             * Decodes a CreateCompany message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CreateCompany
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CreateCompany} CreateCompany
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateCompany.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CreateCompany();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.description = reader.string();
                        break;
                    case 3:
                        message.site = reader.string();
                        break;
                    case 4:
                        message.email = reader.string();
                        break;
                    case 5:
                        message.supportPhone = reader.string();
                        break;
                    case 6:
                        message.maximumDeliveryTime = reader.uint32();
                        break;
                    case 7:
                        message.mapType = reader.int32();
                        break;
                    case 8:
                        message.maximumActiveOrdersOnCourier = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CreateCompany message.
             * @function verify
             * @memberof fastcity.admin.CreateCompany
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateCompany.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.site != null && message.hasOwnProperty("site"))
                    if (!$util.isString(message.site))
                        return "site: string expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.supportPhone != null && message.hasOwnProperty("supportPhone"))
                    if (!$util.isString(message.supportPhone))
                        return "supportPhone: string expected";
                if (message.maximumDeliveryTime != null && message.hasOwnProperty("maximumDeliveryTime"))
                    if (!$util.isInteger(message.maximumDeliveryTime))
                        return "maximumDeliveryTime: integer expected";
                if (message.mapType != null && message.hasOwnProperty("mapType"))
                    switch (message.mapType) {
                    default:
                        return "mapType: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.maximumActiveOrdersOnCourier != null && message.hasOwnProperty("maximumActiveOrdersOnCourier"))
                    if (!$util.isInteger(message.maximumActiveOrdersOnCourier))
                        return "maximumActiveOrdersOnCourier: integer expected";
                return null;
            };

            /**
             * Creates a CreateCompany message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CreateCompany
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CreateCompany} CreateCompany
             */
            CreateCompany.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CreateCompany)
                    return object;
                var message = new $root.fastcity.admin.CreateCompany();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.description != null)
                    message.description = String(object.description);
                if (object.site != null)
                    message.site = String(object.site);
                if (object.email != null)
                    message.email = String(object.email);
                if (object.supportPhone != null)
                    message.supportPhone = String(object.supportPhone);
                if (object.maximumDeliveryTime != null)
                    message.maximumDeliveryTime = object.maximumDeliveryTime >>> 0;
                switch (object.mapType) {
                case "GOOGLE":
                case 0:
                    message.mapType = 0;
                    break;
                case "YANDEX":
                case 1:
                    message.mapType = 1;
                    break;
                }
                if (object.maximumActiveOrdersOnCourier != null)
                    message.maximumActiveOrdersOnCourier = object.maximumActiveOrdersOnCourier >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a CreateCompany message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CreateCompany
             * @static
             * @param {fastcity.admin.CreateCompany} message CreateCompany
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateCompany.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.description = "";
                    object.site = "";
                    object.email = "";
                    object.supportPhone = "";
                    object.maximumDeliveryTime = 0;
                    object.mapType = options.enums === String ? "GOOGLE" : 0;
                    object.maximumActiveOrdersOnCourier = 0;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.site != null && message.hasOwnProperty("site"))
                    object.site = message.site;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.supportPhone != null && message.hasOwnProperty("supportPhone"))
                    object.supportPhone = message.supportPhone;
                if (message.maximumDeliveryTime != null && message.hasOwnProperty("maximumDeliveryTime"))
                    object.maximumDeliveryTime = message.maximumDeliveryTime;
                if (message.mapType != null && message.hasOwnProperty("mapType"))
                    object.mapType = options.enums === String ? $root.fastcity.common.Company.MapType[message.mapType] : message.mapType;
                if (message.maximumActiveOrdersOnCourier != null && message.hasOwnProperty("maximumActiveOrdersOnCourier"))
                    object.maximumActiveOrdersOnCourier = message.maximumActiveOrdersOnCourier;
                return object;
            };

            /**
             * Converts this CreateCompany to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CreateCompany
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateCompany.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CreateCompany;
        })();

        admin.UpdateCompany = (function() {

            /**
             * Properties of an UpdateCompany.
             * @memberof fastcity.admin
             * @interface IUpdateCompany
             * @property {number|Long|null} [companyId] UpdateCompany companyId
             * @property {string|null} [name] UpdateCompany name
             * @property {string|null} [description] UpdateCompany description
             * @property {string|null} [site] UpdateCompany site
             * @property {string|null} [email] UpdateCompany email
             * @property {string|null} [supportPhone] UpdateCompany supportPhone
             * @property {number|null} [maximumDeliveryTime] UpdateCompany maximumDeliveryTime
             * @property {number|Long|null} [minimalDistanceToOrderTarget] UpdateCompany minimalDistanceToOrderTarget
             * @property {fastcity.common.Company.MapType|null} [mapType] UpdateCompany mapType
             * @property {number|null} [maximumActiveOrdersOnCourier] UpdateCompany maximumActiveOrdersOnCourier
             */

            /**
             * Constructs a new UpdateCompany.
             * @memberof fastcity.admin
             * @classdesc Represents an UpdateCompany.
             * @implements IUpdateCompany
             * @constructor
             * @param {fastcity.admin.IUpdateCompany=} [properties] Properties to set
             */
            function UpdateCompany(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateCompany companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.UpdateCompany
             * @instance
             */
            UpdateCompany.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateCompany name.
             * @member {string} name
             * @memberof fastcity.admin.UpdateCompany
             * @instance
             */
            UpdateCompany.prototype.name = "";

            /**
             * UpdateCompany description.
             * @member {string} description
             * @memberof fastcity.admin.UpdateCompany
             * @instance
             */
            UpdateCompany.prototype.description = "";

            /**
             * UpdateCompany site.
             * @member {string} site
             * @memberof fastcity.admin.UpdateCompany
             * @instance
             */
            UpdateCompany.prototype.site = "";

            /**
             * UpdateCompany email.
             * @member {string} email
             * @memberof fastcity.admin.UpdateCompany
             * @instance
             */
            UpdateCompany.prototype.email = "";

            /**
             * UpdateCompany supportPhone.
             * @member {string} supportPhone
             * @memberof fastcity.admin.UpdateCompany
             * @instance
             */
            UpdateCompany.prototype.supportPhone = "";

            /**
             * UpdateCompany maximumDeliveryTime.
             * @member {number} maximumDeliveryTime
             * @memberof fastcity.admin.UpdateCompany
             * @instance
             */
            UpdateCompany.prototype.maximumDeliveryTime = 0;

            /**
             * UpdateCompany minimalDistanceToOrderTarget.
             * @member {number|Long} minimalDistanceToOrderTarget
             * @memberof fastcity.admin.UpdateCompany
             * @instance
             */
            UpdateCompany.prototype.minimalDistanceToOrderTarget = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateCompany mapType.
             * @member {fastcity.common.Company.MapType} mapType
             * @memberof fastcity.admin.UpdateCompany
             * @instance
             */
            UpdateCompany.prototype.mapType = 0;

            /**
             * UpdateCompany maximumActiveOrdersOnCourier.
             * @member {number} maximumActiveOrdersOnCourier
             * @memberof fastcity.admin.UpdateCompany
             * @instance
             */
            UpdateCompany.prototype.maximumActiveOrdersOnCourier = 0;

            /**
             * Creates a new UpdateCompany instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.UpdateCompany
             * @static
             * @param {fastcity.admin.IUpdateCompany=} [properties] Properties to set
             * @returns {fastcity.admin.UpdateCompany} UpdateCompany instance
             */
            UpdateCompany.create = function create(properties) {
                return new UpdateCompany(properties);
            };

            /**
             * Encodes the specified UpdateCompany message. Does not implicitly {@link fastcity.admin.UpdateCompany.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.UpdateCompany
             * @static
             * @param {fastcity.admin.IUpdateCompany} message UpdateCompany message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateCompany.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                if (message.site != null && Object.hasOwnProperty.call(message, "site"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.site);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.email);
                if (message.supportPhone != null && Object.hasOwnProperty.call(message, "supportPhone"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.supportPhone);
                if (message.maximumDeliveryTime != null && Object.hasOwnProperty.call(message, "maximumDeliveryTime"))
                    writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.maximumDeliveryTime);
                if (message.minimalDistanceToOrderTarget != null && Object.hasOwnProperty.call(message, "minimalDistanceToOrderTarget"))
                    writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.minimalDistanceToOrderTarget);
                if (message.mapType != null && Object.hasOwnProperty.call(message, "mapType"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.mapType);
                if (message.maximumActiveOrdersOnCourier != null && Object.hasOwnProperty.call(message, "maximumActiveOrdersOnCourier"))
                    writer.uint32(/* id 10, wireType 0 =*/80).uint32(message.maximumActiveOrdersOnCourier);
                return writer;
            };

            /**
             * Decodes an UpdateCompany message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.UpdateCompany
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.UpdateCompany} UpdateCompany
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateCompany.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.UpdateCompany();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.description = reader.string();
                        break;
                    case 4:
                        message.site = reader.string();
                        break;
                    case 5:
                        message.email = reader.string();
                        break;
                    case 6:
                        message.supportPhone = reader.string();
                        break;
                    case 7:
                        message.maximumDeliveryTime = reader.uint32();
                        break;
                    case 8:
                        message.minimalDistanceToOrderTarget = reader.uint64();
                        break;
                    case 9:
                        message.mapType = reader.int32();
                        break;
                    case 10:
                        message.maximumActiveOrdersOnCourier = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateCompany message.
             * @function verify
             * @memberof fastcity.admin.UpdateCompany
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateCompany.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.site != null && message.hasOwnProperty("site"))
                    if (!$util.isString(message.site))
                        return "site: string expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.supportPhone != null && message.hasOwnProperty("supportPhone"))
                    if (!$util.isString(message.supportPhone))
                        return "supportPhone: string expected";
                if (message.maximumDeliveryTime != null && message.hasOwnProperty("maximumDeliveryTime"))
                    if (!$util.isInteger(message.maximumDeliveryTime))
                        return "maximumDeliveryTime: integer expected";
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (!$util.isInteger(message.minimalDistanceToOrderTarget) && !(message.minimalDistanceToOrderTarget && $util.isInteger(message.minimalDistanceToOrderTarget.low) && $util.isInteger(message.minimalDistanceToOrderTarget.high)))
                        return "minimalDistanceToOrderTarget: integer|Long expected";
                if (message.mapType != null && message.hasOwnProperty("mapType"))
                    switch (message.mapType) {
                    default:
                        return "mapType: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.maximumActiveOrdersOnCourier != null && message.hasOwnProperty("maximumActiveOrdersOnCourier"))
                    if (!$util.isInteger(message.maximumActiveOrdersOnCourier))
                        return "maximumActiveOrdersOnCourier: integer expected";
                return null;
            };

            /**
             * Creates an UpdateCompany message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.UpdateCompany
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.UpdateCompany} UpdateCompany
             */
            UpdateCompany.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.UpdateCompany)
                    return object;
                var message = new $root.fastcity.admin.UpdateCompany();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.description != null)
                    message.description = String(object.description);
                if (object.site != null)
                    message.site = String(object.site);
                if (object.email != null)
                    message.email = String(object.email);
                if (object.supportPhone != null)
                    message.supportPhone = String(object.supportPhone);
                if (object.maximumDeliveryTime != null)
                    message.maximumDeliveryTime = object.maximumDeliveryTime >>> 0;
                if (object.minimalDistanceToOrderTarget != null)
                    if ($util.Long)
                        (message.minimalDistanceToOrderTarget = $util.Long.fromValue(object.minimalDistanceToOrderTarget)).unsigned = true;
                    else if (typeof object.minimalDistanceToOrderTarget === "string")
                        message.minimalDistanceToOrderTarget = parseInt(object.minimalDistanceToOrderTarget, 10);
                    else if (typeof object.minimalDistanceToOrderTarget === "number")
                        message.minimalDistanceToOrderTarget = object.minimalDistanceToOrderTarget;
                    else if (typeof object.minimalDistanceToOrderTarget === "object")
                        message.minimalDistanceToOrderTarget = new $util.LongBits(object.minimalDistanceToOrderTarget.low >>> 0, object.minimalDistanceToOrderTarget.high >>> 0).toNumber(true);
                switch (object.mapType) {
                case "GOOGLE":
                case 0:
                    message.mapType = 0;
                    break;
                case "YANDEX":
                case 1:
                    message.mapType = 1;
                    break;
                }
                if (object.maximumActiveOrdersOnCourier != null)
                    message.maximumActiveOrdersOnCourier = object.maximumActiveOrdersOnCourier >>> 0;
                return message;
            };

            /**
             * Creates a plain object from an UpdateCompany message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.UpdateCompany
             * @static
             * @param {fastcity.admin.UpdateCompany} message UpdateCompany
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateCompany.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.description = "";
                    object.site = "";
                    object.email = "";
                    object.supportPhone = "";
                    object.maximumDeliveryTime = 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.minimalDistanceToOrderTarget = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.minimalDistanceToOrderTarget = options.longs === String ? "0" : 0;
                    object.mapType = options.enums === String ? "GOOGLE" : 0;
                    object.maximumActiveOrdersOnCourier = 0;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.site != null && message.hasOwnProperty("site"))
                    object.site = message.site;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.supportPhone != null && message.hasOwnProperty("supportPhone"))
                    object.supportPhone = message.supportPhone;
                if (message.maximumDeliveryTime != null && message.hasOwnProperty("maximumDeliveryTime"))
                    object.maximumDeliveryTime = message.maximumDeliveryTime;
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (typeof message.minimalDistanceToOrderTarget === "number")
                        object.minimalDistanceToOrderTarget = options.longs === String ? String(message.minimalDistanceToOrderTarget) : message.minimalDistanceToOrderTarget;
                    else
                        object.minimalDistanceToOrderTarget = options.longs === String ? $util.Long.prototype.toString.call(message.minimalDistanceToOrderTarget) : options.longs === Number ? new $util.LongBits(message.minimalDistanceToOrderTarget.low >>> 0, message.minimalDistanceToOrderTarget.high >>> 0).toNumber(true) : message.minimalDistanceToOrderTarget;
                if (message.mapType != null && message.hasOwnProperty("mapType"))
                    object.mapType = options.enums === String ? $root.fastcity.common.Company.MapType[message.mapType] : message.mapType;
                if (message.maximumActiveOrdersOnCourier != null && message.hasOwnProperty("maximumActiveOrdersOnCourier"))
                    object.maximumActiveOrdersOnCourier = message.maximumActiveOrdersOnCourier;
                return object;
            };

            /**
             * Converts this UpdateCompany to JSON.
             * @function toJSON
             * @memberof fastcity.admin.UpdateCompany
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateCompany.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateCompany;
        })();

        admin.DeleteCompany = (function() {

            /**
             * Properties of a DeleteCompany.
             * @memberof fastcity.admin
             * @interface IDeleteCompany
             * @property {number|Long|null} [companyId] DeleteCompany companyId
             */

            /**
             * Constructs a new DeleteCompany.
             * @memberof fastcity.admin
             * @classdesc Represents a DeleteCompany.
             * @implements IDeleteCompany
             * @constructor
             * @param {fastcity.admin.IDeleteCompany=} [properties] Properties to set
             */
            function DeleteCompany(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteCompany companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.DeleteCompany
             * @instance
             */
            DeleteCompany.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new DeleteCompany instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DeleteCompany
             * @static
             * @param {fastcity.admin.IDeleteCompany=} [properties] Properties to set
             * @returns {fastcity.admin.DeleteCompany} DeleteCompany instance
             */
            DeleteCompany.create = function create(properties) {
                return new DeleteCompany(properties);
            };

            /**
             * Encodes the specified DeleteCompany message. Does not implicitly {@link fastcity.admin.DeleteCompany.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DeleteCompany
             * @static
             * @param {fastcity.admin.IDeleteCompany} message DeleteCompany message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteCompany.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                return writer;
            };

            /**
             * Decodes a DeleteCompany message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DeleteCompany
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DeleteCompany} DeleteCompany
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteCompany.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DeleteCompany();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeleteCompany message.
             * @function verify
             * @memberof fastcity.admin.DeleteCompany
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteCompany.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                return null;
            };

            /**
             * Creates a DeleteCompany message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DeleteCompany
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DeleteCompany} DeleteCompany
             */
            DeleteCompany.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DeleteCompany)
                    return object;
                var message = new $root.fastcity.admin.DeleteCompany();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a DeleteCompany message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DeleteCompany
             * @static
             * @param {fastcity.admin.DeleteCompany} message DeleteCompany
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteCompany.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                return object;
            };

            /**
             * Converts this DeleteCompany to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DeleteCompany
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteCompany.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeleteCompany;
        })();

        admin.GetCurrentCompany = (function() {

            /**
             * Properties of a GetCurrentCompany.
             * @memberof fastcity.admin
             * @interface IGetCurrentCompany
             */

            /**
             * Constructs a new GetCurrentCompany.
             * @memberof fastcity.admin
             * @classdesc Represents a GetCurrentCompany.
             * @implements IGetCurrentCompany
             * @constructor
             * @param {fastcity.admin.IGetCurrentCompany=} [properties] Properties to set
             */
            function GetCurrentCompany(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetCurrentCompany instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetCurrentCompany
             * @static
             * @param {fastcity.admin.IGetCurrentCompany=} [properties] Properties to set
             * @returns {fastcity.admin.GetCurrentCompany} GetCurrentCompany instance
             */
            GetCurrentCompany.create = function create(properties) {
                return new GetCurrentCompany(properties);
            };

            /**
             * Encodes the specified GetCurrentCompany message. Does not implicitly {@link fastcity.admin.GetCurrentCompany.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetCurrentCompany
             * @static
             * @param {fastcity.admin.IGetCurrentCompany} message GetCurrentCompany message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCurrentCompany.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetCurrentCompany message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetCurrentCompany
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetCurrentCompany} GetCurrentCompany
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCurrentCompany.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetCurrentCompany();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetCurrentCompany message.
             * @function verify
             * @memberof fastcity.admin.GetCurrentCompany
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetCurrentCompany.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetCurrentCompany message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetCurrentCompany
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetCurrentCompany} GetCurrentCompany
             */
            GetCurrentCompany.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetCurrentCompany)
                    return object;
                return new $root.fastcity.admin.GetCurrentCompany();
            };

            /**
             * Creates a plain object from a GetCurrentCompany message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetCurrentCompany
             * @static
             * @param {fastcity.admin.GetCurrentCompany} message GetCurrentCompany
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCurrentCompany.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetCurrentCompany to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetCurrentCompany
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCurrentCompany.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetCurrentCompany;
        })();

        admin.GetCompanyList = (function() {

            /**
             * Properties of a GetCompanyList.
             * @memberof fastcity.admin
             * @interface IGetCompanyList
             * @property {Array.<number|Long>|null} [companyId] GetCompanyList companyId
             * @property {boolean|null} [withDeleted] GetCompanyList withDeleted
             */

            /**
             * Constructs a new GetCompanyList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetCompanyList.
             * @implements IGetCompanyList
             * @constructor
             * @param {fastcity.admin.IGetCompanyList=} [properties] Properties to set
             */
            function GetCompanyList(properties) {
                this.companyId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetCompanyList companyId.
             * @member {Array.<number|Long>} companyId
             * @memberof fastcity.admin.GetCompanyList
             * @instance
             */
            GetCompanyList.prototype.companyId = $util.emptyArray;

            /**
             * GetCompanyList withDeleted.
             * @member {boolean} withDeleted
             * @memberof fastcity.admin.GetCompanyList
             * @instance
             */
            GetCompanyList.prototype.withDeleted = false;

            /**
             * Creates a new GetCompanyList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetCompanyList
             * @static
             * @param {fastcity.admin.IGetCompanyList=} [properties] Properties to set
             * @returns {fastcity.admin.GetCompanyList} GetCompanyList instance
             */
            GetCompanyList.create = function create(properties) {
                return new GetCompanyList(properties);
            };

            /**
             * Encodes the specified GetCompanyList message. Does not implicitly {@link fastcity.admin.GetCompanyList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetCompanyList
             * @static
             * @param {fastcity.admin.IGetCompanyList} message GetCompanyList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCompanyList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && message.companyId.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.companyId.length; ++i)
                        writer.uint64(message.companyId[i]);
                    writer.ldelim();
                }
                if (message.withDeleted != null && Object.hasOwnProperty.call(message, "withDeleted"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.withDeleted);
                return writer;
            };

            /**
             * Decodes a GetCompanyList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetCompanyList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetCompanyList} GetCompanyList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCompanyList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetCompanyList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.companyId && message.companyId.length))
                            message.companyId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.companyId.push(reader.uint64());
                        } else
                            message.companyId.push(reader.uint64());
                        break;
                    case 2:
                        message.withDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetCompanyList message.
             * @function verify
             * @memberof fastcity.admin.GetCompanyList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetCompanyList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId")) {
                    if (!Array.isArray(message.companyId))
                        return "companyId: array expected";
                    for (var i = 0; i < message.companyId.length; ++i)
                        if (!$util.isInteger(message.companyId[i]) && !(message.companyId[i] && $util.isInteger(message.companyId[i].low) && $util.isInteger(message.companyId[i].high)))
                            return "companyId: integer|Long[] expected";
                }
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    if (typeof message.withDeleted !== "boolean")
                        return "withDeleted: boolean expected";
                return null;
            };

            /**
             * Creates a GetCompanyList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetCompanyList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetCompanyList} GetCompanyList
             */
            GetCompanyList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetCompanyList)
                    return object;
                var message = new $root.fastcity.admin.GetCompanyList();
                if (object.companyId) {
                    if (!Array.isArray(object.companyId))
                        throw TypeError(".fastcity.admin.GetCompanyList.companyId: array expected");
                    message.companyId = [];
                    for (var i = 0; i < object.companyId.length; ++i)
                        if ($util.Long)
                            (message.companyId[i] = $util.Long.fromValue(object.companyId[i])).unsigned = true;
                        else if (typeof object.companyId[i] === "string")
                            message.companyId[i] = parseInt(object.companyId[i], 10);
                        else if (typeof object.companyId[i] === "number")
                            message.companyId[i] = object.companyId[i];
                        else if (typeof object.companyId[i] === "object")
                            message.companyId[i] = new $util.LongBits(object.companyId[i].low >>> 0, object.companyId[i].high >>> 0).toNumber(true);
                }
                if (object.withDeleted != null)
                    message.withDeleted = Boolean(object.withDeleted);
                return message;
            };

            /**
             * Creates a plain object from a GetCompanyList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetCompanyList
             * @static
             * @param {fastcity.admin.GetCompanyList} message GetCompanyList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCompanyList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.companyId = [];
                if (options.defaults)
                    object.withDeleted = false;
                if (message.companyId && message.companyId.length) {
                    object.companyId = [];
                    for (var j = 0; j < message.companyId.length; ++j)
                        if (typeof message.companyId[j] === "number")
                            object.companyId[j] = options.longs === String ? String(message.companyId[j]) : message.companyId[j];
                        else
                            object.companyId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.companyId[j]) : options.longs === Number ? new $util.LongBits(message.companyId[j].low >>> 0, message.companyId[j].high >>> 0).toNumber(true) : message.companyId[j];
                }
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    object.withDeleted = message.withDeleted;
                return object;
            };

            /**
             * Converts this GetCompanyList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetCompanyList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCompanyList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetCompanyList;
        })();

        admin.CompanyList = (function() {

            /**
             * Properties of a CompanyList.
             * @memberof fastcity.admin
             * @interface ICompanyList
             * @property {Array.<fastcity.admin.ICompany>|null} [company] CompanyList company
             */

            /**
             * Constructs a new CompanyList.
             * @memberof fastcity.admin
             * @classdesc Represents a CompanyList.
             * @implements ICompanyList
             * @constructor
             * @param {fastcity.admin.ICompanyList=} [properties] Properties to set
             */
            function CompanyList(properties) {
                this.company = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CompanyList company.
             * @member {Array.<fastcity.admin.ICompany>} company
             * @memberof fastcity.admin.CompanyList
             * @instance
             */
            CompanyList.prototype.company = $util.emptyArray;

            /**
             * Creates a new CompanyList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CompanyList
             * @static
             * @param {fastcity.admin.ICompanyList=} [properties] Properties to set
             * @returns {fastcity.admin.CompanyList} CompanyList instance
             */
            CompanyList.create = function create(properties) {
                return new CompanyList(properties);
            };

            /**
             * Encodes the specified CompanyList message. Does not implicitly {@link fastcity.admin.CompanyList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CompanyList
             * @static
             * @param {fastcity.admin.ICompanyList} message CompanyList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CompanyList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.company != null && message.company.length)
                    for (var i = 0; i < message.company.length; ++i)
                        $root.fastcity.admin.Company.encode(message.company[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a CompanyList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CompanyList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CompanyList} CompanyList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CompanyList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CompanyList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.company && message.company.length))
                            message.company = [];
                        message.company.push($root.fastcity.admin.Company.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CompanyList message.
             * @function verify
             * @memberof fastcity.admin.CompanyList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CompanyList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.company != null && message.hasOwnProperty("company")) {
                    if (!Array.isArray(message.company))
                        return "company: array expected";
                    for (var i = 0; i < message.company.length; ++i) {
                        var error = $root.fastcity.admin.Company.verify(message.company[i]);
                        if (error)
                            return "company." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a CompanyList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CompanyList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CompanyList} CompanyList
             */
            CompanyList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CompanyList)
                    return object;
                var message = new $root.fastcity.admin.CompanyList();
                if (object.company) {
                    if (!Array.isArray(object.company))
                        throw TypeError(".fastcity.admin.CompanyList.company: array expected");
                    message.company = [];
                    for (var i = 0; i < object.company.length; ++i) {
                        if (typeof object.company[i] !== "object")
                            throw TypeError(".fastcity.admin.CompanyList.company: object expected");
                        message.company[i] = $root.fastcity.admin.Company.fromObject(object.company[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a CompanyList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CompanyList
             * @static
             * @param {fastcity.admin.CompanyList} message CompanyList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CompanyList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.company = [];
                if (message.company && message.company.length) {
                    object.company = [];
                    for (var j = 0; j < message.company.length; ++j)
                        object.company[j] = $root.fastcity.admin.Company.toObject(message.company[j], options);
                }
                return object;
            };

            /**
             * Converts this CompanyList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CompanyList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CompanyList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CompanyList;
        })();

        admin.GetExternalSubCompanyIdList = (function() {

            /**
             * Properties of a GetExternalSubCompanyIdList.
             * @memberof fastcity.admin
             * @interface IGetExternalSubCompanyIdList
             * @property {Array.<number|Long>|null} [companyId] GetExternalSubCompanyIdList companyId
             * @property {boolean|null} [withDeleted] GetExternalSubCompanyIdList withDeleted
             */

            /**
             * Constructs a new GetExternalSubCompanyIdList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetExternalSubCompanyIdList.
             * @implements IGetExternalSubCompanyIdList
             * @constructor
             * @param {fastcity.admin.IGetExternalSubCompanyIdList=} [properties] Properties to set
             */
            function GetExternalSubCompanyIdList(properties) {
                this.companyId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetExternalSubCompanyIdList companyId.
             * @member {Array.<number|Long>} companyId
             * @memberof fastcity.admin.GetExternalSubCompanyIdList
             * @instance
             */
            GetExternalSubCompanyIdList.prototype.companyId = $util.emptyArray;

            /**
             * GetExternalSubCompanyIdList withDeleted.
             * @member {boolean} withDeleted
             * @memberof fastcity.admin.GetExternalSubCompanyIdList
             * @instance
             */
            GetExternalSubCompanyIdList.prototype.withDeleted = false;

            /**
             * Creates a new GetExternalSubCompanyIdList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetExternalSubCompanyIdList
             * @static
             * @param {fastcity.admin.IGetExternalSubCompanyIdList=} [properties] Properties to set
             * @returns {fastcity.admin.GetExternalSubCompanyIdList} GetExternalSubCompanyIdList instance
             */
            GetExternalSubCompanyIdList.create = function create(properties) {
                return new GetExternalSubCompanyIdList(properties);
            };

            /**
             * Encodes the specified GetExternalSubCompanyIdList message. Does not implicitly {@link fastcity.admin.GetExternalSubCompanyIdList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetExternalSubCompanyIdList
             * @static
             * @param {fastcity.admin.IGetExternalSubCompanyIdList} message GetExternalSubCompanyIdList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetExternalSubCompanyIdList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && message.companyId.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.companyId.length; ++i)
                        writer.uint64(message.companyId[i]);
                    writer.ldelim();
                }
                if (message.withDeleted != null && Object.hasOwnProperty.call(message, "withDeleted"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.withDeleted);
                return writer;
            };

            /**
             * Decodes a GetExternalSubCompanyIdList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetExternalSubCompanyIdList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetExternalSubCompanyIdList} GetExternalSubCompanyIdList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetExternalSubCompanyIdList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetExternalSubCompanyIdList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.companyId && message.companyId.length))
                            message.companyId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.companyId.push(reader.uint64());
                        } else
                            message.companyId.push(reader.uint64());
                        break;
                    case 2:
                        message.withDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetExternalSubCompanyIdList message.
             * @function verify
             * @memberof fastcity.admin.GetExternalSubCompanyIdList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetExternalSubCompanyIdList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId")) {
                    if (!Array.isArray(message.companyId))
                        return "companyId: array expected";
                    for (var i = 0; i < message.companyId.length; ++i)
                        if (!$util.isInteger(message.companyId[i]) && !(message.companyId[i] && $util.isInteger(message.companyId[i].low) && $util.isInteger(message.companyId[i].high)))
                            return "companyId: integer|Long[] expected";
                }
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    if (typeof message.withDeleted !== "boolean")
                        return "withDeleted: boolean expected";
                return null;
            };

            /**
             * Creates a GetExternalSubCompanyIdList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetExternalSubCompanyIdList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetExternalSubCompanyIdList} GetExternalSubCompanyIdList
             */
            GetExternalSubCompanyIdList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetExternalSubCompanyIdList)
                    return object;
                var message = new $root.fastcity.admin.GetExternalSubCompanyIdList();
                if (object.companyId) {
                    if (!Array.isArray(object.companyId))
                        throw TypeError(".fastcity.admin.GetExternalSubCompanyIdList.companyId: array expected");
                    message.companyId = [];
                    for (var i = 0; i < object.companyId.length; ++i)
                        if ($util.Long)
                            (message.companyId[i] = $util.Long.fromValue(object.companyId[i])).unsigned = true;
                        else if (typeof object.companyId[i] === "string")
                            message.companyId[i] = parseInt(object.companyId[i], 10);
                        else if (typeof object.companyId[i] === "number")
                            message.companyId[i] = object.companyId[i];
                        else if (typeof object.companyId[i] === "object")
                            message.companyId[i] = new $util.LongBits(object.companyId[i].low >>> 0, object.companyId[i].high >>> 0).toNumber(true);
                }
                if (object.withDeleted != null)
                    message.withDeleted = Boolean(object.withDeleted);
                return message;
            };

            /**
             * Creates a plain object from a GetExternalSubCompanyIdList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetExternalSubCompanyIdList
             * @static
             * @param {fastcity.admin.GetExternalSubCompanyIdList} message GetExternalSubCompanyIdList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetExternalSubCompanyIdList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.companyId = [];
                if (options.defaults)
                    object.withDeleted = false;
                if (message.companyId && message.companyId.length) {
                    object.companyId = [];
                    for (var j = 0; j < message.companyId.length; ++j)
                        if (typeof message.companyId[j] === "number")
                            object.companyId[j] = options.longs === String ? String(message.companyId[j]) : message.companyId[j];
                        else
                            object.companyId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.companyId[j]) : options.longs === Number ? new $util.LongBits(message.companyId[j].low >>> 0, message.companyId[j].high >>> 0).toNumber(true) : message.companyId[j];
                }
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    object.withDeleted = message.withDeleted;
                return object;
            };

            /**
             * Converts this GetExternalSubCompanyIdList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetExternalSubCompanyIdList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetExternalSubCompanyIdList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetExternalSubCompanyIdList;
        })();

        admin.ExternalSubCompanyIdList = (function() {

            /**
             * Properties of an ExternalSubCompanyIdList.
             * @memberof fastcity.admin
             * @interface IExternalSubCompanyIdList
             * @property {Array.<fastcity.admin.ExternalSubCompanyIdList.IValue>|null} [value] ExternalSubCompanyIdList value
             */

            /**
             * Constructs a new ExternalSubCompanyIdList.
             * @memberof fastcity.admin
             * @classdesc Represents an ExternalSubCompanyIdList.
             * @implements IExternalSubCompanyIdList
             * @constructor
             * @param {fastcity.admin.IExternalSubCompanyIdList=} [properties] Properties to set
             */
            function ExternalSubCompanyIdList(properties) {
                this.value = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ExternalSubCompanyIdList value.
             * @member {Array.<fastcity.admin.ExternalSubCompanyIdList.IValue>} value
             * @memberof fastcity.admin.ExternalSubCompanyIdList
             * @instance
             */
            ExternalSubCompanyIdList.prototype.value = $util.emptyArray;

            /**
             * Creates a new ExternalSubCompanyIdList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ExternalSubCompanyIdList
             * @static
             * @param {fastcity.admin.IExternalSubCompanyIdList=} [properties] Properties to set
             * @returns {fastcity.admin.ExternalSubCompanyIdList} ExternalSubCompanyIdList instance
             */
            ExternalSubCompanyIdList.create = function create(properties) {
                return new ExternalSubCompanyIdList(properties);
            };

            /**
             * Encodes the specified ExternalSubCompanyIdList message. Does not implicitly {@link fastcity.admin.ExternalSubCompanyIdList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ExternalSubCompanyIdList
             * @static
             * @param {fastcity.admin.IExternalSubCompanyIdList} message ExternalSubCompanyIdList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ExternalSubCompanyIdList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && message.value.length)
                    for (var i = 0; i < message.value.length; ++i)
                        $root.fastcity.admin.ExternalSubCompanyIdList.Value.encode(message.value[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an ExternalSubCompanyIdList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ExternalSubCompanyIdList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ExternalSubCompanyIdList} ExternalSubCompanyIdList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ExternalSubCompanyIdList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ExternalSubCompanyIdList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.value && message.value.length))
                            message.value = [];
                        message.value.push($root.fastcity.admin.ExternalSubCompanyIdList.Value.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an ExternalSubCompanyIdList message.
             * @function verify
             * @memberof fastcity.admin.ExternalSubCompanyIdList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ExternalSubCompanyIdList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value")) {
                    if (!Array.isArray(message.value))
                        return "value: array expected";
                    for (var i = 0; i < message.value.length; ++i) {
                        var error = $root.fastcity.admin.ExternalSubCompanyIdList.Value.verify(message.value[i]);
                        if (error)
                            return "value." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an ExternalSubCompanyIdList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ExternalSubCompanyIdList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ExternalSubCompanyIdList} ExternalSubCompanyIdList
             */
            ExternalSubCompanyIdList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ExternalSubCompanyIdList)
                    return object;
                var message = new $root.fastcity.admin.ExternalSubCompanyIdList();
                if (object.value) {
                    if (!Array.isArray(object.value))
                        throw TypeError(".fastcity.admin.ExternalSubCompanyIdList.value: array expected");
                    message.value = [];
                    for (var i = 0; i < object.value.length; ++i) {
                        if (typeof object.value[i] !== "object")
                            throw TypeError(".fastcity.admin.ExternalSubCompanyIdList.value: object expected");
                        message.value[i] = $root.fastcity.admin.ExternalSubCompanyIdList.Value.fromObject(object.value[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an ExternalSubCompanyIdList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ExternalSubCompanyIdList
             * @static
             * @param {fastcity.admin.ExternalSubCompanyIdList} message ExternalSubCompanyIdList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ExternalSubCompanyIdList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.value = [];
                if (message.value && message.value.length) {
                    object.value = [];
                    for (var j = 0; j < message.value.length; ++j)
                        object.value[j] = $root.fastcity.admin.ExternalSubCompanyIdList.Value.toObject(message.value[j], options);
                }
                return object;
            };

            /**
             * Converts this ExternalSubCompanyIdList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ExternalSubCompanyIdList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ExternalSubCompanyIdList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            ExternalSubCompanyIdList.Value = (function() {

                /**
                 * Properties of a Value.
                 * @memberof fastcity.admin.ExternalSubCompanyIdList
                 * @interface IValue
                 * @property {number|Long|null} [companyId] Value companyId
                 * @property {string|null} [externalSubCompanyId] Value externalSubCompanyId
                 */

                /**
                 * Constructs a new Value.
                 * @memberof fastcity.admin.ExternalSubCompanyIdList
                 * @classdesc Represents a Value.
                 * @implements IValue
                 * @constructor
                 * @param {fastcity.admin.ExternalSubCompanyIdList.IValue=} [properties] Properties to set
                 */
                function Value(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Value companyId.
                 * @member {number|Long} companyId
                 * @memberof fastcity.admin.ExternalSubCompanyIdList.Value
                 * @instance
                 */
                Value.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * Value externalSubCompanyId.
                 * @member {string} externalSubCompanyId
                 * @memberof fastcity.admin.ExternalSubCompanyIdList.Value
                 * @instance
                 */
                Value.prototype.externalSubCompanyId = "";

                /**
                 * Creates a new Value instance using the specified properties.
                 * @function create
                 * @memberof fastcity.admin.ExternalSubCompanyIdList.Value
                 * @static
                 * @param {fastcity.admin.ExternalSubCompanyIdList.IValue=} [properties] Properties to set
                 * @returns {fastcity.admin.ExternalSubCompanyIdList.Value} Value instance
                 */
                Value.create = function create(properties) {
                    return new Value(properties);
                };

                /**
                 * Encodes the specified Value message. Does not implicitly {@link fastcity.admin.ExternalSubCompanyIdList.Value.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.admin.ExternalSubCompanyIdList.Value
                 * @static
                 * @param {fastcity.admin.ExternalSubCompanyIdList.IValue} message Value message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Value.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                    if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalSubCompanyId);
                    return writer;
                };

                /**
                 * Decodes a Value message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.admin.ExternalSubCompanyIdList.Value
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.admin.ExternalSubCompanyIdList.Value} Value
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Value.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ExternalSubCompanyIdList.Value();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.companyId = reader.uint64();
                            break;
                        case 2:
                            message.externalSubCompanyId = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a Value message.
                 * @function verify
                 * @memberof fastcity.admin.ExternalSubCompanyIdList.Value
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Value.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                            return "companyId: integer|Long expected";
                    if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                        if (!$util.isString(message.externalSubCompanyId))
                            return "externalSubCompanyId: string expected";
                    return null;
                };

                /**
                 * Creates a Value message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.admin.ExternalSubCompanyIdList.Value
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.admin.ExternalSubCompanyIdList.Value} Value
                 */
                Value.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.admin.ExternalSubCompanyIdList.Value)
                        return object;
                    var message = new $root.fastcity.admin.ExternalSubCompanyIdList.Value();
                    if (object.companyId != null)
                        if ($util.Long)
                            (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                        else if (typeof object.companyId === "string")
                            message.companyId = parseInt(object.companyId, 10);
                        else if (typeof object.companyId === "number")
                            message.companyId = object.companyId;
                        else if (typeof object.companyId === "object")
                            message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                    if (object.externalSubCompanyId != null)
                        message.externalSubCompanyId = String(object.externalSubCompanyId);
                    return message;
                };

                /**
                 * Creates a plain object from a Value message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.admin.ExternalSubCompanyIdList.Value
                 * @static
                 * @param {fastcity.admin.ExternalSubCompanyIdList.Value} message Value
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Value.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.companyId = options.longs === String ? "0" : 0;
                        object.externalSubCompanyId = "";
                    }
                    if (message.companyId != null && message.hasOwnProperty("companyId"))
                        if (typeof message.companyId === "number")
                            object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                        else
                            object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                    if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                        object.externalSubCompanyId = message.externalSubCompanyId;
                    return object;
                };

                /**
                 * Converts this Value to JSON.
                 * @function toJSON
                 * @memberof fastcity.admin.ExternalSubCompanyIdList.Value
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Value.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Value;
            })();

            return ExternalSubCompanyIdList;
        })();

        admin.ConnectionStatus = (function() {

            /**
             * Properties of a ConnectionStatus.
             * @memberof fastcity.admin
             * @interface IConnectionStatus
             * @property {number|Long|null} [companyId] ConnectionStatus companyId
             * @property {string|null} [externalSubCompanyId] ConnectionStatus externalSubCompanyId
             * @property {fastcity.admin.ConnectionStatus.Status|null} [status] ConnectionStatus status
             * @property {fastcity.admin.ConnectionStatus.ILauncher|null} [launcher] ConnectionStatus launcher
             * @property {fastcity.admin.ConnectionStatus.IConnector|null} [connector] ConnectionStatus connector
             */

            /**
             * Constructs a new ConnectionStatus.
             * @memberof fastcity.admin
             * @classdesc Represents a ConnectionStatus.
             * @implements IConnectionStatus
             * @constructor
             * @param {fastcity.admin.IConnectionStatus=} [properties] Properties to set
             */
            function ConnectionStatus(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ConnectionStatus companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.ConnectionStatus
             * @instance
             */
            ConnectionStatus.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ConnectionStatus externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.ConnectionStatus
             * @instance
             */
            ConnectionStatus.prototype.externalSubCompanyId = "";

            /**
             * ConnectionStatus status.
             * @member {fastcity.admin.ConnectionStatus.Status} status
             * @memberof fastcity.admin.ConnectionStatus
             * @instance
             */
            ConnectionStatus.prototype.status = 0;

            /**
             * ConnectionStatus launcher.
             * @member {fastcity.admin.ConnectionStatus.ILauncher|null|undefined} launcher
             * @memberof fastcity.admin.ConnectionStatus
             * @instance
             */
            ConnectionStatus.prototype.launcher = null;

            /**
             * ConnectionStatus connector.
             * @member {fastcity.admin.ConnectionStatus.IConnector|null|undefined} connector
             * @memberof fastcity.admin.ConnectionStatus
             * @instance
             */
            ConnectionStatus.prototype.connector = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * ConnectionStatus type.
             * @member {"launcher"|"connector"|undefined} type
             * @memberof fastcity.admin.ConnectionStatus
             * @instance
             */
            Object.defineProperty(ConnectionStatus.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["launcher", "connector"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new ConnectionStatus instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ConnectionStatus
             * @static
             * @param {fastcity.admin.IConnectionStatus=} [properties] Properties to set
             * @returns {fastcity.admin.ConnectionStatus} ConnectionStatus instance
             */
            ConnectionStatus.create = function create(properties) {
                return new ConnectionStatus(properties);
            };

            /**
             * Encodes the specified ConnectionStatus message. Does not implicitly {@link fastcity.admin.ConnectionStatus.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ConnectionStatus
             * @static
             * @param {fastcity.admin.IConnectionStatus} message ConnectionStatus message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConnectionStatus.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalSubCompanyId);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.status);
                if (message.launcher != null && Object.hasOwnProperty.call(message, "launcher"))
                    $root.fastcity.admin.ConnectionStatus.Launcher.encode(message.launcher, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.connector != null && Object.hasOwnProperty.call(message, "connector"))
                    $root.fastcity.admin.ConnectionStatus.Connector.encode(message.connector, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ConnectionStatus message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ConnectionStatus
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ConnectionStatus} ConnectionStatus
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConnectionStatus.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ConnectionStatus();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.externalSubCompanyId = reader.string();
                        break;
                    case 3:
                        message.status = reader.int32();
                        break;
                    case 4:
                        message.launcher = $root.fastcity.admin.ConnectionStatus.Launcher.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.connector = $root.fastcity.admin.ConnectionStatus.Connector.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ConnectionStatus message.
             * @function verify
             * @memberof fastcity.admin.ConnectionStatus
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ConnectionStatus.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.launcher != null && message.hasOwnProperty("launcher")) {
                    properties.type = 1;
                    {
                        var error = $root.fastcity.admin.ConnectionStatus.Launcher.verify(message.launcher);
                        if (error)
                            return "launcher." + error;
                    }
                }
                if (message.connector != null && message.hasOwnProperty("connector")) {
                    if (properties.type === 1)
                        return "type: multiple values";
                    properties.type = 1;
                    {
                        var error = $root.fastcity.admin.ConnectionStatus.Connector.verify(message.connector);
                        if (error)
                            return "connector." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ConnectionStatus message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ConnectionStatus
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ConnectionStatus} ConnectionStatus
             */
            ConnectionStatus.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ConnectionStatus)
                    return object;
                var message = new $root.fastcity.admin.ConnectionStatus();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                switch (object.status) {
                case "CONNECTED":
                case 0:
                    message.status = 0;
                    break;
                case "DISCONNECTED":
                case 1:
                    message.status = 1;
                    break;
                }
                if (object.launcher != null) {
                    if (typeof object.launcher !== "object")
                        throw TypeError(".fastcity.admin.ConnectionStatus.launcher: object expected");
                    message.launcher = $root.fastcity.admin.ConnectionStatus.Launcher.fromObject(object.launcher);
                }
                if (object.connector != null) {
                    if (typeof object.connector !== "object")
                        throw TypeError(".fastcity.admin.ConnectionStatus.connector: object expected");
                    message.connector = $root.fastcity.admin.ConnectionStatus.Connector.fromObject(object.connector);
                }
                return message;
            };

            /**
             * Creates a plain object from a ConnectionStatus message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ConnectionStatus
             * @static
             * @param {fastcity.admin.ConnectionStatus} message ConnectionStatus
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConnectionStatus.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                    object.status = options.enums === String ? "CONNECTED" : 0;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.fastcity.admin.ConnectionStatus.Status[message.status] : message.status;
                if (message.launcher != null && message.hasOwnProperty("launcher")) {
                    object.launcher = $root.fastcity.admin.ConnectionStatus.Launcher.toObject(message.launcher, options);
                    if (options.oneofs)
                        object.type = "launcher";
                }
                if (message.connector != null && message.hasOwnProperty("connector")) {
                    object.connector = $root.fastcity.admin.ConnectionStatus.Connector.toObject(message.connector, options);
                    if (options.oneofs)
                        object.type = "connector";
                }
                return object;
            };

            /**
             * Converts this ConnectionStatus to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ConnectionStatus
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConnectionStatus.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            ConnectionStatus.Launcher = (function() {

                /**
                 * Properties of a Launcher.
                 * @memberof fastcity.admin.ConnectionStatus
                 * @interface ILauncher
                 * @property {fastcity.common.IVersion|null} [launcherVersion] Launcher launcherVersion
                 * @property {fastcity.common.IVersion|null} [connectorVersion] Launcher connectorVersion
                 */

                /**
                 * Constructs a new Launcher.
                 * @memberof fastcity.admin.ConnectionStatus
                 * @classdesc Represents a Launcher.
                 * @implements ILauncher
                 * @constructor
                 * @param {fastcity.admin.ConnectionStatus.ILauncher=} [properties] Properties to set
                 */
                function Launcher(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Launcher launcherVersion.
                 * @member {fastcity.common.IVersion|null|undefined} launcherVersion
                 * @memberof fastcity.admin.ConnectionStatus.Launcher
                 * @instance
                 */
                Launcher.prototype.launcherVersion = null;

                /**
                 * Launcher connectorVersion.
                 * @member {fastcity.common.IVersion|null|undefined} connectorVersion
                 * @memberof fastcity.admin.ConnectionStatus.Launcher
                 * @instance
                 */
                Launcher.prototype.connectorVersion = null;

                /**
                 * Creates a new Launcher instance using the specified properties.
                 * @function create
                 * @memberof fastcity.admin.ConnectionStatus.Launcher
                 * @static
                 * @param {fastcity.admin.ConnectionStatus.ILauncher=} [properties] Properties to set
                 * @returns {fastcity.admin.ConnectionStatus.Launcher} Launcher instance
                 */
                Launcher.create = function create(properties) {
                    return new Launcher(properties);
                };

                /**
                 * Encodes the specified Launcher message. Does not implicitly {@link fastcity.admin.ConnectionStatus.Launcher.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.admin.ConnectionStatus.Launcher
                 * @static
                 * @param {fastcity.admin.ConnectionStatus.ILauncher} message Launcher message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Launcher.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.launcherVersion != null && Object.hasOwnProperty.call(message, "launcherVersion"))
                        $root.fastcity.common.Version.encode(message.launcherVersion, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.connectorVersion != null && Object.hasOwnProperty.call(message, "connectorVersion"))
                        $root.fastcity.common.Version.encode(message.connectorVersion, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Decodes a Launcher message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.admin.ConnectionStatus.Launcher
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.admin.ConnectionStatus.Launcher} Launcher
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Launcher.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ConnectionStatus.Launcher();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.launcherVersion = $root.fastcity.common.Version.decode(reader, reader.uint32());
                            break;
                        case 2:
                            message.connectorVersion = $root.fastcity.common.Version.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a Launcher message.
                 * @function verify
                 * @memberof fastcity.admin.ConnectionStatus.Launcher
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Launcher.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.launcherVersion != null && message.hasOwnProperty("launcherVersion")) {
                        var error = $root.fastcity.common.Version.verify(message.launcherVersion);
                        if (error)
                            return "launcherVersion." + error;
                    }
                    if (message.connectorVersion != null && message.hasOwnProperty("connectorVersion")) {
                        var error = $root.fastcity.common.Version.verify(message.connectorVersion);
                        if (error)
                            return "connectorVersion." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Launcher message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.admin.ConnectionStatus.Launcher
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.admin.ConnectionStatus.Launcher} Launcher
                 */
                Launcher.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.admin.ConnectionStatus.Launcher)
                        return object;
                    var message = new $root.fastcity.admin.ConnectionStatus.Launcher();
                    if (object.launcherVersion != null) {
                        if (typeof object.launcherVersion !== "object")
                            throw TypeError(".fastcity.admin.ConnectionStatus.Launcher.launcherVersion: object expected");
                        message.launcherVersion = $root.fastcity.common.Version.fromObject(object.launcherVersion);
                    }
                    if (object.connectorVersion != null) {
                        if (typeof object.connectorVersion !== "object")
                            throw TypeError(".fastcity.admin.ConnectionStatus.Launcher.connectorVersion: object expected");
                        message.connectorVersion = $root.fastcity.common.Version.fromObject(object.connectorVersion);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Launcher message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.admin.ConnectionStatus.Launcher
                 * @static
                 * @param {fastcity.admin.ConnectionStatus.Launcher} message Launcher
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Launcher.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.launcherVersion = null;
                        object.connectorVersion = null;
                    }
                    if (message.launcherVersion != null && message.hasOwnProperty("launcherVersion"))
                        object.launcherVersion = $root.fastcity.common.Version.toObject(message.launcherVersion, options);
                    if (message.connectorVersion != null && message.hasOwnProperty("connectorVersion"))
                        object.connectorVersion = $root.fastcity.common.Version.toObject(message.connectorVersion, options);
                    return object;
                };

                /**
                 * Converts this Launcher to JSON.
                 * @function toJSON
                 * @memberof fastcity.admin.ConnectionStatus.Launcher
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Launcher.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Launcher;
            })();

            ConnectionStatus.Connector = (function() {

                /**
                 * Properties of a Connector.
                 * @memberof fastcity.admin.ConnectionStatus
                 * @interface IConnector
                 * @property {fastcity.common.IVersion|null} [version] Connector version
                 */

                /**
                 * Constructs a new Connector.
                 * @memberof fastcity.admin.ConnectionStatus
                 * @classdesc Represents a Connector.
                 * @implements IConnector
                 * @constructor
                 * @param {fastcity.admin.ConnectionStatus.IConnector=} [properties] Properties to set
                 */
                function Connector(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Connector version.
                 * @member {fastcity.common.IVersion|null|undefined} version
                 * @memberof fastcity.admin.ConnectionStatus.Connector
                 * @instance
                 */
                Connector.prototype.version = null;

                /**
                 * Creates a new Connector instance using the specified properties.
                 * @function create
                 * @memberof fastcity.admin.ConnectionStatus.Connector
                 * @static
                 * @param {fastcity.admin.ConnectionStatus.IConnector=} [properties] Properties to set
                 * @returns {fastcity.admin.ConnectionStatus.Connector} Connector instance
                 */
                Connector.create = function create(properties) {
                    return new Connector(properties);
                };

                /**
                 * Encodes the specified Connector message. Does not implicitly {@link fastcity.admin.ConnectionStatus.Connector.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.admin.ConnectionStatus.Connector
                 * @static
                 * @param {fastcity.admin.ConnectionStatus.IConnector} message Connector message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Connector.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                        $root.fastcity.common.Version.encode(message.version, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Decodes a Connector message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.admin.ConnectionStatus.Connector
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.admin.ConnectionStatus.Connector} Connector
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Connector.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ConnectionStatus.Connector();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.version = $root.fastcity.common.Version.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a Connector message.
                 * @function verify
                 * @memberof fastcity.admin.ConnectionStatus.Connector
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Connector.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.version != null && message.hasOwnProperty("version")) {
                        var error = $root.fastcity.common.Version.verify(message.version);
                        if (error)
                            return "version." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Connector message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.admin.ConnectionStatus.Connector
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.admin.ConnectionStatus.Connector} Connector
                 */
                Connector.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.admin.ConnectionStatus.Connector)
                        return object;
                    var message = new $root.fastcity.admin.ConnectionStatus.Connector();
                    if (object.version != null) {
                        if (typeof object.version !== "object")
                            throw TypeError(".fastcity.admin.ConnectionStatus.Connector.version: object expected");
                        message.version = $root.fastcity.common.Version.fromObject(object.version);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Connector message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.admin.ConnectionStatus.Connector
                 * @static
                 * @param {fastcity.admin.ConnectionStatus.Connector} message Connector
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Connector.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.version = null;
                    if (message.version != null && message.hasOwnProperty("version"))
                        object.version = $root.fastcity.common.Version.toObject(message.version, options);
                    return object;
                };

                /**
                 * Converts this Connector to JSON.
                 * @function toJSON
                 * @memberof fastcity.admin.ConnectionStatus.Connector
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Connector.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Connector;
            })();

            /**
             * Status enum.
             * @name fastcity.admin.ConnectionStatus.Status
             * @enum {number}
             * @property {number} CONNECTED=0 CONNECTED value
             * @property {number} DISCONNECTED=1 DISCONNECTED value
             */
            ConnectionStatus.Status = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "CONNECTED"] = 0;
                values[valuesById[1] = "DISCONNECTED"] = 1;
                return values;
            })();

            return ConnectionStatus;
        })();

        admin.ConnectionStatusList = (function() {

            /**
             * Properties of a ConnectionStatusList.
             * @memberof fastcity.admin
             * @interface IConnectionStatusList
             * @property {Array.<fastcity.admin.IConnectionStatus>|null} [connectionStatus] ConnectionStatusList connectionStatus
             */

            /**
             * Constructs a new ConnectionStatusList.
             * @memberof fastcity.admin
             * @classdesc Represents a ConnectionStatusList.
             * @implements IConnectionStatusList
             * @constructor
             * @param {fastcity.admin.IConnectionStatusList=} [properties] Properties to set
             */
            function ConnectionStatusList(properties) {
                this.connectionStatus = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ConnectionStatusList connectionStatus.
             * @member {Array.<fastcity.admin.IConnectionStatus>} connectionStatus
             * @memberof fastcity.admin.ConnectionStatusList
             * @instance
             */
            ConnectionStatusList.prototype.connectionStatus = $util.emptyArray;

            /**
             * Creates a new ConnectionStatusList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ConnectionStatusList
             * @static
             * @param {fastcity.admin.IConnectionStatusList=} [properties] Properties to set
             * @returns {fastcity.admin.ConnectionStatusList} ConnectionStatusList instance
             */
            ConnectionStatusList.create = function create(properties) {
                return new ConnectionStatusList(properties);
            };

            /**
             * Encodes the specified ConnectionStatusList message. Does not implicitly {@link fastcity.admin.ConnectionStatusList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ConnectionStatusList
             * @static
             * @param {fastcity.admin.IConnectionStatusList} message ConnectionStatusList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConnectionStatusList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.connectionStatus != null && message.connectionStatus.length)
                    for (var i = 0; i < message.connectionStatus.length; ++i)
                        $root.fastcity.admin.ConnectionStatus.encode(message.connectionStatus[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ConnectionStatusList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ConnectionStatusList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ConnectionStatusList} ConnectionStatusList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConnectionStatusList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ConnectionStatusList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.connectionStatus && message.connectionStatus.length))
                            message.connectionStatus = [];
                        message.connectionStatus.push($root.fastcity.admin.ConnectionStatus.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ConnectionStatusList message.
             * @function verify
             * @memberof fastcity.admin.ConnectionStatusList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ConnectionStatusList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.connectionStatus != null && message.hasOwnProperty("connectionStatus")) {
                    if (!Array.isArray(message.connectionStatus))
                        return "connectionStatus: array expected";
                    for (var i = 0; i < message.connectionStatus.length; ++i) {
                        var error = $root.fastcity.admin.ConnectionStatus.verify(message.connectionStatus[i]);
                        if (error)
                            return "connectionStatus." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ConnectionStatusList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ConnectionStatusList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ConnectionStatusList} ConnectionStatusList
             */
            ConnectionStatusList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ConnectionStatusList)
                    return object;
                var message = new $root.fastcity.admin.ConnectionStatusList();
                if (object.connectionStatus) {
                    if (!Array.isArray(object.connectionStatus))
                        throw TypeError(".fastcity.admin.ConnectionStatusList.connectionStatus: array expected");
                    message.connectionStatus = [];
                    for (var i = 0; i < object.connectionStatus.length; ++i) {
                        if (typeof object.connectionStatus[i] !== "object")
                            throw TypeError(".fastcity.admin.ConnectionStatusList.connectionStatus: object expected");
                        message.connectionStatus[i] = $root.fastcity.admin.ConnectionStatus.fromObject(object.connectionStatus[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a ConnectionStatusList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ConnectionStatusList
             * @static
             * @param {fastcity.admin.ConnectionStatusList} message ConnectionStatusList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConnectionStatusList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.connectionStatus = [];
                if (message.connectionStatus && message.connectionStatus.length) {
                    object.connectionStatus = [];
                    for (var j = 0; j < message.connectionStatus.length; ++j)
                        object.connectionStatus[j] = $root.fastcity.admin.ConnectionStatus.toObject(message.connectionStatus[j], options);
                }
                return object;
            };

            /**
             * Converts this ConnectionStatusList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ConnectionStatusList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConnectionStatusList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ConnectionStatusList;
        })();

        admin.GetConnectionStatusList = (function() {

            /**
             * Properties of a GetConnectionStatusList.
             * @memberof fastcity.admin
             * @interface IGetConnectionStatusList
             */

            /**
             * Constructs a new GetConnectionStatusList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetConnectionStatusList.
             * @implements IGetConnectionStatusList
             * @constructor
             * @param {fastcity.admin.IGetConnectionStatusList=} [properties] Properties to set
             */
            function GetConnectionStatusList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetConnectionStatusList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetConnectionStatusList
             * @static
             * @param {fastcity.admin.IGetConnectionStatusList=} [properties] Properties to set
             * @returns {fastcity.admin.GetConnectionStatusList} GetConnectionStatusList instance
             */
            GetConnectionStatusList.create = function create(properties) {
                return new GetConnectionStatusList(properties);
            };

            /**
             * Encodes the specified GetConnectionStatusList message. Does not implicitly {@link fastcity.admin.GetConnectionStatusList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetConnectionStatusList
             * @static
             * @param {fastcity.admin.IGetConnectionStatusList} message GetConnectionStatusList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetConnectionStatusList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetConnectionStatusList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetConnectionStatusList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetConnectionStatusList} GetConnectionStatusList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetConnectionStatusList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetConnectionStatusList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetConnectionStatusList message.
             * @function verify
             * @memberof fastcity.admin.GetConnectionStatusList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetConnectionStatusList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetConnectionStatusList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetConnectionStatusList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetConnectionStatusList} GetConnectionStatusList
             */
            GetConnectionStatusList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetConnectionStatusList)
                    return object;
                return new $root.fastcity.admin.GetConnectionStatusList();
            };

            /**
             * Creates a plain object from a GetConnectionStatusList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetConnectionStatusList
             * @static
             * @param {fastcity.admin.GetConnectionStatusList} message GetConnectionStatusList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetConnectionStatusList.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetConnectionStatusList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetConnectionStatusList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetConnectionStatusList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetConnectionStatusList;
        })();

        admin.Courier = (function() {

            /**
             * Properties of a Courier.
             * @memberof fastcity.admin
             * @interface ICourier
             * @property {number|Long|null} [courierId] Courier courierId
             * @property {string|null} [externalCourierId] Courier externalCourierId
             * @property {number|Long|null} [companyId] Courier companyId
             * @property {string|null} [externalSubCompanyId] Courier externalSubCompanyId
             * @property {string|null} [name] Courier name
             * @property {string|null} [phone] Courier phone
             * @property {number|Long|null} [groupId] Courier groupId
             * @property {number|Long|null} [timestamp] Courier timestamp
             * @property {boolean|null} [isSynchronized] Courier isSynchronized
             * @property {boolean|null} [isDeleted] Courier isDeleted
             * @property {boolean|null} [isConnected] Courier isConnected
             * @property {number|Long|null} [minimalDistanceToOrderTarget] Courier minimalDistanceToOrderTarget
             * @property {boolean|null} [useDifferentColorsForAllOrders] Courier useDifferentColorsForAllOrders
             */

            /**
             * Constructs a new Courier.
             * @memberof fastcity.admin
             * @classdesc Represents a Courier.
             * @implements ICourier
             * @constructor
             * @param {fastcity.admin.ICourier=} [properties] Properties to set
             */
            function Courier(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Courier courierId.
             * @member {number|Long} courierId
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.courierId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Courier externalCourierId.
             * @member {string} externalCourierId
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.externalCourierId = "";

            /**
             * Courier companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Courier externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.externalSubCompanyId = "";

            /**
             * Courier name.
             * @member {string} name
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.name = "";

            /**
             * Courier phone.
             * @member {string} phone
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.phone = "";

            /**
             * Courier groupId.
             * @member {number|Long} groupId
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.groupId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Courier timestamp.
             * @member {number|Long} timestamp
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Courier isSynchronized.
             * @member {boolean} isSynchronized
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.isSynchronized = false;

            /**
             * Courier isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.isDeleted = false;

            /**
             * Courier isConnected.
             * @member {boolean} isConnected
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.isConnected = false;

            /**
             * Courier minimalDistanceToOrderTarget.
             * @member {number|Long} minimalDistanceToOrderTarget
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.minimalDistanceToOrderTarget = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Courier useDifferentColorsForAllOrders.
             * @member {boolean} useDifferentColorsForAllOrders
             * @memberof fastcity.admin.Courier
             * @instance
             */
            Courier.prototype.useDifferentColorsForAllOrders = false;

            /**
             * Creates a new Courier instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.Courier
             * @static
             * @param {fastcity.admin.ICourier=} [properties] Properties to set
             * @returns {fastcity.admin.Courier} Courier instance
             */
            Courier.create = function create(properties) {
                return new Courier(properties);
            };

            /**
             * Encodes the specified Courier message. Does not implicitly {@link fastcity.admin.Courier.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.Courier
             * @static
             * @param {fastcity.admin.ICourier} message Courier message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Courier.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && Object.hasOwnProperty.call(message, "courierId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.courierId);
                if (message.externalCourierId != null && Object.hasOwnProperty.call(message, "externalCourierId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalCourierId);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.externalSubCompanyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
                if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.phone);
                if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                    writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.groupId);
                if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int64(message.timestamp);
                if (message.isSynchronized != null && Object.hasOwnProperty.call(message, "isSynchronized"))
                    writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isSynchronized);
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isDeleted);
                if (message.isConnected != null && Object.hasOwnProperty.call(message, "isConnected"))
                    writer.uint32(/* id 11, wireType 0 =*/88).bool(message.isConnected);
                if (message.minimalDistanceToOrderTarget != null && Object.hasOwnProperty.call(message, "minimalDistanceToOrderTarget"))
                    writer.uint32(/* id 12, wireType 0 =*/96).uint64(message.minimalDistanceToOrderTarget);
                if (message.useDifferentColorsForAllOrders != null && Object.hasOwnProperty.call(message, "useDifferentColorsForAllOrders"))
                    writer.uint32(/* id 13, wireType 0 =*/104).bool(message.useDifferentColorsForAllOrders);
                return writer;
            };

            /**
             * Decodes a Courier message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.Courier
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.Courier} Courier
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Courier.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.Courier();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.courierId = reader.uint64();
                        break;
                    case 2:
                        message.externalCourierId = reader.string();
                        break;
                    case 3:
                        message.companyId = reader.uint64();
                        break;
                    case 4:
                        message.externalSubCompanyId = reader.string();
                        break;
                    case 5:
                        message.name = reader.string();
                        break;
                    case 6:
                        message.phone = reader.string();
                        break;
                    case 7:
                        message.groupId = reader.uint64();
                        break;
                    case 8:
                        message.timestamp = reader.int64();
                        break;
                    case 9:
                        message.isSynchronized = reader.bool();
                        break;
                    case 10:
                        message.isDeleted = reader.bool();
                        break;
                    case 11:
                        message.isConnected = reader.bool();
                        break;
                    case 12:
                        message.minimalDistanceToOrderTarget = reader.uint64();
                        break;
                    case 13:
                        message.useDifferentColorsForAllOrders = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Courier message.
             * @function verify
             * @memberof fastcity.admin.Courier
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Courier.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (!$util.isInteger(message.courierId) && !(message.courierId && $util.isInteger(message.courierId.low) && $util.isInteger(message.courierId.high)))
                        return "courierId: integer|Long expected";
                if (message.externalCourierId != null && message.hasOwnProperty("externalCourierId"))
                    if (!$util.isString(message.externalCourierId))
                        return "externalCourierId: string expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.phone != null && message.hasOwnProperty("phone"))
                    if (!$util.isString(message.phone))
                        return "phone: string expected";
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (!$util.isInteger(message.groupId) && !(message.groupId && $util.isInteger(message.groupId.low) && $util.isInteger(message.groupId.high)))
                        return "groupId: integer|Long expected";
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                        return "timestamp: integer|Long expected";
                if (message.isSynchronized != null && message.hasOwnProperty("isSynchronized"))
                    if (typeof message.isSynchronized !== "boolean")
                        return "isSynchronized: boolean expected";
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                if (message.isConnected != null && message.hasOwnProperty("isConnected"))
                    if (typeof message.isConnected !== "boolean")
                        return "isConnected: boolean expected";
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (!$util.isInteger(message.minimalDistanceToOrderTarget) && !(message.minimalDistanceToOrderTarget && $util.isInteger(message.minimalDistanceToOrderTarget.low) && $util.isInteger(message.minimalDistanceToOrderTarget.high)))
                        return "minimalDistanceToOrderTarget: integer|Long expected";
                if (message.useDifferentColorsForAllOrders != null && message.hasOwnProperty("useDifferentColorsForAllOrders"))
                    if (typeof message.useDifferentColorsForAllOrders !== "boolean")
                        return "useDifferentColorsForAllOrders: boolean expected";
                return null;
            };

            /**
             * Creates a Courier message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.Courier
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.Courier} Courier
             */
            Courier.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.Courier)
                    return object;
                var message = new $root.fastcity.admin.Courier();
                if (object.courierId != null)
                    if ($util.Long)
                        (message.courierId = $util.Long.fromValue(object.courierId)).unsigned = true;
                    else if (typeof object.courierId === "string")
                        message.courierId = parseInt(object.courierId, 10);
                    else if (typeof object.courierId === "number")
                        message.courierId = object.courierId;
                    else if (typeof object.courierId === "object")
                        message.courierId = new $util.LongBits(object.courierId.low >>> 0, object.courierId.high >>> 0).toNumber(true);
                if (object.externalCourierId != null)
                    message.externalCourierId = String(object.externalCourierId);
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.phone != null)
                    message.phone = String(object.phone);
                if (object.groupId != null)
                    if ($util.Long)
                        (message.groupId = $util.Long.fromValue(object.groupId)).unsigned = true;
                    else if (typeof object.groupId === "string")
                        message.groupId = parseInt(object.groupId, 10);
                    else if (typeof object.groupId === "number")
                        message.groupId = object.groupId;
                    else if (typeof object.groupId === "object")
                        message.groupId = new $util.LongBits(object.groupId.low >>> 0, object.groupId.high >>> 0).toNumber(true);
                if (object.timestamp != null)
                    if ($util.Long)
                        (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                    else if (typeof object.timestamp === "string")
                        message.timestamp = parseInt(object.timestamp, 10);
                    else if (typeof object.timestamp === "number")
                        message.timestamp = object.timestamp;
                    else if (typeof object.timestamp === "object")
                        message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
                if (object.isSynchronized != null)
                    message.isSynchronized = Boolean(object.isSynchronized);
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                if (object.isConnected != null)
                    message.isConnected = Boolean(object.isConnected);
                if (object.minimalDistanceToOrderTarget != null)
                    if ($util.Long)
                        (message.minimalDistanceToOrderTarget = $util.Long.fromValue(object.minimalDistanceToOrderTarget)).unsigned = true;
                    else if (typeof object.minimalDistanceToOrderTarget === "string")
                        message.minimalDistanceToOrderTarget = parseInt(object.minimalDistanceToOrderTarget, 10);
                    else if (typeof object.minimalDistanceToOrderTarget === "number")
                        message.minimalDistanceToOrderTarget = object.minimalDistanceToOrderTarget;
                    else if (typeof object.minimalDistanceToOrderTarget === "object")
                        message.minimalDistanceToOrderTarget = new $util.LongBits(object.minimalDistanceToOrderTarget.low >>> 0, object.minimalDistanceToOrderTarget.high >>> 0).toNumber(true);
                if (object.useDifferentColorsForAllOrders != null)
                    message.useDifferentColorsForAllOrders = Boolean(object.useDifferentColorsForAllOrders);
                return message;
            };

            /**
             * Creates a plain object from a Courier message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.Courier
             * @static
             * @param {fastcity.admin.Courier} message Courier
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Courier.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.courierId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.courierId = options.longs === String ? "0" : 0;
                    object.externalCourierId = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                    object.name = "";
                    object.phone = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.groupId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.groupId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestamp = options.longs === String ? "0" : 0;
                    object.isSynchronized = false;
                    object.isDeleted = false;
                    object.isConnected = false;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.minimalDistanceToOrderTarget = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.minimalDistanceToOrderTarget = options.longs === String ? "0" : 0;
                    object.useDifferentColorsForAllOrders = false;
                }
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (typeof message.courierId === "number")
                        object.courierId = options.longs === String ? String(message.courierId) : message.courierId;
                    else
                        object.courierId = options.longs === String ? $util.Long.prototype.toString.call(message.courierId) : options.longs === Number ? new $util.LongBits(message.courierId.low >>> 0, message.courierId.high >>> 0).toNumber(true) : message.courierId;
                if (message.externalCourierId != null && message.hasOwnProperty("externalCourierId"))
                    object.externalCourierId = message.externalCourierId;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.phone != null && message.hasOwnProperty("phone"))
                    object.phone = message.phone;
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (typeof message.groupId === "number")
                        object.groupId = options.longs === String ? String(message.groupId) : message.groupId;
                    else
                        object.groupId = options.longs === String ? $util.Long.prototype.toString.call(message.groupId) : options.longs === Number ? new $util.LongBits(message.groupId.low >>> 0, message.groupId.high >>> 0).toNumber(true) : message.groupId;
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (typeof message.timestamp === "number")
                        object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                    else
                        object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
                if (message.isSynchronized != null && message.hasOwnProperty("isSynchronized"))
                    object.isSynchronized = message.isSynchronized;
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                if (message.isConnected != null && message.hasOwnProperty("isConnected"))
                    object.isConnected = message.isConnected;
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (typeof message.minimalDistanceToOrderTarget === "number")
                        object.minimalDistanceToOrderTarget = options.longs === String ? String(message.minimalDistanceToOrderTarget) : message.minimalDistanceToOrderTarget;
                    else
                        object.minimalDistanceToOrderTarget = options.longs === String ? $util.Long.prototype.toString.call(message.minimalDistanceToOrderTarget) : options.longs === Number ? new $util.LongBits(message.minimalDistanceToOrderTarget.low >>> 0, message.minimalDistanceToOrderTarget.high >>> 0).toNumber(true) : message.minimalDistanceToOrderTarget;
                if (message.useDifferentColorsForAllOrders != null && message.hasOwnProperty("useDifferentColorsForAllOrders"))
                    object.useDifferentColorsForAllOrders = message.useDifferentColorsForAllOrders;
                return object;
            };

            /**
             * Converts this Courier to JSON.
             * @function toJSON
             * @memberof fastcity.admin.Courier
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Courier.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Courier;
        })();

        admin.CreateCourier = (function() {

            /**
             * Properties of a CreateCourier.
             * @memberof fastcity.admin
             * @interface ICreateCourier
             * @property {string|null} [externalCourierId] CreateCourier externalCourierId
             * @property {number|Long|null} [companyId] CreateCourier companyId
             * @property {string|null} [externalSubCompanyId] CreateCourier externalSubCompanyId
             * @property {string|null} [name] CreateCourier name
             * @property {string|null} [phone] CreateCourier phone
             * @property {number|Long|null} [groupId] CreateCourier groupId
             * @property {string|null} [password] CreateCourier password
             * @property {number|Long|null} [minimalDistanceToOrderTarget] CreateCourier minimalDistanceToOrderTarget
             * @property {boolean|null} [useDifferentColorsForAllOrders] CreateCourier useDifferentColorsForAllOrders
             * @property {boolean|null} [generateExternalCourierId] CreateCourier generateExternalCourierId
             */

            /**
             * Constructs a new CreateCourier.
             * @memberof fastcity.admin
             * @classdesc Represents a CreateCourier.
             * @implements ICreateCourier
             * @constructor
             * @param {fastcity.admin.ICreateCourier=} [properties] Properties to set
             */
            function CreateCourier(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateCourier externalCourierId.
             * @member {string} externalCourierId
             * @memberof fastcity.admin.CreateCourier
             * @instance
             */
            CreateCourier.prototype.externalCourierId = "";

            /**
             * CreateCourier companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.CreateCourier
             * @instance
             */
            CreateCourier.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateCourier externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.CreateCourier
             * @instance
             */
            CreateCourier.prototype.externalSubCompanyId = "";

            /**
             * CreateCourier name.
             * @member {string} name
             * @memberof fastcity.admin.CreateCourier
             * @instance
             */
            CreateCourier.prototype.name = "";

            /**
             * CreateCourier phone.
             * @member {string} phone
             * @memberof fastcity.admin.CreateCourier
             * @instance
             */
            CreateCourier.prototype.phone = "";

            /**
             * CreateCourier groupId.
             * @member {number|Long} groupId
             * @memberof fastcity.admin.CreateCourier
             * @instance
             */
            CreateCourier.prototype.groupId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateCourier password.
             * @member {string} password
             * @memberof fastcity.admin.CreateCourier
             * @instance
             */
            CreateCourier.prototype.password = "";

            /**
             * CreateCourier minimalDistanceToOrderTarget.
             * @member {number|Long} minimalDistanceToOrderTarget
             * @memberof fastcity.admin.CreateCourier
             * @instance
             */
            CreateCourier.prototype.minimalDistanceToOrderTarget = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateCourier useDifferentColorsForAllOrders.
             * @member {boolean} useDifferentColorsForAllOrders
             * @memberof fastcity.admin.CreateCourier
             * @instance
             */
            CreateCourier.prototype.useDifferentColorsForAllOrders = false;

            /**
             * CreateCourier generateExternalCourierId.
             * @member {boolean} generateExternalCourierId
             * @memberof fastcity.admin.CreateCourier
             * @instance
             */
            CreateCourier.prototype.generateExternalCourierId = false;

            /**
             * Creates a new CreateCourier instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CreateCourier
             * @static
             * @param {fastcity.admin.ICreateCourier=} [properties] Properties to set
             * @returns {fastcity.admin.CreateCourier} CreateCourier instance
             */
            CreateCourier.create = function create(properties) {
                return new CreateCourier(properties);
            };

            /**
             * Encodes the specified CreateCourier message. Does not implicitly {@link fastcity.admin.CreateCourier.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CreateCourier
             * @static
             * @param {fastcity.admin.ICreateCourier} message CreateCourier message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateCourier.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.externalCourierId != null && Object.hasOwnProperty.call(message, "externalCourierId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.externalCourierId);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.externalSubCompanyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
                if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.phone);
                if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.groupId);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.password);
                if (message.minimalDistanceToOrderTarget != null && Object.hasOwnProperty.call(message, "minimalDistanceToOrderTarget"))
                    writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.minimalDistanceToOrderTarget);
                if (message.useDifferentColorsForAllOrders != null && Object.hasOwnProperty.call(message, "useDifferentColorsForAllOrders"))
                    writer.uint32(/* id 9, wireType 0 =*/72).bool(message.useDifferentColorsForAllOrders);
                if (message.generateExternalCourierId != null && Object.hasOwnProperty.call(message, "generateExternalCourierId"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.generateExternalCourierId);
                return writer;
            };

            /**
             * Decodes a CreateCourier message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CreateCourier
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CreateCourier} CreateCourier
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateCourier.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CreateCourier();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.externalCourierId = reader.string();
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        message.externalSubCompanyId = reader.string();
                        break;
                    case 4:
                        message.name = reader.string();
                        break;
                    case 5:
                        message.phone = reader.string();
                        break;
                    case 6:
                        message.groupId = reader.uint64();
                        break;
                    case 7:
                        message.password = reader.string();
                        break;
                    case 8:
                        message.minimalDistanceToOrderTarget = reader.uint64();
                        break;
                    case 9:
                        message.useDifferentColorsForAllOrders = reader.bool();
                        break;
                    case 10:
                        message.generateExternalCourierId = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CreateCourier message.
             * @function verify
             * @memberof fastcity.admin.CreateCourier
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateCourier.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.externalCourierId != null && message.hasOwnProperty("externalCourierId"))
                    if (!$util.isString(message.externalCourierId))
                        return "externalCourierId: string expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.phone != null && message.hasOwnProperty("phone"))
                    if (!$util.isString(message.phone))
                        return "phone: string expected";
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (!$util.isInteger(message.groupId) && !(message.groupId && $util.isInteger(message.groupId.low) && $util.isInteger(message.groupId.high)))
                        return "groupId: integer|Long expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (!$util.isInteger(message.minimalDistanceToOrderTarget) && !(message.minimalDistanceToOrderTarget && $util.isInteger(message.minimalDistanceToOrderTarget.low) && $util.isInteger(message.minimalDistanceToOrderTarget.high)))
                        return "minimalDistanceToOrderTarget: integer|Long expected";
                if (message.useDifferentColorsForAllOrders != null && message.hasOwnProperty("useDifferentColorsForAllOrders"))
                    if (typeof message.useDifferentColorsForAllOrders !== "boolean")
                        return "useDifferentColorsForAllOrders: boolean expected";
                if (message.generateExternalCourierId != null && message.hasOwnProperty("generateExternalCourierId"))
                    if (typeof message.generateExternalCourierId !== "boolean")
                        return "generateExternalCourierId: boolean expected";
                return null;
            };

            /**
             * Creates a CreateCourier message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CreateCourier
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CreateCourier} CreateCourier
             */
            CreateCourier.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CreateCourier)
                    return object;
                var message = new $root.fastcity.admin.CreateCourier();
                if (object.externalCourierId != null)
                    message.externalCourierId = String(object.externalCourierId);
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.phone != null)
                    message.phone = String(object.phone);
                if (object.groupId != null)
                    if ($util.Long)
                        (message.groupId = $util.Long.fromValue(object.groupId)).unsigned = true;
                    else if (typeof object.groupId === "string")
                        message.groupId = parseInt(object.groupId, 10);
                    else if (typeof object.groupId === "number")
                        message.groupId = object.groupId;
                    else if (typeof object.groupId === "object")
                        message.groupId = new $util.LongBits(object.groupId.low >>> 0, object.groupId.high >>> 0).toNumber(true);
                if (object.password != null)
                    message.password = String(object.password);
                if (object.minimalDistanceToOrderTarget != null)
                    if ($util.Long)
                        (message.minimalDistanceToOrderTarget = $util.Long.fromValue(object.minimalDistanceToOrderTarget)).unsigned = true;
                    else if (typeof object.minimalDistanceToOrderTarget === "string")
                        message.minimalDistanceToOrderTarget = parseInt(object.minimalDistanceToOrderTarget, 10);
                    else if (typeof object.minimalDistanceToOrderTarget === "number")
                        message.minimalDistanceToOrderTarget = object.minimalDistanceToOrderTarget;
                    else if (typeof object.minimalDistanceToOrderTarget === "object")
                        message.minimalDistanceToOrderTarget = new $util.LongBits(object.minimalDistanceToOrderTarget.low >>> 0, object.minimalDistanceToOrderTarget.high >>> 0).toNumber(true);
                if (object.useDifferentColorsForAllOrders != null)
                    message.useDifferentColorsForAllOrders = Boolean(object.useDifferentColorsForAllOrders);
                if (object.generateExternalCourierId != null)
                    message.generateExternalCourierId = Boolean(object.generateExternalCourierId);
                return message;
            };

            /**
             * Creates a plain object from a CreateCourier message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CreateCourier
             * @static
             * @param {fastcity.admin.CreateCourier} message CreateCourier
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateCourier.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.externalCourierId = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                    object.name = "";
                    object.phone = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.groupId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.groupId = options.longs === String ? "0" : 0;
                    object.password = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.minimalDistanceToOrderTarget = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.minimalDistanceToOrderTarget = options.longs === String ? "0" : 0;
                    object.useDifferentColorsForAllOrders = false;
                    object.generateExternalCourierId = false;
                }
                if (message.externalCourierId != null && message.hasOwnProperty("externalCourierId"))
                    object.externalCourierId = message.externalCourierId;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.phone != null && message.hasOwnProperty("phone"))
                    object.phone = message.phone;
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (typeof message.groupId === "number")
                        object.groupId = options.longs === String ? String(message.groupId) : message.groupId;
                    else
                        object.groupId = options.longs === String ? $util.Long.prototype.toString.call(message.groupId) : options.longs === Number ? new $util.LongBits(message.groupId.low >>> 0, message.groupId.high >>> 0).toNumber(true) : message.groupId;
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (typeof message.minimalDistanceToOrderTarget === "number")
                        object.minimalDistanceToOrderTarget = options.longs === String ? String(message.minimalDistanceToOrderTarget) : message.minimalDistanceToOrderTarget;
                    else
                        object.minimalDistanceToOrderTarget = options.longs === String ? $util.Long.prototype.toString.call(message.minimalDistanceToOrderTarget) : options.longs === Number ? new $util.LongBits(message.minimalDistanceToOrderTarget.low >>> 0, message.minimalDistanceToOrderTarget.high >>> 0).toNumber(true) : message.minimalDistanceToOrderTarget;
                if (message.useDifferentColorsForAllOrders != null && message.hasOwnProperty("useDifferentColorsForAllOrders"))
                    object.useDifferentColorsForAllOrders = message.useDifferentColorsForAllOrders;
                if (message.generateExternalCourierId != null && message.hasOwnProperty("generateExternalCourierId"))
                    object.generateExternalCourierId = message.generateExternalCourierId;
                return object;
            };

            /**
             * Converts this CreateCourier to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CreateCourier
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateCourier.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CreateCourier;
        })();

        admin.UpdateCourier = (function() {

            /**
             * Properties of an UpdateCourier.
             * @memberof fastcity.admin
             * @interface IUpdateCourier
             * @property {number|Long|null} [courierId] UpdateCourier courierId
             * @property {string|null} [externalCourierId] UpdateCourier externalCourierId
             * @property {number|Long|null} [companyId] UpdateCourier companyId
             * @property {string|null} [externalSubCompanyId] UpdateCourier externalSubCompanyId
             * @property {string|null} [name] UpdateCourier name
             * @property {string|null} [phone] UpdateCourier phone
             * @property {number|Long|null} [groupId] UpdateCourier groupId
             * @property {number|Long|null} [minimalDistanceToOrderTarget] UpdateCourier minimalDistanceToOrderTarget
             * @property {boolean|null} [useDifferentColorsForAllOrders] UpdateCourier useDifferentColorsForAllOrders
             * @property {boolean|null} [isDeleted] UpdateCourier isDeleted
             */

            /**
             * Constructs a new UpdateCourier.
             * @memberof fastcity.admin
             * @classdesc Represents an UpdateCourier.
             * @implements IUpdateCourier
             * @constructor
             * @param {fastcity.admin.IUpdateCourier=} [properties] Properties to set
             */
            function UpdateCourier(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateCourier courierId.
             * @member {number|Long} courierId
             * @memberof fastcity.admin.UpdateCourier
             * @instance
             */
            UpdateCourier.prototype.courierId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateCourier externalCourierId.
             * @member {string} externalCourierId
             * @memberof fastcity.admin.UpdateCourier
             * @instance
             */
            UpdateCourier.prototype.externalCourierId = "";

            /**
             * UpdateCourier companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.UpdateCourier
             * @instance
             */
            UpdateCourier.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateCourier externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.UpdateCourier
             * @instance
             */
            UpdateCourier.prototype.externalSubCompanyId = "";

            /**
             * UpdateCourier name.
             * @member {string} name
             * @memberof fastcity.admin.UpdateCourier
             * @instance
             */
            UpdateCourier.prototype.name = "";

            /**
             * UpdateCourier phone.
             * @member {string} phone
             * @memberof fastcity.admin.UpdateCourier
             * @instance
             */
            UpdateCourier.prototype.phone = "";

            /**
             * UpdateCourier groupId.
             * @member {number|Long} groupId
             * @memberof fastcity.admin.UpdateCourier
             * @instance
             */
            UpdateCourier.prototype.groupId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateCourier minimalDistanceToOrderTarget.
             * @member {number|Long} minimalDistanceToOrderTarget
             * @memberof fastcity.admin.UpdateCourier
             * @instance
             */
            UpdateCourier.prototype.minimalDistanceToOrderTarget = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateCourier useDifferentColorsForAllOrders.
             * @member {boolean} useDifferentColorsForAllOrders
             * @memberof fastcity.admin.UpdateCourier
             * @instance
             */
            UpdateCourier.prototype.useDifferentColorsForAllOrders = false;

            /**
             * UpdateCourier isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.UpdateCourier
             * @instance
             */
            UpdateCourier.prototype.isDeleted = false;

            /**
             * Creates a new UpdateCourier instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.UpdateCourier
             * @static
             * @param {fastcity.admin.IUpdateCourier=} [properties] Properties to set
             * @returns {fastcity.admin.UpdateCourier} UpdateCourier instance
             */
            UpdateCourier.create = function create(properties) {
                return new UpdateCourier(properties);
            };

            /**
             * Encodes the specified UpdateCourier message. Does not implicitly {@link fastcity.admin.UpdateCourier.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.UpdateCourier
             * @static
             * @param {fastcity.admin.IUpdateCourier} message UpdateCourier message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateCourier.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && Object.hasOwnProperty.call(message, "courierId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.courierId);
                if (message.externalCourierId != null && Object.hasOwnProperty.call(message, "externalCourierId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalCourierId);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.externalSubCompanyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
                if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.phone);
                if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                    writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.groupId);
                if (message.minimalDistanceToOrderTarget != null && Object.hasOwnProperty.call(message, "minimalDistanceToOrderTarget"))
                    writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.minimalDistanceToOrderTarget);
                if (message.useDifferentColorsForAllOrders != null && Object.hasOwnProperty.call(message, "useDifferentColorsForAllOrders"))
                    writer.uint32(/* id 9, wireType 0 =*/72).bool(message.useDifferentColorsForAllOrders);
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isDeleted);
                return writer;
            };

            /**
             * Decodes an UpdateCourier message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.UpdateCourier
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.UpdateCourier} UpdateCourier
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateCourier.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.UpdateCourier();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.courierId = reader.uint64();
                        break;
                    case 2:
                        message.externalCourierId = reader.string();
                        break;
                    case 3:
                        message.companyId = reader.uint64();
                        break;
                    case 4:
                        message.externalSubCompanyId = reader.string();
                        break;
                    case 5:
                        message.name = reader.string();
                        break;
                    case 6:
                        message.phone = reader.string();
                        break;
                    case 7:
                        message.groupId = reader.uint64();
                        break;
                    case 8:
                        message.minimalDistanceToOrderTarget = reader.uint64();
                        break;
                    case 9:
                        message.useDifferentColorsForAllOrders = reader.bool();
                        break;
                    case 10:
                        message.isDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateCourier message.
             * @function verify
             * @memberof fastcity.admin.UpdateCourier
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateCourier.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (!$util.isInteger(message.courierId) && !(message.courierId && $util.isInteger(message.courierId.low) && $util.isInteger(message.courierId.high)))
                        return "courierId: integer|Long expected";
                if (message.externalCourierId != null && message.hasOwnProperty("externalCourierId"))
                    if (!$util.isString(message.externalCourierId))
                        return "externalCourierId: string expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.phone != null && message.hasOwnProperty("phone"))
                    if (!$util.isString(message.phone))
                        return "phone: string expected";
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (!$util.isInteger(message.groupId) && !(message.groupId && $util.isInteger(message.groupId.low) && $util.isInteger(message.groupId.high)))
                        return "groupId: integer|Long expected";
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (!$util.isInteger(message.minimalDistanceToOrderTarget) && !(message.minimalDistanceToOrderTarget && $util.isInteger(message.minimalDistanceToOrderTarget.low) && $util.isInteger(message.minimalDistanceToOrderTarget.high)))
                        return "minimalDistanceToOrderTarget: integer|Long expected";
                if (message.useDifferentColorsForAllOrders != null && message.hasOwnProperty("useDifferentColorsForAllOrders"))
                    if (typeof message.useDifferentColorsForAllOrders !== "boolean")
                        return "useDifferentColorsForAllOrders: boolean expected";
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                return null;
            };

            /**
             * Creates an UpdateCourier message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.UpdateCourier
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.UpdateCourier} UpdateCourier
             */
            UpdateCourier.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.UpdateCourier)
                    return object;
                var message = new $root.fastcity.admin.UpdateCourier();
                if (object.courierId != null)
                    if ($util.Long)
                        (message.courierId = $util.Long.fromValue(object.courierId)).unsigned = true;
                    else if (typeof object.courierId === "string")
                        message.courierId = parseInt(object.courierId, 10);
                    else if (typeof object.courierId === "number")
                        message.courierId = object.courierId;
                    else if (typeof object.courierId === "object")
                        message.courierId = new $util.LongBits(object.courierId.low >>> 0, object.courierId.high >>> 0).toNumber(true);
                if (object.externalCourierId != null)
                    message.externalCourierId = String(object.externalCourierId);
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.phone != null)
                    message.phone = String(object.phone);
                if (object.groupId != null)
                    if ($util.Long)
                        (message.groupId = $util.Long.fromValue(object.groupId)).unsigned = true;
                    else if (typeof object.groupId === "string")
                        message.groupId = parseInt(object.groupId, 10);
                    else if (typeof object.groupId === "number")
                        message.groupId = object.groupId;
                    else if (typeof object.groupId === "object")
                        message.groupId = new $util.LongBits(object.groupId.low >>> 0, object.groupId.high >>> 0).toNumber(true);
                if (object.minimalDistanceToOrderTarget != null)
                    if ($util.Long)
                        (message.minimalDistanceToOrderTarget = $util.Long.fromValue(object.minimalDistanceToOrderTarget)).unsigned = true;
                    else if (typeof object.minimalDistanceToOrderTarget === "string")
                        message.minimalDistanceToOrderTarget = parseInt(object.minimalDistanceToOrderTarget, 10);
                    else if (typeof object.minimalDistanceToOrderTarget === "number")
                        message.minimalDistanceToOrderTarget = object.minimalDistanceToOrderTarget;
                    else if (typeof object.minimalDistanceToOrderTarget === "object")
                        message.minimalDistanceToOrderTarget = new $util.LongBits(object.minimalDistanceToOrderTarget.low >>> 0, object.minimalDistanceToOrderTarget.high >>> 0).toNumber(true);
                if (object.useDifferentColorsForAllOrders != null)
                    message.useDifferentColorsForAllOrders = Boolean(object.useDifferentColorsForAllOrders);
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                return message;
            };

            /**
             * Creates a plain object from an UpdateCourier message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.UpdateCourier
             * @static
             * @param {fastcity.admin.UpdateCourier} message UpdateCourier
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateCourier.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.courierId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.courierId = options.longs === String ? "0" : 0;
                    object.externalCourierId = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                    object.name = "";
                    object.phone = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.groupId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.groupId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.minimalDistanceToOrderTarget = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.minimalDistanceToOrderTarget = options.longs === String ? "0" : 0;
                    object.useDifferentColorsForAllOrders = false;
                    object.isDeleted = false;
                }
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (typeof message.courierId === "number")
                        object.courierId = options.longs === String ? String(message.courierId) : message.courierId;
                    else
                        object.courierId = options.longs === String ? $util.Long.prototype.toString.call(message.courierId) : options.longs === Number ? new $util.LongBits(message.courierId.low >>> 0, message.courierId.high >>> 0).toNumber(true) : message.courierId;
                if (message.externalCourierId != null && message.hasOwnProperty("externalCourierId"))
                    object.externalCourierId = message.externalCourierId;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.phone != null && message.hasOwnProperty("phone"))
                    object.phone = message.phone;
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (typeof message.groupId === "number")
                        object.groupId = options.longs === String ? String(message.groupId) : message.groupId;
                    else
                        object.groupId = options.longs === String ? $util.Long.prototype.toString.call(message.groupId) : options.longs === Number ? new $util.LongBits(message.groupId.low >>> 0, message.groupId.high >>> 0).toNumber(true) : message.groupId;
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (typeof message.minimalDistanceToOrderTarget === "number")
                        object.minimalDistanceToOrderTarget = options.longs === String ? String(message.minimalDistanceToOrderTarget) : message.minimalDistanceToOrderTarget;
                    else
                        object.minimalDistanceToOrderTarget = options.longs === String ? $util.Long.prototype.toString.call(message.minimalDistanceToOrderTarget) : options.longs === Number ? new $util.LongBits(message.minimalDistanceToOrderTarget.low >>> 0, message.minimalDistanceToOrderTarget.high >>> 0).toNumber(true) : message.minimalDistanceToOrderTarget;
                if (message.useDifferentColorsForAllOrders != null && message.hasOwnProperty("useDifferentColorsForAllOrders"))
                    object.useDifferentColorsForAllOrders = message.useDifferentColorsForAllOrders;
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                return object;
            };

            /**
             * Converts this UpdateCourier to JSON.
             * @function toJSON
             * @memberof fastcity.admin.UpdateCourier
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateCourier.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateCourier;
        })();

        admin.GetCourierList = (function() {

            /**
             * Properties of a GetCourierList.
             * @memberof fastcity.admin
             * @interface IGetCourierList
             * @property {number|Long|null} [companyId] GetCourierList companyId
             * @property {Array.<number|Long>|null} [courierId] GetCourierList courierId
             * @property {Array.<number|Long>|null} [groupId] GetCourierList groupId
             * @property {number|null} [offset] GetCourierList offset
             * @property {number|null} [limit] GetCourierList limit
             * @property {fastcity.common.SortOrder.Order|null} [sortOrder] GetCourierList sortOrder
             * @property {Array.<string>|null} [externalSubCompanyId] GetCourierList externalSubCompanyId
             * @property {boolean|null} [withDeleted] GetCourierList withDeleted
             */

            /**
             * Constructs a new GetCourierList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetCourierList.
             * @implements IGetCourierList
             * @constructor
             * @param {fastcity.admin.IGetCourierList=} [properties] Properties to set
             */
            function GetCourierList(properties) {
                this.courierId = [];
                this.groupId = [];
                this.externalSubCompanyId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetCourierList companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.GetCourierList
             * @instance
             */
            GetCourierList.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetCourierList courierId.
             * @member {Array.<number|Long>} courierId
             * @memberof fastcity.admin.GetCourierList
             * @instance
             */
            GetCourierList.prototype.courierId = $util.emptyArray;

            /**
             * GetCourierList groupId.
             * @member {Array.<number|Long>} groupId
             * @memberof fastcity.admin.GetCourierList
             * @instance
             */
            GetCourierList.prototype.groupId = $util.emptyArray;

            /**
             * GetCourierList offset.
             * @member {number} offset
             * @memberof fastcity.admin.GetCourierList
             * @instance
             */
            GetCourierList.prototype.offset = 0;

            /**
             * GetCourierList limit.
             * @member {number} limit
             * @memberof fastcity.admin.GetCourierList
             * @instance
             */
            GetCourierList.prototype.limit = 0;

            /**
             * GetCourierList sortOrder.
             * @member {fastcity.common.SortOrder.Order} sortOrder
             * @memberof fastcity.admin.GetCourierList
             * @instance
             */
            GetCourierList.prototype.sortOrder = 0;

            /**
             * GetCourierList externalSubCompanyId.
             * @member {Array.<string>} externalSubCompanyId
             * @memberof fastcity.admin.GetCourierList
             * @instance
             */
            GetCourierList.prototype.externalSubCompanyId = $util.emptyArray;

            /**
             * GetCourierList withDeleted.
             * @member {boolean} withDeleted
             * @memberof fastcity.admin.GetCourierList
             * @instance
             */
            GetCourierList.prototype.withDeleted = false;

            /**
             * Creates a new GetCourierList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetCourierList
             * @static
             * @param {fastcity.admin.IGetCourierList=} [properties] Properties to set
             * @returns {fastcity.admin.GetCourierList} GetCourierList instance
             */
            GetCourierList.create = function create(properties) {
                return new GetCourierList(properties);
            };

            /**
             * Encodes the specified GetCourierList message. Does not implicitly {@link fastcity.admin.GetCourierList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetCourierList
             * @static
             * @param {fastcity.admin.IGetCourierList} message GetCourierList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCourierList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.courierId != null && message.courierId.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (var i = 0; i < message.courierId.length; ++i)
                        writer.uint64(message.courierId[i]);
                    writer.ldelim();
                }
                if (message.groupId != null && message.groupId.length) {
                    writer.uint32(/* id 3, wireType 2 =*/26).fork();
                    for (var i = 0; i < message.groupId.length; ++i)
                        writer.uint64(message.groupId[i]);
                    writer.ldelim();
                }
                if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.offset);
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.limit);
                if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.sortOrder);
                if (message.externalSubCompanyId != null && message.externalSubCompanyId.length)
                    for (var i = 0; i < message.externalSubCompanyId.length; ++i)
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.externalSubCompanyId[i]);
                if (message.withDeleted != null && Object.hasOwnProperty.call(message, "withDeleted"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.withDeleted);
                return writer;
            };

            /**
             * Decodes a GetCourierList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetCourierList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetCourierList} GetCourierList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCourierList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetCourierList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        if (!(message.courierId && message.courierId.length))
                            message.courierId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.courierId.push(reader.uint64());
                        } else
                            message.courierId.push(reader.uint64());
                        break;
                    case 3:
                        if (!(message.groupId && message.groupId.length))
                            message.groupId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.groupId.push(reader.uint64());
                        } else
                            message.groupId.push(reader.uint64());
                        break;
                    case 4:
                        message.offset = reader.uint32();
                        break;
                    case 5:
                        message.limit = reader.uint32();
                        break;
                    case 6:
                        message.sortOrder = reader.int32();
                        break;
                    case 7:
                        if (!(message.externalSubCompanyId && message.externalSubCompanyId.length))
                            message.externalSubCompanyId = [];
                        message.externalSubCompanyId.push(reader.string());
                        break;
                    case 8:
                        message.withDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetCourierList message.
             * @function verify
             * @memberof fastcity.admin.GetCourierList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetCourierList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.courierId != null && message.hasOwnProperty("courierId")) {
                    if (!Array.isArray(message.courierId))
                        return "courierId: array expected";
                    for (var i = 0; i < message.courierId.length; ++i)
                        if (!$util.isInteger(message.courierId[i]) && !(message.courierId[i] && $util.isInteger(message.courierId[i].low) && $util.isInteger(message.courierId[i].high)))
                            return "courierId: integer|Long[] expected";
                }
                if (message.groupId != null && message.hasOwnProperty("groupId")) {
                    if (!Array.isArray(message.groupId))
                        return "groupId: array expected";
                    for (var i = 0; i < message.groupId.length; ++i)
                        if (!$util.isInteger(message.groupId[i]) && !(message.groupId[i] && $util.isInteger(message.groupId[i].low) && $util.isInteger(message.groupId[i].high)))
                            return "groupId: integer|Long[] expected";
                }
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (!$util.isInteger(message.offset))
                        return "offset: integer expected";
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit))
                        return "limit: integer expected";
                if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                    switch (message.sortOrder) {
                    default:
                        return "sortOrder: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId")) {
                    if (!Array.isArray(message.externalSubCompanyId))
                        return "externalSubCompanyId: array expected";
                    for (var i = 0; i < message.externalSubCompanyId.length; ++i)
                        if (!$util.isString(message.externalSubCompanyId[i]))
                            return "externalSubCompanyId: string[] expected";
                }
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    if (typeof message.withDeleted !== "boolean")
                        return "withDeleted: boolean expected";
                return null;
            };

            /**
             * Creates a GetCourierList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetCourierList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetCourierList} GetCourierList
             */
            GetCourierList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetCourierList)
                    return object;
                var message = new $root.fastcity.admin.GetCourierList();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.courierId) {
                    if (!Array.isArray(object.courierId))
                        throw TypeError(".fastcity.admin.GetCourierList.courierId: array expected");
                    message.courierId = [];
                    for (var i = 0; i < object.courierId.length; ++i)
                        if ($util.Long)
                            (message.courierId[i] = $util.Long.fromValue(object.courierId[i])).unsigned = true;
                        else if (typeof object.courierId[i] === "string")
                            message.courierId[i] = parseInt(object.courierId[i], 10);
                        else if (typeof object.courierId[i] === "number")
                            message.courierId[i] = object.courierId[i];
                        else if (typeof object.courierId[i] === "object")
                            message.courierId[i] = new $util.LongBits(object.courierId[i].low >>> 0, object.courierId[i].high >>> 0).toNumber(true);
                }
                if (object.groupId) {
                    if (!Array.isArray(object.groupId))
                        throw TypeError(".fastcity.admin.GetCourierList.groupId: array expected");
                    message.groupId = [];
                    for (var i = 0; i < object.groupId.length; ++i)
                        if ($util.Long)
                            (message.groupId[i] = $util.Long.fromValue(object.groupId[i])).unsigned = true;
                        else if (typeof object.groupId[i] === "string")
                            message.groupId[i] = parseInt(object.groupId[i], 10);
                        else if (typeof object.groupId[i] === "number")
                            message.groupId[i] = object.groupId[i];
                        else if (typeof object.groupId[i] === "object")
                            message.groupId[i] = new $util.LongBits(object.groupId[i].low >>> 0, object.groupId[i].high >>> 0).toNumber(true);
                }
                if (object.offset != null)
                    message.offset = object.offset >>> 0;
                if (object.limit != null)
                    message.limit = object.limit >>> 0;
                switch (object.sortOrder) {
                case "ASC":
                case 0:
                    message.sortOrder = 0;
                    break;
                case "DESC":
                case 1:
                    message.sortOrder = 1;
                    break;
                }
                if (object.externalSubCompanyId) {
                    if (!Array.isArray(object.externalSubCompanyId))
                        throw TypeError(".fastcity.admin.GetCourierList.externalSubCompanyId: array expected");
                    message.externalSubCompanyId = [];
                    for (var i = 0; i < object.externalSubCompanyId.length; ++i)
                        message.externalSubCompanyId[i] = String(object.externalSubCompanyId[i]);
                }
                if (object.withDeleted != null)
                    message.withDeleted = Boolean(object.withDeleted);
                return message;
            };

            /**
             * Creates a plain object from a GetCourierList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetCourierList
             * @static
             * @param {fastcity.admin.GetCourierList} message GetCourierList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCourierList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.courierId = [];
                    object.groupId = [];
                    object.externalSubCompanyId = [];
                }
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.offset = 0;
                    object.limit = 0;
                    object.sortOrder = options.enums === String ? "ASC" : 0;
                    object.withDeleted = false;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.courierId && message.courierId.length) {
                    object.courierId = [];
                    for (var j = 0; j < message.courierId.length; ++j)
                        if (typeof message.courierId[j] === "number")
                            object.courierId[j] = options.longs === String ? String(message.courierId[j]) : message.courierId[j];
                        else
                            object.courierId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.courierId[j]) : options.longs === Number ? new $util.LongBits(message.courierId[j].low >>> 0, message.courierId[j].high >>> 0).toNumber(true) : message.courierId[j];
                }
                if (message.groupId && message.groupId.length) {
                    object.groupId = [];
                    for (var j = 0; j < message.groupId.length; ++j)
                        if (typeof message.groupId[j] === "number")
                            object.groupId[j] = options.longs === String ? String(message.groupId[j]) : message.groupId[j];
                        else
                            object.groupId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.groupId[j]) : options.longs === Number ? new $util.LongBits(message.groupId[j].low >>> 0, message.groupId[j].high >>> 0).toNumber(true) : message.groupId[j];
                }
                if (message.offset != null && message.hasOwnProperty("offset"))
                    object.offset = message.offset;
                if (message.limit != null && message.hasOwnProperty("limit"))
                    object.limit = message.limit;
                if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                    object.sortOrder = options.enums === String ? $root.fastcity.common.SortOrder.Order[message.sortOrder] : message.sortOrder;
                if (message.externalSubCompanyId && message.externalSubCompanyId.length) {
                    object.externalSubCompanyId = [];
                    for (var j = 0; j < message.externalSubCompanyId.length; ++j)
                        object.externalSubCompanyId[j] = message.externalSubCompanyId[j];
                }
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    object.withDeleted = message.withDeleted;
                return object;
            };

            /**
             * Converts this GetCourierList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetCourierList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCourierList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetCourierList;
        })();

        admin.SetCourierGroup = (function() {

            /**
             * Properties of a SetCourierGroup.
             * @memberof fastcity.admin
             * @interface ISetCourierGroup
             * @property {number|Long|null} [courierId] SetCourierGroup courierId
             * @property {number|Long|null} [groupId] SetCourierGroup groupId
             */

            /**
             * Constructs a new SetCourierGroup.
             * @memberof fastcity.admin
             * @classdesc Represents a SetCourierGroup.
             * @implements ISetCourierGroup
             * @constructor
             * @param {fastcity.admin.ISetCourierGroup=} [properties] Properties to set
             */
            function SetCourierGroup(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SetCourierGroup courierId.
             * @member {number|Long} courierId
             * @memberof fastcity.admin.SetCourierGroup
             * @instance
             */
            SetCourierGroup.prototype.courierId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SetCourierGroup groupId.
             * @member {number|Long} groupId
             * @memberof fastcity.admin.SetCourierGroup
             * @instance
             */
            SetCourierGroup.prototype.groupId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new SetCourierGroup instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.SetCourierGroup
             * @static
             * @param {fastcity.admin.ISetCourierGroup=} [properties] Properties to set
             * @returns {fastcity.admin.SetCourierGroup} SetCourierGroup instance
             */
            SetCourierGroup.create = function create(properties) {
                return new SetCourierGroup(properties);
            };

            /**
             * Encodes the specified SetCourierGroup message. Does not implicitly {@link fastcity.admin.SetCourierGroup.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.SetCourierGroup
             * @static
             * @param {fastcity.admin.ISetCourierGroup} message SetCourierGroup message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SetCourierGroup.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && Object.hasOwnProperty.call(message, "courierId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.courierId);
                if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.groupId);
                return writer;
            };

            /**
             * Decodes a SetCourierGroup message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.SetCourierGroup
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.SetCourierGroup} SetCourierGroup
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SetCourierGroup.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.SetCourierGroup();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.courierId = reader.uint64();
                        break;
                    case 2:
                        message.groupId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SetCourierGroup message.
             * @function verify
             * @memberof fastcity.admin.SetCourierGroup
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SetCourierGroup.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (!$util.isInteger(message.courierId) && !(message.courierId && $util.isInteger(message.courierId.low) && $util.isInteger(message.courierId.high)))
                        return "courierId: integer|Long expected";
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (!$util.isInteger(message.groupId) && !(message.groupId && $util.isInteger(message.groupId.low) && $util.isInteger(message.groupId.high)))
                        return "groupId: integer|Long expected";
                return null;
            };

            /**
             * Creates a SetCourierGroup message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.SetCourierGroup
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.SetCourierGroup} SetCourierGroup
             */
            SetCourierGroup.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.SetCourierGroup)
                    return object;
                var message = new $root.fastcity.admin.SetCourierGroup();
                if (object.courierId != null)
                    if ($util.Long)
                        (message.courierId = $util.Long.fromValue(object.courierId)).unsigned = true;
                    else if (typeof object.courierId === "string")
                        message.courierId = parseInt(object.courierId, 10);
                    else if (typeof object.courierId === "number")
                        message.courierId = object.courierId;
                    else if (typeof object.courierId === "object")
                        message.courierId = new $util.LongBits(object.courierId.low >>> 0, object.courierId.high >>> 0).toNumber(true);
                if (object.groupId != null)
                    if ($util.Long)
                        (message.groupId = $util.Long.fromValue(object.groupId)).unsigned = true;
                    else if (typeof object.groupId === "string")
                        message.groupId = parseInt(object.groupId, 10);
                    else if (typeof object.groupId === "number")
                        message.groupId = object.groupId;
                    else if (typeof object.groupId === "object")
                        message.groupId = new $util.LongBits(object.groupId.low >>> 0, object.groupId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a SetCourierGroup message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.SetCourierGroup
             * @static
             * @param {fastcity.admin.SetCourierGroup} message SetCourierGroup
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SetCourierGroup.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.courierId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.courierId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.groupId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.groupId = options.longs === String ? "0" : 0;
                }
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (typeof message.courierId === "number")
                        object.courierId = options.longs === String ? String(message.courierId) : message.courierId;
                    else
                        object.courierId = options.longs === String ? $util.Long.prototype.toString.call(message.courierId) : options.longs === Number ? new $util.LongBits(message.courierId.low >>> 0, message.courierId.high >>> 0).toNumber(true) : message.courierId;
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (typeof message.groupId === "number")
                        object.groupId = options.longs === String ? String(message.groupId) : message.groupId;
                    else
                        object.groupId = options.longs === String ? $util.Long.prototype.toString.call(message.groupId) : options.longs === Number ? new $util.LongBits(message.groupId.low >>> 0, message.groupId.high >>> 0).toNumber(true) : message.groupId;
                return object;
            };

            /**
             * Converts this SetCourierGroup to JSON.
             * @function toJSON
             * @memberof fastcity.admin.SetCourierGroup
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SetCourierGroup.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SetCourierGroup;
        })();

        admin.CourierList = (function() {

            /**
             * Properties of a CourierList.
             * @memberof fastcity.admin
             * @interface ICourierList
             * @property {Array.<fastcity.admin.ICourier>|null} [courier] CourierList courier
             * @property {number|null} [totalCouriers] CourierList totalCouriers
             */

            /**
             * Constructs a new CourierList.
             * @memberof fastcity.admin
             * @classdesc Represents a CourierList.
             * @implements ICourierList
             * @constructor
             * @param {fastcity.admin.ICourierList=} [properties] Properties to set
             */
            function CourierList(properties) {
                this.courier = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CourierList courier.
             * @member {Array.<fastcity.admin.ICourier>} courier
             * @memberof fastcity.admin.CourierList
             * @instance
             */
            CourierList.prototype.courier = $util.emptyArray;

            /**
             * CourierList totalCouriers.
             * @member {number} totalCouriers
             * @memberof fastcity.admin.CourierList
             * @instance
             */
            CourierList.prototype.totalCouriers = 0;

            /**
             * Creates a new CourierList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CourierList
             * @static
             * @param {fastcity.admin.ICourierList=} [properties] Properties to set
             * @returns {fastcity.admin.CourierList} CourierList instance
             */
            CourierList.create = function create(properties) {
                return new CourierList(properties);
            };

            /**
             * Encodes the specified CourierList message. Does not implicitly {@link fastcity.admin.CourierList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CourierList
             * @static
             * @param {fastcity.admin.ICourierList} message CourierList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CourierList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courier != null && message.courier.length)
                    for (var i = 0; i < message.courier.length; ++i)
                        $root.fastcity.admin.Courier.encode(message.courier[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.totalCouriers != null && Object.hasOwnProperty.call(message, "totalCouriers"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.totalCouriers);
                return writer;
            };

            /**
             * Decodes a CourierList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CourierList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CourierList} CourierList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CourierList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CourierList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.courier && message.courier.length))
                            message.courier = [];
                        message.courier.push($root.fastcity.admin.Courier.decode(reader, reader.uint32()));
                        break;
                    case 2:
                        message.totalCouriers = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CourierList message.
             * @function verify
             * @memberof fastcity.admin.CourierList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CourierList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courier != null && message.hasOwnProperty("courier")) {
                    if (!Array.isArray(message.courier))
                        return "courier: array expected";
                    for (var i = 0; i < message.courier.length; ++i) {
                        var error = $root.fastcity.admin.Courier.verify(message.courier[i]);
                        if (error)
                            return "courier." + error;
                    }
                }
                if (message.totalCouriers != null && message.hasOwnProperty("totalCouriers"))
                    if (!$util.isInteger(message.totalCouriers))
                        return "totalCouriers: integer expected";
                return null;
            };

            /**
             * Creates a CourierList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CourierList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CourierList} CourierList
             */
            CourierList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CourierList)
                    return object;
                var message = new $root.fastcity.admin.CourierList();
                if (object.courier) {
                    if (!Array.isArray(object.courier))
                        throw TypeError(".fastcity.admin.CourierList.courier: array expected");
                    message.courier = [];
                    for (var i = 0; i < object.courier.length; ++i) {
                        if (typeof object.courier[i] !== "object")
                            throw TypeError(".fastcity.admin.CourierList.courier: object expected");
                        message.courier[i] = $root.fastcity.admin.Courier.fromObject(object.courier[i]);
                    }
                }
                if (object.totalCouriers != null)
                    message.totalCouriers = object.totalCouriers >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a CourierList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CourierList
             * @static
             * @param {fastcity.admin.CourierList} message CourierList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CourierList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.courier = [];
                if (options.defaults)
                    object.totalCouriers = 0;
                if (message.courier && message.courier.length) {
                    object.courier = [];
                    for (var j = 0; j < message.courier.length; ++j)
                        object.courier[j] = $root.fastcity.admin.Courier.toObject(message.courier[j], options);
                }
                if (message.totalCouriers != null && message.hasOwnProperty("totalCouriers"))
                    object.totalCouriers = message.totalCouriers;
                return object;
            };

            /**
             * Converts this CourierList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CourierList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CourierList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CourierList;
        })();

        admin.ChangedCourierIdList = (function() {

            /**
             * Properties of a ChangedCourierIdList.
             * @memberof fastcity.admin
             * @interface IChangedCourierIdList
             * @property {Array.<number|Long>|null} [courierId] ChangedCourierIdList courierId
             * @property {number|Long|null} [companyId] ChangedCourierIdList companyId
             * @property {string|null} [externalSubCompanyId] ChangedCourierIdList externalSubCompanyId
             */

            /**
             * Constructs a new ChangedCourierIdList.
             * @memberof fastcity.admin
             * @classdesc Represents a ChangedCourierIdList.
             * @implements IChangedCourierIdList
             * @constructor
             * @param {fastcity.admin.IChangedCourierIdList=} [properties] Properties to set
             */
            function ChangedCourierIdList(properties) {
                this.courierId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ChangedCourierIdList courierId.
             * @member {Array.<number|Long>} courierId
             * @memberof fastcity.admin.ChangedCourierIdList
             * @instance
             */
            ChangedCourierIdList.prototype.courierId = $util.emptyArray;

            /**
             * ChangedCourierIdList companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.ChangedCourierIdList
             * @instance
             */
            ChangedCourierIdList.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ChangedCourierIdList externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.ChangedCourierIdList
             * @instance
             */
            ChangedCourierIdList.prototype.externalSubCompanyId = "";

            /**
             * Creates a new ChangedCourierIdList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ChangedCourierIdList
             * @static
             * @param {fastcity.admin.IChangedCourierIdList=} [properties] Properties to set
             * @returns {fastcity.admin.ChangedCourierIdList} ChangedCourierIdList instance
             */
            ChangedCourierIdList.create = function create(properties) {
                return new ChangedCourierIdList(properties);
            };

            /**
             * Encodes the specified ChangedCourierIdList message. Does not implicitly {@link fastcity.admin.ChangedCourierIdList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ChangedCourierIdList
             * @static
             * @param {fastcity.admin.IChangedCourierIdList} message ChangedCourierIdList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChangedCourierIdList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && message.courierId.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.courierId.length; ++i)
                        writer.uint64(message.courierId[i]);
                    writer.ldelim();
                }
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.externalSubCompanyId);
                return writer;
            };

            /**
             * Decodes a ChangedCourierIdList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ChangedCourierIdList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ChangedCourierIdList} ChangedCourierIdList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChangedCourierIdList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ChangedCourierIdList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.courierId && message.courierId.length))
                            message.courierId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.courierId.push(reader.uint64());
                        } else
                            message.courierId.push(reader.uint64());
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        message.externalSubCompanyId = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ChangedCourierIdList message.
             * @function verify
             * @memberof fastcity.admin.ChangedCourierIdList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChangedCourierIdList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId")) {
                    if (!Array.isArray(message.courierId))
                        return "courierId: array expected";
                    for (var i = 0; i < message.courierId.length; ++i)
                        if (!$util.isInteger(message.courierId[i]) && !(message.courierId[i] && $util.isInteger(message.courierId[i].low) && $util.isInteger(message.courierId[i].high)))
                            return "courierId: integer|Long[] expected";
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                return null;
            };

            /**
             * Creates a ChangedCourierIdList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ChangedCourierIdList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ChangedCourierIdList} ChangedCourierIdList
             */
            ChangedCourierIdList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ChangedCourierIdList)
                    return object;
                var message = new $root.fastcity.admin.ChangedCourierIdList();
                if (object.courierId) {
                    if (!Array.isArray(object.courierId))
                        throw TypeError(".fastcity.admin.ChangedCourierIdList.courierId: array expected");
                    message.courierId = [];
                    for (var i = 0; i < object.courierId.length; ++i)
                        if ($util.Long)
                            (message.courierId[i] = $util.Long.fromValue(object.courierId[i])).unsigned = true;
                        else if (typeof object.courierId[i] === "string")
                            message.courierId[i] = parseInt(object.courierId[i], 10);
                        else if (typeof object.courierId[i] === "number")
                            message.courierId[i] = object.courierId[i];
                        else if (typeof object.courierId[i] === "object")
                            message.courierId[i] = new $util.LongBits(object.courierId[i].low >>> 0, object.courierId[i].high >>> 0).toNumber(true);
                }
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                return message;
            };

            /**
             * Creates a plain object from a ChangedCourierIdList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ChangedCourierIdList
             * @static
             * @param {fastcity.admin.ChangedCourierIdList} message ChangedCourierIdList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChangedCourierIdList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.courierId = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                }
                if (message.courierId && message.courierId.length) {
                    object.courierId = [];
                    for (var j = 0; j < message.courierId.length; ++j)
                        if (typeof message.courierId[j] === "number")
                            object.courierId[j] = options.longs === String ? String(message.courierId[j]) : message.courierId[j];
                        else
                            object.courierId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.courierId[j]) : options.longs === Number ? new $util.LongBits(message.courierId[j].low >>> 0, message.courierId[j].high >>> 0).toNumber(true) : message.courierId[j];
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                return object;
            };

            /**
             * Converts this ChangedCourierIdList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ChangedCourierIdList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChangedCourierIdList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ChangedCourierIdList;
        })();

        admin.SetCourierIsDeleted = (function() {

            /**
             * Properties of a SetCourierIsDeleted.
             * @memberof fastcity.admin
             * @interface ISetCourierIsDeleted
             * @property {number|Long|null} [courierId] SetCourierIsDeleted courierId
             * @property {boolean|null} [isDeleted] SetCourierIsDeleted isDeleted
             */

            /**
             * Constructs a new SetCourierIsDeleted.
             * @memberof fastcity.admin
             * @classdesc Represents a SetCourierIsDeleted.
             * @implements ISetCourierIsDeleted
             * @constructor
             * @param {fastcity.admin.ISetCourierIsDeleted=} [properties] Properties to set
             */
            function SetCourierIsDeleted(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SetCourierIsDeleted courierId.
             * @member {number|Long} courierId
             * @memberof fastcity.admin.SetCourierIsDeleted
             * @instance
             */
            SetCourierIsDeleted.prototype.courierId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SetCourierIsDeleted isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.SetCourierIsDeleted
             * @instance
             */
            SetCourierIsDeleted.prototype.isDeleted = false;

            /**
             * Creates a new SetCourierIsDeleted instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.SetCourierIsDeleted
             * @static
             * @param {fastcity.admin.ISetCourierIsDeleted=} [properties] Properties to set
             * @returns {fastcity.admin.SetCourierIsDeleted} SetCourierIsDeleted instance
             */
            SetCourierIsDeleted.create = function create(properties) {
                return new SetCourierIsDeleted(properties);
            };

            /**
             * Encodes the specified SetCourierIsDeleted message. Does not implicitly {@link fastcity.admin.SetCourierIsDeleted.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.SetCourierIsDeleted
             * @static
             * @param {fastcity.admin.ISetCourierIsDeleted} message SetCourierIsDeleted message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SetCourierIsDeleted.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && Object.hasOwnProperty.call(message, "courierId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.courierId);
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isDeleted);
                return writer;
            };

            /**
             * Decodes a SetCourierIsDeleted message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.SetCourierIsDeleted
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.SetCourierIsDeleted} SetCourierIsDeleted
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SetCourierIsDeleted.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.SetCourierIsDeleted();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.courierId = reader.uint64();
                        break;
                    case 2:
                        message.isDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SetCourierIsDeleted message.
             * @function verify
             * @memberof fastcity.admin.SetCourierIsDeleted
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SetCourierIsDeleted.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (!$util.isInteger(message.courierId) && !(message.courierId && $util.isInteger(message.courierId.low) && $util.isInteger(message.courierId.high)))
                        return "courierId: integer|Long expected";
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                return null;
            };

            /**
             * Creates a SetCourierIsDeleted message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.SetCourierIsDeleted
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.SetCourierIsDeleted} SetCourierIsDeleted
             */
            SetCourierIsDeleted.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.SetCourierIsDeleted)
                    return object;
                var message = new $root.fastcity.admin.SetCourierIsDeleted();
                if (object.courierId != null)
                    if ($util.Long)
                        (message.courierId = $util.Long.fromValue(object.courierId)).unsigned = true;
                    else if (typeof object.courierId === "string")
                        message.courierId = parseInt(object.courierId, 10);
                    else if (typeof object.courierId === "number")
                        message.courierId = object.courierId;
                    else if (typeof object.courierId === "object")
                        message.courierId = new $util.LongBits(object.courierId.low >>> 0, object.courierId.high >>> 0).toNumber(true);
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                return message;
            };

            /**
             * Creates a plain object from a SetCourierIsDeleted message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.SetCourierIsDeleted
             * @static
             * @param {fastcity.admin.SetCourierIsDeleted} message SetCourierIsDeleted
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SetCourierIsDeleted.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.courierId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.courierId = options.longs === String ? "0" : 0;
                    object.isDeleted = false;
                }
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (typeof message.courierId === "number")
                        object.courierId = options.longs === String ? String(message.courierId) : message.courierId;
                    else
                        object.courierId = options.longs === String ? $util.Long.prototype.toString.call(message.courierId) : options.longs === Number ? new $util.LongBits(message.courierId.low >>> 0, message.courierId.high >>> 0).toNumber(true) : message.courierId;
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                return object;
            };

            /**
             * Converts this SetCourierIsDeleted to JSON.
             * @function toJSON
             * @memberof fastcity.admin.SetCourierIsDeleted
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SetCourierIsDeleted.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SetCourierIsDeleted;
        })();

        admin.DeliveryStatDay = (function() {

            /**
             * Properties of a DeliveryStatDay.
             * @memberof fastcity.admin
             * @interface IDeliveryStatDay
             * @property {number|Long|null} [courierId] DeliveryStatDay courierId
             * @property {number|Long|null} [timestampWorkingDay] DeliveryStatDay timestampWorkingDay
             * @property {number|null} [numberDelivered] DeliveryStatDay numberDelivered
             * @property {number|null} [numberDeliveredWithoutDelay] DeliveryStatDay numberDeliveredWithoutDelay
             * @property {number|null} [numberDeliveredWithDelay] DeliveryStatDay numberDeliveredWithDelay
             * @property {number|null} [averageDeliveryTime] DeliveryStatDay averageDeliveryTime
             */

            /**
             * Constructs a new DeliveryStatDay.
             * @memberof fastcity.admin
             * @classdesc Represents a DeliveryStatDay.
             * @implements IDeliveryStatDay
             * @constructor
             * @param {fastcity.admin.IDeliveryStatDay=} [properties] Properties to set
             */
            function DeliveryStatDay(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeliveryStatDay courierId.
             * @member {number|Long} courierId
             * @memberof fastcity.admin.DeliveryStatDay
             * @instance
             */
            DeliveryStatDay.prototype.courierId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * DeliveryStatDay timestampWorkingDay.
             * @member {number|Long} timestampWorkingDay
             * @memberof fastcity.admin.DeliveryStatDay
             * @instance
             */
            DeliveryStatDay.prototype.timestampWorkingDay = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * DeliveryStatDay numberDelivered.
             * @member {number} numberDelivered
             * @memberof fastcity.admin.DeliveryStatDay
             * @instance
             */
            DeliveryStatDay.prototype.numberDelivered = 0;

            /**
             * DeliveryStatDay numberDeliveredWithoutDelay.
             * @member {number} numberDeliveredWithoutDelay
             * @memberof fastcity.admin.DeliveryStatDay
             * @instance
             */
            DeliveryStatDay.prototype.numberDeliveredWithoutDelay = 0;

            /**
             * DeliveryStatDay numberDeliveredWithDelay.
             * @member {number} numberDeliveredWithDelay
             * @memberof fastcity.admin.DeliveryStatDay
             * @instance
             */
            DeliveryStatDay.prototype.numberDeliveredWithDelay = 0;

            /**
             * DeliveryStatDay averageDeliveryTime.
             * @member {number} averageDeliveryTime
             * @memberof fastcity.admin.DeliveryStatDay
             * @instance
             */
            DeliveryStatDay.prototype.averageDeliveryTime = 0;

            /**
             * Creates a new DeliveryStatDay instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DeliveryStatDay
             * @static
             * @param {fastcity.admin.IDeliveryStatDay=} [properties] Properties to set
             * @returns {fastcity.admin.DeliveryStatDay} DeliveryStatDay instance
             */
            DeliveryStatDay.create = function create(properties) {
                return new DeliveryStatDay(properties);
            };

            /**
             * Encodes the specified DeliveryStatDay message. Does not implicitly {@link fastcity.admin.DeliveryStatDay.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DeliveryStatDay
             * @static
             * @param {fastcity.admin.IDeliveryStatDay} message DeliveryStatDay message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeliveryStatDay.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && Object.hasOwnProperty.call(message, "courierId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.courierId);
                if (message.timestampWorkingDay != null && Object.hasOwnProperty.call(message, "timestampWorkingDay"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.timestampWorkingDay);
                if (message.numberDelivered != null && Object.hasOwnProperty.call(message, "numberDelivered"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.numberDelivered);
                if (message.numberDeliveredWithoutDelay != null && Object.hasOwnProperty.call(message, "numberDeliveredWithoutDelay"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.numberDeliveredWithoutDelay);
                if (message.numberDeliveredWithDelay != null && Object.hasOwnProperty.call(message, "numberDeliveredWithDelay"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.numberDeliveredWithDelay);
                if (message.averageDeliveryTime != null && Object.hasOwnProperty.call(message, "averageDeliveryTime"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.averageDeliveryTime);
                return writer;
            };

            /**
             * Decodes a DeliveryStatDay message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DeliveryStatDay
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DeliveryStatDay} DeliveryStatDay
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeliveryStatDay.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DeliveryStatDay();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.courierId = reader.uint64();
                        break;
                    case 2:
                        message.timestampWorkingDay = reader.int64();
                        break;
                    case 3:
                        message.numberDelivered = reader.uint32();
                        break;
                    case 4:
                        message.numberDeliveredWithoutDelay = reader.uint32();
                        break;
                    case 5:
                        message.numberDeliveredWithDelay = reader.uint32();
                        break;
                    case 6:
                        message.averageDeliveryTime = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeliveryStatDay message.
             * @function verify
             * @memberof fastcity.admin.DeliveryStatDay
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeliveryStatDay.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (!$util.isInteger(message.courierId) && !(message.courierId && $util.isInteger(message.courierId.low) && $util.isInteger(message.courierId.high)))
                        return "courierId: integer|Long expected";
                if (message.timestampWorkingDay != null && message.hasOwnProperty("timestampWorkingDay"))
                    if (!$util.isInteger(message.timestampWorkingDay) && !(message.timestampWorkingDay && $util.isInteger(message.timestampWorkingDay.low) && $util.isInteger(message.timestampWorkingDay.high)))
                        return "timestampWorkingDay: integer|Long expected";
                if (message.numberDelivered != null && message.hasOwnProperty("numberDelivered"))
                    if (!$util.isInteger(message.numberDelivered))
                        return "numberDelivered: integer expected";
                if (message.numberDeliveredWithoutDelay != null && message.hasOwnProperty("numberDeliveredWithoutDelay"))
                    if (!$util.isInteger(message.numberDeliveredWithoutDelay))
                        return "numberDeliveredWithoutDelay: integer expected";
                if (message.numberDeliveredWithDelay != null && message.hasOwnProperty("numberDeliveredWithDelay"))
                    if (!$util.isInteger(message.numberDeliveredWithDelay))
                        return "numberDeliveredWithDelay: integer expected";
                if (message.averageDeliveryTime != null && message.hasOwnProperty("averageDeliveryTime"))
                    if (!$util.isInteger(message.averageDeliveryTime))
                        return "averageDeliveryTime: integer expected";
                return null;
            };

            /**
             * Creates a DeliveryStatDay message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DeliveryStatDay
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DeliveryStatDay} DeliveryStatDay
             */
            DeliveryStatDay.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DeliveryStatDay)
                    return object;
                var message = new $root.fastcity.admin.DeliveryStatDay();
                if (object.courierId != null)
                    if ($util.Long)
                        (message.courierId = $util.Long.fromValue(object.courierId)).unsigned = true;
                    else if (typeof object.courierId === "string")
                        message.courierId = parseInt(object.courierId, 10);
                    else if (typeof object.courierId === "number")
                        message.courierId = object.courierId;
                    else if (typeof object.courierId === "object")
                        message.courierId = new $util.LongBits(object.courierId.low >>> 0, object.courierId.high >>> 0).toNumber(true);
                if (object.timestampWorkingDay != null)
                    if ($util.Long)
                        (message.timestampWorkingDay = $util.Long.fromValue(object.timestampWorkingDay)).unsigned = false;
                    else if (typeof object.timestampWorkingDay === "string")
                        message.timestampWorkingDay = parseInt(object.timestampWorkingDay, 10);
                    else if (typeof object.timestampWorkingDay === "number")
                        message.timestampWorkingDay = object.timestampWorkingDay;
                    else if (typeof object.timestampWorkingDay === "object")
                        message.timestampWorkingDay = new $util.LongBits(object.timestampWorkingDay.low >>> 0, object.timestampWorkingDay.high >>> 0).toNumber();
                if (object.numberDelivered != null)
                    message.numberDelivered = object.numberDelivered >>> 0;
                if (object.numberDeliveredWithoutDelay != null)
                    message.numberDeliveredWithoutDelay = object.numberDeliveredWithoutDelay >>> 0;
                if (object.numberDeliveredWithDelay != null)
                    message.numberDeliveredWithDelay = object.numberDeliveredWithDelay >>> 0;
                if (object.averageDeliveryTime != null)
                    message.averageDeliveryTime = object.averageDeliveryTime >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a DeliveryStatDay message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DeliveryStatDay
             * @static
             * @param {fastcity.admin.DeliveryStatDay} message DeliveryStatDay
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeliveryStatDay.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.courierId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.courierId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampWorkingDay = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampWorkingDay = options.longs === String ? "0" : 0;
                    object.numberDelivered = 0;
                    object.numberDeliveredWithoutDelay = 0;
                    object.numberDeliveredWithDelay = 0;
                    object.averageDeliveryTime = 0;
                }
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (typeof message.courierId === "number")
                        object.courierId = options.longs === String ? String(message.courierId) : message.courierId;
                    else
                        object.courierId = options.longs === String ? $util.Long.prototype.toString.call(message.courierId) : options.longs === Number ? new $util.LongBits(message.courierId.low >>> 0, message.courierId.high >>> 0).toNumber(true) : message.courierId;
                if (message.timestampWorkingDay != null && message.hasOwnProperty("timestampWorkingDay"))
                    if (typeof message.timestampWorkingDay === "number")
                        object.timestampWorkingDay = options.longs === String ? String(message.timestampWorkingDay) : message.timestampWorkingDay;
                    else
                        object.timestampWorkingDay = options.longs === String ? $util.Long.prototype.toString.call(message.timestampWorkingDay) : options.longs === Number ? new $util.LongBits(message.timestampWorkingDay.low >>> 0, message.timestampWorkingDay.high >>> 0).toNumber() : message.timestampWorkingDay;
                if (message.numberDelivered != null && message.hasOwnProperty("numberDelivered"))
                    object.numberDelivered = message.numberDelivered;
                if (message.numberDeliveredWithoutDelay != null && message.hasOwnProperty("numberDeliveredWithoutDelay"))
                    object.numberDeliveredWithoutDelay = message.numberDeliveredWithoutDelay;
                if (message.numberDeliveredWithDelay != null && message.hasOwnProperty("numberDeliveredWithDelay"))
                    object.numberDeliveredWithDelay = message.numberDeliveredWithDelay;
                if (message.averageDeliveryTime != null && message.hasOwnProperty("averageDeliveryTime"))
                    object.averageDeliveryTime = message.averageDeliveryTime;
                return object;
            };

            /**
             * Converts this DeliveryStatDay to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DeliveryStatDay
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeliveryStatDay.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeliveryStatDay;
        })();

        admin.DeliveryStat = (function() {

            /**
             * Properties of a DeliveryStat.
             * @memberof fastcity.admin
             * @interface IDeliveryStat
             * @property {fastcity.admin.IDeliveryStatDay|null} [currentStatDay] DeliveryStat currentStatDay
             * @property {number|null} [averageDeliveryTime_1w] DeliveryStat averageDeliveryTime_1w
             * @property {number|null} [averageDeliveryTime_1m] DeliveryStat averageDeliveryTime_1m
             * @property {number|null} [maximumNumberDelivered] DeliveryStat maximumNumberDelivered
             * @property {number|null} [totalNumberDelivered] DeliveryStat totalNumberDelivered
             */

            /**
             * Constructs a new DeliveryStat.
             * @memberof fastcity.admin
             * @classdesc Represents a DeliveryStat.
             * @implements IDeliveryStat
             * @constructor
             * @param {fastcity.admin.IDeliveryStat=} [properties] Properties to set
             */
            function DeliveryStat(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeliveryStat currentStatDay.
             * @member {fastcity.admin.IDeliveryStatDay|null|undefined} currentStatDay
             * @memberof fastcity.admin.DeliveryStat
             * @instance
             */
            DeliveryStat.prototype.currentStatDay = null;

            /**
             * DeliveryStat averageDeliveryTime_1w.
             * @member {number} averageDeliveryTime_1w
             * @memberof fastcity.admin.DeliveryStat
             * @instance
             */
            DeliveryStat.prototype.averageDeliveryTime_1w = 0;

            /**
             * DeliveryStat averageDeliveryTime_1m.
             * @member {number} averageDeliveryTime_1m
             * @memberof fastcity.admin.DeliveryStat
             * @instance
             */
            DeliveryStat.prototype.averageDeliveryTime_1m = 0;

            /**
             * DeliveryStat maximumNumberDelivered.
             * @member {number} maximumNumberDelivered
             * @memberof fastcity.admin.DeliveryStat
             * @instance
             */
            DeliveryStat.prototype.maximumNumberDelivered = 0;

            /**
             * DeliveryStat totalNumberDelivered.
             * @member {number} totalNumberDelivered
             * @memberof fastcity.admin.DeliveryStat
             * @instance
             */
            DeliveryStat.prototype.totalNumberDelivered = 0;

            /**
             * Creates a new DeliveryStat instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DeliveryStat
             * @static
             * @param {fastcity.admin.IDeliveryStat=} [properties] Properties to set
             * @returns {fastcity.admin.DeliveryStat} DeliveryStat instance
             */
            DeliveryStat.create = function create(properties) {
                return new DeliveryStat(properties);
            };

            /**
             * Encodes the specified DeliveryStat message. Does not implicitly {@link fastcity.admin.DeliveryStat.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DeliveryStat
             * @static
             * @param {fastcity.admin.IDeliveryStat} message DeliveryStat message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeliveryStat.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.currentStatDay != null && Object.hasOwnProperty.call(message, "currentStatDay"))
                    $root.fastcity.admin.DeliveryStatDay.encode(message.currentStatDay, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.averageDeliveryTime_1w != null && Object.hasOwnProperty.call(message, "averageDeliveryTime_1w"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.averageDeliveryTime_1w);
                if (message.averageDeliveryTime_1m != null && Object.hasOwnProperty.call(message, "averageDeliveryTime_1m"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.averageDeliveryTime_1m);
                if (message.maximumNumberDelivered != null && Object.hasOwnProperty.call(message, "maximumNumberDelivered"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.maximumNumberDelivered);
                if (message.totalNumberDelivered != null && Object.hasOwnProperty.call(message, "totalNumberDelivered"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.totalNumberDelivered);
                return writer;
            };

            /**
             * Decodes a DeliveryStat message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DeliveryStat
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DeliveryStat} DeliveryStat
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeliveryStat.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DeliveryStat();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.currentStatDay = $root.fastcity.admin.DeliveryStatDay.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.averageDeliveryTime_1w = reader.uint32();
                        break;
                    case 3:
                        message.averageDeliveryTime_1m = reader.uint32();
                        break;
                    case 4:
                        message.maximumNumberDelivered = reader.uint32();
                        break;
                    case 5:
                        message.totalNumberDelivered = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeliveryStat message.
             * @function verify
             * @memberof fastcity.admin.DeliveryStat
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeliveryStat.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.currentStatDay != null && message.hasOwnProperty("currentStatDay")) {
                    var error = $root.fastcity.admin.DeliveryStatDay.verify(message.currentStatDay);
                    if (error)
                        return "currentStatDay." + error;
                }
                if (message.averageDeliveryTime_1w != null && message.hasOwnProperty("averageDeliveryTime_1w"))
                    if (!$util.isInteger(message.averageDeliveryTime_1w))
                        return "averageDeliveryTime_1w: integer expected";
                if (message.averageDeliveryTime_1m != null && message.hasOwnProperty("averageDeliveryTime_1m"))
                    if (!$util.isInteger(message.averageDeliveryTime_1m))
                        return "averageDeliveryTime_1m: integer expected";
                if (message.maximumNumberDelivered != null && message.hasOwnProperty("maximumNumberDelivered"))
                    if (!$util.isInteger(message.maximumNumberDelivered))
                        return "maximumNumberDelivered: integer expected";
                if (message.totalNumberDelivered != null && message.hasOwnProperty("totalNumberDelivered"))
                    if (!$util.isInteger(message.totalNumberDelivered))
                        return "totalNumberDelivered: integer expected";
                return null;
            };

            /**
             * Creates a DeliveryStat message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DeliveryStat
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DeliveryStat} DeliveryStat
             */
            DeliveryStat.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DeliveryStat)
                    return object;
                var message = new $root.fastcity.admin.DeliveryStat();
                if (object.currentStatDay != null) {
                    if (typeof object.currentStatDay !== "object")
                        throw TypeError(".fastcity.admin.DeliveryStat.currentStatDay: object expected");
                    message.currentStatDay = $root.fastcity.admin.DeliveryStatDay.fromObject(object.currentStatDay);
                }
                if (object.averageDeliveryTime_1w != null)
                    message.averageDeliveryTime_1w = object.averageDeliveryTime_1w >>> 0;
                if (object.averageDeliveryTime_1m != null)
                    message.averageDeliveryTime_1m = object.averageDeliveryTime_1m >>> 0;
                if (object.maximumNumberDelivered != null)
                    message.maximumNumberDelivered = object.maximumNumberDelivered >>> 0;
                if (object.totalNumberDelivered != null)
                    message.totalNumberDelivered = object.totalNumberDelivered >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a DeliveryStat message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DeliveryStat
             * @static
             * @param {fastcity.admin.DeliveryStat} message DeliveryStat
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeliveryStat.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.currentStatDay = null;
                    object.averageDeliveryTime_1w = 0;
                    object.averageDeliveryTime_1m = 0;
                    object.maximumNumberDelivered = 0;
                    object.totalNumberDelivered = 0;
                }
                if (message.currentStatDay != null && message.hasOwnProperty("currentStatDay"))
                    object.currentStatDay = $root.fastcity.admin.DeliveryStatDay.toObject(message.currentStatDay, options);
                if (message.averageDeliveryTime_1w != null && message.hasOwnProperty("averageDeliveryTime_1w"))
                    object.averageDeliveryTime_1w = message.averageDeliveryTime_1w;
                if (message.averageDeliveryTime_1m != null && message.hasOwnProperty("averageDeliveryTime_1m"))
                    object.averageDeliveryTime_1m = message.averageDeliveryTime_1m;
                if (message.maximumNumberDelivered != null && message.hasOwnProperty("maximumNumberDelivered"))
                    object.maximumNumberDelivered = message.maximumNumberDelivered;
                if (message.totalNumberDelivered != null && message.hasOwnProperty("totalNumberDelivered"))
                    object.totalNumberDelivered = message.totalNumberDelivered;
                return object;
            };

            /**
             * Converts this DeliveryStat to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DeliveryStat
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeliveryStat.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeliveryStat;
        })();

        admin.DeliveryStatList = (function() {

            /**
             * Properties of a DeliveryStatList.
             * @memberof fastcity.admin
             * @interface IDeliveryStatList
             * @property {Array.<fastcity.admin.IDeliveryStat>|null} [deliveryStat] DeliveryStatList deliveryStat
             */

            /**
             * Constructs a new DeliveryStatList.
             * @memberof fastcity.admin
             * @classdesc Represents a DeliveryStatList.
             * @implements IDeliveryStatList
             * @constructor
             * @param {fastcity.admin.IDeliveryStatList=} [properties] Properties to set
             */
            function DeliveryStatList(properties) {
                this.deliveryStat = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeliveryStatList deliveryStat.
             * @member {Array.<fastcity.admin.IDeliveryStat>} deliveryStat
             * @memberof fastcity.admin.DeliveryStatList
             * @instance
             */
            DeliveryStatList.prototype.deliveryStat = $util.emptyArray;

            /**
             * Creates a new DeliveryStatList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DeliveryStatList
             * @static
             * @param {fastcity.admin.IDeliveryStatList=} [properties] Properties to set
             * @returns {fastcity.admin.DeliveryStatList} DeliveryStatList instance
             */
            DeliveryStatList.create = function create(properties) {
                return new DeliveryStatList(properties);
            };

            /**
             * Encodes the specified DeliveryStatList message. Does not implicitly {@link fastcity.admin.DeliveryStatList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DeliveryStatList
             * @static
             * @param {fastcity.admin.IDeliveryStatList} message DeliveryStatList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeliveryStatList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deliveryStat != null && message.deliveryStat.length)
                    for (var i = 0; i < message.deliveryStat.length; ++i)
                        $root.fastcity.admin.DeliveryStat.encode(message.deliveryStat[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a DeliveryStatList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DeliveryStatList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DeliveryStatList} DeliveryStatList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeliveryStatList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DeliveryStatList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.deliveryStat && message.deliveryStat.length))
                            message.deliveryStat = [];
                        message.deliveryStat.push($root.fastcity.admin.DeliveryStat.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeliveryStatList message.
             * @function verify
             * @memberof fastcity.admin.DeliveryStatList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeliveryStatList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deliveryStat != null && message.hasOwnProperty("deliveryStat")) {
                    if (!Array.isArray(message.deliveryStat))
                        return "deliveryStat: array expected";
                    for (var i = 0; i < message.deliveryStat.length; ++i) {
                        var error = $root.fastcity.admin.DeliveryStat.verify(message.deliveryStat[i]);
                        if (error)
                            return "deliveryStat." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a DeliveryStatList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DeliveryStatList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DeliveryStatList} DeliveryStatList
             */
            DeliveryStatList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DeliveryStatList)
                    return object;
                var message = new $root.fastcity.admin.DeliveryStatList();
                if (object.deliveryStat) {
                    if (!Array.isArray(object.deliveryStat))
                        throw TypeError(".fastcity.admin.DeliveryStatList.deliveryStat: array expected");
                    message.deliveryStat = [];
                    for (var i = 0; i < object.deliveryStat.length; ++i) {
                        if (typeof object.deliveryStat[i] !== "object")
                            throw TypeError(".fastcity.admin.DeliveryStatList.deliveryStat: object expected");
                        message.deliveryStat[i] = $root.fastcity.admin.DeliveryStat.fromObject(object.deliveryStat[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a DeliveryStatList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DeliveryStatList
             * @static
             * @param {fastcity.admin.DeliveryStatList} message DeliveryStatList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeliveryStatList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.deliveryStat = [];
                if (message.deliveryStat && message.deliveryStat.length) {
                    object.deliveryStat = [];
                    for (var j = 0; j < message.deliveryStat.length; ++j)
                        object.deliveryStat[j] = $root.fastcity.admin.DeliveryStat.toObject(message.deliveryStat[j], options);
                }
                return object;
            };

            /**
             * Converts this DeliveryStatList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DeliveryStatList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeliveryStatList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeliveryStatList;
        })();

        admin.DeliveryStatDayList = (function() {

            /**
             * Properties of a DeliveryStatDayList.
             * @memberof fastcity.admin
             * @interface IDeliveryStatDayList
             * @property {Array.<fastcity.admin.IDeliveryStatDay>|null} [deliveryStatDay] DeliveryStatDayList deliveryStatDay
             * @property {number|null} [totalDeliveryStatDays] DeliveryStatDayList totalDeliveryStatDays
             */

            /**
             * Constructs a new DeliveryStatDayList.
             * @memberof fastcity.admin
             * @classdesc Represents a DeliveryStatDayList.
             * @implements IDeliveryStatDayList
             * @constructor
             * @param {fastcity.admin.IDeliveryStatDayList=} [properties] Properties to set
             */
            function DeliveryStatDayList(properties) {
                this.deliveryStatDay = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeliveryStatDayList deliveryStatDay.
             * @member {Array.<fastcity.admin.IDeliveryStatDay>} deliveryStatDay
             * @memberof fastcity.admin.DeliveryStatDayList
             * @instance
             */
            DeliveryStatDayList.prototype.deliveryStatDay = $util.emptyArray;

            /**
             * DeliveryStatDayList totalDeliveryStatDays.
             * @member {number} totalDeliveryStatDays
             * @memberof fastcity.admin.DeliveryStatDayList
             * @instance
             */
            DeliveryStatDayList.prototype.totalDeliveryStatDays = 0;

            /**
             * Creates a new DeliveryStatDayList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DeliveryStatDayList
             * @static
             * @param {fastcity.admin.IDeliveryStatDayList=} [properties] Properties to set
             * @returns {fastcity.admin.DeliveryStatDayList} DeliveryStatDayList instance
             */
            DeliveryStatDayList.create = function create(properties) {
                return new DeliveryStatDayList(properties);
            };

            /**
             * Encodes the specified DeliveryStatDayList message. Does not implicitly {@link fastcity.admin.DeliveryStatDayList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DeliveryStatDayList
             * @static
             * @param {fastcity.admin.IDeliveryStatDayList} message DeliveryStatDayList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeliveryStatDayList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deliveryStatDay != null && message.deliveryStatDay.length)
                    for (var i = 0; i < message.deliveryStatDay.length; ++i)
                        $root.fastcity.admin.DeliveryStatDay.encode(message.deliveryStatDay[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.totalDeliveryStatDays != null && Object.hasOwnProperty.call(message, "totalDeliveryStatDays"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.totalDeliveryStatDays);
                return writer;
            };

            /**
             * Decodes a DeliveryStatDayList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DeliveryStatDayList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DeliveryStatDayList} DeliveryStatDayList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeliveryStatDayList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DeliveryStatDayList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.deliveryStatDay && message.deliveryStatDay.length))
                            message.deliveryStatDay = [];
                        message.deliveryStatDay.push($root.fastcity.admin.DeliveryStatDay.decode(reader, reader.uint32()));
                        break;
                    case 2:
                        message.totalDeliveryStatDays = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeliveryStatDayList message.
             * @function verify
             * @memberof fastcity.admin.DeliveryStatDayList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeliveryStatDayList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deliveryStatDay != null && message.hasOwnProperty("deliveryStatDay")) {
                    if (!Array.isArray(message.deliveryStatDay))
                        return "deliveryStatDay: array expected";
                    for (var i = 0; i < message.deliveryStatDay.length; ++i) {
                        var error = $root.fastcity.admin.DeliveryStatDay.verify(message.deliveryStatDay[i]);
                        if (error)
                            return "deliveryStatDay." + error;
                    }
                }
                if (message.totalDeliveryStatDays != null && message.hasOwnProperty("totalDeliveryStatDays"))
                    if (!$util.isInteger(message.totalDeliveryStatDays))
                        return "totalDeliveryStatDays: integer expected";
                return null;
            };

            /**
             * Creates a DeliveryStatDayList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DeliveryStatDayList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DeliveryStatDayList} DeliveryStatDayList
             */
            DeliveryStatDayList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DeliveryStatDayList)
                    return object;
                var message = new $root.fastcity.admin.DeliveryStatDayList();
                if (object.deliveryStatDay) {
                    if (!Array.isArray(object.deliveryStatDay))
                        throw TypeError(".fastcity.admin.DeliveryStatDayList.deliveryStatDay: array expected");
                    message.deliveryStatDay = [];
                    for (var i = 0; i < object.deliveryStatDay.length; ++i) {
                        if (typeof object.deliveryStatDay[i] !== "object")
                            throw TypeError(".fastcity.admin.DeliveryStatDayList.deliveryStatDay: object expected");
                        message.deliveryStatDay[i] = $root.fastcity.admin.DeliveryStatDay.fromObject(object.deliveryStatDay[i]);
                    }
                }
                if (object.totalDeliveryStatDays != null)
                    message.totalDeliveryStatDays = object.totalDeliveryStatDays >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a DeliveryStatDayList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DeliveryStatDayList
             * @static
             * @param {fastcity.admin.DeliveryStatDayList} message DeliveryStatDayList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeliveryStatDayList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.deliveryStatDay = [];
                if (options.defaults)
                    object.totalDeliveryStatDays = 0;
                if (message.deliveryStatDay && message.deliveryStatDay.length) {
                    object.deliveryStatDay = [];
                    for (var j = 0; j < message.deliveryStatDay.length; ++j)
                        object.deliveryStatDay[j] = $root.fastcity.admin.DeliveryStatDay.toObject(message.deliveryStatDay[j], options);
                }
                if (message.totalDeliveryStatDays != null && message.hasOwnProperty("totalDeliveryStatDays"))
                    object.totalDeliveryStatDays = message.totalDeliveryStatDays;
                return object;
            };

            /**
             * Converts this DeliveryStatDayList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DeliveryStatDayList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeliveryStatDayList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeliveryStatDayList;
        })();

        admin.GetDeliveryStatDayList = (function() {

            /**
             * Properties of a GetDeliveryStatDayList.
             * @memberof fastcity.admin
             * @interface IGetDeliveryStatDayList
             * @property {fastcity.admin.GetDeliveryStatDayList.ITimeInterval|null} [timeInterval] GetDeliveryStatDayList timeInterval
             */

            /**
             * Constructs a new GetDeliveryStatDayList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetDeliveryStatDayList.
             * @implements IGetDeliveryStatDayList
             * @constructor
             * @param {fastcity.admin.IGetDeliveryStatDayList=} [properties] Properties to set
             */
            function GetDeliveryStatDayList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetDeliveryStatDayList timeInterval.
             * @member {fastcity.admin.GetDeliveryStatDayList.ITimeInterval|null|undefined} timeInterval
             * @memberof fastcity.admin.GetDeliveryStatDayList
             * @instance
             */
            GetDeliveryStatDayList.prototype.timeInterval = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * GetDeliveryStatDayList type.
             * @member {"timeInterval"|undefined} type
             * @memberof fastcity.admin.GetDeliveryStatDayList
             * @instance
             */
            Object.defineProperty(GetDeliveryStatDayList.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["timeInterval"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new GetDeliveryStatDayList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetDeliveryStatDayList
             * @static
             * @param {fastcity.admin.IGetDeliveryStatDayList=} [properties] Properties to set
             * @returns {fastcity.admin.GetDeliveryStatDayList} GetDeliveryStatDayList instance
             */
            GetDeliveryStatDayList.create = function create(properties) {
                return new GetDeliveryStatDayList(properties);
            };

            /**
             * Encodes the specified GetDeliveryStatDayList message. Does not implicitly {@link fastcity.admin.GetDeliveryStatDayList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetDeliveryStatDayList
             * @static
             * @param {fastcity.admin.IGetDeliveryStatDayList} message GetDeliveryStatDayList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetDeliveryStatDayList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.timeInterval != null && Object.hasOwnProperty.call(message, "timeInterval"))
                    $root.fastcity.admin.GetDeliveryStatDayList.TimeInterval.encode(message.timeInterval, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a GetDeliveryStatDayList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetDeliveryStatDayList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetDeliveryStatDayList} GetDeliveryStatDayList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetDeliveryStatDayList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetDeliveryStatDayList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.timeInterval = $root.fastcity.admin.GetDeliveryStatDayList.TimeInterval.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetDeliveryStatDayList message.
             * @function verify
             * @memberof fastcity.admin.GetDeliveryStatDayList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetDeliveryStatDayList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.timeInterval != null && message.hasOwnProperty("timeInterval")) {
                    properties.type = 1;
                    {
                        var error = $root.fastcity.admin.GetDeliveryStatDayList.TimeInterval.verify(message.timeInterval);
                        if (error)
                            return "timeInterval." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GetDeliveryStatDayList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetDeliveryStatDayList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetDeliveryStatDayList} GetDeliveryStatDayList
             */
            GetDeliveryStatDayList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetDeliveryStatDayList)
                    return object;
                var message = new $root.fastcity.admin.GetDeliveryStatDayList();
                if (object.timeInterval != null) {
                    if (typeof object.timeInterval !== "object")
                        throw TypeError(".fastcity.admin.GetDeliveryStatDayList.timeInterval: object expected");
                    message.timeInterval = $root.fastcity.admin.GetDeliveryStatDayList.TimeInterval.fromObject(object.timeInterval);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetDeliveryStatDayList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetDeliveryStatDayList
             * @static
             * @param {fastcity.admin.GetDeliveryStatDayList} message GetDeliveryStatDayList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetDeliveryStatDayList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.timeInterval != null && message.hasOwnProperty("timeInterval")) {
                    object.timeInterval = $root.fastcity.admin.GetDeliveryStatDayList.TimeInterval.toObject(message.timeInterval, options);
                    if (options.oneofs)
                        object.type = "timeInterval";
                }
                return object;
            };

            /**
             * Converts this GetDeliveryStatDayList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetDeliveryStatDayList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetDeliveryStatDayList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            GetDeliveryStatDayList.TimeInterval = (function() {

                /**
                 * Properties of a TimeInterval.
                 * @memberof fastcity.admin.GetDeliveryStatDayList
                 * @interface ITimeInterval
                 * @property {Array.<number|Long>|null} [courierId] TimeInterval courierId
                 * @property {number|Long|null} [fromTime] TimeInterval fromTime
                 * @property {number|Long|null} [toTime] TimeInterval toTime
                 * @property {number|null} [offset] TimeInterval offset
                 * @property {number|null} [limit] TimeInterval limit
                 * @property {fastcity.common.SortOrder.Order|null} [sortOrder] TimeInterval sortOrder
                 */

                /**
                 * Constructs a new TimeInterval.
                 * @memberof fastcity.admin.GetDeliveryStatDayList
                 * @classdesc Represents a TimeInterval.
                 * @implements ITimeInterval
                 * @constructor
                 * @param {fastcity.admin.GetDeliveryStatDayList.ITimeInterval=} [properties] Properties to set
                 */
                function TimeInterval(properties) {
                    this.courierId = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TimeInterval courierId.
                 * @member {Array.<number|Long>} courierId
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.courierId = $util.emptyArray;

                /**
                 * TimeInterval fromTime.
                 * @member {number|Long} fromTime
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.fromTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TimeInterval toTime.
                 * @member {number|Long} toTime
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.toTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TimeInterval offset.
                 * @member {number} offset
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.offset = 0;

                /**
                 * TimeInterval limit.
                 * @member {number} limit
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.limit = 0;

                /**
                 * TimeInterval sortOrder.
                 * @member {fastcity.common.SortOrder.Order} sortOrder
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.sortOrder = 0;

                /**
                 * Creates a new TimeInterval instance using the specified properties.
                 * @function create
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @static
                 * @param {fastcity.admin.GetDeliveryStatDayList.ITimeInterval=} [properties] Properties to set
                 * @returns {fastcity.admin.GetDeliveryStatDayList.TimeInterval} TimeInterval instance
                 */
                TimeInterval.create = function create(properties) {
                    return new TimeInterval(properties);
                };

                /**
                 * Encodes the specified TimeInterval message. Does not implicitly {@link fastcity.admin.GetDeliveryStatDayList.TimeInterval.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @static
                 * @param {fastcity.admin.GetDeliveryStatDayList.ITimeInterval} message TimeInterval message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TimeInterval.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.courierId != null && message.courierId.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.courierId.length; ++i)
                            writer.uint64(message.courierId[i]);
                        writer.ldelim();
                    }
                    if (message.fromTime != null && Object.hasOwnProperty.call(message, "fromTime"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.fromTime);
                    if (message.toTime != null && Object.hasOwnProperty.call(message, "toTime"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.toTime);
                    if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                        writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.offset);
                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                        writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.limit);
                    if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.sortOrder);
                    return writer;
                };

                /**
                 * Decodes a TimeInterval message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.admin.GetDeliveryStatDayList.TimeInterval} TimeInterval
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TimeInterval.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetDeliveryStatDayList.TimeInterval();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.courierId && message.courierId.length))
                                message.courierId = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.courierId.push(reader.uint64());
                            } else
                                message.courierId.push(reader.uint64());
                            break;
                        case 2:
                            message.fromTime = reader.int64();
                            break;
                        case 3:
                            message.toTime = reader.int64();
                            break;
                        case 4:
                            message.offset = reader.uint32();
                            break;
                        case 5:
                            message.limit = reader.uint32();
                            break;
                        case 6:
                            message.sortOrder = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a TimeInterval message.
                 * @function verify
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TimeInterval.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.courierId != null && message.hasOwnProperty("courierId")) {
                        if (!Array.isArray(message.courierId))
                            return "courierId: array expected";
                        for (var i = 0; i < message.courierId.length; ++i)
                            if (!$util.isInteger(message.courierId[i]) && !(message.courierId[i] && $util.isInteger(message.courierId[i].low) && $util.isInteger(message.courierId[i].high)))
                                return "courierId: integer|Long[] expected";
                    }
                    if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                        if (!$util.isInteger(message.fromTime) && !(message.fromTime && $util.isInteger(message.fromTime.low) && $util.isInteger(message.fromTime.high)))
                            return "fromTime: integer|Long expected";
                    if (message.toTime != null && message.hasOwnProperty("toTime"))
                        if (!$util.isInteger(message.toTime) && !(message.toTime && $util.isInteger(message.toTime.low) && $util.isInteger(message.toTime.high)))
                            return "toTime: integer|Long expected";
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        if (!$util.isInteger(message.offset))
                            return "offset: integer expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit))
                            return "limit: integer expected";
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        switch (message.sortOrder) {
                        default:
                            return "sortOrder: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a TimeInterval message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.admin.GetDeliveryStatDayList.TimeInterval} TimeInterval
                 */
                TimeInterval.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.admin.GetDeliveryStatDayList.TimeInterval)
                        return object;
                    var message = new $root.fastcity.admin.GetDeliveryStatDayList.TimeInterval();
                    if (object.courierId) {
                        if (!Array.isArray(object.courierId))
                            throw TypeError(".fastcity.admin.GetDeliveryStatDayList.TimeInterval.courierId: array expected");
                        message.courierId = [];
                        for (var i = 0; i < object.courierId.length; ++i)
                            if ($util.Long)
                                (message.courierId[i] = $util.Long.fromValue(object.courierId[i])).unsigned = true;
                            else if (typeof object.courierId[i] === "string")
                                message.courierId[i] = parseInt(object.courierId[i], 10);
                            else if (typeof object.courierId[i] === "number")
                                message.courierId[i] = object.courierId[i];
                            else if (typeof object.courierId[i] === "object")
                                message.courierId[i] = new $util.LongBits(object.courierId[i].low >>> 0, object.courierId[i].high >>> 0).toNumber(true);
                    }
                    if (object.fromTime != null)
                        if ($util.Long)
                            (message.fromTime = $util.Long.fromValue(object.fromTime)).unsigned = false;
                        else if (typeof object.fromTime === "string")
                            message.fromTime = parseInt(object.fromTime, 10);
                        else if (typeof object.fromTime === "number")
                            message.fromTime = object.fromTime;
                        else if (typeof object.fromTime === "object")
                            message.fromTime = new $util.LongBits(object.fromTime.low >>> 0, object.fromTime.high >>> 0).toNumber();
                    if (object.toTime != null)
                        if ($util.Long)
                            (message.toTime = $util.Long.fromValue(object.toTime)).unsigned = false;
                        else if (typeof object.toTime === "string")
                            message.toTime = parseInt(object.toTime, 10);
                        else if (typeof object.toTime === "number")
                            message.toTime = object.toTime;
                        else if (typeof object.toTime === "object")
                            message.toTime = new $util.LongBits(object.toTime.low >>> 0, object.toTime.high >>> 0).toNumber();
                    if (object.offset != null)
                        message.offset = object.offset >>> 0;
                    if (object.limit != null)
                        message.limit = object.limit >>> 0;
                    switch (object.sortOrder) {
                    case "ASC":
                    case 0:
                        message.sortOrder = 0;
                        break;
                    case "DESC":
                    case 1:
                        message.sortOrder = 1;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a TimeInterval message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @static
                 * @param {fastcity.admin.GetDeliveryStatDayList.TimeInterval} message TimeInterval
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TimeInterval.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.courierId = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.fromTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.fromTime = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.toTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.toTime = options.longs === String ? "0" : 0;
                        object.offset = 0;
                        object.limit = 0;
                        object.sortOrder = options.enums === String ? "ASC" : 0;
                    }
                    if (message.courierId && message.courierId.length) {
                        object.courierId = [];
                        for (var j = 0; j < message.courierId.length; ++j)
                            if (typeof message.courierId[j] === "number")
                                object.courierId[j] = options.longs === String ? String(message.courierId[j]) : message.courierId[j];
                            else
                                object.courierId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.courierId[j]) : options.longs === Number ? new $util.LongBits(message.courierId[j].low >>> 0, message.courierId[j].high >>> 0).toNumber(true) : message.courierId[j];
                    }
                    if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                        if (typeof message.fromTime === "number")
                            object.fromTime = options.longs === String ? String(message.fromTime) : message.fromTime;
                        else
                            object.fromTime = options.longs === String ? $util.Long.prototype.toString.call(message.fromTime) : options.longs === Number ? new $util.LongBits(message.fromTime.low >>> 0, message.fromTime.high >>> 0).toNumber() : message.fromTime;
                    if (message.toTime != null && message.hasOwnProperty("toTime"))
                        if (typeof message.toTime === "number")
                            object.toTime = options.longs === String ? String(message.toTime) : message.toTime;
                        else
                            object.toTime = options.longs === String ? $util.Long.prototype.toString.call(message.toTime) : options.longs === Number ? new $util.LongBits(message.toTime.low >>> 0, message.toTime.high >>> 0).toNumber() : message.toTime;
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        object.offset = message.offset;
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        object.limit = message.limit;
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        object.sortOrder = options.enums === String ? $root.fastcity.common.SortOrder.Order[message.sortOrder] : message.sortOrder;
                    return object;
                };

                /**
                 * Converts this TimeInterval to JSON.
                 * @function toJSON
                 * @memberof fastcity.admin.GetDeliveryStatDayList.TimeInterval
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TimeInterval.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TimeInterval;
            })();

            return GetDeliveryStatDayList;
        })();

        admin.GetDeliveryStatList = (function() {

            /**
             * Properties of a GetDeliveryStatList.
             * @memberof fastcity.admin
             * @interface IGetDeliveryStatList
             * @property {fastcity.admin.GetDeliveryStatList.ITimestamp|null} [timestamp] GetDeliveryStatList timestamp
             */

            /**
             * Constructs a new GetDeliveryStatList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetDeliveryStatList.
             * @implements IGetDeliveryStatList
             * @constructor
             * @param {fastcity.admin.IGetDeliveryStatList=} [properties] Properties to set
             */
            function GetDeliveryStatList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetDeliveryStatList timestamp.
             * @member {fastcity.admin.GetDeliveryStatList.ITimestamp|null|undefined} timestamp
             * @memberof fastcity.admin.GetDeliveryStatList
             * @instance
             */
            GetDeliveryStatList.prototype.timestamp = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * GetDeliveryStatList type.
             * @member {"timestamp"|undefined} type
             * @memberof fastcity.admin.GetDeliveryStatList
             * @instance
             */
            Object.defineProperty(GetDeliveryStatList.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["timestamp"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new GetDeliveryStatList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetDeliveryStatList
             * @static
             * @param {fastcity.admin.IGetDeliveryStatList=} [properties] Properties to set
             * @returns {fastcity.admin.GetDeliveryStatList} GetDeliveryStatList instance
             */
            GetDeliveryStatList.create = function create(properties) {
                return new GetDeliveryStatList(properties);
            };

            /**
             * Encodes the specified GetDeliveryStatList message. Does not implicitly {@link fastcity.admin.GetDeliveryStatList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetDeliveryStatList
             * @static
             * @param {fastcity.admin.IGetDeliveryStatList} message GetDeliveryStatList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetDeliveryStatList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                    $root.fastcity.admin.GetDeliveryStatList.Timestamp.encode(message.timestamp, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a GetDeliveryStatList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetDeliveryStatList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetDeliveryStatList} GetDeliveryStatList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetDeliveryStatList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetDeliveryStatList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.timestamp = $root.fastcity.admin.GetDeliveryStatList.Timestamp.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetDeliveryStatList message.
             * @function verify
             * @memberof fastcity.admin.GetDeliveryStatList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetDeliveryStatList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.timestamp != null && message.hasOwnProperty("timestamp")) {
                    properties.type = 1;
                    {
                        var error = $root.fastcity.admin.GetDeliveryStatList.Timestamp.verify(message.timestamp);
                        if (error)
                            return "timestamp." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GetDeliveryStatList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetDeliveryStatList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetDeliveryStatList} GetDeliveryStatList
             */
            GetDeliveryStatList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetDeliveryStatList)
                    return object;
                var message = new $root.fastcity.admin.GetDeliveryStatList();
                if (object.timestamp != null) {
                    if (typeof object.timestamp !== "object")
                        throw TypeError(".fastcity.admin.GetDeliveryStatList.timestamp: object expected");
                    message.timestamp = $root.fastcity.admin.GetDeliveryStatList.Timestamp.fromObject(object.timestamp);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetDeliveryStatList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetDeliveryStatList
             * @static
             * @param {fastcity.admin.GetDeliveryStatList} message GetDeliveryStatList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetDeliveryStatList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.timestamp != null && message.hasOwnProperty("timestamp")) {
                    object.timestamp = $root.fastcity.admin.GetDeliveryStatList.Timestamp.toObject(message.timestamp, options);
                    if (options.oneofs)
                        object.type = "timestamp";
                }
                return object;
            };

            /**
             * Converts this GetDeliveryStatList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetDeliveryStatList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetDeliveryStatList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            GetDeliveryStatList.Timestamp = (function() {

                /**
                 * Properties of a Timestamp.
                 * @memberof fastcity.admin.GetDeliveryStatList
                 * @interface ITimestamp
                 * @property {Array.<number|Long>|null} [courierId] Timestamp courierId
                 * @property {number|Long|null} [timestampWorkingDay] Timestamp timestampWorkingDay
                 */

                /**
                 * Constructs a new Timestamp.
                 * @memberof fastcity.admin.GetDeliveryStatList
                 * @classdesc Represents a Timestamp.
                 * @implements ITimestamp
                 * @constructor
                 * @param {fastcity.admin.GetDeliveryStatList.ITimestamp=} [properties] Properties to set
                 */
                function Timestamp(properties) {
                    this.courierId = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Timestamp courierId.
                 * @member {Array.<number|Long>} courierId
                 * @memberof fastcity.admin.GetDeliveryStatList.Timestamp
                 * @instance
                 */
                Timestamp.prototype.courierId = $util.emptyArray;

                /**
                 * Timestamp timestampWorkingDay.
                 * @member {number|Long} timestampWorkingDay
                 * @memberof fastcity.admin.GetDeliveryStatList.Timestamp
                 * @instance
                 */
                Timestamp.prototype.timestampWorkingDay = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new Timestamp instance using the specified properties.
                 * @function create
                 * @memberof fastcity.admin.GetDeliveryStatList.Timestamp
                 * @static
                 * @param {fastcity.admin.GetDeliveryStatList.ITimestamp=} [properties] Properties to set
                 * @returns {fastcity.admin.GetDeliveryStatList.Timestamp} Timestamp instance
                 */
                Timestamp.create = function create(properties) {
                    return new Timestamp(properties);
                };

                /**
                 * Encodes the specified Timestamp message. Does not implicitly {@link fastcity.admin.GetDeliveryStatList.Timestamp.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.admin.GetDeliveryStatList.Timestamp
                 * @static
                 * @param {fastcity.admin.GetDeliveryStatList.ITimestamp} message Timestamp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Timestamp.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.courierId != null && message.courierId.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.courierId.length; ++i)
                            writer.uint64(message.courierId[i]);
                        writer.ldelim();
                    }
                    if (message.timestampWorkingDay != null && Object.hasOwnProperty.call(message, "timestampWorkingDay"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.timestampWorkingDay);
                    return writer;
                };

                /**
                 * Decodes a Timestamp message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.admin.GetDeliveryStatList.Timestamp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.admin.GetDeliveryStatList.Timestamp} Timestamp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Timestamp.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetDeliveryStatList.Timestamp();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.courierId && message.courierId.length))
                                message.courierId = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.courierId.push(reader.uint64());
                            } else
                                message.courierId.push(reader.uint64());
                            break;
                        case 2:
                            message.timestampWorkingDay = reader.int64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a Timestamp message.
                 * @function verify
                 * @memberof fastcity.admin.GetDeliveryStatList.Timestamp
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Timestamp.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.courierId != null && message.hasOwnProperty("courierId")) {
                        if (!Array.isArray(message.courierId))
                            return "courierId: array expected";
                        for (var i = 0; i < message.courierId.length; ++i)
                            if (!$util.isInteger(message.courierId[i]) && !(message.courierId[i] && $util.isInteger(message.courierId[i].low) && $util.isInteger(message.courierId[i].high)))
                                return "courierId: integer|Long[] expected";
                    }
                    if (message.timestampWorkingDay != null && message.hasOwnProperty("timestampWorkingDay"))
                        if (!$util.isInteger(message.timestampWorkingDay) && !(message.timestampWorkingDay && $util.isInteger(message.timestampWorkingDay.low) && $util.isInteger(message.timestampWorkingDay.high)))
                            return "timestampWorkingDay: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.admin.GetDeliveryStatList.Timestamp
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.admin.GetDeliveryStatList.Timestamp} Timestamp
                 */
                Timestamp.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.admin.GetDeliveryStatList.Timestamp)
                        return object;
                    var message = new $root.fastcity.admin.GetDeliveryStatList.Timestamp();
                    if (object.courierId) {
                        if (!Array.isArray(object.courierId))
                            throw TypeError(".fastcity.admin.GetDeliveryStatList.Timestamp.courierId: array expected");
                        message.courierId = [];
                        for (var i = 0; i < object.courierId.length; ++i)
                            if ($util.Long)
                                (message.courierId[i] = $util.Long.fromValue(object.courierId[i])).unsigned = true;
                            else if (typeof object.courierId[i] === "string")
                                message.courierId[i] = parseInt(object.courierId[i], 10);
                            else if (typeof object.courierId[i] === "number")
                                message.courierId[i] = object.courierId[i];
                            else if (typeof object.courierId[i] === "object")
                                message.courierId[i] = new $util.LongBits(object.courierId[i].low >>> 0, object.courierId[i].high >>> 0).toNumber(true);
                    }
                    if (object.timestampWorkingDay != null)
                        if ($util.Long)
                            (message.timestampWorkingDay = $util.Long.fromValue(object.timestampWorkingDay)).unsigned = false;
                        else if (typeof object.timestampWorkingDay === "string")
                            message.timestampWorkingDay = parseInt(object.timestampWorkingDay, 10);
                        else if (typeof object.timestampWorkingDay === "number")
                            message.timestampWorkingDay = object.timestampWorkingDay;
                        else if (typeof object.timestampWorkingDay === "object")
                            message.timestampWorkingDay = new $util.LongBits(object.timestampWorkingDay.low >>> 0, object.timestampWorkingDay.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.admin.GetDeliveryStatList.Timestamp
                 * @static
                 * @param {fastcity.admin.GetDeliveryStatList.Timestamp} message Timestamp
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Timestamp.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.courierId = [];
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.timestampWorkingDay = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.timestampWorkingDay = options.longs === String ? "0" : 0;
                    if (message.courierId && message.courierId.length) {
                        object.courierId = [];
                        for (var j = 0; j < message.courierId.length; ++j)
                            if (typeof message.courierId[j] === "number")
                                object.courierId[j] = options.longs === String ? String(message.courierId[j]) : message.courierId[j];
                            else
                                object.courierId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.courierId[j]) : options.longs === Number ? new $util.LongBits(message.courierId[j].low >>> 0, message.courierId[j].high >>> 0).toNumber(true) : message.courierId[j];
                    }
                    if (message.timestampWorkingDay != null && message.hasOwnProperty("timestampWorkingDay"))
                        if (typeof message.timestampWorkingDay === "number")
                            object.timestampWorkingDay = options.longs === String ? String(message.timestampWorkingDay) : message.timestampWorkingDay;
                        else
                            object.timestampWorkingDay = options.longs === String ? $util.Long.prototype.toString.call(message.timestampWorkingDay) : options.longs === Number ? new $util.LongBits(message.timestampWorkingDay.low >>> 0, message.timestampWorkingDay.high >>> 0).toNumber() : message.timestampWorkingDay;
                    return object;
                };

                /**
                 * Converts this Timestamp to JSON.
                 * @function toJSON
                 * @memberof fastcity.admin.GetDeliveryStatList.Timestamp
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Timestamp.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Timestamp;
            })();

            return GetDeliveryStatList;
        })();

        admin.WorkingDayList = (function() {

            /**
             * Properties of a WorkingDayList.
             * @memberof fastcity.admin
             * @interface IWorkingDayList
             * @property {Array.<number|Long>|null} [workingDay] WorkingDayList workingDay
             * @property {number|null} [totalWorkingDays] WorkingDayList totalWorkingDays
             */

            /**
             * Constructs a new WorkingDayList.
             * @memberof fastcity.admin
             * @classdesc Represents a WorkingDayList.
             * @implements IWorkingDayList
             * @constructor
             * @param {fastcity.admin.IWorkingDayList=} [properties] Properties to set
             */
            function WorkingDayList(properties) {
                this.workingDay = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WorkingDayList workingDay.
             * @member {Array.<number|Long>} workingDay
             * @memberof fastcity.admin.WorkingDayList
             * @instance
             */
            WorkingDayList.prototype.workingDay = $util.emptyArray;

            /**
             * WorkingDayList totalWorkingDays.
             * @member {number} totalWorkingDays
             * @memberof fastcity.admin.WorkingDayList
             * @instance
             */
            WorkingDayList.prototype.totalWorkingDays = 0;

            /**
             * Creates a new WorkingDayList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.WorkingDayList
             * @static
             * @param {fastcity.admin.IWorkingDayList=} [properties] Properties to set
             * @returns {fastcity.admin.WorkingDayList} WorkingDayList instance
             */
            WorkingDayList.create = function create(properties) {
                return new WorkingDayList(properties);
            };

            /**
             * Encodes the specified WorkingDayList message. Does not implicitly {@link fastcity.admin.WorkingDayList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.WorkingDayList
             * @static
             * @param {fastcity.admin.IWorkingDayList} message WorkingDayList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkingDayList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.workingDay != null && message.workingDay.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.workingDay.length; ++i)
                        writer.int64(message.workingDay[i]);
                    writer.ldelim();
                }
                if (message.totalWorkingDays != null && Object.hasOwnProperty.call(message, "totalWorkingDays"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.totalWorkingDays);
                return writer;
            };

            /**
             * Decodes a WorkingDayList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.WorkingDayList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.WorkingDayList} WorkingDayList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkingDayList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.WorkingDayList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.workingDay && message.workingDay.length))
                            message.workingDay = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.workingDay.push(reader.int64());
                        } else
                            message.workingDay.push(reader.int64());
                        break;
                    case 2:
                        message.totalWorkingDays = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a WorkingDayList message.
             * @function verify
             * @memberof fastcity.admin.WorkingDayList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkingDayList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.workingDay != null && message.hasOwnProperty("workingDay")) {
                    if (!Array.isArray(message.workingDay))
                        return "workingDay: array expected";
                    for (var i = 0; i < message.workingDay.length; ++i)
                        if (!$util.isInteger(message.workingDay[i]) && !(message.workingDay[i] && $util.isInteger(message.workingDay[i].low) && $util.isInteger(message.workingDay[i].high)))
                            return "workingDay: integer|Long[] expected";
                }
                if (message.totalWorkingDays != null && message.hasOwnProperty("totalWorkingDays"))
                    if (!$util.isInteger(message.totalWorkingDays))
                        return "totalWorkingDays: integer expected";
                return null;
            };

            /**
             * Creates a WorkingDayList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.WorkingDayList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.WorkingDayList} WorkingDayList
             */
            WorkingDayList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.WorkingDayList)
                    return object;
                var message = new $root.fastcity.admin.WorkingDayList();
                if (object.workingDay) {
                    if (!Array.isArray(object.workingDay))
                        throw TypeError(".fastcity.admin.WorkingDayList.workingDay: array expected");
                    message.workingDay = [];
                    for (var i = 0; i < object.workingDay.length; ++i)
                        if ($util.Long)
                            (message.workingDay[i] = $util.Long.fromValue(object.workingDay[i])).unsigned = false;
                        else if (typeof object.workingDay[i] === "string")
                            message.workingDay[i] = parseInt(object.workingDay[i], 10);
                        else if (typeof object.workingDay[i] === "number")
                            message.workingDay[i] = object.workingDay[i];
                        else if (typeof object.workingDay[i] === "object")
                            message.workingDay[i] = new $util.LongBits(object.workingDay[i].low >>> 0, object.workingDay[i].high >>> 0).toNumber();
                }
                if (object.totalWorkingDays != null)
                    message.totalWorkingDays = object.totalWorkingDays >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a WorkingDayList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.WorkingDayList
             * @static
             * @param {fastcity.admin.WorkingDayList} message WorkingDayList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkingDayList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.workingDay = [];
                if (options.defaults)
                    object.totalWorkingDays = 0;
                if (message.workingDay && message.workingDay.length) {
                    object.workingDay = [];
                    for (var j = 0; j < message.workingDay.length; ++j)
                        if (typeof message.workingDay[j] === "number")
                            object.workingDay[j] = options.longs === String ? String(message.workingDay[j]) : message.workingDay[j];
                        else
                            object.workingDay[j] = options.longs === String ? $util.Long.prototype.toString.call(message.workingDay[j]) : options.longs === Number ? new $util.LongBits(message.workingDay[j].low >>> 0, message.workingDay[j].high >>> 0).toNumber() : message.workingDay[j];
                }
                if (message.totalWorkingDays != null && message.hasOwnProperty("totalWorkingDays"))
                    object.totalWorkingDays = message.totalWorkingDays;
                return object;
            };

            /**
             * Converts this WorkingDayList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.WorkingDayList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkingDayList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return WorkingDayList;
        })();

        admin.GetWorkingDayList = (function() {

            /**
             * Properties of a GetWorkingDayList.
             * @memberof fastcity.admin
             * @interface IGetWorkingDayList
             * @property {fastcity.admin.GetWorkingDayList.ITimeInterval|null} [timeInterval] GetWorkingDayList timeInterval
             */

            /**
             * Constructs a new GetWorkingDayList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetWorkingDayList.
             * @implements IGetWorkingDayList
             * @constructor
             * @param {fastcity.admin.IGetWorkingDayList=} [properties] Properties to set
             */
            function GetWorkingDayList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetWorkingDayList timeInterval.
             * @member {fastcity.admin.GetWorkingDayList.ITimeInterval|null|undefined} timeInterval
             * @memberof fastcity.admin.GetWorkingDayList
             * @instance
             */
            GetWorkingDayList.prototype.timeInterval = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * GetWorkingDayList type.
             * @member {"timeInterval"|undefined} type
             * @memberof fastcity.admin.GetWorkingDayList
             * @instance
             */
            Object.defineProperty(GetWorkingDayList.prototype, "type", {
                get: $util.oneOfGetter($oneOfFields = ["timeInterval"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new GetWorkingDayList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetWorkingDayList
             * @static
             * @param {fastcity.admin.IGetWorkingDayList=} [properties] Properties to set
             * @returns {fastcity.admin.GetWorkingDayList} GetWorkingDayList instance
             */
            GetWorkingDayList.create = function create(properties) {
                return new GetWorkingDayList(properties);
            };

            /**
             * Encodes the specified GetWorkingDayList message. Does not implicitly {@link fastcity.admin.GetWorkingDayList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetWorkingDayList
             * @static
             * @param {fastcity.admin.IGetWorkingDayList} message GetWorkingDayList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetWorkingDayList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.timeInterval != null && Object.hasOwnProperty.call(message, "timeInterval"))
                    $root.fastcity.admin.GetWorkingDayList.TimeInterval.encode(message.timeInterval, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a GetWorkingDayList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetWorkingDayList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetWorkingDayList} GetWorkingDayList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetWorkingDayList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetWorkingDayList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.timeInterval = $root.fastcity.admin.GetWorkingDayList.TimeInterval.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetWorkingDayList message.
             * @function verify
             * @memberof fastcity.admin.GetWorkingDayList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetWorkingDayList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.timeInterval != null && message.hasOwnProperty("timeInterval")) {
                    properties.type = 1;
                    {
                        var error = $root.fastcity.admin.GetWorkingDayList.TimeInterval.verify(message.timeInterval);
                        if (error)
                            return "timeInterval." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GetWorkingDayList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetWorkingDayList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetWorkingDayList} GetWorkingDayList
             */
            GetWorkingDayList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetWorkingDayList)
                    return object;
                var message = new $root.fastcity.admin.GetWorkingDayList();
                if (object.timeInterval != null) {
                    if (typeof object.timeInterval !== "object")
                        throw TypeError(".fastcity.admin.GetWorkingDayList.timeInterval: object expected");
                    message.timeInterval = $root.fastcity.admin.GetWorkingDayList.TimeInterval.fromObject(object.timeInterval);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetWorkingDayList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetWorkingDayList
             * @static
             * @param {fastcity.admin.GetWorkingDayList} message GetWorkingDayList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetWorkingDayList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.timeInterval != null && message.hasOwnProperty("timeInterval")) {
                    object.timeInterval = $root.fastcity.admin.GetWorkingDayList.TimeInterval.toObject(message.timeInterval, options);
                    if (options.oneofs)
                        object.type = "timeInterval";
                }
                return object;
            };

            /**
             * Converts this GetWorkingDayList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetWorkingDayList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetWorkingDayList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            GetWorkingDayList.TimeInterval = (function() {

                /**
                 * Properties of a TimeInterval.
                 * @memberof fastcity.admin.GetWorkingDayList
                 * @interface ITimeInterval
                 * @property {Array.<number|Long>|null} [courierId] TimeInterval courierId
                 * @property {number|Long|null} [fromTime] TimeInterval fromTime
                 * @property {number|Long|null} [toTime] TimeInterval toTime
                 * @property {number|null} [offset] TimeInterval offset
                 * @property {number|null} [limit] TimeInterval limit
                 * @property {fastcity.common.SortOrder.Order|null} [sortOrder] TimeInterval sortOrder
                 */

                /**
                 * Constructs a new TimeInterval.
                 * @memberof fastcity.admin.GetWorkingDayList
                 * @classdesc Represents a TimeInterval.
                 * @implements ITimeInterval
                 * @constructor
                 * @param {fastcity.admin.GetWorkingDayList.ITimeInterval=} [properties] Properties to set
                 */
                function TimeInterval(properties) {
                    this.courierId = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TimeInterval courierId.
                 * @member {Array.<number|Long>} courierId
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.courierId = $util.emptyArray;

                /**
                 * TimeInterval fromTime.
                 * @member {number|Long} fromTime
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.fromTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TimeInterval toTime.
                 * @member {number|Long} toTime
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.toTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * TimeInterval offset.
                 * @member {number} offset
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.offset = 0;

                /**
                 * TimeInterval limit.
                 * @member {number} limit
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.limit = 0;

                /**
                 * TimeInterval sortOrder.
                 * @member {fastcity.common.SortOrder.Order} sortOrder
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @instance
                 */
                TimeInterval.prototype.sortOrder = 0;

                /**
                 * Creates a new TimeInterval instance using the specified properties.
                 * @function create
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @static
                 * @param {fastcity.admin.GetWorkingDayList.ITimeInterval=} [properties] Properties to set
                 * @returns {fastcity.admin.GetWorkingDayList.TimeInterval} TimeInterval instance
                 */
                TimeInterval.create = function create(properties) {
                    return new TimeInterval(properties);
                };

                /**
                 * Encodes the specified TimeInterval message. Does not implicitly {@link fastcity.admin.GetWorkingDayList.TimeInterval.verify|verify} messages.
                 * @function encode
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @static
                 * @param {fastcity.admin.GetWorkingDayList.ITimeInterval} message TimeInterval message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TimeInterval.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.courierId != null && message.courierId.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.courierId.length; ++i)
                            writer.uint64(message.courierId[i]);
                        writer.ldelim();
                    }
                    if (message.fromTime != null && Object.hasOwnProperty.call(message, "fromTime"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.fromTime);
                    if (message.toTime != null && Object.hasOwnProperty.call(message, "toTime"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.toTime);
                    if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                        writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.offset);
                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                        writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.limit);
                    if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.sortOrder);
                    return writer;
                };

                /**
                 * Decodes a TimeInterval message from the specified reader or buffer.
                 * @function decode
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {fastcity.admin.GetWorkingDayList.TimeInterval} TimeInterval
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TimeInterval.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetWorkingDayList.TimeInterval();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.courierId && message.courierId.length))
                                message.courierId = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.courierId.push(reader.uint64());
                            } else
                                message.courierId.push(reader.uint64());
                            break;
                        case 2:
                            message.fromTime = reader.int64();
                            break;
                        case 3:
                            message.toTime = reader.int64();
                            break;
                        case 4:
                            message.offset = reader.uint32();
                            break;
                        case 5:
                            message.limit = reader.uint32();
                            break;
                        case 6:
                            message.sortOrder = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a TimeInterval message.
                 * @function verify
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TimeInterval.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.courierId != null && message.hasOwnProperty("courierId")) {
                        if (!Array.isArray(message.courierId))
                            return "courierId: array expected";
                        for (var i = 0; i < message.courierId.length; ++i)
                            if (!$util.isInteger(message.courierId[i]) && !(message.courierId[i] && $util.isInteger(message.courierId[i].low) && $util.isInteger(message.courierId[i].high)))
                                return "courierId: integer|Long[] expected";
                    }
                    if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                        if (!$util.isInteger(message.fromTime) && !(message.fromTime && $util.isInteger(message.fromTime.low) && $util.isInteger(message.fromTime.high)))
                            return "fromTime: integer|Long expected";
                    if (message.toTime != null && message.hasOwnProperty("toTime"))
                        if (!$util.isInteger(message.toTime) && !(message.toTime && $util.isInteger(message.toTime.low) && $util.isInteger(message.toTime.high)))
                            return "toTime: integer|Long expected";
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        if (!$util.isInteger(message.offset))
                            return "offset: integer expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit))
                            return "limit: integer expected";
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        switch (message.sortOrder) {
                        default:
                            return "sortOrder: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a TimeInterval message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {fastcity.admin.GetWorkingDayList.TimeInterval} TimeInterval
                 */
                TimeInterval.fromObject = function fromObject(object) {
                    if (object instanceof $root.fastcity.admin.GetWorkingDayList.TimeInterval)
                        return object;
                    var message = new $root.fastcity.admin.GetWorkingDayList.TimeInterval();
                    if (object.courierId) {
                        if (!Array.isArray(object.courierId))
                            throw TypeError(".fastcity.admin.GetWorkingDayList.TimeInterval.courierId: array expected");
                        message.courierId = [];
                        for (var i = 0; i < object.courierId.length; ++i)
                            if ($util.Long)
                                (message.courierId[i] = $util.Long.fromValue(object.courierId[i])).unsigned = true;
                            else if (typeof object.courierId[i] === "string")
                                message.courierId[i] = parseInt(object.courierId[i], 10);
                            else if (typeof object.courierId[i] === "number")
                                message.courierId[i] = object.courierId[i];
                            else if (typeof object.courierId[i] === "object")
                                message.courierId[i] = new $util.LongBits(object.courierId[i].low >>> 0, object.courierId[i].high >>> 0).toNumber(true);
                    }
                    if (object.fromTime != null)
                        if ($util.Long)
                            (message.fromTime = $util.Long.fromValue(object.fromTime)).unsigned = false;
                        else if (typeof object.fromTime === "string")
                            message.fromTime = parseInt(object.fromTime, 10);
                        else if (typeof object.fromTime === "number")
                            message.fromTime = object.fromTime;
                        else if (typeof object.fromTime === "object")
                            message.fromTime = new $util.LongBits(object.fromTime.low >>> 0, object.fromTime.high >>> 0).toNumber();
                    if (object.toTime != null)
                        if ($util.Long)
                            (message.toTime = $util.Long.fromValue(object.toTime)).unsigned = false;
                        else if (typeof object.toTime === "string")
                            message.toTime = parseInt(object.toTime, 10);
                        else if (typeof object.toTime === "number")
                            message.toTime = object.toTime;
                        else if (typeof object.toTime === "object")
                            message.toTime = new $util.LongBits(object.toTime.low >>> 0, object.toTime.high >>> 0).toNumber();
                    if (object.offset != null)
                        message.offset = object.offset >>> 0;
                    if (object.limit != null)
                        message.limit = object.limit >>> 0;
                    switch (object.sortOrder) {
                    case "ASC":
                    case 0:
                        message.sortOrder = 0;
                        break;
                    case "DESC":
                    case 1:
                        message.sortOrder = 1;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a TimeInterval message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @static
                 * @param {fastcity.admin.GetWorkingDayList.TimeInterval} message TimeInterval
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TimeInterval.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.courierId = [];
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.fromTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.fromTime = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.toTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.toTime = options.longs === String ? "0" : 0;
                        object.offset = 0;
                        object.limit = 0;
                        object.sortOrder = options.enums === String ? "ASC" : 0;
                    }
                    if (message.courierId && message.courierId.length) {
                        object.courierId = [];
                        for (var j = 0; j < message.courierId.length; ++j)
                            if (typeof message.courierId[j] === "number")
                                object.courierId[j] = options.longs === String ? String(message.courierId[j]) : message.courierId[j];
                            else
                                object.courierId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.courierId[j]) : options.longs === Number ? new $util.LongBits(message.courierId[j].low >>> 0, message.courierId[j].high >>> 0).toNumber(true) : message.courierId[j];
                    }
                    if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                        if (typeof message.fromTime === "number")
                            object.fromTime = options.longs === String ? String(message.fromTime) : message.fromTime;
                        else
                            object.fromTime = options.longs === String ? $util.Long.prototype.toString.call(message.fromTime) : options.longs === Number ? new $util.LongBits(message.fromTime.low >>> 0, message.fromTime.high >>> 0).toNumber() : message.fromTime;
                    if (message.toTime != null && message.hasOwnProperty("toTime"))
                        if (typeof message.toTime === "number")
                            object.toTime = options.longs === String ? String(message.toTime) : message.toTime;
                        else
                            object.toTime = options.longs === String ? $util.Long.prototype.toString.call(message.toTime) : options.longs === Number ? new $util.LongBits(message.toTime.low >>> 0, message.toTime.high >>> 0).toNumber() : message.toTime;
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        object.offset = message.offset;
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        object.limit = message.limit;
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        object.sortOrder = options.enums === String ? $root.fastcity.common.SortOrder.Order[message.sortOrder] : message.sortOrder;
                    return object;
                };

                /**
                 * Converts this TimeInterval to JSON.
                 * @function toJSON
                 * @memberof fastcity.admin.GetWorkingDayList.TimeInterval
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TimeInterval.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TimeInterval;
            })();

            return GetWorkingDayList;
        })();

        /**
         * ExternalAuthTokenSource enum.
         * @name fastcity.admin.ExternalAuthTokenSource
         * @enum {number}
         * @property {number} UNKNOWN=0 UNKNOWN value
         * @property {number} IIKO=1 IIKO value
         */
        admin.ExternalAuthTokenSource = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN"] = 0;
            values[valuesById[1] = "IIKO"] = 1;
            return values;
        })();

        admin.ExternalAuthToken = (function() {

            /**
             * Properties of an ExternalAuthToken.
             * @memberof fastcity.admin
             * @interface IExternalAuthToken
             * @property {number|Long|null} [externalAuthTokenId] ExternalAuthToken externalAuthTokenId
             * @property {number|Long|null} [companyId] ExternalAuthToken companyId
             * @property {string|null} [name] ExternalAuthToken name
             * @property {string|null} [externalToken] ExternalAuthToken externalToken
             * @property {string|null} [urlToAuth] ExternalAuthToken urlToAuth
             * @property {fastcity.admin.ExternalAuthTokenSource|null} [source] ExternalAuthToken source
             * @property {boolean|null} [isDeleted] ExternalAuthToken isDeleted
             */

            /**
             * Constructs a new ExternalAuthToken.
             * @memberof fastcity.admin
             * @classdesc Represents an ExternalAuthToken.
             * @implements IExternalAuthToken
             * @constructor
             * @param {fastcity.admin.IExternalAuthToken=} [properties] Properties to set
             */
            function ExternalAuthToken(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ExternalAuthToken externalAuthTokenId.
             * @member {number|Long} externalAuthTokenId
             * @memberof fastcity.admin.ExternalAuthToken
             * @instance
             */
            ExternalAuthToken.prototype.externalAuthTokenId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * ExternalAuthToken companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.ExternalAuthToken
             * @instance
             */
            ExternalAuthToken.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * ExternalAuthToken name.
             * @member {string} name
             * @memberof fastcity.admin.ExternalAuthToken
             * @instance
             */
            ExternalAuthToken.prototype.name = "";

            /**
             * ExternalAuthToken externalToken.
             * @member {string} externalToken
             * @memberof fastcity.admin.ExternalAuthToken
             * @instance
             */
            ExternalAuthToken.prototype.externalToken = "";

            /**
             * ExternalAuthToken urlToAuth.
             * @member {string} urlToAuth
             * @memberof fastcity.admin.ExternalAuthToken
             * @instance
             */
            ExternalAuthToken.prototype.urlToAuth = "";

            /**
             * ExternalAuthToken source.
             * @member {fastcity.admin.ExternalAuthTokenSource} source
             * @memberof fastcity.admin.ExternalAuthToken
             * @instance
             */
            ExternalAuthToken.prototype.source = 0;

            /**
             * ExternalAuthToken isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.ExternalAuthToken
             * @instance
             */
            ExternalAuthToken.prototype.isDeleted = false;

            /**
             * Creates a new ExternalAuthToken instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ExternalAuthToken
             * @static
             * @param {fastcity.admin.IExternalAuthToken=} [properties] Properties to set
             * @returns {fastcity.admin.ExternalAuthToken} ExternalAuthToken instance
             */
            ExternalAuthToken.create = function create(properties) {
                return new ExternalAuthToken(properties);
            };

            /**
             * Encodes the specified ExternalAuthToken message. Does not implicitly {@link fastcity.admin.ExternalAuthToken.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ExternalAuthToken
             * @static
             * @param {fastcity.admin.IExternalAuthToken} message ExternalAuthToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ExternalAuthToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.externalAuthTokenId != null && Object.hasOwnProperty.call(message, "externalAuthTokenId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.externalAuthTokenId);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.externalToken != null && Object.hasOwnProperty.call(message, "externalToken"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.externalToken);
                if (message.urlToAuth != null && Object.hasOwnProperty.call(message, "urlToAuth"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.urlToAuth);
                if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.source);
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isDeleted);
                return writer;
            };

            /**
             * Decodes an ExternalAuthToken message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ExternalAuthToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ExternalAuthToken} ExternalAuthToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ExternalAuthToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ExternalAuthToken();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.externalAuthTokenId = reader.int64();
                        break;
                    case 2:
                        message.companyId = reader.int64();
                        break;
                    case 3:
                        message.name = reader.string();
                        break;
                    case 4:
                        message.externalToken = reader.string();
                        break;
                    case 5:
                        message.urlToAuth = reader.string();
                        break;
                    case 6:
                        message.source = reader.int32();
                        break;
                    case 7:
                        message.isDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an ExternalAuthToken message.
             * @function verify
             * @memberof fastcity.admin.ExternalAuthToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ExternalAuthToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.externalAuthTokenId != null && message.hasOwnProperty("externalAuthTokenId"))
                    if (!$util.isInteger(message.externalAuthTokenId) && !(message.externalAuthTokenId && $util.isInteger(message.externalAuthTokenId.low) && $util.isInteger(message.externalAuthTokenId.high)))
                        return "externalAuthTokenId: integer|Long expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.externalToken != null && message.hasOwnProperty("externalToken"))
                    if (!$util.isString(message.externalToken))
                        return "externalToken: string expected";
                if (message.urlToAuth != null && message.hasOwnProperty("urlToAuth"))
                    if (!$util.isString(message.urlToAuth))
                        return "urlToAuth: string expected";
                if (message.source != null && message.hasOwnProperty("source"))
                    switch (message.source) {
                    default:
                        return "source: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                return null;
            };

            /**
             * Creates an ExternalAuthToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ExternalAuthToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ExternalAuthToken} ExternalAuthToken
             */
            ExternalAuthToken.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ExternalAuthToken)
                    return object;
                var message = new $root.fastcity.admin.ExternalAuthToken();
                if (object.externalAuthTokenId != null)
                    if ($util.Long)
                        (message.externalAuthTokenId = $util.Long.fromValue(object.externalAuthTokenId)).unsigned = false;
                    else if (typeof object.externalAuthTokenId === "string")
                        message.externalAuthTokenId = parseInt(object.externalAuthTokenId, 10);
                    else if (typeof object.externalAuthTokenId === "number")
                        message.externalAuthTokenId = object.externalAuthTokenId;
                    else if (typeof object.externalAuthTokenId === "object")
                        message.externalAuthTokenId = new $util.LongBits(object.externalAuthTokenId.low >>> 0, object.externalAuthTokenId.high >>> 0).toNumber();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.externalToken != null)
                    message.externalToken = String(object.externalToken);
                if (object.urlToAuth != null)
                    message.urlToAuth = String(object.urlToAuth);
                switch (object.source) {
                case "UNKNOWN":
                case 0:
                    message.source = 0;
                    break;
                case "IIKO":
                case 1:
                    message.source = 1;
                    break;
                }
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                return message;
            };

            /**
             * Creates a plain object from an ExternalAuthToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ExternalAuthToken
             * @static
             * @param {fastcity.admin.ExternalAuthToken} message ExternalAuthToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ExternalAuthToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.externalAuthTokenId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.externalAuthTokenId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.externalToken = "";
                    object.urlToAuth = "";
                    object.source = options.enums === String ? "UNKNOWN" : 0;
                    object.isDeleted = false;
                }
                if (message.externalAuthTokenId != null && message.hasOwnProperty("externalAuthTokenId"))
                    if (typeof message.externalAuthTokenId === "number")
                        object.externalAuthTokenId = options.longs === String ? String(message.externalAuthTokenId) : message.externalAuthTokenId;
                    else
                        object.externalAuthTokenId = options.longs === String ? $util.Long.prototype.toString.call(message.externalAuthTokenId) : options.longs === Number ? new $util.LongBits(message.externalAuthTokenId.low >>> 0, message.externalAuthTokenId.high >>> 0).toNumber() : message.externalAuthTokenId;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.externalToken != null && message.hasOwnProperty("externalToken"))
                    object.externalToken = message.externalToken;
                if (message.urlToAuth != null && message.hasOwnProperty("urlToAuth"))
                    object.urlToAuth = message.urlToAuth;
                if (message.source != null && message.hasOwnProperty("source"))
                    object.source = options.enums === String ? $root.fastcity.admin.ExternalAuthTokenSource[message.source] : message.source;
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                return object;
            };

            /**
             * Converts this ExternalAuthToken to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ExternalAuthToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ExternalAuthToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ExternalAuthToken;
        })();

        admin.ExternalAuthTokenList = (function() {

            /**
             * Properties of an ExternalAuthTokenList.
             * @memberof fastcity.admin
             * @interface IExternalAuthTokenList
             * @property {Array.<fastcity.admin.IExternalAuthToken>|null} [externalAuthTokens] ExternalAuthTokenList externalAuthTokens
             */

            /**
             * Constructs a new ExternalAuthTokenList.
             * @memberof fastcity.admin
             * @classdesc Represents an ExternalAuthTokenList.
             * @implements IExternalAuthTokenList
             * @constructor
             * @param {fastcity.admin.IExternalAuthTokenList=} [properties] Properties to set
             */
            function ExternalAuthTokenList(properties) {
                this.externalAuthTokens = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ExternalAuthTokenList externalAuthTokens.
             * @member {Array.<fastcity.admin.IExternalAuthToken>} externalAuthTokens
             * @memberof fastcity.admin.ExternalAuthTokenList
             * @instance
             */
            ExternalAuthTokenList.prototype.externalAuthTokens = $util.emptyArray;

            /**
             * Creates a new ExternalAuthTokenList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ExternalAuthTokenList
             * @static
             * @param {fastcity.admin.IExternalAuthTokenList=} [properties] Properties to set
             * @returns {fastcity.admin.ExternalAuthTokenList} ExternalAuthTokenList instance
             */
            ExternalAuthTokenList.create = function create(properties) {
                return new ExternalAuthTokenList(properties);
            };

            /**
             * Encodes the specified ExternalAuthTokenList message. Does not implicitly {@link fastcity.admin.ExternalAuthTokenList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ExternalAuthTokenList
             * @static
             * @param {fastcity.admin.IExternalAuthTokenList} message ExternalAuthTokenList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ExternalAuthTokenList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.externalAuthTokens != null && message.externalAuthTokens.length)
                    for (var i = 0; i < message.externalAuthTokens.length; ++i)
                        $root.fastcity.admin.ExternalAuthToken.encode(message.externalAuthTokens[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an ExternalAuthTokenList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ExternalAuthTokenList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ExternalAuthTokenList} ExternalAuthTokenList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ExternalAuthTokenList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ExternalAuthTokenList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.externalAuthTokens && message.externalAuthTokens.length))
                            message.externalAuthTokens = [];
                        message.externalAuthTokens.push($root.fastcity.admin.ExternalAuthToken.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an ExternalAuthTokenList message.
             * @function verify
             * @memberof fastcity.admin.ExternalAuthTokenList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ExternalAuthTokenList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.externalAuthTokens != null && message.hasOwnProperty("externalAuthTokens")) {
                    if (!Array.isArray(message.externalAuthTokens))
                        return "externalAuthTokens: array expected";
                    for (var i = 0; i < message.externalAuthTokens.length; ++i) {
                        var error = $root.fastcity.admin.ExternalAuthToken.verify(message.externalAuthTokens[i]);
                        if (error)
                            return "externalAuthTokens." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an ExternalAuthTokenList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ExternalAuthTokenList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ExternalAuthTokenList} ExternalAuthTokenList
             */
            ExternalAuthTokenList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ExternalAuthTokenList)
                    return object;
                var message = new $root.fastcity.admin.ExternalAuthTokenList();
                if (object.externalAuthTokens) {
                    if (!Array.isArray(object.externalAuthTokens))
                        throw TypeError(".fastcity.admin.ExternalAuthTokenList.externalAuthTokens: array expected");
                    message.externalAuthTokens = [];
                    for (var i = 0; i < object.externalAuthTokens.length; ++i) {
                        if (typeof object.externalAuthTokens[i] !== "object")
                            throw TypeError(".fastcity.admin.ExternalAuthTokenList.externalAuthTokens: object expected");
                        message.externalAuthTokens[i] = $root.fastcity.admin.ExternalAuthToken.fromObject(object.externalAuthTokens[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an ExternalAuthTokenList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ExternalAuthTokenList
             * @static
             * @param {fastcity.admin.ExternalAuthTokenList} message ExternalAuthTokenList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ExternalAuthTokenList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.externalAuthTokens = [];
                if (message.externalAuthTokens && message.externalAuthTokens.length) {
                    object.externalAuthTokens = [];
                    for (var j = 0; j < message.externalAuthTokens.length; ++j)
                        object.externalAuthTokens[j] = $root.fastcity.admin.ExternalAuthToken.toObject(message.externalAuthTokens[j], options);
                }
                return object;
            };

            /**
             * Converts this ExternalAuthTokenList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ExternalAuthTokenList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ExternalAuthTokenList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ExternalAuthTokenList;
        })();

        admin.OptionalExternalAuthToken = (function() {

            /**
             * Properties of an OptionalExternalAuthToken.
             * @memberof fastcity.admin
             * @interface IOptionalExternalAuthToken
             * @property {fastcity.admin.IExternalAuthToken|null} [externalAuthToken] OptionalExternalAuthToken externalAuthToken
             */

            /**
             * Constructs a new OptionalExternalAuthToken.
             * @memberof fastcity.admin
             * @classdesc Represents an OptionalExternalAuthToken.
             * @implements IOptionalExternalAuthToken
             * @constructor
             * @param {fastcity.admin.IOptionalExternalAuthToken=} [properties] Properties to set
             */
            function OptionalExternalAuthToken(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OptionalExternalAuthToken externalAuthToken.
             * @member {fastcity.admin.IExternalAuthToken|null|undefined} externalAuthToken
             * @memberof fastcity.admin.OptionalExternalAuthToken
             * @instance
             */
            OptionalExternalAuthToken.prototype.externalAuthToken = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * OptionalExternalAuthToken body.
             * @member {"externalAuthToken"|undefined} body
             * @memberof fastcity.admin.OptionalExternalAuthToken
             * @instance
             */
            Object.defineProperty(OptionalExternalAuthToken.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["externalAuthToken"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new OptionalExternalAuthToken instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.OptionalExternalAuthToken
             * @static
             * @param {fastcity.admin.IOptionalExternalAuthToken=} [properties] Properties to set
             * @returns {fastcity.admin.OptionalExternalAuthToken} OptionalExternalAuthToken instance
             */
            OptionalExternalAuthToken.create = function create(properties) {
                return new OptionalExternalAuthToken(properties);
            };

            /**
             * Encodes the specified OptionalExternalAuthToken message. Does not implicitly {@link fastcity.admin.OptionalExternalAuthToken.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.OptionalExternalAuthToken
             * @static
             * @param {fastcity.admin.IOptionalExternalAuthToken} message OptionalExternalAuthToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OptionalExternalAuthToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.externalAuthToken != null && Object.hasOwnProperty.call(message, "externalAuthToken"))
                    $root.fastcity.admin.ExternalAuthToken.encode(message.externalAuthToken, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an OptionalExternalAuthToken message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.OptionalExternalAuthToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.OptionalExternalAuthToken} OptionalExternalAuthToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OptionalExternalAuthToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.OptionalExternalAuthToken();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.externalAuthToken = $root.fastcity.admin.ExternalAuthToken.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OptionalExternalAuthToken message.
             * @function verify
             * @memberof fastcity.admin.OptionalExternalAuthToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OptionalExternalAuthToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.externalAuthToken != null && message.hasOwnProperty("externalAuthToken")) {
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.ExternalAuthToken.verify(message.externalAuthToken);
                        if (error)
                            return "externalAuthToken." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an OptionalExternalAuthToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.OptionalExternalAuthToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.OptionalExternalAuthToken} OptionalExternalAuthToken
             */
            OptionalExternalAuthToken.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.OptionalExternalAuthToken)
                    return object;
                var message = new $root.fastcity.admin.OptionalExternalAuthToken();
                if (object.externalAuthToken != null) {
                    if (typeof object.externalAuthToken !== "object")
                        throw TypeError(".fastcity.admin.OptionalExternalAuthToken.externalAuthToken: object expected");
                    message.externalAuthToken = $root.fastcity.admin.ExternalAuthToken.fromObject(object.externalAuthToken);
                }
                return message;
            };

            /**
             * Creates a plain object from an OptionalExternalAuthToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.OptionalExternalAuthToken
             * @static
             * @param {fastcity.admin.OptionalExternalAuthToken} message OptionalExternalAuthToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OptionalExternalAuthToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.externalAuthToken != null && message.hasOwnProperty("externalAuthToken")) {
                    object.externalAuthToken = $root.fastcity.admin.ExternalAuthToken.toObject(message.externalAuthToken, options);
                    if (options.oneofs)
                        object.body = "externalAuthToken";
                }
                return object;
            };

            /**
             * Converts this OptionalExternalAuthToken to JSON.
             * @function toJSON
             * @memberof fastcity.admin.OptionalExternalAuthToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OptionalExternalAuthToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OptionalExternalAuthToken;
        })();

        admin.CreateExternalAuthToken = (function() {

            /**
             * Properties of a CreateExternalAuthToken.
             * @memberof fastcity.admin
             * @interface ICreateExternalAuthToken
             * @property {number|Long|null} [companyId] CreateExternalAuthToken companyId
             * @property {string|null} [name] CreateExternalAuthToken name
             * @property {string|null} [externalToken] CreateExternalAuthToken externalToken
             * @property {string|null} [urlToAuth] CreateExternalAuthToken urlToAuth
             * @property {fastcity.admin.ExternalAuthTokenSource|null} [source] CreateExternalAuthToken source
             */

            /**
             * Constructs a new CreateExternalAuthToken.
             * @memberof fastcity.admin
             * @classdesc Represents a CreateExternalAuthToken.
             * @implements ICreateExternalAuthToken
             * @constructor
             * @param {fastcity.admin.ICreateExternalAuthToken=} [properties] Properties to set
             */
            function CreateExternalAuthToken(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateExternalAuthToken companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @instance
             */
            CreateExternalAuthToken.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * CreateExternalAuthToken name.
             * @member {string} name
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @instance
             */
            CreateExternalAuthToken.prototype.name = "";

            /**
             * CreateExternalAuthToken externalToken.
             * @member {string} externalToken
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @instance
             */
            CreateExternalAuthToken.prototype.externalToken = "";

            /**
             * CreateExternalAuthToken urlToAuth.
             * @member {string} urlToAuth
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @instance
             */
            CreateExternalAuthToken.prototype.urlToAuth = "";

            /**
             * CreateExternalAuthToken source.
             * @member {fastcity.admin.ExternalAuthTokenSource} source
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @instance
             */
            CreateExternalAuthToken.prototype.source = 0;

            /**
             * Creates a new CreateExternalAuthToken instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @static
             * @param {fastcity.admin.ICreateExternalAuthToken=} [properties] Properties to set
             * @returns {fastcity.admin.CreateExternalAuthToken} CreateExternalAuthToken instance
             */
            CreateExternalAuthToken.create = function create(properties) {
                return new CreateExternalAuthToken(properties);
            };

            /**
             * Encodes the specified CreateExternalAuthToken message. Does not implicitly {@link fastcity.admin.CreateExternalAuthToken.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @static
             * @param {fastcity.admin.ICreateExternalAuthToken} message CreateExternalAuthToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateExternalAuthToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.externalToken != null && Object.hasOwnProperty.call(message, "externalToken"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.externalToken);
                if (message.urlToAuth != null && Object.hasOwnProperty.call(message, "urlToAuth"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.urlToAuth);
                if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.source);
                return writer;
            };

            /**
             * Decodes a CreateExternalAuthToken message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CreateExternalAuthToken} CreateExternalAuthToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateExternalAuthToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CreateExternalAuthToken();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.int64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.externalToken = reader.string();
                        break;
                    case 4:
                        message.urlToAuth = reader.string();
                        break;
                    case 5:
                        message.source = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CreateExternalAuthToken message.
             * @function verify
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateExternalAuthToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.externalToken != null && message.hasOwnProperty("externalToken"))
                    if (!$util.isString(message.externalToken))
                        return "externalToken: string expected";
                if (message.urlToAuth != null && message.hasOwnProperty("urlToAuth"))
                    if (!$util.isString(message.urlToAuth))
                        return "urlToAuth: string expected";
                if (message.source != null && message.hasOwnProperty("source"))
                    switch (message.source) {
                    default:
                        return "source: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                return null;
            };

            /**
             * Creates a CreateExternalAuthToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CreateExternalAuthToken} CreateExternalAuthToken
             */
            CreateExternalAuthToken.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CreateExternalAuthToken)
                    return object;
                var message = new $root.fastcity.admin.CreateExternalAuthToken();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.externalToken != null)
                    message.externalToken = String(object.externalToken);
                if (object.urlToAuth != null)
                    message.urlToAuth = String(object.urlToAuth);
                switch (object.source) {
                case "UNKNOWN":
                case 0:
                    message.source = 0;
                    break;
                case "IIKO":
                case 1:
                    message.source = 1;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a CreateExternalAuthToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @static
             * @param {fastcity.admin.CreateExternalAuthToken} message CreateExternalAuthToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateExternalAuthToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.externalToken = "";
                    object.urlToAuth = "";
                    object.source = options.enums === String ? "UNKNOWN" : 0;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.externalToken != null && message.hasOwnProperty("externalToken"))
                    object.externalToken = message.externalToken;
                if (message.urlToAuth != null && message.hasOwnProperty("urlToAuth"))
                    object.urlToAuth = message.urlToAuth;
                if (message.source != null && message.hasOwnProperty("source"))
                    object.source = options.enums === String ? $root.fastcity.admin.ExternalAuthTokenSource[message.source] : message.source;
                return object;
            };

            /**
             * Converts this CreateExternalAuthToken to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CreateExternalAuthToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateExternalAuthToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CreateExternalAuthToken;
        })();

        admin.UpdateExternalAuthToken = (function() {

            /**
             * Properties of an UpdateExternalAuthToken.
             * @memberof fastcity.admin
             * @interface IUpdateExternalAuthToken
             * @property {number|Long|null} [externalAuthTokenId] UpdateExternalAuthToken externalAuthTokenId
             * @property {number|Long|null} [companyId] UpdateExternalAuthToken companyId
             * @property {string|null} [name] UpdateExternalAuthToken name
             * @property {string|null} [externalToken] UpdateExternalAuthToken externalToken
             * @property {string|null} [urlToAuth] UpdateExternalAuthToken urlToAuth
             * @property {fastcity.admin.ExternalAuthTokenSource|null} [source] UpdateExternalAuthToken source
             * @property {boolean|null} [isDeleted] UpdateExternalAuthToken isDeleted
             */

            /**
             * Constructs a new UpdateExternalAuthToken.
             * @memberof fastcity.admin
             * @classdesc Represents an UpdateExternalAuthToken.
             * @implements IUpdateExternalAuthToken
             * @constructor
             * @param {fastcity.admin.IUpdateExternalAuthToken=} [properties] Properties to set
             */
            function UpdateExternalAuthToken(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateExternalAuthToken externalAuthTokenId.
             * @member {number|Long} externalAuthTokenId
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @instance
             */
            UpdateExternalAuthToken.prototype.externalAuthTokenId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UpdateExternalAuthToken companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @instance
             */
            UpdateExternalAuthToken.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UpdateExternalAuthToken name.
             * @member {string} name
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @instance
             */
            UpdateExternalAuthToken.prototype.name = "";

            /**
             * UpdateExternalAuthToken externalToken.
             * @member {string} externalToken
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @instance
             */
            UpdateExternalAuthToken.prototype.externalToken = "";

            /**
             * UpdateExternalAuthToken urlToAuth.
             * @member {string} urlToAuth
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @instance
             */
            UpdateExternalAuthToken.prototype.urlToAuth = "";

            /**
             * UpdateExternalAuthToken source.
             * @member {fastcity.admin.ExternalAuthTokenSource} source
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @instance
             */
            UpdateExternalAuthToken.prototype.source = 0;

            /**
             * UpdateExternalAuthToken isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @instance
             */
            UpdateExternalAuthToken.prototype.isDeleted = false;

            /**
             * Creates a new UpdateExternalAuthToken instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @static
             * @param {fastcity.admin.IUpdateExternalAuthToken=} [properties] Properties to set
             * @returns {fastcity.admin.UpdateExternalAuthToken} UpdateExternalAuthToken instance
             */
            UpdateExternalAuthToken.create = function create(properties) {
                return new UpdateExternalAuthToken(properties);
            };

            /**
             * Encodes the specified UpdateExternalAuthToken message. Does not implicitly {@link fastcity.admin.UpdateExternalAuthToken.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @static
             * @param {fastcity.admin.IUpdateExternalAuthToken} message UpdateExternalAuthToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateExternalAuthToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.externalAuthTokenId != null && Object.hasOwnProperty.call(message, "externalAuthTokenId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.externalAuthTokenId);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.companyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.externalToken != null && Object.hasOwnProperty.call(message, "externalToken"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.externalToken);
                if (message.urlToAuth != null && Object.hasOwnProperty.call(message, "urlToAuth"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.urlToAuth);
                if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.source);
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isDeleted);
                return writer;
            };

            /**
             * Decodes an UpdateExternalAuthToken message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.UpdateExternalAuthToken} UpdateExternalAuthToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateExternalAuthToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.UpdateExternalAuthToken();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.externalAuthTokenId = reader.int64();
                        break;
                    case 2:
                        message.companyId = reader.int64();
                        break;
                    case 3:
                        message.name = reader.string();
                        break;
                    case 4:
                        message.externalToken = reader.string();
                        break;
                    case 5:
                        message.urlToAuth = reader.string();
                        break;
                    case 6:
                        message.source = reader.int32();
                        break;
                    case 7:
                        message.isDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateExternalAuthToken message.
             * @function verify
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateExternalAuthToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.externalAuthTokenId != null && message.hasOwnProperty("externalAuthTokenId"))
                    if (!$util.isInteger(message.externalAuthTokenId) && !(message.externalAuthTokenId && $util.isInteger(message.externalAuthTokenId.low) && $util.isInteger(message.externalAuthTokenId.high)))
                        return "externalAuthTokenId: integer|Long expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.externalToken != null && message.hasOwnProperty("externalToken"))
                    if (!$util.isString(message.externalToken))
                        return "externalToken: string expected";
                if (message.urlToAuth != null && message.hasOwnProperty("urlToAuth"))
                    if (!$util.isString(message.urlToAuth))
                        return "urlToAuth: string expected";
                if (message.source != null && message.hasOwnProperty("source"))
                    switch (message.source) {
                    default:
                        return "source: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                return null;
            };

            /**
             * Creates an UpdateExternalAuthToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.UpdateExternalAuthToken} UpdateExternalAuthToken
             */
            UpdateExternalAuthToken.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.UpdateExternalAuthToken)
                    return object;
                var message = new $root.fastcity.admin.UpdateExternalAuthToken();
                if (object.externalAuthTokenId != null)
                    if ($util.Long)
                        (message.externalAuthTokenId = $util.Long.fromValue(object.externalAuthTokenId)).unsigned = false;
                    else if (typeof object.externalAuthTokenId === "string")
                        message.externalAuthTokenId = parseInt(object.externalAuthTokenId, 10);
                    else if (typeof object.externalAuthTokenId === "number")
                        message.externalAuthTokenId = object.externalAuthTokenId;
                    else if (typeof object.externalAuthTokenId === "object")
                        message.externalAuthTokenId = new $util.LongBits(object.externalAuthTokenId.low >>> 0, object.externalAuthTokenId.high >>> 0).toNumber();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.externalToken != null)
                    message.externalToken = String(object.externalToken);
                if (object.urlToAuth != null)
                    message.urlToAuth = String(object.urlToAuth);
                switch (object.source) {
                case "UNKNOWN":
                case 0:
                    message.source = 0;
                    break;
                case "IIKO":
                case 1:
                    message.source = 1;
                    break;
                }
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                return message;
            };

            /**
             * Creates a plain object from an UpdateExternalAuthToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @static
             * @param {fastcity.admin.UpdateExternalAuthToken} message UpdateExternalAuthToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateExternalAuthToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.externalAuthTokenId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.externalAuthTokenId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.externalToken = "";
                    object.urlToAuth = "";
                    object.source = options.enums === String ? "UNKNOWN" : 0;
                    object.isDeleted = false;
                }
                if (message.externalAuthTokenId != null && message.hasOwnProperty("externalAuthTokenId"))
                    if (typeof message.externalAuthTokenId === "number")
                        object.externalAuthTokenId = options.longs === String ? String(message.externalAuthTokenId) : message.externalAuthTokenId;
                    else
                        object.externalAuthTokenId = options.longs === String ? $util.Long.prototype.toString.call(message.externalAuthTokenId) : options.longs === Number ? new $util.LongBits(message.externalAuthTokenId.low >>> 0, message.externalAuthTokenId.high >>> 0).toNumber() : message.externalAuthTokenId;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.externalToken != null && message.hasOwnProperty("externalToken"))
                    object.externalToken = message.externalToken;
                if (message.urlToAuth != null && message.hasOwnProperty("urlToAuth"))
                    object.urlToAuth = message.urlToAuth;
                if (message.source != null && message.hasOwnProperty("source"))
                    object.source = options.enums === String ? $root.fastcity.admin.ExternalAuthTokenSource[message.source] : message.source;
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                return object;
            };

            /**
             * Converts this UpdateExternalAuthToken to JSON.
             * @function toJSON
             * @memberof fastcity.admin.UpdateExternalAuthToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateExternalAuthToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateExternalAuthToken;
        })();

        admin.DeleteExternalAuthToken = (function() {

            /**
             * Properties of a DeleteExternalAuthToken.
             * @memberof fastcity.admin
             * @interface IDeleteExternalAuthToken
             * @property {number|Long|null} [externalAuthTokenId] DeleteExternalAuthToken externalAuthTokenId
             */

            /**
             * Constructs a new DeleteExternalAuthToken.
             * @memberof fastcity.admin
             * @classdesc Represents a DeleteExternalAuthToken.
             * @implements IDeleteExternalAuthToken
             * @constructor
             * @param {fastcity.admin.IDeleteExternalAuthToken=} [properties] Properties to set
             */
            function DeleteExternalAuthToken(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteExternalAuthToken externalAuthTokenId.
             * @member {number|Long} externalAuthTokenId
             * @memberof fastcity.admin.DeleteExternalAuthToken
             * @instance
             */
            DeleteExternalAuthToken.prototype.externalAuthTokenId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new DeleteExternalAuthToken instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DeleteExternalAuthToken
             * @static
             * @param {fastcity.admin.IDeleteExternalAuthToken=} [properties] Properties to set
             * @returns {fastcity.admin.DeleteExternalAuthToken} DeleteExternalAuthToken instance
             */
            DeleteExternalAuthToken.create = function create(properties) {
                return new DeleteExternalAuthToken(properties);
            };

            /**
             * Encodes the specified DeleteExternalAuthToken message. Does not implicitly {@link fastcity.admin.DeleteExternalAuthToken.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DeleteExternalAuthToken
             * @static
             * @param {fastcity.admin.IDeleteExternalAuthToken} message DeleteExternalAuthToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteExternalAuthToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.externalAuthTokenId != null && Object.hasOwnProperty.call(message, "externalAuthTokenId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.externalAuthTokenId);
                return writer;
            };

            /**
             * Decodes a DeleteExternalAuthToken message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DeleteExternalAuthToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DeleteExternalAuthToken} DeleteExternalAuthToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteExternalAuthToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DeleteExternalAuthToken();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.externalAuthTokenId = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeleteExternalAuthToken message.
             * @function verify
             * @memberof fastcity.admin.DeleteExternalAuthToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteExternalAuthToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.externalAuthTokenId != null && message.hasOwnProperty("externalAuthTokenId"))
                    if (!$util.isInteger(message.externalAuthTokenId) && !(message.externalAuthTokenId && $util.isInteger(message.externalAuthTokenId.low) && $util.isInteger(message.externalAuthTokenId.high)))
                        return "externalAuthTokenId: integer|Long expected";
                return null;
            };

            /**
             * Creates a DeleteExternalAuthToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DeleteExternalAuthToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DeleteExternalAuthToken} DeleteExternalAuthToken
             */
            DeleteExternalAuthToken.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DeleteExternalAuthToken)
                    return object;
                var message = new $root.fastcity.admin.DeleteExternalAuthToken();
                if (object.externalAuthTokenId != null)
                    if ($util.Long)
                        (message.externalAuthTokenId = $util.Long.fromValue(object.externalAuthTokenId)).unsigned = false;
                    else if (typeof object.externalAuthTokenId === "string")
                        message.externalAuthTokenId = parseInt(object.externalAuthTokenId, 10);
                    else if (typeof object.externalAuthTokenId === "number")
                        message.externalAuthTokenId = object.externalAuthTokenId;
                    else if (typeof object.externalAuthTokenId === "object")
                        message.externalAuthTokenId = new $util.LongBits(object.externalAuthTokenId.low >>> 0, object.externalAuthTokenId.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a DeleteExternalAuthToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DeleteExternalAuthToken
             * @static
             * @param {fastcity.admin.DeleteExternalAuthToken} message DeleteExternalAuthToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteExternalAuthToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.externalAuthTokenId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.externalAuthTokenId = options.longs === String ? "0" : 0;
                if (message.externalAuthTokenId != null && message.hasOwnProperty("externalAuthTokenId"))
                    if (typeof message.externalAuthTokenId === "number")
                        object.externalAuthTokenId = options.longs === String ? String(message.externalAuthTokenId) : message.externalAuthTokenId;
                    else
                        object.externalAuthTokenId = options.longs === String ? $util.Long.prototype.toString.call(message.externalAuthTokenId) : options.longs === Number ? new $util.LongBits(message.externalAuthTokenId.low >>> 0, message.externalAuthTokenId.high >>> 0).toNumber() : message.externalAuthTokenId;
                return object;
            };

            /**
             * Converts this DeleteExternalAuthToken to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DeleteExternalAuthToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteExternalAuthToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeleteExternalAuthToken;
        })();

        admin.GetExternalAuthToken = (function() {

            /**
             * Properties of a GetExternalAuthToken.
             * @memberof fastcity.admin
             * @interface IGetExternalAuthToken
             * @property {number|Long|null} [externalAuthTokenId] GetExternalAuthToken externalAuthTokenId
             */

            /**
             * Constructs a new GetExternalAuthToken.
             * @memberof fastcity.admin
             * @classdesc Represents a GetExternalAuthToken.
             * @implements IGetExternalAuthToken
             * @constructor
             * @param {fastcity.admin.IGetExternalAuthToken=} [properties] Properties to set
             */
            function GetExternalAuthToken(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetExternalAuthToken externalAuthTokenId.
             * @member {number|Long} externalAuthTokenId
             * @memberof fastcity.admin.GetExternalAuthToken
             * @instance
             */
            GetExternalAuthToken.prototype.externalAuthTokenId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new GetExternalAuthToken instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetExternalAuthToken
             * @static
             * @param {fastcity.admin.IGetExternalAuthToken=} [properties] Properties to set
             * @returns {fastcity.admin.GetExternalAuthToken} GetExternalAuthToken instance
             */
            GetExternalAuthToken.create = function create(properties) {
                return new GetExternalAuthToken(properties);
            };

            /**
             * Encodes the specified GetExternalAuthToken message. Does not implicitly {@link fastcity.admin.GetExternalAuthToken.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetExternalAuthToken
             * @static
             * @param {fastcity.admin.IGetExternalAuthToken} message GetExternalAuthToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetExternalAuthToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.externalAuthTokenId != null && Object.hasOwnProperty.call(message, "externalAuthTokenId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.externalAuthTokenId);
                return writer;
            };

            /**
             * Decodes a GetExternalAuthToken message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetExternalAuthToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetExternalAuthToken} GetExternalAuthToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetExternalAuthToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetExternalAuthToken();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.externalAuthTokenId = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetExternalAuthToken message.
             * @function verify
             * @memberof fastcity.admin.GetExternalAuthToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetExternalAuthToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.externalAuthTokenId != null && message.hasOwnProperty("externalAuthTokenId"))
                    if (!$util.isInteger(message.externalAuthTokenId) && !(message.externalAuthTokenId && $util.isInteger(message.externalAuthTokenId.low) && $util.isInteger(message.externalAuthTokenId.high)))
                        return "externalAuthTokenId: integer|Long expected";
                return null;
            };

            /**
             * Creates a GetExternalAuthToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetExternalAuthToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetExternalAuthToken} GetExternalAuthToken
             */
            GetExternalAuthToken.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetExternalAuthToken)
                    return object;
                var message = new $root.fastcity.admin.GetExternalAuthToken();
                if (object.externalAuthTokenId != null)
                    if ($util.Long)
                        (message.externalAuthTokenId = $util.Long.fromValue(object.externalAuthTokenId)).unsigned = false;
                    else if (typeof object.externalAuthTokenId === "string")
                        message.externalAuthTokenId = parseInt(object.externalAuthTokenId, 10);
                    else if (typeof object.externalAuthTokenId === "number")
                        message.externalAuthTokenId = object.externalAuthTokenId;
                    else if (typeof object.externalAuthTokenId === "object")
                        message.externalAuthTokenId = new $util.LongBits(object.externalAuthTokenId.low >>> 0, object.externalAuthTokenId.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a GetExternalAuthToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetExternalAuthToken
             * @static
             * @param {fastcity.admin.GetExternalAuthToken} message GetExternalAuthToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetExternalAuthToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.externalAuthTokenId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.externalAuthTokenId = options.longs === String ? "0" : 0;
                if (message.externalAuthTokenId != null && message.hasOwnProperty("externalAuthTokenId"))
                    if (typeof message.externalAuthTokenId === "number")
                        object.externalAuthTokenId = options.longs === String ? String(message.externalAuthTokenId) : message.externalAuthTokenId;
                    else
                        object.externalAuthTokenId = options.longs === String ? $util.Long.prototype.toString.call(message.externalAuthTokenId) : options.longs === Number ? new $util.LongBits(message.externalAuthTokenId.low >>> 0, message.externalAuthTokenId.high >>> 0).toNumber() : message.externalAuthTokenId;
                return object;
            };

            /**
             * Converts this GetExternalAuthToken to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetExternalAuthToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetExternalAuthToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetExternalAuthToken;
        })();

        admin.GetExternalAuthTokenList = (function() {

            /**
             * Properties of a GetExternalAuthTokenList.
             * @memberof fastcity.admin
             * @interface IGetExternalAuthTokenList
             * @property {number|Long|null} [companyId] GetExternalAuthTokenList companyId
             * @property {boolean|null} [withDeleted] GetExternalAuthTokenList withDeleted
             */

            /**
             * Constructs a new GetExternalAuthTokenList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetExternalAuthTokenList.
             * @implements IGetExternalAuthTokenList
             * @constructor
             * @param {fastcity.admin.IGetExternalAuthTokenList=} [properties] Properties to set
             */
            function GetExternalAuthTokenList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetExternalAuthTokenList companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.GetExternalAuthTokenList
             * @instance
             */
            GetExternalAuthTokenList.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * GetExternalAuthTokenList withDeleted.
             * @member {boolean} withDeleted
             * @memberof fastcity.admin.GetExternalAuthTokenList
             * @instance
             */
            GetExternalAuthTokenList.prototype.withDeleted = false;

            /**
             * Creates a new GetExternalAuthTokenList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetExternalAuthTokenList
             * @static
             * @param {fastcity.admin.IGetExternalAuthTokenList=} [properties] Properties to set
             * @returns {fastcity.admin.GetExternalAuthTokenList} GetExternalAuthTokenList instance
             */
            GetExternalAuthTokenList.create = function create(properties) {
                return new GetExternalAuthTokenList(properties);
            };

            /**
             * Encodes the specified GetExternalAuthTokenList message. Does not implicitly {@link fastcity.admin.GetExternalAuthTokenList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetExternalAuthTokenList
             * @static
             * @param {fastcity.admin.IGetExternalAuthTokenList} message GetExternalAuthTokenList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetExternalAuthTokenList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                if (message.withDeleted != null && Object.hasOwnProperty.call(message, "withDeleted"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.withDeleted);
                return writer;
            };

            /**
             * Decodes a GetExternalAuthTokenList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetExternalAuthTokenList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetExternalAuthTokenList} GetExternalAuthTokenList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetExternalAuthTokenList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetExternalAuthTokenList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.int64();
                        break;
                    case 2:
                        message.withDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetExternalAuthTokenList message.
             * @function verify
             * @memberof fastcity.admin.GetExternalAuthTokenList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetExternalAuthTokenList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    if (typeof message.withDeleted !== "boolean")
                        return "withDeleted: boolean expected";
                return null;
            };

            /**
             * Creates a GetExternalAuthTokenList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetExternalAuthTokenList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetExternalAuthTokenList} GetExternalAuthTokenList
             */
            GetExternalAuthTokenList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetExternalAuthTokenList)
                    return object;
                var message = new $root.fastcity.admin.GetExternalAuthTokenList();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                if (object.withDeleted != null)
                    message.withDeleted = Boolean(object.withDeleted);
                return message;
            };

            /**
             * Creates a plain object from a GetExternalAuthTokenList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetExternalAuthTokenList
             * @static
             * @param {fastcity.admin.GetExternalAuthTokenList} message GetExternalAuthTokenList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetExternalAuthTokenList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.withDeleted = false;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    object.withDeleted = message.withDeleted;
                return object;
            };

            /**
             * Converts this GetExternalAuthTokenList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetExternalAuthTokenList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetExternalAuthTokenList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetExternalAuthTokenList;
        })();

        admin.BindSubCompanyToExternalAuthToken = (function() {

            /**
             * Properties of a BindSubCompanyToExternalAuthToken.
             * @memberof fastcity.admin
             * @interface IBindSubCompanyToExternalAuthToken
             * @property {number|Long|null} [companyId] BindSubCompanyToExternalAuthToken companyId
             * @property {string|null} [externalSubCompanyId] BindSubCompanyToExternalAuthToken externalSubCompanyId
             * @property {number|Long|null} [externalAuthTokenId] BindSubCompanyToExternalAuthToken externalAuthTokenId
             */

            /**
             * Constructs a new BindSubCompanyToExternalAuthToken.
             * @memberof fastcity.admin
             * @classdesc Represents a BindSubCompanyToExternalAuthToken.
             * @implements IBindSubCompanyToExternalAuthToken
             * @constructor
             * @param {fastcity.admin.IBindSubCompanyToExternalAuthToken=} [properties] Properties to set
             */
            function BindSubCompanyToExternalAuthToken(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BindSubCompanyToExternalAuthToken companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.BindSubCompanyToExternalAuthToken
             * @instance
             */
            BindSubCompanyToExternalAuthToken.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * BindSubCompanyToExternalAuthToken externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.BindSubCompanyToExternalAuthToken
             * @instance
             */
            BindSubCompanyToExternalAuthToken.prototype.externalSubCompanyId = "";

            /**
             * BindSubCompanyToExternalAuthToken externalAuthTokenId.
             * @member {number|Long} externalAuthTokenId
             * @memberof fastcity.admin.BindSubCompanyToExternalAuthToken
             * @instance
             */
            BindSubCompanyToExternalAuthToken.prototype.externalAuthTokenId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new BindSubCompanyToExternalAuthToken instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.BindSubCompanyToExternalAuthToken
             * @static
             * @param {fastcity.admin.IBindSubCompanyToExternalAuthToken=} [properties] Properties to set
             * @returns {fastcity.admin.BindSubCompanyToExternalAuthToken} BindSubCompanyToExternalAuthToken instance
             */
            BindSubCompanyToExternalAuthToken.create = function create(properties) {
                return new BindSubCompanyToExternalAuthToken(properties);
            };

            /**
             * Encodes the specified BindSubCompanyToExternalAuthToken message. Does not implicitly {@link fastcity.admin.BindSubCompanyToExternalAuthToken.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.BindSubCompanyToExternalAuthToken
             * @static
             * @param {fastcity.admin.IBindSubCompanyToExternalAuthToken} message BindSubCompanyToExternalAuthToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BindSubCompanyToExternalAuthToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalSubCompanyId);
                if (message.externalAuthTokenId != null && Object.hasOwnProperty.call(message, "externalAuthTokenId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.externalAuthTokenId);
                return writer;
            };

            /**
             * Decodes a BindSubCompanyToExternalAuthToken message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.BindSubCompanyToExternalAuthToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.BindSubCompanyToExternalAuthToken} BindSubCompanyToExternalAuthToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BindSubCompanyToExternalAuthToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.BindSubCompanyToExternalAuthToken();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.int64();
                        break;
                    case 2:
                        message.externalSubCompanyId = reader.string();
                        break;
                    case 3:
                        message.externalAuthTokenId = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a BindSubCompanyToExternalAuthToken message.
             * @function verify
             * @memberof fastcity.admin.BindSubCompanyToExternalAuthToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BindSubCompanyToExternalAuthToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                if (message.externalAuthTokenId != null && message.hasOwnProperty("externalAuthTokenId"))
                    if (!$util.isInteger(message.externalAuthTokenId) && !(message.externalAuthTokenId && $util.isInteger(message.externalAuthTokenId.low) && $util.isInteger(message.externalAuthTokenId.high)))
                        return "externalAuthTokenId: integer|Long expected";
                return null;
            };

            /**
             * Creates a BindSubCompanyToExternalAuthToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.BindSubCompanyToExternalAuthToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.BindSubCompanyToExternalAuthToken} BindSubCompanyToExternalAuthToken
             */
            BindSubCompanyToExternalAuthToken.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.BindSubCompanyToExternalAuthToken)
                    return object;
                var message = new $root.fastcity.admin.BindSubCompanyToExternalAuthToken();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                if (object.externalAuthTokenId != null)
                    if ($util.Long)
                        (message.externalAuthTokenId = $util.Long.fromValue(object.externalAuthTokenId)).unsigned = false;
                    else if (typeof object.externalAuthTokenId === "string")
                        message.externalAuthTokenId = parseInt(object.externalAuthTokenId, 10);
                    else if (typeof object.externalAuthTokenId === "number")
                        message.externalAuthTokenId = object.externalAuthTokenId;
                    else if (typeof object.externalAuthTokenId === "object")
                        message.externalAuthTokenId = new $util.LongBits(object.externalAuthTokenId.low >>> 0, object.externalAuthTokenId.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a BindSubCompanyToExternalAuthToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.BindSubCompanyToExternalAuthToken
             * @static
             * @param {fastcity.admin.BindSubCompanyToExternalAuthToken} message BindSubCompanyToExternalAuthToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BindSubCompanyToExternalAuthToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.externalAuthTokenId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.externalAuthTokenId = options.longs === String ? "0" : 0;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                if (message.externalAuthTokenId != null && message.hasOwnProperty("externalAuthTokenId"))
                    if (typeof message.externalAuthTokenId === "number")
                        object.externalAuthTokenId = options.longs === String ? String(message.externalAuthTokenId) : message.externalAuthTokenId;
                    else
                        object.externalAuthTokenId = options.longs === String ? $util.Long.prototype.toString.call(message.externalAuthTokenId) : options.longs === Number ? new $util.LongBits(message.externalAuthTokenId.low >>> 0, message.externalAuthTokenId.high >>> 0).toNumber() : message.externalAuthTokenId;
                return object;
            };

            /**
             * Converts this BindSubCompanyToExternalAuthToken to JSON.
             * @function toJSON
             * @memberof fastcity.admin.BindSubCompanyToExternalAuthToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BindSubCompanyToExternalAuthToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return BindSubCompanyToExternalAuthToken;
        })();

        admin.UnbindSubCompanyFromExternalAuthToken = (function() {

            /**
             * Properties of an UnbindSubCompanyFromExternalAuthToken.
             * @memberof fastcity.admin
             * @interface IUnbindSubCompanyFromExternalAuthToken
             * @property {number|Long|null} [companyId] UnbindSubCompanyFromExternalAuthToken companyId
             * @property {string|null} [externalSubCompanyId] UnbindSubCompanyFromExternalAuthToken externalSubCompanyId
             * @property {number|Long|null} [externalAuthTokenId] UnbindSubCompanyFromExternalAuthToken externalAuthTokenId
             */

            /**
             * Constructs a new UnbindSubCompanyFromExternalAuthToken.
             * @memberof fastcity.admin
             * @classdesc Represents an UnbindSubCompanyFromExternalAuthToken.
             * @implements IUnbindSubCompanyFromExternalAuthToken
             * @constructor
             * @param {fastcity.admin.IUnbindSubCompanyFromExternalAuthToken=} [properties] Properties to set
             */
            function UnbindSubCompanyFromExternalAuthToken(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UnbindSubCompanyFromExternalAuthToken companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.UnbindSubCompanyFromExternalAuthToken
             * @instance
             */
            UnbindSubCompanyFromExternalAuthToken.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UnbindSubCompanyFromExternalAuthToken externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.UnbindSubCompanyFromExternalAuthToken
             * @instance
             */
            UnbindSubCompanyFromExternalAuthToken.prototype.externalSubCompanyId = "";

            /**
             * UnbindSubCompanyFromExternalAuthToken externalAuthTokenId.
             * @member {number|Long} externalAuthTokenId
             * @memberof fastcity.admin.UnbindSubCompanyFromExternalAuthToken
             * @instance
             */
            UnbindSubCompanyFromExternalAuthToken.prototype.externalAuthTokenId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new UnbindSubCompanyFromExternalAuthToken instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.UnbindSubCompanyFromExternalAuthToken
             * @static
             * @param {fastcity.admin.IUnbindSubCompanyFromExternalAuthToken=} [properties] Properties to set
             * @returns {fastcity.admin.UnbindSubCompanyFromExternalAuthToken} UnbindSubCompanyFromExternalAuthToken instance
             */
            UnbindSubCompanyFromExternalAuthToken.create = function create(properties) {
                return new UnbindSubCompanyFromExternalAuthToken(properties);
            };

            /**
             * Encodes the specified UnbindSubCompanyFromExternalAuthToken message. Does not implicitly {@link fastcity.admin.UnbindSubCompanyFromExternalAuthToken.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.UnbindSubCompanyFromExternalAuthToken
             * @static
             * @param {fastcity.admin.IUnbindSubCompanyFromExternalAuthToken} message UnbindSubCompanyFromExternalAuthToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UnbindSubCompanyFromExternalAuthToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalSubCompanyId);
                if (message.externalAuthTokenId != null && Object.hasOwnProperty.call(message, "externalAuthTokenId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.externalAuthTokenId);
                return writer;
            };

            /**
             * Decodes an UnbindSubCompanyFromExternalAuthToken message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.UnbindSubCompanyFromExternalAuthToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.UnbindSubCompanyFromExternalAuthToken} UnbindSubCompanyFromExternalAuthToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UnbindSubCompanyFromExternalAuthToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.UnbindSubCompanyFromExternalAuthToken();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.int64();
                        break;
                    case 2:
                        message.externalSubCompanyId = reader.string();
                        break;
                    case 3:
                        message.externalAuthTokenId = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UnbindSubCompanyFromExternalAuthToken message.
             * @function verify
             * @memberof fastcity.admin.UnbindSubCompanyFromExternalAuthToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UnbindSubCompanyFromExternalAuthToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                if (message.externalAuthTokenId != null && message.hasOwnProperty("externalAuthTokenId"))
                    if (!$util.isInteger(message.externalAuthTokenId) && !(message.externalAuthTokenId && $util.isInteger(message.externalAuthTokenId.low) && $util.isInteger(message.externalAuthTokenId.high)))
                        return "externalAuthTokenId: integer|Long expected";
                return null;
            };

            /**
             * Creates an UnbindSubCompanyFromExternalAuthToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.UnbindSubCompanyFromExternalAuthToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.UnbindSubCompanyFromExternalAuthToken} UnbindSubCompanyFromExternalAuthToken
             */
            UnbindSubCompanyFromExternalAuthToken.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.UnbindSubCompanyFromExternalAuthToken)
                    return object;
                var message = new $root.fastcity.admin.UnbindSubCompanyFromExternalAuthToken();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                if (object.externalAuthTokenId != null)
                    if ($util.Long)
                        (message.externalAuthTokenId = $util.Long.fromValue(object.externalAuthTokenId)).unsigned = false;
                    else if (typeof object.externalAuthTokenId === "string")
                        message.externalAuthTokenId = parseInt(object.externalAuthTokenId, 10);
                    else if (typeof object.externalAuthTokenId === "number")
                        message.externalAuthTokenId = object.externalAuthTokenId;
                    else if (typeof object.externalAuthTokenId === "object")
                        message.externalAuthTokenId = new $util.LongBits(object.externalAuthTokenId.low >>> 0, object.externalAuthTokenId.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from an UnbindSubCompanyFromExternalAuthToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.UnbindSubCompanyFromExternalAuthToken
             * @static
             * @param {fastcity.admin.UnbindSubCompanyFromExternalAuthToken} message UnbindSubCompanyFromExternalAuthToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UnbindSubCompanyFromExternalAuthToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.externalAuthTokenId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.externalAuthTokenId = options.longs === String ? "0" : 0;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                if (message.externalAuthTokenId != null && message.hasOwnProperty("externalAuthTokenId"))
                    if (typeof message.externalAuthTokenId === "number")
                        object.externalAuthTokenId = options.longs === String ? String(message.externalAuthTokenId) : message.externalAuthTokenId;
                    else
                        object.externalAuthTokenId = options.longs === String ? $util.Long.prototype.toString.call(message.externalAuthTokenId) : options.longs === Number ? new $util.LongBits(message.externalAuthTokenId.low >>> 0, message.externalAuthTokenId.high >>> 0).toNumber() : message.externalAuthTokenId;
                return object;
            };

            /**
             * Converts this UnbindSubCompanyFromExternalAuthToken to JSON.
             * @function toJSON
             * @memberof fastcity.admin.UnbindSubCompanyFromExternalAuthToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UnbindSubCompanyFromExternalAuthToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UnbindSubCompanyFromExternalAuthToken;
        })();

        admin.GetBindedExternalAuthToken = (function() {

            /**
             * Properties of a GetBindedExternalAuthToken.
             * @memberof fastcity.admin
             * @interface IGetBindedExternalAuthToken
             * @property {number|Long|null} [companyId] GetBindedExternalAuthToken companyId
             * @property {string|null} [externalSubCompanyId] GetBindedExternalAuthToken externalSubCompanyId
             */

            /**
             * Constructs a new GetBindedExternalAuthToken.
             * @memberof fastcity.admin
             * @classdesc Represents a GetBindedExternalAuthToken.
             * @implements IGetBindedExternalAuthToken
             * @constructor
             * @param {fastcity.admin.IGetBindedExternalAuthToken=} [properties] Properties to set
             */
            function GetBindedExternalAuthToken(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetBindedExternalAuthToken companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.GetBindedExternalAuthToken
             * @instance
             */
            GetBindedExternalAuthToken.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * GetBindedExternalAuthToken externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.GetBindedExternalAuthToken
             * @instance
             */
            GetBindedExternalAuthToken.prototype.externalSubCompanyId = "";

            /**
             * Creates a new GetBindedExternalAuthToken instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetBindedExternalAuthToken
             * @static
             * @param {fastcity.admin.IGetBindedExternalAuthToken=} [properties] Properties to set
             * @returns {fastcity.admin.GetBindedExternalAuthToken} GetBindedExternalAuthToken instance
             */
            GetBindedExternalAuthToken.create = function create(properties) {
                return new GetBindedExternalAuthToken(properties);
            };

            /**
             * Encodes the specified GetBindedExternalAuthToken message. Does not implicitly {@link fastcity.admin.GetBindedExternalAuthToken.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetBindedExternalAuthToken
             * @static
             * @param {fastcity.admin.IGetBindedExternalAuthToken} message GetBindedExternalAuthToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetBindedExternalAuthToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalSubCompanyId);
                return writer;
            };

            /**
             * Decodes a GetBindedExternalAuthToken message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetBindedExternalAuthToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetBindedExternalAuthToken} GetBindedExternalAuthToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetBindedExternalAuthToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetBindedExternalAuthToken();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.int64();
                        break;
                    case 2:
                        message.externalSubCompanyId = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetBindedExternalAuthToken message.
             * @function verify
             * @memberof fastcity.admin.GetBindedExternalAuthToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetBindedExternalAuthToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                return null;
            };

            /**
             * Creates a GetBindedExternalAuthToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetBindedExternalAuthToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetBindedExternalAuthToken} GetBindedExternalAuthToken
             */
            GetBindedExternalAuthToken.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetBindedExternalAuthToken)
                    return object;
                var message = new $root.fastcity.admin.GetBindedExternalAuthToken();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = false;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber();
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                return message;
            };

            /**
             * Creates a plain object from a GetBindedExternalAuthToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetBindedExternalAuthToken
             * @static
             * @param {fastcity.admin.GetBindedExternalAuthToken} message GetBindedExternalAuthToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetBindedExternalAuthToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber() : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                return object;
            };

            /**
             * Converts this GetBindedExternalAuthToken to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetBindedExternalAuthToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetBindedExternalAuthToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetBindedExternalAuthToken;
        })();

        admin.GetBindedSubCompanies = (function() {

            /**
             * Properties of a GetBindedSubCompanies.
             * @memberof fastcity.admin
             * @interface IGetBindedSubCompanies
             * @property {number|Long|null} [externalAuthTokenId] GetBindedSubCompanies externalAuthTokenId
             */

            /**
             * Constructs a new GetBindedSubCompanies.
             * @memberof fastcity.admin
             * @classdesc Represents a GetBindedSubCompanies.
             * @implements IGetBindedSubCompanies
             * @constructor
             * @param {fastcity.admin.IGetBindedSubCompanies=} [properties] Properties to set
             */
            function GetBindedSubCompanies(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetBindedSubCompanies externalAuthTokenId.
             * @member {number|Long} externalAuthTokenId
             * @memberof fastcity.admin.GetBindedSubCompanies
             * @instance
             */
            GetBindedSubCompanies.prototype.externalAuthTokenId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new GetBindedSubCompanies instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetBindedSubCompanies
             * @static
             * @param {fastcity.admin.IGetBindedSubCompanies=} [properties] Properties to set
             * @returns {fastcity.admin.GetBindedSubCompanies} GetBindedSubCompanies instance
             */
            GetBindedSubCompanies.create = function create(properties) {
                return new GetBindedSubCompanies(properties);
            };

            /**
             * Encodes the specified GetBindedSubCompanies message. Does not implicitly {@link fastcity.admin.GetBindedSubCompanies.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetBindedSubCompanies
             * @static
             * @param {fastcity.admin.IGetBindedSubCompanies} message GetBindedSubCompanies message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetBindedSubCompanies.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.externalAuthTokenId != null && Object.hasOwnProperty.call(message, "externalAuthTokenId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.externalAuthTokenId);
                return writer;
            };

            /**
             * Decodes a GetBindedSubCompanies message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetBindedSubCompanies
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetBindedSubCompanies} GetBindedSubCompanies
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetBindedSubCompanies.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetBindedSubCompanies();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.externalAuthTokenId = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetBindedSubCompanies message.
             * @function verify
             * @memberof fastcity.admin.GetBindedSubCompanies
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetBindedSubCompanies.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.externalAuthTokenId != null && message.hasOwnProperty("externalAuthTokenId"))
                    if (!$util.isInteger(message.externalAuthTokenId) && !(message.externalAuthTokenId && $util.isInteger(message.externalAuthTokenId.low) && $util.isInteger(message.externalAuthTokenId.high)))
                        return "externalAuthTokenId: integer|Long expected";
                return null;
            };

            /**
             * Creates a GetBindedSubCompanies message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetBindedSubCompanies
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetBindedSubCompanies} GetBindedSubCompanies
             */
            GetBindedSubCompanies.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetBindedSubCompanies)
                    return object;
                var message = new $root.fastcity.admin.GetBindedSubCompanies();
                if (object.externalAuthTokenId != null)
                    if ($util.Long)
                        (message.externalAuthTokenId = $util.Long.fromValue(object.externalAuthTokenId)).unsigned = false;
                    else if (typeof object.externalAuthTokenId === "string")
                        message.externalAuthTokenId = parseInt(object.externalAuthTokenId, 10);
                    else if (typeof object.externalAuthTokenId === "number")
                        message.externalAuthTokenId = object.externalAuthTokenId;
                    else if (typeof object.externalAuthTokenId === "object")
                        message.externalAuthTokenId = new $util.LongBits(object.externalAuthTokenId.low >>> 0, object.externalAuthTokenId.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a GetBindedSubCompanies message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetBindedSubCompanies
             * @static
             * @param {fastcity.admin.GetBindedSubCompanies} message GetBindedSubCompanies
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetBindedSubCompanies.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.externalAuthTokenId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.externalAuthTokenId = options.longs === String ? "0" : 0;
                if (message.externalAuthTokenId != null && message.hasOwnProperty("externalAuthTokenId"))
                    if (typeof message.externalAuthTokenId === "number")
                        object.externalAuthTokenId = options.longs === String ? String(message.externalAuthTokenId) : message.externalAuthTokenId;
                    else
                        object.externalAuthTokenId = options.longs === String ? $util.Long.prototype.toString.call(message.externalAuthTokenId) : options.longs === Number ? new $util.LongBits(message.externalAuthTokenId.low >>> 0, message.externalAuthTokenId.high >>> 0).toNumber() : message.externalAuthTokenId;
                return object;
            };

            /**
             * Converts this GetBindedSubCompanies to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetBindedSubCompanies
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetBindedSubCompanies.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetBindedSubCompanies;
        })();

        admin.Group = (function() {

            /**
             * Properties of a Group.
             * @memberof fastcity.admin
             * @interface IGroup
             * @property {number|Long|null} [groupId] Group groupId
             * @property {number|Long|null} [companyId] Group companyId
             * @property {string|null} [name] Group name
             * @property {boolean|null} [isDeleted] Group isDeleted
             */

            /**
             * Constructs a new Group.
             * @memberof fastcity.admin
             * @classdesc Represents a Group.
             * @implements IGroup
             * @constructor
             * @param {fastcity.admin.IGroup=} [properties] Properties to set
             */
            function Group(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Group groupId.
             * @member {number|Long} groupId
             * @memberof fastcity.admin.Group
             * @instance
             */
            Group.prototype.groupId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Group companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.Group
             * @instance
             */
            Group.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Group name.
             * @member {string} name
             * @memberof fastcity.admin.Group
             * @instance
             */
            Group.prototype.name = "";

            /**
             * Group isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.Group
             * @instance
             */
            Group.prototype.isDeleted = false;

            /**
             * Creates a new Group instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.Group
             * @static
             * @param {fastcity.admin.IGroup=} [properties] Properties to set
             * @returns {fastcity.admin.Group} Group instance
             */
            Group.create = function create(properties) {
                return new Group(properties);
            };

            /**
             * Encodes the specified Group message. Does not implicitly {@link fastcity.admin.Group.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.Group
             * @static
             * @param {fastcity.admin.IGroup} message Group message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Group.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.groupId);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
                return writer;
            };

            /**
             * Decodes a Group message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.Group
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.Group} Group
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Group.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.Group();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.groupId = reader.uint64();
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        message.name = reader.string();
                        break;
                    case 4:
                        message.isDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Group message.
             * @function verify
             * @memberof fastcity.admin.Group
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Group.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (!$util.isInteger(message.groupId) && !(message.groupId && $util.isInteger(message.groupId.low) && $util.isInteger(message.groupId.high)))
                        return "groupId: integer|Long expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                return null;
            };

            /**
             * Creates a Group message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.Group
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.Group} Group
             */
            Group.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.Group)
                    return object;
                var message = new $root.fastcity.admin.Group();
                if (object.groupId != null)
                    if ($util.Long)
                        (message.groupId = $util.Long.fromValue(object.groupId)).unsigned = true;
                    else if (typeof object.groupId === "string")
                        message.groupId = parseInt(object.groupId, 10);
                    else if (typeof object.groupId === "number")
                        message.groupId = object.groupId;
                    else if (typeof object.groupId === "object")
                        message.groupId = new $util.LongBits(object.groupId.low >>> 0, object.groupId.high >>> 0).toNumber(true);
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                return message;
            };

            /**
             * Creates a plain object from a Group message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.Group
             * @static
             * @param {fastcity.admin.Group} message Group
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Group.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.groupId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.groupId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.isDeleted = false;
                }
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (typeof message.groupId === "number")
                        object.groupId = options.longs === String ? String(message.groupId) : message.groupId;
                    else
                        object.groupId = options.longs === String ? $util.Long.prototype.toString.call(message.groupId) : options.longs === Number ? new $util.LongBits(message.groupId.low >>> 0, message.groupId.high >>> 0).toNumber(true) : message.groupId;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                return object;
            };

            /**
             * Converts this Group to JSON.
             * @function toJSON
             * @memberof fastcity.admin.Group
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Group.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Group;
        })();

        admin.CreateGroup = (function() {

            /**
             * Properties of a CreateGroup.
             * @memberof fastcity.admin
             * @interface ICreateGroup
             * @property {number|Long|null} [companyId] CreateGroup companyId
             * @property {string|null} [name] CreateGroup name
             */

            /**
             * Constructs a new CreateGroup.
             * @memberof fastcity.admin
             * @classdesc Represents a CreateGroup.
             * @implements ICreateGroup
             * @constructor
             * @param {fastcity.admin.ICreateGroup=} [properties] Properties to set
             */
            function CreateGroup(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateGroup companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.CreateGroup
             * @instance
             */
            CreateGroup.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateGroup name.
             * @member {string} name
             * @memberof fastcity.admin.CreateGroup
             * @instance
             */
            CreateGroup.prototype.name = "";

            /**
             * Creates a new CreateGroup instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CreateGroup
             * @static
             * @param {fastcity.admin.ICreateGroup=} [properties] Properties to set
             * @returns {fastcity.admin.CreateGroup} CreateGroup instance
             */
            CreateGroup.create = function create(properties) {
                return new CreateGroup(properties);
            };

            /**
             * Encodes the specified CreateGroup message. Does not implicitly {@link fastcity.admin.CreateGroup.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CreateGroup
             * @static
             * @param {fastcity.admin.ICreateGroup} message CreateGroup message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateGroup.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };

            /**
             * Decodes a CreateGroup message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CreateGroup
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CreateGroup} CreateGroup
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateGroup.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CreateGroup();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CreateGroup message.
             * @function verify
             * @memberof fastcity.admin.CreateGroup
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateGroup.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates a CreateGroup message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CreateGroup
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CreateGroup} CreateGroup
             */
            CreateGroup.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CreateGroup)
                    return object;
                var message = new $root.fastcity.admin.CreateGroup();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from a CreateGroup message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CreateGroup
             * @static
             * @param {fastcity.admin.CreateGroup} message CreateGroup
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateGroup.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.name = "";
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this CreateGroup to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CreateGroup
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateGroup.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CreateGroup;
        })();

        admin.UpdateGroup = (function() {

            /**
             * Properties of an UpdateGroup.
             * @memberof fastcity.admin
             * @interface IUpdateGroup
             * @property {number|Long|null} [groupId] UpdateGroup groupId
             * @property {string|null} [name] UpdateGroup name
             */

            /**
             * Constructs a new UpdateGroup.
             * @memberof fastcity.admin
             * @classdesc Represents an UpdateGroup.
             * @implements IUpdateGroup
             * @constructor
             * @param {fastcity.admin.IUpdateGroup=} [properties] Properties to set
             */
            function UpdateGroup(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateGroup groupId.
             * @member {number|Long} groupId
             * @memberof fastcity.admin.UpdateGroup
             * @instance
             */
            UpdateGroup.prototype.groupId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateGroup name.
             * @member {string} name
             * @memberof fastcity.admin.UpdateGroup
             * @instance
             */
            UpdateGroup.prototype.name = "";

            /**
             * Creates a new UpdateGroup instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.UpdateGroup
             * @static
             * @param {fastcity.admin.IUpdateGroup=} [properties] Properties to set
             * @returns {fastcity.admin.UpdateGroup} UpdateGroup instance
             */
            UpdateGroup.create = function create(properties) {
                return new UpdateGroup(properties);
            };

            /**
             * Encodes the specified UpdateGroup message. Does not implicitly {@link fastcity.admin.UpdateGroup.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.UpdateGroup
             * @static
             * @param {fastcity.admin.IUpdateGroup} message UpdateGroup message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateGroup.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.groupId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };

            /**
             * Decodes an UpdateGroup message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.UpdateGroup
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.UpdateGroup} UpdateGroup
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateGroup.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.UpdateGroup();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.groupId = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateGroup message.
             * @function verify
             * @memberof fastcity.admin.UpdateGroup
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateGroup.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (!$util.isInteger(message.groupId) && !(message.groupId && $util.isInteger(message.groupId.low) && $util.isInteger(message.groupId.high)))
                        return "groupId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates an UpdateGroup message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.UpdateGroup
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.UpdateGroup} UpdateGroup
             */
            UpdateGroup.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.UpdateGroup)
                    return object;
                var message = new $root.fastcity.admin.UpdateGroup();
                if (object.groupId != null)
                    if ($util.Long)
                        (message.groupId = $util.Long.fromValue(object.groupId)).unsigned = true;
                    else if (typeof object.groupId === "string")
                        message.groupId = parseInt(object.groupId, 10);
                    else if (typeof object.groupId === "number")
                        message.groupId = object.groupId;
                    else if (typeof object.groupId === "object")
                        message.groupId = new $util.LongBits(object.groupId.low >>> 0, object.groupId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from an UpdateGroup message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.UpdateGroup
             * @static
             * @param {fastcity.admin.UpdateGroup} message UpdateGroup
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateGroup.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.groupId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.groupId = options.longs === String ? "0" : 0;
                    object.name = "";
                }
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (typeof message.groupId === "number")
                        object.groupId = options.longs === String ? String(message.groupId) : message.groupId;
                    else
                        object.groupId = options.longs === String ? $util.Long.prototype.toString.call(message.groupId) : options.longs === Number ? new $util.LongBits(message.groupId.low >>> 0, message.groupId.high >>> 0).toNumber(true) : message.groupId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this UpdateGroup to JSON.
             * @function toJSON
             * @memberof fastcity.admin.UpdateGroup
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateGroup.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateGroup;
        })();

        admin.DeleteGroup = (function() {

            /**
             * Properties of a DeleteGroup.
             * @memberof fastcity.admin
             * @interface IDeleteGroup
             * @property {number|Long|null} [groupId] DeleteGroup groupId
             */

            /**
             * Constructs a new DeleteGroup.
             * @memberof fastcity.admin
             * @classdesc Represents a DeleteGroup.
             * @implements IDeleteGroup
             * @constructor
             * @param {fastcity.admin.IDeleteGroup=} [properties] Properties to set
             */
            function DeleteGroup(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteGroup groupId.
             * @member {number|Long} groupId
             * @memberof fastcity.admin.DeleteGroup
             * @instance
             */
            DeleteGroup.prototype.groupId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new DeleteGroup instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DeleteGroup
             * @static
             * @param {fastcity.admin.IDeleteGroup=} [properties] Properties to set
             * @returns {fastcity.admin.DeleteGroup} DeleteGroup instance
             */
            DeleteGroup.create = function create(properties) {
                return new DeleteGroup(properties);
            };

            /**
             * Encodes the specified DeleteGroup message. Does not implicitly {@link fastcity.admin.DeleteGroup.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DeleteGroup
             * @static
             * @param {fastcity.admin.IDeleteGroup} message DeleteGroup message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteGroup.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.groupId);
                return writer;
            };

            /**
             * Decodes a DeleteGroup message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DeleteGroup
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DeleteGroup} DeleteGroup
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteGroup.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DeleteGroup();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.groupId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeleteGroup message.
             * @function verify
             * @memberof fastcity.admin.DeleteGroup
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteGroup.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (!$util.isInteger(message.groupId) && !(message.groupId && $util.isInteger(message.groupId.low) && $util.isInteger(message.groupId.high)))
                        return "groupId: integer|Long expected";
                return null;
            };

            /**
             * Creates a DeleteGroup message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DeleteGroup
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DeleteGroup} DeleteGroup
             */
            DeleteGroup.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DeleteGroup)
                    return object;
                var message = new $root.fastcity.admin.DeleteGroup();
                if (object.groupId != null)
                    if ($util.Long)
                        (message.groupId = $util.Long.fromValue(object.groupId)).unsigned = true;
                    else if (typeof object.groupId === "string")
                        message.groupId = parseInt(object.groupId, 10);
                    else if (typeof object.groupId === "number")
                        message.groupId = object.groupId;
                    else if (typeof object.groupId === "object")
                        message.groupId = new $util.LongBits(object.groupId.low >>> 0, object.groupId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a DeleteGroup message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DeleteGroup
             * @static
             * @param {fastcity.admin.DeleteGroup} message DeleteGroup
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteGroup.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.groupId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.groupId = options.longs === String ? "0" : 0;
                if (message.groupId != null && message.hasOwnProperty("groupId"))
                    if (typeof message.groupId === "number")
                        object.groupId = options.longs === String ? String(message.groupId) : message.groupId;
                    else
                        object.groupId = options.longs === String ? $util.Long.prototype.toString.call(message.groupId) : options.longs === Number ? new $util.LongBits(message.groupId.low >>> 0, message.groupId.high >>> 0).toNumber(true) : message.groupId;
                return object;
            };

            /**
             * Converts this DeleteGroup to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DeleteGroup
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteGroup.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeleteGroup;
        })();

        admin.GetGroupList = (function() {

            /**
             * Properties of a GetGroupList.
             * @memberof fastcity.admin
             * @interface IGetGroupList
             * @property {number|Long|null} [companyId] GetGroupList companyId
             * @property {boolean|null} [withDeleted] GetGroupList withDeleted
             */

            /**
             * Constructs a new GetGroupList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetGroupList.
             * @implements IGetGroupList
             * @constructor
             * @param {fastcity.admin.IGetGroupList=} [properties] Properties to set
             */
            function GetGroupList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetGroupList companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.GetGroupList
             * @instance
             */
            GetGroupList.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetGroupList withDeleted.
             * @member {boolean} withDeleted
             * @memberof fastcity.admin.GetGroupList
             * @instance
             */
            GetGroupList.prototype.withDeleted = false;

            /**
             * Creates a new GetGroupList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetGroupList
             * @static
             * @param {fastcity.admin.IGetGroupList=} [properties] Properties to set
             * @returns {fastcity.admin.GetGroupList} GetGroupList instance
             */
            GetGroupList.create = function create(properties) {
                return new GetGroupList(properties);
            };

            /**
             * Encodes the specified GetGroupList message. Does not implicitly {@link fastcity.admin.GetGroupList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetGroupList
             * @static
             * @param {fastcity.admin.IGetGroupList} message GetGroupList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetGroupList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.withDeleted != null && Object.hasOwnProperty.call(message, "withDeleted"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.withDeleted);
                return writer;
            };

            /**
             * Decodes a GetGroupList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetGroupList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetGroupList} GetGroupList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetGroupList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetGroupList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.withDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetGroupList message.
             * @function verify
             * @memberof fastcity.admin.GetGroupList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetGroupList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    if (typeof message.withDeleted !== "boolean")
                        return "withDeleted: boolean expected";
                return null;
            };

            /**
             * Creates a GetGroupList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetGroupList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetGroupList} GetGroupList
             */
            GetGroupList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetGroupList)
                    return object;
                var message = new $root.fastcity.admin.GetGroupList();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.withDeleted != null)
                    message.withDeleted = Boolean(object.withDeleted);
                return message;
            };

            /**
             * Creates a plain object from a GetGroupList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetGroupList
             * @static
             * @param {fastcity.admin.GetGroupList} message GetGroupList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetGroupList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.withDeleted = false;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    object.withDeleted = message.withDeleted;
                return object;
            };

            /**
             * Converts this GetGroupList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetGroupList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetGroupList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetGroupList;
        })();

        admin.GroupList = (function() {

            /**
             * Properties of a GroupList.
             * @memberof fastcity.admin
             * @interface IGroupList
             * @property {Array.<fastcity.admin.IGroup>|null} [group] GroupList group
             */

            /**
             * Constructs a new GroupList.
             * @memberof fastcity.admin
             * @classdesc Represents a GroupList.
             * @implements IGroupList
             * @constructor
             * @param {fastcity.admin.IGroupList=} [properties] Properties to set
             */
            function GroupList(properties) {
                this.group = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GroupList group.
             * @member {Array.<fastcity.admin.IGroup>} group
             * @memberof fastcity.admin.GroupList
             * @instance
             */
            GroupList.prototype.group = $util.emptyArray;

            /**
             * Creates a new GroupList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GroupList
             * @static
             * @param {fastcity.admin.IGroupList=} [properties] Properties to set
             * @returns {fastcity.admin.GroupList} GroupList instance
             */
            GroupList.create = function create(properties) {
                return new GroupList(properties);
            };

            /**
             * Encodes the specified GroupList message. Does not implicitly {@link fastcity.admin.GroupList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GroupList
             * @static
             * @param {fastcity.admin.IGroupList} message GroupList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GroupList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.group != null && message.group.length)
                    for (var i = 0; i < message.group.length; ++i)
                        $root.fastcity.admin.Group.encode(message.group[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a GroupList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GroupList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GroupList} GroupList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GroupList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GroupList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.group && message.group.length))
                            message.group = [];
                        message.group.push($root.fastcity.admin.Group.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GroupList message.
             * @function verify
             * @memberof fastcity.admin.GroupList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GroupList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.group != null && message.hasOwnProperty("group")) {
                    if (!Array.isArray(message.group))
                        return "group: array expected";
                    for (var i = 0; i < message.group.length; ++i) {
                        var error = $root.fastcity.admin.Group.verify(message.group[i]);
                        if (error)
                            return "group." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GroupList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GroupList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GroupList} GroupList
             */
            GroupList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GroupList)
                    return object;
                var message = new $root.fastcity.admin.GroupList();
                if (object.group) {
                    if (!Array.isArray(object.group))
                        throw TypeError(".fastcity.admin.GroupList.group: array expected");
                    message.group = [];
                    for (var i = 0; i < object.group.length; ++i) {
                        if (typeof object.group[i] !== "object")
                            throw TypeError(".fastcity.admin.GroupList.group: object expected");
                        message.group[i] = $root.fastcity.admin.Group.fromObject(object.group[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a GroupList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GroupList
             * @static
             * @param {fastcity.admin.GroupList} message GroupList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GroupList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.group = [];
                if (message.group && message.group.length) {
                    object.group = [];
                    for (var j = 0; j < message.group.length; ++j)
                        object.group[j] = $root.fastcity.admin.Group.toObject(message.group[j], options);
                }
                return object;
            };

            /**
             * Converts this GroupList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GroupList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GroupList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GroupList;
        })();

        admin.IntegrationToken = (function() {

            /**
             * Properties of an IntegrationToken.
             * @memberof fastcity.admin
             * @interface IIntegrationToken
             * @property {number|Long|null} [tokenId] IntegrationToken tokenId
             * @property {number|Long|null} [companyId] IntegrationToken companyId
             * @property {string|null} [token] IntegrationToken token
             * @property {boolean|null} [isDeleted] IntegrationToken isDeleted
             */

            /**
             * Constructs a new IntegrationToken.
             * @memberof fastcity.admin
             * @classdesc Represents an IntegrationToken.
             * @implements IIntegrationToken
             * @constructor
             * @param {fastcity.admin.IIntegrationToken=} [properties] Properties to set
             */
            function IntegrationToken(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * IntegrationToken tokenId.
             * @member {number|Long} tokenId
             * @memberof fastcity.admin.IntegrationToken
             * @instance
             */
            IntegrationToken.prototype.tokenId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * IntegrationToken companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.IntegrationToken
             * @instance
             */
            IntegrationToken.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * IntegrationToken token.
             * @member {string} token
             * @memberof fastcity.admin.IntegrationToken
             * @instance
             */
            IntegrationToken.prototype.token = "";

            /**
             * IntegrationToken isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.IntegrationToken
             * @instance
             */
            IntegrationToken.prototype.isDeleted = false;

            /**
             * Creates a new IntegrationToken instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.IntegrationToken
             * @static
             * @param {fastcity.admin.IIntegrationToken=} [properties] Properties to set
             * @returns {fastcity.admin.IntegrationToken} IntegrationToken instance
             */
            IntegrationToken.create = function create(properties) {
                return new IntegrationToken(properties);
            };

            /**
             * Encodes the specified IntegrationToken message. Does not implicitly {@link fastcity.admin.IntegrationToken.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.IntegrationToken
             * @static
             * @param {fastcity.admin.IIntegrationToken} message IntegrationToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            IntegrationToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.tokenId != null && Object.hasOwnProperty.call(message, "tokenId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.tokenId);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.token);
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
                return writer;
            };

            /**
             * Decodes an IntegrationToken message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.IntegrationToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.IntegrationToken} IntegrationToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            IntegrationToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.IntegrationToken();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.tokenId = reader.uint64();
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        message.token = reader.string();
                        break;
                    case 4:
                        message.isDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an IntegrationToken message.
             * @function verify
             * @memberof fastcity.admin.IntegrationToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            IntegrationToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.tokenId != null && message.hasOwnProperty("tokenId"))
                    if (!$util.isInteger(message.tokenId) && !(message.tokenId && $util.isInteger(message.tokenId.low) && $util.isInteger(message.tokenId.high)))
                        return "tokenId: integer|Long expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.token != null && message.hasOwnProperty("token"))
                    if (!$util.isString(message.token))
                        return "token: string expected";
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                return null;
            };

            /**
             * Creates an IntegrationToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.IntegrationToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.IntegrationToken} IntegrationToken
             */
            IntegrationToken.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.IntegrationToken)
                    return object;
                var message = new $root.fastcity.admin.IntegrationToken();
                if (object.tokenId != null)
                    if ($util.Long)
                        (message.tokenId = $util.Long.fromValue(object.tokenId)).unsigned = true;
                    else if (typeof object.tokenId === "string")
                        message.tokenId = parseInt(object.tokenId, 10);
                    else if (typeof object.tokenId === "number")
                        message.tokenId = object.tokenId;
                    else if (typeof object.tokenId === "object")
                        message.tokenId = new $util.LongBits(object.tokenId.low >>> 0, object.tokenId.high >>> 0).toNumber(true);
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.token != null)
                    message.token = String(object.token);
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                return message;
            };

            /**
             * Creates a plain object from an IntegrationToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.IntegrationToken
             * @static
             * @param {fastcity.admin.IntegrationToken} message IntegrationToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            IntegrationToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.tokenId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.tokenId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.token = "";
                    object.isDeleted = false;
                }
                if (message.tokenId != null && message.hasOwnProperty("tokenId"))
                    if (typeof message.tokenId === "number")
                        object.tokenId = options.longs === String ? String(message.tokenId) : message.tokenId;
                    else
                        object.tokenId = options.longs === String ? $util.Long.prototype.toString.call(message.tokenId) : options.longs === Number ? new $util.LongBits(message.tokenId.low >>> 0, message.tokenId.high >>> 0).toNumber(true) : message.tokenId;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.token != null && message.hasOwnProperty("token"))
                    object.token = message.token;
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                return object;
            };

            /**
             * Converts this IntegrationToken to JSON.
             * @function toJSON
             * @memberof fastcity.admin.IntegrationToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            IntegrationToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return IntegrationToken;
        })();

        admin.CreateIntegrationToken = (function() {

            /**
             * Properties of a CreateIntegrationToken.
             * @memberof fastcity.admin
             * @interface ICreateIntegrationToken
             * @property {number|Long|null} [companyId] CreateIntegrationToken companyId
             */

            /**
             * Constructs a new CreateIntegrationToken.
             * @memberof fastcity.admin
             * @classdesc Represents a CreateIntegrationToken.
             * @implements ICreateIntegrationToken
             * @constructor
             * @param {fastcity.admin.ICreateIntegrationToken=} [properties] Properties to set
             */
            function CreateIntegrationToken(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateIntegrationToken companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.CreateIntegrationToken
             * @instance
             */
            CreateIntegrationToken.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new CreateIntegrationToken instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CreateIntegrationToken
             * @static
             * @param {fastcity.admin.ICreateIntegrationToken=} [properties] Properties to set
             * @returns {fastcity.admin.CreateIntegrationToken} CreateIntegrationToken instance
             */
            CreateIntegrationToken.create = function create(properties) {
                return new CreateIntegrationToken(properties);
            };

            /**
             * Encodes the specified CreateIntegrationToken message. Does not implicitly {@link fastcity.admin.CreateIntegrationToken.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CreateIntegrationToken
             * @static
             * @param {fastcity.admin.ICreateIntegrationToken} message CreateIntegrationToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateIntegrationToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                return writer;
            };

            /**
             * Decodes a CreateIntegrationToken message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CreateIntegrationToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CreateIntegrationToken} CreateIntegrationToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateIntegrationToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CreateIntegrationToken();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CreateIntegrationToken message.
             * @function verify
             * @memberof fastcity.admin.CreateIntegrationToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateIntegrationToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                return null;
            };

            /**
             * Creates a CreateIntegrationToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CreateIntegrationToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CreateIntegrationToken} CreateIntegrationToken
             */
            CreateIntegrationToken.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CreateIntegrationToken)
                    return object;
                var message = new $root.fastcity.admin.CreateIntegrationToken();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a CreateIntegrationToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CreateIntegrationToken
             * @static
             * @param {fastcity.admin.CreateIntegrationToken} message CreateIntegrationToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateIntegrationToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                return object;
            };

            /**
             * Converts this CreateIntegrationToken to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CreateIntegrationToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateIntegrationToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CreateIntegrationToken;
        })();

        admin.DeleteIntegrationToken = (function() {

            /**
             * Properties of a DeleteIntegrationToken.
             * @memberof fastcity.admin
             * @interface IDeleteIntegrationToken
             * @property {number|Long|null} [tokenId] DeleteIntegrationToken tokenId
             */

            /**
             * Constructs a new DeleteIntegrationToken.
             * @memberof fastcity.admin
             * @classdesc Represents a DeleteIntegrationToken.
             * @implements IDeleteIntegrationToken
             * @constructor
             * @param {fastcity.admin.IDeleteIntegrationToken=} [properties] Properties to set
             */
            function DeleteIntegrationToken(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteIntegrationToken tokenId.
             * @member {number|Long} tokenId
             * @memberof fastcity.admin.DeleteIntegrationToken
             * @instance
             */
            DeleteIntegrationToken.prototype.tokenId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new DeleteIntegrationToken instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DeleteIntegrationToken
             * @static
             * @param {fastcity.admin.IDeleteIntegrationToken=} [properties] Properties to set
             * @returns {fastcity.admin.DeleteIntegrationToken} DeleteIntegrationToken instance
             */
            DeleteIntegrationToken.create = function create(properties) {
                return new DeleteIntegrationToken(properties);
            };

            /**
             * Encodes the specified DeleteIntegrationToken message. Does not implicitly {@link fastcity.admin.DeleteIntegrationToken.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DeleteIntegrationToken
             * @static
             * @param {fastcity.admin.IDeleteIntegrationToken} message DeleteIntegrationToken message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteIntegrationToken.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.tokenId != null && Object.hasOwnProperty.call(message, "tokenId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.tokenId);
                return writer;
            };

            /**
             * Decodes a DeleteIntegrationToken message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DeleteIntegrationToken
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DeleteIntegrationToken} DeleteIntegrationToken
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteIntegrationToken.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DeleteIntegrationToken();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.tokenId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeleteIntegrationToken message.
             * @function verify
             * @memberof fastcity.admin.DeleteIntegrationToken
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteIntegrationToken.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.tokenId != null && message.hasOwnProperty("tokenId"))
                    if (!$util.isInteger(message.tokenId) && !(message.tokenId && $util.isInteger(message.tokenId.low) && $util.isInteger(message.tokenId.high)))
                        return "tokenId: integer|Long expected";
                return null;
            };

            /**
             * Creates a DeleteIntegrationToken message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DeleteIntegrationToken
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DeleteIntegrationToken} DeleteIntegrationToken
             */
            DeleteIntegrationToken.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DeleteIntegrationToken)
                    return object;
                var message = new $root.fastcity.admin.DeleteIntegrationToken();
                if (object.tokenId != null)
                    if ($util.Long)
                        (message.tokenId = $util.Long.fromValue(object.tokenId)).unsigned = true;
                    else if (typeof object.tokenId === "string")
                        message.tokenId = parseInt(object.tokenId, 10);
                    else if (typeof object.tokenId === "number")
                        message.tokenId = object.tokenId;
                    else if (typeof object.tokenId === "object")
                        message.tokenId = new $util.LongBits(object.tokenId.low >>> 0, object.tokenId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a DeleteIntegrationToken message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DeleteIntegrationToken
             * @static
             * @param {fastcity.admin.DeleteIntegrationToken} message DeleteIntegrationToken
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteIntegrationToken.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.tokenId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.tokenId = options.longs === String ? "0" : 0;
                if (message.tokenId != null && message.hasOwnProperty("tokenId"))
                    if (typeof message.tokenId === "number")
                        object.tokenId = options.longs === String ? String(message.tokenId) : message.tokenId;
                    else
                        object.tokenId = options.longs === String ? $util.Long.prototype.toString.call(message.tokenId) : options.longs === Number ? new $util.LongBits(message.tokenId.low >>> 0, message.tokenId.high >>> 0).toNumber(true) : message.tokenId;
                return object;
            };

            /**
             * Converts this DeleteIntegrationToken to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DeleteIntegrationToken
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteIntegrationToken.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeleteIntegrationToken;
        })();

        admin.GetIntegrationTokenList = (function() {

            /**
             * Properties of a GetIntegrationTokenList.
             * @memberof fastcity.admin
             * @interface IGetIntegrationTokenList
             * @property {number|Long|null} [companyId] GetIntegrationTokenList companyId
             */

            /**
             * Constructs a new GetIntegrationTokenList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetIntegrationTokenList.
             * @implements IGetIntegrationTokenList
             * @constructor
             * @param {fastcity.admin.IGetIntegrationTokenList=} [properties] Properties to set
             */
            function GetIntegrationTokenList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetIntegrationTokenList companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.GetIntegrationTokenList
             * @instance
             */
            GetIntegrationTokenList.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new GetIntegrationTokenList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetIntegrationTokenList
             * @static
             * @param {fastcity.admin.IGetIntegrationTokenList=} [properties] Properties to set
             * @returns {fastcity.admin.GetIntegrationTokenList} GetIntegrationTokenList instance
             */
            GetIntegrationTokenList.create = function create(properties) {
                return new GetIntegrationTokenList(properties);
            };

            /**
             * Encodes the specified GetIntegrationTokenList message. Does not implicitly {@link fastcity.admin.GetIntegrationTokenList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetIntegrationTokenList
             * @static
             * @param {fastcity.admin.IGetIntegrationTokenList} message GetIntegrationTokenList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetIntegrationTokenList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                return writer;
            };

            /**
             * Decodes a GetIntegrationTokenList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetIntegrationTokenList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetIntegrationTokenList} GetIntegrationTokenList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetIntegrationTokenList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetIntegrationTokenList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetIntegrationTokenList message.
             * @function verify
             * @memberof fastcity.admin.GetIntegrationTokenList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetIntegrationTokenList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                return null;
            };

            /**
             * Creates a GetIntegrationTokenList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetIntegrationTokenList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetIntegrationTokenList} GetIntegrationTokenList
             */
            GetIntegrationTokenList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetIntegrationTokenList)
                    return object;
                var message = new $root.fastcity.admin.GetIntegrationTokenList();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a GetIntegrationTokenList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetIntegrationTokenList
             * @static
             * @param {fastcity.admin.GetIntegrationTokenList} message GetIntegrationTokenList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetIntegrationTokenList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                return object;
            };

            /**
             * Converts this GetIntegrationTokenList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetIntegrationTokenList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetIntegrationTokenList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetIntegrationTokenList;
        })();

        admin.IntegrationTokenList = (function() {

            /**
             * Properties of an IntegrationTokenList.
             * @memberof fastcity.admin
             * @interface IIntegrationTokenList
             * @property {Array.<fastcity.admin.IIntegrationToken>|null} [integrationToken] IntegrationTokenList integrationToken
             */

            /**
             * Constructs a new IntegrationTokenList.
             * @memberof fastcity.admin
             * @classdesc Represents an IntegrationTokenList.
             * @implements IIntegrationTokenList
             * @constructor
             * @param {fastcity.admin.IIntegrationTokenList=} [properties] Properties to set
             */
            function IntegrationTokenList(properties) {
                this.integrationToken = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * IntegrationTokenList integrationToken.
             * @member {Array.<fastcity.admin.IIntegrationToken>} integrationToken
             * @memberof fastcity.admin.IntegrationTokenList
             * @instance
             */
            IntegrationTokenList.prototype.integrationToken = $util.emptyArray;

            /**
             * Creates a new IntegrationTokenList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.IntegrationTokenList
             * @static
             * @param {fastcity.admin.IIntegrationTokenList=} [properties] Properties to set
             * @returns {fastcity.admin.IntegrationTokenList} IntegrationTokenList instance
             */
            IntegrationTokenList.create = function create(properties) {
                return new IntegrationTokenList(properties);
            };

            /**
             * Encodes the specified IntegrationTokenList message. Does not implicitly {@link fastcity.admin.IntegrationTokenList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.IntegrationTokenList
             * @static
             * @param {fastcity.admin.IIntegrationTokenList} message IntegrationTokenList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            IntegrationTokenList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.integrationToken != null && message.integrationToken.length)
                    for (var i = 0; i < message.integrationToken.length; ++i)
                        $root.fastcity.admin.IntegrationToken.encode(message.integrationToken[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an IntegrationTokenList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.IntegrationTokenList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.IntegrationTokenList} IntegrationTokenList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            IntegrationTokenList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.IntegrationTokenList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.integrationToken && message.integrationToken.length))
                            message.integrationToken = [];
                        message.integrationToken.push($root.fastcity.admin.IntegrationToken.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an IntegrationTokenList message.
             * @function verify
             * @memberof fastcity.admin.IntegrationTokenList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            IntegrationTokenList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.integrationToken != null && message.hasOwnProperty("integrationToken")) {
                    if (!Array.isArray(message.integrationToken))
                        return "integrationToken: array expected";
                    for (var i = 0; i < message.integrationToken.length; ++i) {
                        var error = $root.fastcity.admin.IntegrationToken.verify(message.integrationToken[i]);
                        if (error)
                            return "integrationToken." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an IntegrationTokenList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.IntegrationTokenList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.IntegrationTokenList} IntegrationTokenList
             */
            IntegrationTokenList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.IntegrationTokenList)
                    return object;
                var message = new $root.fastcity.admin.IntegrationTokenList();
                if (object.integrationToken) {
                    if (!Array.isArray(object.integrationToken))
                        throw TypeError(".fastcity.admin.IntegrationTokenList.integrationToken: array expected");
                    message.integrationToken = [];
                    for (var i = 0; i < object.integrationToken.length; ++i) {
                        if (typeof object.integrationToken[i] !== "object")
                            throw TypeError(".fastcity.admin.IntegrationTokenList.integrationToken: object expected");
                        message.integrationToken[i] = $root.fastcity.admin.IntegrationToken.fromObject(object.integrationToken[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an IntegrationTokenList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.IntegrationTokenList
             * @static
             * @param {fastcity.admin.IntegrationTokenList} message IntegrationTokenList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            IntegrationTokenList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.integrationToken = [];
                if (message.integrationToken && message.integrationToken.length) {
                    object.integrationToken = [];
                    for (var j = 0; j < message.integrationToken.length; ++j)
                        object.integrationToken[j] = $root.fastcity.admin.IntegrationToken.toObject(message.integrationToken[j], options);
                }
                return object;
            };

            /**
             * Converts this IntegrationTokenList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.IntegrationTokenList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            IntegrationTokenList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return IntegrationTokenList;
        })();

        admin.Location = (function() {

            /**
             * Properties of a Location.
             * @memberof fastcity.admin
             * @interface ILocation
             * @property {number|Long|null} [courierId] Location courierId
             * @property {number|null} [latitude] Location latitude
             * @property {number|null} [longitude] Location longitude
             * @property {number|Long|null} [timestamp] Location timestamp
             * @property {number|null} [bearing] Location bearing
             * @property {number|null} [altitude] Location altitude
             * @property {number|null} [speed] Location speed
             * @property {number|Long|null} [orderId] Location orderId
             */

            /**
             * Constructs a new Location.
             * @memberof fastcity.admin
             * @classdesc Represents a Location.
             * @implements ILocation
             * @constructor
             * @param {fastcity.admin.ILocation=} [properties] Properties to set
             */
            function Location(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Location courierId.
             * @member {number|Long} courierId
             * @memberof fastcity.admin.Location
             * @instance
             */
            Location.prototype.courierId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Location latitude.
             * @member {number} latitude
             * @memberof fastcity.admin.Location
             * @instance
             */
            Location.prototype.latitude = 0;

            /**
             * Location longitude.
             * @member {number} longitude
             * @memberof fastcity.admin.Location
             * @instance
             */
            Location.prototype.longitude = 0;

            /**
             * Location timestamp.
             * @member {number|Long} timestamp
             * @memberof fastcity.admin.Location
             * @instance
             */
            Location.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Location bearing.
             * @member {number} bearing
             * @memberof fastcity.admin.Location
             * @instance
             */
            Location.prototype.bearing = 0;

            /**
             * Location altitude.
             * @member {number} altitude
             * @memberof fastcity.admin.Location
             * @instance
             */
            Location.prototype.altitude = 0;

            /**
             * Location speed.
             * @member {number} speed
             * @memberof fastcity.admin.Location
             * @instance
             */
            Location.prototype.speed = 0;

            /**
             * Location orderId.
             * @member {number|Long} orderId
             * @memberof fastcity.admin.Location
             * @instance
             */
            Location.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new Location instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.Location
             * @static
             * @param {fastcity.admin.ILocation=} [properties] Properties to set
             * @returns {fastcity.admin.Location} Location instance
             */
            Location.create = function create(properties) {
                return new Location(properties);
            };

            /**
             * Encodes the specified Location message. Does not implicitly {@link fastcity.admin.Location.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.Location
             * @static
             * @param {fastcity.admin.ILocation} message Location message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Location.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && Object.hasOwnProperty.call(message, "courierId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.courierId);
                if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
                    writer.uint32(/* id 2, wireType 1 =*/17).double(message.latitude);
                if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                    writer.uint32(/* id 3, wireType 1 =*/25).double(message.longitude);
                if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.timestamp);
                if (message.bearing != null && Object.hasOwnProperty.call(message, "bearing"))
                    writer.uint32(/* id 5, wireType 1 =*/41).double(message.bearing);
                if (message.altitude != null && Object.hasOwnProperty.call(message, "altitude"))
                    writer.uint32(/* id 6, wireType 1 =*/49).double(message.altitude);
                if (message.speed != null && Object.hasOwnProperty.call(message, "speed"))
                    writer.uint32(/* id 7, wireType 1 =*/57).double(message.speed);
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.orderId);
                return writer;
            };

            /**
             * Decodes a Location message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.Location
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.Location} Location
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Location.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.Location();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.courierId = reader.uint64();
                        break;
                    case 2:
                        message.latitude = reader.double();
                        break;
                    case 3:
                        message.longitude = reader.double();
                        break;
                    case 4:
                        message.timestamp = reader.int64();
                        break;
                    case 5:
                        message.bearing = reader.double();
                        break;
                    case 6:
                        message.altitude = reader.double();
                        break;
                    case 7:
                        message.speed = reader.double();
                        break;
                    case 8:
                        message.orderId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Location message.
             * @function verify
             * @memberof fastcity.admin.Location
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Location.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (!$util.isInteger(message.courierId) && !(message.courierId && $util.isInteger(message.courierId.low) && $util.isInteger(message.courierId.high)))
                        return "courierId: integer|Long expected";
                if (message.latitude != null && message.hasOwnProperty("latitude"))
                    if (typeof message.latitude !== "number")
                        return "latitude: number expected";
                if (message.longitude != null && message.hasOwnProperty("longitude"))
                    if (typeof message.longitude !== "number")
                        return "longitude: number expected";
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                        return "timestamp: integer|Long expected";
                if (message.bearing != null && message.hasOwnProperty("bearing"))
                    if (typeof message.bearing !== "number")
                        return "bearing: number expected";
                if (message.altitude != null && message.hasOwnProperty("altitude"))
                    if (typeof message.altitude !== "number")
                        return "altitude: number expected";
                if (message.speed != null && message.hasOwnProperty("speed"))
                    if (typeof message.speed !== "number")
                        return "speed: number expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                return null;
            };

            /**
             * Creates a Location message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.Location
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.Location} Location
             */
            Location.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.Location)
                    return object;
                var message = new $root.fastcity.admin.Location();
                if (object.courierId != null)
                    if ($util.Long)
                        (message.courierId = $util.Long.fromValue(object.courierId)).unsigned = true;
                    else if (typeof object.courierId === "string")
                        message.courierId = parseInt(object.courierId, 10);
                    else if (typeof object.courierId === "number")
                        message.courierId = object.courierId;
                    else if (typeof object.courierId === "object")
                        message.courierId = new $util.LongBits(object.courierId.low >>> 0, object.courierId.high >>> 0).toNumber(true);
                if (object.latitude != null)
                    message.latitude = Number(object.latitude);
                if (object.longitude != null)
                    message.longitude = Number(object.longitude);
                if (object.timestamp != null)
                    if ($util.Long)
                        (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                    else if (typeof object.timestamp === "string")
                        message.timestamp = parseInt(object.timestamp, 10);
                    else if (typeof object.timestamp === "number")
                        message.timestamp = object.timestamp;
                    else if (typeof object.timestamp === "object")
                        message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
                if (object.bearing != null)
                    message.bearing = Number(object.bearing);
                if (object.altitude != null)
                    message.altitude = Number(object.altitude);
                if (object.speed != null)
                    message.speed = Number(object.speed);
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = true;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a Location message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.Location
             * @static
             * @param {fastcity.admin.Location} message Location
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Location.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.courierId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.courierId = options.longs === String ? "0" : 0;
                    object.latitude = 0;
                    object.longitude = 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestamp = options.longs === String ? "0" : 0;
                    object.bearing = 0;
                    object.altitude = 0;
                    object.speed = 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                }
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (typeof message.courierId === "number")
                        object.courierId = options.longs === String ? String(message.courierId) : message.courierId;
                    else
                        object.courierId = options.longs === String ? $util.Long.prototype.toString.call(message.courierId) : options.longs === Number ? new $util.LongBits(message.courierId.low >>> 0, message.courierId.high >>> 0).toNumber(true) : message.courierId;
                if (message.latitude != null && message.hasOwnProperty("latitude"))
                    object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
                if (message.longitude != null && message.hasOwnProperty("longitude"))
                    object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
                if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                    if (typeof message.timestamp === "number")
                        object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                    else
                        object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
                if (message.bearing != null && message.hasOwnProperty("bearing"))
                    object.bearing = options.json && !isFinite(message.bearing) ? String(message.bearing) : message.bearing;
                if (message.altitude != null && message.hasOwnProperty("altitude"))
                    object.altitude = options.json && !isFinite(message.altitude) ? String(message.altitude) : message.altitude;
                if (message.speed != null && message.hasOwnProperty("speed"))
                    object.speed = options.json && !isFinite(message.speed) ? String(message.speed) : message.speed;
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber(true) : message.orderId;
                return object;
            };

            /**
             * Converts this Location to JSON.
             * @function toJSON
             * @memberof fastcity.admin.Location
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Location.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Location;
        })();

        admin.GetLastLocationList = (function() {

            /**
             * Properties of a GetLastLocationList.
             * @memberof fastcity.admin
             * @interface IGetLastLocationList
             * @property {number|Long|null} [companyId] GetLastLocationList companyId
             * @property {Array.<number|Long>|null} [courierId] GetLastLocationList courierId
             */

            /**
             * Constructs a new GetLastLocationList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetLastLocationList.
             * @implements IGetLastLocationList
             * @constructor
             * @param {fastcity.admin.IGetLastLocationList=} [properties] Properties to set
             */
            function GetLastLocationList(properties) {
                this.courierId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetLastLocationList companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.GetLastLocationList
             * @instance
             */
            GetLastLocationList.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetLastLocationList courierId.
             * @member {Array.<number|Long>} courierId
             * @memberof fastcity.admin.GetLastLocationList
             * @instance
             */
            GetLastLocationList.prototype.courierId = $util.emptyArray;

            /**
             * Creates a new GetLastLocationList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetLastLocationList
             * @static
             * @param {fastcity.admin.IGetLastLocationList=} [properties] Properties to set
             * @returns {fastcity.admin.GetLastLocationList} GetLastLocationList instance
             */
            GetLastLocationList.create = function create(properties) {
                return new GetLastLocationList(properties);
            };

            /**
             * Encodes the specified GetLastLocationList message. Does not implicitly {@link fastcity.admin.GetLastLocationList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetLastLocationList
             * @static
             * @param {fastcity.admin.IGetLastLocationList} message GetLastLocationList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetLastLocationList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.courierId != null && message.courierId.length) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork();
                    for (var i = 0; i < message.courierId.length; ++i)
                        writer.uint64(message.courierId[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes a GetLastLocationList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetLastLocationList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetLastLocationList} GetLastLocationList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetLastLocationList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetLastLocationList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        if (!(message.courierId && message.courierId.length))
                            message.courierId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.courierId.push(reader.uint64());
                        } else
                            message.courierId.push(reader.uint64());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetLastLocationList message.
             * @function verify
             * @memberof fastcity.admin.GetLastLocationList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetLastLocationList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.courierId != null && message.hasOwnProperty("courierId")) {
                    if (!Array.isArray(message.courierId))
                        return "courierId: array expected";
                    for (var i = 0; i < message.courierId.length; ++i)
                        if (!$util.isInteger(message.courierId[i]) && !(message.courierId[i] && $util.isInteger(message.courierId[i].low) && $util.isInteger(message.courierId[i].high)))
                            return "courierId: integer|Long[] expected";
                }
                return null;
            };

            /**
             * Creates a GetLastLocationList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetLastLocationList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetLastLocationList} GetLastLocationList
             */
            GetLastLocationList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetLastLocationList)
                    return object;
                var message = new $root.fastcity.admin.GetLastLocationList();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.courierId) {
                    if (!Array.isArray(object.courierId))
                        throw TypeError(".fastcity.admin.GetLastLocationList.courierId: array expected");
                    message.courierId = [];
                    for (var i = 0; i < object.courierId.length; ++i)
                        if ($util.Long)
                            (message.courierId[i] = $util.Long.fromValue(object.courierId[i])).unsigned = true;
                        else if (typeof object.courierId[i] === "string")
                            message.courierId[i] = parseInt(object.courierId[i], 10);
                        else if (typeof object.courierId[i] === "number")
                            message.courierId[i] = object.courierId[i];
                        else if (typeof object.courierId[i] === "object")
                            message.courierId[i] = new $util.LongBits(object.courierId[i].low >>> 0, object.courierId[i].high >>> 0).toNumber(true);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetLastLocationList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetLastLocationList
             * @static
             * @param {fastcity.admin.GetLastLocationList} message GetLastLocationList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetLastLocationList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.courierId = [];
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.courierId && message.courierId.length) {
                    object.courierId = [];
                    for (var j = 0; j < message.courierId.length; ++j)
                        if (typeof message.courierId[j] === "number")
                            object.courierId[j] = options.longs === String ? String(message.courierId[j]) : message.courierId[j];
                        else
                            object.courierId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.courierId[j]) : options.longs === Number ? new $util.LongBits(message.courierId[j].low >>> 0, message.courierId[j].high >>> 0).toNumber(true) : message.courierId[j];
                }
                return object;
            };

            /**
             * Converts this GetLastLocationList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetLastLocationList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetLastLocationList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetLastLocationList;
        })();

        admin.GetLocationList = (function() {

            /**
             * Properties of a GetLocationList.
             * @memberof fastcity.admin
             * @interface IGetLocationList
             * @property {number|Long|null} [courierId] GetLocationList courierId
             * @property {number|Long|null} [orderId] GetLocationList orderId
             * @property {number|Long|null} [fromTime] GetLocationList fromTime
             * @property {number|Long|null} [toTime] GetLocationList toTime
             * @property {number|null} [offset] GetLocationList offset
             * @property {number|null} [limit] GetLocationList limit
             * @property {fastcity.common.SortOrder.Order|null} [sortOrder] GetLocationList sortOrder
             */

            /**
             * Constructs a new GetLocationList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetLocationList.
             * @implements IGetLocationList
             * @constructor
             * @param {fastcity.admin.IGetLocationList=} [properties] Properties to set
             */
            function GetLocationList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetLocationList courierId.
             * @member {number|Long} courierId
             * @memberof fastcity.admin.GetLocationList
             * @instance
             */
            GetLocationList.prototype.courierId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetLocationList orderId.
             * @member {number|Long} orderId
             * @memberof fastcity.admin.GetLocationList
             * @instance
             */
            GetLocationList.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetLocationList fromTime.
             * @member {number|Long} fromTime
             * @memberof fastcity.admin.GetLocationList
             * @instance
             */
            GetLocationList.prototype.fromTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * GetLocationList toTime.
             * @member {number|Long} toTime
             * @memberof fastcity.admin.GetLocationList
             * @instance
             */
            GetLocationList.prototype.toTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * GetLocationList offset.
             * @member {number} offset
             * @memberof fastcity.admin.GetLocationList
             * @instance
             */
            GetLocationList.prototype.offset = 0;

            /**
             * GetLocationList limit.
             * @member {number} limit
             * @memberof fastcity.admin.GetLocationList
             * @instance
             */
            GetLocationList.prototype.limit = 0;

            /**
             * GetLocationList sortOrder.
             * @member {fastcity.common.SortOrder.Order} sortOrder
             * @memberof fastcity.admin.GetLocationList
             * @instance
             */
            GetLocationList.prototype.sortOrder = 0;

            /**
             * Creates a new GetLocationList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetLocationList
             * @static
             * @param {fastcity.admin.IGetLocationList=} [properties] Properties to set
             * @returns {fastcity.admin.GetLocationList} GetLocationList instance
             */
            GetLocationList.create = function create(properties) {
                return new GetLocationList(properties);
            };

            /**
             * Encodes the specified GetLocationList message. Does not implicitly {@link fastcity.admin.GetLocationList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetLocationList
             * @static
             * @param {fastcity.admin.IGetLocationList} message GetLocationList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetLocationList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && Object.hasOwnProperty.call(message, "courierId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.courierId);
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.orderId);
                if (message.fromTime != null && Object.hasOwnProperty.call(message, "fromTime"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.fromTime);
                if (message.toTime != null && Object.hasOwnProperty.call(message, "toTime"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.toTime);
                if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.offset);
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.limit);
                if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.sortOrder);
                return writer;
            };

            /**
             * Decodes a GetLocationList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetLocationList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetLocationList} GetLocationList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetLocationList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetLocationList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.courierId = reader.uint64();
                        break;
                    case 2:
                        message.orderId = reader.uint64();
                        break;
                    case 3:
                        message.fromTime = reader.int64();
                        break;
                    case 4:
                        message.toTime = reader.int64();
                        break;
                    case 5:
                        message.offset = reader.uint32();
                        break;
                    case 6:
                        message.limit = reader.uint32();
                        break;
                    case 7:
                        message.sortOrder = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetLocationList message.
             * @function verify
             * @memberof fastcity.admin.GetLocationList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetLocationList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (!$util.isInteger(message.courierId) && !(message.courierId && $util.isInteger(message.courierId.low) && $util.isInteger(message.courierId.high)))
                        return "courierId: integer|Long expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                    if (!$util.isInteger(message.fromTime) && !(message.fromTime && $util.isInteger(message.fromTime.low) && $util.isInteger(message.fromTime.high)))
                        return "fromTime: integer|Long expected";
                if (message.toTime != null && message.hasOwnProperty("toTime"))
                    if (!$util.isInteger(message.toTime) && !(message.toTime && $util.isInteger(message.toTime.low) && $util.isInteger(message.toTime.high)))
                        return "toTime: integer|Long expected";
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (!$util.isInteger(message.offset))
                        return "offset: integer expected";
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit))
                        return "limit: integer expected";
                if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                    switch (message.sortOrder) {
                    default:
                        return "sortOrder: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                return null;
            };

            /**
             * Creates a GetLocationList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetLocationList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetLocationList} GetLocationList
             */
            GetLocationList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetLocationList)
                    return object;
                var message = new $root.fastcity.admin.GetLocationList();
                if (object.courierId != null)
                    if ($util.Long)
                        (message.courierId = $util.Long.fromValue(object.courierId)).unsigned = true;
                    else if (typeof object.courierId === "string")
                        message.courierId = parseInt(object.courierId, 10);
                    else if (typeof object.courierId === "number")
                        message.courierId = object.courierId;
                    else if (typeof object.courierId === "object")
                        message.courierId = new $util.LongBits(object.courierId.low >>> 0, object.courierId.high >>> 0).toNumber(true);
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = true;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber(true);
                if (object.fromTime != null)
                    if ($util.Long)
                        (message.fromTime = $util.Long.fromValue(object.fromTime)).unsigned = false;
                    else if (typeof object.fromTime === "string")
                        message.fromTime = parseInt(object.fromTime, 10);
                    else if (typeof object.fromTime === "number")
                        message.fromTime = object.fromTime;
                    else if (typeof object.fromTime === "object")
                        message.fromTime = new $util.LongBits(object.fromTime.low >>> 0, object.fromTime.high >>> 0).toNumber();
                if (object.toTime != null)
                    if ($util.Long)
                        (message.toTime = $util.Long.fromValue(object.toTime)).unsigned = false;
                    else if (typeof object.toTime === "string")
                        message.toTime = parseInt(object.toTime, 10);
                    else if (typeof object.toTime === "number")
                        message.toTime = object.toTime;
                    else if (typeof object.toTime === "object")
                        message.toTime = new $util.LongBits(object.toTime.low >>> 0, object.toTime.high >>> 0).toNumber();
                if (object.offset != null)
                    message.offset = object.offset >>> 0;
                if (object.limit != null)
                    message.limit = object.limit >>> 0;
                switch (object.sortOrder) {
                case "ASC":
                case 0:
                    message.sortOrder = 0;
                    break;
                case "DESC":
                case 1:
                    message.sortOrder = 1;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a GetLocationList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetLocationList
             * @static
             * @param {fastcity.admin.GetLocationList} message GetLocationList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetLocationList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.courierId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.courierId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.fromTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.fromTime = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.toTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.toTime = options.longs === String ? "0" : 0;
                    object.offset = 0;
                    object.limit = 0;
                    object.sortOrder = options.enums === String ? "ASC" : 0;
                }
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (typeof message.courierId === "number")
                        object.courierId = options.longs === String ? String(message.courierId) : message.courierId;
                    else
                        object.courierId = options.longs === String ? $util.Long.prototype.toString.call(message.courierId) : options.longs === Number ? new $util.LongBits(message.courierId.low >>> 0, message.courierId.high >>> 0).toNumber(true) : message.courierId;
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber(true) : message.orderId;
                if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                    if (typeof message.fromTime === "number")
                        object.fromTime = options.longs === String ? String(message.fromTime) : message.fromTime;
                    else
                        object.fromTime = options.longs === String ? $util.Long.prototype.toString.call(message.fromTime) : options.longs === Number ? new $util.LongBits(message.fromTime.low >>> 0, message.fromTime.high >>> 0).toNumber() : message.fromTime;
                if (message.toTime != null && message.hasOwnProperty("toTime"))
                    if (typeof message.toTime === "number")
                        object.toTime = options.longs === String ? String(message.toTime) : message.toTime;
                    else
                        object.toTime = options.longs === String ? $util.Long.prototype.toString.call(message.toTime) : options.longs === Number ? new $util.LongBits(message.toTime.low >>> 0, message.toTime.high >>> 0).toNumber() : message.toTime;
                if (message.offset != null && message.hasOwnProperty("offset"))
                    object.offset = message.offset;
                if (message.limit != null && message.hasOwnProperty("limit"))
                    object.limit = message.limit;
                if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                    object.sortOrder = options.enums === String ? $root.fastcity.common.SortOrder.Order[message.sortOrder] : message.sortOrder;
                return object;
            };

            /**
             * Converts this GetLocationList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetLocationList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetLocationList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetLocationList;
        })();

        admin.LocationList = (function() {

            /**
             * Properties of a LocationList.
             * @memberof fastcity.admin
             * @interface ILocationList
             * @property {Array.<fastcity.admin.ILocation>|null} [location] LocationList location
             * @property {number|null} [totalLocations] LocationList totalLocations
             */

            /**
             * Constructs a new LocationList.
             * @memberof fastcity.admin
             * @classdesc Represents a LocationList.
             * @implements ILocationList
             * @constructor
             * @param {fastcity.admin.ILocationList=} [properties] Properties to set
             */
            function LocationList(properties) {
                this.location = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LocationList location.
             * @member {Array.<fastcity.admin.ILocation>} location
             * @memberof fastcity.admin.LocationList
             * @instance
             */
            LocationList.prototype.location = $util.emptyArray;

            /**
             * LocationList totalLocations.
             * @member {number} totalLocations
             * @memberof fastcity.admin.LocationList
             * @instance
             */
            LocationList.prototype.totalLocations = 0;

            /**
             * Creates a new LocationList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.LocationList
             * @static
             * @param {fastcity.admin.ILocationList=} [properties] Properties to set
             * @returns {fastcity.admin.LocationList} LocationList instance
             */
            LocationList.create = function create(properties) {
                return new LocationList(properties);
            };

            /**
             * Encodes the specified LocationList message. Does not implicitly {@link fastcity.admin.LocationList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.LocationList
             * @static
             * @param {fastcity.admin.ILocationList} message LocationList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.location != null && message.location.length)
                    for (var i = 0; i < message.location.length; ++i)
                        $root.fastcity.admin.Location.encode(message.location[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.totalLocations != null && Object.hasOwnProperty.call(message, "totalLocations"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.totalLocations);
                return writer;
            };

            /**
             * Decodes a LocationList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.LocationList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.LocationList} LocationList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.LocationList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.location && message.location.length))
                            message.location = [];
                        message.location.push($root.fastcity.admin.Location.decode(reader, reader.uint32()));
                        break;
                    case 2:
                        message.totalLocations = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a LocationList message.
             * @function verify
             * @memberof fastcity.admin.LocationList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LocationList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.location != null && message.hasOwnProperty("location")) {
                    if (!Array.isArray(message.location))
                        return "location: array expected";
                    for (var i = 0; i < message.location.length; ++i) {
                        var error = $root.fastcity.admin.Location.verify(message.location[i]);
                        if (error)
                            return "location." + error;
                    }
                }
                if (message.totalLocations != null && message.hasOwnProperty("totalLocations"))
                    if (!$util.isInteger(message.totalLocations))
                        return "totalLocations: integer expected";
                return null;
            };

            /**
             * Creates a LocationList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.LocationList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.LocationList} LocationList
             */
            LocationList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.LocationList)
                    return object;
                var message = new $root.fastcity.admin.LocationList();
                if (object.location) {
                    if (!Array.isArray(object.location))
                        throw TypeError(".fastcity.admin.LocationList.location: array expected");
                    message.location = [];
                    for (var i = 0; i < object.location.length; ++i) {
                        if (typeof object.location[i] !== "object")
                            throw TypeError(".fastcity.admin.LocationList.location: object expected");
                        message.location[i] = $root.fastcity.admin.Location.fromObject(object.location[i]);
                    }
                }
                if (object.totalLocations != null)
                    message.totalLocations = object.totalLocations >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a LocationList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.LocationList
             * @static
             * @param {fastcity.admin.LocationList} message LocationList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LocationList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.location = [];
                if (options.defaults)
                    object.totalLocations = 0;
                if (message.location && message.location.length) {
                    object.location = [];
                    for (var j = 0; j < message.location.length; ++j)
                        object.location[j] = $root.fastcity.admin.Location.toObject(message.location[j], options);
                }
                if (message.totalLocations != null && message.hasOwnProperty("totalLocations"))
                    object.totalLocations = message.totalLocations;
                return object;
            };

            /**
             * Converts this LocationList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.LocationList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LocationList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return LocationList;
        })();

        admin.ChangedLastLocation = (function() {

            /**
             * Properties of a ChangedLastLocation.
             * @memberof fastcity.admin
             * @interface IChangedLastLocation
             * @property {Array.<number|Long>|null} [courierId] ChangedLastLocation courierId
             */

            /**
             * Constructs a new ChangedLastLocation.
             * @memberof fastcity.admin
             * @classdesc Represents a ChangedLastLocation.
             * @implements IChangedLastLocation
             * @constructor
             * @param {fastcity.admin.IChangedLastLocation=} [properties] Properties to set
             */
            function ChangedLastLocation(properties) {
                this.courierId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ChangedLastLocation courierId.
             * @member {Array.<number|Long>} courierId
             * @memberof fastcity.admin.ChangedLastLocation
             * @instance
             */
            ChangedLastLocation.prototype.courierId = $util.emptyArray;

            /**
             * Creates a new ChangedLastLocation instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ChangedLastLocation
             * @static
             * @param {fastcity.admin.IChangedLastLocation=} [properties] Properties to set
             * @returns {fastcity.admin.ChangedLastLocation} ChangedLastLocation instance
             */
            ChangedLastLocation.create = function create(properties) {
                return new ChangedLastLocation(properties);
            };

            /**
             * Encodes the specified ChangedLastLocation message. Does not implicitly {@link fastcity.admin.ChangedLastLocation.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ChangedLastLocation
             * @static
             * @param {fastcity.admin.IChangedLastLocation} message ChangedLastLocation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChangedLastLocation.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.courierId != null && message.courierId.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.courierId.length; ++i)
                        writer.uint64(message.courierId[i]);
                    writer.ldelim();
                }
                return writer;
            };

            /**
             * Decodes a ChangedLastLocation message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ChangedLastLocation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ChangedLastLocation} ChangedLastLocation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChangedLastLocation.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ChangedLastLocation();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.courierId && message.courierId.length))
                            message.courierId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.courierId.push(reader.uint64());
                        } else
                            message.courierId.push(reader.uint64());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ChangedLastLocation message.
             * @function verify
             * @memberof fastcity.admin.ChangedLastLocation
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChangedLastLocation.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.courierId != null && message.hasOwnProperty("courierId")) {
                    if (!Array.isArray(message.courierId))
                        return "courierId: array expected";
                    for (var i = 0; i < message.courierId.length; ++i)
                        if (!$util.isInteger(message.courierId[i]) && !(message.courierId[i] && $util.isInteger(message.courierId[i].low) && $util.isInteger(message.courierId[i].high)))
                            return "courierId: integer|Long[] expected";
                }
                return null;
            };

            /**
             * Creates a ChangedLastLocation message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ChangedLastLocation
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ChangedLastLocation} ChangedLastLocation
             */
            ChangedLastLocation.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ChangedLastLocation)
                    return object;
                var message = new $root.fastcity.admin.ChangedLastLocation();
                if (object.courierId) {
                    if (!Array.isArray(object.courierId))
                        throw TypeError(".fastcity.admin.ChangedLastLocation.courierId: array expected");
                    message.courierId = [];
                    for (var i = 0; i < object.courierId.length; ++i)
                        if ($util.Long)
                            (message.courierId[i] = $util.Long.fromValue(object.courierId[i])).unsigned = true;
                        else if (typeof object.courierId[i] === "string")
                            message.courierId[i] = parseInt(object.courierId[i], 10);
                        else if (typeof object.courierId[i] === "number")
                            message.courierId[i] = object.courierId[i];
                        else if (typeof object.courierId[i] === "object")
                            message.courierId[i] = new $util.LongBits(object.courierId[i].low >>> 0, object.courierId[i].high >>> 0).toNumber(true);
                }
                return message;
            };

            /**
             * Creates a plain object from a ChangedLastLocation message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ChangedLastLocation
             * @static
             * @param {fastcity.admin.ChangedLastLocation} message ChangedLastLocation
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChangedLastLocation.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.courierId = [];
                if (message.courierId && message.courierId.length) {
                    object.courierId = [];
                    for (var j = 0; j < message.courierId.length; ++j)
                        if (typeof message.courierId[j] === "number")
                            object.courierId[j] = options.longs === String ? String(message.courierId[j]) : message.courierId[j];
                        else
                            object.courierId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.courierId[j]) : options.longs === Number ? new $util.LongBits(message.courierId[j].low >>> 0, message.courierId[j].high >>> 0).toNumber(true) : message.courierId[j];
                }
                return object;
            };

            /**
             * Converts this ChangedLastLocation to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ChangedLastLocation
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChangedLastLocation.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ChangedLastLocation;
        })();

        admin.Logon = (function() {

            /**
             * Properties of a Logon.
             * @memberof fastcity.admin
             * @interface ILogon
             * @property {string|null} [token] Logon token
             */

            /**
             * Constructs a new Logon.
             * @memberof fastcity.admin
             * @classdesc Represents a Logon.
             * @implements ILogon
             * @constructor
             * @param {fastcity.admin.ILogon=} [properties] Properties to set
             */
            function Logon(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Logon token.
             * @member {string} token
             * @memberof fastcity.admin.Logon
             * @instance
             */
            Logon.prototype.token = "";

            /**
             * Creates a new Logon instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.Logon
             * @static
             * @param {fastcity.admin.ILogon=} [properties] Properties to set
             * @returns {fastcity.admin.Logon} Logon instance
             */
            Logon.create = function create(properties) {
                return new Logon(properties);
            };

            /**
             * Encodes the specified Logon message. Does not implicitly {@link fastcity.admin.Logon.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.Logon
             * @static
             * @param {fastcity.admin.ILogon} message Logon message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Logon.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                return writer;
            };

            /**
             * Decodes a Logon message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.Logon
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.Logon} Logon
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Logon.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.Logon();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.token = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Logon message.
             * @function verify
             * @memberof fastcity.admin.Logon
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Logon.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.token != null && message.hasOwnProperty("token"))
                    if (!$util.isString(message.token))
                        return "token: string expected";
                return null;
            };

            /**
             * Creates a Logon message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.Logon
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.Logon} Logon
             */
            Logon.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.Logon)
                    return object;
                var message = new $root.fastcity.admin.Logon();
                if (object.token != null)
                    message.token = String(object.token);
                return message;
            };

            /**
             * Creates a plain object from a Logon message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.Logon
             * @static
             * @param {fastcity.admin.Logon} message Logon
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Logon.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.token = "";
                if (message.token != null && message.hasOwnProperty("token"))
                    object.token = message.token;
                return object;
            };

            /**
             * Converts this Logon to JSON.
             * @function toJSON
             * @memberof fastcity.admin.Logon
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Logon.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Logon;
        })();

        admin.LogonResult = (function() {

            /**
             * Properties of a LogonResult.
             * @memberof fastcity.admin
             * @interface ILogonResult
             * @property {fastcity.admin.LogonResult.Code|null} [code] LogonResult code
             * @property {string|null} [description] LogonResult description
             */

            /**
             * Constructs a new LogonResult.
             * @memberof fastcity.admin
             * @classdesc Represents a LogonResult.
             * @implements ILogonResult
             * @constructor
             * @param {fastcity.admin.ILogonResult=} [properties] Properties to set
             */
            function LogonResult(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LogonResult code.
             * @member {fastcity.admin.LogonResult.Code} code
             * @memberof fastcity.admin.LogonResult
             * @instance
             */
            LogonResult.prototype.code = 0;

            /**
             * LogonResult description.
             * @member {string} description
             * @memberof fastcity.admin.LogonResult
             * @instance
             */
            LogonResult.prototype.description = "";

            /**
             * Creates a new LogonResult instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.LogonResult
             * @static
             * @param {fastcity.admin.ILogonResult=} [properties] Properties to set
             * @returns {fastcity.admin.LogonResult} LogonResult instance
             */
            LogonResult.create = function create(properties) {
                return new LogonResult(properties);
            };

            /**
             * Encodes the specified LogonResult message. Does not implicitly {@link fastcity.admin.LogonResult.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.LogonResult
             * @static
             * @param {fastcity.admin.ILogonResult} message LogonResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LogonResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
                return writer;
            };

            /**
             * Decodes a LogonResult message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.LogonResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.LogonResult} LogonResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LogonResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.LogonResult();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.code = reader.int32();
                        break;
                    case 2:
                        message.description = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a LogonResult message.
             * @function verify
             * @memberof fastcity.admin.LogonResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LogonResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    switch (message.code) {
                    default:
                        return "code: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                return null;
            };

            /**
             * Creates a LogonResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.LogonResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.LogonResult} LogonResult
             */
            LogonResult.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.LogonResult)
                    return object;
                var message = new $root.fastcity.admin.LogonResult();
                switch (object.code) {
                case "SUCCESS":
                case 0:
                    message.code = 0;
                    break;
                case "INCORRECT_LOGIN_PASSWORD":
                case 1:
                    message.code = 1;
                    break;
                case "INACTIVE":
                case 2:
                    message.code = 2;
                    break;
                case "INCORRECT_REQUEST":
                case 3:
                    message.code = 3;
                    break;
                }
                if (object.description != null)
                    message.description = String(object.description);
                return message;
            };

            /**
             * Creates a plain object from a LogonResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.LogonResult
             * @static
             * @param {fastcity.admin.LogonResult} message LogonResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LogonResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.code = options.enums === String ? "SUCCESS" : 0;
                    object.description = "";
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = options.enums === String ? $root.fastcity.admin.LogonResult.Code[message.code] : message.code;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                return object;
            };

            /**
             * Converts this LogonResult to JSON.
             * @function toJSON
             * @memberof fastcity.admin.LogonResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LogonResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Code enum.
             * @name fastcity.admin.LogonResult.Code
             * @enum {number}
             * @property {number} SUCCESS=0 SUCCESS value
             * @property {number} INCORRECT_LOGIN_PASSWORD=1 INCORRECT_LOGIN_PASSWORD value
             * @property {number} INACTIVE=2 INACTIVE value
             * @property {number} INCORRECT_REQUEST=3 INCORRECT_REQUEST value
             */
            LogonResult.Code = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SUCCESS"] = 0;
                values[valuesById[1] = "INCORRECT_LOGIN_PASSWORD"] = 1;
                values[valuesById[2] = "INACTIVE"] = 2;
                values[valuesById[3] = "INCORRECT_REQUEST"] = 3;
                return values;
            })();

            return LogonResult;
        })();

        admin.OrderItem = (function() {

            /**
             * Properties of an OrderItem.
             * @memberof fastcity.admin
             * @interface IOrderItem
             * @property {number|Long|null} [orderItemId] OrderItem orderItemId
             * @property {string|null} [externalOrderItemId] OrderItem externalOrderItemId
             * @property {string|null} [externalItemCode] OrderItem externalItemCode
             * @property {string|null} [name] OrderItem name
             * @property {string|null} [description] OrderItem description
             * @property {string|null} [finalPrice] OrderItem finalPrice
             * @property {string|null} [priceForOne] OrderItem priceForOne
             * @property {number|null} [quantity] OrderItem quantity
             * @property {fastcity.common.OrderItem.ProductType|null} [productType] OrderItem productType
             */

            /**
             * Constructs a new OrderItem.
             * @memberof fastcity.admin
             * @classdesc Represents an OrderItem.
             * @implements IOrderItem
             * @constructor
             * @param {fastcity.admin.IOrderItem=} [properties] Properties to set
             */
            function OrderItem(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderItem orderItemId.
             * @member {number|Long} orderItemId
             * @memberof fastcity.admin.OrderItem
             * @instance
             */
            OrderItem.prototype.orderItemId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * OrderItem externalOrderItemId.
             * @member {string} externalOrderItemId
             * @memberof fastcity.admin.OrderItem
             * @instance
             */
            OrderItem.prototype.externalOrderItemId = "";

            /**
             * OrderItem externalItemCode.
             * @member {string} externalItemCode
             * @memberof fastcity.admin.OrderItem
             * @instance
             */
            OrderItem.prototype.externalItemCode = "";

            /**
             * OrderItem name.
             * @member {string} name
             * @memberof fastcity.admin.OrderItem
             * @instance
             */
            OrderItem.prototype.name = "";

            /**
             * OrderItem description.
             * @member {string} description
             * @memberof fastcity.admin.OrderItem
             * @instance
             */
            OrderItem.prototype.description = "";

            /**
             * OrderItem finalPrice.
             * @member {string} finalPrice
             * @memberof fastcity.admin.OrderItem
             * @instance
             */
            OrderItem.prototype.finalPrice = "";

            /**
             * OrderItem priceForOne.
             * @member {string} priceForOne
             * @memberof fastcity.admin.OrderItem
             * @instance
             */
            OrderItem.prototype.priceForOne = "";

            /**
             * OrderItem quantity.
             * @member {number} quantity
             * @memberof fastcity.admin.OrderItem
             * @instance
             */
            OrderItem.prototype.quantity = 0;

            /**
             * OrderItem productType.
             * @member {fastcity.common.OrderItem.ProductType} productType
             * @memberof fastcity.admin.OrderItem
             * @instance
             */
            OrderItem.prototype.productType = 0;

            /**
             * Creates a new OrderItem instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.OrderItem
             * @static
             * @param {fastcity.admin.IOrderItem=} [properties] Properties to set
             * @returns {fastcity.admin.OrderItem} OrderItem instance
             */
            OrderItem.create = function create(properties) {
                return new OrderItem(properties);
            };

            /**
             * Encodes the specified OrderItem message. Does not implicitly {@link fastcity.admin.OrderItem.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.OrderItem
             * @static
             * @param {fastcity.admin.IOrderItem} message OrderItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderItemId != null && Object.hasOwnProperty.call(message, "orderItemId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.orderItemId);
                if (message.externalOrderItemId != null && Object.hasOwnProperty.call(message, "externalOrderItemId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalOrderItemId);
                if (message.externalItemCode != null && Object.hasOwnProperty.call(message, "externalItemCode"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.externalItemCode);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.description);
                if (message.finalPrice != null && Object.hasOwnProperty.call(message, "finalPrice"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.finalPrice);
                if (message.priceForOne != null && Object.hasOwnProperty.call(message, "priceForOne"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.priceForOne);
                if (message.quantity != null && Object.hasOwnProperty.call(message, "quantity"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.quantity);
                if (message.productType != null && Object.hasOwnProperty.call(message, "productType"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.productType);
                return writer;
            };

            /**
             * Decodes an OrderItem message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.OrderItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.OrderItem} OrderItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.OrderItem();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.orderItemId = reader.uint64();
                        break;
                    case 2:
                        message.externalOrderItemId = reader.string();
                        break;
                    case 3:
                        message.externalItemCode = reader.string();
                        break;
                    case 4:
                        message.name = reader.string();
                        break;
                    case 5:
                        message.description = reader.string();
                        break;
                    case 6:
                        message.finalPrice = reader.string();
                        break;
                    case 7:
                        message.priceForOne = reader.string();
                        break;
                    case 8:
                        message.quantity = reader.int32();
                        break;
                    case 9:
                        message.productType = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderItem message.
             * @function verify
             * @memberof fastcity.admin.OrderItem
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderItemId != null && message.hasOwnProperty("orderItemId"))
                    if (!$util.isInteger(message.orderItemId) && !(message.orderItemId && $util.isInteger(message.orderItemId.low) && $util.isInteger(message.orderItemId.high)))
                        return "orderItemId: integer|Long expected";
                if (message.externalOrderItemId != null && message.hasOwnProperty("externalOrderItemId"))
                    if (!$util.isString(message.externalOrderItemId))
                        return "externalOrderItemId: string expected";
                if (message.externalItemCode != null && message.hasOwnProperty("externalItemCode"))
                    if (!$util.isString(message.externalItemCode))
                        return "externalItemCode: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.finalPrice != null && message.hasOwnProperty("finalPrice"))
                    if (!$util.isString(message.finalPrice))
                        return "finalPrice: string expected";
                if (message.priceForOne != null && message.hasOwnProperty("priceForOne"))
                    if (!$util.isString(message.priceForOne))
                        return "priceForOne: string expected";
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    if (!$util.isInteger(message.quantity))
                        return "quantity: integer expected";
                if (message.productType != null && message.hasOwnProperty("productType"))
                    switch (message.productType) {
                    default:
                        return "productType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                        break;
                    }
                return null;
            };

            /**
             * Creates an OrderItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.OrderItem
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.OrderItem} OrderItem
             */
            OrderItem.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.OrderItem)
                    return object;
                var message = new $root.fastcity.admin.OrderItem();
                if (object.orderItemId != null)
                    if ($util.Long)
                        (message.orderItemId = $util.Long.fromValue(object.orderItemId)).unsigned = true;
                    else if (typeof object.orderItemId === "string")
                        message.orderItemId = parseInt(object.orderItemId, 10);
                    else if (typeof object.orderItemId === "number")
                        message.orderItemId = object.orderItemId;
                    else if (typeof object.orderItemId === "object")
                        message.orderItemId = new $util.LongBits(object.orderItemId.low >>> 0, object.orderItemId.high >>> 0).toNumber(true);
                if (object.externalOrderItemId != null)
                    message.externalOrderItemId = String(object.externalOrderItemId);
                if (object.externalItemCode != null)
                    message.externalItemCode = String(object.externalItemCode);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.description != null)
                    message.description = String(object.description);
                if (object.finalPrice != null)
                    message.finalPrice = String(object.finalPrice);
                if (object.priceForOne != null)
                    message.priceForOne = String(object.priceForOne);
                if (object.quantity != null)
                    message.quantity = object.quantity | 0;
                switch (object.productType) {
                case "T_UNKNOWN":
                case 0:
                    message.productType = 0;
                    break;
                case "T_DESSERT":
                case 1:
                    message.productType = 1;
                    break;
                case "T_SNACK":
                case 2:
                    message.productType = 2;
                    break;
                case "T_DRINK":
                case 3:
                    message.productType = 3;
                    break;
                case "T_BEER":
                case 4:
                    message.productType = 4;
                    break;
                case "T_PIZZA":
                case 5:
                    message.productType = 5;
                    break;
                case "T_GIFT":
                case 6:
                    message.productType = 6;
                    break;
                case "T_SALAD":
                case 7:
                    message.productType = 7;
                    break;
                case "T_BREAD":
                case 8:
                    message.productType = 8;
                    break;
                case "T_SAUCE":
                case 9:
                    message.productType = 9;
                    break;
                case "T_COMPOUND":
                case 10:
                    message.productType = 10;
                    break;
                case "T_SERVICE":
                case 11:
                    message.productType = 11;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from an OrderItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.OrderItem
             * @static
             * @param {fastcity.admin.OrderItem} message OrderItem
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderItem.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.orderItemId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderItemId = options.longs === String ? "0" : 0;
                    object.externalOrderItemId = "";
                    object.externalItemCode = "";
                    object.name = "";
                    object.description = "";
                    object.finalPrice = "";
                    object.priceForOne = "";
                    object.quantity = 0;
                    object.productType = options.enums === String ? "T_UNKNOWN" : 0;
                }
                if (message.orderItemId != null && message.hasOwnProperty("orderItemId"))
                    if (typeof message.orderItemId === "number")
                        object.orderItemId = options.longs === String ? String(message.orderItemId) : message.orderItemId;
                    else
                        object.orderItemId = options.longs === String ? $util.Long.prototype.toString.call(message.orderItemId) : options.longs === Number ? new $util.LongBits(message.orderItemId.low >>> 0, message.orderItemId.high >>> 0).toNumber(true) : message.orderItemId;
                if (message.externalOrderItemId != null && message.hasOwnProperty("externalOrderItemId"))
                    object.externalOrderItemId = message.externalOrderItemId;
                if (message.externalItemCode != null && message.hasOwnProperty("externalItemCode"))
                    object.externalItemCode = message.externalItemCode;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.finalPrice != null && message.hasOwnProperty("finalPrice"))
                    object.finalPrice = message.finalPrice;
                if (message.priceForOne != null && message.hasOwnProperty("priceForOne"))
                    object.priceForOne = message.priceForOne;
                if (message.quantity != null && message.hasOwnProperty("quantity"))
                    object.quantity = message.quantity;
                if (message.productType != null && message.hasOwnProperty("productType"))
                    object.productType = options.enums === String ? $root.fastcity.common.OrderItem.ProductType[message.productType] : message.productType;
                return object;
            };

            /**
             * Converts this OrderItem to JSON.
             * @function toJSON
             * @memberof fastcity.admin.OrderItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OrderItem;
        })();

        admin.Order = (function() {

            /**
             * Properties of an Order.
             * @memberof fastcity.admin
             * @interface IOrder
             * @property {number|Long|null} [orderId] Order orderId
             * @property {string|null} [orderIdToPrint] Order orderIdToPrint
             * @property {string|null} [externalOrderId] Order externalOrderId
             * @property {number|Long|null} [companyId] Order companyId
             * @property {string|null} [externalSubCompanyId] Order externalSubCompanyId
             * @property {number|Long|null} [courierId] Order courierId
             * @property {number|Long|null} [timestampOpen] Order timestampOpen
             * @property {number|Long|null} [timestampClose] Order timestampClose
             * @property {fastcity.common.Order.Status|null} [status] Order status
             * @property {string|null} [finalPrice] Order finalPrice
             * @property {fastcity.common.Order.PaymentType|null} [paymentType] Order paymentType
             * @property {string|null} [clientPhone] Order clientPhone
             * @property {string|null} [clientName] Order clientName
             * @property {string|null} [clientAddress] Order clientAddress
             * @property {string|null} [comments] Order comments
             * @property {Array.<fastcity.admin.IOrderItem>|null} [orderItem] Order orderItem
             * @property {fastcity.common.ICoordinate|null} [coordinate] Order coordinate
             * @property {string|null} [currencySymbol] Order currencySymbol
             * @property {number|Long|null} [timestampCancel] Order timestampCancel
             * @property {string|null} [clientRoom] Order clientRoom
             * @property {number|Long|null} [timestampAccept] Order timestampAccept
             * @property {number|Long|null} [timestampUpdated] Order timestampUpdated
             * @property {number|Long|null} [timestampDeliveryBefore] Order timestampDeliveryBefore
             */

            /**
             * Constructs a new Order.
             * @memberof fastcity.admin
             * @classdesc Represents an Order.
             * @implements IOrder
             * @constructor
             * @param {fastcity.admin.IOrder=} [properties] Properties to set
             */
            function Order(properties) {
                this.orderItem = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Order orderId.
             * @member {number|Long} orderId
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Order orderIdToPrint.
             * @member {string} orderIdToPrint
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.orderIdToPrint = "";

            /**
             * Order externalOrderId.
             * @member {string} externalOrderId
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.externalOrderId = "";

            /**
             * Order companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Order externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.externalSubCompanyId = "";

            /**
             * Order courierId.
             * @member {number|Long} courierId
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.courierId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Order timestampOpen.
             * @member {number|Long} timestampOpen
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.timestampOpen = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order timestampClose.
             * @member {number|Long} timestampClose
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.timestampClose = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order status.
             * @member {fastcity.common.Order.Status} status
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.status = 0;

            /**
             * Order finalPrice.
             * @member {string} finalPrice
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.finalPrice = "";

            /**
             * Order paymentType.
             * @member {fastcity.common.Order.PaymentType} paymentType
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.paymentType = 0;

            /**
             * Order clientPhone.
             * @member {string} clientPhone
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.clientPhone = "";

            /**
             * Order clientName.
             * @member {string} clientName
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.clientName = "";

            /**
             * Order clientAddress.
             * @member {string} clientAddress
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.clientAddress = "";

            /**
             * Order comments.
             * @member {string} comments
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.comments = "";

            /**
             * Order orderItem.
             * @member {Array.<fastcity.admin.IOrderItem>} orderItem
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.orderItem = $util.emptyArray;

            /**
             * Order coordinate.
             * @member {fastcity.common.ICoordinate|null|undefined} coordinate
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.coordinate = null;

            /**
             * Order currencySymbol.
             * @member {string} currencySymbol
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.currencySymbol = "";

            /**
             * Order timestampCancel.
             * @member {number|Long} timestampCancel
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.timestampCancel = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order clientRoom.
             * @member {string} clientRoom
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.clientRoom = "";

            /**
             * Order timestampAccept.
             * @member {number|Long} timestampAccept
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.timestampAccept = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order timestampUpdated.
             * @member {number|Long} timestampUpdated
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.timestampUpdated = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order timestampDeliveryBefore.
             * @member {number|Long} timestampDeliveryBefore
             * @memberof fastcity.admin.Order
             * @instance
             */
            Order.prototype.timestampDeliveryBefore = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new Order instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.Order
             * @static
             * @param {fastcity.admin.IOrder=} [properties] Properties to set
             * @returns {fastcity.admin.Order} Order instance
             */
            Order.create = function create(properties) {
                return new Order(properties);
            };

            /**
             * Encodes the specified Order message. Does not implicitly {@link fastcity.admin.Order.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.Order
             * @static
             * @param {fastcity.admin.IOrder} message Order message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Order.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.orderId);
                if (message.externalOrderId != null && Object.hasOwnProperty.call(message, "externalOrderId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalOrderId);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.externalSubCompanyId);
                if (message.courierId != null && Object.hasOwnProperty.call(message, "courierId"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.courierId);
                if (message.timestampOpen != null && Object.hasOwnProperty.call(message, "timestampOpen"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.timestampOpen);
                if (message.timestampClose != null && Object.hasOwnProperty.call(message, "timestampClose"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.timestampClose);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.status);
                if (message.finalPrice != null && Object.hasOwnProperty.call(message, "finalPrice"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.finalPrice);
                if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int32(message.paymentType);
                if (message.clientPhone != null && Object.hasOwnProperty.call(message, "clientPhone"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.clientPhone);
                if (message.clientName != null && Object.hasOwnProperty.call(message, "clientName"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.clientName);
                if (message.clientAddress != null && Object.hasOwnProperty.call(message, "clientAddress"))
                    writer.uint32(/* id 13, wireType 2 =*/106).string(message.clientAddress);
                if (message.comments != null && Object.hasOwnProperty.call(message, "comments"))
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.comments);
                if (message.orderItem != null && message.orderItem.length)
                    for (var i = 0; i < message.orderItem.length; ++i)
                        $root.fastcity.admin.OrderItem.encode(message.orderItem[i], writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.coordinate != null && Object.hasOwnProperty.call(message, "coordinate"))
                    $root.fastcity.common.Coordinate.encode(message.coordinate, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.currencySymbol != null && Object.hasOwnProperty.call(message, "currencySymbol"))
                    writer.uint32(/* id 17, wireType 2 =*/138).string(message.currencySymbol);
                if (message.timestampCancel != null && Object.hasOwnProperty.call(message, "timestampCancel"))
                    writer.uint32(/* id 18, wireType 0 =*/144).int64(message.timestampCancel);
                if (message.clientRoom != null && Object.hasOwnProperty.call(message, "clientRoom"))
                    writer.uint32(/* id 19, wireType 2 =*/154).string(message.clientRoom);
                if (message.timestampAccept != null && Object.hasOwnProperty.call(message, "timestampAccept"))
                    writer.uint32(/* id 20, wireType 0 =*/160).int64(message.timestampAccept);
                if (message.timestampUpdated != null && Object.hasOwnProperty.call(message, "timestampUpdated"))
                    writer.uint32(/* id 21, wireType 0 =*/168).int64(message.timestampUpdated);
                if (message.orderIdToPrint != null && Object.hasOwnProperty.call(message, "orderIdToPrint"))
                    writer.uint32(/* id 22, wireType 2 =*/178).string(message.orderIdToPrint);
                if (message.timestampDeliveryBefore != null && Object.hasOwnProperty.call(message, "timestampDeliveryBefore"))
                    writer.uint32(/* id 23, wireType 0 =*/184).int64(message.timestampDeliveryBefore);
                return writer;
            };

            /**
             * Decodes an Order message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.Order
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.Order} Order
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Order.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.Order();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.orderId = reader.uint64();
                        break;
                    case 22:
                        message.orderIdToPrint = reader.string();
                        break;
                    case 2:
                        message.externalOrderId = reader.string();
                        break;
                    case 3:
                        message.companyId = reader.uint64();
                        break;
                    case 4:
                        message.externalSubCompanyId = reader.string();
                        break;
                    case 5:
                        message.courierId = reader.uint64();
                        break;
                    case 6:
                        message.timestampOpen = reader.int64();
                        break;
                    case 7:
                        message.timestampClose = reader.int64();
                        break;
                    case 8:
                        message.status = reader.int32();
                        break;
                    case 9:
                        message.finalPrice = reader.string();
                        break;
                    case 10:
                        message.paymentType = reader.int32();
                        break;
                    case 11:
                        message.clientPhone = reader.string();
                        break;
                    case 12:
                        message.clientName = reader.string();
                        break;
                    case 13:
                        message.clientAddress = reader.string();
                        break;
                    case 14:
                        message.comments = reader.string();
                        break;
                    case 15:
                        if (!(message.orderItem && message.orderItem.length))
                            message.orderItem = [];
                        message.orderItem.push($root.fastcity.admin.OrderItem.decode(reader, reader.uint32()));
                        break;
                    case 16:
                        message.coordinate = $root.fastcity.common.Coordinate.decode(reader, reader.uint32());
                        break;
                    case 17:
                        message.currencySymbol = reader.string();
                        break;
                    case 18:
                        message.timestampCancel = reader.int64();
                        break;
                    case 19:
                        message.clientRoom = reader.string();
                        break;
                    case 20:
                        message.timestampAccept = reader.int64();
                        break;
                    case 21:
                        message.timestampUpdated = reader.int64();
                        break;
                    case 23:
                        message.timestampDeliveryBefore = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an Order message.
             * @function verify
             * @memberof fastcity.admin.Order
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Order.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                if (message.orderIdToPrint != null && message.hasOwnProperty("orderIdToPrint"))
                    if (!$util.isString(message.orderIdToPrint))
                        return "orderIdToPrint: string expected";
                if (message.externalOrderId != null && message.hasOwnProperty("externalOrderId"))
                    if (!$util.isString(message.externalOrderId))
                        return "externalOrderId: string expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (!$util.isInteger(message.courierId) && !(message.courierId && $util.isInteger(message.courierId.low) && $util.isInteger(message.courierId.high)))
                        return "courierId: integer|Long expected";
                if (message.timestampOpen != null && message.hasOwnProperty("timestampOpen"))
                    if (!$util.isInteger(message.timestampOpen) && !(message.timestampOpen && $util.isInteger(message.timestampOpen.low) && $util.isInteger(message.timestampOpen.high)))
                        return "timestampOpen: integer|Long expected";
                if (message.timestampClose != null && message.hasOwnProperty("timestampClose"))
                    if (!$util.isInteger(message.timestampClose) && !(message.timestampClose && $util.isInteger(message.timestampClose.low) && $util.isInteger(message.timestampClose.high)))
                        return "timestampClose: integer|Long expected";
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                        break;
                    }
                if (message.finalPrice != null && message.hasOwnProperty("finalPrice"))
                    if (!$util.isString(message.finalPrice))
                        return "finalPrice: string expected";
                if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                    switch (message.paymentType) {
                    default:
                        return "paymentType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break;
                    }
                if (message.clientPhone != null && message.hasOwnProperty("clientPhone"))
                    if (!$util.isString(message.clientPhone))
                        return "clientPhone: string expected";
                if (message.clientName != null && message.hasOwnProperty("clientName"))
                    if (!$util.isString(message.clientName))
                        return "clientName: string expected";
                if (message.clientAddress != null && message.hasOwnProperty("clientAddress"))
                    if (!$util.isString(message.clientAddress))
                        return "clientAddress: string expected";
                if (message.comments != null && message.hasOwnProperty("comments"))
                    if (!$util.isString(message.comments))
                        return "comments: string expected";
                if (message.orderItem != null && message.hasOwnProperty("orderItem")) {
                    if (!Array.isArray(message.orderItem))
                        return "orderItem: array expected";
                    for (var i = 0; i < message.orderItem.length; ++i) {
                        var error = $root.fastcity.admin.OrderItem.verify(message.orderItem[i]);
                        if (error)
                            return "orderItem." + error;
                    }
                }
                if (message.coordinate != null && message.hasOwnProperty("coordinate")) {
                    var error = $root.fastcity.common.Coordinate.verify(message.coordinate);
                    if (error)
                        return "coordinate." + error;
                }
                if (message.currencySymbol != null && message.hasOwnProperty("currencySymbol"))
                    if (!$util.isString(message.currencySymbol))
                        return "currencySymbol: string expected";
                if (message.timestampCancel != null && message.hasOwnProperty("timestampCancel"))
                    if (!$util.isInteger(message.timestampCancel) && !(message.timestampCancel && $util.isInteger(message.timestampCancel.low) && $util.isInteger(message.timestampCancel.high)))
                        return "timestampCancel: integer|Long expected";
                if (message.clientRoom != null && message.hasOwnProperty("clientRoom"))
                    if (!$util.isString(message.clientRoom))
                        return "clientRoom: string expected";
                if (message.timestampAccept != null && message.hasOwnProperty("timestampAccept"))
                    if (!$util.isInteger(message.timestampAccept) && !(message.timestampAccept && $util.isInteger(message.timestampAccept.low) && $util.isInteger(message.timestampAccept.high)))
                        return "timestampAccept: integer|Long expected";
                if (message.timestampUpdated != null && message.hasOwnProperty("timestampUpdated"))
                    if (!$util.isInteger(message.timestampUpdated) && !(message.timestampUpdated && $util.isInteger(message.timestampUpdated.low) && $util.isInteger(message.timestampUpdated.high)))
                        return "timestampUpdated: integer|Long expected";
                if (message.timestampDeliveryBefore != null && message.hasOwnProperty("timestampDeliveryBefore"))
                    if (!$util.isInteger(message.timestampDeliveryBefore) && !(message.timestampDeliveryBefore && $util.isInteger(message.timestampDeliveryBefore.low) && $util.isInteger(message.timestampDeliveryBefore.high)))
                        return "timestampDeliveryBefore: integer|Long expected";
                return null;
            };

            /**
             * Creates an Order message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.Order
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.Order} Order
             */
            Order.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.Order)
                    return object;
                var message = new $root.fastcity.admin.Order();
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = true;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber(true);
                if (object.orderIdToPrint != null)
                    message.orderIdToPrint = String(object.orderIdToPrint);
                if (object.externalOrderId != null)
                    message.externalOrderId = String(object.externalOrderId);
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                if (object.courierId != null)
                    if ($util.Long)
                        (message.courierId = $util.Long.fromValue(object.courierId)).unsigned = true;
                    else if (typeof object.courierId === "string")
                        message.courierId = parseInt(object.courierId, 10);
                    else if (typeof object.courierId === "number")
                        message.courierId = object.courierId;
                    else if (typeof object.courierId === "object")
                        message.courierId = new $util.LongBits(object.courierId.low >>> 0, object.courierId.high >>> 0).toNumber(true);
                if (object.timestampOpen != null)
                    if ($util.Long)
                        (message.timestampOpen = $util.Long.fromValue(object.timestampOpen)).unsigned = false;
                    else if (typeof object.timestampOpen === "string")
                        message.timestampOpen = parseInt(object.timestampOpen, 10);
                    else if (typeof object.timestampOpen === "number")
                        message.timestampOpen = object.timestampOpen;
                    else if (typeof object.timestampOpen === "object")
                        message.timestampOpen = new $util.LongBits(object.timestampOpen.low >>> 0, object.timestampOpen.high >>> 0).toNumber();
                if (object.timestampClose != null)
                    if ($util.Long)
                        (message.timestampClose = $util.Long.fromValue(object.timestampClose)).unsigned = false;
                    else if (typeof object.timestampClose === "string")
                        message.timestampClose = parseInt(object.timestampClose, 10);
                    else if (typeof object.timestampClose === "number")
                        message.timestampClose = object.timestampClose;
                    else if (typeof object.timestampClose === "object")
                        message.timestampClose = new $util.LongBits(object.timestampClose.low >>> 0, object.timestampClose.high >>> 0).toNumber();
                switch (object.status) {
                case "S_UNKNOWN":
                case 0:
                    message.status = 0;
                    break;
                case "S_IN_PROGRESS":
                case 1:
                    message.status = 1;
                    break;
                case "S_MAKELINE":
                case 2:
                    message.status = 2;
                    break;
                case "S_ROUTING_STATION":
                case 3:
                    message.status = 3;
                    break;
                case "S_OUT_THE_DOOR":
                case 4:
                    message.status = 4;
                    break;
                case "S_COMPLETE":
                case 5:
                    message.status = 5;
                    break;
                case "S_GIFT":
                case 6:
                    message.status = 6;
                    break;
                case "S_BAD":
                case 7:
                    message.status = 7;
                    break;
                case "S_VOID":
                case 8:
                    message.status = 8;
                    break;
                case "S_BEING_TAKEN":
                case 9:
                    message.status = 9;
                    break;
                case "S_ABANDONED":
                case 10:
                    message.status = 10;
                    break;
                case "S_SUSPENDED":
                case 11:
                    message.status = 11;
                    break;
                case "S_GIFT_CARD_PURCHASE":
                case 12:
                    message.status = 12;
                    break;
                case "S_GIFT_CARD_VOID":
                case 13:
                    message.status = 13;
                    break;
                case "S_TRANSFERRED":
                case 14:
                    message.status = 14;
                    break;
                case "S_HISTORIC_ORDER":
                case 15:
                    message.status = 15;
                    break;
                case "S_BEING_ASSIGNED":
                case 16:
                    message.status = 16;
                    break;
                }
                if (object.finalPrice != null)
                    message.finalPrice = String(object.finalPrice);
                switch (object.paymentType) {
                case "T_UNKNOWN":
                case 0:
                    message.paymentType = 0;
                    break;
                case "T_PAID":
                case 1:
                    message.paymentType = 1;
                    break;
                case "T_BY_CASH_TO_THE_COURIER":
                case 2:
                    message.paymentType = 2;
                    break;
                case "T_BY_CARD_TO_THE_COURIER":
                case 3:
                    message.paymentType = 3;
                    break;
                case "T_PAID_BY_CASH":
                case 4:
                    message.paymentType = 4;
                    break;
                case "T_PAID_BY_CARD":
                case 5:
                    message.paymentType = 5;
                    break;
                case "T_PAID_BY_CHECK":
                case 6:
                    message.paymentType = 6;
                    break;
                }
                if (object.clientPhone != null)
                    message.clientPhone = String(object.clientPhone);
                if (object.clientName != null)
                    message.clientName = String(object.clientName);
                if (object.clientAddress != null)
                    message.clientAddress = String(object.clientAddress);
                if (object.comments != null)
                    message.comments = String(object.comments);
                if (object.orderItem) {
                    if (!Array.isArray(object.orderItem))
                        throw TypeError(".fastcity.admin.Order.orderItem: array expected");
                    message.orderItem = [];
                    for (var i = 0; i < object.orderItem.length; ++i) {
                        if (typeof object.orderItem[i] !== "object")
                            throw TypeError(".fastcity.admin.Order.orderItem: object expected");
                        message.orderItem[i] = $root.fastcity.admin.OrderItem.fromObject(object.orderItem[i]);
                    }
                }
                if (object.coordinate != null) {
                    if (typeof object.coordinate !== "object")
                        throw TypeError(".fastcity.admin.Order.coordinate: object expected");
                    message.coordinate = $root.fastcity.common.Coordinate.fromObject(object.coordinate);
                }
                if (object.currencySymbol != null)
                    message.currencySymbol = String(object.currencySymbol);
                if (object.timestampCancel != null)
                    if ($util.Long)
                        (message.timestampCancel = $util.Long.fromValue(object.timestampCancel)).unsigned = false;
                    else if (typeof object.timestampCancel === "string")
                        message.timestampCancel = parseInt(object.timestampCancel, 10);
                    else if (typeof object.timestampCancel === "number")
                        message.timestampCancel = object.timestampCancel;
                    else if (typeof object.timestampCancel === "object")
                        message.timestampCancel = new $util.LongBits(object.timestampCancel.low >>> 0, object.timestampCancel.high >>> 0).toNumber();
                if (object.clientRoom != null)
                    message.clientRoom = String(object.clientRoom);
                if (object.timestampAccept != null)
                    if ($util.Long)
                        (message.timestampAccept = $util.Long.fromValue(object.timestampAccept)).unsigned = false;
                    else if (typeof object.timestampAccept === "string")
                        message.timestampAccept = parseInt(object.timestampAccept, 10);
                    else if (typeof object.timestampAccept === "number")
                        message.timestampAccept = object.timestampAccept;
                    else if (typeof object.timestampAccept === "object")
                        message.timestampAccept = new $util.LongBits(object.timestampAccept.low >>> 0, object.timestampAccept.high >>> 0).toNumber();
                if (object.timestampUpdated != null)
                    if ($util.Long)
                        (message.timestampUpdated = $util.Long.fromValue(object.timestampUpdated)).unsigned = false;
                    else if (typeof object.timestampUpdated === "string")
                        message.timestampUpdated = parseInt(object.timestampUpdated, 10);
                    else if (typeof object.timestampUpdated === "number")
                        message.timestampUpdated = object.timestampUpdated;
                    else if (typeof object.timestampUpdated === "object")
                        message.timestampUpdated = new $util.LongBits(object.timestampUpdated.low >>> 0, object.timestampUpdated.high >>> 0).toNumber();
                if (object.timestampDeliveryBefore != null)
                    if ($util.Long)
                        (message.timestampDeliveryBefore = $util.Long.fromValue(object.timestampDeliveryBefore)).unsigned = false;
                    else if (typeof object.timestampDeliveryBefore === "string")
                        message.timestampDeliveryBefore = parseInt(object.timestampDeliveryBefore, 10);
                    else if (typeof object.timestampDeliveryBefore === "number")
                        message.timestampDeliveryBefore = object.timestampDeliveryBefore;
                    else if (typeof object.timestampDeliveryBefore === "object")
                        message.timestampDeliveryBefore = new $util.LongBits(object.timestampDeliveryBefore.low >>> 0, object.timestampDeliveryBefore.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from an Order message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.Order
             * @static
             * @param {fastcity.admin.Order} message Order
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Order.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.orderItem = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                    object.externalOrderId = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.courierId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.courierId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampOpen = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampOpen = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampClose = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampClose = options.longs === String ? "0" : 0;
                    object.status = options.enums === String ? "S_UNKNOWN" : 0;
                    object.finalPrice = "";
                    object.paymentType = options.enums === String ? "T_UNKNOWN" : 0;
                    object.clientPhone = "";
                    object.clientName = "";
                    object.clientAddress = "";
                    object.comments = "";
                    object.coordinate = null;
                    object.currencySymbol = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampCancel = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampCancel = options.longs === String ? "0" : 0;
                    object.clientRoom = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampAccept = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampAccept = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampUpdated = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampUpdated = options.longs === String ? "0" : 0;
                    object.orderIdToPrint = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timestampDeliveryBefore = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timestampDeliveryBefore = options.longs === String ? "0" : 0;
                }
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber(true) : message.orderId;
                if (message.externalOrderId != null && message.hasOwnProperty("externalOrderId"))
                    object.externalOrderId = message.externalOrderId;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                if (message.courierId != null && message.hasOwnProperty("courierId"))
                    if (typeof message.courierId === "number")
                        object.courierId = options.longs === String ? String(message.courierId) : message.courierId;
                    else
                        object.courierId = options.longs === String ? $util.Long.prototype.toString.call(message.courierId) : options.longs === Number ? new $util.LongBits(message.courierId.low >>> 0, message.courierId.high >>> 0).toNumber(true) : message.courierId;
                if (message.timestampOpen != null && message.hasOwnProperty("timestampOpen"))
                    if (typeof message.timestampOpen === "number")
                        object.timestampOpen = options.longs === String ? String(message.timestampOpen) : message.timestampOpen;
                    else
                        object.timestampOpen = options.longs === String ? $util.Long.prototype.toString.call(message.timestampOpen) : options.longs === Number ? new $util.LongBits(message.timestampOpen.low >>> 0, message.timestampOpen.high >>> 0).toNumber() : message.timestampOpen;
                if (message.timestampClose != null && message.hasOwnProperty("timestampClose"))
                    if (typeof message.timestampClose === "number")
                        object.timestampClose = options.longs === String ? String(message.timestampClose) : message.timestampClose;
                    else
                        object.timestampClose = options.longs === String ? $util.Long.prototype.toString.call(message.timestampClose) : options.longs === Number ? new $util.LongBits(message.timestampClose.low >>> 0, message.timestampClose.high >>> 0).toNumber() : message.timestampClose;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.fastcity.common.Order.Status[message.status] : message.status;
                if (message.finalPrice != null && message.hasOwnProperty("finalPrice"))
                    object.finalPrice = message.finalPrice;
                if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                    object.paymentType = options.enums === String ? $root.fastcity.common.Order.PaymentType[message.paymentType] : message.paymentType;
                if (message.clientPhone != null && message.hasOwnProperty("clientPhone"))
                    object.clientPhone = message.clientPhone;
                if (message.clientName != null && message.hasOwnProperty("clientName"))
                    object.clientName = message.clientName;
                if (message.clientAddress != null && message.hasOwnProperty("clientAddress"))
                    object.clientAddress = message.clientAddress;
                if (message.comments != null && message.hasOwnProperty("comments"))
                    object.comments = message.comments;
                if (message.orderItem && message.orderItem.length) {
                    object.orderItem = [];
                    for (var j = 0; j < message.orderItem.length; ++j)
                        object.orderItem[j] = $root.fastcity.admin.OrderItem.toObject(message.orderItem[j], options);
                }
                if (message.coordinate != null && message.hasOwnProperty("coordinate"))
                    object.coordinate = $root.fastcity.common.Coordinate.toObject(message.coordinate, options);
                if (message.currencySymbol != null && message.hasOwnProperty("currencySymbol"))
                    object.currencySymbol = message.currencySymbol;
                if (message.timestampCancel != null && message.hasOwnProperty("timestampCancel"))
                    if (typeof message.timestampCancel === "number")
                        object.timestampCancel = options.longs === String ? String(message.timestampCancel) : message.timestampCancel;
                    else
                        object.timestampCancel = options.longs === String ? $util.Long.prototype.toString.call(message.timestampCancel) : options.longs === Number ? new $util.LongBits(message.timestampCancel.low >>> 0, message.timestampCancel.high >>> 0).toNumber() : message.timestampCancel;
                if (message.clientRoom != null && message.hasOwnProperty("clientRoom"))
                    object.clientRoom = message.clientRoom;
                if (message.timestampAccept != null && message.hasOwnProperty("timestampAccept"))
                    if (typeof message.timestampAccept === "number")
                        object.timestampAccept = options.longs === String ? String(message.timestampAccept) : message.timestampAccept;
                    else
                        object.timestampAccept = options.longs === String ? $util.Long.prototype.toString.call(message.timestampAccept) : options.longs === Number ? new $util.LongBits(message.timestampAccept.low >>> 0, message.timestampAccept.high >>> 0).toNumber() : message.timestampAccept;
                if (message.timestampUpdated != null && message.hasOwnProperty("timestampUpdated"))
                    if (typeof message.timestampUpdated === "number")
                        object.timestampUpdated = options.longs === String ? String(message.timestampUpdated) : message.timestampUpdated;
                    else
                        object.timestampUpdated = options.longs === String ? $util.Long.prototype.toString.call(message.timestampUpdated) : options.longs === Number ? new $util.LongBits(message.timestampUpdated.low >>> 0, message.timestampUpdated.high >>> 0).toNumber() : message.timestampUpdated;
                if (message.orderIdToPrint != null && message.hasOwnProperty("orderIdToPrint"))
                    object.orderIdToPrint = message.orderIdToPrint;
                if (message.timestampDeliveryBefore != null && message.hasOwnProperty("timestampDeliveryBefore"))
                    if (typeof message.timestampDeliveryBefore === "number")
                        object.timestampDeliveryBefore = options.longs === String ? String(message.timestampDeliveryBefore) : message.timestampDeliveryBefore;
                    else
                        object.timestampDeliveryBefore = options.longs === String ? $util.Long.prototype.toString.call(message.timestampDeliveryBefore) : options.longs === Number ? new $util.LongBits(message.timestampDeliveryBefore.low >>> 0, message.timestampDeliveryBefore.high >>> 0).toNumber() : message.timestampDeliveryBefore;
                return object;
            };

            /**
             * Converts this Order to JSON.
             * @function toJSON
             * @memberof fastcity.admin.Order
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Order.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Order;
        })();

        admin.GetOrderList = (function() {

            /**
             * Properties of a GetOrderList.
             * @memberof fastcity.admin
             * @interface IGetOrderList
             * @property {Array.<number|Long>|null} [orderId] GetOrderList orderId
             * @property {number|Long|null} [companyId] GetOrderList companyId
             * @property {Array.<number|Long>|null} [courierId] GetOrderList courierId
             * @property {number|Long|null} [fromTime] GetOrderList fromTime
             * @property {number|Long|null} [toTime] GetOrderList toTime
             * @property {number|null} [offset] GetOrderList offset
             * @property {number|null} [limit] GetOrderList limit
             * @property {fastcity.common.SortOrder.Order|null} [sortOrder] GetOrderList sortOrder
             */

            /**
             * Constructs a new GetOrderList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetOrderList.
             * @implements IGetOrderList
             * @constructor
             * @param {fastcity.admin.IGetOrderList=} [properties] Properties to set
             */
            function GetOrderList(properties) {
                this.orderId = [];
                this.courierId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetOrderList orderId.
             * @member {Array.<number|Long>} orderId
             * @memberof fastcity.admin.GetOrderList
             * @instance
             */
            GetOrderList.prototype.orderId = $util.emptyArray;

            /**
             * GetOrderList companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.GetOrderList
             * @instance
             */
            GetOrderList.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetOrderList courierId.
             * @member {Array.<number|Long>} courierId
             * @memberof fastcity.admin.GetOrderList
             * @instance
             */
            GetOrderList.prototype.courierId = $util.emptyArray;

            /**
             * GetOrderList fromTime.
             * @member {number|Long} fromTime
             * @memberof fastcity.admin.GetOrderList
             * @instance
             */
            GetOrderList.prototype.fromTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * GetOrderList toTime.
             * @member {number|Long} toTime
             * @memberof fastcity.admin.GetOrderList
             * @instance
             */
            GetOrderList.prototype.toTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * GetOrderList offset.
             * @member {number} offset
             * @memberof fastcity.admin.GetOrderList
             * @instance
             */
            GetOrderList.prototype.offset = 0;

            /**
             * GetOrderList limit.
             * @member {number} limit
             * @memberof fastcity.admin.GetOrderList
             * @instance
             */
            GetOrderList.prototype.limit = 0;

            /**
             * GetOrderList sortOrder.
             * @member {fastcity.common.SortOrder.Order} sortOrder
             * @memberof fastcity.admin.GetOrderList
             * @instance
             */
            GetOrderList.prototype.sortOrder = 0;

            /**
             * Creates a new GetOrderList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetOrderList
             * @static
             * @param {fastcity.admin.IGetOrderList=} [properties] Properties to set
             * @returns {fastcity.admin.GetOrderList} GetOrderList instance
             */
            GetOrderList.create = function create(properties) {
                return new GetOrderList(properties);
            };

            /**
             * Encodes the specified GetOrderList message. Does not implicitly {@link fastcity.admin.GetOrderList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetOrderList
             * @static
             * @param {fastcity.admin.IGetOrderList} message GetOrderList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOrderList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderId != null && message.orderId.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.orderId.length; ++i)
                        writer.uint64(message.orderId[i]);
                    writer.ldelim();
                }
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.courierId != null && message.courierId.length) {
                    writer.uint32(/* id 3, wireType 2 =*/26).fork();
                    for (var i = 0; i < message.courierId.length; ++i)
                        writer.uint64(message.courierId[i]);
                    writer.ldelim();
                }
                if (message.fromTime != null && Object.hasOwnProperty.call(message, "fromTime"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.fromTime);
                if (message.toTime != null && Object.hasOwnProperty.call(message, "toTime"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.toTime);
                if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.offset);
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.limit);
                if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.sortOrder);
                return writer;
            };

            /**
             * Decodes a GetOrderList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetOrderList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetOrderList} GetOrderList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOrderList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetOrderList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.orderId && message.orderId.length))
                            message.orderId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.orderId.push(reader.uint64());
                        } else
                            message.orderId.push(reader.uint64());
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        if (!(message.courierId && message.courierId.length))
                            message.courierId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.courierId.push(reader.uint64());
                        } else
                            message.courierId.push(reader.uint64());
                        break;
                    case 4:
                        message.fromTime = reader.int64();
                        break;
                    case 5:
                        message.toTime = reader.int64();
                        break;
                    case 6:
                        message.offset = reader.uint32();
                        break;
                    case 7:
                        message.limit = reader.uint32();
                        break;
                    case 8:
                        message.sortOrder = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetOrderList message.
             * @function verify
             * @memberof fastcity.admin.GetOrderList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetOrderList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderId != null && message.hasOwnProperty("orderId")) {
                    if (!Array.isArray(message.orderId))
                        return "orderId: array expected";
                    for (var i = 0; i < message.orderId.length; ++i)
                        if (!$util.isInteger(message.orderId[i]) && !(message.orderId[i] && $util.isInteger(message.orderId[i].low) && $util.isInteger(message.orderId[i].high)))
                            return "orderId: integer|Long[] expected";
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.courierId != null && message.hasOwnProperty("courierId")) {
                    if (!Array.isArray(message.courierId))
                        return "courierId: array expected";
                    for (var i = 0; i < message.courierId.length; ++i)
                        if (!$util.isInteger(message.courierId[i]) && !(message.courierId[i] && $util.isInteger(message.courierId[i].low) && $util.isInteger(message.courierId[i].high)))
                            return "courierId: integer|Long[] expected";
                }
                if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                    if (!$util.isInteger(message.fromTime) && !(message.fromTime && $util.isInteger(message.fromTime.low) && $util.isInteger(message.fromTime.high)))
                        return "fromTime: integer|Long expected";
                if (message.toTime != null && message.hasOwnProperty("toTime"))
                    if (!$util.isInteger(message.toTime) && !(message.toTime && $util.isInteger(message.toTime.low) && $util.isInteger(message.toTime.high)))
                        return "toTime: integer|Long expected";
                if (message.offset != null && message.hasOwnProperty("offset"))
                    if (!$util.isInteger(message.offset))
                        return "offset: integer expected";
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit))
                        return "limit: integer expected";
                if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                    switch (message.sortOrder) {
                    default:
                        return "sortOrder: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                return null;
            };

            /**
             * Creates a GetOrderList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetOrderList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetOrderList} GetOrderList
             */
            GetOrderList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetOrderList)
                    return object;
                var message = new $root.fastcity.admin.GetOrderList();
                if (object.orderId) {
                    if (!Array.isArray(object.orderId))
                        throw TypeError(".fastcity.admin.GetOrderList.orderId: array expected");
                    message.orderId = [];
                    for (var i = 0; i < object.orderId.length; ++i)
                        if ($util.Long)
                            (message.orderId[i] = $util.Long.fromValue(object.orderId[i])).unsigned = true;
                        else if (typeof object.orderId[i] === "string")
                            message.orderId[i] = parseInt(object.orderId[i], 10);
                        else if (typeof object.orderId[i] === "number")
                            message.orderId[i] = object.orderId[i];
                        else if (typeof object.orderId[i] === "object")
                            message.orderId[i] = new $util.LongBits(object.orderId[i].low >>> 0, object.orderId[i].high >>> 0).toNumber(true);
                }
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.courierId) {
                    if (!Array.isArray(object.courierId))
                        throw TypeError(".fastcity.admin.GetOrderList.courierId: array expected");
                    message.courierId = [];
                    for (var i = 0; i < object.courierId.length; ++i)
                        if ($util.Long)
                            (message.courierId[i] = $util.Long.fromValue(object.courierId[i])).unsigned = true;
                        else if (typeof object.courierId[i] === "string")
                            message.courierId[i] = parseInt(object.courierId[i], 10);
                        else if (typeof object.courierId[i] === "number")
                            message.courierId[i] = object.courierId[i];
                        else if (typeof object.courierId[i] === "object")
                            message.courierId[i] = new $util.LongBits(object.courierId[i].low >>> 0, object.courierId[i].high >>> 0).toNumber(true);
                }
                if (object.fromTime != null)
                    if ($util.Long)
                        (message.fromTime = $util.Long.fromValue(object.fromTime)).unsigned = false;
                    else if (typeof object.fromTime === "string")
                        message.fromTime = parseInt(object.fromTime, 10);
                    else if (typeof object.fromTime === "number")
                        message.fromTime = object.fromTime;
                    else if (typeof object.fromTime === "object")
                        message.fromTime = new $util.LongBits(object.fromTime.low >>> 0, object.fromTime.high >>> 0).toNumber();
                if (object.toTime != null)
                    if ($util.Long)
                        (message.toTime = $util.Long.fromValue(object.toTime)).unsigned = false;
                    else if (typeof object.toTime === "string")
                        message.toTime = parseInt(object.toTime, 10);
                    else if (typeof object.toTime === "number")
                        message.toTime = object.toTime;
                    else if (typeof object.toTime === "object")
                        message.toTime = new $util.LongBits(object.toTime.low >>> 0, object.toTime.high >>> 0).toNumber();
                if (object.offset != null)
                    message.offset = object.offset >>> 0;
                if (object.limit != null)
                    message.limit = object.limit >>> 0;
                switch (object.sortOrder) {
                case "ASC":
                case 0:
                    message.sortOrder = 0;
                    break;
                case "DESC":
                case 1:
                    message.sortOrder = 1;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a GetOrderList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetOrderList
             * @static
             * @param {fastcity.admin.GetOrderList} message GetOrderList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetOrderList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.orderId = [];
                    object.courierId = [];
                }
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.fromTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.fromTime = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.toTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.toTime = options.longs === String ? "0" : 0;
                    object.offset = 0;
                    object.limit = 0;
                    object.sortOrder = options.enums === String ? "ASC" : 0;
                }
                if (message.orderId && message.orderId.length) {
                    object.orderId = [];
                    for (var j = 0; j < message.orderId.length; ++j)
                        if (typeof message.orderId[j] === "number")
                            object.orderId[j] = options.longs === String ? String(message.orderId[j]) : message.orderId[j];
                        else
                            object.orderId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.orderId[j]) : options.longs === Number ? new $util.LongBits(message.orderId[j].low >>> 0, message.orderId[j].high >>> 0).toNumber(true) : message.orderId[j];
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.courierId && message.courierId.length) {
                    object.courierId = [];
                    for (var j = 0; j < message.courierId.length; ++j)
                        if (typeof message.courierId[j] === "number")
                            object.courierId[j] = options.longs === String ? String(message.courierId[j]) : message.courierId[j];
                        else
                            object.courierId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.courierId[j]) : options.longs === Number ? new $util.LongBits(message.courierId[j].low >>> 0, message.courierId[j].high >>> 0).toNumber(true) : message.courierId[j];
                }
                if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                    if (typeof message.fromTime === "number")
                        object.fromTime = options.longs === String ? String(message.fromTime) : message.fromTime;
                    else
                        object.fromTime = options.longs === String ? $util.Long.prototype.toString.call(message.fromTime) : options.longs === Number ? new $util.LongBits(message.fromTime.low >>> 0, message.fromTime.high >>> 0).toNumber() : message.fromTime;
                if (message.toTime != null && message.hasOwnProperty("toTime"))
                    if (typeof message.toTime === "number")
                        object.toTime = options.longs === String ? String(message.toTime) : message.toTime;
                    else
                        object.toTime = options.longs === String ? $util.Long.prototype.toString.call(message.toTime) : options.longs === Number ? new $util.LongBits(message.toTime.low >>> 0, message.toTime.high >>> 0).toNumber() : message.toTime;
                if (message.offset != null && message.hasOwnProperty("offset"))
                    object.offset = message.offset;
                if (message.limit != null && message.hasOwnProperty("limit"))
                    object.limit = message.limit;
                if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                    object.sortOrder = options.enums === String ? $root.fastcity.common.SortOrder.Order[message.sortOrder] : message.sortOrder;
                return object;
            };

            /**
             * Converts this GetOrderList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetOrderList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetOrderList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetOrderList;
        })();

        admin.OrderList = (function() {

            /**
             * Properties of an OrderList.
             * @memberof fastcity.admin
             * @interface IOrderList
             * @property {Array.<fastcity.admin.IOrder>|null} [order] OrderList order
             * @property {number|null} [totalOrders] OrderList totalOrders
             */

            /**
             * Constructs a new OrderList.
             * @memberof fastcity.admin
             * @classdesc Represents an OrderList.
             * @implements IOrderList
             * @constructor
             * @param {fastcity.admin.IOrderList=} [properties] Properties to set
             */
            function OrderList(properties) {
                this.order = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderList order.
             * @member {Array.<fastcity.admin.IOrder>} order
             * @memberof fastcity.admin.OrderList
             * @instance
             */
            OrderList.prototype.order = $util.emptyArray;

            /**
             * OrderList totalOrders.
             * @member {number} totalOrders
             * @memberof fastcity.admin.OrderList
             * @instance
             */
            OrderList.prototype.totalOrders = 0;

            /**
             * Creates a new OrderList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.OrderList
             * @static
             * @param {fastcity.admin.IOrderList=} [properties] Properties to set
             * @returns {fastcity.admin.OrderList} OrderList instance
             */
            OrderList.create = function create(properties) {
                return new OrderList(properties);
            };

            /**
             * Encodes the specified OrderList message. Does not implicitly {@link fastcity.admin.OrderList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.OrderList
             * @static
             * @param {fastcity.admin.IOrderList} message OrderList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.order != null && message.order.length)
                    for (var i = 0; i < message.order.length; ++i)
                        $root.fastcity.admin.Order.encode(message.order[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.totalOrders != null && Object.hasOwnProperty.call(message, "totalOrders"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.totalOrders);
                return writer;
            };

            /**
             * Decodes an OrderList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.OrderList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.OrderList} OrderList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.OrderList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.order && message.order.length))
                            message.order = [];
                        message.order.push($root.fastcity.admin.Order.decode(reader, reader.uint32()));
                        break;
                    case 2:
                        message.totalOrders = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderList message.
             * @function verify
             * @memberof fastcity.admin.OrderList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.order != null && message.hasOwnProperty("order")) {
                    if (!Array.isArray(message.order))
                        return "order: array expected";
                    for (var i = 0; i < message.order.length; ++i) {
                        var error = $root.fastcity.admin.Order.verify(message.order[i]);
                        if (error)
                            return "order." + error;
                    }
                }
                if (message.totalOrders != null && message.hasOwnProperty("totalOrders"))
                    if (!$util.isInteger(message.totalOrders))
                        return "totalOrders: integer expected";
                return null;
            };

            /**
             * Creates an OrderList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.OrderList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.OrderList} OrderList
             */
            OrderList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.OrderList)
                    return object;
                var message = new $root.fastcity.admin.OrderList();
                if (object.order) {
                    if (!Array.isArray(object.order))
                        throw TypeError(".fastcity.admin.OrderList.order: array expected");
                    message.order = [];
                    for (var i = 0; i < object.order.length; ++i) {
                        if (typeof object.order[i] !== "object")
                            throw TypeError(".fastcity.admin.OrderList.order: object expected");
                        message.order[i] = $root.fastcity.admin.Order.fromObject(object.order[i]);
                    }
                }
                if (object.totalOrders != null)
                    message.totalOrders = object.totalOrders >>> 0;
                return message;
            };

            /**
             * Creates a plain object from an OrderList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.OrderList
             * @static
             * @param {fastcity.admin.OrderList} message OrderList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.order = [];
                if (options.defaults)
                    object.totalOrders = 0;
                if (message.order && message.order.length) {
                    object.order = [];
                    for (var j = 0; j < message.order.length; ++j)
                        object.order[j] = $root.fastcity.admin.Order.toObject(message.order[j], options);
                }
                if (message.totalOrders != null && message.hasOwnProperty("totalOrders"))
                    object.totalOrders = message.totalOrders;
                return object;
            };

            /**
             * Converts this OrderList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.OrderList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OrderList;
        })();

        admin.ChangedOrderIdList = (function() {

            /**
             * Properties of a ChangedOrderIdList.
             * @memberof fastcity.admin
             * @interface IChangedOrderIdList
             * @property {Array.<number|Long>|null} [orderId] ChangedOrderIdList orderId
             * @property {number|Long|null} [companyId] ChangedOrderIdList companyId
             * @property {string|null} [externalSubCompanyId] ChangedOrderIdList externalSubCompanyId
             */

            /**
             * Constructs a new ChangedOrderIdList.
             * @memberof fastcity.admin
             * @classdesc Represents a ChangedOrderIdList.
             * @implements IChangedOrderIdList
             * @constructor
             * @param {fastcity.admin.IChangedOrderIdList=} [properties] Properties to set
             */
            function ChangedOrderIdList(properties) {
                this.orderId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ChangedOrderIdList orderId.
             * @member {Array.<number|Long>} orderId
             * @memberof fastcity.admin.ChangedOrderIdList
             * @instance
             */
            ChangedOrderIdList.prototype.orderId = $util.emptyArray;

            /**
             * ChangedOrderIdList companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.ChangedOrderIdList
             * @instance
             */
            ChangedOrderIdList.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ChangedOrderIdList externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.ChangedOrderIdList
             * @instance
             */
            ChangedOrderIdList.prototype.externalSubCompanyId = "";

            /**
             * Creates a new ChangedOrderIdList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ChangedOrderIdList
             * @static
             * @param {fastcity.admin.IChangedOrderIdList=} [properties] Properties to set
             * @returns {fastcity.admin.ChangedOrderIdList} ChangedOrderIdList instance
             */
            ChangedOrderIdList.create = function create(properties) {
                return new ChangedOrderIdList(properties);
            };

            /**
             * Encodes the specified ChangedOrderIdList message. Does not implicitly {@link fastcity.admin.ChangedOrderIdList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ChangedOrderIdList
             * @static
             * @param {fastcity.admin.IChangedOrderIdList} message ChangedOrderIdList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChangedOrderIdList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderId != null && message.orderId.length) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork();
                    for (var i = 0; i < message.orderId.length; ++i)
                        writer.uint64(message.orderId[i]);
                    writer.ldelim();
                }
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.externalSubCompanyId);
                return writer;
            };

            /**
             * Decodes a ChangedOrderIdList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ChangedOrderIdList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ChangedOrderIdList} ChangedOrderIdList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChangedOrderIdList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ChangedOrderIdList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.orderId && message.orderId.length))
                            message.orderId = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.orderId.push(reader.uint64());
                        } else
                            message.orderId.push(reader.uint64());
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        message.externalSubCompanyId = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ChangedOrderIdList message.
             * @function verify
             * @memberof fastcity.admin.ChangedOrderIdList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChangedOrderIdList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderId != null && message.hasOwnProperty("orderId")) {
                    if (!Array.isArray(message.orderId))
                        return "orderId: array expected";
                    for (var i = 0; i < message.orderId.length; ++i)
                        if (!$util.isInteger(message.orderId[i]) && !(message.orderId[i] && $util.isInteger(message.orderId[i].low) && $util.isInteger(message.orderId[i].high)))
                            return "orderId: integer|Long[] expected";
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                return null;
            };

            /**
             * Creates a ChangedOrderIdList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ChangedOrderIdList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ChangedOrderIdList} ChangedOrderIdList
             */
            ChangedOrderIdList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ChangedOrderIdList)
                    return object;
                var message = new $root.fastcity.admin.ChangedOrderIdList();
                if (object.orderId) {
                    if (!Array.isArray(object.orderId))
                        throw TypeError(".fastcity.admin.ChangedOrderIdList.orderId: array expected");
                    message.orderId = [];
                    for (var i = 0; i < object.orderId.length; ++i)
                        if ($util.Long)
                            (message.orderId[i] = $util.Long.fromValue(object.orderId[i])).unsigned = true;
                        else if (typeof object.orderId[i] === "string")
                            message.orderId[i] = parseInt(object.orderId[i], 10);
                        else if (typeof object.orderId[i] === "number")
                            message.orderId[i] = object.orderId[i];
                        else if (typeof object.orderId[i] === "object")
                            message.orderId[i] = new $util.LongBits(object.orderId[i].low >>> 0, object.orderId[i].high >>> 0).toNumber(true);
                }
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                return message;
            };

            /**
             * Creates a plain object from a ChangedOrderIdList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ChangedOrderIdList
             * @static
             * @param {fastcity.admin.ChangedOrderIdList} message ChangedOrderIdList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChangedOrderIdList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.orderId = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                }
                if (message.orderId && message.orderId.length) {
                    object.orderId = [];
                    for (var j = 0; j < message.orderId.length; ++j)
                        if (typeof message.orderId[j] === "number")
                            object.orderId[j] = options.longs === String ? String(message.orderId[j]) : message.orderId[j];
                        else
                            object.orderId[j] = options.longs === String ? $util.Long.prototype.toString.call(message.orderId[j]) : options.longs === Number ? new $util.LongBits(message.orderId[j].low >>> 0, message.orderId[j].high >>> 0).toNumber(true) : message.orderId[j];
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                return object;
            };

            /**
             * Converts this ChangedOrderIdList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ChangedOrderIdList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChangedOrderIdList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ChangedOrderIdList;
        })();

        admin.ClientMessage = (function() {

            /**
             * Properties of a ClientMessage.
             * @memberof fastcity.admin
             * @interface IClientMessage
             * @property {number|Long|null} [requestId] ClientMessage requestId
             * @property {fastcity.admin.IGetCurrentAdmin|null} [getCurrentAdmin] ClientMessage getCurrentAdmin
             * @property {fastcity.admin.IGetAdminList|null} [getAdminList] ClientMessage getAdminList
             * @property {fastcity.admin.ICreateAdmin|null} [createAdmin] ClientMessage createAdmin
             * @property {fastcity.admin.IUpdateAdmin|null} [updateAdmin] ClientMessage updateAdmin
             * @property {fastcity.admin.IDeleteAdmin|null} [deleteAdmin] ClientMessage deleteAdmin
             * @property {fastcity.admin.IGetCurrentCompany|null} [getCurrentCompany] ClientMessage getCurrentCompany
             * @property {fastcity.admin.IGetCompanyList|null} [getCompanyList] ClientMessage getCompanyList
             * @property {fastcity.admin.ICreateCompany|null} [createCompany] ClientMessage createCompany
             * @property {fastcity.admin.IUpdateCompany|null} [updateCompany] ClientMessage updateCompany
             * @property {fastcity.admin.IDeleteCompany|null} [deleteCompany] ClientMessage deleteCompany
             * @property {fastcity.admin.IGetCourierList|null} [getCourierList] ClientMessage getCourierList
             * @property {fastcity.admin.ICreateCourier|null} [createCourier] ClientMessage createCourier
             * @property {fastcity.admin.IUpdateCourier|null} [updateCourier] ClientMessage updateCourier
             * @property {fastcity.admin.ISetCourierGroup|null} [setCourierGroup] ClientMessage setCourierGroup
             * @property {fastcity.admin.ISetCourierIsDeleted|null} [setCourierIsDeleted] ClientMessage setCourierIsDeleted
             * @property {fastcity.admin.IGetGroupList|null} [getGroupList] ClientMessage getGroupList
             * @property {fastcity.admin.ICreateGroup|null} [createGroup] ClientMessage createGroup
             * @property {fastcity.admin.IGetLastLocationList|null} [getLastLocationList] ClientMessage getLastLocationList
             * @property {fastcity.admin.IGetLocationList|null} [getLocationList] ClientMessage getLocationList
             * @property {fastcity.admin.IGetOrderList|null} [getOrderList] ClientMessage getOrderList
             * @property {fastcity.admin.IGetConnectionStatusList|null} [getConnectionStatusList] ClientMessage getConnectionStatusList
             * @property {fastcity.admin.ICreateIntegrationToken|null} [createIntegrationToken] ClientMessage createIntegrationToken
             * @property {fastcity.admin.IDeleteIntegrationToken|null} [deleteIntegrationToken] ClientMessage deleteIntegrationToken
             * @property {fastcity.admin.IGetIntegrationTokenList|null} [getIntegrationTokenList] ClientMessage getIntegrationTokenList
             * @property {fastcity.admin.ILogon|null} [logon] ClientMessage logon
             * @property {fastcity.admin.IResetPassword|null} [resetPassword] ClientMessage resetPassword
             * @property {fastcity.admin.IDeleteGroup|null} [deleteGroup] ClientMessage deleteGroup
             * @property {fastcity.admin.IUpdateGroup|null} [updateGroup] ClientMessage updateGroup
             * @property {fastcity.admin.IGetDeliveryStatDayList|null} [getDeliveryStatDayList] ClientMessage getDeliveryStatDayList
             * @property {fastcity.admin.IGetDeliveryStatList|null} [getDeliveryStatList] ClientMessage getDeliveryStatList
             * @property {fastcity.admin.IGetWorkingDayList|null} [getWorkingDayList] ClientMessage getWorkingDayList
             * @property {fastcity.admin.IGetExternalSubCompanyIdList|null} [getExternalSubCompanyIdList] ClientMessage getExternalSubCompanyIdList
             * @property {fastcity.admin.IGetSubCompanyList|null} [getSubCompanyList] ClientMessage getSubCompanyList
             * @property {fastcity.admin.IDeleteSubCompany|null} [deleteSubCompany] ClientMessage deleteSubCompany
             * @property {fastcity.admin.ICreateSubCompany|null} [createSubCompany] ClientMessage createSubCompany
             * @property {fastcity.admin.IUpdateSubCompany|null} [updateSubCompany] ClientMessage updateSubCompany
             * @property {fastcity.admin.IActivate|null} [activate] ClientMessage activate
             * @property {fastcity.admin.ICreateSubCompanyZone|null} [createSubCompanyZone] ClientMessage createSubCompanyZone
             * @property {fastcity.admin.ICreateSubCompanyZoneFromKML|null} [createSubCompanyZoneFromKml] ClientMessage createSubCompanyZoneFromKml
             * @property {fastcity.admin.IDeleteSubCompanyZone|null} [deleteSubCompanyZone] ClientMessage deleteSubCompanyZone
             * @property {fastcity.admin.IRecoverSubCompanyZone|null} [recoverSubCompanyZone] ClientMessage recoverSubCompanyZone
             * @property {fastcity.admin.IUpdateSubCompanyZone|null} [updateSubCompanyZone] ClientMessage updateSubCompanyZone
             * @property {fastcity.admin.IUpdateSubCompanyZoneUsingKML|null} [updateSubCompanyZoneUsingKml] ClientMessage updateSubCompanyZoneUsingKml
             * @property {fastcity.admin.IGetSubCompanyZone|null} [getSubCompanyZone] ClientMessage getSubCompanyZone
             * @property {fastcity.admin.IGetSubCompanyZoneList|null} [getSubCompanyZoneList] ClientMessage getSubCompanyZoneList
             * @property {fastcity.admin.ICreateAvailableOrderStatuses|null} [createAvailableOrderStatuses] ClientMessage createAvailableOrderStatuses
             * @property {fastcity.admin.IUpdateAvailableOrderStatuses|null} [updateAvailableOrderStatuses] ClientMessage updateAvailableOrderStatuses
             * @property {fastcity.admin.IGetAvailableOrderStatuses|null} [getAvailableOrderStatuses] ClientMessage getAvailableOrderStatuses
             * @property {fastcity.admin.IGetAvailableOrderStatusesList|null} [getAvailableOrderStatusesList] ClientMessage getAvailableOrderStatusesList
             * @property {fastcity.admin.ICreateExternalAuthToken|null} [createExternalAuthToken] ClientMessage createExternalAuthToken
             * @property {fastcity.admin.IUpdateExternalAuthToken|null} [updateExternalAuthToken] ClientMessage updateExternalAuthToken
             * @property {fastcity.admin.IDeleteExternalAuthToken|null} [deleteExternalAuthToken] ClientMessage deleteExternalAuthToken
             * @property {fastcity.admin.IGetExternalAuthToken|null} [getExternalAuthToken] ClientMessage getExternalAuthToken
             * @property {fastcity.admin.IGetExternalAuthTokenList|null} [getExternalAuthTokenList] ClientMessage getExternalAuthTokenList
             * @property {fastcity.admin.IBindSubCompanyToExternalAuthToken|null} [bindSubCompanyToExternalAuthToken] ClientMessage bindSubCompanyToExternalAuthToken
             * @property {fastcity.admin.IUnbindSubCompanyFromExternalAuthToken|null} [unbindSubCompanyFromExternalAuthToken] ClientMessage unbindSubCompanyFromExternalAuthToken
             * @property {fastcity.admin.IGetBindedExternalAuthToken|null} [getBindedExternalAuthToken] ClientMessage getBindedExternalAuthToken
             * @property {fastcity.admin.IGetBindedSubCompanies|null} [getBindedSubCompanies] ClientMessage getBindedSubCompanies
             * @property {fastcity.admin.IGetServerVersion|null} [getServerVersion] ClientMessage getServerVersion
             */

            /**
             * Constructs a new ClientMessage.
             * @memberof fastcity.admin
             * @classdesc Represents a ClientMessage.
             * @implements IClientMessage
             * @constructor
             * @param {fastcity.admin.IClientMessage=} [properties] Properties to set
             */
            function ClientMessage(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ClientMessage requestId.
             * @member {number|Long} requestId
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.requestId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ClientMessage getCurrentAdmin.
             * @member {fastcity.admin.IGetCurrentAdmin|null|undefined} getCurrentAdmin
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getCurrentAdmin = null;

            /**
             * ClientMessage getAdminList.
             * @member {fastcity.admin.IGetAdminList|null|undefined} getAdminList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getAdminList = null;

            /**
             * ClientMessage createAdmin.
             * @member {fastcity.admin.ICreateAdmin|null|undefined} createAdmin
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.createAdmin = null;

            /**
             * ClientMessage updateAdmin.
             * @member {fastcity.admin.IUpdateAdmin|null|undefined} updateAdmin
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateAdmin = null;

            /**
             * ClientMessage deleteAdmin.
             * @member {fastcity.admin.IDeleteAdmin|null|undefined} deleteAdmin
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.deleteAdmin = null;

            /**
             * ClientMessage getCurrentCompany.
             * @member {fastcity.admin.IGetCurrentCompany|null|undefined} getCurrentCompany
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getCurrentCompany = null;

            /**
             * ClientMessage getCompanyList.
             * @member {fastcity.admin.IGetCompanyList|null|undefined} getCompanyList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getCompanyList = null;

            /**
             * ClientMessage createCompany.
             * @member {fastcity.admin.ICreateCompany|null|undefined} createCompany
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.createCompany = null;

            /**
             * ClientMessage updateCompany.
             * @member {fastcity.admin.IUpdateCompany|null|undefined} updateCompany
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateCompany = null;

            /**
             * ClientMessage deleteCompany.
             * @member {fastcity.admin.IDeleteCompany|null|undefined} deleteCompany
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.deleteCompany = null;

            /**
             * ClientMessage getCourierList.
             * @member {fastcity.admin.IGetCourierList|null|undefined} getCourierList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getCourierList = null;

            /**
             * ClientMessage createCourier.
             * @member {fastcity.admin.ICreateCourier|null|undefined} createCourier
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.createCourier = null;

            /**
             * ClientMessage updateCourier.
             * @member {fastcity.admin.IUpdateCourier|null|undefined} updateCourier
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateCourier = null;

            /**
             * ClientMessage setCourierGroup.
             * @member {fastcity.admin.ISetCourierGroup|null|undefined} setCourierGroup
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.setCourierGroup = null;

            /**
             * ClientMessage setCourierIsDeleted.
             * @member {fastcity.admin.ISetCourierIsDeleted|null|undefined} setCourierIsDeleted
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.setCourierIsDeleted = null;

            /**
             * ClientMessage getGroupList.
             * @member {fastcity.admin.IGetGroupList|null|undefined} getGroupList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getGroupList = null;

            /**
             * ClientMessage createGroup.
             * @member {fastcity.admin.ICreateGroup|null|undefined} createGroup
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.createGroup = null;

            /**
             * ClientMessage getLastLocationList.
             * @member {fastcity.admin.IGetLastLocationList|null|undefined} getLastLocationList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getLastLocationList = null;

            /**
             * ClientMessage getLocationList.
             * @member {fastcity.admin.IGetLocationList|null|undefined} getLocationList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getLocationList = null;

            /**
             * ClientMessage getOrderList.
             * @member {fastcity.admin.IGetOrderList|null|undefined} getOrderList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getOrderList = null;

            /**
             * ClientMessage getConnectionStatusList.
             * @member {fastcity.admin.IGetConnectionStatusList|null|undefined} getConnectionStatusList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getConnectionStatusList = null;

            /**
             * ClientMessage createIntegrationToken.
             * @member {fastcity.admin.ICreateIntegrationToken|null|undefined} createIntegrationToken
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.createIntegrationToken = null;

            /**
             * ClientMessage deleteIntegrationToken.
             * @member {fastcity.admin.IDeleteIntegrationToken|null|undefined} deleteIntegrationToken
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.deleteIntegrationToken = null;

            /**
             * ClientMessage getIntegrationTokenList.
             * @member {fastcity.admin.IGetIntegrationTokenList|null|undefined} getIntegrationTokenList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getIntegrationTokenList = null;

            /**
             * ClientMessage logon.
             * @member {fastcity.admin.ILogon|null|undefined} logon
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.logon = null;

            /**
             * ClientMessage resetPassword.
             * @member {fastcity.admin.IResetPassword|null|undefined} resetPassword
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.resetPassword = null;

            /**
             * ClientMessage deleteGroup.
             * @member {fastcity.admin.IDeleteGroup|null|undefined} deleteGroup
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.deleteGroup = null;

            /**
             * ClientMessage updateGroup.
             * @member {fastcity.admin.IUpdateGroup|null|undefined} updateGroup
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateGroup = null;

            /**
             * ClientMessage getDeliveryStatDayList.
             * @member {fastcity.admin.IGetDeliveryStatDayList|null|undefined} getDeliveryStatDayList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getDeliveryStatDayList = null;

            /**
             * ClientMessage getDeliveryStatList.
             * @member {fastcity.admin.IGetDeliveryStatList|null|undefined} getDeliveryStatList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getDeliveryStatList = null;

            /**
             * ClientMessage getWorkingDayList.
             * @member {fastcity.admin.IGetWorkingDayList|null|undefined} getWorkingDayList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getWorkingDayList = null;

            /**
             * ClientMessage getExternalSubCompanyIdList.
             * @member {fastcity.admin.IGetExternalSubCompanyIdList|null|undefined} getExternalSubCompanyIdList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getExternalSubCompanyIdList = null;

            /**
             * ClientMessage getSubCompanyList.
             * @member {fastcity.admin.IGetSubCompanyList|null|undefined} getSubCompanyList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getSubCompanyList = null;

            /**
             * ClientMessage deleteSubCompany.
             * @member {fastcity.admin.IDeleteSubCompany|null|undefined} deleteSubCompany
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.deleteSubCompany = null;

            /**
             * ClientMessage createSubCompany.
             * @member {fastcity.admin.ICreateSubCompany|null|undefined} createSubCompany
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.createSubCompany = null;

            /**
             * ClientMessage updateSubCompany.
             * @member {fastcity.admin.IUpdateSubCompany|null|undefined} updateSubCompany
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateSubCompany = null;

            /**
             * ClientMessage activate.
             * @member {fastcity.admin.IActivate|null|undefined} activate
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.activate = null;

            /**
             * ClientMessage createSubCompanyZone.
             * @member {fastcity.admin.ICreateSubCompanyZone|null|undefined} createSubCompanyZone
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.createSubCompanyZone = null;

            /**
             * ClientMessage createSubCompanyZoneFromKml.
             * @member {fastcity.admin.ICreateSubCompanyZoneFromKML|null|undefined} createSubCompanyZoneFromKml
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.createSubCompanyZoneFromKml = null;

            /**
             * ClientMessage deleteSubCompanyZone.
             * @member {fastcity.admin.IDeleteSubCompanyZone|null|undefined} deleteSubCompanyZone
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.deleteSubCompanyZone = null;

            /**
             * ClientMessage recoverSubCompanyZone.
             * @member {fastcity.admin.IRecoverSubCompanyZone|null|undefined} recoverSubCompanyZone
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.recoverSubCompanyZone = null;

            /**
             * ClientMessage updateSubCompanyZone.
             * @member {fastcity.admin.IUpdateSubCompanyZone|null|undefined} updateSubCompanyZone
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateSubCompanyZone = null;

            /**
             * ClientMessage updateSubCompanyZoneUsingKml.
             * @member {fastcity.admin.IUpdateSubCompanyZoneUsingKML|null|undefined} updateSubCompanyZoneUsingKml
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateSubCompanyZoneUsingKml = null;

            /**
             * ClientMessage getSubCompanyZone.
             * @member {fastcity.admin.IGetSubCompanyZone|null|undefined} getSubCompanyZone
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getSubCompanyZone = null;

            /**
             * ClientMessage getSubCompanyZoneList.
             * @member {fastcity.admin.IGetSubCompanyZoneList|null|undefined} getSubCompanyZoneList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getSubCompanyZoneList = null;

            /**
             * ClientMessage createAvailableOrderStatuses.
             * @member {fastcity.admin.ICreateAvailableOrderStatuses|null|undefined} createAvailableOrderStatuses
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.createAvailableOrderStatuses = null;

            /**
             * ClientMessage updateAvailableOrderStatuses.
             * @member {fastcity.admin.IUpdateAvailableOrderStatuses|null|undefined} updateAvailableOrderStatuses
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateAvailableOrderStatuses = null;

            /**
             * ClientMessage getAvailableOrderStatuses.
             * @member {fastcity.admin.IGetAvailableOrderStatuses|null|undefined} getAvailableOrderStatuses
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getAvailableOrderStatuses = null;

            /**
             * ClientMessage getAvailableOrderStatusesList.
             * @member {fastcity.admin.IGetAvailableOrderStatusesList|null|undefined} getAvailableOrderStatusesList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getAvailableOrderStatusesList = null;

            /**
             * ClientMessage createExternalAuthToken.
             * @member {fastcity.admin.ICreateExternalAuthToken|null|undefined} createExternalAuthToken
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.createExternalAuthToken = null;

            /**
             * ClientMessage updateExternalAuthToken.
             * @member {fastcity.admin.IUpdateExternalAuthToken|null|undefined} updateExternalAuthToken
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.updateExternalAuthToken = null;

            /**
             * ClientMessage deleteExternalAuthToken.
             * @member {fastcity.admin.IDeleteExternalAuthToken|null|undefined} deleteExternalAuthToken
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.deleteExternalAuthToken = null;

            /**
             * ClientMessage getExternalAuthToken.
             * @member {fastcity.admin.IGetExternalAuthToken|null|undefined} getExternalAuthToken
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getExternalAuthToken = null;

            /**
             * ClientMessage getExternalAuthTokenList.
             * @member {fastcity.admin.IGetExternalAuthTokenList|null|undefined} getExternalAuthTokenList
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getExternalAuthTokenList = null;

            /**
             * ClientMessage bindSubCompanyToExternalAuthToken.
             * @member {fastcity.admin.IBindSubCompanyToExternalAuthToken|null|undefined} bindSubCompanyToExternalAuthToken
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.bindSubCompanyToExternalAuthToken = null;

            /**
             * ClientMessage unbindSubCompanyFromExternalAuthToken.
             * @member {fastcity.admin.IUnbindSubCompanyFromExternalAuthToken|null|undefined} unbindSubCompanyFromExternalAuthToken
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.unbindSubCompanyFromExternalAuthToken = null;

            /**
             * ClientMessage getBindedExternalAuthToken.
             * @member {fastcity.admin.IGetBindedExternalAuthToken|null|undefined} getBindedExternalAuthToken
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getBindedExternalAuthToken = null;

            /**
             * ClientMessage getBindedSubCompanies.
             * @member {fastcity.admin.IGetBindedSubCompanies|null|undefined} getBindedSubCompanies
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getBindedSubCompanies = null;

            /**
             * ClientMessage getServerVersion.
             * @member {fastcity.admin.IGetServerVersion|null|undefined} getServerVersion
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            ClientMessage.prototype.getServerVersion = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * ClientMessage body.
             * @member {"getCurrentAdmin"|"getAdminList"|"createAdmin"|"updateAdmin"|"deleteAdmin"|"getCurrentCompany"|"getCompanyList"|"createCompany"|"updateCompany"|"deleteCompany"|"getCourierList"|"createCourier"|"updateCourier"|"setCourierGroup"|"setCourierIsDeleted"|"getGroupList"|"createGroup"|"getLastLocationList"|"getLocationList"|"getOrderList"|"getConnectionStatusList"|"createIntegrationToken"|"deleteIntegrationToken"|"getIntegrationTokenList"|"logon"|"resetPassword"|"deleteGroup"|"updateGroup"|"getDeliveryStatDayList"|"getDeliveryStatList"|"getWorkingDayList"|"getExternalSubCompanyIdList"|"getSubCompanyList"|"deleteSubCompany"|"createSubCompany"|"updateSubCompany"|"activate"|"createSubCompanyZone"|"createSubCompanyZoneFromKml"|"deleteSubCompanyZone"|"recoverSubCompanyZone"|"updateSubCompanyZone"|"updateSubCompanyZoneUsingKml"|"getSubCompanyZone"|"getSubCompanyZoneList"|"createAvailableOrderStatuses"|"updateAvailableOrderStatuses"|"getAvailableOrderStatuses"|"getAvailableOrderStatusesList"|"createExternalAuthToken"|"updateExternalAuthToken"|"deleteExternalAuthToken"|"getExternalAuthToken"|"getExternalAuthTokenList"|"bindSubCompanyToExternalAuthToken"|"unbindSubCompanyFromExternalAuthToken"|"getBindedExternalAuthToken"|"getBindedSubCompanies"|"getServerVersion"|undefined} body
             * @memberof fastcity.admin.ClientMessage
             * @instance
             */
            Object.defineProperty(ClientMessage.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["getCurrentAdmin", "getAdminList", "createAdmin", "updateAdmin", "deleteAdmin", "getCurrentCompany", "getCompanyList", "createCompany", "updateCompany", "deleteCompany", "getCourierList", "createCourier", "updateCourier", "setCourierGroup", "setCourierIsDeleted", "getGroupList", "createGroup", "getLastLocationList", "getLocationList", "getOrderList", "getConnectionStatusList", "createIntegrationToken", "deleteIntegrationToken", "getIntegrationTokenList", "logon", "resetPassword", "deleteGroup", "updateGroup", "getDeliveryStatDayList", "getDeliveryStatList", "getWorkingDayList", "getExternalSubCompanyIdList", "getSubCompanyList", "deleteSubCompany", "createSubCompany", "updateSubCompany", "activate", "createSubCompanyZone", "createSubCompanyZoneFromKml", "deleteSubCompanyZone", "recoverSubCompanyZone", "updateSubCompanyZone", "updateSubCompanyZoneUsingKml", "getSubCompanyZone", "getSubCompanyZoneList", "createAvailableOrderStatuses", "updateAvailableOrderStatuses", "getAvailableOrderStatuses", "getAvailableOrderStatusesList", "createExternalAuthToken", "updateExternalAuthToken", "deleteExternalAuthToken", "getExternalAuthToken", "getExternalAuthTokenList", "bindSubCompanyToExternalAuthToken", "unbindSubCompanyFromExternalAuthToken", "getBindedExternalAuthToken", "getBindedSubCompanies", "getServerVersion"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new ClientMessage instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ClientMessage
             * @static
             * @param {fastcity.admin.IClientMessage=} [properties] Properties to set
             * @returns {fastcity.admin.ClientMessage} ClientMessage instance
             */
            ClientMessage.create = function create(properties) {
                return new ClientMessage(properties);
            };

            /**
             * Encodes the specified ClientMessage message. Does not implicitly {@link fastcity.admin.ClientMessage.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ClientMessage
             * @static
             * @param {fastcity.admin.IClientMessage} message ClientMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientMessage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.requestId);
                if (message.getCurrentAdmin != null && Object.hasOwnProperty.call(message, "getCurrentAdmin"))
                    $root.fastcity.admin.GetCurrentAdmin.encode(message.getCurrentAdmin, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.getAdminList != null && Object.hasOwnProperty.call(message, "getAdminList"))
                    $root.fastcity.admin.GetAdminList.encode(message.getAdminList, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.createAdmin != null && Object.hasOwnProperty.call(message, "createAdmin"))
                    $root.fastcity.admin.CreateAdmin.encode(message.createAdmin, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.updateAdmin != null && Object.hasOwnProperty.call(message, "updateAdmin"))
                    $root.fastcity.admin.UpdateAdmin.encode(message.updateAdmin, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.getCurrentCompany != null && Object.hasOwnProperty.call(message, "getCurrentCompany"))
                    $root.fastcity.admin.GetCurrentCompany.encode(message.getCurrentCompany, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.getCompanyList != null && Object.hasOwnProperty.call(message, "getCompanyList"))
                    $root.fastcity.admin.GetCompanyList.encode(message.getCompanyList, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.createCompany != null && Object.hasOwnProperty.call(message, "createCompany"))
                    $root.fastcity.admin.CreateCompany.encode(message.createCompany, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.updateCompany != null && Object.hasOwnProperty.call(message, "updateCompany"))
                    $root.fastcity.admin.UpdateCompany.encode(message.updateCompany, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.getCourierList != null && Object.hasOwnProperty.call(message, "getCourierList"))
                    $root.fastcity.admin.GetCourierList.encode(message.getCourierList, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.createCourier != null && Object.hasOwnProperty.call(message, "createCourier"))
                    $root.fastcity.admin.CreateCourier.encode(message.createCourier, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.getGroupList != null && Object.hasOwnProperty.call(message, "getGroupList"))
                    $root.fastcity.admin.GetGroupList.encode(message.getGroupList, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.createGroup != null && Object.hasOwnProperty.call(message, "createGroup"))
                    $root.fastcity.admin.CreateGroup.encode(message.createGroup, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.getLastLocationList != null && Object.hasOwnProperty.call(message, "getLastLocationList"))
                    $root.fastcity.admin.GetLastLocationList.encode(message.getLastLocationList, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.getLocationList != null && Object.hasOwnProperty.call(message, "getLocationList"))
                    $root.fastcity.admin.GetLocationList.encode(message.getLocationList, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.getOrderList != null && Object.hasOwnProperty.call(message, "getOrderList"))
                    $root.fastcity.admin.GetOrderList.encode(message.getOrderList, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.setCourierGroup != null && Object.hasOwnProperty.call(message, "setCourierGroup"))
                    $root.fastcity.admin.SetCourierGroup.encode(message.setCourierGroup, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.getConnectionStatusList != null && Object.hasOwnProperty.call(message, "getConnectionStatusList"))
                    $root.fastcity.admin.GetConnectionStatusList.encode(message.getConnectionStatusList, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                if (message.createIntegrationToken != null && Object.hasOwnProperty.call(message, "createIntegrationToken"))
                    $root.fastcity.admin.CreateIntegrationToken.encode(message.createIntegrationToken, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                if (message.deleteIntegrationToken != null && Object.hasOwnProperty.call(message, "deleteIntegrationToken"))
                    $root.fastcity.admin.DeleteIntegrationToken.encode(message.deleteIntegrationToken, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                if (message.getIntegrationTokenList != null && Object.hasOwnProperty.call(message, "getIntegrationTokenList"))
                    $root.fastcity.admin.GetIntegrationTokenList.encode(message.getIntegrationTokenList, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                if (message.logon != null && Object.hasOwnProperty.call(message, "logon"))
                    $root.fastcity.admin.Logon.encode(message.logon, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                if (message.deleteGroup != null && Object.hasOwnProperty.call(message, "deleteGroup"))
                    $root.fastcity.admin.DeleteGroup.encode(message.deleteGroup, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                if (message.updateGroup != null && Object.hasOwnProperty.call(message, "updateGroup"))
                    $root.fastcity.admin.UpdateGroup.encode(message.updateGroup, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                if (message.getDeliveryStatDayList != null && Object.hasOwnProperty.call(message, "getDeliveryStatDayList"))
                    $root.fastcity.admin.GetDeliveryStatDayList.encode(message.getDeliveryStatDayList, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
                if (message.getDeliveryStatList != null && Object.hasOwnProperty.call(message, "getDeliveryStatList"))
                    $root.fastcity.admin.GetDeliveryStatList.encode(message.getDeliveryStatList, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                if (message.getWorkingDayList != null && Object.hasOwnProperty.call(message, "getWorkingDayList"))
                    $root.fastcity.admin.GetWorkingDayList.encode(message.getWorkingDayList, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
                if (message.getExternalSubCompanyIdList != null && Object.hasOwnProperty.call(message, "getExternalSubCompanyIdList"))
                    $root.fastcity.admin.GetExternalSubCompanyIdList.encode(message.getExternalSubCompanyIdList, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
                if (message.setCourierIsDeleted != null && Object.hasOwnProperty.call(message, "setCourierIsDeleted"))
                    $root.fastcity.admin.SetCourierIsDeleted.encode(message.setCourierIsDeleted, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
                if (message.getSubCompanyList != null && Object.hasOwnProperty.call(message, "getSubCompanyList"))
                    $root.fastcity.admin.GetSubCompanyList.encode(message.getSubCompanyList, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
                if (message.deleteAdmin != null && Object.hasOwnProperty.call(message, "deleteAdmin"))
                    $root.fastcity.admin.DeleteAdmin.encode(message.deleteAdmin, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
                if (message.deleteCompany != null && Object.hasOwnProperty.call(message, "deleteCompany"))
                    $root.fastcity.admin.DeleteCompany.encode(message.deleteCompany, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
                if (message.deleteSubCompany != null && Object.hasOwnProperty.call(message, "deleteSubCompany"))
                    $root.fastcity.admin.DeleteSubCompany.encode(message.deleteSubCompany, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
                if (message.resetPassword != null && Object.hasOwnProperty.call(message, "resetPassword"))
                    $root.fastcity.admin.ResetPassword.encode(message.resetPassword, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
                if (message.activate != null && Object.hasOwnProperty.call(message, "activate"))
                    $root.fastcity.admin.Activate.encode(message.activate, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
                if (message.updateSubCompany != null && Object.hasOwnProperty.call(message, "updateSubCompany"))
                    $root.fastcity.admin.UpdateSubCompany.encode(message.updateSubCompany, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
                if (message.updateCourier != null && Object.hasOwnProperty.call(message, "updateCourier"))
                    $root.fastcity.admin.UpdateCourier.encode(message.updateCourier, writer.uint32(/* id 38, wireType 2 =*/306).fork()).ldelim();
                if (message.createSubCompanyZone != null && Object.hasOwnProperty.call(message, "createSubCompanyZone"))
                    $root.fastcity.admin.CreateSubCompanyZone.encode(message.createSubCompanyZone, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
                if (message.updateSubCompanyZone != null && Object.hasOwnProperty.call(message, "updateSubCompanyZone"))
                    $root.fastcity.admin.UpdateSubCompanyZone.encode(message.updateSubCompanyZone, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
                if (message.getSubCompanyZone != null && Object.hasOwnProperty.call(message, "getSubCompanyZone"))
                    $root.fastcity.admin.GetSubCompanyZone.encode(message.getSubCompanyZone, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
                if (message.getSubCompanyZoneList != null && Object.hasOwnProperty.call(message, "getSubCompanyZoneList"))
                    $root.fastcity.admin.GetSubCompanyZoneList.encode(message.getSubCompanyZoneList, writer.uint32(/* id 42, wireType 2 =*/338).fork()).ldelim();
                if (message.createSubCompany != null && Object.hasOwnProperty.call(message, "createSubCompany"))
                    $root.fastcity.admin.CreateSubCompany.encode(message.createSubCompany, writer.uint32(/* id 43, wireType 2 =*/346).fork()).ldelim();
                if (message.createAvailableOrderStatuses != null && Object.hasOwnProperty.call(message, "createAvailableOrderStatuses"))
                    $root.fastcity.admin.CreateAvailableOrderStatuses.encode(message.createAvailableOrderStatuses, writer.uint32(/* id 44, wireType 2 =*/354).fork()).ldelim();
                if (message.updateAvailableOrderStatuses != null && Object.hasOwnProperty.call(message, "updateAvailableOrderStatuses"))
                    $root.fastcity.admin.UpdateAvailableOrderStatuses.encode(message.updateAvailableOrderStatuses, writer.uint32(/* id 45, wireType 2 =*/362).fork()).ldelim();
                if (message.createSubCompanyZoneFromKml != null && Object.hasOwnProperty.call(message, "createSubCompanyZoneFromKml"))
                    $root.fastcity.admin.CreateSubCompanyZoneFromKML.encode(message.createSubCompanyZoneFromKml, writer.uint32(/* id 46, wireType 2 =*/370).fork()).ldelim();
                if (message.createExternalAuthToken != null && Object.hasOwnProperty.call(message, "createExternalAuthToken"))
                    $root.fastcity.admin.CreateExternalAuthToken.encode(message.createExternalAuthToken, writer.uint32(/* id 47, wireType 2 =*/378).fork()).ldelim();
                if (message.updateExternalAuthToken != null && Object.hasOwnProperty.call(message, "updateExternalAuthToken"))
                    $root.fastcity.admin.UpdateExternalAuthToken.encode(message.updateExternalAuthToken, writer.uint32(/* id 48, wireType 2 =*/386).fork()).ldelim();
                if (message.deleteExternalAuthToken != null && Object.hasOwnProperty.call(message, "deleteExternalAuthToken"))
                    $root.fastcity.admin.DeleteExternalAuthToken.encode(message.deleteExternalAuthToken, writer.uint32(/* id 49, wireType 2 =*/394).fork()).ldelim();
                if (message.getExternalAuthToken != null && Object.hasOwnProperty.call(message, "getExternalAuthToken"))
                    $root.fastcity.admin.GetExternalAuthToken.encode(message.getExternalAuthToken, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
                if (message.getExternalAuthTokenList != null && Object.hasOwnProperty.call(message, "getExternalAuthTokenList"))
                    $root.fastcity.admin.GetExternalAuthTokenList.encode(message.getExternalAuthTokenList, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
                if (message.bindSubCompanyToExternalAuthToken != null && Object.hasOwnProperty.call(message, "bindSubCompanyToExternalAuthToken"))
                    $root.fastcity.admin.BindSubCompanyToExternalAuthToken.encode(message.bindSubCompanyToExternalAuthToken, writer.uint32(/* id 52, wireType 2 =*/418).fork()).ldelim();
                if (message.unbindSubCompanyFromExternalAuthToken != null && Object.hasOwnProperty.call(message, "unbindSubCompanyFromExternalAuthToken"))
                    $root.fastcity.admin.UnbindSubCompanyFromExternalAuthToken.encode(message.unbindSubCompanyFromExternalAuthToken, writer.uint32(/* id 53, wireType 2 =*/426).fork()).ldelim();
                if (message.updateSubCompanyZoneUsingKml != null && Object.hasOwnProperty.call(message, "updateSubCompanyZoneUsingKml"))
                    $root.fastcity.admin.UpdateSubCompanyZoneUsingKML.encode(message.updateSubCompanyZoneUsingKml, writer.uint32(/* id 54, wireType 2 =*/434).fork()).ldelim();
                if (message.getAvailableOrderStatuses != null && Object.hasOwnProperty.call(message, "getAvailableOrderStatuses"))
                    $root.fastcity.admin.GetAvailableOrderStatuses.encode(message.getAvailableOrderStatuses, writer.uint32(/* id 55, wireType 2 =*/442).fork()).ldelim();
                if (message.getAvailableOrderStatusesList != null && Object.hasOwnProperty.call(message, "getAvailableOrderStatusesList"))
                    $root.fastcity.admin.GetAvailableOrderStatusesList.encode(message.getAvailableOrderStatusesList, writer.uint32(/* id 56, wireType 2 =*/450).fork()).ldelim();
                if (message.getBindedExternalAuthToken != null && Object.hasOwnProperty.call(message, "getBindedExternalAuthToken"))
                    $root.fastcity.admin.GetBindedExternalAuthToken.encode(message.getBindedExternalAuthToken, writer.uint32(/* id 57, wireType 2 =*/458).fork()).ldelim();
                if (message.getBindedSubCompanies != null && Object.hasOwnProperty.call(message, "getBindedSubCompanies"))
                    $root.fastcity.admin.GetBindedSubCompanies.encode(message.getBindedSubCompanies, writer.uint32(/* id 58, wireType 2 =*/466).fork()).ldelim();
                if (message.getServerVersion != null && Object.hasOwnProperty.call(message, "getServerVersion"))
                    $root.fastcity.admin.GetServerVersion.encode(message.getServerVersion, writer.uint32(/* id 59, wireType 2 =*/474).fork()).ldelim();
                if (message.deleteSubCompanyZone != null && Object.hasOwnProperty.call(message, "deleteSubCompanyZone"))
                    $root.fastcity.admin.DeleteSubCompanyZone.encode(message.deleteSubCompanyZone, writer.uint32(/* id 60, wireType 2 =*/482).fork()).ldelim();
                if (message.recoverSubCompanyZone != null && Object.hasOwnProperty.call(message, "recoverSubCompanyZone"))
                    $root.fastcity.admin.RecoverSubCompanyZone.encode(message.recoverSubCompanyZone, writer.uint32(/* id 61, wireType 2 =*/490).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ClientMessage message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ClientMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ClientMessage} ClientMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientMessage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ClientMessage();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.requestId = reader.uint64();
                        break;
                    case 2:
                        message.getCurrentAdmin = $root.fastcity.admin.GetCurrentAdmin.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.getAdminList = $root.fastcity.admin.GetAdminList.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.createAdmin = $root.fastcity.admin.CreateAdmin.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.updateAdmin = $root.fastcity.admin.UpdateAdmin.decode(reader, reader.uint32());
                        break;
                    case 31:
                        message.deleteAdmin = $root.fastcity.admin.DeleteAdmin.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.getCurrentCompany = $root.fastcity.admin.GetCurrentCompany.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.getCompanyList = $root.fastcity.admin.GetCompanyList.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.createCompany = $root.fastcity.admin.CreateCompany.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.updateCompany = $root.fastcity.admin.UpdateCompany.decode(reader, reader.uint32());
                        break;
                    case 32:
                        message.deleteCompany = $root.fastcity.admin.DeleteCompany.decode(reader, reader.uint32());
                        break;
                    case 10:
                        message.getCourierList = $root.fastcity.admin.GetCourierList.decode(reader, reader.uint32());
                        break;
                    case 11:
                        message.createCourier = $root.fastcity.admin.CreateCourier.decode(reader, reader.uint32());
                        break;
                    case 38:
                        message.updateCourier = $root.fastcity.admin.UpdateCourier.decode(reader, reader.uint32());
                        break;
                    case 17:
                        message.setCourierGroup = $root.fastcity.admin.SetCourierGroup.decode(reader, reader.uint32());
                        break;
                    case 29:
                        message.setCourierIsDeleted = $root.fastcity.admin.SetCourierIsDeleted.decode(reader, reader.uint32());
                        break;
                    case 12:
                        message.getGroupList = $root.fastcity.admin.GetGroupList.decode(reader, reader.uint32());
                        break;
                    case 13:
                        message.createGroup = $root.fastcity.admin.CreateGroup.decode(reader, reader.uint32());
                        break;
                    case 14:
                        message.getLastLocationList = $root.fastcity.admin.GetLastLocationList.decode(reader, reader.uint32());
                        break;
                    case 15:
                        message.getLocationList = $root.fastcity.admin.GetLocationList.decode(reader, reader.uint32());
                        break;
                    case 16:
                        message.getOrderList = $root.fastcity.admin.GetOrderList.decode(reader, reader.uint32());
                        break;
                    case 18:
                        message.getConnectionStatusList = $root.fastcity.admin.GetConnectionStatusList.decode(reader, reader.uint32());
                        break;
                    case 19:
                        message.createIntegrationToken = $root.fastcity.admin.CreateIntegrationToken.decode(reader, reader.uint32());
                        break;
                    case 20:
                        message.deleteIntegrationToken = $root.fastcity.admin.DeleteIntegrationToken.decode(reader, reader.uint32());
                        break;
                    case 21:
                        message.getIntegrationTokenList = $root.fastcity.admin.GetIntegrationTokenList.decode(reader, reader.uint32());
                        break;
                    case 22:
                        message.logon = $root.fastcity.admin.Logon.decode(reader, reader.uint32());
                        break;
                    case 34:
                        message.resetPassword = $root.fastcity.admin.ResetPassword.decode(reader, reader.uint32());
                        break;
                    case 23:
                        message.deleteGroup = $root.fastcity.admin.DeleteGroup.decode(reader, reader.uint32());
                        break;
                    case 24:
                        message.updateGroup = $root.fastcity.admin.UpdateGroup.decode(reader, reader.uint32());
                        break;
                    case 25:
                        message.getDeliveryStatDayList = $root.fastcity.admin.GetDeliveryStatDayList.decode(reader, reader.uint32());
                        break;
                    case 26:
                        message.getDeliveryStatList = $root.fastcity.admin.GetDeliveryStatList.decode(reader, reader.uint32());
                        break;
                    case 27:
                        message.getWorkingDayList = $root.fastcity.admin.GetWorkingDayList.decode(reader, reader.uint32());
                        break;
                    case 28:
                        message.getExternalSubCompanyIdList = $root.fastcity.admin.GetExternalSubCompanyIdList.decode(reader, reader.uint32());
                        break;
                    case 30:
                        message.getSubCompanyList = $root.fastcity.admin.GetSubCompanyList.decode(reader, reader.uint32());
                        break;
                    case 33:
                        message.deleteSubCompany = $root.fastcity.admin.DeleteSubCompany.decode(reader, reader.uint32());
                        break;
                    case 43:
                        message.createSubCompany = $root.fastcity.admin.CreateSubCompany.decode(reader, reader.uint32());
                        break;
                    case 36:
                        message.updateSubCompany = $root.fastcity.admin.UpdateSubCompany.decode(reader, reader.uint32());
                        break;
                    case 35:
                        message.activate = $root.fastcity.admin.Activate.decode(reader, reader.uint32());
                        break;
                    case 39:
                        message.createSubCompanyZone = $root.fastcity.admin.CreateSubCompanyZone.decode(reader, reader.uint32());
                        break;
                    case 46:
                        message.createSubCompanyZoneFromKml = $root.fastcity.admin.CreateSubCompanyZoneFromKML.decode(reader, reader.uint32());
                        break;
                    case 60:
                        message.deleteSubCompanyZone = $root.fastcity.admin.DeleteSubCompanyZone.decode(reader, reader.uint32());
                        break;
                    case 61:
                        message.recoverSubCompanyZone = $root.fastcity.admin.RecoverSubCompanyZone.decode(reader, reader.uint32());
                        break;
                    case 40:
                        message.updateSubCompanyZone = $root.fastcity.admin.UpdateSubCompanyZone.decode(reader, reader.uint32());
                        break;
                    case 54:
                        message.updateSubCompanyZoneUsingKml = $root.fastcity.admin.UpdateSubCompanyZoneUsingKML.decode(reader, reader.uint32());
                        break;
                    case 41:
                        message.getSubCompanyZone = $root.fastcity.admin.GetSubCompanyZone.decode(reader, reader.uint32());
                        break;
                    case 42:
                        message.getSubCompanyZoneList = $root.fastcity.admin.GetSubCompanyZoneList.decode(reader, reader.uint32());
                        break;
                    case 44:
                        message.createAvailableOrderStatuses = $root.fastcity.admin.CreateAvailableOrderStatuses.decode(reader, reader.uint32());
                        break;
                    case 45:
                        message.updateAvailableOrderStatuses = $root.fastcity.admin.UpdateAvailableOrderStatuses.decode(reader, reader.uint32());
                        break;
                    case 55:
                        message.getAvailableOrderStatuses = $root.fastcity.admin.GetAvailableOrderStatuses.decode(reader, reader.uint32());
                        break;
                    case 56:
                        message.getAvailableOrderStatusesList = $root.fastcity.admin.GetAvailableOrderStatusesList.decode(reader, reader.uint32());
                        break;
                    case 47:
                        message.createExternalAuthToken = $root.fastcity.admin.CreateExternalAuthToken.decode(reader, reader.uint32());
                        break;
                    case 48:
                        message.updateExternalAuthToken = $root.fastcity.admin.UpdateExternalAuthToken.decode(reader, reader.uint32());
                        break;
                    case 49:
                        message.deleteExternalAuthToken = $root.fastcity.admin.DeleteExternalAuthToken.decode(reader, reader.uint32());
                        break;
                    case 50:
                        message.getExternalAuthToken = $root.fastcity.admin.GetExternalAuthToken.decode(reader, reader.uint32());
                        break;
                    case 51:
                        message.getExternalAuthTokenList = $root.fastcity.admin.GetExternalAuthTokenList.decode(reader, reader.uint32());
                        break;
                    case 52:
                        message.bindSubCompanyToExternalAuthToken = $root.fastcity.admin.BindSubCompanyToExternalAuthToken.decode(reader, reader.uint32());
                        break;
                    case 53:
                        message.unbindSubCompanyFromExternalAuthToken = $root.fastcity.admin.UnbindSubCompanyFromExternalAuthToken.decode(reader, reader.uint32());
                        break;
                    case 57:
                        message.getBindedExternalAuthToken = $root.fastcity.admin.GetBindedExternalAuthToken.decode(reader, reader.uint32());
                        break;
                    case 58:
                        message.getBindedSubCompanies = $root.fastcity.admin.GetBindedSubCompanies.decode(reader, reader.uint32());
                        break;
                    case 59:
                        message.getServerVersion = $root.fastcity.admin.GetServerVersion.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ClientMessage message.
             * @function verify
             * @memberof fastcity.admin.ClientMessage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientMessage.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.requestId != null && message.hasOwnProperty("requestId"))
                    if (!$util.isInteger(message.requestId) && !(message.requestId && $util.isInteger(message.requestId.low) && $util.isInteger(message.requestId.high)))
                        return "requestId: integer|Long expected";
                if (message.getCurrentAdmin != null && message.hasOwnProperty("getCurrentAdmin")) {
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetCurrentAdmin.verify(message.getCurrentAdmin);
                        if (error)
                            return "getCurrentAdmin." + error;
                    }
                }
                if (message.getAdminList != null && message.hasOwnProperty("getAdminList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetAdminList.verify(message.getAdminList);
                        if (error)
                            return "getAdminList." + error;
                    }
                }
                if (message.createAdmin != null && message.hasOwnProperty("createAdmin")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CreateAdmin.verify(message.createAdmin);
                        if (error)
                            return "createAdmin." + error;
                    }
                }
                if (message.updateAdmin != null && message.hasOwnProperty("updateAdmin")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.UpdateAdmin.verify(message.updateAdmin);
                        if (error)
                            return "updateAdmin." + error;
                    }
                }
                if (message.deleteAdmin != null && message.hasOwnProperty("deleteAdmin")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.DeleteAdmin.verify(message.deleteAdmin);
                        if (error)
                            return "deleteAdmin." + error;
                    }
                }
                if (message.getCurrentCompany != null && message.hasOwnProperty("getCurrentCompany")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetCurrentCompany.verify(message.getCurrentCompany);
                        if (error)
                            return "getCurrentCompany." + error;
                    }
                }
                if (message.getCompanyList != null && message.hasOwnProperty("getCompanyList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetCompanyList.verify(message.getCompanyList);
                        if (error)
                            return "getCompanyList." + error;
                    }
                }
                if (message.createCompany != null && message.hasOwnProperty("createCompany")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CreateCompany.verify(message.createCompany);
                        if (error)
                            return "createCompany." + error;
                    }
                }
                if (message.updateCompany != null && message.hasOwnProperty("updateCompany")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.UpdateCompany.verify(message.updateCompany);
                        if (error)
                            return "updateCompany." + error;
                    }
                }
                if (message.deleteCompany != null && message.hasOwnProperty("deleteCompany")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.DeleteCompany.verify(message.deleteCompany);
                        if (error)
                            return "deleteCompany." + error;
                    }
                }
                if (message.getCourierList != null && message.hasOwnProperty("getCourierList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetCourierList.verify(message.getCourierList);
                        if (error)
                            return "getCourierList." + error;
                    }
                }
                if (message.createCourier != null && message.hasOwnProperty("createCourier")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CreateCourier.verify(message.createCourier);
                        if (error)
                            return "createCourier." + error;
                    }
                }
                if (message.updateCourier != null && message.hasOwnProperty("updateCourier")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.UpdateCourier.verify(message.updateCourier);
                        if (error)
                            return "updateCourier." + error;
                    }
                }
                if (message.setCourierGroup != null && message.hasOwnProperty("setCourierGroup")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.SetCourierGroup.verify(message.setCourierGroup);
                        if (error)
                            return "setCourierGroup." + error;
                    }
                }
                if (message.setCourierIsDeleted != null && message.hasOwnProperty("setCourierIsDeleted")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.SetCourierIsDeleted.verify(message.setCourierIsDeleted);
                        if (error)
                            return "setCourierIsDeleted." + error;
                    }
                }
                if (message.getGroupList != null && message.hasOwnProperty("getGroupList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetGroupList.verify(message.getGroupList);
                        if (error)
                            return "getGroupList." + error;
                    }
                }
                if (message.createGroup != null && message.hasOwnProperty("createGroup")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CreateGroup.verify(message.createGroup);
                        if (error)
                            return "createGroup." + error;
                    }
                }
                if (message.getLastLocationList != null && message.hasOwnProperty("getLastLocationList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetLastLocationList.verify(message.getLastLocationList);
                        if (error)
                            return "getLastLocationList." + error;
                    }
                }
                if (message.getLocationList != null && message.hasOwnProperty("getLocationList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetLocationList.verify(message.getLocationList);
                        if (error)
                            return "getLocationList." + error;
                    }
                }
                if (message.getOrderList != null && message.hasOwnProperty("getOrderList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetOrderList.verify(message.getOrderList);
                        if (error)
                            return "getOrderList." + error;
                    }
                }
                if (message.getConnectionStatusList != null && message.hasOwnProperty("getConnectionStatusList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetConnectionStatusList.verify(message.getConnectionStatusList);
                        if (error)
                            return "getConnectionStatusList." + error;
                    }
                }
                if (message.createIntegrationToken != null && message.hasOwnProperty("createIntegrationToken")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CreateIntegrationToken.verify(message.createIntegrationToken);
                        if (error)
                            return "createIntegrationToken." + error;
                    }
                }
                if (message.deleteIntegrationToken != null && message.hasOwnProperty("deleteIntegrationToken")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.DeleteIntegrationToken.verify(message.deleteIntegrationToken);
                        if (error)
                            return "deleteIntegrationToken." + error;
                    }
                }
                if (message.getIntegrationTokenList != null && message.hasOwnProperty("getIntegrationTokenList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetIntegrationTokenList.verify(message.getIntegrationTokenList);
                        if (error)
                            return "getIntegrationTokenList." + error;
                    }
                }
                if (message.logon != null && message.hasOwnProperty("logon")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.Logon.verify(message.logon);
                        if (error)
                            return "logon." + error;
                    }
                }
                if (message.resetPassword != null && message.hasOwnProperty("resetPassword")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.ResetPassword.verify(message.resetPassword);
                        if (error)
                            return "resetPassword." + error;
                    }
                }
                if (message.deleteGroup != null && message.hasOwnProperty("deleteGroup")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.DeleteGroup.verify(message.deleteGroup);
                        if (error)
                            return "deleteGroup." + error;
                    }
                }
                if (message.updateGroup != null && message.hasOwnProperty("updateGroup")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.UpdateGroup.verify(message.updateGroup);
                        if (error)
                            return "updateGroup." + error;
                    }
                }
                if (message.getDeliveryStatDayList != null && message.hasOwnProperty("getDeliveryStatDayList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetDeliveryStatDayList.verify(message.getDeliveryStatDayList);
                        if (error)
                            return "getDeliveryStatDayList." + error;
                    }
                }
                if (message.getDeliveryStatList != null && message.hasOwnProperty("getDeliveryStatList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetDeliveryStatList.verify(message.getDeliveryStatList);
                        if (error)
                            return "getDeliveryStatList." + error;
                    }
                }
                if (message.getWorkingDayList != null && message.hasOwnProperty("getWorkingDayList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetWorkingDayList.verify(message.getWorkingDayList);
                        if (error)
                            return "getWorkingDayList." + error;
                    }
                }
                if (message.getExternalSubCompanyIdList != null && message.hasOwnProperty("getExternalSubCompanyIdList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetExternalSubCompanyIdList.verify(message.getExternalSubCompanyIdList);
                        if (error)
                            return "getExternalSubCompanyIdList." + error;
                    }
                }
                if (message.getSubCompanyList != null && message.hasOwnProperty("getSubCompanyList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetSubCompanyList.verify(message.getSubCompanyList);
                        if (error)
                            return "getSubCompanyList." + error;
                    }
                }
                if (message.deleteSubCompany != null && message.hasOwnProperty("deleteSubCompany")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.DeleteSubCompany.verify(message.deleteSubCompany);
                        if (error)
                            return "deleteSubCompany." + error;
                    }
                }
                if (message.createSubCompany != null && message.hasOwnProperty("createSubCompany")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CreateSubCompany.verify(message.createSubCompany);
                        if (error)
                            return "createSubCompany." + error;
                    }
                }
                if (message.updateSubCompany != null && message.hasOwnProperty("updateSubCompany")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.UpdateSubCompany.verify(message.updateSubCompany);
                        if (error)
                            return "updateSubCompany." + error;
                    }
                }
                if (message.activate != null && message.hasOwnProperty("activate")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.Activate.verify(message.activate);
                        if (error)
                            return "activate." + error;
                    }
                }
                if (message.createSubCompanyZone != null && message.hasOwnProperty("createSubCompanyZone")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CreateSubCompanyZone.verify(message.createSubCompanyZone);
                        if (error)
                            return "createSubCompanyZone." + error;
                    }
                }
                if (message.createSubCompanyZoneFromKml != null && message.hasOwnProperty("createSubCompanyZoneFromKml")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CreateSubCompanyZoneFromKML.verify(message.createSubCompanyZoneFromKml);
                        if (error)
                            return "createSubCompanyZoneFromKml." + error;
                    }
                }
                if (message.deleteSubCompanyZone != null && message.hasOwnProperty("deleteSubCompanyZone")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.DeleteSubCompanyZone.verify(message.deleteSubCompanyZone);
                        if (error)
                            return "deleteSubCompanyZone." + error;
                    }
                }
                if (message.recoverSubCompanyZone != null && message.hasOwnProperty("recoverSubCompanyZone")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.RecoverSubCompanyZone.verify(message.recoverSubCompanyZone);
                        if (error)
                            return "recoverSubCompanyZone." + error;
                    }
                }
                if (message.updateSubCompanyZone != null && message.hasOwnProperty("updateSubCompanyZone")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.UpdateSubCompanyZone.verify(message.updateSubCompanyZone);
                        if (error)
                            return "updateSubCompanyZone." + error;
                    }
                }
                if (message.updateSubCompanyZoneUsingKml != null && message.hasOwnProperty("updateSubCompanyZoneUsingKml")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.UpdateSubCompanyZoneUsingKML.verify(message.updateSubCompanyZoneUsingKml);
                        if (error)
                            return "updateSubCompanyZoneUsingKml." + error;
                    }
                }
                if (message.getSubCompanyZone != null && message.hasOwnProperty("getSubCompanyZone")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetSubCompanyZone.verify(message.getSubCompanyZone);
                        if (error)
                            return "getSubCompanyZone." + error;
                    }
                }
                if (message.getSubCompanyZoneList != null && message.hasOwnProperty("getSubCompanyZoneList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetSubCompanyZoneList.verify(message.getSubCompanyZoneList);
                        if (error)
                            return "getSubCompanyZoneList." + error;
                    }
                }
                if (message.createAvailableOrderStatuses != null && message.hasOwnProperty("createAvailableOrderStatuses")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CreateAvailableOrderStatuses.verify(message.createAvailableOrderStatuses);
                        if (error)
                            return "createAvailableOrderStatuses." + error;
                    }
                }
                if (message.updateAvailableOrderStatuses != null && message.hasOwnProperty("updateAvailableOrderStatuses")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.UpdateAvailableOrderStatuses.verify(message.updateAvailableOrderStatuses);
                        if (error)
                            return "updateAvailableOrderStatuses." + error;
                    }
                }
                if (message.getAvailableOrderStatuses != null && message.hasOwnProperty("getAvailableOrderStatuses")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetAvailableOrderStatuses.verify(message.getAvailableOrderStatuses);
                        if (error)
                            return "getAvailableOrderStatuses." + error;
                    }
                }
                if (message.getAvailableOrderStatusesList != null && message.hasOwnProperty("getAvailableOrderStatusesList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetAvailableOrderStatusesList.verify(message.getAvailableOrderStatusesList);
                        if (error)
                            return "getAvailableOrderStatusesList." + error;
                    }
                }
                if (message.createExternalAuthToken != null && message.hasOwnProperty("createExternalAuthToken")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CreateExternalAuthToken.verify(message.createExternalAuthToken);
                        if (error)
                            return "createExternalAuthToken." + error;
                    }
                }
                if (message.updateExternalAuthToken != null && message.hasOwnProperty("updateExternalAuthToken")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.UpdateExternalAuthToken.verify(message.updateExternalAuthToken);
                        if (error)
                            return "updateExternalAuthToken." + error;
                    }
                }
                if (message.deleteExternalAuthToken != null && message.hasOwnProperty("deleteExternalAuthToken")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.DeleteExternalAuthToken.verify(message.deleteExternalAuthToken);
                        if (error)
                            return "deleteExternalAuthToken." + error;
                    }
                }
                if (message.getExternalAuthToken != null && message.hasOwnProperty("getExternalAuthToken")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetExternalAuthToken.verify(message.getExternalAuthToken);
                        if (error)
                            return "getExternalAuthToken." + error;
                    }
                }
                if (message.getExternalAuthTokenList != null && message.hasOwnProperty("getExternalAuthTokenList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetExternalAuthTokenList.verify(message.getExternalAuthTokenList);
                        if (error)
                            return "getExternalAuthTokenList." + error;
                    }
                }
                if (message.bindSubCompanyToExternalAuthToken != null && message.hasOwnProperty("bindSubCompanyToExternalAuthToken")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.BindSubCompanyToExternalAuthToken.verify(message.bindSubCompanyToExternalAuthToken);
                        if (error)
                            return "bindSubCompanyToExternalAuthToken." + error;
                    }
                }
                if (message.unbindSubCompanyFromExternalAuthToken != null && message.hasOwnProperty("unbindSubCompanyFromExternalAuthToken")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.UnbindSubCompanyFromExternalAuthToken.verify(message.unbindSubCompanyFromExternalAuthToken);
                        if (error)
                            return "unbindSubCompanyFromExternalAuthToken." + error;
                    }
                }
                if (message.getBindedExternalAuthToken != null && message.hasOwnProperty("getBindedExternalAuthToken")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetBindedExternalAuthToken.verify(message.getBindedExternalAuthToken);
                        if (error)
                            return "getBindedExternalAuthToken." + error;
                    }
                }
                if (message.getBindedSubCompanies != null && message.hasOwnProperty("getBindedSubCompanies")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetBindedSubCompanies.verify(message.getBindedSubCompanies);
                        if (error)
                            return "getBindedSubCompanies." + error;
                    }
                }
                if (message.getServerVersion != null && message.hasOwnProperty("getServerVersion")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GetServerVersion.verify(message.getServerVersion);
                        if (error)
                            return "getServerVersion." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ClientMessage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ClientMessage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ClientMessage} ClientMessage
             */
            ClientMessage.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ClientMessage)
                    return object;
                var message = new $root.fastcity.admin.ClientMessage();
                if (object.requestId != null)
                    if ($util.Long)
                        (message.requestId = $util.Long.fromValue(object.requestId)).unsigned = true;
                    else if (typeof object.requestId === "string")
                        message.requestId = parseInt(object.requestId, 10);
                    else if (typeof object.requestId === "number")
                        message.requestId = object.requestId;
                    else if (typeof object.requestId === "object")
                        message.requestId = new $util.LongBits(object.requestId.low >>> 0, object.requestId.high >>> 0).toNumber(true);
                if (object.getCurrentAdmin != null) {
                    if (typeof object.getCurrentAdmin !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getCurrentAdmin: object expected");
                    message.getCurrentAdmin = $root.fastcity.admin.GetCurrentAdmin.fromObject(object.getCurrentAdmin);
                }
                if (object.getAdminList != null) {
                    if (typeof object.getAdminList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getAdminList: object expected");
                    message.getAdminList = $root.fastcity.admin.GetAdminList.fromObject(object.getAdminList);
                }
                if (object.createAdmin != null) {
                    if (typeof object.createAdmin !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.createAdmin: object expected");
                    message.createAdmin = $root.fastcity.admin.CreateAdmin.fromObject(object.createAdmin);
                }
                if (object.updateAdmin != null) {
                    if (typeof object.updateAdmin !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.updateAdmin: object expected");
                    message.updateAdmin = $root.fastcity.admin.UpdateAdmin.fromObject(object.updateAdmin);
                }
                if (object.deleteAdmin != null) {
                    if (typeof object.deleteAdmin !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.deleteAdmin: object expected");
                    message.deleteAdmin = $root.fastcity.admin.DeleteAdmin.fromObject(object.deleteAdmin);
                }
                if (object.getCurrentCompany != null) {
                    if (typeof object.getCurrentCompany !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getCurrentCompany: object expected");
                    message.getCurrentCompany = $root.fastcity.admin.GetCurrentCompany.fromObject(object.getCurrentCompany);
                }
                if (object.getCompanyList != null) {
                    if (typeof object.getCompanyList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getCompanyList: object expected");
                    message.getCompanyList = $root.fastcity.admin.GetCompanyList.fromObject(object.getCompanyList);
                }
                if (object.createCompany != null) {
                    if (typeof object.createCompany !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.createCompany: object expected");
                    message.createCompany = $root.fastcity.admin.CreateCompany.fromObject(object.createCompany);
                }
                if (object.updateCompany != null) {
                    if (typeof object.updateCompany !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.updateCompany: object expected");
                    message.updateCompany = $root.fastcity.admin.UpdateCompany.fromObject(object.updateCompany);
                }
                if (object.deleteCompany != null) {
                    if (typeof object.deleteCompany !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.deleteCompany: object expected");
                    message.deleteCompany = $root.fastcity.admin.DeleteCompany.fromObject(object.deleteCompany);
                }
                if (object.getCourierList != null) {
                    if (typeof object.getCourierList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getCourierList: object expected");
                    message.getCourierList = $root.fastcity.admin.GetCourierList.fromObject(object.getCourierList);
                }
                if (object.createCourier != null) {
                    if (typeof object.createCourier !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.createCourier: object expected");
                    message.createCourier = $root.fastcity.admin.CreateCourier.fromObject(object.createCourier);
                }
                if (object.updateCourier != null) {
                    if (typeof object.updateCourier !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.updateCourier: object expected");
                    message.updateCourier = $root.fastcity.admin.UpdateCourier.fromObject(object.updateCourier);
                }
                if (object.setCourierGroup != null) {
                    if (typeof object.setCourierGroup !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.setCourierGroup: object expected");
                    message.setCourierGroup = $root.fastcity.admin.SetCourierGroup.fromObject(object.setCourierGroup);
                }
                if (object.setCourierIsDeleted != null) {
                    if (typeof object.setCourierIsDeleted !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.setCourierIsDeleted: object expected");
                    message.setCourierIsDeleted = $root.fastcity.admin.SetCourierIsDeleted.fromObject(object.setCourierIsDeleted);
                }
                if (object.getGroupList != null) {
                    if (typeof object.getGroupList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getGroupList: object expected");
                    message.getGroupList = $root.fastcity.admin.GetGroupList.fromObject(object.getGroupList);
                }
                if (object.createGroup != null) {
                    if (typeof object.createGroup !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.createGroup: object expected");
                    message.createGroup = $root.fastcity.admin.CreateGroup.fromObject(object.createGroup);
                }
                if (object.getLastLocationList != null) {
                    if (typeof object.getLastLocationList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getLastLocationList: object expected");
                    message.getLastLocationList = $root.fastcity.admin.GetLastLocationList.fromObject(object.getLastLocationList);
                }
                if (object.getLocationList != null) {
                    if (typeof object.getLocationList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getLocationList: object expected");
                    message.getLocationList = $root.fastcity.admin.GetLocationList.fromObject(object.getLocationList);
                }
                if (object.getOrderList != null) {
                    if (typeof object.getOrderList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getOrderList: object expected");
                    message.getOrderList = $root.fastcity.admin.GetOrderList.fromObject(object.getOrderList);
                }
                if (object.getConnectionStatusList != null) {
                    if (typeof object.getConnectionStatusList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getConnectionStatusList: object expected");
                    message.getConnectionStatusList = $root.fastcity.admin.GetConnectionStatusList.fromObject(object.getConnectionStatusList);
                }
                if (object.createIntegrationToken != null) {
                    if (typeof object.createIntegrationToken !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.createIntegrationToken: object expected");
                    message.createIntegrationToken = $root.fastcity.admin.CreateIntegrationToken.fromObject(object.createIntegrationToken);
                }
                if (object.deleteIntegrationToken != null) {
                    if (typeof object.deleteIntegrationToken !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.deleteIntegrationToken: object expected");
                    message.deleteIntegrationToken = $root.fastcity.admin.DeleteIntegrationToken.fromObject(object.deleteIntegrationToken);
                }
                if (object.getIntegrationTokenList != null) {
                    if (typeof object.getIntegrationTokenList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getIntegrationTokenList: object expected");
                    message.getIntegrationTokenList = $root.fastcity.admin.GetIntegrationTokenList.fromObject(object.getIntegrationTokenList);
                }
                if (object.logon != null) {
                    if (typeof object.logon !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.logon: object expected");
                    message.logon = $root.fastcity.admin.Logon.fromObject(object.logon);
                }
                if (object.resetPassword != null) {
                    if (typeof object.resetPassword !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.resetPassword: object expected");
                    message.resetPassword = $root.fastcity.admin.ResetPassword.fromObject(object.resetPassword);
                }
                if (object.deleteGroup != null) {
                    if (typeof object.deleteGroup !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.deleteGroup: object expected");
                    message.deleteGroup = $root.fastcity.admin.DeleteGroup.fromObject(object.deleteGroup);
                }
                if (object.updateGroup != null) {
                    if (typeof object.updateGroup !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.updateGroup: object expected");
                    message.updateGroup = $root.fastcity.admin.UpdateGroup.fromObject(object.updateGroup);
                }
                if (object.getDeliveryStatDayList != null) {
                    if (typeof object.getDeliveryStatDayList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getDeliveryStatDayList: object expected");
                    message.getDeliveryStatDayList = $root.fastcity.admin.GetDeliveryStatDayList.fromObject(object.getDeliveryStatDayList);
                }
                if (object.getDeliveryStatList != null) {
                    if (typeof object.getDeliveryStatList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getDeliveryStatList: object expected");
                    message.getDeliveryStatList = $root.fastcity.admin.GetDeliveryStatList.fromObject(object.getDeliveryStatList);
                }
                if (object.getWorkingDayList != null) {
                    if (typeof object.getWorkingDayList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getWorkingDayList: object expected");
                    message.getWorkingDayList = $root.fastcity.admin.GetWorkingDayList.fromObject(object.getWorkingDayList);
                }
                if (object.getExternalSubCompanyIdList != null) {
                    if (typeof object.getExternalSubCompanyIdList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getExternalSubCompanyIdList: object expected");
                    message.getExternalSubCompanyIdList = $root.fastcity.admin.GetExternalSubCompanyIdList.fromObject(object.getExternalSubCompanyIdList);
                }
                if (object.getSubCompanyList != null) {
                    if (typeof object.getSubCompanyList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getSubCompanyList: object expected");
                    message.getSubCompanyList = $root.fastcity.admin.GetSubCompanyList.fromObject(object.getSubCompanyList);
                }
                if (object.deleteSubCompany != null) {
                    if (typeof object.deleteSubCompany !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.deleteSubCompany: object expected");
                    message.deleteSubCompany = $root.fastcity.admin.DeleteSubCompany.fromObject(object.deleteSubCompany);
                }
                if (object.createSubCompany != null) {
                    if (typeof object.createSubCompany !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.createSubCompany: object expected");
                    message.createSubCompany = $root.fastcity.admin.CreateSubCompany.fromObject(object.createSubCompany);
                }
                if (object.updateSubCompany != null) {
                    if (typeof object.updateSubCompany !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.updateSubCompany: object expected");
                    message.updateSubCompany = $root.fastcity.admin.UpdateSubCompany.fromObject(object.updateSubCompany);
                }
                if (object.activate != null) {
                    if (typeof object.activate !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.activate: object expected");
                    message.activate = $root.fastcity.admin.Activate.fromObject(object.activate);
                }
                if (object.createSubCompanyZone != null) {
                    if (typeof object.createSubCompanyZone !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.createSubCompanyZone: object expected");
                    message.createSubCompanyZone = $root.fastcity.admin.CreateSubCompanyZone.fromObject(object.createSubCompanyZone);
                }
                if (object.createSubCompanyZoneFromKml != null) {
                    if (typeof object.createSubCompanyZoneFromKml !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.createSubCompanyZoneFromKml: object expected");
                    message.createSubCompanyZoneFromKml = $root.fastcity.admin.CreateSubCompanyZoneFromKML.fromObject(object.createSubCompanyZoneFromKml);
                }
                if (object.deleteSubCompanyZone != null) {
                    if (typeof object.deleteSubCompanyZone !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.deleteSubCompanyZone: object expected");
                    message.deleteSubCompanyZone = $root.fastcity.admin.DeleteSubCompanyZone.fromObject(object.deleteSubCompanyZone);
                }
                if (object.recoverSubCompanyZone != null) {
                    if (typeof object.recoverSubCompanyZone !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.recoverSubCompanyZone: object expected");
                    message.recoverSubCompanyZone = $root.fastcity.admin.RecoverSubCompanyZone.fromObject(object.recoverSubCompanyZone);
                }
                if (object.updateSubCompanyZone != null) {
                    if (typeof object.updateSubCompanyZone !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.updateSubCompanyZone: object expected");
                    message.updateSubCompanyZone = $root.fastcity.admin.UpdateSubCompanyZone.fromObject(object.updateSubCompanyZone);
                }
                if (object.updateSubCompanyZoneUsingKml != null) {
                    if (typeof object.updateSubCompanyZoneUsingKml !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.updateSubCompanyZoneUsingKml: object expected");
                    message.updateSubCompanyZoneUsingKml = $root.fastcity.admin.UpdateSubCompanyZoneUsingKML.fromObject(object.updateSubCompanyZoneUsingKml);
                }
                if (object.getSubCompanyZone != null) {
                    if (typeof object.getSubCompanyZone !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getSubCompanyZone: object expected");
                    message.getSubCompanyZone = $root.fastcity.admin.GetSubCompanyZone.fromObject(object.getSubCompanyZone);
                }
                if (object.getSubCompanyZoneList != null) {
                    if (typeof object.getSubCompanyZoneList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getSubCompanyZoneList: object expected");
                    message.getSubCompanyZoneList = $root.fastcity.admin.GetSubCompanyZoneList.fromObject(object.getSubCompanyZoneList);
                }
                if (object.createAvailableOrderStatuses != null) {
                    if (typeof object.createAvailableOrderStatuses !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.createAvailableOrderStatuses: object expected");
                    message.createAvailableOrderStatuses = $root.fastcity.admin.CreateAvailableOrderStatuses.fromObject(object.createAvailableOrderStatuses);
                }
                if (object.updateAvailableOrderStatuses != null) {
                    if (typeof object.updateAvailableOrderStatuses !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.updateAvailableOrderStatuses: object expected");
                    message.updateAvailableOrderStatuses = $root.fastcity.admin.UpdateAvailableOrderStatuses.fromObject(object.updateAvailableOrderStatuses);
                }
                if (object.getAvailableOrderStatuses != null) {
                    if (typeof object.getAvailableOrderStatuses !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getAvailableOrderStatuses: object expected");
                    message.getAvailableOrderStatuses = $root.fastcity.admin.GetAvailableOrderStatuses.fromObject(object.getAvailableOrderStatuses);
                }
                if (object.getAvailableOrderStatusesList != null) {
                    if (typeof object.getAvailableOrderStatusesList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getAvailableOrderStatusesList: object expected");
                    message.getAvailableOrderStatusesList = $root.fastcity.admin.GetAvailableOrderStatusesList.fromObject(object.getAvailableOrderStatusesList);
                }
                if (object.createExternalAuthToken != null) {
                    if (typeof object.createExternalAuthToken !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.createExternalAuthToken: object expected");
                    message.createExternalAuthToken = $root.fastcity.admin.CreateExternalAuthToken.fromObject(object.createExternalAuthToken);
                }
                if (object.updateExternalAuthToken != null) {
                    if (typeof object.updateExternalAuthToken !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.updateExternalAuthToken: object expected");
                    message.updateExternalAuthToken = $root.fastcity.admin.UpdateExternalAuthToken.fromObject(object.updateExternalAuthToken);
                }
                if (object.deleteExternalAuthToken != null) {
                    if (typeof object.deleteExternalAuthToken !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.deleteExternalAuthToken: object expected");
                    message.deleteExternalAuthToken = $root.fastcity.admin.DeleteExternalAuthToken.fromObject(object.deleteExternalAuthToken);
                }
                if (object.getExternalAuthToken != null) {
                    if (typeof object.getExternalAuthToken !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getExternalAuthToken: object expected");
                    message.getExternalAuthToken = $root.fastcity.admin.GetExternalAuthToken.fromObject(object.getExternalAuthToken);
                }
                if (object.getExternalAuthTokenList != null) {
                    if (typeof object.getExternalAuthTokenList !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getExternalAuthTokenList: object expected");
                    message.getExternalAuthTokenList = $root.fastcity.admin.GetExternalAuthTokenList.fromObject(object.getExternalAuthTokenList);
                }
                if (object.bindSubCompanyToExternalAuthToken != null) {
                    if (typeof object.bindSubCompanyToExternalAuthToken !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.bindSubCompanyToExternalAuthToken: object expected");
                    message.bindSubCompanyToExternalAuthToken = $root.fastcity.admin.BindSubCompanyToExternalAuthToken.fromObject(object.bindSubCompanyToExternalAuthToken);
                }
                if (object.unbindSubCompanyFromExternalAuthToken != null) {
                    if (typeof object.unbindSubCompanyFromExternalAuthToken !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.unbindSubCompanyFromExternalAuthToken: object expected");
                    message.unbindSubCompanyFromExternalAuthToken = $root.fastcity.admin.UnbindSubCompanyFromExternalAuthToken.fromObject(object.unbindSubCompanyFromExternalAuthToken);
                }
                if (object.getBindedExternalAuthToken != null) {
                    if (typeof object.getBindedExternalAuthToken !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getBindedExternalAuthToken: object expected");
                    message.getBindedExternalAuthToken = $root.fastcity.admin.GetBindedExternalAuthToken.fromObject(object.getBindedExternalAuthToken);
                }
                if (object.getBindedSubCompanies != null) {
                    if (typeof object.getBindedSubCompanies !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getBindedSubCompanies: object expected");
                    message.getBindedSubCompanies = $root.fastcity.admin.GetBindedSubCompanies.fromObject(object.getBindedSubCompanies);
                }
                if (object.getServerVersion != null) {
                    if (typeof object.getServerVersion !== "object")
                        throw TypeError(".fastcity.admin.ClientMessage.getServerVersion: object expected");
                    message.getServerVersion = $root.fastcity.admin.GetServerVersion.fromObject(object.getServerVersion);
                }
                return message;
            };

            /**
             * Creates a plain object from a ClientMessage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ClientMessage
             * @static
             * @param {fastcity.admin.ClientMessage} message ClientMessage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientMessage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.requestId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.requestId = options.longs === String ? "0" : 0;
                if (message.requestId != null && message.hasOwnProperty("requestId"))
                    if (typeof message.requestId === "number")
                        object.requestId = options.longs === String ? String(message.requestId) : message.requestId;
                    else
                        object.requestId = options.longs === String ? $util.Long.prototype.toString.call(message.requestId) : options.longs === Number ? new $util.LongBits(message.requestId.low >>> 0, message.requestId.high >>> 0).toNumber(true) : message.requestId;
                if (message.getCurrentAdmin != null && message.hasOwnProperty("getCurrentAdmin")) {
                    object.getCurrentAdmin = $root.fastcity.admin.GetCurrentAdmin.toObject(message.getCurrentAdmin, options);
                    if (options.oneofs)
                        object.body = "getCurrentAdmin";
                }
                if (message.getAdminList != null && message.hasOwnProperty("getAdminList")) {
                    object.getAdminList = $root.fastcity.admin.GetAdminList.toObject(message.getAdminList, options);
                    if (options.oneofs)
                        object.body = "getAdminList";
                }
                if (message.createAdmin != null && message.hasOwnProperty("createAdmin")) {
                    object.createAdmin = $root.fastcity.admin.CreateAdmin.toObject(message.createAdmin, options);
                    if (options.oneofs)
                        object.body = "createAdmin";
                }
                if (message.updateAdmin != null && message.hasOwnProperty("updateAdmin")) {
                    object.updateAdmin = $root.fastcity.admin.UpdateAdmin.toObject(message.updateAdmin, options);
                    if (options.oneofs)
                        object.body = "updateAdmin";
                }
                if (message.getCurrentCompany != null && message.hasOwnProperty("getCurrentCompany")) {
                    object.getCurrentCompany = $root.fastcity.admin.GetCurrentCompany.toObject(message.getCurrentCompany, options);
                    if (options.oneofs)
                        object.body = "getCurrentCompany";
                }
                if (message.getCompanyList != null && message.hasOwnProperty("getCompanyList")) {
                    object.getCompanyList = $root.fastcity.admin.GetCompanyList.toObject(message.getCompanyList, options);
                    if (options.oneofs)
                        object.body = "getCompanyList";
                }
                if (message.createCompany != null && message.hasOwnProperty("createCompany")) {
                    object.createCompany = $root.fastcity.admin.CreateCompany.toObject(message.createCompany, options);
                    if (options.oneofs)
                        object.body = "createCompany";
                }
                if (message.updateCompany != null && message.hasOwnProperty("updateCompany")) {
                    object.updateCompany = $root.fastcity.admin.UpdateCompany.toObject(message.updateCompany, options);
                    if (options.oneofs)
                        object.body = "updateCompany";
                }
                if (message.getCourierList != null && message.hasOwnProperty("getCourierList")) {
                    object.getCourierList = $root.fastcity.admin.GetCourierList.toObject(message.getCourierList, options);
                    if (options.oneofs)
                        object.body = "getCourierList";
                }
                if (message.createCourier != null && message.hasOwnProperty("createCourier")) {
                    object.createCourier = $root.fastcity.admin.CreateCourier.toObject(message.createCourier, options);
                    if (options.oneofs)
                        object.body = "createCourier";
                }
                if (message.getGroupList != null && message.hasOwnProperty("getGroupList")) {
                    object.getGroupList = $root.fastcity.admin.GetGroupList.toObject(message.getGroupList, options);
                    if (options.oneofs)
                        object.body = "getGroupList";
                }
                if (message.createGroup != null && message.hasOwnProperty("createGroup")) {
                    object.createGroup = $root.fastcity.admin.CreateGroup.toObject(message.createGroup, options);
                    if (options.oneofs)
                        object.body = "createGroup";
                }
                if (message.getLastLocationList != null && message.hasOwnProperty("getLastLocationList")) {
                    object.getLastLocationList = $root.fastcity.admin.GetLastLocationList.toObject(message.getLastLocationList, options);
                    if (options.oneofs)
                        object.body = "getLastLocationList";
                }
                if (message.getLocationList != null && message.hasOwnProperty("getLocationList")) {
                    object.getLocationList = $root.fastcity.admin.GetLocationList.toObject(message.getLocationList, options);
                    if (options.oneofs)
                        object.body = "getLocationList";
                }
                if (message.getOrderList != null && message.hasOwnProperty("getOrderList")) {
                    object.getOrderList = $root.fastcity.admin.GetOrderList.toObject(message.getOrderList, options);
                    if (options.oneofs)
                        object.body = "getOrderList";
                }
                if (message.setCourierGroup != null && message.hasOwnProperty("setCourierGroup")) {
                    object.setCourierGroup = $root.fastcity.admin.SetCourierGroup.toObject(message.setCourierGroup, options);
                    if (options.oneofs)
                        object.body = "setCourierGroup";
                }
                if (message.getConnectionStatusList != null && message.hasOwnProperty("getConnectionStatusList")) {
                    object.getConnectionStatusList = $root.fastcity.admin.GetConnectionStatusList.toObject(message.getConnectionStatusList, options);
                    if (options.oneofs)
                        object.body = "getConnectionStatusList";
                }
                if (message.createIntegrationToken != null && message.hasOwnProperty("createIntegrationToken")) {
                    object.createIntegrationToken = $root.fastcity.admin.CreateIntegrationToken.toObject(message.createIntegrationToken, options);
                    if (options.oneofs)
                        object.body = "createIntegrationToken";
                }
                if (message.deleteIntegrationToken != null && message.hasOwnProperty("deleteIntegrationToken")) {
                    object.deleteIntegrationToken = $root.fastcity.admin.DeleteIntegrationToken.toObject(message.deleteIntegrationToken, options);
                    if (options.oneofs)
                        object.body = "deleteIntegrationToken";
                }
                if (message.getIntegrationTokenList != null && message.hasOwnProperty("getIntegrationTokenList")) {
                    object.getIntegrationTokenList = $root.fastcity.admin.GetIntegrationTokenList.toObject(message.getIntegrationTokenList, options);
                    if (options.oneofs)
                        object.body = "getIntegrationTokenList";
                }
                if (message.logon != null && message.hasOwnProperty("logon")) {
                    object.logon = $root.fastcity.admin.Logon.toObject(message.logon, options);
                    if (options.oneofs)
                        object.body = "logon";
                }
                if (message.deleteGroup != null && message.hasOwnProperty("deleteGroup")) {
                    object.deleteGroup = $root.fastcity.admin.DeleteGroup.toObject(message.deleteGroup, options);
                    if (options.oneofs)
                        object.body = "deleteGroup";
                }
                if (message.updateGroup != null && message.hasOwnProperty("updateGroup")) {
                    object.updateGroup = $root.fastcity.admin.UpdateGroup.toObject(message.updateGroup, options);
                    if (options.oneofs)
                        object.body = "updateGroup";
                }
                if (message.getDeliveryStatDayList != null && message.hasOwnProperty("getDeliveryStatDayList")) {
                    object.getDeliveryStatDayList = $root.fastcity.admin.GetDeliveryStatDayList.toObject(message.getDeliveryStatDayList, options);
                    if (options.oneofs)
                        object.body = "getDeliveryStatDayList";
                }
                if (message.getDeliveryStatList != null && message.hasOwnProperty("getDeliveryStatList")) {
                    object.getDeliveryStatList = $root.fastcity.admin.GetDeliveryStatList.toObject(message.getDeliveryStatList, options);
                    if (options.oneofs)
                        object.body = "getDeliveryStatList";
                }
                if (message.getWorkingDayList != null && message.hasOwnProperty("getWorkingDayList")) {
                    object.getWorkingDayList = $root.fastcity.admin.GetWorkingDayList.toObject(message.getWorkingDayList, options);
                    if (options.oneofs)
                        object.body = "getWorkingDayList";
                }
                if (message.getExternalSubCompanyIdList != null && message.hasOwnProperty("getExternalSubCompanyIdList")) {
                    object.getExternalSubCompanyIdList = $root.fastcity.admin.GetExternalSubCompanyIdList.toObject(message.getExternalSubCompanyIdList, options);
                    if (options.oneofs)
                        object.body = "getExternalSubCompanyIdList";
                }
                if (message.setCourierIsDeleted != null && message.hasOwnProperty("setCourierIsDeleted")) {
                    object.setCourierIsDeleted = $root.fastcity.admin.SetCourierIsDeleted.toObject(message.setCourierIsDeleted, options);
                    if (options.oneofs)
                        object.body = "setCourierIsDeleted";
                }
                if (message.getSubCompanyList != null && message.hasOwnProperty("getSubCompanyList")) {
                    object.getSubCompanyList = $root.fastcity.admin.GetSubCompanyList.toObject(message.getSubCompanyList, options);
                    if (options.oneofs)
                        object.body = "getSubCompanyList";
                }
                if (message.deleteAdmin != null && message.hasOwnProperty("deleteAdmin")) {
                    object.deleteAdmin = $root.fastcity.admin.DeleteAdmin.toObject(message.deleteAdmin, options);
                    if (options.oneofs)
                        object.body = "deleteAdmin";
                }
                if (message.deleteCompany != null && message.hasOwnProperty("deleteCompany")) {
                    object.deleteCompany = $root.fastcity.admin.DeleteCompany.toObject(message.deleteCompany, options);
                    if (options.oneofs)
                        object.body = "deleteCompany";
                }
                if (message.deleteSubCompany != null && message.hasOwnProperty("deleteSubCompany")) {
                    object.deleteSubCompany = $root.fastcity.admin.DeleteSubCompany.toObject(message.deleteSubCompany, options);
                    if (options.oneofs)
                        object.body = "deleteSubCompany";
                }
                if (message.resetPassword != null && message.hasOwnProperty("resetPassword")) {
                    object.resetPassword = $root.fastcity.admin.ResetPassword.toObject(message.resetPassword, options);
                    if (options.oneofs)
                        object.body = "resetPassword";
                }
                if (message.activate != null && message.hasOwnProperty("activate")) {
                    object.activate = $root.fastcity.admin.Activate.toObject(message.activate, options);
                    if (options.oneofs)
                        object.body = "activate";
                }
                if (message.updateSubCompany != null && message.hasOwnProperty("updateSubCompany")) {
                    object.updateSubCompany = $root.fastcity.admin.UpdateSubCompany.toObject(message.updateSubCompany, options);
                    if (options.oneofs)
                        object.body = "updateSubCompany";
                }
                if (message.updateCourier != null && message.hasOwnProperty("updateCourier")) {
                    object.updateCourier = $root.fastcity.admin.UpdateCourier.toObject(message.updateCourier, options);
                    if (options.oneofs)
                        object.body = "updateCourier";
                }
                if (message.createSubCompanyZone != null && message.hasOwnProperty("createSubCompanyZone")) {
                    object.createSubCompanyZone = $root.fastcity.admin.CreateSubCompanyZone.toObject(message.createSubCompanyZone, options);
                    if (options.oneofs)
                        object.body = "createSubCompanyZone";
                }
                if (message.updateSubCompanyZone != null && message.hasOwnProperty("updateSubCompanyZone")) {
                    object.updateSubCompanyZone = $root.fastcity.admin.UpdateSubCompanyZone.toObject(message.updateSubCompanyZone, options);
                    if (options.oneofs)
                        object.body = "updateSubCompanyZone";
                }
                if (message.getSubCompanyZone != null && message.hasOwnProperty("getSubCompanyZone")) {
                    object.getSubCompanyZone = $root.fastcity.admin.GetSubCompanyZone.toObject(message.getSubCompanyZone, options);
                    if (options.oneofs)
                        object.body = "getSubCompanyZone";
                }
                if (message.getSubCompanyZoneList != null && message.hasOwnProperty("getSubCompanyZoneList")) {
                    object.getSubCompanyZoneList = $root.fastcity.admin.GetSubCompanyZoneList.toObject(message.getSubCompanyZoneList, options);
                    if (options.oneofs)
                        object.body = "getSubCompanyZoneList";
                }
                if (message.createSubCompany != null && message.hasOwnProperty("createSubCompany")) {
                    object.createSubCompany = $root.fastcity.admin.CreateSubCompany.toObject(message.createSubCompany, options);
                    if (options.oneofs)
                        object.body = "createSubCompany";
                }
                if (message.createAvailableOrderStatuses != null && message.hasOwnProperty("createAvailableOrderStatuses")) {
                    object.createAvailableOrderStatuses = $root.fastcity.admin.CreateAvailableOrderStatuses.toObject(message.createAvailableOrderStatuses, options);
                    if (options.oneofs)
                        object.body = "createAvailableOrderStatuses";
                }
                if (message.updateAvailableOrderStatuses != null && message.hasOwnProperty("updateAvailableOrderStatuses")) {
                    object.updateAvailableOrderStatuses = $root.fastcity.admin.UpdateAvailableOrderStatuses.toObject(message.updateAvailableOrderStatuses, options);
                    if (options.oneofs)
                        object.body = "updateAvailableOrderStatuses";
                }
                if (message.createSubCompanyZoneFromKml != null && message.hasOwnProperty("createSubCompanyZoneFromKml")) {
                    object.createSubCompanyZoneFromKml = $root.fastcity.admin.CreateSubCompanyZoneFromKML.toObject(message.createSubCompanyZoneFromKml, options);
                    if (options.oneofs)
                        object.body = "createSubCompanyZoneFromKml";
                }
                if (message.createExternalAuthToken != null && message.hasOwnProperty("createExternalAuthToken")) {
                    object.createExternalAuthToken = $root.fastcity.admin.CreateExternalAuthToken.toObject(message.createExternalAuthToken, options);
                    if (options.oneofs)
                        object.body = "createExternalAuthToken";
                }
                if (message.updateExternalAuthToken != null && message.hasOwnProperty("updateExternalAuthToken")) {
                    object.updateExternalAuthToken = $root.fastcity.admin.UpdateExternalAuthToken.toObject(message.updateExternalAuthToken, options);
                    if (options.oneofs)
                        object.body = "updateExternalAuthToken";
                }
                if (message.deleteExternalAuthToken != null && message.hasOwnProperty("deleteExternalAuthToken")) {
                    object.deleteExternalAuthToken = $root.fastcity.admin.DeleteExternalAuthToken.toObject(message.deleteExternalAuthToken, options);
                    if (options.oneofs)
                        object.body = "deleteExternalAuthToken";
                }
                if (message.getExternalAuthToken != null && message.hasOwnProperty("getExternalAuthToken")) {
                    object.getExternalAuthToken = $root.fastcity.admin.GetExternalAuthToken.toObject(message.getExternalAuthToken, options);
                    if (options.oneofs)
                        object.body = "getExternalAuthToken";
                }
                if (message.getExternalAuthTokenList != null && message.hasOwnProperty("getExternalAuthTokenList")) {
                    object.getExternalAuthTokenList = $root.fastcity.admin.GetExternalAuthTokenList.toObject(message.getExternalAuthTokenList, options);
                    if (options.oneofs)
                        object.body = "getExternalAuthTokenList";
                }
                if (message.bindSubCompanyToExternalAuthToken != null && message.hasOwnProperty("bindSubCompanyToExternalAuthToken")) {
                    object.bindSubCompanyToExternalAuthToken = $root.fastcity.admin.BindSubCompanyToExternalAuthToken.toObject(message.bindSubCompanyToExternalAuthToken, options);
                    if (options.oneofs)
                        object.body = "bindSubCompanyToExternalAuthToken";
                }
                if (message.unbindSubCompanyFromExternalAuthToken != null && message.hasOwnProperty("unbindSubCompanyFromExternalAuthToken")) {
                    object.unbindSubCompanyFromExternalAuthToken = $root.fastcity.admin.UnbindSubCompanyFromExternalAuthToken.toObject(message.unbindSubCompanyFromExternalAuthToken, options);
                    if (options.oneofs)
                        object.body = "unbindSubCompanyFromExternalAuthToken";
                }
                if (message.updateSubCompanyZoneUsingKml != null && message.hasOwnProperty("updateSubCompanyZoneUsingKml")) {
                    object.updateSubCompanyZoneUsingKml = $root.fastcity.admin.UpdateSubCompanyZoneUsingKML.toObject(message.updateSubCompanyZoneUsingKml, options);
                    if (options.oneofs)
                        object.body = "updateSubCompanyZoneUsingKml";
                }
                if (message.getAvailableOrderStatuses != null && message.hasOwnProperty("getAvailableOrderStatuses")) {
                    object.getAvailableOrderStatuses = $root.fastcity.admin.GetAvailableOrderStatuses.toObject(message.getAvailableOrderStatuses, options);
                    if (options.oneofs)
                        object.body = "getAvailableOrderStatuses";
                }
                if (message.getAvailableOrderStatusesList != null && message.hasOwnProperty("getAvailableOrderStatusesList")) {
                    object.getAvailableOrderStatusesList = $root.fastcity.admin.GetAvailableOrderStatusesList.toObject(message.getAvailableOrderStatusesList, options);
                    if (options.oneofs)
                        object.body = "getAvailableOrderStatusesList";
                }
                if (message.getBindedExternalAuthToken != null && message.hasOwnProperty("getBindedExternalAuthToken")) {
                    object.getBindedExternalAuthToken = $root.fastcity.admin.GetBindedExternalAuthToken.toObject(message.getBindedExternalAuthToken, options);
                    if (options.oneofs)
                        object.body = "getBindedExternalAuthToken";
                }
                if (message.getBindedSubCompanies != null && message.hasOwnProperty("getBindedSubCompanies")) {
                    object.getBindedSubCompanies = $root.fastcity.admin.GetBindedSubCompanies.toObject(message.getBindedSubCompanies, options);
                    if (options.oneofs)
                        object.body = "getBindedSubCompanies";
                }
                if (message.getServerVersion != null && message.hasOwnProperty("getServerVersion")) {
                    object.getServerVersion = $root.fastcity.admin.GetServerVersion.toObject(message.getServerVersion, options);
                    if (options.oneofs)
                        object.body = "getServerVersion";
                }
                if (message.deleteSubCompanyZone != null && message.hasOwnProperty("deleteSubCompanyZone")) {
                    object.deleteSubCompanyZone = $root.fastcity.admin.DeleteSubCompanyZone.toObject(message.deleteSubCompanyZone, options);
                    if (options.oneofs)
                        object.body = "deleteSubCompanyZone";
                }
                if (message.recoverSubCompanyZone != null && message.hasOwnProperty("recoverSubCompanyZone")) {
                    object.recoverSubCompanyZone = $root.fastcity.admin.RecoverSubCompanyZone.toObject(message.recoverSubCompanyZone, options);
                    if (options.oneofs)
                        object.body = "recoverSubCompanyZone";
                }
                return object;
            };

            /**
             * Converts this ClientMessage to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ClientMessage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientMessage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ClientMessage;
        })();

        admin.ServerMessage = (function() {

            /**
             * Properties of a ServerMessage.
             * @memberof fastcity.admin
             * @interface IServerMessage
             * @property {number|Long|null} [requestId] ServerMessage requestId
             * @property {fastcity.admin.IResult|null} [result] ServerMessage result
             * @property {fastcity.admin.IAdmin|null} [admin] ServerMessage admin
             * @property {fastcity.admin.IAdminList|null} [adminList] ServerMessage adminList
             * @property {fastcity.admin.ICompany|null} [company] ServerMessage company
             * @property {fastcity.admin.ICompanyList|null} [companyList] ServerMessage companyList
             * @property {fastcity.admin.ICourier|null} [courier] ServerMessage courier
             * @property {fastcity.admin.ICourierList|null} [courierList] ServerMessage courierList
             * @property {fastcity.admin.IGroup|null} [group] ServerMessage group
             * @property {fastcity.admin.IGroupList|null} [groupList] ServerMessage groupList
             * @property {fastcity.admin.ILocationList|null} [locationList] ServerMessage locationList
             * @property {fastcity.admin.IOrderList|null} [orderList] ServerMessage orderList
             * @property {fastcity.admin.IConnectionStatus|null} [connectionStatus] ServerMessage connectionStatus
             * @property {fastcity.admin.IConnectionStatusList|null} [connectionStatusList] ServerMessage connectionStatusList
             * @property {fastcity.admin.IIntegrationToken|null} [integrationToken] ServerMessage integrationToken
             * @property {fastcity.admin.IIntegrationTokenList|null} [integrationTokenList] ServerMessage integrationTokenList
             * @property {fastcity.admin.IChangedCourierIdList|null} [changedCourierIdList] ServerMessage changedCourierIdList
             * @property {fastcity.admin.IChangedOrderIdList|null} [changedOrderIdList] ServerMessage changedOrderIdList
             * @property {fastcity.admin.ILogonResult|null} [logonResult] ServerMessage logonResult
             * @property {fastcity.admin.IChangedLastLocation|null} [changedLastLocation] ServerMessage changedLastLocation
             * @property {fastcity.admin.IDeliveryStatDayList|null} [deliveryStatDayList] ServerMessage deliveryStatDayList
             * @property {fastcity.admin.IDeliveryStatList|null} [deliveryStatList] ServerMessage deliveryStatList
             * @property {fastcity.admin.IWorkingDayList|null} [workingDayList] ServerMessage workingDayList
             * @property {fastcity.admin.IExternalSubCompanyIdList|null} [externalSubCompanyIdList] ServerMessage externalSubCompanyIdList
             * @property {fastcity.admin.ISubCompany|null} [subCompany] ServerMessage subCompany
             * @property {fastcity.admin.ISubCompanyList|null} [getSubCompanyList] ServerMessage getSubCompanyList
             * @property {fastcity.admin.ISubCompanyZone|null} [subCompanyZone] ServerMessage subCompanyZone
             * @property {fastcity.admin.ISubCompanyZoneList|null} [subCompanyZoneList] ServerMessage subCompanyZoneList
             * @property {fastcity.admin.IAvailableOrderStatuses|null} [availableOrderStatuses] ServerMessage availableOrderStatuses
             * @property {fastcity.admin.IAvailableOrderStatusesList|null} [availableOrderStatusesList] ServerMessage availableOrderStatusesList
             * @property {fastcity.admin.IExternalAuthToken|null} [externalAuthToken] ServerMessage externalAuthToken
             * @property {fastcity.admin.IExternalAuthTokenList|null} [externalAuthTokenList] ServerMessage externalAuthTokenList
             * @property {fastcity.admin.IOptionalExternalAuthToken|null} [optionalExternalAuthToken] ServerMessage optionalExternalAuthToken
             * @property {fastcity.admin.IVersion|null} [serverVersion] ServerMessage serverVersion
             */

            /**
             * Constructs a new ServerMessage.
             * @memberof fastcity.admin
             * @classdesc Represents a ServerMessage.
             * @implements IServerMessage
             * @constructor
             * @param {fastcity.admin.IServerMessage=} [properties] Properties to set
             */
            function ServerMessage(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServerMessage requestId.
             * @member {number|Long} requestId
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.requestId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ServerMessage result.
             * @member {fastcity.admin.IResult|null|undefined} result
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.result = null;

            /**
             * ServerMessage admin.
             * @member {fastcity.admin.IAdmin|null|undefined} admin
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.admin = null;

            /**
             * ServerMessage adminList.
             * @member {fastcity.admin.IAdminList|null|undefined} adminList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.adminList = null;

            /**
             * ServerMessage company.
             * @member {fastcity.admin.ICompany|null|undefined} company
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.company = null;

            /**
             * ServerMessage companyList.
             * @member {fastcity.admin.ICompanyList|null|undefined} companyList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.companyList = null;

            /**
             * ServerMessage courier.
             * @member {fastcity.admin.ICourier|null|undefined} courier
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.courier = null;

            /**
             * ServerMessage courierList.
             * @member {fastcity.admin.ICourierList|null|undefined} courierList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.courierList = null;

            /**
             * ServerMessage group.
             * @member {fastcity.admin.IGroup|null|undefined} group
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.group = null;

            /**
             * ServerMessage groupList.
             * @member {fastcity.admin.IGroupList|null|undefined} groupList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.groupList = null;

            /**
             * ServerMessage locationList.
             * @member {fastcity.admin.ILocationList|null|undefined} locationList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.locationList = null;

            /**
             * ServerMessage orderList.
             * @member {fastcity.admin.IOrderList|null|undefined} orderList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.orderList = null;

            /**
             * ServerMessage connectionStatus.
             * @member {fastcity.admin.IConnectionStatus|null|undefined} connectionStatus
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.connectionStatus = null;

            /**
             * ServerMessage connectionStatusList.
             * @member {fastcity.admin.IConnectionStatusList|null|undefined} connectionStatusList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.connectionStatusList = null;

            /**
             * ServerMessage integrationToken.
             * @member {fastcity.admin.IIntegrationToken|null|undefined} integrationToken
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.integrationToken = null;

            /**
             * ServerMessage integrationTokenList.
             * @member {fastcity.admin.IIntegrationTokenList|null|undefined} integrationTokenList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.integrationTokenList = null;

            /**
             * ServerMessage changedCourierIdList.
             * @member {fastcity.admin.IChangedCourierIdList|null|undefined} changedCourierIdList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.changedCourierIdList = null;

            /**
             * ServerMessage changedOrderIdList.
             * @member {fastcity.admin.IChangedOrderIdList|null|undefined} changedOrderIdList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.changedOrderIdList = null;

            /**
             * ServerMessage logonResult.
             * @member {fastcity.admin.ILogonResult|null|undefined} logonResult
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.logonResult = null;

            /**
             * ServerMessage changedLastLocation.
             * @member {fastcity.admin.IChangedLastLocation|null|undefined} changedLastLocation
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.changedLastLocation = null;

            /**
             * ServerMessage deliveryStatDayList.
             * @member {fastcity.admin.IDeliveryStatDayList|null|undefined} deliveryStatDayList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.deliveryStatDayList = null;

            /**
             * ServerMessage deliveryStatList.
             * @member {fastcity.admin.IDeliveryStatList|null|undefined} deliveryStatList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.deliveryStatList = null;

            /**
             * ServerMessage workingDayList.
             * @member {fastcity.admin.IWorkingDayList|null|undefined} workingDayList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.workingDayList = null;

            /**
             * ServerMessage externalSubCompanyIdList.
             * @member {fastcity.admin.IExternalSubCompanyIdList|null|undefined} externalSubCompanyIdList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.externalSubCompanyIdList = null;

            /**
             * ServerMessage subCompany.
             * @member {fastcity.admin.ISubCompany|null|undefined} subCompany
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.subCompany = null;

            /**
             * ServerMessage getSubCompanyList.
             * @member {fastcity.admin.ISubCompanyList|null|undefined} getSubCompanyList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.getSubCompanyList = null;

            /**
             * ServerMessage subCompanyZone.
             * @member {fastcity.admin.ISubCompanyZone|null|undefined} subCompanyZone
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.subCompanyZone = null;

            /**
             * ServerMessage subCompanyZoneList.
             * @member {fastcity.admin.ISubCompanyZoneList|null|undefined} subCompanyZoneList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.subCompanyZoneList = null;

            /**
             * ServerMessage availableOrderStatuses.
             * @member {fastcity.admin.IAvailableOrderStatuses|null|undefined} availableOrderStatuses
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.availableOrderStatuses = null;

            /**
             * ServerMessage availableOrderStatusesList.
             * @member {fastcity.admin.IAvailableOrderStatusesList|null|undefined} availableOrderStatusesList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.availableOrderStatusesList = null;

            /**
             * ServerMessage externalAuthToken.
             * @member {fastcity.admin.IExternalAuthToken|null|undefined} externalAuthToken
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.externalAuthToken = null;

            /**
             * ServerMessage externalAuthTokenList.
             * @member {fastcity.admin.IExternalAuthTokenList|null|undefined} externalAuthTokenList
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.externalAuthTokenList = null;

            /**
             * ServerMessage optionalExternalAuthToken.
             * @member {fastcity.admin.IOptionalExternalAuthToken|null|undefined} optionalExternalAuthToken
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.optionalExternalAuthToken = null;

            /**
             * ServerMessage serverVersion.
             * @member {fastcity.admin.IVersion|null|undefined} serverVersion
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            ServerMessage.prototype.serverVersion = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * ServerMessage body.
             * @member {"result"|"admin"|"adminList"|"company"|"companyList"|"courier"|"courierList"|"group"|"groupList"|"locationList"|"orderList"|"connectionStatus"|"connectionStatusList"|"integrationToken"|"integrationTokenList"|"changedCourierIdList"|"changedOrderIdList"|"logonResult"|"changedLastLocation"|"deliveryStatDayList"|"deliveryStatList"|"workingDayList"|"externalSubCompanyIdList"|"subCompany"|"getSubCompanyList"|"subCompanyZone"|"subCompanyZoneList"|"availableOrderStatuses"|"availableOrderStatusesList"|"externalAuthToken"|"externalAuthTokenList"|"optionalExternalAuthToken"|"serverVersion"|undefined} body
             * @memberof fastcity.admin.ServerMessage
             * @instance
             */
            Object.defineProperty(ServerMessage.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["result", "admin", "adminList", "company", "companyList", "courier", "courierList", "group", "groupList", "locationList", "orderList", "connectionStatus", "connectionStatusList", "integrationToken", "integrationTokenList", "changedCourierIdList", "changedOrderIdList", "logonResult", "changedLastLocation", "deliveryStatDayList", "deliveryStatList", "workingDayList", "externalSubCompanyIdList", "subCompany", "getSubCompanyList", "subCompanyZone", "subCompanyZoneList", "availableOrderStatuses", "availableOrderStatusesList", "externalAuthToken", "externalAuthTokenList", "optionalExternalAuthToken", "serverVersion"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new ServerMessage instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ServerMessage
             * @static
             * @param {fastcity.admin.IServerMessage=} [properties] Properties to set
             * @returns {fastcity.admin.ServerMessage} ServerMessage instance
             */
            ServerMessage.create = function create(properties) {
                return new ServerMessage(properties);
            };

            /**
             * Encodes the specified ServerMessage message. Does not implicitly {@link fastcity.admin.ServerMessage.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ServerMessage
             * @static
             * @param {fastcity.admin.IServerMessage} message ServerMessage message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerMessage.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.requestId);
                if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                    $root.fastcity.admin.Result.encode(message.result, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.admin != null && Object.hasOwnProperty.call(message, "admin"))
                    $root.fastcity.admin.Admin.encode(message.admin, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.adminList != null && Object.hasOwnProperty.call(message, "adminList"))
                    $root.fastcity.admin.AdminList.encode(message.adminList, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.company != null && Object.hasOwnProperty.call(message, "company"))
                    $root.fastcity.admin.Company.encode(message.company, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.companyList != null && Object.hasOwnProperty.call(message, "companyList"))
                    $root.fastcity.admin.CompanyList.encode(message.companyList, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.courier != null && Object.hasOwnProperty.call(message, "courier"))
                    $root.fastcity.admin.Courier.encode(message.courier, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.courierList != null && Object.hasOwnProperty.call(message, "courierList"))
                    $root.fastcity.admin.CourierList.encode(message.courierList, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                    $root.fastcity.admin.Group.encode(message.group, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.groupList != null && Object.hasOwnProperty.call(message, "groupList"))
                    $root.fastcity.admin.GroupList.encode(message.groupList, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.locationList != null && Object.hasOwnProperty.call(message, "locationList"))
                    $root.fastcity.admin.LocationList.encode(message.locationList, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.orderList != null && Object.hasOwnProperty.call(message, "orderList"))
                    $root.fastcity.admin.OrderList.encode(message.orderList, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.connectionStatus != null && Object.hasOwnProperty.call(message, "connectionStatus"))
                    $root.fastcity.admin.ConnectionStatus.encode(message.connectionStatus, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.connectionStatusList != null && Object.hasOwnProperty.call(message, "connectionStatusList"))
                    $root.fastcity.admin.ConnectionStatusList.encode(message.connectionStatusList, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.integrationToken != null && Object.hasOwnProperty.call(message, "integrationToken"))
                    $root.fastcity.admin.IntegrationToken.encode(message.integrationToken, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.integrationTokenList != null && Object.hasOwnProperty.call(message, "integrationTokenList"))
                    $root.fastcity.admin.IntegrationTokenList.encode(message.integrationTokenList, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                if (message.changedCourierIdList != null && Object.hasOwnProperty.call(message, "changedCourierIdList"))
                    $root.fastcity.admin.ChangedCourierIdList.encode(message.changedCourierIdList, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                if (message.changedOrderIdList != null && Object.hasOwnProperty.call(message, "changedOrderIdList"))
                    $root.fastcity.admin.ChangedOrderIdList.encode(message.changedOrderIdList, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                if (message.logonResult != null && Object.hasOwnProperty.call(message, "logonResult"))
                    $root.fastcity.admin.LogonResult.encode(message.logonResult, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                if (message.changedLastLocation != null && Object.hasOwnProperty.call(message, "changedLastLocation"))
                    $root.fastcity.admin.ChangedLastLocation.encode(message.changedLastLocation, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                if (message.deliveryStatDayList != null && Object.hasOwnProperty.call(message, "deliveryStatDayList"))
                    $root.fastcity.admin.DeliveryStatDayList.encode(message.deliveryStatDayList, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                if (message.deliveryStatList != null && Object.hasOwnProperty.call(message, "deliveryStatList"))
                    $root.fastcity.admin.DeliveryStatList.encode(message.deliveryStatList, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                if (message.workingDayList != null && Object.hasOwnProperty.call(message, "workingDayList"))
                    $root.fastcity.admin.WorkingDayList.encode(message.workingDayList, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
                if (message.externalSubCompanyIdList != null && Object.hasOwnProperty.call(message, "externalSubCompanyIdList"))
                    $root.fastcity.admin.ExternalSubCompanyIdList.encode(message.externalSubCompanyIdList, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                if (message.subCompany != null && Object.hasOwnProperty.call(message, "subCompany"))
                    $root.fastcity.admin.SubCompany.encode(message.subCompany, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
                if (message.getSubCompanyList != null && Object.hasOwnProperty.call(message, "getSubCompanyList"))
                    $root.fastcity.admin.SubCompanyList.encode(message.getSubCompanyList, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
                if (message.subCompanyZone != null && Object.hasOwnProperty.call(message, "subCompanyZone"))
                    $root.fastcity.admin.SubCompanyZone.encode(message.subCompanyZone, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
                if (message.subCompanyZoneList != null && Object.hasOwnProperty.call(message, "subCompanyZoneList"))
                    $root.fastcity.admin.SubCompanyZoneList.encode(message.subCompanyZoneList, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
                if (message.availableOrderStatuses != null && Object.hasOwnProperty.call(message, "availableOrderStatuses"))
                    $root.fastcity.admin.AvailableOrderStatuses.encode(message.availableOrderStatuses, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
                if (message.externalAuthToken != null && Object.hasOwnProperty.call(message, "externalAuthToken"))
                    $root.fastcity.admin.ExternalAuthToken.encode(message.externalAuthToken, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
                if (message.externalAuthTokenList != null && Object.hasOwnProperty.call(message, "externalAuthTokenList"))
                    $root.fastcity.admin.ExternalAuthTokenList.encode(message.externalAuthTokenList, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
                if (message.availableOrderStatusesList != null && Object.hasOwnProperty.call(message, "availableOrderStatusesList"))
                    $root.fastcity.admin.AvailableOrderStatusesList.encode(message.availableOrderStatusesList, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
                if (message.optionalExternalAuthToken != null && Object.hasOwnProperty.call(message, "optionalExternalAuthToken"))
                    $root.fastcity.admin.OptionalExternalAuthToken.encode(message.optionalExternalAuthToken, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
                if (message.serverVersion != null && Object.hasOwnProperty.call(message, "serverVersion"))
                    $root.fastcity.admin.Version.encode(message.serverVersion, writer.uint32(/* id 37, wireType 2 =*/298).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ServerMessage message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ServerMessage
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ServerMessage} ServerMessage
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerMessage.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ServerMessage();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.requestId = reader.uint64();
                        break;
                    case 2:
                        message.result = $root.fastcity.admin.Result.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.admin = $root.fastcity.admin.Admin.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.adminList = $root.fastcity.admin.AdminList.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.company = $root.fastcity.admin.Company.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.companyList = $root.fastcity.admin.CompanyList.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.courier = $root.fastcity.admin.Courier.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.courierList = $root.fastcity.admin.CourierList.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.group = $root.fastcity.admin.Group.decode(reader, reader.uint32());
                        break;
                    case 10:
                        message.groupList = $root.fastcity.admin.GroupList.decode(reader, reader.uint32());
                        break;
                    case 12:
                        message.locationList = $root.fastcity.admin.LocationList.decode(reader, reader.uint32());
                        break;
                    case 14:
                        message.orderList = $root.fastcity.admin.OrderList.decode(reader, reader.uint32());
                        break;
                    case 15:
                        message.connectionStatus = $root.fastcity.admin.ConnectionStatus.decode(reader, reader.uint32());
                        break;
                    case 16:
                        message.connectionStatusList = $root.fastcity.admin.ConnectionStatusList.decode(reader, reader.uint32());
                        break;
                    case 17:
                        message.integrationToken = $root.fastcity.admin.IntegrationToken.decode(reader, reader.uint32());
                        break;
                    case 18:
                        message.integrationTokenList = $root.fastcity.admin.IntegrationTokenList.decode(reader, reader.uint32());
                        break;
                    case 19:
                        message.changedCourierIdList = $root.fastcity.admin.ChangedCourierIdList.decode(reader, reader.uint32());
                        break;
                    case 20:
                        message.changedOrderIdList = $root.fastcity.admin.ChangedOrderIdList.decode(reader, reader.uint32());
                        break;
                    case 21:
                        message.logonResult = $root.fastcity.admin.LogonResult.decode(reader, reader.uint32());
                        break;
                    case 22:
                        message.changedLastLocation = $root.fastcity.admin.ChangedLastLocation.decode(reader, reader.uint32());
                        break;
                    case 23:
                        message.deliveryStatDayList = $root.fastcity.admin.DeliveryStatDayList.decode(reader, reader.uint32());
                        break;
                    case 24:
                        message.deliveryStatList = $root.fastcity.admin.DeliveryStatList.decode(reader, reader.uint32());
                        break;
                    case 25:
                        message.workingDayList = $root.fastcity.admin.WorkingDayList.decode(reader, reader.uint32());
                        break;
                    case 26:
                        message.externalSubCompanyIdList = $root.fastcity.admin.ExternalSubCompanyIdList.decode(reader, reader.uint32());
                        break;
                    case 27:
                        message.subCompany = $root.fastcity.admin.SubCompany.decode(reader, reader.uint32());
                        break;
                    case 28:
                        message.getSubCompanyList = $root.fastcity.admin.SubCompanyList.decode(reader, reader.uint32());
                        break;
                    case 30:
                        message.subCompanyZone = $root.fastcity.admin.SubCompanyZone.decode(reader, reader.uint32());
                        break;
                    case 31:
                        message.subCompanyZoneList = $root.fastcity.admin.SubCompanyZoneList.decode(reader, reader.uint32());
                        break;
                    case 32:
                        message.availableOrderStatuses = $root.fastcity.admin.AvailableOrderStatuses.decode(reader, reader.uint32());
                        break;
                    case 35:
                        message.availableOrderStatusesList = $root.fastcity.admin.AvailableOrderStatusesList.decode(reader, reader.uint32());
                        break;
                    case 33:
                        message.externalAuthToken = $root.fastcity.admin.ExternalAuthToken.decode(reader, reader.uint32());
                        break;
                    case 34:
                        message.externalAuthTokenList = $root.fastcity.admin.ExternalAuthTokenList.decode(reader, reader.uint32());
                        break;
                    case 36:
                        message.optionalExternalAuthToken = $root.fastcity.admin.OptionalExternalAuthToken.decode(reader, reader.uint32());
                        break;
                    case 37:
                        message.serverVersion = $root.fastcity.admin.Version.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ServerMessage message.
             * @function verify
             * @memberof fastcity.admin.ServerMessage
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServerMessage.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.requestId != null && message.hasOwnProperty("requestId"))
                    if (!$util.isInteger(message.requestId) && !(message.requestId && $util.isInteger(message.requestId.low) && $util.isInteger(message.requestId.high)))
                        return "requestId: integer|Long expected";
                if (message.result != null && message.hasOwnProperty("result")) {
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.Result.verify(message.result);
                        if (error)
                            return "result." + error;
                    }
                }
                if (message.admin != null && message.hasOwnProperty("admin")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.Admin.verify(message.admin);
                        if (error)
                            return "admin." + error;
                    }
                }
                if (message.adminList != null && message.hasOwnProperty("adminList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.AdminList.verify(message.adminList);
                        if (error)
                            return "adminList." + error;
                    }
                }
                if (message.company != null && message.hasOwnProperty("company")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.Company.verify(message.company);
                        if (error)
                            return "company." + error;
                    }
                }
                if (message.companyList != null && message.hasOwnProperty("companyList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CompanyList.verify(message.companyList);
                        if (error)
                            return "companyList." + error;
                    }
                }
                if (message.courier != null && message.hasOwnProperty("courier")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.Courier.verify(message.courier);
                        if (error)
                            return "courier." + error;
                    }
                }
                if (message.courierList != null && message.hasOwnProperty("courierList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.CourierList.verify(message.courierList);
                        if (error)
                            return "courierList." + error;
                    }
                }
                if (message.group != null && message.hasOwnProperty("group")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.Group.verify(message.group);
                        if (error)
                            return "group." + error;
                    }
                }
                if (message.groupList != null && message.hasOwnProperty("groupList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.GroupList.verify(message.groupList);
                        if (error)
                            return "groupList." + error;
                    }
                }
                if (message.locationList != null && message.hasOwnProperty("locationList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.LocationList.verify(message.locationList);
                        if (error)
                            return "locationList." + error;
                    }
                }
                if (message.orderList != null && message.hasOwnProperty("orderList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.OrderList.verify(message.orderList);
                        if (error)
                            return "orderList." + error;
                    }
                }
                if (message.connectionStatus != null && message.hasOwnProperty("connectionStatus")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.ConnectionStatus.verify(message.connectionStatus);
                        if (error)
                            return "connectionStatus." + error;
                    }
                }
                if (message.connectionStatusList != null && message.hasOwnProperty("connectionStatusList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.ConnectionStatusList.verify(message.connectionStatusList);
                        if (error)
                            return "connectionStatusList." + error;
                    }
                }
                if (message.integrationToken != null && message.hasOwnProperty("integrationToken")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.IntegrationToken.verify(message.integrationToken);
                        if (error)
                            return "integrationToken." + error;
                    }
                }
                if (message.integrationTokenList != null && message.hasOwnProperty("integrationTokenList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.IntegrationTokenList.verify(message.integrationTokenList);
                        if (error)
                            return "integrationTokenList." + error;
                    }
                }
                if (message.changedCourierIdList != null && message.hasOwnProperty("changedCourierIdList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.ChangedCourierIdList.verify(message.changedCourierIdList);
                        if (error)
                            return "changedCourierIdList." + error;
                    }
                }
                if (message.changedOrderIdList != null && message.hasOwnProperty("changedOrderIdList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.ChangedOrderIdList.verify(message.changedOrderIdList);
                        if (error)
                            return "changedOrderIdList." + error;
                    }
                }
                if (message.logonResult != null && message.hasOwnProperty("logonResult")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.LogonResult.verify(message.logonResult);
                        if (error)
                            return "logonResult." + error;
                    }
                }
                if (message.changedLastLocation != null && message.hasOwnProperty("changedLastLocation")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.ChangedLastLocation.verify(message.changedLastLocation);
                        if (error)
                            return "changedLastLocation." + error;
                    }
                }
                if (message.deliveryStatDayList != null && message.hasOwnProperty("deliveryStatDayList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.DeliveryStatDayList.verify(message.deliveryStatDayList);
                        if (error)
                            return "deliveryStatDayList." + error;
                    }
                }
                if (message.deliveryStatList != null && message.hasOwnProperty("deliveryStatList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.DeliveryStatList.verify(message.deliveryStatList);
                        if (error)
                            return "deliveryStatList." + error;
                    }
                }
                if (message.workingDayList != null && message.hasOwnProperty("workingDayList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.WorkingDayList.verify(message.workingDayList);
                        if (error)
                            return "workingDayList." + error;
                    }
                }
                if (message.externalSubCompanyIdList != null && message.hasOwnProperty("externalSubCompanyIdList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.ExternalSubCompanyIdList.verify(message.externalSubCompanyIdList);
                        if (error)
                            return "externalSubCompanyIdList." + error;
                    }
                }
                if (message.subCompany != null && message.hasOwnProperty("subCompany")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.SubCompany.verify(message.subCompany);
                        if (error)
                            return "subCompany." + error;
                    }
                }
                if (message.getSubCompanyList != null && message.hasOwnProperty("getSubCompanyList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.SubCompanyList.verify(message.getSubCompanyList);
                        if (error)
                            return "getSubCompanyList." + error;
                    }
                }
                if (message.subCompanyZone != null && message.hasOwnProperty("subCompanyZone")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.SubCompanyZone.verify(message.subCompanyZone);
                        if (error)
                            return "subCompanyZone." + error;
                    }
                }
                if (message.subCompanyZoneList != null && message.hasOwnProperty("subCompanyZoneList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.SubCompanyZoneList.verify(message.subCompanyZoneList);
                        if (error)
                            return "subCompanyZoneList." + error;
                    }
                }
                if (message.availableOrderStatuses != null && message.hasOwnProperty("availableOrderStatuses")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.AvailableOrderStatuses.verify(message.availableOrderStatuses);
                        if (error)
                            return "availableOrderStatuses." + error;
                    }
                }
                if (message.availableOrderStatusesList != null && message.hasOwnProperty("availableOrderStatusesList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.AvailableOrderStatusesList.verify(message.availableOrderStatusesList);
                        if (error)
                            return "availableOrderStatusesList." + error;
                    }
                }
                if (message.externalAuthToken != null && message.hasOwnProperty("externalAuthToken")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.ExternalAuthToken.verify(message.externalAuthToken);
                        if (error)
                            return "externalAuthToken." + error;
                    }
                }
                if (message.externalAuthTokenList != null && message.hasOwnProperty("externalAuthTokenList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.ExternalAuthTokenList.verify(message.externalAuthTokenList);
                        if (error)
                            return "externalAuthTokenList." + error;
                    }
                }
                if (message.optionalExternalAuthToken != null && message.hasOwnProperty("optionalExternalAuthToken")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.OptionalExternalAuthToken.verify(message.optionalExternalAuthToken);
                        if (error)
                            return "optionalExternalAuthToken." + error;
                    }
                }
                if (message.serverVersion != null && message.hasOwnProperty("serverVersion")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.fastcity.admin.Version.verify(message.serverVersion);
                        if (error)
                            return "serverVersion." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ServerMessage message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ServerMessage
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ServerMessage} ServerMessage
             */
            ServerMessage.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ServerMessage)
                    return object;
                var message = new $root.fastcity.admin.ServerMessage();
                if (object.requestId != null)
                    if ($util.Long)
                        (message.requestId = $util.Long.fromValue(object.requestId)).unsigned = true;
                    else if (typeof object.requestId === "string")
                        message.requestId = parseInt(object.requestId, 10);
                    else if (typeof object.requestId === "number")
                        message.requestId = object.requestId;
                    else if (typeof object.requestId === "object")
                        message.requestId = new $util.LongBits(object.requestId.low >>> 0, object.requestId.high >>> 0).toNumber(true);
                if (object.result != null) {
                    if (typeof object.result !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.result: object expected");
                    message.result = $root.fastcity.admin.Result.fromObject(object.result);
                }
                if (object.admin != null) {
                    if (typeof object.admin !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.admin: object expected");
                    message.admin = $root.fastcity.admin.Admin.fromObject(object.admin);
                }
                if (object.adminList != null) {
                    if (typeof object.adminList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.adminList: object expected");
                    message.adminList = $root.fastcity.admin.AdminList.fromObject(object.adminList);
                }
                if (object.company != null) {
                    if (typeof object.company !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.company: object expected");
                    message.company = $root.fastcity.admin.Company.fromObject(object.company);
                }
                if (object.companyList != null) {
                    if (typeof object.companyList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.companyList: object expected");
                    message.companyList = $root.fastcity.admin.CompanyList.fromObject(object.companyList);
                }
                if (object.courier != null) {
                    if (typeof object.courier !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.courier: object expected");
                    message.courier = $root.fastcity.admin.Courier.fromObject(object.courier);
                }
                if (object.courierList != null) {
                    if (typeof object.courierList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.courierList: object expected");
                    message.courierList = $root.fastcity.admin.CourierList.fromObject(object.courierList);
                }
                if (object.group != null) {
                    if (typeof object.group !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.group: object expected");
                    message.group = $root.fastcity.admin.Group.fromObject(object.group);
                }
                if (object.groupList != null) {
                    if (typeof object.groupList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.groupList: object expected");
                    message.groupList = $root.fastcity.admin.GroupList.fromObject(object.groupList);
                }
                if (object.locationList != null) {
                    if (typeof object.locationList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.locationList: object expected");
                    message.locationList = $root.fastcity.admin.LocationList.fromObject(object.locationList);
                }
                if (object.orderList != null) {
                    if (typeof object.orderList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.orderList: object expected");
                    message.orderList = $root.fastcity.admin.OrderList.fromObject(object.orderList);
                }
                if (object.connectionStatus != null) {
                    if (typeof object.connectionStatus !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.connectionStatus: object expected");
                    message.connectionStatus = $root.fastcity.admin.ConnectionStatus.fromObject(object.connectionStatus);
                }
                if (object.connectionStatusList != null) {
                    if (typeof object.connectionStatusList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.connectionStatusList: object expected");
                    message.connectionStatusList = $root.fastcity.admin.ConnectionStatusList.fromObject(object.connectionStatusList);
                }
                if (object.integrationToken != null) {
                    if (typeof object.integrationToken !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.integrationToken: object expected");
                    message.integrationToken = $root.fastcity.admin.IntegrationToken.fromObject(object.integrationToken);
                }
                if (object.integrationTokenList != null) {
                    if (typeof object.integrationTokenList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.integrationTokenList: object expected");
                    message.integrationTokenList = $root.fastcity.admin.IntegrationTokenList.fromObject(object.integrationTokenList);
                }
                if (object.changedCourierIdList != null) {
                    if (typeof object.changedCourierIdList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.changedCourierIdList: object expected");
                    message.changedCourierIdList = $root.fastcity.admin.ChangedCourierIdList.fromObject(object.changedCourierIdList);
                }
                if (object.changedOrderIdList != null) {
                    if (typeof object.changedOrderIdList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.changedOrderIdList: object expected");
                    message.changedOrderIdList = $root.fastcity.admin.ChangedOrderIdList.fromObject(object.changedOrderIdList);
                }
                if (object.logonResult != null) {
                    if (typeof object.logonResult !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.logonResult: object expected");
                    message.logonResult = $root.fastcity.admin.LogonResult.fromObject(object.logonResult);
                }
                if (object.changedLastLocation != null) {
                    if (typeof object.changedLastLocation !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.changedLastLocation: object expected");
                    message.changedLastLocation = $root.fastcity.admin.ChangedLastLocation.fromObject(object.changedLastLocation);
                }
                if (object.deliveryStatDayList != null) {
                    if (typeof object.deliveryStatDayList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.deliveryStatDayList: object expected");
                    message.deliveryStatDayList = $root.fastcity.admin.DeliveryStatDayList.fromObject(object.deliveryStatDayList);
                }
                if (object.deliveryStatList != null) {
                    if (typeof object.deliveryStatList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.deliveryStatList: object expected");
                    message.deliveryStatList = $root.fastcity.admin.DeliveryStatList.fromObject(object.deliveryStatList);
                }
                if (object.workingDayList != null) {
                    if (typeof object.workingDayList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.workingDayList: object expected");
                    message.workingDayList = $root.fastcity.admin.WorkingDayList.fromObject(object.workingDayList);
                }
                if (object.externalSubCompanyIdList != null) {
                    if (typeof object.externalSubCompanyIdList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.externalSubCompanyIdList: object expected");
                    message.externalSubCompanyIdList = $root.fastcity.admin.ExternalSubCompanyIdList.fromObject(object.externalSubCompanyIdList);
                }
                if (object.subCompany != null) {
                    if (typeof object.subCompany !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.subCompany: object expected");
                    message.subCompany = $root.fastcity.admin.SubCompany.fromObject(object.subCompany);
                }
                if (object.getSubCompanyList != null) {
                    if (typeof object.getSubCompanyList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.getSubCompanyList: object expected");
                    message.getSubCompanyList = $root.fastcity.admin.SubCompanyList.fromObject(object.getSubCompanyList);
                }
                if (object.subCompanyZone != null) {
                    if (typeof object.subCompanyZone !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.subCompanyZone: object expected");
                    message.subCompanyZone = $root.fastcity.admin.SubCompanyZone.fromObject(object.subCompanyZone);
                }
                if (object.subCompanyZoneList != null) {
                    if (typeof object.subCompanyZoneList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.subCompanyZoneList: object expected");
                    message.subCompanyZoneList = $root.fastcity.admin.SubCompanyZoneList.fromObject(object.subCompanyZoneList);
                }
                if (object.availableOrderStatuses != null) {
                    if (typeof object.availableOrderStatuses !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.availableOrderStatuses: object expected");
                    message.availableOrderStatuses = $root.fastcity.admin.AvailableOrderStatuses.fromObject(object.availableOrderStatuses);
                }
                if (object.availableOrderStatusesList != null) {
                    if (typeof object.availableOrderStatusesList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.availableOrderStatusesList: object expected");
                    message.availableOrderStatusesList = $root.fastcity.admin.AvailableOrderStatusesList.fromObject(object.availableOrderStatusesList);
                }
                if (object.externalAuthToken != null) {
                    if (typeof object.externalAuthToken !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.externalAuthToken: object expected");
                    message.externalAuthToken = $root.fastcity.admin.ExternalAuthToken.fromObject(object.externalAuthToken);
                }
                if (object.externalAuthTokenList != null) {
                    if (typeof object.externalAuthTokenList !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.externalAuthTokenList: object expected");
                    message.externalAuthTokenList = $root.fastcity.admin.ExternalAuthTokenList.fromObject(object.externalAuthTokenList);
                }
                if (object.optionalExternalAuthToken != null) {
                    if (typeof object.optionalExternalAuthToken !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.optionalExternalAuthToken: object expected");
                    message.optionalExternalAuthToken = $root.fastcity.admin.OptionalExternalAuthToken.fromObject(object.optionalExternalAuthToken);
                }
                if (object.serverVersion != null) {
                    if (typeof object.serverVersion !== "object")
                        throw TypeError(".fastcity.admin.ServerMessage.serverVersion: object expected");
                    message.serverVersion = $root.fastcity.admin.Version.fromObject(object.serverVersion);
                }
                return message;
            };

            /**
             * Creates a plain object from a ServerMessage message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ServerMessage
             * @static
             * @param {fastcity.admin.ServerMessage} message ServerMessage
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServerMessage.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.requestId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.requestId = options.longs === String ? "0" : 0;
                if (message.requestId != null && message.hasOwnProperty("requestId"))
                    if (typeof message.requestId === "number")
                        object.requestId = options.longs === String ? String(message.requestId) : message.requestId;
                    else
                        object.requestId = options.longs === String ? $util.Long.prototype.toString.call(message.requestId) : options.longs === Number ? new $util.LongBits(message.requestId.low >>> 0, message.requestId.high >>> 0).toNumber(true) : message.requestId;
                if (message.result != null && message.hasOwnProperty("result")) {
                    object.result = $root.fastcity.admin.Result.toObject(message.result, options);
                    if (options.oneofs)
                        object.body = "result";
                }
                if (message.admin != null && message.hasOwnProperty("admin")) {
                    object.admin = $root.fastcity.admin.Admin.toObject(message.admin, options);
                    if (options.oneofs)
                        object.body = "admin";
                }
                if (message.adminList != null && message.hasOwnProperty("adminList")) {
                    object.adminList = $root.fastcity.admin.AdminList.toObject(message.adminList, options);
                    if (options.oneofs)
                        object.body = "adminList";
                }
                if (message.company != null && message.hasOwnProperty("company")) {
                    object.company = $root.fastcity.admin.Company.toObject(message.company, options);
                    if (options.oneofs)
                        object.body = "company";
                }
                if (message.companyList != null && message.hasOwnProperty("companyList")) {
                    object.companyList = $root.fastcity.admin.CompanyList.toObject(message.companyList, options);
                    if (options.oneofs)
                        object.body = "companyList";
                }
                if (message.courier != null && message.hasOwnProperty("courier")) {
                    object.courier = $root.fastcity.admin.Courier.toObject(message.courier, options);
                    if (options.oneofs)
                        object.body = "courier";
                }
                if (message.courierList != null && message.hasOwnProperty("courierList")) {
                    object.courierList = $root.fastcity.admin.CourierList.toObject(message.courierList, options);
                    if (options.oneofs)
                        object.body = "courierList";
                }
                if (message.group != null && message.hasOwnProperty("group")) {
                    object.group = $root.fastcity.admin.Group.toObject(message.group, options);
                    if (options.oneofs)
                        object.body = "group";
                }
                if (message.groupList != null && message.hasOwnProperty("groupList")) {
                    object.groupList = $root.fastcity.admin.GroupList.toObject(message.groupList, options);
                    if (options.oneofs)
                        object.body = "groupList";
                }
                if (message.locationList != null && message.hasOwnProperty("locationList")) {
                    object.locationList = $root.fastcity.admin.LocationList.toObject(message.locationList, options);
                    if (options.oneofs)
                        object.body = "locationList";
                }
                if (message.orderList != null && message.hasOwnProperty("orderList")) {
                    object.orderList = $root.fastcity.admin.OrderList.toObject(message.orderList, options);
                    if (options.oneofs)
                        object.body = "orderList";
                }
                if (message.connectionStatus != null && message.hasOwnProperty("connectionStatus")) {
                    object.connectionStatus = $root.fastcity.admin.ConnectionStatus.toObject(message.connectionStatus, options);
                    if (options.oneofs)
                        object.body = "connectionStatus";
                }
                if (message.connectionStatusList != null && message.hasOwnProperty("connectionStatusList")) {
                    object.connectionStatusList = $root.fastcity.admin.ConnectionStatusList.toObject(message.connectionStatusList, options);
                    if (options.oneofs)
                        object.body = "connectionStatusList";
                }
                if (message.integrationToken != null && message.hasOwnProperty("integrationToken")) {
                    object.integrationToken = $root.fastcity.admin.IntegrationToken.toObject(message.integrationToken, options);
                    if (options.oneofs)
                        object.body = "integrationToken";
                }
                if (message.integrationTokenList != null && message.hasOwnProperty("integrationTokenList")) {
                    object.integrationTokenList = $root.fastcity.admin.IntegrationTokenList.toObject(message.integrationTokenList, options);
                    if (options.oneofs)
                        object.body = "integrationTokenList";
                }
                if (message.changedCourierIdList != null && message.hasOwnProperty("changedCourierIdList")) {
                    object.changedCourierIdList = $root.fastcity.admin.ChangedCourierIdList.toObject(message.changedCourierIdList, options);
                    if (options.oneofs)
                        object.body = "changedCourierIdList";
                }
                if (message.changedOrderIdList != null && message.hasOwnProperty("changedOrderIdList")) {
                    object.changedOrderIdList = $root.fastcity.admin.ChangedOrderIdList.toObject(message.changedOrderIdList, options);
                    if (options.oneofs)
                        object.body = "changedOrderIdList";
                }
                if (message.logonResult != null && message.hasOwnProperty("logonResult")) {
                    object.logonResult = $root.fastcity.admin.LogonResult.toObject(message.logonResult, options);
                    if (options.oneofs)
                        object.body = "logonResult";
                }
                if (message.changedLastLocation != null && message.hasOwnProperty("changedLastLocation")) {
                    object.changedLastLocation = $root.fastcity.admin.ChangedLastLocation.toObject(message.changedLastLocation, options);
                    if (options.oneofs)
                        object.body = "changedLastLocation";
                }
                if (message.deliveryStatDayList != null && message.hasOwnProperty("deliveryStatDayList")) {
                    object.deliveryStatDayList = $root.fastcity.admin.DeliveryStatDayList.toObject(message.deliveryStatDayList, options);
                    if (options.oneofs)
                        object.body = "deliveryStatDayList";
                }
                if (message.deliveryStatList != null && message.hasOwnProperty("deliveryStatList")) {
                    object.deliveryStatList = $root.fastcity.admin.DeliveryStatList.toObject(message.deliveryStatList, options);
                    if (options.oneofs)
                        object.body = "deliveryStatList";
                }
                if (message.workingDayList != null && message.hasOwnProperty("workingDayList")) {
                    object.workingDayList = $root.fastcity.admin.WorkingDayList.toObject(message.workingDayList, options);
                    if (options.oneofs)
                        object.body = "workingDayList";
                }
                if (message.externalSubCompanyIdList != null && message.hasOwnProperty("externalSubCompanyIdList")) {
                    object.externalSubCompanyIdList = $root.fastcity.admin.ExternalSubCompanyIdList.toObject(message.externalSubCompanyIdList, options);
                    if (options.oneofs)
                        object.body = "externalSubCompanyIdList";
                }
                if (message.subCompany != null && message.hasOwnProperty("subCompany")) {
                    object.subCompany = $root.fastcity.admin.SubCompany.toObject(message.subCompany, options);
                    if (options.oneofs)
                        object.body = "subCompany";
                }
                if (message.getSubCompanyList != null && message.hasOwnProperty("getSubCompanyList")) {
                    object.getSubCompanyList = $root.fastcity.admin.SubCompanyList.toObject(message.getSubCompanyList, options);
                    if (options.oneofs)
                        object.body = "getSubCompanyList";
                }
                if (message.subCompanyZone != null && message.hasOwnProperty("subCompanyZone")) {
                    object.subCompanyZone = $root.fastcity.admin.SubCompanyZone.toObject(message.subCompanyZone, options);
                    if (options.oneofs)
                        object.body = "subCompanyZone";
                }
                if (message.subCompanyZoneList != null && message.hasOwnProperty("subCompanyZoneList")) {
                    object.subCompanyZoneList = $root.fastcity.admin.SubCompanyZoneList.toObject(message.subCompanyZoneList, options);
                    if (options.oneofs)
                        object.body = "subCompanyZoneList";
                }
                if (message.availableOrderStatuses != null && message.hasOwnProperty("availableOrderStatuses")) {
                    object.availableOrderStatuses = $root.fastcity.admin.AvailableOrderStatuses.toObject(message.availableOrderStatuses, options);
                    if (options.oneofs)
                        object.body = "availableOrderStatuses";
                }
                if (message.externalAuthToken != null && message.hasOwnProperty("externalAuthToken")) {
                    object.externalAuthToken = $root.fastcity.admin.ExternalAuthToken.toObject(message.externalAuthToken, options);
                    if (options.oneofs)
                        object.body = "externalAuthToken";
                }
                if (message.externalAuthTokenList != null && message.hasOwnProperty("externalAuthTokenList")) {
                    object.externalAuthTokenList = $root.fastcity.admin.ExternalAuthTokenList.toObject(message.externalAuthTokenList, options);
                    if (options.oneofs)
                        object.body = "externalAuthTokenList";
                }
                if (message.availableOrderStatusesList != null && message.hasOwnProperty("availableOrderStatusesList")) {
                    object.availableOrderStatusesList = $root.fastcity.admin.AvailableOrderStatusesList.toObject(message.availableOrderStatusesList, options);
                    if (options.oneofs)
                        object.body = "availableOrderStatusesList";
                }
                if (message.optionalExternalAuthToken != null && message.hasOwnProperty("optionalExternalAuthToken")) {
                    object.optionalExternalAuthToken = $root.fastcity.admin.OptionalExternalAuthToken.toObject(message.optionalExternalAuthToken, options);
                    if (options.oneofs)
                        object.body = "optionalExternalAuthToken";
                }
                if (message.serverVersion != null && message.hasOwnProperty("serverVersion")) {
                    object.serverVersion = $root.fastcity.admin.Version.toObject(message.serverVersion, options);
                    if (options.oneofs)
                        object.body = "serverVersion";
                }
                return object;
            };

            /**
             * Converts this ServerMessage to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ServerMessage
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServerMessage.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServerMessage;
        })();

        admin.ResetPassword = (function() {

            /**
             * Properties of a ResetPassword.
             * @memberof fastcity.admin
             * @interface IResetPassword
             * @property {string|null} [login] ResetPassword login
             */

            /**
             * Constructs a new ResetPassword.
             * @memberof fastcity.admin
             * @classdesc Represents a ResetPassword.
             * @implements IResetPassword
             * @constructor
             * @param {fastcity.admin.IResetPassword=} [properties] Properties to set
             */
            function ResetPassword(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ResetPassword login.
             * @member {string} login
             * @memberof fastcity.admin.ResetPassword
             * @instance
             */
            ResetPassword.prototype.login = "";

            /**
             * Creates a new ResetPassword instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ResetPassword
             * @static
             * @param {fastcity.admin.IResetPassword=} [properties] Properties to set
             * @returns {fastcity.admin.ResetPassword} ResetPassword instance
             */
            ResetPassword.create = function create(properties) {
                return new ResetPassword(properties);
            };

            /**
             * Encodes the specified ResetPassword message. Does not implicitly {@link fastcity.admin.ResetPassword.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ResetPassword
             * @static
             * @param {fastcity.admin.IResetPassword} message ResetPassword message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResetPassword.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.login != null && Object.hasOwnProperty.call(message, "login"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.login);
                return writer;
            };

            /**
             * Decodes a ResetPassword message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ResetPassword
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ResetPassword} ResetPassword
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResetPassword.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ResetPassword();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.login = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ResetPassword message.
             * @function verify
             * @memberof fastcity.admin.ResetPassword
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ResetPassword.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.login != null && message.hasOwnProperty("login"))
                    if (!$util.isString(message.login))
                        return "login: string expected";
                return null;
            };

            /**
             * Creates a ResetPassword message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ResetPassword
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ResetPassword} ResetPassword
             */
            ResetPassword.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ResetPassword)
                    return object;
                var message = new $root.fastcity.admin.ResetPassword();
                if (object.login != null)
                    message.login = String(object.login);
                return message;
            };

            /**
             * Creates a plain object from a ResetPassword message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ResetPassword
             * @static
             * @param {fastcity.admin.ResetPassword} message ResetPassword
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ResetPassword.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.login = "";
                if (message.login != null && message.hasOwnProperty("login"))
                    object.login = message.login;
                return object;
            };

            /**
             * Converts this ResetPassword to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ResetPassword
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ResetPassword.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ResetPassword;
        })();

        admin.Result = (function() {

            /**
             * Properties of a Result.
             * @memberof fastcity.admin
             * @interface IResult
             * @property {fastcity.admin.Result.Code|null} [code] Result code
             * @property {string|null} [description] Result description
             */

            /**
             * Constructs a new Result.
             * @memberof fastcity.admin
             * @classdesc Represents a Result.
             * @implements IResult
             * @constructor
             * @param {fastcity.admin.IResult=} [properties] Properties to set
             */
            function Result(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Result code.
             * @member {fastcity.admin.Result.Code} code
             * @memberof fastcity.admin.Result
             * @instance
             */
            Result.prototype.code = 0;

            /**
             * Result description.
             * @member {string} description
             * @memberof fastcity.admin.Result
             * @instance
             */
            Result.prototype.description = "";

            /**
             * Creates a new Result instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.Result
             * @static
             * @param {fastcity.admin.IResult=} [properties] Properties to set
             * @returns {fastcity.admin.Result} Result instance
             */
            Result.create = function create(properties) {
                return new Result(properties);
            };

            /**
             * Encodes the specified Result message. Does not implicitly {@link fastcity.admin.Result.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.Result
             * @static
             * @param {fastcity.admin.IResult} message Result message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Result.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
                return writer;
            };

            /**
             * Decodes a Result message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.Result
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.Result} Result
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Result.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.Result();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.code = reader.int32();
                        break;
                    case 2:
                        message.description = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Result message.
             * @function verify
             * @memberof fastcity.admin.Result
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Result.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    switch (message.code) {
                    default:
                        return "code: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                return null;
            };

            /**
             * Creates a Result message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.Result
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.Result} Result
             */
            Result.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.Result)
                    return object;
                var message = new $root.fastcity.admin.Result();
                switch (object.code) {
                case "SUCCESS":
                case 0:
                    message.code = 0;
                    break;
                case "INVALID_REQUEST":
                case 1:
                    message.code = 1;
                    break;
                case "SERVER_ERROR":
                case 2:
                    message.code = 2;
                    break;
                case "NO_ACCESS":
                case 3:
                    message.code = 3;
                    break;
                case "CANNOT_SEND_EMAIL":
                case 4:
                    message.code = 4;
                    break;
                }
                if (object.description != null)
                    message.description = String(object.description);
                return message;
            };

            /**
             * Creates a plain object from a Result message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.Result
             * @static
             * @param {fastcity.admin.Result} message Result
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Result.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.code = options.enums === String ? "SUCCESS" : 0;
                    object.description = "";
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = options.enums === String ? $root.fastcity.admin.Result.Code[message.code] : message.code;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                return object;
            };

            /**
             * Converts this Result to JSON.
             * @function toJSON
             * @memberof fastcity.admin.Result
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Result.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Code enum.
             * @name fastcity.admin.Result.Code
             * @enum {number}
             * @property {number} SUCCESS=0 SUCCESS value
             * @property {number} INVALID_REQUEST=1 INVALID_REQUEST value
             * @property {number} SERVER_ERROR=2 SERVER_ERROR value
             * @property {number} NO_ACCESS=3 NO_ACCESS value
             * @property {number} CANNOT_SEND_EMAIL=4 CANNOT_SEND_EMAIL value
             */
            Result.Code = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SUCCESS"] = 0;
                values[valuesById[1] = "INVALID_REQUEST"] = 1;
                values[valuesById[2] = "SERVER_ERROR"] = 2;
                values[valuesById[3] = "NO_ACCESS"] = 3;
                values[valuesById[4] = "CANNOT_SEND_EMAIL"] = 4;
                return values;
            })();

            return Result;
        })();

        admin.SubCompany = (function() {

            /**
             * Properties of a SubCompany.
             * @memberof fastcity.admin
             * @interface ISubCompany
             * @property {number|Long|null} [companyId] SubCompany companyId
             * @property {string|null} [externalSubCompanyId] SubCompany externalSubCompanyId
             * @property {Uint8Array|null} [address] SubCompany address
             * @property {boolean|null} [isDeleted] SubCompany isDeleted
             * @property {fastcity.common.ICoordinate|null} [coordinate] SubCompany coordinate
             * @property {number|Long|null} [updateOrdersInterval] SubCompany updateOrdersInterval
             * @property {number|Long|null} [averageDeliveryTime] SubCompany averageDeliveryTime
             * @property {boolean|null} [ordersLatitudeLongitudeSwap] SubCompany ordersLatitudeLongitudeSwap
             */

            /**
             * Constructs a new SubCompany.
             * @memberof fastcity.admin
             * @classdesc Represents a SubCompany.
             * @implements ISubCompany
             * @constructor
             * @param {fastcity.admin.ISubCompany=} [properties] Properties to set
             */
            function SubCompany(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SubCompany companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.SubCompany
             * @instance
             */
            SubCompany.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SubCompany externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.SubCompany
             * @instance
             */
            SubCompany.prototype.externalSubCompanyId = "";

            /**
             * SubCompany address.
             * @member {Uint8Array} address
             * @memberof fastcity.admin.SubCompany
             * @instance
             */
            SubCompany.prototype.address = $util.newBuffer([]);

            /**
             * SubCompany isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.SubCompany
             * @instance
             */
            SubCompany.prototype.isDeleted = false;

            /**
             * SubCompany coordinate.
             * @member {fastcity.common.ICoordinate|null|undefined} coordinate
             * @memberof fastcity.admin.SubCompany
             * @instance
             */
            SubCompany.prototype.coordinate = null;

            /**
             * SubCompany updateOrdersInterval.
             * @member {number|Long} updateOrdersInterval
             * @memberof fastcity.admin.SubCompany
             * @instance
             */
            SubCompany.prototype.updateOrdersInterval = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * SubCompany averageDeliveryTime.
             * @member {number|Long} averageDeliveryTime
             * @memberof fastcity.admin.SubCompany
             * @instance
             */
            SubCompany.prototype.averageDeliveryTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * SubCompany ordersLatitudeLongitudeSwap.
             * @member {boolean} ordersLatitudeLongitudeSwap
             * @memberof fastcity.admin.SubCompany
             * @instance
             */
            SubCompany.prototype.ordersLatitudeLongitudeSwap = false;

            /**
             * Creates a new SubCompany instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.SubCompany
             * @static
             * @param {fastcity.admin.ISubCompany=} [properties] Properties to set
             * @returns {fastcity.admin.SubCompany} SubCompany instance
             */
            SubCompany.create = function create(properties) {
                return new SubCompany(properties);
            };

            /**
             * Encodes the specified SubCompany message. Does not implicitly {@link fastcity.admin.SubCompany.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.SubCompany
             * @static
             * @param {fastcity.admin.ISubCompany} message SubCompany message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubCompany.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalSubCompanyId);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.address);
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
                if (message.coordinate != null && Object.hasOwnProperty.call(message, "coordinate"))
                    $root.fastcity.common.Coordinate.encode(message.coordinate, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.updateOrdersInterval != null && Object.hasOwnProperty.call(message, "updateOrdersInterval"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.updateOrdersInterval);
                if (message.averageDeliveryTime != null && Object.hasOwnProperty.call(message, "averageDeliveryTime"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.averageDeliveryTime);
                if (message.ordersLatitudeLongitudeSwap != null && Object.hasOwnProperty.call(message, "ordersLatitudeLongitudeSwap"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.ordersLatitudeLongitudeSwap);
                return writer;
            };

            /**
             * Decodes a SubCompany message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.SubCompany
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.SubCompany} SubCompany
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubCompany.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.SubCompany();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.externalSubCompanyId = reader.string();
                        break;
                    case 3:
                        message.address = reader.bytes();
                        break;
                    case 4:
                        message.isDeleted = reader.bool();
                        break;
                    case 5:
                        message.coordinate = $root.fastcity.common.Coordinate.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.updateOrdersInterval = reader.int64();
                        break;
                    case 7:
                        message.averageDeliveryTime = reader.int64();
                        break;
                    case 8:
                        message.ordersLatitudeLongitudeSwap = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SubCompany message.
             * @function verify
             * @memberof fastcity.admin.SubCompany
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SubCompany.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!(message.address && typeof message.address.length === "number" || $util.isString(message.address)))
                        return "address: buffer expected";
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                if (message.coordinate != null && message.hasOwnProperty("coordinate")) {
                    var error = $root.fastcity.common.Coordinate.verify(message.coordinate);
                    if (error)
                        return "coordinate." + error;
                }
                if (message.updateOrdersInterval != null && message.hasOwnProperty("updateOrdersInterval"))
                    if (!$util.isInteger(message.updateOrdersInterval) && !(message.updateOrdersInterval && $util.isInteger(message.updateOrdersInterval.low) && $util.isInteger(message.updateOrdersInterval.high)))
                        return "updateOrdersInterval: integer|Long expected";
                if (message.averageDeliveryTime != null && message.hasOwnProperty("averageDeliveryTime"))
                    if (!$util.isInteger(message.averageDeliveryTime) && !(message.averageDeliveryTime && $util.isInteger(message.averageDeliveryTime.low) && $util.isInteger(message.averageDeliveryTime.high)))
                        return "averageDeliveryTime: integer|Long expected";
                if (message.ordersLatitudeLongitudeSwap != null && message.hasOwnProperty("ordersLatitudeLongitudeSwap"))
                    if (typeof message.ordersLatitudeLongitudeSwap !== "boolean")
                        return "ordersLatitudeLongitudeSwap: boolean expected";
                return null;
            };

            /**
             * Creates a SubCompany message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.SubCompany
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.SubCompany} SubCompany
             */
            SubCompany.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.SubCompany)
                    return object;
                var message = new $root.fastcity.admin.SubCompany();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                if (object.address != null)
                    if (typeof object.address === "string")
                        $util.base64.decode(object.address, message.address = $util.newBuffer($util.base64.length(object.address)), 0);
                    else if (object.address.length)
                        message.address = object.address;
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                if (object.coordinate != null) {
                    if (typeof object.coordinate !== "object")
                        throw TypeError(".fastcity.admin.SubCompany.coordinate: object expected");
                    message.coordinate = $root.fastcity.common.Coordinate.fromObject(object.coordinate);
                }
                if (object.updateOrdersInterval != null)
                    if ($util.Long)
                        (message.updateOrdersInterval = $util.Long.fromValue(object.updateOrdersInterval)).unsigned = false;
                    else if (typeof object.updateOrdersInterval === "string")
                        message.updateOrdersInterval = parseInt(object.updateOrdersInterval, 10);
                    else if (typeof object.updateOrdersInterval === "number")
                        message.updateOrdersInterval = object.updateOrdersInterval;
                    else if (typeof object.updateOrdersInterval === "object")
                        message.updateOrdersInterval = new $util.LongBits(object.updateOrdersInterval.low >>> 0, object.updateOrdersInterval.high >>> 0).toNumber();
                if (object.averageDeliveryTime != null)
                    if ($util.Long)
                        (message.averageDeliveryTime = $util.Long.fromValue(object.averageDeliveryTime)).unsigned = false;
                    else if (typeof object.averageDeliveryTime === "string")
                        message.averageDeliveryTime = parseInt(object.averageDeliveryTime, 10);
                    else if (typeof object.averageDeliveryTime === "number")
                        message.averageDeliveryTime = object.averageDeliveryTime;
                    else if (typeof object.averageDeliveryTime === "object")
                        message.averageDeliveryTime = new $util.LongBits(object.averageDeliveryTime.low >>> 0, object.averageDeliveryTime.high >>> 0).toNumber();
                if (object.ordersLatitudeLongitudeSwap != null)
                    message.ordersLatitudeLongitudeSwap = Boolean(object.ordersLatitudeLongitudeSwap);
                return message;
            };

            /**
             * Creates a plain object from a SubCompany message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.SubCompany
             * @static
             * @param {fastcity.admin.SubCompany} message SubCompany
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SubCompany.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                    if (options.bytes === String)
                        object.address = "";
                    else {
                        object.address = [];
                        if (options.bytes !== Array)
                            object.address = $util.newBuffer(object.address);
                    }
                    object.isDeleted = false;
                    object.coordinate = null;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.updateOrdersInterval = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.updateOrdersInterval = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.averageDeliveryTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.averageDeliveryTime = options.longs === String ? "0" : 0;
                    object.ordersLatitudeLongitudeSwap = false;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = options.bytes === String ? $util.base64.encode(message.address, 0, message.address.length) : options.bytes === Array ? Array.prototype.slice.call(message.address) : message.address;
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                if (message.coordinate != null && message.hasOwnProperty("coordinate"))
                    object.coordinate = $root.fastcity.common.Coordinate.toObject(message.coordinate, options);
                if (message.updateOrdersInterval != null && message.hasOwnProperty("updateOrdersInterval"))
                    if (typeof message.updateOrdersInterval === "number")
                        object.updateOrdersInterval = options.longs === String ? String(message.updateOrdersInterval) : message.updateOrdersInterval;
                    else
                        object.updateOrdersInterval = options.longs === String ? $util.Long.prototype.toString.call(message.updateOrdersInterval) : options.longs === Number ? new $util.LongBits(message.updateOrdersInterval.low >>> 0, message.updateOrdersInterval.high >>> 0).toNumber() : message.updateOrdersInterval;
                if (message.averageDeliveryTime != null && message.hasOwnProperty("averageDeliveryTime"))
                    if (typeof message.averageDeliveryTime === "number")
                        object.averageDeliveryTime = options.longs === String ? String(message.averageDeliveryTime) : message.averageDeliveryTime;
                    else
                        object.averageDeliveryTime = options.longs === String ? $util.Long.prototype.toString.call(message.averageDeliveryTime) : options.longs === Number ? new $util.LongBits(message.averageDeliveryTime.low >>> 0, message.averageDeliveryTime.high >>> 0).toNumber() : message.averageDeliveryTime;
                if (message.ordersLatitudeLongitudeSwap != null && message.hasOwnProperty("ordersLatitudeLongitudeSwap"))
                    object.ordersLatitudeLongitudeSwap = message.ordersLatitudeLongitudeSwap;
                return object;
            };

            /**
             * Converts this SubCompany to JSON.
             * @function toJSON
             * @memberof fastcity.admin.SubCompany
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SubCompany.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SubCompany;
        })();

        admin.CreateSubCompany = (function() {

            /**
             * Properties of a CreateSubCompany.
             * @memberof fastcity.admin
             * @interface ICreateSubCompany
             * @property {number|Long|null} [companyId] CreateSubCompany companyId
             * @property {string|null} [externalSubCompanyId] CreateSubCompany externalSubCompanyId
             * @property {Uint8Array|null} [address] CreateSubCompany address
             * @property {fastcity.common.ICoordinate|null} [coordinate] CreateSubCompany coordinate
             * @property {number|Long|null} [updateOrdersInterval] CreateSubCompany updateOrdersInterval
             * @property {number|Long|null} [averageDeliveryTime] CreateSubCompany averageDeliveryTime
             * @property {boolean|null} [ordersLatitudeLongitudeSwap] CreateSubCompany ordersLatitudeLongitudeSwap
             */

            /**
             * Constructs a new CreateSubCompany.
             * @memberof fastcity.admin
             * @classdesc Represents a CreateSubCompany.
             * @implements ICreateSubCompany
             * @constructor
             * @param {fastcity.admin.ICreateSubCompany=} [properties] Properties to set
             */
            function CreateSubCompany(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateSubCompany companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.CreateSubCompany
             * @instance
             */
            CreateSubCompany.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateSubCompany externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.CreateSubCompany
             * @instance
             */
            CreateSubCompany.prototype.externalSubCompanyId = "";

            /**
             * CreateSubCompany address.
             * @member {Uint8Array} address
             * @memberof fastcity.admin.CreateSubCompany
             * @instance
             */
            CreateSubCompany.prototype.address = $util.newBuffer([]);

            /**
             * CreateSubCompany coordinate.
             * @member {fastcity.common.ICoordinate|null|undefined} coordinate
             * @memberof fastcity.admin.CreateSubCompany
             * @instance
             */
            CreateSubCompany.prototype.coordinate = null;

            /**
             * CreateSubCompany updateOrdersInterval.
             * @member {number|Long} updateOrdersInterval
             * @memberof fastcity.admin.CreateSubCompany
             * @instance
             */
            CreateSubCompany.prototype.updateOrdersInterval = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * CreateSubCompany averageDeliveryTime.
             * @member {number|Long} averageDeliveryTime
             * @memberof fastcity.admin.CreateSubCompany
             * @instance
             */
            CreateSubCompany.prototype.averageDeliveryTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * CreateSubCompany ordersLatitudeLongitudeSwap.
             * @member {boolean} ordersLatitudeLongitudeSwap
             * @memberof fastcity.admin.CreateSubCompany
             * @instance
             */
            CreateSubCompany.prototype.ordersLatitudeLongitudeSwap = false;

            /**
             * Creates a new CreateSubCompany instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CreateSubCompany
             * @static
             * @param {fastcity.admin.ICreateSubCompany=} [properties] Properties to set
             * @returns {fastcity.admin.CreateSubCompany} CreateSubCompany instance
             */
            CreateSubCompany.create = function create(properties) {
                return new CreateSubCompany(properties);
            };

            /**
             * Encodes the specified CreateSubCompany message. Does not implicitly {@link fastcity.admin.CreateSubCompany.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CreateSubCompany
             * @static
             * @param {fastcity.admin.ICreateSubCompany} message CreateSubCompany message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateSubCompany.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalSubCompanyId);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.address);
                if (message.coordinate != null && Object.hasOwnProperty.call(message, "coordinate"))
                    $root.fastcity.common.Coordinate.encode(message.coordinate, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.updateOrdersInterval != null && Object.hasOwnProperty.call(message, "updateOrdersInterval"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.updateOrdersInterval);
                if (message.averageDeliveryTime != null && Object.hasOwnProperty.call(message, "averageDeliveryTime"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.averageDeliveryTime);
                if (message.ordersLatitudeLongitudeSwap != null && Object.hasOwnProperty.call(message, "ordersLatitudeLongitudeSwap"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.ordersLatitudeLongitudeSwap);
                return writer;
            };

            /**
             * Decodes a CreateSubCompany message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CreateSubCompany
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CreateSubCompany} CreateSubCompany
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateSubCompany.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CreateSubCompany();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.externalSubCompanyId = reader.string();
                        break;
                    case 3:
                        message.address = reader.bytes();
                        break;
                    case 4:
                        message.coordinate = $root.fastcity.common.Coordinate.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.updateOrdersInterval = reader.int64();
                        break;
                    case 6:
                        message.averageDeliveryTime = reader.int64();
                        break;
                    case 7:
                        message.ordersLatitudeLongitudeSwap = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CreateSubCompany message.
             * @function verify
             * @memberof fastcity.admin.CreateSubCompany
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateSubCompany.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!(message.address && typeof message.address.length === "number" || $util.isString(message.address)))
                        return "address: buffer expected";
                if (message.coordinate != null && message.hasOwnProperty("coordinate")) {
                    var error = $root.fastcity.common.Coordinate.verify(message.coordinate);
                    if (error)
                        return "coordinate." + error;
                }
                if (message.updateOrdersInterval != null && message.hasOwnProperty("updateOrdersInterval"))
                    if (!$util.isInteger(message.updateOrdersInterval) && !(message.updateOrdersInterval && $util.isInteger(message.updateOrdersInterval.low) && $util.isInteger(message.updateOrdersInterval.high)))
                        return "updateOrdersInterval: integer|Long expected";
                if (message.averageDeliveryTime != null && message.hasOwnProperty("averageDeliveryTime"))
                    if (!$util.isInteger(message.averageDeliveryTime) && !(message.averageDeliveryTime && $util.isInteger(message.averageDeliveryTime.low) && $util.isInteger(message.averageDeliveryTime.high)))
                        return "averageDeliveryTime: integer|Long expected";
                if (message.ordersLatitudeLongitudeSwap != null && message.hasOwnProperty("ordersLatitudeLongitudeSwap"))
                    if (typeof message.ordersLatitudeLongitudeSwap !== "boolean")
                        return "ordersLatitudeLongitudeSwap: boolean expected";
                return null;
            };

            /**
             * Creates a CreateSubCompany message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CreateSubCompany
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CreateSubCompany} CreateSubCompany
             */
            CreateSubCompany.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CreateSubCompany)
                    return object;
                var message = new $root.fastcity.admin.CreateSubCompany();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                if (object.address != null)
                    if (typeof object.address === "string")
                        $util.base64.decode(object.address, message.address = $util.newBuffer($util.base64.length(object.address)), 0);
                    else if (object.address.length)
                        message.address = object.address;
                if (object.coordinate != null) {
                    if (typeof object.coordinate !== "object")
                        throw TypeError(".fastcity.admin.CreateSubCompany.coordinate: object expected");
                    message.coordinate = $root.fastcity.common.Coordinate.fromObject(object.coordinate);
                }
                if (object.updateOrdersInterval != null)
                    if ($util.Long)
                        (message.updateOrdersInterval = $util.Long.fromValue(object.updateOrdersInterval)).unsigned = false;
                    else if (typeof object.updateOrdersInterval === "string")
                        message.updateOrdersInterval = parseInt(object.updateOrdersInterval, 10);
                    else if (typeof object.updateOrdersInterval === "number")
                        message.updateOrdersInterval = object.updateOrdersInterval;
                    else if (typeof object.updateOrdersInterval === "object")
                        message.updateOrdersInterval = new $util.LongBits(object.updateOrdersInterval.low >>> 0, object.updateOrdersInterval.high >>> 0).toNumber();
                if (object.averageDeliveryTime != null)
                    if ($util.Long)
                        (message.averageDeliveryTime = $util.Long.fromValue(object.averageDeliveryTime)).unsigned = false;
                    else if (typeof object.averageDeliveryTime === "string")
                        message.averageDeliveryTime = parseInt(object.averageDeliveryTime, 10);
                    else if (typeof object.averageDeliveryTime === "number")
                        message.averageDeliveryTime = object.averageDeliveryTime;
                    else if (typeof object.averageDeliveryTime === "object")
                        message.averageDeliveryTime = new $util.LongBits(object.averageDeliveryTime.low >>> 0, object.averageDeliveryTime.high >>> 0).toNumber();
                if (object.ordersLatitudeLongitudeSwap != null)
                    message.ordersLatitudeLongitudeSwap = Boolean(object.ordersLatitudeLongitudeSwap);
                return message;
            };

            /**
             * Creates a plain object from a CreateSubCompany message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CreateSubCompany
             * @static
             * @param {fastcity.admin.CreateSubCompany} message CreateSubCompany
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateSubCompany.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                    if (options.bytes === String)
                        object.address = "";
                    else {
                        object.address = [];
                        if (options.bytes !== Array)
                            object.address = $util.newBuffer(object.address);
                    }
                    object.coordinate = null;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.updateOrdersInterval = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.updateOrdersInterval = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.averageDeliveryTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.averageDeliveryTime = options.longs === String ? "0" : 0;
                    object.ordersLatitudeLongitudeSwap = false;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = options.bytes === String ? $util.base64.encode(message.address, 0, message.address.length) : options.bytes === Array ? Array.prototype.slice.call(message.address) : message.address;
                if (message.coordinate != null && message.hasOwnProperty("coordinate"))
                    object.coordinate = $root.fastcity.common.Coordinate.toObject(message.coordinate, options);
                if (message.updateOrdersInterval != null && message.hasOwnProperty("updateOrdersInterval"))
                    if (typeof message.updateOrdersInterval === "number")
                        object.updateOrdersInterval = options.longs === String ? String(message.updateOrdersInterval) : message.updateOrdersInterval;
                    else
                        object.updateOrdersInterval = options.longs === String ? $util.Long.prototype.toString.call(message.updateOrdersInterval) : options.longs === Number ? new $util.LongBits(message.updateOrdersInterval.low >>> 0, message.updateOrdersInterval.high >>> 0).toNumber() : message.updateOrdersInterval;
                if (message.averageDeliveryTime != null && message.hasOwnProperty("averageDeliveryTime"))
                    if (typeof message.averageDeliveryTime === "number")
                        object.averageDeliveryTime = options.longs === String ? String(message.averageDeliveryTime) : message.averageDeliveryTime;
                    else
                        object.averageDeliveryTime = options.longs === String ? $util.Long.prototype.toString.call(message.averageDeliveryTime) : options.longs === Number ? new $util.LongBits(message.averageDeliveryTime.low >>> 0, message.averageDeliveryTime.high >>> 0).toNumber() : message.averageDeliveryTime;
                if (message.ordersLatitudeLongitudeSwap != null && message.hasOwnProperty("ordersLatitudeLongitudeSwap"))
                    object.ordersLatitudeLongitudeSwap = message.ordersLatitudeLongitudeSwap;
                return object;
            };

            /**
             * Converts this CreateSubCompany to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CreateSubCompany
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateSubCompany.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CreateSubCompany;
        })();

        admin.UpdateSubCompany = (function() {

            /**
             * Properties of an UpdateSubCompany.
             * @memberof fastcity.admin
             * @interface IUpdateSubCompany
             * @property {number|Long|null} [companyId] UpdateSubCompany companyId
             * @property {string|null} [externalSubCompanyId] UpdateSubCompany externalSubCompanyId
             * @property {Uint8Array|null} [address] UpdateSubCompany address
             * @property {fastcity.common.ICoordinate|null} [coordinate] UpdateSubCompany coordinate
             * @property {number|Long|null} [minimalDistanceToOrderTarget] UpdateSubCompany minimalDistanceToOrderTarget
             * @property {number|Long|null} [updateOrdersInterval] UpdateSubCompany updateOrdersInterval
             * @property {number|Long|null} [averageDeliveryTime] UpdateSubCompany averageDeliveryTime
             * @property {boolean|null} [ordersLatitudeLongitudeSwap] UpdateSubCompany ordersLatitudeLongitudeSwap
             */

            /**
             * Constructs a new UpdateSubCompany.
             * @memberof fastcity.admin
             * @classdesc Represents an UpdateSubCompany.
             * @implements IUpdateSubCompany
             * @constructor
             * @param {fastcity.admin.IUpdateSubCompany=} [properties] Properties to set
             */
            function UpdateSubCompany(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateSubCompany companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.UpdateSubCompany
             * @instance
             */
            UpdateSubCompany.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateSubCompany externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.UpdateSubCompany
             * @instance
             */
            UpdateSubCompany.prototype.externalSubCompanyId = "";

            /**
             * UpdateSubCompany address.
             * @member {Uint8Array} address
             * @memberof fastcity.admin.UpdateSubCompany
             * @instance
             */
            UpdateSubCompany.prototype.address = $util.newBuffer([]);

            /**
             * UpdateSubCompany coordinate.
             * @member {fastcity.common.ICoordinate|null|undefined} coordinate
             * @memberof fastcity.admin.UpdateSubCompany
             * @instance
             */
            UpdateSubCompany.prototype.coordinate = null;

            /**
             * UpdateSubCompany minimalDistanceToOrderTarget.
             * @member {number|Long} minimalDistanceToOrderTarget
             * @memberof fastcity.admin.UpdateSubCompany
             * @instance
             */
            UpdateSubCompany.prototype.minimalDistanceToOrderTarget = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UpdateSubCompany updateOrdersInterval.
             * @member {number|Long} updateOrdersInterval
             * @memberof fastcity.admin.UpdateSubCompany
             * @instance
             */
            UpdateSubCompany.prototype.updateOrdersInterval = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UpdateSubCompany averageDeliveryTime.
             * @member {number|Long} averageDeliveryTime
             * @memberof fastcity.admin.UpdateSubCompany
             * @instance
             */
            UpdateSubCompany.prototype.averageDeliveryTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UpdateSubCompany ordersLatitudeLongitudeSwap.
             * @member {boolean} ordersLatitudeLongitudeSwap
             * @memberof fastcity.admin.UpdateSubCompany
             * @instance
             */
            UpdateSubCompany.prototype.ordersLatitudeLongitudeSwap = false;

            /**
             * Creates a new UpdateSubCompany instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.UpdateSubCompany
             * @static
             * @param {fastcity.admin.IUpdateSubCompany=} [properties] Properties to set
             * @returns {fastcity.admin.UpdateSubCompany} UpdateSubCompany instance
             */
            UpdateSubCompany.create = function create(properties) {
                return new UpdateSubCompany(properties);
            };

            /**
             * Encodes the specified UpdateSubCompany message. Does not implicitly {@link fastcity.admin.UpdateSubCompany.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.UpdateSubCompany
             * @static
             * @param {fastcity.admin.IUpdateSubCompany} message UpdateSubCompany message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateSubCompany.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalSubCompanyId);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.address);
                if (message.coordinate != null && Object.hasOwnProperty.call(message, "coordinate"))
                    $root.fastcity.common.Coordinate.encode(message.coordinate, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.minimalDistanceToOrderTarget != null && Object.hasOwnProperty.call(message, "minimalDistanceToOrderTarget"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.minimalDistanceToOrderTarget);
                if (message.updateOrdersInterval != null && Object.hasOwnProperty.call(message, "updateOrdersInterval"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.updateOrdersInterval);
                if (message.averageDeliveryTime != null && Object.hasOwnProperty.call(message, "averageDeliveryTime"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.averageDeliveryTime);
                if (message.ordersLatitudeLongitudeSwap != null && Object.hasOwnProperty.call(message, "ordersLatitudeLongitudeSwap"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.ordersLatitudeLongitudeSwap);
                return writer;
            };

            /**
             * Decodes an UpdateSubCompany message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.UpdateSubCompany
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.UpdateSubCompany} UpdateSubCompany
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateSubCompany.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.UpdateSubCompany();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.externalSubCompanyId = reader.string();
                        break;
                    case 3:
                        message.address = reader.bytes();
                        break;
                    case 4:
                        message.coordinate = $root.fastcity.common.Coordinate.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.minimalDistanceToOrderTarget = reader.int64();
                        break;
                    case 6:
                        message.updateOrdersInterval = reader.int64();
                        break;
                    case 7:
                        message.averageDeliveryTime = reader.int64();
                        break;
                    case 8:
                        message.ordersLatitudeLongitudeSwap = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateSubCompany message.
             * @function verify
             * @memberof fastcity.admin.UpdateSubCompany
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateSubCompany.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!(message.address && typeof message.address.length === "number" || $util.isString(message.address)))
                        return "address: buffer expected";
                if (message.coordinate != null && message.hasOwnProperty("coordinate")) {
                    var error = $root.fastcity.common.Coordinate.verify(message.coordinate);
                    if (error)
                        return "coordinate." + error;
                }
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (!$util.isInteger(message.minimalDistanceToOrderTarget) && !(message.minimalDistanceToOrderTarget && $util.isInteger(message.minimalDistanceToOrderTarget.low) && $util.isInteger(message.minimalDistanceToOrderTarget.high)))
                        return "minimalDistanceToOrderTarget: integer|Long expected";
                if (message.updateOrdersInterval != null && message.hasOwnProperty("updateOrdersInterval"))
                    if (!$util.isInteger(message.updateOrdersInterval) && !(message.updateOrdersInterval && $util.isInteger(message.updateOrdersInterval.low) && $util.isInteger(message.updateOrdersInterval.high)))
                        return "updateOrdersInterval: integer|Long expected";
                if (message.averageDeliveryTime != null && message.hasOwnProperty("averageDeliveryTime"))
                    if (!$util.isInteger(message.averageDeliveryTime) && !(message.averageDeliveryTime && $util.isInteger(message.averageDeliveryTime.low) && $util.isInteger(message.averageDeliveryTime.high)))
                        return "averageDeliveryTime: integer|Long expected";
                if (message.ordersLatitudeLongitudeSwap != null && message.hasOwnProperty("ordersLatitudeLongitudeSwap"))
                    if (typeof message.ordersLatitudeLongitudeSwap !== "boolean")
                        return "ordersLatitudeLongitudeSwap: boolean expected";
                return null;
            };

            /**
             * Creates an UpdateSubCompany message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.UpdateSubCompany
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.UpdateSubCompany} UpdateSubCompany
             */
            UpdateSubCompany.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.UpdateSubCompany)
                    return object;
                var message = new $root.fastcity.admin.UpdateSubCompany();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                if (object.address != null)
                    if (typeof object.address === "string")
                        $util.base64.decode(object.address, message.address = $util.newBuffer($util.base64.length(object.address)), 0);
                    else if (object.address.length)
                        message.address = object.address;
                if (object.coordinate != null) {
                    if (typeof object.coordinate !== "object")
                        throw TypeError(".fastcity.admin.UpdateSubCompany.coordinate: object expected");
                    message.coordinate = $root.fastcity.common.Coordinate.fromObject(object.coordinate);
                }
                if (object.minimalDistanceToOrderTarget != null)
                    if ($util.Long)
                        (message.minimalDistanceToOrderTarget = $util.Long.fromValue(object.minimalDistanceToOrderTarget)).unsigned = false;
                    else if (typeof object.minimalDistanceToOrderTarget === "string")
                        message.minimalDistanceToOrderTarget = parseInt(object.minimalDistanceToOrderTarget, 10);
                    else if (typeof object.minimalDistanceToOrderTarget === "number")
                        message.minimalDistanceToOrderTarget = object.minimalDistanceToOrderTarget;
                    else if (typeof object.minimalDistanceToOrderTarget === "object")
                        message.minimalDistanceToOrderTarget = new $util.LongBits(object.minimalDistanceToOrderTarget.low >>> 0, object.minimalDistanceToOrderTarget.high >>> 0).toNumber();
                if (object.updateOrdersInterval != null)
                    if ($util.Long)
                        (message.updateOrdersInterval = $util.Long.fromValue(object.updateOrdersInterval)).unsigned = false;
                    else if (typeof object.updateOrdersInterval === "string")
                        message.updateOrdersInterval = parseInt(object.updateOrdersInterval, 10);
                    else if (typeof object.updateOrdersInterval === "number")
                        message.updateOrdersInterval = object.updateOrdersInterval;
                    else if (typeof object.updateOrdersInterval === "object")
                        message.updateOrdersInterval = new $util.LongBits(object.updateOrdersInterval.low >>> 0, object.updateOrdersInterval.high >>> 0).toNumber();
                if (object.averageDeliveryTime != null)
                    if ($util.Long)
                        (message.averageDeliveryTime = $util.Long.fromValue(object.averageDeliveryTime)).unsigned = false;
                    else if (typeof object.averageDeliveryTime === "string")
                        message.averageDeliveryTime = parseInt(object.averageDeliveryTime, 10);
                    else if (typeof object.averageDeliveryTime === "number")
                        message.averageDeliveryTime = object.averageDeliveryTime;
                    else if (typeof object.averageDeliveryTime === "object")
                        message.averageDeliveryTime = new $util.LongBits(object.averageDeliveryTime.low >>> 0, object.averageDeliveryTime.high >>> 0).toNumber();
                if (object.ordersLatitudeLongitudeSwap != null)
                    message.ordersLatitudeLongitudeSwap = Boolean(object.ordersLatitudeLongitudeSwap);
                return message;
            };

            /**
             * Creates a plain object from an UpdateSubCompany message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.UpdateSubCompany
             * @static
             * @param {fastcity.admin.UpdateSubCompany} message UpdateSubCompany
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateSubCompany.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                    if (options.bytes === String)
                        object.address = "";
                    else {
                        object.address = [];
                        if (options.bytes !== Array)
                            object.address = $util.newBuffer(object.address);
                    }
                    object.coordinate = null;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.minimalDistanceToOrderTarget = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.minimalDistanceToOrderTarget = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.updateOrdersInterval = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.updateOrdersInterval = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.averageDeliveryTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.averageDeliveryTime = options.longs === String ? "0" : 0;
                    object.ordersLatitudeLongitudeSwap = false;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = options.bytes === String ? $util.base64.encode(message.address, 0, message.address.length) : options.bytes === Array ? Array.prototype.slice.call(message.address) : message.address;
                if (message.coordinate != null && message.hasOwnProperty("coordinate"))
                    object.coordinate = $root.fastcity.common.Coordinate.toObject(message.coordinate, options);
                if (message.minimalDistanceToOrderTarget != null && message.hasOwnProperty("minimalDistanceToOrderTarget"))
                    if (typeof message.minimalDistanceToOrderTarget === "number")
                        object.minimalDistanceToOrderTarget = options.longs === String ? String(message.minimalDistanceToOrderTarget) : message.minimalDistanceToOrderTarget;
                    else
                        object.minimalDistanceToOrderTarget = options.longs === String ? $util.Long.prototype.toString.call(message.minimalDistanceToOrderTarget) : options.longs === Number ? new $util.LongBits(message.minimalDistanceToOrderTarget.low >>> 0, message.minimalDistanceToOrderTarget.high >>> 0).toNumber() : message.minimalDistanceToOrderTarget;
                if (message.updateOrdersInterval != null && message.hasOwnProperty("updateOrdersInterval"))
                    if (typeof message.updateOrdersInterval === "number")
                        object.updateOrdersInterval = options.longs === String ? String(message.updateOrdersInterval) : message.updateOrdersInterval;
                    else
                        object.updateOrdersInterval = options.longs === String ? $util.Long.prototype.toString.call(message.updateOrdersInterval) : options.longs === Number ? new $util.LongBits(message.updateOrdersInterval.low >>> 0, message.updateOrdersInterval.high >>> 0).toNumber() : message.updateOrdersInterval;
                if (message.averageDeliveryTime != null && message.hasOwnProperty("averageDeliveryTime"))
                    if (typeof message.averageDeliveryTime === "number")
                        object.averageDeliveryTime = options.longs === String ? String(message.averageDeliveryTime) : message.averageDeliveryTime;
                    else
                        object.averageDeliveryTime = options.longs === String ? $util.Long.prototype.toString.call(message.averageDeliveryTime) : options.longs === Number ? new $util.LongBits(message.averageDeliveryTime.low >>> 0, message.averageDeliveryTime.high >>> 0).toNumber() : message.averageDeliveryTime;
                if (message.ordersLatitudeLongitudeSwap != null && message.hasOwnProperty("ordersLatitudeLongitudeSwap"))
                    object.ordersLatitudeLongitudeSwap = message.ordersLatitudeLongitudeSwap;
                return object;
            };

            /**
             * Converts this UpdateSubCompany to JSON.
             * @function toJSON
             * @memberof fastcity.admin.UpdateSubCompany
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateSubCompany.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateSubCompany;
        })();

        admin.DeleteSubCompany = (function() {

            /**
             * Properties of a DeleteSubCompany.
             * @memberof fastcity.admin
             * @interface IDeleteSubCompany
             * @property {number|Long|null} [companyId] DeleteSubCompany companyId
             * @property {string|null} [externalSubCompanyId] DeleteSubCompany externalSubCompanyId
             */

            /**
             * Constructs a new DeleteSubCompany.
             * @memberof fastcity.admin
             * @classdesc Represents a DeleteSubCompany.
             * @implements IDeleteSubCompany
             * @constructor
             * @param {fastcity.admin.IDeleteSubCompany=} [properties] Properties to set
             */
            function DeleteSubCompany(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteSubCompany companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.DeleteSubCompany
             * @instance
             */
            DeleteSubCompany.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * DeleteSubCompany externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.DeleteSubCompany
             * @instance
             */
            DeleteSubCompany.prototype.externalSubCompanyId = "";

            /**
             * Creates a new DeleteSubCompany instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DeleteSubCompany
             * @static
             * @param {fastcity.admin.IDeleteSubCompany=} [properties] Properties to set
             * @returns {fastcity.admin.DeleteSubCompany} DeleteSubCompany instance
             */
            DeleteSubCompany.create = function create(properties) {
                return new DeleteSubCompany(properties);
            };

            /**
             * Encodes the specified DeleteSubCompany message. Does not implicitly {@link fastcity.admin.DeleteSubCompany.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DeleteSubCompany
             * @static
             * @param {fastcity.admin.IDeleteSubCompany} message DeleteSubCompany message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteSubCompany.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalSubCompanyId);
                return writer;
            };

            /**
             * Decodes a DeleteSubCompany message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DeleteSubCompany
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DeleteSubCompany} DeleteSubCompany
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteSubCompany.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DeleteSubCompany();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.externalSubCompanyId = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeleteSubCompany message.
             * @function verify
             * @memberof fastcity.admin.DeleteSubCompany
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteSubCompany.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                return null;
            };

            /**
             * Creates a DeleteSubCompany message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DeleteSubCompany
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DeleteSubCompany} DeleteSubCompany
             */
            DeleteSubCompany.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DeleteSubCompany)
                    return object;
                var message = new $root.fastcity.admin.DeleteSubCompany();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                return message;
            };

            /**
             * Creates a plain object from a DeleteSubCompany message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DeleteSubCompany
             * @static
             * @param {fastcity.admin.DeleteSubCompany} message DeleteSubCompany
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteSubCompany.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                return object;
            };

            /**
             * Converts this DeleteSubCompany to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DeleteSubCompany
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteSubCompany.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeleteSubCompany;
        })();

        admin.GetSubCompanyList = (function() {

            /**
             * Properties of a GetSubCompanyList.
             * @memberof fastcity.admin
             * @interface IGetSubCompanyList
             * @property {number|Long|null} [companyId] GetSubCompanyList companyId
             * @property {Array.<string>|null} [externalSubCompanyId] GetSubCompanyList externalSubCompanyId
             * @property {boolean|null} [withDeleted] GetSubCompanyList withDeleted
             */

            /**
             * Constructs a new GetSubCompanyList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetSubCompanyList.
             * @implements IGetSubCompanyList
             * @constructor
             * @param {fastcity.admin.IGetSubCompanyList=} [properties] Properties to set
             */
            function GetSubCompanyList(properties) {
                this.externalSubCompanyId = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetSubCompanyList companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.GetSubCompanyList
             * @instance
             */
            GetSubCompanyList.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetSubCompanyList externalSubCompanyId.
             * @member {Array.<string>} externalSubCompanyId
             * @memberof fastcity.admin.GetSubCompanyList
             * @instance
             */
            GetSubCompanyList.prototype.externalSubCompanyId = $util.emptyArray;

            /**
             * GetSubCompanyList withDeleted.
             * @member {boolean} withDeleted
             * @memberof fastcity.admin.GetSubCompanyList
             * @instance
             */
            GetSubCompanyList.prototype.withDeleted = false;

            /**
             * Creates a new GetSubCompanyList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetSubCompanyList
             * @static
             * @param {fastcity.admin.IGetSubCompanyList=} [properties] Properties to set
             * @returns {fastcity.admin.GetSubCompanyList} GetSubCompanyList instance
             */
            GetSubCompanyList.create = function create(properties) {
                return new GetSubCompanyList(properties);
            };

            /**
             * Encodes the specified GetSubCompanyList message. Does not implicitly {@link fastcity.admin.GetSubCompanyList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetSubCompanyList
             * @static
             * @param {fastcity.admin.IGetSubCompanyList} message GetSubCompanyList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSubCompanyList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.externalSubCompanyId != null && message.externalSubCompanyId.length)
                    for (var i = 0; i < message.externalSubCompanyId.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalSubCompanyId[i]);
                if (message.withDeleted != null && Object.hasOwnProperty.call(message, "withDeleted"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.withDeleted);
                return writer;
            };

            /**
             * Decodes a GetSubCompanyList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetSubCompanyList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetSubCompanyList} GetSubCompanyList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSubCompanyList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetSubCompanyList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        if (!(message.externalSubCompanyId && message.externalSubCompanyId.length))
                            message.externalSubCompanyId = [];
                        message.externalSubCompanyId.push(reader.string());
                        break;
                    case 3:
                        message.withDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetSubCompanyList message.
             * @function verify
             * @memberof fastcity.admin.GetSubCompanyList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetSubCompanyList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId")) {
                    if (!Array.isArray(message.externalSubCompanyId))
                        return "externalSubCompanyId: array expected";
                    for (var i = 0; i < message.externalSubCompanyId.length; ++i)
                        if (!$util.isString(message.externalSubCompanyId[i]))
                            return "externalSubCompanyId: string[] expected";
                }
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    if (typeof message.withDeleted !== "boolean")
                        return "withDeleted: boolean expected";
                return null;
            };

            /**
             * Creates a GetSubCompanyList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetSubCompanyList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetSubCompanyList} GetSubCompanyList
             */
            GetSubCompanyList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetSubCompanyList)
                    return object;
                var message = new $root.fastcity.admin.GetSubCompanyList();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId) {
                    if (!Array.isArray(object.externalSubCompanyId))
                        throw TypeError(".fastcity.admin.GetSubCompanyList.externalSubCompanyId: array expected");
                    message.externalSubCompanyId = [];
                    for (var i = 0; i < object.externalSubCompanyId.length; ++i)
                        message.externalSubCompanyId[i] = String(object.externalSubCompanyId[i]);
                }
                if (object.withDeleted != null)
                    message.withDeleted = Boolean(object.withDeleted);
                return message;
            };

            /**
             * Creates a plain object from a GetSubCompanyList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetSubCompanyList
             * @static
             * @param {fastcity.admin.GetSubCompanyList} message GetSubCompanyList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetSubCompanyList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.externalSubCompanyId = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.withDeleted = false;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId && message.externalSubCompanyId.length) {
                    object.externalSubCompanyId = [];
                    for (var j = 0; j < message.externalSubCompanyId.length; ++j)
                        object.externalSubCompanyId[j] = message.externalSubCompanyId[j];
                }
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    object.withDeleted = message.withDeleted;
                return object;
            };

            /**
             * Converts this GetSubCompanyList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetSubCompanyList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetSubCompanyList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetSubCompanyList;
        })();

        admin.SubCompanyList = (function() {

            /**
             * Properties of a SubCompanyList.
             * @memberof fastcity.admin
             * @interface ISubCompanyList
             * @property {Array.<fastcity.admin.ISubCompany>|null} [subCompany] SubCompanyList subCompany
             */

            /**
             * Constructs a new SubCompanyList.
             * @memberof fastcity.admin
             * @classdesc Represents a SubCompanyList.
             * @implements ISubCompanyList
             * @constructor
             * @param {fastcity.admin.ISubCompanyList=} [properties] Properties to set
             */
            function SubCompanyList(properties) {
                this.subCompany = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SubCompanyList subCompany.
             * @member {Array.<fastcity.admin.ISubCompany>} subCompany
             * @memberof fastcity.admin.SubCompanyList
             * @instance
             */
            SubCompanyList.prototype.subCompany = $util.emptyArray;

            /**
             * Creates a new SubCompanyList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.SubCompanyList
             * @static
             * @param {fastcity.admin.ISubCompanyList=} [properties] Properties to set
             * @returns {fastcity.admin.SubCompanyList} SubCompanyList instance
             */
            SubCompanyList.create = function create(properties) {
                return new SubCompanyList(properties);
            };

            /**
             * Encodes the specified SubCompanyList message. Does not implicitly {@link fastcity.admin.SubCompanyList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.SubCompanyList
             * @static
             * @param {fastcity.admin.ISubCompanyList} message SubCompanyList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubCompanyList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompany != null && message.subCompany.length)
                    for (var i = 0; i < message.subCompany.length; ++i)
                        $root.fastcity.admin.SubCompany.encode(message.subCompany[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a SubCompanyList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.SubCompanyList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.SubCompanyList} SubCompanyList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubCompanyList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.SubCompanyList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.subCompany && message.subCompany.length))
                            message.subCompany = [];
                        message.subCompany.push($root.fastcity.admin.SubCompany.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SubCompanyList message.
             * @function verify
             * @memberof fastcity.admin.SubCompanyList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SubCompanyList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompany != null && message.hasOwnProperty("subCompany")) {
                    if (!Array.isArray(message.subCompany))
                        return "subCompany: array expected";
                    for (var i = 0; i < message.subCompany.length; ++i) {
                        var error = $root.fastcity.admin.SubCompany.verify(message.subCompany[i]);
                        if (error)
                            return "subCompany." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SubCompanyList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.SubCompanyList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.SubCompanyList} SubCompanyList
             */
            SubCompanyList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.SubCompanyList)
                    return object;
                var message = new $root.fastcity.admin.SubCompanyList();
                if (object.subCompany) {
                    if (!Array.isArray(object.subCompany))
                        throw TypeError(".fastcity.admin.SubCompanyList.subCompany: array expected");
                    message.subCompany = [];
                    for (var i = 0; i < object.subCompany.length; ++i) {
                        if (typeof object.subCompany[i] !== "object")
                            throw TypeError(".fastcity.admin.SubCompanyList.subCompany: object expected");
                        message.subCompany[i] = $root.fastcity.admin.SubCompany.fromObject(object.subCompany[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SubCompanyList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.SubCompanyList
             * @static
             * @param {fastcity.admin.SubCompanyList} message SubCompanyList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SubCompanyList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.subCompany = [];
                if (message.subCompany && message.subCompany.length) {
                    object.subCompany = [];
                    for (var j = 0; j < message.subCompany.length; ++j)
                        object.subCompany[j] = $root.fastcity.admin.SubCompany.toObject(message.subCompany[j], options);
                }
                return object;
            };

            /**
             * Converts this SubCompanyList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.SubCompanyList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SubCompanyList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SubCompanyList;
        })();

        admin.Polygon = (function() {

            /**
             * Properties of a Polygon.
             * @memberof fastcity.admin
             * @interface IPolygon
             * @property {Array.<fastcity.common.ICoordinate>|null} [coordinates] Polygon coordinates
             */

            /**
             * Constructs a new Polygon.
             * @memberof fastcity.admin
             * @classdesc Represents a Polygon.
             * @implements IPolygon
             * @constructor
             * @param {fastcity.admin.IPolygon=} [properties] Properties to set
             */
            function Polygon(properties) {
                this.coordinates = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Polygon coordinates.
             * @member {Array.<fastcity.common.ICoordinate>} coordinates
             * @memberof fastcity.admin.Polygon
             * @instance
             */
            Polygon.prototype.coordinates = $util.emptyArray;

            /**
             * Creates a new Polygon instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.Polygon
             * @static
             * @param {fastcity.admin.IPolygon=} [properties] Properties to set
             * @returns {fastcity.admin.Polygon} Polygon instance
             */
            Polygon.create = function create(properties) {
                return new Polygon(properties);
            };

            /**
             * Encodes the specified Polygon message. Does not implicitly {@link fastcity.admin.Polygon.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.Polygon
             * @static
             * @param {fastcity.admin.IPolygon} message Polygon message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Polygon.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.coordinates != null && message.coordinates.length)
                    for (var i = 0; i < message.coordinates.length; ++i)
                        $root.fastcity.common.Coordinate.encode(message.coordinates[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a Polygon message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.Polygon
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.Polygon} Polygon
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Polygon.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.Polygon();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.coordinates && message.coordinates.length))
                            message.coordinates = [];
                        message.coordinates.push($root.fastcity.common.Coordinate.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Polygon message.
             * @function verify
             * @memberof fastcity.admin.Polygon
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Polygon.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.coordinates != null && message.hasOwnProperty("coordinates")) {
                    if (!Array.isArray(message.coordinates))
                        return "coordinates: array expected";
                    for (var i = 0; i < message.coordinates.length; ++i) {
                        var error = $root.fastcity.common.Coordinate.verify(message.coordinates[i]);
                        if (error)
                            return "coordinates." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a Polygon message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.Polygon
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.Polygon} Polygon
             */
            Polygon.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.Polygon)
                    return object;
                var message = new $root.fastcity.admin.Polygon();
                if (object.coordinates) {
                    if (!Array.isArray(object.coordinates))
                        throw TypeError(".fastcity.admin.Polygon.coordinates: array expected");
                    message.coordinates = [];
                    for (var i = 0; i < object.coordinates.length; ++i) {
                        if (typeof object.coordinates[i] !== "object")
                            throw TypeError(".fastcity.admin.Polygon.coordinates: object expected");
                        message.coordinates[i] = $root.fastcity.common.Coordinate.fromObject(object.coordinates[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a Polygon message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.Polygon
             * @static
             * @param {fastcity.admin.Polygon} message Polygon
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Polygon.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.coordinates = [];
                if (message.coordinates && message.coordinates.length) {
                    object.coordinates = [];
                    for (var j = 0; j < message.coordinates.length; ++j)
                        object.coordinates[j] = $root.fastcity.common.Coordinate.toObject(message.coordinates[j], options);
                }
                return object;
            };

            /**
             * Converts this Polygon to JSON.
             * @function toJSON
             * @memberof fastcity.admin.Polygon
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Polygon.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Polygon;
        })();

        admin.SubCompanyZone = (function() {

            /**
             * Properties of a SubCompanyZone.
             * @memberof fastcity.admin
             * @interface ISubCompanyZone
             * @property {number|Long|null} [subCompanyZoneId] SubCompanyZone subCompanyZoneId
             * @property {number|Long|null} [companyId] SubCompanyZone companyId
             * @property {string|null} [externalSubCompanyId] SubCompanyZone externalSubCompanyId
             * @property {string|null} [name] SubCompanyZone name
             * @property {fastcity.admin.IPolygon|null} [polygon] SubCompanyZone polygon
             * @property {string|null} [color] SubCompanyZone color
             * @property {boolean|null} [isDisabled] SubCompanyZone isDisabled
             * @property {boolean|null} [isDeleted] SubCompanyZone isDeleted
             */

            /**
             * Constructs a new SubCompanyZone.
             * @memberof fastcity.admin
             * @classdesc Represents a SubCompanyZone.
             * @implements ISubCompanyZone
             * @constructor
             * @param {fastcity.admin.ISubCompanyZone=} [properties] Properties to set
             */
            function SubCompanyZone(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SubCompanyZone subCompanyZoneId.
             * @member {number|Long} subCompanyZoneId
             * @memberof fastcity.admin.SubCompanyZone
             * @instance
             */
            SubCompanyZone.prototype.subCompanyZoneId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SubCompanyZone companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.SubCompanyZone
             * @instance
             */
            SubCompanyZone.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SubCompanyZone externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.SubCompanyZone
             * @instance
             */
            SubCompanyZone.prototype.externalSubCompanyId = "";

            /**
             * SubCompanyZone name.
             * @member {string} name
             * @memberof fastcity.admin.SubCompanyZone
             * @instance
             */
            SubCompanyZone.prototype.name = "";

            /**
             * SubCompanyZone polygon.
             * @member {fastcity.admin.IPolygon|null|undefined} polygon
             * @memberof fastcity.admin.SubCompanyZone
             * @instance
             */
            SubCompanyZone.prototype.polygon = null;

            /**
             * SubCompanyZone color.
             * @member {string} color
             * @memberof fastcity.admin.SubCompanyZone
             * @instance
             */
            SubCompanyZone.prototype.color = "";

            /**
             * SubCompanyZone isDisabled.
             * @member {boolean} isDisabled
             * @memberof fastcity.admin.SubCompanyZone
             * @instance
             */
            SubCompanyZone.prototype.isDisabled = false;

            /**
             * SubCompanyZone isDeleted.
             * @member {boolean} isDeleted
             * @memberof fastcity.admin.SubCompanyZone
             * @instance
             */
            SubCompanyZone.prototype.isDeleted = false;

            /**
             * Creates a new SubCompanyZone instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.SubCompanyZone
             * @static
             * @param {fastcity.admin.ISubCompanyZone=} [properties] Properties to set
             * @returns {fastcity.admin.SubCompanyZone} SubCompanyZone instance
             */
            SubCompanyZone.create = function create(properties) {
                return new SubCompanyZone(properties);
            };

            /**
             * Encodes the specified SubCompanyZone message. Does not implicitly {@link fastcity.admin.SubCompanyZone.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.SubCompanyZone
             * @static
             * @param {fastcity.admin.ISubCompanyZone} message SubCompanyZone message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubCompanyZone.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyZoneId != null && Object.hasOwnProperty.call(message, "subCompanyZoneId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyZoneId);
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.externalSubCompanyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
                if (message.polygon != null && Object.hasOwnProperty.call(message, "polygon"))
                    $root.fastcity.admin.Polygon.encode(message.polygon, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.color);
                if (message.isDisabled != null && Object.hasOwnProperty.call(message, "isDisabled"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isDisabled);
                if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isDeleted);
                return writer;
            };

            /**
             * Decodes a SubCompanyZone message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.SubCompanyZone
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.SubCompanyZone} SubCompanyZone
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubCompanyZone.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.SubCompanyZone();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyZoneId = reader.uint64();
                        break;
                    case 2:
                        message.companyId = reader.uint64();
                        break;
                    case 3:
                        message.externalSubCompanyId = reader.string();
                        break;
                    case 4:
                        message.name = reader.string();
                        break;
                    case 5:
                        message.polygon = $root.fastcity.admin.Polygon.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.color = reader.string();
                        break;
                    case 7:
                        message.isDisabled = reader.bool();
                        break;
                    case 8:
                        message.isDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SubCompanyZone message.
             * @function verify
             * @memberof fastcity.admin.SubCompanyZone
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SubCompanyZone.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (!$util.isInteger(message.subCompanyZoneId) && !(message.subCompanyZoneId && $util.isInteger(message.subCompanyZoneId.low) && $util.isInteger(message.subCompanyZoneId.high)))
                        return "subCompanyZoneId: integer|Long expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.polygon != null && message.hasOwnProperty("polygon")) {
                    var error = $root.fastcity.admin.Polygon.verify(message.polygon);
                    if (error)
                        return "polygon." + error;
                }
                if (message.color != null && message.hasOwnProperty("color"))
                    if (!$util.isString(message.color))
                        return "color: string expected";
                if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                    if (typeof message.isDisabled !== "boolean")
                        return "isDisabled: boolean expected";
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    if (typeof message.isDeleted !== "boolean")
                        return "isDeleted: boolean expected";
                return null;
            };

            /**
             * Creates a SubCompanyZone message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.SubCompanyZone
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.SubCompanyZone} SubCompanyZone
             */
            SubCompanyZone.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.SubCompanyZone)
                    return object;
                var message = new $root.fastcity.admin.SubCompanyZone();
                if (object.subCompanyZoneId != null)
                    if ($util.Long)
                        (message.subCompanyZoneId = $util.Long.fromValue(object.subCompanyZoneId)).unsigned = true;
                    else if (typeof object.subCompanyZoneId === "string")
                        message.subCompanyZoneId = parseInt(object.subCompanyZoneId, 10);
                    else if (typeof object.subCompanyZoneId === "number")
                        message.subCompanyZoneId = object.subCompanyZoneId;
                    else if (typeof object.subCompanyZoneId === "object")
                        message.subCompanyZoneId = new $util.LongBits(object.subCompanyZoneId.low >>> 0, object.subCompanyZoneId.high >>> 0).toNumber(true);
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.polygon != null) {
                    if (typeof object.polygon !== "object")
                        throw TypeError(".fastcity.admin.SubCompanyZone.polygon: object expected");
                    message.polygon = $root.fastcity.admin.Polygon.fromObject(object.polygon);
                }
                if (object.color != null)
                    message.color = String(object.color);
                if (object.isDisabled != null)
                    message.isDisabled = Boolean(object.isDisabled);
                if (object.isDeleted != null)
                    message.isDeleted = Boolean(object.isDeleted);
                return message;
            };

            /**
             * Creates a plain object from a SubCompanyZone message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.SubCompanyZone
             * @static
             * @param {fastcity.admin.SubCompanyZone} message SubCompanyZone
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SubCompanyZone.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyZoneId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyZoneId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                    object.name = "";
                    object.polygon = null;
                    object.color = "";
                    object.isDisabled = false;
                    object.isDeleted = false;
                }
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (typeof message.subCompanyZoneId === "number")
                        object.subCompanyZoneId = options.longs === String ? String(message.subCompanyZoneId) : message.subCompanyZoneId;
                    else
                        object.subCompanyZoneId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyZoneId) : options.longs === Number ? new $util.LongBits(message.subCompanyZoneId.low >>> 0, message.subCompanyZoneId.high >>> 0).toNumber(true) : message.subCompanyZoneId;
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.polygon != null && message.hasOwnProperty("polygon"))
                    object.polygon = $root.fastcity.admin.Polygon.toObject(message.polygon, options);
                if (message.color != null && message.hasOwnProperty("color"))
                    object.color = message.color;
                if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                    object.isDisabled = message.isDisabled;
                if (message.isDeleted != null && message.hasOwnProperty("isDeleted"))
                    object.isDeleted = message.isDeleted;
                return object;
            };

            /**
             * Converts this SubCompanyZone to JSON.
             * @function toJSON
             * @memberof fastcity.admin.SubCompanyZone
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SubCompanyZone.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SubCompanyZone;
        })();

        admin.SubCompanyZoneList = (function() {

            /**
             * Properties of a SubCompanyZoneList.
             * @memberof fastcity.admin
             * @interface ISubCompanyZoneList
             * @property {Array.<fastcity.admin.ISubCompanyZone>|null} [subCompanyZone] SubCompanyZoneList subCompanyZone
             */

            /**
             * Constructs a new SubCompanyZoneList.
             * @memberof fastcity.admin
             * @classdesc Represents a SubCompanyZoneList.
             * @implements ISubCompanyZoneList
             * @constructor
             * @param {fastcity.admin.ISubCompanyZoneList=} [properties] Properties to set
             */
            function SubCompanyZoneList(properties) {
                this.subCompanyZone = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SubCompanyZoneList subCompanyZone.
             * @member {Array.<fastcity.admin.ISubCompanyZone>} subCompanyZone
             * @memberof fastcity.admin.SubCompanyZoneList
             * @instance
             */
            SubCompanyZoneList.prototype.subCompanyZone = $util.emptyArray;

            /**
             * Creates a new SubCompanyZoneList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.SubCompanyZoneList
             * @static
             * @param {fastcity.admin.ISubCompanyZoneList=} [properties] Properties to set
             * @returns {fastcity.admin.SubCompanyZoneList} SubCompanyZoneList instance
             */
            SubCompanyZoneList.create = function create(properties) {
                return new SubCompanyZoneList(properties);
            };

            /**
             * Encodes the specified SubCompanyZoneList message. Does not implicitly {@link fastcity.admin.SubCompanyZoneList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.SubCompanyZoneList
             * @static
             * @param {fastcity.admin.ISubCompanyZoneList} message SubCompanyZoneList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubCompanyZoneList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyZone != null && message.subCompanyZone.length)
                    for (var i = 0; i < message.subCompanyZone.length; ++i)
                        $root.fastcity.admin.SubCompanyZone.encode(message.subCompanyZone[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a SubCompanyZoneList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.SubCompanyZoneList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.SubCompanyZoneList} SubCompanyZoneList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubCompanyZoneList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.SubCompanyZoneList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.subCompanyZone && message.subCompanyZone.length))
                            message.subCompanyZone = [];
                        message.subCompanyZone.push($root.fastcity.admin.SubCompanyZone.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SubCompanyZoneList message.
             * @function verify
             * @memberof fastcity.admin.SubCompanyZoneList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SubCompanyZoneList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyZone != null && message.hasOwnProperty("subCompanyZone")) {
                    if (!Array.isArray(message.subCompanyZone))
                        return "subCompanyZone: array expected";
                    for (var i = 0; i < message.subCompanyZone.length; ++i) {
                        var error = $root.fastcity.admin.SubCompanyZone.verify(message.subCompanyZone[i]);
                        if (error)
                            return "subCompanyZone." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SubCompanyZoneList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.SubCompanyZoneList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.SubCompanyZoneList} SubCompanyZoneList
             */
            SubCompanyZoneList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.SubCompanyZoneList)
                    return object;
                var message = new $root.fastcity.admin.SubCompanyZoneList();
                if (object.subCompanyZone) {
                    if (!Array.isArray(object.subCompanyZone))
                        throw TypeError(".fastcity.admin.SubCompanyZoneList.subCompanyZone: array expected");
                    message.subCompanyZone = [];
                    for (var i = 0; i < object.subCompanyZone.length; ++i) {
                        if (typeof object.subCompanyZone[i] !== "object")
                            throw TypeError(".fastcity.admin.SubCompanyZoneList.subCompanyZone: object expected");
                        message.subCompanyZone[i] = $root.fastcity.admin.SubCompanyZone.fromObject(object.subCompanyZone[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SubCompanyZoneList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.SubCompanyZoneList
             * @static
             * @param {fastcity.admin.SubCompanyZoneList} message SubCompanyZoneList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SubCompanyZoneList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.subCompanyZone = [];
                if (message.subCompanyZone && message.subCompanyZone.length) {
                    object.subCompanyZone = [];
                    for (var j = 0; j < message.subCompanyZone.length; ++j)
                        object.subCompanyZone[j] = $root.fastcity.admin.SubCompanyZone.toObject(message.subCompanyZone[j], options);
                }
                return object;
            };

            /**
             * Converts this SubCompanyZoneList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.SubCompanyZoneList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SubCompanyZoneList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SubCompanyZoneList;
        })();

        admin.CreateSubCompanyZone = (function() {

            /**
             * Properties of a CreateSubCompanyZone.
             * @memberof fastcity.admin
             * @interface ICreateSubCompanyZone
             * @property {number|Long|null} [companyId] CreateSubCompanyZone companyId
             * @property {string|null} [externalSubCompanyId] CreateSubCompanyZone externalSubCompanyId
             * @property {string|null} [name] CreateSubCompanyZone name
             * @property {fastcity.admin.IPolygon|null} [polygon] CreateSubCompanyZone polygon
             * @property {string|null} [color] CreateSubCompanyZone color
             * @property {boolean|null} [isDisabled] CreateSubCompanyZone isDisabled
             * @property {boolean|null} [latitudeLongitudeSwap] CreateSubCompanyZone latitudeLongitudeSwap
             */

            /**
             * Constructs a new CreateSubCompanyZone.
             * @memberof fastcity.admin
             * @classdesc Represents a CreateSubCompanyZone.
             * @implements ICreateSubCompanyZone
             * @constructor
             * @param {fastcity.admin.ICreateSubCompanyZone=} [properties] Properties to set
             */
            function CreateSubCompanyZone(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateSubCompanyZone companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @instance
             */
            CreateSubCompanyZone.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateSubCompanyZone externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @instance
             */
            CreateSubCompanyZone.prototype.externalSubCompanyId = "";

            /**
             * CreateSubCompanyZone name.
             * @member {string} name
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @instance
             */
            CreateSubCompanyZone.prototype.name = "";

            /**
             * CreateSubCompanyZone polygon.
             * @member {fastcity.admin.IPolygon|null|undefined} polygon
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @instance
             */
            CreateSubCompanyZone.prototype.polygon = null;

            /**
             * CreateSubCompanyZone color.
             * @member {string} color
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @instance
             */
            CreateSubCompanyZone.prototype.color = "";

            /**
             * CreateSubCompanyZone isDisabled.
             * @member {boolean} isDisabled
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @instance
             */
            CreateSubCompanyZone.prototype.isDisabled = false;

            /**
             * CreateSubCompanyZone latitudeLongitudeSwap.
             * @member {boolean} latitudeLongitudeSwap
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @instance
             */
            CreateSubCompanyZone.prototype.latitudeLongitudeSwap = false;

            /**
             * Creates a new CreateSubCompanyZone instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @static
             * @param {fastcity.admin.ICreateSubCompanyZone=} [properties] Properties to set
             * @returns {fastcity.admin.CreateSubCompanyZone} CreateSubCompanyZone instance
             */
            CreateSubCompanyZone.create = function create(properties) {
                return new CreateSubCompanyZone(properties);
            };

            /**
             * Encodes the specified CreateSubCompanyZone message. Does not implicitly {@link fastcity.admin.CreateSubCompanyZone.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @static
             * @param {fastcity.admin.ICreateSubCompanyZone} message CreateSubCompanyZone message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateSubCompanyZone.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalSubCompanyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.polygon != null && Object.hasOwnProperty.call(message, "polygon"))
                    $root.fastcity.admin.Polygon.encode(message.polygon, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.color);
                if (message.isDisabled != null && Object.hasOwnProperty.call(message, "isDisabled"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isDisabled);
                if (message.latitudeLongitudeSwap != null && Object.hasOwnProperty.call(message, "latitudeLongitudeSwap"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.latitudeLongitudeSwap);
                return writer;
            };

            /**
             * Decodes a CreateSubCompanyZone message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CreateSubCompanyZone} CreateSubCompanyZone
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateSubCompanyZone.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CreateSubCompanyZone();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.externalSubCompanyId = reader.string();
                        break;
                    case 3:
                        message.name = reader.string();
                        break;
                    case 4:
                        message.polygon = $root.fastcity.admin.Polygon.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.color = reader.string();
                        break;
                    case 6:
                        message.isDisabled = reader.bool();
                        break;
                    case 7:
                        message.latitudeLongitudeSwap = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CreateSubCompanyZone message.
             * @function verify
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateSubCompanyZone.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.polygon != null && message.hasOwnProperty("polygon")) {
                    var error = $root.fastcity.admin.Polygon.verify(message.polygon);
                    if (error)
                        return "polygon." + error;
                }
                if (message.color != null && message.hasOwnProperty("color"))
                    if (!$util.isString(message.color))
                        return "color: string expected";
                if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                    if (typeof message.isDisabled !== "boolean")
                        return "isDisabled: boolean expected";
                if (message.latitudeLongitudeSwap != null && message.hasOwnProperty("latitudeLongitudeSwap"))
                    if (typeof message.latitudeLongitudeSwap !== "boolean")
                        return "latitudeLongitudeSwap: boolean expected";
                return null;
            };

            /**
             * Creates a CreateSubCompanyZone message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CreateSubCompanyZone} CreateSubCompanyZone
             */
            CreateSubCompanyZone.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CreateSubCompanyZone)
                    return object;
                var message = new $root.fastcity.admin.CreateSubCompanyZone();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.polygon != null) {
                    if (typeof object.polygon !== "object")
                        throw TypeError(".fastcity.admin.CreateSubCompanyZone.polygon: object expected");
                    message.polygon = $root.fastcity.admin.Polygon.fromObject(object.polygon);
                }
                if (object.color != null)
                    message.color = String(object.color);
                if (object.isDisabled != null)
                    message.isDisabled = Boolean(object.isDisabled);
                if (object.latitudeLongitudeSwap != null)
                    message.latitudeLongitudeSwap = Boolean(object.latitudeLongitudeSwap);
                return message;
            };

            /**
             * Creates a plain object from a CreateSubCompanyZone message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @static
             * @param {fastcity.admin.CreateSubCompanyZone} message CreateSubCompanyZone
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateSubCompanyZone.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                    object.name = "";
                    object.polygon = null;
                    object.color = "";
                    object.isDisabled = false;
                    object.latitudeLongitudeSwap = false;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.polygon != null && message.hasOwnProperty("polygon"))
                    object.polygon = $root.fastcity.admin.Polygon.toObject(message.polygon, options);
                if (message.color != null && message.hasOwnProperty("color"))
                    object.color = message.color;
                if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                    object.isDisabled = message.isDisabled;
                if (message.latitudeLongitudeSwap != null && message.hasOwnProperty("latitudeLongitudeSwap"))
                    object.latitudeLongitudeSwap = message.latitudeLongitudeSwap;
                return object;
            };

            /**
             * Converts this CreateSubCompanyZone to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CreateSubCompanyZone
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateSubCompanyZone.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CreateSubCompanyZone;
        })();

        admin.CreateSubCompanyZoneFromKML = (function() {

            /**
             * Properties of a CreateSubCompanyZoneFromKML.
             * @memberof fastcity.admin
             * @interface ICreateSubCompanyZoneFromKML
             * @property {number|Long|null} [companyId] CreateSubCompanyZoneFromKML companyId
             * @property {string|null} [externalSubCompanyId] CreateSubCompanyZoneFromKML externalSubCompanyId
             * @property {string|null} [name] CreateSubCompanyZoneFromKML name
             * @property {string|null} [kmlFileBody] CreateSubCompanyZoneFromKML kmlFileBody
             * @property {string|null} [color] CreateSubCompanyZoneFromKML color
             * @property {boolean|null} [isDisabled] CreateSubCompanyZoneFromKML isDisabled
             * @property {boolean|null} [latitudeLongitudeSwap] CreateSubCompanyZoneFromKML latitudeLongitudeSwap
             */

            /**
             * Constructs a new CreateSubCompanyZoneFromKML.
             * @memberof fastcity.admin
             * @classdesc Represents a CreateSubCompanyZoneFromKML.
             * @implements ICreateSubCompanyZoneFromKML
             * @constructor
             * @param {fastcity.admin.ICreateSubCompanyZoneFromKML=} [properties] Properties to set
             */
            function CreateSubCompanyZoneFromKML(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CreateSubCompanyZoneFromKML companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @instance
             */
            CreateSubCompanyZoneFromKML.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CreateSubCompanyZoneFromKML externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @instance
             */
            CreateSubCompanyZoneFromKML.prototype.externalSubCompanyId = "";

            /**
             * CreateSubCompanyZoneFromKML name.
             * @member {string} name
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @instance
             */
            CreateSubCompanyZoneFromKML.prototype.name = "";

            /**
             * CreateSubCompanyZoneFromKML kmlFileBody.
             * @member {string} kmlFileBody
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @instance
             */
            CreateSubCompanyZoneFromKML.prototype.kmlFileBody = "";

            /**
             * CreateSubCompanyZoneFromKML color.
             * @member {string} color
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @instance
             */
            CreateSubCompanyZoneFromKML.prototype.color = "";

            /**
             * CreateSubCompanyZoneFromKML isDisabled.
             * @member {boolean} isDisabled
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @instance
             */
            CreateSubCompanyZoneFromKML.prototype.isDisabled = false;

            /**
             * CreateSubCompanyZoneFromKML latitudeLongitudeSwap.
             * @member {boolean} latitudeLongitudeSwap
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @instance
             */
            CreateSubCompanyZoneFromKML.prototype.latitudeLongitudeSwap = false;

            /**
             * Creates a new CreateSubCompanyZoneFromKML instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @static
             * @param {fastcity.admin.ICreateSubCompanyZoneFromKML=} [properties] Properties to set
             * @returns {fastcity.admin.CreateSubCompanyZoneFromKML} CreateSubCompanyZoneFromKML instance
             */
            CreateSubCompanyZoneFromKML.create = function create(properties) {
                return new CreateSubCompanyZoneFromKML(properties);
            };

            /**
             * Encodes the specified CreateSubCompanyZoneFromKML message. Does not implicitly {@link fastcity.admin.CreateSubCompanyZoneFromKML.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @static
             * @param {fastcity.admin.ICreateSubCompanyZoneFromKML} message CreateSubCompanyZoneFromKML message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateSubCompanyZoneFromKML.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalSubCompanyId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.kmlFileBody != null && Object.hasOwnProperty.call(message, "kmlFileBody"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.kmlFileBody);
                if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.color);
                if (message.isDisabled != null && Object.hasOwnProperty.call(message, "isDisabled"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isDisabled);
                if (message.latitudeLongitudeSwap != null && Object.hasOwnProperty.call(message, "latitudeLongitudeSwap"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.latitudeLongitudeSwap);
                return writer;
            };

            /**
             * Decodes a CreateSubCompanyZoneFromKML message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.CreateSubCompanyZoneFromKML} CreateSubCompanyZoneFromKML
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateSubCompanyZoneFromKML.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.CreateSubCompanyZoneFromKML();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.externalSubCompanyId = reader.string();
                        break;
                    case 3:
                        message.name = reader.string();
                        break;
                    case 4:
                        message.kmlFileBody = reader.string();
                        break;
                    case 5:
                        message.color = reader.string();
                        break;
                    case 6:
                        message.isDisabled = reader.bool();
                        break;
                    case 7:
                        message.latitudeLongitudeSwap = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CreateSubCompanyZoneFromKML message.
             * @function verify
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateSubCompanyZoneFromKML.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.kmlFileBody != null && message.hasOwnProperty("kmlFileBody"))
                    if (!$util.isString(message.kmlFileBody))
                        return "kmlFileBody: string expected";
                if (message.color != null && message.hasOwnProperty("color"))
                    if (!$util.isString(message.color))
                        return "color: string expected";
                if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                    if (typeof message.isDisabled !== "boolean")
                        return "isDisabled: boolean expected";
                if (message.latitudeLongitudeSwap != null && message.hasOwnProperty("latitudeLongitudeSwap"))
                    if (typeof message.latitudeLongitudeSwap !== "boolean")
                        return "latitudeLongitudeSwap: boolean expected";
                return null;
            };

            /**
             * Creates a CreateSubCompanyZoneFromKML message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.CreateSubCompanyZoneFromKML} CreateSubCompanyZoneFromKML
             */
            CreateSubCompanyZoneFromKML.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.CreateSubCompanyZoneFromKML)
                    return object;
                var message = new $root.fastcity.admin.CreateSubCompanyZoneFromKML();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.kmlFileBody != null)
                    message.kmlFileBody = String(object.kmlFileBody);
                if (object.color != null)
                    message.color = String(object.color);
                if (object.isDisabled != null)
                    message.isDisabled = Boolean(object.isDisabled);
                if (object.latitudeLongitudeSwap != null)
                    message.latitudeLongitudeSwap = Boolean(object.latitudeLongitudeSwap);
                return message;
            };

            /**
             * Creates a plain object from a CreateSubCompanyZoneFromKML message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @static
             * @param {fastcity.admin.CreateSubCompanyZoneFromKML} message CreateSubCompanyZoneFromKML
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateSubCompanyZoneFromKML.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                    object.name = "";
                    object.kmlFileBody = "";
                    object.color = "";
                    object.isDisabled = false;
                    object.latitudeLongitudeSwap = false;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.kmlFileBody != null && message.hasOwnProperty("kmlFileBody"))
                    object.kmlFileBody = message.kmlFileBody;
                if (message.color != null && message.hasOwnProperty("color"))
                    object.color = message.color;
                if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                    object.isDisabled = message.isDisabled;
                if (message.latitudeLongitudeSwap != null && message.hasOwnProperty("latitudeLongitudeSwap"))
                    object.latitudeLongitudeSwap = message.latitudeLongitudeSwap;
                return object;
            };

            /**
             * Converts this CreateSubCompanyZoneFromKML to JSON.
             * @function toJSON
             * @memberof fastcity.admin.CreateSubCompanyZoneFromKML
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateSubCompanyZoneFromKML.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CreateSubCompanyZoneFromKML;
        })();

        admin.DeleteSubCompanyZone = (function() {

            /**
             * Properties of a DeleteSubCompanyZone.
             * @memberof fastcity.admin
             * @interface IDeleteSubCompanyZone
             * @property {number|Long|null} [subCompanyZoneId] DeleteSubCompanyZone subCompanyZoneId
             */

            /**
             * Constructs a new DeleteSubCompanyZone.
             * @memberof fastcity.admin
             * @classdesc Represents a DeleteSubCompanyZone.
             * @implements IDeleteSubCompanyZone
             * @constructor
             * @param {fastcity.admin.IDeleteSubCompanyZone=} [properties] Properties to set
             */
            function DeleteSubCompanyZone(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeleteSubCompanyZone subCompanyZoneId.
             * @member {number|Long} subCompanyZoneId
             * @memberof fastcity.admin.DeleteSubCompanyZone
             * @instance
             */
            DeleteSubCompanyZone.prototype.subCompanyZoneId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new DeleteSubCompanyZone instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.DeleteSubCompanyZone
             * @static
             * @param {fastcity.admin.IDeleteSubCompanyZone=} [properties] Properties to set
             * @returns {fastcity.admin.DeleteSubCompanyZone} DeleteSubCompanyZone instance
             */
            DeleteSubCompanyZone.create = function create(properties) {
                return new DeleteSubCompanyZone(properties);
            };

            /**
             * Encodes the specified DeleteSubCompanyZone message. Does not implicitly {@link fastcity.admin.DeleteSubCompanyZone.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.DeleteSubCompanyZone
             * @static
             * @param {fastcity.admin.IDeleteSubCompanyZone} message DeleteSubCompanyZone message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteSubCompanyZone.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyZoneId != null && Object.hasOwnProperty.call(message, "subCompanyZoneId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyZoneId);
                return writer;
            };

            /**
             * Decodes a DeleteSubCompanyZone message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.DeleteSubCompanyZone
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.DeleteSubCompanyZone} DeleteSubCompanyZone
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteSubCompanyZone.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.DeleteSubCompanyZone();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyZoneId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DeleteSubCompanyZone message.
             * @function verify
             * @memberof fastcity.admin.DeleteSubCompanyZone
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteSubCompanyZone.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (!$util.isInteger(message.subCompanyZoneId) && !(message.subCompanyZoneId && $util.isInteger(message.subCompanyZoneId.low) && $util.isInteger(message.subCompanyZoneId.high)))
                        return "subCompanyZoneId: integer|Long expected";
                return null;
            };

            /**
             * Creates a DeleteSubCompanyZone message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.DeleteSubCompanyZone
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.DeleteSubCompanyZone} DeleteSubCompanyZone
             */
            DeleteSubCompanyZone.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.DeleteSubCompanyZone)
                    return object;
                var message = new $root.fastcity.admin.DeleteSubCompanyZone();
                if (object.subCompanyZoneId != null)
                    if ($util.Long)
                        (message.subCompanyZoneId = $util.Long.fromValue(object.subCompanyZoneId)).unsigned = true;
                    else if (typeof object.subCompanyZoneId === "string")
                        message.subCompanyZoneId = parseInt(object.subCompanyZoneId, 10);
                    else if (typeof object.subCompanyZoneId === "number")
                        message.subCompanyZoneId = object.subCompanyZoneId;
                    else if (typeof object.subCompanyZoneId === "object")
                        message.subCompanyZoneId = new $util.LongBits(object.subCompanyZoneId.low >>> 0, object.subCompanyZoneId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a DeleteSubCompanyZone message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.DeleteSubCompanyZone
             * @static
             * @param {fastcity.admin.DeleteSubCompanyZone} message DeleteSubCompanyZone
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteSubCompanyZone.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyZoneId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyZoneId = options.longs === String ? "0" : 0;
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (typeof message.subCompanyZoneId === "number")
                        object.subCompanyZoneId = options.longs === String ? String(message.subCompanyZoneId) : message.subCompanyZoneId;
                    else
                        object.subCompanyZoneId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyZoneId) : options.longs === Number ? new $util.LongBits(message.subCompanyZoneId.low >>> 0, message.subCompanyZoneId.high >>> 0).toNumber(true) : message.subCompanyZoneId;
                return object;
            };

            /**
             * Converts this DeleteSubCompanyZone to JSON.
             * @function toJSON
             * @memberof fastcity.admin.DeleteSubCompanyZone
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteSubCompanyZone.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DeleteSubCompanyZone;
        })();

        admin.RecoverSubCompanyZone = (function() {

            /**
             * Properties of a RecoverSubCompanyZone.
             * @memberof fastcity.admin
             * @interface IRecoverSubCompanyZone
             * @property {number|Long|null} [subCompanyZoneId] RecoverSubCompanyZone subCompanyZoneId
             */

            /**
             * Constructs a new RecoverSubCompanyZone.
             * @memberof fastcity.admin
             * @classdesc Represents a RecoverSubCompanyZone.
             * @implements IRecoverSubCompanyZone
             * @constructor
             * @param {fastcity.admin.IRecoverSubCompanyZone=} [properties] Properties to set
             */
            function RecoverSubCompanyZone(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * RecoverSubCompanyZone subCompanyZoneId.
             * @member {number|Long} subCompanyZoneId
             * @memberof fastcity.admin.RecoverSubCompanyZone
             * @instance
             */
            RecoverSubCompanyZone.prototype.subCompanyZoneId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new RecoverSubCompanyZone instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.RecoverSubCompanyZone
             * @static
             * @param {fastcity.admin.IRecoverSubCompanyZone=} [properties] Properties to set
             * @returns {fastcity.admin.RecoverSubCompanyZone} RecoverSubCompanyZone instance
             */
            RecoverSubCompanyZone.create = function create(properties) {
                return new RecoverSubCompanyZone(properties);
            };

            /**
             * Encodes the specified RecoverSubCompanyZone message. Does not implicitly {@link fastcity.admin.RecoverSubCompanyZone.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.RecoverSubCompanyZone
             * @static
             * @param {fastcity.admin.IRecoverSubCompanyZone} message RecoverSubCompanyZone message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RecoverSubCompanyZone.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyZoneId != null && Object.hasOwnProperty.call(message, "subCompanyZoneId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyZoneId);
                return writer;
            };

            /**
             * Decodes a RecoverSubCompanyZone message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.RecoverSubCompanyZone
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.RecoverSubCompanyZone} RecoverSubCompanyZone
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RecoverSubCompanyZone.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.RecoverSubCompanyZone();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyZoneId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a RecoverSubCompanyZone message.
             * @function verify
             * @memberof fastcity.admin.RecoverSubCompanyZone
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RecoverSubCompanyZone.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (!$util.isInteger(message.subCompanyZoneId) && !(message.subCompanyZoneId && $util.isInteger(message.subCompanyZoneId.low) && $util.isInteger(message.subCompanyZoneId.high)))
                        return "subCompanyZoneId: integer|Long expected";
                return null;
            };

            /**
             * Creates a RecoverSubCompanyZone message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.RecoverSubCompanyZone
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.RecoverSubCompanyZone} RecoverSubCompanyZone
             */
            RecoverSubCompanyZone.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.RecoverSubCompanyZone)
                    return object;
                var message = new $root.fastcity.admin.RecoverSubCompanyZone();
                if (object.subCompanyZoneId != null)
                    if ($util.Long)
                        (message.subCompanyZoneId = $util.Long.fromValue(object.subCompanyZoneId)).unsigned = true;
                    else if (typeof object.subCompanyZoneId === "string")
                        message.subCompanyZoneId = parseInt(object.subCompanyZoneId, 10);
                    else if (typeof object.subCompanyZoneId === "number")
                        message.subCompanyZoneId = object.subCompanyZoneId;
                    else if (typeof object.subCompanyZoneId === "object")
                        message.subCompanyZoneId = new $util.LongBits(object.subCompanyZoneId.low >>> 0, object.subCompanyZoneId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a RecoverSubCompanyZone message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.RecoverSubCompanyZone
             * @static
             * @param {fastcity.admin.RecoverSubCompanyZone} message RecoverSubCompanyZone
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RecoverSubCompanyZone.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyZoneId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyZoneId = options.longs === String ? "0" : 0;
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (typeof message.subCompanyZoneId === "number")
                        object.subCompanyZoneId = options.longs === String ? String(message.subCompanyZoneId) : message.subCompanyZoneId;
                    else
                        object.subCompanyZoneId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyZoneId) : options.longs === Number ? new $util.LongBits(message.subCompanyZoneId.low >>> 0, message.subCompanyZoneId.high >>> 0).toNumber(true) : message.subCompanyZoneId;
                return object;
            };

            /**
             * Converts this RecoverSubCompanyZone to JSON.
             * @function toJSON
             * @memberof fastcity.admin.RecoverSubCompanyZone
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RecoverSubCompanyZone.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RecoverSubCompanyZone;
        })();

        admin.UpdateSubCompanyZone = (function() {

            /**
             * Properties of an UpdateSubCompanyZone.
             * @memberof fastcity.admin
             * @interface IUpdateSubCompanyZone
             * @property {number|Long|null} [subCompanyZoneId] UpdateSubCompanyZone subCompanyZoneId
             * @property {string|null} [name] UpdateSubCompanyZone name
             * @property {fastcity.admin.IPolygon|null} [polygon] UpdateSubCompanyZone polygon
             * @property {string|null} [color] UpdateSubCompanyZone color
             * @property {boolean|null} [isDisabled] UpdateSubCompanyZone isDisabled
             */

            /**
             * Constructs a new UpdateSubCompanyZone.
             * @memberof fastcity.admin
             * @classdesc Represents an UpdateSubCompanyZone.
             * @implements IUpdateSubCompanyZone
             * @constructor
             * @param {fastcity.admin.IUpdateSubCompanyZone=} [properties] Properties to set
             */
            function UpdateSubCompanyZone(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateSubCompanyZone subCompanyZoneId.
             * @member {number|Long} subCompanyZoneId
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @instance
             */
            UpdateSubCompanyZone.prototype.subCompanyZoneId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateSubCompanyZone name.
             * @member {string} name
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @instance
             */
            UpdateSubCompanyZone.prototype.name = "";

            /**
             * UpdateSubCompanyZone polygon.
             * @member {fastcity.admin.IPolygon|null|undefined} polygon
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @instance
             */
            UpdateSubCompanyZone.prototype.polygon = null;

            /**
             * UpdateSubCompanyZone color.
             * @member {string} color
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @instance
             */
            UpdateSubCompanyZone.prototype.color = "";

            /**
             * UpdateSubCompanyZone isDisabled.
             * @member {boolean} isDisabled
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @instance
             */
            UpdateSubCompanyZone.prototype.isDisabled = false;

            /**
             * Creates a new UpdateSubCompanyZone instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @static
             * @param {fastcity.admin.IUpdateSubCompanyZone=} [properties] Properties to set
             * @returns {fastcity.admin.UpdateSubCompanyZone} UpdateSubCompanyZone instance
             */
            UpdateSubCompanyZone.create = function create(properties) {
                return new UpdateSubCompanyZone(properties);
            };

            /**
             * Encodes the specified UpdateSubCompanyZone message. Does not implicitly {@link fastcity.admin.UpdateSubCompanyZone.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @static
             * @param {fastcity.admin.IUpdateSubCompanyZone} message UpdateSubCompanyZone message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateSubCompanyZone.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyZoneId != null && Object.hasOwnProperty.call(message, "subCompanyZoneId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyZoneId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.polygon != null && Object.hasOwnProperty.call(message, "polygon"))
                    $root.fastcity.admin.Polygon.encode(message.polygon, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.color);
                if (message.isDisabled != null && Object.hasOwnProperty.call(message, "isDisabled"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isDisabled);
                return writer;
            };

            /**
             * Decodes an UpdateSubCompanyZone message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.UpdateSubCompanyZone} UpdateSubCompanyZone
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateSubCompanyZone.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.UpdateSubCompanyZone();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyZoneId = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.polygon = $root.fastcity.admin.Polygon.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.color = reader.string();
                        break;
                    case 5:
                        message.isDisabled = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateSubCompanyZone message.
             * @function verify
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateSubCompanyZone.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (!$util.isInteger(message.subCompanyZoneId) && !(message.subCompanyZoneId && $util.isInteger(message.subCompanyZoneId.low) && $util.isInteger(message.subCompanyZoneId.high)))
                        return "subCompanyZoneId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.polygon != null && message.hasOwnProperty("polygon")) {
                    var error = $root.fastcity.admin.Polygon.verify(message.polygon);
                    if (error)
                        return "polygon." + error;
                }
                if (message.color != null && message.hasOwnProperty("color"))
                    if (!$util.isString(message.color))
                        return "color: string expected";
                if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                    if (typeof message.isDisabled !== "boolean")
                        return "isDisabled: boolean expected";
                return null;
            };

            /**
             * Creates an UpdateSubCompanyZone message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.UpdateSubCompanyZone} UpdateSubCompanyZone
             */
            UpdateSubCompanyZone.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.UpdateSubCompanyZone)
                    return object;
                var message = new $root.fastcity.admin.UpdateSubCompanyZone();
                if (object.subCompanyZoneId != null)
                    if ($util.Long)
                        (message.subCompanyZoneId = $util.Long.fromValue(object.subCompanyZoneId)).unsigned = true;
                    else if (typeof object.subCompanyZoneId === "string")
                        message.subCompanyZoneId = parseInt(object.subCompanyZoneId, 10);
                    else if (typeof object.subCompanyZoneId === "number")
                        message.subCompanyZoneId = object.subCompanyZoneId;
                    else if (typeof object.subCompanyZoneId === "object")
                        message.subCompanyZoneId = new $util.LongBits(object.subCompanyZoneId.low >>> 0, object.subCompanyZoneId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.polygon != null) {
                    if (typeof object.polygon !== "object")
                        throw TypeError(".fastcity.admin.UpdateSubCompanyZone.polygon: object expected");
                    message.polygon = $root.fastcity.admin.Polygon.fromObject(object.polygon);
                }
                if (object.color != null)
                    message.color = String(object.color);
                if (object.isDisabled != null)
                    message.isDisabled = Boolean(object.isDisabled);
                return message;
            };

            /**
             * Creates a plain object from an UpdateSubCompanyZone message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @static
             * @param {fastcity.admin.UpdateSubCompanyZone} message UpdateSubCompanyZone
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateSubCompanyZone.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyZoneId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyZoneId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.polygon = null;
                    object.color = "";
                    object.isDisabled = false;
                }
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (typeof message.subCompanyZoneId === "number")
                        object.subCompanyZoneId = options.longs === String ? String(message.subCompanyZoneId) : message.subCompanyZoneId;
                    else
                        object.subCompanyZoneId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyZoneId) : options.longs === Number ? new $util.LongBits(message.subCompanyZoneId.low >>> 0, message.subCompanyZoneId.high >>> 0).toNumber(true) : message.subCompanyZoneId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.polygon != null && message.hasOwnProperty("polygon"))
                    object.polygon = $root.fastcity.admin.Polygon.toObject(message.polygon, options);
                if (message.color != null && message.hasOwnProperty("color"))
                    object.color = message.color;
                if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                    object.isDisabled = message.isDisabled;
                return object;
            };

            /**
             * Converts this UpdateSubCompanyZone to JSON.
             * @function toJSON
             * @memberof fastcity.admin.UpdateSubCompanyZone
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateSubCompanyZone.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateSubCompanyZone;
        })();

        admin.UpdateSubCompanyZoneUsingKML = (function() {

            /**
             * Properties of an UpdateSubCompanyZoneUsingKML.
             * @memberof fastcity.admin
             * @interface IUpdateSubCompanyZoneUsingKML
             * @property {number|Long|null} [subCompanyZoneId] UpdateSubCompanyZoneUsingKML subCompanyZoneId
             * @property {string|null} [name] UpdateSubCompanyZoneUsingKML name
             * @property {string|null} [kmlFileBody] UpdateSubCompanyZoneUsingKML kmlFileBody
             * @property {boolean|null} [latitudeLongitudeSwap] UpdateSubCompanyZoneUsingKML latitudeLongitudeSwap
             * @property {string|null} [color] UpdateSubCompanyZoneUsingKML color
             * @property {boolean|null} [isDisabled] UpdateSubCompanyZoneUsingKML isDisabled
             */

            /**
             * Constructs a new UpdateSubCompanyZoneUsingKML.
             * @memberof fastcity.admin
             * @classdesc Represents an UpdateSubCompanyZoneUsingKML.
             * @implements IUpdateSubCompanyZoneUsingKML
             * @constructor
             * @param {fastcity.admin.IUpdateSubCompanyZoneUsingKML=} [properties] Properties to set
             */
            function UpdateSubCompanyZoneUsingKML(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdateSubCompanyZoneUsingKML subCompanyZoneId.
             * @member {number|Long} subCompanyZoneId
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @instance
             */
            UpdateSubCompanyZoneUsingKML.prototype.subCompanyZoneId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdateSubCompanyZoneUsingKML name.
             * @member {string} name
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @instance
             */
            UpdateSubCompanyZoneUsingKML.prototype.name = "";

            /**
             * UpdateSubCompanyZoneUsingKML kmlFileBody.
             * @member {string} kmlFileBody
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @instance
             */
            UpdateSubCompanyZoneUsingKML.prototype.kmlFileBody = "";

            /**
             * UpdateSubCompanyZoneUsingKML latitudeLongitudeSwap.
             * @member {boolean} latitudeLongitudeSwap
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @instance
             */
            UpdateSubCompanyZoneUsingKML.prototype.latitudeLongitudeSwap = false;

            /**
             * UpdateSubCompanyZoneUsingKML color.
             * @member {string} color
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @instance
             */
            UpdateSubCompanyZoneUsingKML.prototype.color = "";

            /**
             * UpdateSubCompanyZoneUsingKML isDisabled.
             * @member {boolean} isDisabled
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @instance
             */
            UpdateSubCompanyZoneUsingKML.prototype.isDisabled = false;

            /**
             * Creates a new UpdateSubCompanyZoneUsingKML instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @static
             * @param {fastcity.admin.IUpdateSubCompanyZoneUsingKML=} [properties] Properties to set
             * @returns {fastcity.admin.UpdateSubCompanyZoneUsingKML} UpdateSubCompanyZoneUsingKML instance
             */
            UpdateSubCompanyZoneUsingKML.create = function create(properties) {
                return new UpdateSubCompanyZoneUsingKML(properties);
            };

            /**
             * Encodes the specified UpdateSubCompanyZoneUsingKML message. Does not implicitly {@link fastcity.admin.UpdateSubCompanyZoneUsingKML.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @static
             * @param {fastcity.admin.IUpdateSubCompanyZoneUsingKML} message UpdateSubCompanyZoneUsingKML message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateSubCompanyZoneUsingKML.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyZoneId != null && Object.hasOwnProperty.call(message, "subCompanyZoneId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyZoneId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.kmlFileBody != null && Object.hasOwnProperty.call(message, "kmlFileBody"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.kmlFileBody);
                if (message.latitudeLongitudeSwap != null && Object.hasOwnProperty.call(message, "latitudeLongitudeSwap"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.latitudeLongitudeSwap);
                if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.color);
                if (message.isDisabled != null && Object.hasOwnProperty.call(message, "isDisabled"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isDisabled);
                return writer;
            };

            /**
             * Decodes an UpdateSubCompanyZoneUsingKML message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.UpdateSubCompanyZoneUsingKML} UpdateSubCompanyZoneUsingKML
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateSubCompanyZoneUsingKML.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.UpdateSubCompanyZoneUsingKML();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyZoneId = reader.uint64();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.kmlFileBody = reader.string();
                        break;
                    case 4:
                        message.latitudeLongitudeSwap = reader.bool();
                        break;
                    case 5:
                        message.color = reader.string();
                        break;
                    case 6:
                        message.isDisabled = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdateSubCompanyZoneUsingKML message.
             * @function verify
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateSubCompanyZoneUsingKML.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (!$util.isInteger(message.subCompanyZoneId) && !(message.subCompanyZoneId && $util.isInteger(message.subCompanyZoneId.low) && $util.isInteger(message.subCompanyZoneId.high)))
                        return "subCompanyZoneId: integer|Long expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.kmlFileBody != null && message.hasOwnProperty("kmlFileBody"))
                    if (!$util.isString(message.kmlFileBody))
                        return "kmlFileBody: string expected";
                if (message.latitudeLongitudeSwap != null && message.hasOwnProperty("latitudeLongitudeSwap"))
                    if (typeof message.latitudeLongitudeSwap !== "boolean")
                        return "latitudeLongitudeSwap: boolean expected";
                if (message.color != null && message.hasOwnProperty("color"))
                    if (!$util.isString(message.color))
                        return "color: string expected";
                if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                    if (typeof message.isDisabled !== "boolean")
                        return "isDisabled: boolean expected";
                return null;
            };

            /**
             * Creates an UpdateSubCompanyZoneUsingKML message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.UpdateSubCompanyZoneUsingKML} UpdateSubCompanyZoneUsingKML
             */
            UpdateSubCompanyZoneUsingKML.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.UpdateSubCompanyZoneUsingKML)
                    return object;
                var message = new $root.fastcity.admin.UpdateSubCompanyZoneUsingKML();
                if (object.subCompanyZoneId != null)
                    if ($util.Long)
                        (message.subCompanyZoneId = $util.Long.fromValue(object.subCompanyZoneId)).unsigned = true;
                    else if (typeof object.subCompanyZoneId === "string")
                        message.subCompanyZoneId = parseInt(object.subCompanyZoneId, 10);
                    else if (typeof object.subCompanyZoneId === "number")
                        message.subCompanyZoneId = object.subCompanyZoneId;
                    else if (typeof object.subCompanyZoneId === "object")
                        message.subCompanyZoneId = new $util.LongBits(object.subCompanyZoneId.low >>> 0, object.subCompanyZoneId.high >>> 0).toNumber(true);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.kmlFileBody != null)
                    message.kmlFileBody = String(object.kmlFileBody);
                if (object.latitudeLongitudeSwap != null)
                    message.latitudeLongitudeSwap = Boolean(object.latitudeLongitudeSwap);
                if (object.color != null)
                    message.color = String(object.color);
                if (object.isDisabled != null)
                    message.isDisabled = Boolean(object.isDisabled);
                return message;
            };

            /**
             * Creates a plain object from an UpdateSubCompanyZoneUsingKML message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @static
             * @param {fastcity.admin.UpdateSubCompanyZoneUsingKML} message UpdateSubCompanyZoneUsingKML
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateSubCompanyZoneUsingKML.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyZoneId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyZoneId = options.longs === String ? "0" : 0;
                    object.name = "";
                    object.kmlFileBody = "";
                    object.latitudeLongitudeSwap = false;
                    object.color = "";
                    object.isDisabled = false;
                }
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (typeof message.subCompanyZoneId === "number")
                        object.subCompanyZoneId = options.longs === String ? String(message.subCompanyZoneId) : message.subCompanyZoneId;
                    else
                        object.subCompanyZoneId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyZoneId) : options.longs === Number ? new $util.LongBits(message.subCompanyZoneId.low >>> 0, message.subCompanyZoneId.high >>> 0).toNumber(true) : message.subCompanyZoneId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.kmlFileBody != null && message.hasOwnProperty("kmlFileBody"))
                    object.kmlFileBody = message.kmlFileBody;
                if (message.latitudeLongitudeSwap != null && message.hasOwnProperty("latitudeLongitudeSwap"))
                    object.latitudeLongitudeSwap = message.latitudeLongitudeSwap;
                if (message.color != null && message.hasOwnProperty("color"))
                    object.color = message.color;
                if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                    object.isDisabled = message.isDisabled;
                return object;
            };

            /**
             * Converts this UpdateSubCompanyZoneUsingKML to JSON.
             * @function toJSON
             * @memberof fastcity.admin.UpdateSubCompanyZoneUsingKML
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateSubCompanyZoneUsingKML.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdateSubCompanyZoneUsingKML;
        })();

        admin.GetSubCompanyZone = (function() {

            /**
             * Properties of a GetSubCompanyZone.
             * @memberof fastcity.admin
             * @interface IGetSubCompanyZone
             * @property {number|Long|null} [subCompanyZoneId] GetSubCompanyZone subCompanyZoneId
             */

            /**
             * Constructs a new GetSubCompanyZone.
             * @memberof fastcity.admin
             * @classdesc Represents a GetSubCompanyZone.
             * @implements IGetSubCompanyZone
             * @constructor
             * @param {fastcity.admin.IGetSubCompanyZone=} [properties] Properties to set
             */
            function GetSubCompanyZone(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetSubCompanyZone subCompanyZoneId.
             * @member {number|Long} subCompanyZoneId
             * @memberof fastcity.admin.GetSubCompanyZone
             * @instance
             */
            GetSubCompanyZone.prototype.subCompanyZoneId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new GetSubCompanyZone instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetSubCompanyZone
             * @static
             * @param {fastcity.admin.IGetSubCompanyZone=} [properties] Properties to set
             * @returns {fastcity.admin.GetSubCompanyZone} GetSubCompanyZone instance
             */
            GetSubCompanyZone.create = function create(properties) {
                return new GetSubCompanyZone(properties);
            };

            /**
             * Encodes the specified GetSubCompanyZone message. Does not implicitly {@link fastcity.admin.GetSubCompanyZone.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetSubCompanyZone
             * @static
             * @param {fastcity.admin.IGetSubCompanyZone} message GetSubCompanyZone message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSubCompanyZone.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subCompanyZoneId != null && Object.hasOwnProperty.call(message, "subCompanyZoneId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subCompanyZoneId);
                return writer;
            };

            /**
             * Decodes a GetSubCompanyZone message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetSubCompanyZone
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetSubCompanyZone} GetSubCompanyZone
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSubCompanyZone.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetSubCompanyZone();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subCompanyZoneId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetSubCompanyZone message.
             * @function verify
             * @memberof fastcity.admin.GetSubCompanyZone
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetSubCompanyZone.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (!$util.isInteger(message.subCompanyZoneId) && !(message.subCompanyZoneId && $util.isInteger(message.subCompanyZoneId.low) && $util.isInteger(message.subCompanyZoneId.high)))
                        return "subCompanyZoneId: integer|Long expected";
                return null;
            };

            /**
             * Creates a GetSubCompanyZone message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetSubCompanyZone
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetSubCompanyZone} GetSubCompanyZone
             */
            GetSubCompanyZone.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetSubCompanyZone)
                    return object;
                var message = new $root.fastcity.admin.GetSubCompanyZone();
                if (object.subCompanyZoneId != null)
                    if ($util.Long)
                        (message.subCompanyZoneId = $util.Long.fromValue(object.subCompanyZoneId)).unsigned = true;
                    else if (typeof object.subCompanyZoneId === "string")
                        message.subCompanyZoneId = parseInt(object.subCompanyZoneId, 10);
                    else if (typeof object.subCompanyZoneId === "number")
                        message.subCompanyZoneId = object.subCompanyZoneId;
                    else if (typeof object.subCompanyZoneId === "object")
                        message.subCompanyZoneId = new $util.LongBits(object.subCompanyZoneId.low >>> 0, object.subCompanyZoneId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a GetSubCompanyZone message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetSubCompanyZone
             * @static
             * @param {fastcity.admin.GetSubCompanyZone} message GetSubCompanyZone
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetSubCompanyZone.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subCompanyZoneId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subCompanyZoneId = options.longs === String ? "0" : 0;
                if (message.subCompanyZoneId != null && message.hasOwnProperty("subCompanyZoneId"))
                    if (typeof message.subCompanyZoneId === "number")
                        object.subCompanyZoneId = options.longs === String ? String(message.subCompanyZoneId) : message.subCompanyZoneId;
                    else
                        object.subCompanyZoneId = options.longs === String ? $util.Long.prototype.toString.call(message.subCompanyZoneId) : options.longs === Number ? new $util.LongBits(message.subCompanyZoneId.low >>> 0, message.subCompanyZoneId.high >>> 0).toNumber(true) : message.subCompanyZoneId;
                return object;
            };

            /**
             * Converts this GetSubCompanyZone to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetSubCompanyZone
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetSubCompanyZone.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetSubCompanyZone;
        })();

        admin.GetSubCompanyZoneList = (function() {

            /**
             * Properties of a GetSubCompanyZoneList.
             * @memberof fastcity.admin
             * @interface IGetSubCompanyZoneList
             * @property {number|Long|null} [companyId] GetSubCompanyZoneList companyId
             * @property {string|null} [externalSubCompanyId] GetSubCompanyZoneList externalSubCompanyId
             * @property {boolean|null} [withDeleted] GetSubCompanyZoneList withDeleted
             */

            /**
             * Constructs a new GetSubCompanyZoneList.
             * @memberof fastcity.admin
             * @classdesc Represents a GetSubCompanyZoneList.
             * @implements IGetSubCompanyZoneList
             * @constructor
             * @param {fastcity.admin.IGetSubCompanyZoneList=} [properties] Properties to set
             */
            function GetSubCompanyZoneList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetSubCompanyZoneList companyId.
             * @member {number|Long} companyId
             * @memberof fastcity.admin.GetSubCompanyZoneList
             * @instance
             */
            GetSubCompanyZoneList.prototype.companyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetSubCompanyZoneList externalSubCompanyId.
             * @member {string} externalSubCompanyId
             * @memberof fastcity.admin.GetSubCompanyZoneList
             * @instance
             */
            GetSubCompanyZoneList.prototype.externalSubCompanyId = "";

            /**
             * GetSubCompanyZoneList withDeleted.
             * @member {boolean} withDeleted
             * @memberof fastcity.admin.GetSubCompanyZoneList
             * @instance
             */
            GetSubCompanyZoneList.prototype.withDeleted = false;

            /**
             * Creates a new GetSubCompanyZoneList instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetSubCompanyZoneList
             * @static
             * @param {fastcity.admin.IGetSubCompanyZoneList=} [properties] Properties to set
             * @returns {fastcity.admin.GetSubCompanyZoneList} GetSubCompanyZoneList instance
             */
            GetSubCompanyZoneList.create = function create(properties) {
                return new GetSubCompanyZoneList(properties);
            };

            /**
             * Encodes the specified GetSubCompanyZoneList message. Does not implicitly {@link fastcity.admin.GetSubCompanyZoneList.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetSubCompanyZoneList
             * @static
             * @param {fastcity.admin.IGetSubCompanyZoneList} message GetSubCompanyZoneList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSubCompanyZoneList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.companyId != null && Object.hasOwnProperty.call(message, "companyId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.companyId);
                if (message.externalSubCompanyId != null && Object.hasOwnProperty.call(message, "externalSubCompanyId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalSubCompanyId);
                if (message.withDeleted != null && Object.hasOwnProperty.call(message, "withDeleted"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.withDeleted);
                return writer;
            };

            /**
             * Decodes a GetSubCompanyZoneList message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetSubCompanyZoneList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetSubCompanyZoneList} GetSubCompanyZoneList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSubCompanyZoneList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetSubCompanyZoneList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.companyId = reader.uint64();
                        break;
                    case 2:
                        message.externalSubCompanyId = reader.string();
                        break;
                    case 3:
                        message.withDeleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetSubCompanyZoneList message.
             * @function verify
             * @memberof fastcity.admin.GetSubCompanyZoneList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetSubCompanyZoneList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (!$util.isInteger(message.companyId) && !(message.companyId && $util.isInteger(message.companyId.low) && $util.isInteger(message.companyId.high)))
                        return "companyId: integer|Long expected";
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    if (!$util.isString(message.externalSubCompanyId))
                        return "externalSubCompanyId: string expected";
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    if (typeof message.withDeleted !== "boolean")
                        return "withDeleted: boolean expected";
                return null;
            };

            /**
             * Creates a GetSubCompanyZoneList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetSubCompanyZoneList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetSubCompanyZoneList} GetSubCompanyZoneList
             */
            GetSubCompanyZoneList.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetSubCompanyZoneList)
                    return object;
                var message = new $root.fastcity.admin.GetSubCompanyZoneList();
                if (object.companyId != null)
                    if ($util.Long)
                        (message.companyId = $util.Long.fromValue(object.companyId)).unsigned = true;
                    else if (typeof object.companyId === "string")
                        message.companyId = parseInt(object.companyId, 10);
                    else if (typeof object.companyId === "number")
                        message.companyId = object.companyId;
                    else if (typeof object.companyId === "object")
                        message.companyId = new $util.LongBits(object.companyId.low >>> 0, object.companyId.high >>> 0).toNumber(true);
                if (object.externalSubCompanyId != null)
                    message.externalSubCompanyId = String(object.externalSubCompanyId);
                if (object.withDeleted != null)
                    message.withDeleted = Boolean(object.withDeleted);
                return message;
            };

            /**
             * Creates a plain object from a GetSubCompanyZoneList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetSubCompanyZoneList
             * @static
             * @param {fastcity.admin.GetSubCompanyZoneList} message GetSubCompanyZoneList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetSubCompanyZoneList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.companyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.companyId = options.longs === String ? "0" : 0;
                    object.externalSubCompanyId = "";
                    object.withDeleted = false;
                }
                if (message.companyId != null && message.hasOwnProperty("companyId"))
                    if (typeof message.companyId === "number")
                        object.companyId = options.longs === String ? String(message.companyId) : message.companyId;
                    else
                        object.companyId = options.longs === String ? $util.Long.prototype.toString.call(message.companyId) : options.longs === Number ? new $util.LongBits(message.companyId.low >>> 0, message.companyId.high >>> 0).toNumber(true) : message.companyId;
                if (message.externalSubCompanyId != null && message.hasOwnProperty("externalSubCompanyId"))
                    object.externalSubCompanyId = message.externalSubCompanyId;
                if (message.withDeleted != null && message.hasOwnProperty("withDeleted"))
                    object.withDeleted = message.withDeleted;
                return object;
            };

            /**
             * Converts this GetSubCompanyZoneList to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetSubCompanyZoneList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetSubCompanyZoneList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetSubCompanyZoneList;
        })();

        admin.ProtocolVersion = (function() {

            /**
             * Properties of a ProtocolVersion.
             * @memberof fastcity.admin
             * @interface IProtocolVersion
             */

            /**
             * Constructs a new ProtocolVersion.
             * @memberof fastcity.admin
             * @classdesc Represents a ProtocolVersion.
             * @implements IProtocolVersion
             * @constructor
             * @param {fastcity.admin.IProtocolVersion=} [properties] Properties to set
             */
            function ProtocolVersion(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new ProtocolVersion instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.ProtocolVersion
             * @static
             * @param {fastcity.admin.IProtocolVersion=} [properties] Properties to set
             * @returns {fastcity.admin.ProtocolVersion} ProtocolVersion instance
             */
            ProtocolVersion.create = function create(properties) {
                return new ProtocolVersion(properties);
            };

            /**
             * Encodes the specified ProtocolVersion message. Does not implicitly {@link fastcity.admin.ProtocolVersion.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.ProtocolVersion
             * @static
             * @param {fastcity.admin.IProtocolVersion} message ProtocolVersion message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProtocolVersion.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a ProtocolVersion message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.ProtocolVersion
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.ProtocolVersion} ProtocolVersion
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProtocolVersion.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.ProtocolVersion();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ProtocolVersion message.
             * @function verify
             * @memberof fastcity.admin.ProtocolVersion
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ProtocolVersion.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a ProtocolVersion message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.ProtocolVersion
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.ProtocolVersion} ProtocolVersion
             */
            ProtocolVersion.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.ProtocolVersion)
                    return object;
                return new $root.fastcity.admin.ProtocolVersion();
            };

            /**
             * Creates a plain object from a ProtocolVersion message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.ProtocolVersion
             * @static
             * @param {fastcity.admin.ProtocolVersion} message ProtocolVersion
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ProtocolVersion.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this ProtocolVersion to JSON.
             * @function toJSON
             * @memberof fastcity.admin.ProtocolVersion
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ProtocolVersion.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Value enum.
             * @name fastcity.admin.ProtocolVersion.Value
             * @enum {number}
             * @property {number} PLACEHOLDER1=0 PLACEHOLDER1 value
             * @property {number} PLACEHOLDER2=0 PLACEHOLDER2 value
             * @property {number} MAJOR=5 MAJOR value
             * @property {number} MINOR=6 MINOR value
             * @property {number} PATCH=0 PATCH value
             */
            ProtocolVersion.Value = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "PLACEHOLDER1"] = 0;
                values["PLACEHOLDER2"] = 0;
                values[valuesById[5] = "MAJOR"] = 5;
                values[valuesById[6] = "MINOR"] = 6;
                values["PATCH"] = 0;
                return values;
            })();

            return ProtocolVersion;
        })();

        admin.Version = (function() {

            /**
             * Properties of a Version.
             * @memberof fastcity.admin
             * @interface IVersion
             * @property {number|null} [major] Version major
             * @property {number|null} [minor] Version minor
             * @property {number|null} [patch] Version patch
             */

            /**
             * Constructs a new Version.
             * @memberof fastcity.admin
             * @classdesc Represents a Version.
             * @implements IVersion
             * @constructor
             * @param {fastcity.admin.IVersion=} [properties] Properties to set
             */
            function Version(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Version major.
             * @member {number} major
             * @memberof fastcity.admin.Version
             * @instance
             */
            Version.prototype.major = 0;

            /**
             * Version minor.
             * @member {number} minor
             * @memberof fastcity.admin.Version
             * @instance
             */
            Version.prototype.minor = 0;

            /**
             * Version patch.
             * @member {number} patch
             * @memberof fastcity.admin.Version
             * @instance
             */
            Version.prototype.patch = 0;

            /**
             * Creates a new Version instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.Version
             * @static
             * @param {fastcity.admin.IVersion=} [properties] Properties to set
             * @returns {fastcity.admin.Version} Version instance
             */
            Version.create = function create(properties) {
                return new Version(properties);
            };

            /**
             * Encodes the specified Version message. Does not implicitly {@link fastcity.admin.Version.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.Version
             * @static
             * @param {fastcity.admin.IVersion} message Version message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Version.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.major != null && Object.hasOwnProperty.call(message, "major"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.major);
                if (message.minor != null && Object.hasOwnProperty.call(message, "minor"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.minor);
                if (message.patch != null && Object.hasOwnProperty.call(message, "patch"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.patch);
                return writer;
            };

            /**
             * Decodes a Version message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.Version
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.Version} Version
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Version.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.Version();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.major = reader.int32();
                        break;
                    case 2:
                        message.minor = reader.int32();
                        break;
                    case 3:
                        message.patch = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Version message.
             * @function verify
             * @memberof fastcity.admin.Version
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Version.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.major != null && message.hasOwnProperty("major"))
                    if (!$util.isInteger(message.major))
                        return "major: integer expected";
                if (message.minor != null && message.hasOwnProperty("minor"))
                    if (!$util.isInteger(message.minor))
                        return "minor: integer expected";
                if (message.patch != null && message.hasOwnProperty("patch"))
                    if (!$util.isInteger(message.patch))
                        return "patch: integer expected";
                return null;
            };

            /**
             * Creates a Version message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.Version
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.Version} Version
             */
            Version.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.Version)
                    return object;
                var message = new $root.fastcity.admin.Version();
                if (object.major != null)
                    message.major = object.major | 0;
                if (object.minor != null)
                    message.minor = object.minor | 0;
                if (object.patch != null)
                    message.patch = object.patch | 0;
                return message;
            };

            /**
             * Creates a plain object from a Version message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.Version
             * @static
             * @param {fastcity.admin.Version} message Version
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Version.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.major = 0;
                    object.minor = 0;
                    object.patch = 0;
                }
                if (message.major != null && message.hasOwnProperty("major"))
                    object.major = message.major;
                if (message.minor != null && message.hasOwnProperty("minor"))
                    object.minor = message.minor;
                if (message.patch != null && message.hasOwnProperty("patch"))
                    object.patch = message.patch;
                return object;
            };

            /**
             * Converts this Version to JSON.
             * @function toJSON
             * @memberof fastcity.admin.Version
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Version.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Version;
        })();

        admin.GetServerVersion = (function() {

            /**
             * Properties of a GetServerVersion.
             * @memberof fastcity.admin
             * @interface IGetServerVersion
             */

            /**
             * Constructs a new GetServerVersion.
             * @memberof fastcity.admin
             * @classdesc Represents a GetServerVersion.
             * @implements IGetServerVersion
             * @constructor
             * @param {fastcity.admin.IGetServerVersion=} [properties] Properties to set
             */
            function GetServerVersion(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetServerVersion instance using the specified properties.
             * @function create
             * @memberof fastcity.admin.GetServerVersion
             * @static
             * @param {fastcity.admin.IGetServerVersion=} [properties] Properties to set
             * @returns {fastcity.admin.GetServerVersion} GetServerVersion instance
             */
            GetServerVersion.create = function create(properties) {
                return new GetServerVersion(properties);
            };

            /**
             * Encodes the specified GetServerVersion message. Does not implicitly {@link fastcity.admin.GetServerVersion.verify|verify} messages.
             * @function encode
             * @memberof fastcity.admin.GetServerVersion
             * @static
             * @param {fastcity.admin.IGetServerVersion} message GetServerVersion message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetServerVersion.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetServerVersion message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.admin.GetServerVersion
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.admin.GetServerVersion} GetServerVersion
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetServerVersion.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.admin.GetServerVersion();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetServerVersion message.
             * @function verify
             * @memberof fastcity.admin.GetServerVersion
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetServerVersion.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetServerVersion message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.admin.GetServerVersion
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.admin.GetServerVersion} GetServerVersion
             */
            GetServerVersion.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.admin.GetServerVersion)
                    return object;
                return new $root.fastcity.admin.GetServerVersion();
            };

            /**
             * Creates a plain object from a GetServerVersion message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.admin.GetServerVersion
             * @static
             * @param {fastcity.admin.GetServerVersion} message GetServerVersion
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetServerVersion.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetServerVersion to JSON.
             * @function toJSON
             * @memberof fastcity.admin.GetServerVersion
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetServerVersion.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetServerVersion;
        })();

        return admin;
    })();

    fastcity.common = (function() {

        /**
         * Namespace common.
         * @memberof fastcity
         * @namespace
         */
        var common = {};

        common.Company = (function() {

            /**
             * Properties of a Company.
             * @memberof fastcity.common
             * @interface ICompany
             */

            /**
             * Constructs a new Company.
             * @memberof fastcity.common
             * @classdesc Represents a Company.
             * @implements ICompany
             * @constructor
             * @param {fastcity.common.ICompany=} [properties] Properties to set
             */
            function Company(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new Company instance using the specified properties.
             * @function create
             * @memberof fastcity.common.Company
             * @static
             * @param {fastcity.common.ICompany=} [properties] Properties to set
             * @returns {fastcity.common.Company} Company instance
             */
            Company.create = function create(properties) {
                return new Company(properties);
            };

            /**
             * Encodes the specified Company message. Does not implicitly {@link fastcity.common.Company.verify|verify} messages.
             * @function encode
             * @memberof fastcity.common.Company
             * @static
             * @param {fastcity.common.ICompany} message Company message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Company.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a Company message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.common.Company
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.common.Company} Company
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Company.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.common.Company();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Company message.
             * @function verify
             * @memberof fastcity.common.Company
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Company.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a Company message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.common.Company
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.common.Company} Company
             */
            Company.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.common.Company)
                    return object;
                return new $root.fastcity.common.Company();
            };

            /**
             * Creates a plain object from a Company message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.common.Company
             * @static
             * @param {fastcity.common.Company} message Company
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Company.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this Company to JSON.
             * @function toJSON
             * @memberof fastcity.common.Company
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Company.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * MapType enum.
             * @name fastcity.common.Company.MapType
             * @enum {number}
             * @property {number} GOOGLE=0 GOOGLE value
             * @property {number} YANDEX=1 YANDEX value
             */
            Company.MapType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "GOOGLE"] = 0;
                values[valuesById[1] = "YANDEX"] = 1;
                return values;
            })();

            return Company;
        })();

        /**
         * UserRole enum.
         * @name fastcity.common.UserRole
         * @enum {number}
         * @property {number} GLOBAL_ADMIN=0 GLOBAL_ADMIN value
         * @property {number} SUPPORT=1 SUPPORT value
         * @property {number} COMPANY_ADMIN=2 COMPANY_ADMIN value
         * @property {number} COMPANY_MANAGER=3 COMPANY_MANAGER value
         * @property {number} COMPANY_COURIER=4 COMPANY_COURIER value
         */
        common.UserRole = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "GLOBAL_ADMIN"] = 0;
            values[valuesById[1] = "SUPPORT"] = 1;
            values[valuesById[2] = "COMPANY_ADMIN"] = 2;
            values[valuesById[3] = "COMPANY_MANAGER"] = 3;
            values[valuesById[4] = "COMPANY_COURIER"] = 4;
            return values;
        })();

        common.Version = (function() {

            /**
             * Properties of a Version.
             * @memberof fastcity.common
             * @interface IVersion
             * @property {number|null} [major] Version major
             * @property {number|null} [minor] Version minor
             * @property {number|null} [patch] Version patch
             */

            /**
             * Constructs a new Version.
             * @memberof fastcity.common
             * @classdesc Represents a Version.
             * @implements IVersion
             * @constructor
             * @param {fastcity.common.IVersion=} [properties] Properties to set
             */
            function Version(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Version major.
             * @member {number} major
             * @memberof fastcity.common.Version
             * @instance
             */
            Version.prototype.major = 0;

            /**
             * Version minor.
             * @member {number} minor
             * @memberof fastcity.common.Version
             * @instance
             */
            Version.prototype.minor = 0;

            /**
             * Version patch.
             * @member {number} patch
             * @memberof fastcity.common.Version
             * @instance
             */
            Version.prototype.patch = 0;

            /**
             * Creates a new Version instance using the specified properties.
             * @function create
             * @memberof fastcity.common.Version
             * @static
             * @param {fastcity.common.IVersion=} [properties] Properties to set
             * @returns {fastcity.common.Version} Version instance
             */
            Version.create = function create(properties) {
                return new Version(properties);
            };

            /**
             * Encodes the specified Version message. Does not implicitly {@link fastcity.common.Version.verify|verify} messages.
             * @function encode
             * @memberof fastcity.common.Version
             * @static
             * @param {fastcity.common.IVersion} message Version message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Version.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.major != null && Object.hasOwnProperty.call(message, "major"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.major);
                if (message.minor != null && Object.hasOwnProperty.call(message, "minor"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.minor);
                if (message.patch != null && Object.hasOwnProperty.call(message, "patch"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.patch);
                return writer;
            };

            /**
             * Decodes a Version message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.common.Version
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.common.Version} Version
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Version.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.common.Version();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.major = reader.uint32();
                        break;
                    case 2:
                        message.minor = reader.uint32();
                        break;
                    case 3:
                        message.patch = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Version message.
             * @function verify
             * @memberof fastcity.common.Version
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Version.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.major != null && message.hasOwnProperty("major"))
                    if (!$util.isInteger(message.major))
                        return "major: integer expected";
                if (message.minor != null && message.hasOwnProperty("minor"))
                    if (!$util.isInteger(message.minor))
                        return "minor: integer expected";
                if (message.patch != null && message.hasOwnProperty("patch"))
                    if (!$util.isInteger(message.patch))
                        return "patch: integer expected";
                return null;
            };

            /**
             * Creates a Version message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.common.Version
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.common.Version} Version
             */
            Version.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.common.Version)
                    return object;
                var message = new $root.fastcity.common.Version();
                if (object.major != null)
                    message.major = object.major >>> 0;
                if (object.minor != null)
                    message.minor = object.minor >>> 0;
                if (object.patch != null)
                    message.patch = object.patch >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a Version message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.common.Version
             * @static
             * @param {fastcity.common.Version} message Version
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Version.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.major = 0;
                    object.minor = 0;
                    object.patch = 0;
                }
                if (message.major != null && message.hasOwnProperty("major"))
                    object.major = message.major;
                if (message.minor != null && message.hasOwnProperty("minor"))
                    object.minor = message.minor;
                if (message.patch != null && message.hasOwnProperty("patch"))
                    object.patch = message.patch;
                return object;
            };

            /**
             * Converts this Version to JSON.
             * @function toJSON
             * @memberof fastcity.common.Version
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Version.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Version;
        })();

        common.SortOrder = (function() {

            /**
             * Properties of a SortOrder.
             * @memberof fastcity.common
             * @interface ISortOrder
             */

            /**
             * Constructs a new SortOrder.
             * @memberof fastcity.common
             * @classdesc Represents a SortOrder.
             * @implements ISortOrder
             * @constructor
             * @param {fastcity.common.ISortOrder=} [properties] Properties to set
             */
            function SortOrder(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new SortOrder instance using the specified properties.
             * @function create
             * @memberof fastcity.common.SortOrder
             * @static
             * @param {fastcity.common.ISortOrder=} [properties] Properties to set
             * @returns {fastcity.common.SortOrder} SortOrder instance
             */
            SortOrder.create = function create(properties) {
                return new SortOrder(properties);
            };

            /**
             * Encodes the specified SortOrder message. Does not implicitly {@link fastcity.common.SortOrder.verify|verify} messages.
             * @function encode
             * @memberof fastcity.common.SortOrder
             * @static
             * @param {fastcity.common.ISortOrder} message SortOrder message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SortOrder.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a SortOrder message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.common.SortOrder
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.common.SortOrder} SortOrder
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SortOrder.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.common.SortOrder();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SortOrder message.
             * @function verify
             * @memberof fastcity.common.SortOrder
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SortOrder.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a SortOrder message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.common.SortOrder
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.common.SortOrder} SortOrder
             */
            SortOrder.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.common.SortOrder)
                    return object;
                return new $root.fastcity.common.SortOrder();
            };

            /**
             * Creates a plain object from a SortOrder message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.common.SortOrder
             * @static
             * @param {fastcity.common.SortOrder} message SortOrder
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SortOrder.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this SortOrder to JSON.
             * @function toJSON
             * @memberof fastcity.common.SortOrder
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SortOrder.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Order enum.
             * @name fastcity.common.SortOrder.Order
             * @enum {number}
             * @property {number} ASC=0 ASC value
             * @property {number} DESC=1 DESC value
             */
            SortOrder.Order = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ASC"] = 0;
                values[valuesById[1] = "DESC"] = 1;
                return values;
            })();

            return SortOrder;
        })();

        common.Coordinate = (function() {

            /**
             * Properties of a Coordinate.
             * @memberof fastcity.common
             * @interface ICoordinate
             * @property {number|null} [latitude] Coordinate latitude
             * @property {number|null} [longitude] Coordinate longitude
             */

            /**
             * Constructs a new Coordinate.
             * @memberof fastcity.common
             * @classdesc Represents a Coordinate.
             * @implements ICoordinate
             * @constructor
             * @param {fastcity.common.ICoordinate=} [properties] Properties to set
             */
            function Coordinate(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Coordinate latitude.
             * @member {number} latitude
             * @memberof fastcity.common.Coordinate
             * @instance
             */
            Coordinate.prototype.latitude = 0;

            /**
             * Coordinate longitude.
             * @member {number} longitude
             * @memberof fastcity.common.Coordinate
             * @instance
             */
            Coordinate.prototype.longitude = 0;

            /**
             * Creates a new Coordinate instance using the specified properties.
             * @function create
             * @memberof fastcity.common.Coordinate
             * @static
             * @param {fastcity.common.ICoordinate=} [properties] Properties to set
             * @returns {fastcity.common.Coordinate} Coordinate instance
             */
            Coordinate.create = function create(properties) {
                return new Coordinate(properties);
            };

            /**
             * Encodes the specified Coordinate message. Does not implicitly {@link fastcity.common.Coordinate.verify|verify} messages.
             * @function encode
             * @memberof fastcity.common.Coordinate
             * @static
             * @param {fastcity.common.ICoordinate} message Coordinate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Coordinate.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.latitude != null && Object.hasOwnProperty.call(message, "latitude"))
                    writer.uint32(/* id 1, wireType 1 =*/9).double(message.latitude);
                if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                    writer.uint32(/* id 2, wireType 1 =*/17).double(message.longitude);
                return writer;
            };

            /**
             * Decodes a Coordinate message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.common.Coordinate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.common.Coordinate} Coordinate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Coordinate.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.common.Coordinate();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.latitude = reader.double();
                        break;
                    case 2:
                        message.longitude = reader.double();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Coordinate message.
             * @function verify
             * @memberof fastcity.common.Coordinate
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Coordinate.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.latitude != null && message.hasOwnProperty("latitude"))
                    if (typeof message.latitude !== "number")
                        return "latitude: number expected";
                if (message.longitude != null && message.hasOwnProperty("longitude"))
                    if (typeof message.longitude !== "number")
                        return "longitude: number expected";
                return null;
            };

            /**
             * Creates a Coordinate message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.common.Coordinate
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.common.Coordinate} Coordinate
             */
            Coordinate.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.common.Coordinate)
                    return object;
                var message = new $root.fastcity.common.Coordinate();
                if (object.latitude != null)
                    message.latitude = Number(object.latitude);
                if (object.longitude != null)
                    message.longitude = Number(object.longitude);
                return message;
            };

            /**
             * Creates a plain object from a Coordinate message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.common.Coordinate
             * @static
             * @param {fastcity.common.Coordinate} message Coordinate
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Coordinate.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.latitude = 0;
                    object.longitude = 0;
                }
                if (message.latitude != null && message.hasOwnProperty("latitude"))
                    object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
                if (message.longitude != null && message.hasOwnProperty("longitude"))
                    object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
                return object;
            };

            /**
             * Converts this Coordinate to JSON.
             * @function toJSON
             * @memberof fastcity.common.Coordinate
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Coordinate.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Coordinate;
        })();

        common.OrderItem = (function() {

            /**
             * Properties of an OrderItem.
             * @memberof fastcity.common
             * @interface IOrderItem
             */

            /**
             * Constructs a new OrderItem.
             * @memberof fastcity.common
             * @classdesc Represents an OrderItem.
             * @implements IOrderItem
             * @constructor
             * @param {fastcity.common.IOrderItem=} [properties] Properties to set
             */
            function OrderItem(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new OrderItem instance using the specified properties.
             * @function create
             * @memberof fastcity.common.OrderItem
             * @static
             * @param {fastcity.common.IOrderItem=} [properties] Properties to set
             * @returns {fastcity.common.OrderItem} OrderItem instance
             */
            OrderItem.create = function create(properties) {
                return new OrderItem(properties);
            };

            /**
             * Encodes the specified OrderItem message. Does not implicitly {@link fastcity.common.OrderItem.verify|verify} messages.
             * @function encode
             * @memberof fastcity.common.OrderItem
             * @static
             * @param {fastcity.common.IOrderItem} message OrderItem message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderItem.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes an OrderItem message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.common.OrderItem
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.common.OrderItem} OrderItem
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderItem.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.common.OrderItem();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderItem message.
             * @function verify
             * @memberof fastcity.common.OrderItem
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderItem.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an OrderItem message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.common.OrderItem
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.common.OrderItem} OrderItem
             */
            OrderItem.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.common.OrderItem)
                    return object;
                return new $root.fastcity.common.OrderItem();
            };

            /**
             * Creates a plain object from an OrderItem message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.common.OrderItem
             * @static
             * @param {fastcity.common.OrderItem} message OrderItem
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderItem.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this OrderItem to JSON.
             * @function toJSON
             * @memberof fastcity.common.OrderItem
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderItem.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * ProductType enum.
             * @name fastcity.common.OrderItem.ProductType
             * @enum {number}
             * @property {number} T_UNKNOWN=0 T_UNKNOWN value
             * @property {number} T_DESSERT=1 T_DESSERT value
             * @property {number} T_SNACK=2 T_SNACK value
             * @property {number} T_DRINK=3 T_DRINK value
             * @property {number} T_BEER=4 T_BEER value
             * @property {number} T_PIZZA=5 T_PIZZA value
             * @property {number} T_GIFT=6 T_GIFT value
             * @property {number} T_SALAD=7 T_SALAD value
             * @property {number} T_BREAD=8 T_BREAD value
             * @property {number} T_SAUCE=9 T_SAUCE value
             * @property {number} T_COMPOUND=10 T_COMPOUND value
             * @property {number} T_SERVICE=11 T_SERVICE value
             */
            OrderItem.ProductType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "T_UNKNOWN"] = 0;
                values[valuesById[1] = "T_DESSERT"] = 1;
                values[valuesById[2] = "T_SNACK"] = 2;
                values[valuesById[3] = "T_DRINK"] = 3;
                values[valuesById[4] = "T_BEER"] = 4;
                values[valuesById[5] = "T_PIZZA"] = 5;
                values[valuesById[6] = "T_GIFT"] = 6;
                values[valuesById[7] = "T_SALAD"] = 7;
                values[valuesById[8] = "T_BREAD"] = 8;
                values[valuesById[9] = "T_SAUCE"] = 9;
                values[valuesById[10] = "T_COMPOUND"] = 10;
                values[valuesById[11] = "T_SERVICE"] = 11;
                return values;
            })();

            return OrderItem;
        })();

        common.Order = (function() {

            /**
             * Properties of an Order.
             * @memberof fastcity.common
             * @interface IOrder
             */

            /**
             * Constructs a new Order.
             * @memberof fastcity.common
             * @classdesc Represents an Order.
             * @implements IOrder
             * @constructor
             * @param {fastcity.common.IOrder=} [properties] Properties to set
             */
            function Order(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new Order instance using the specified properties.
             * @function create
             * @memberof fastcity.common.Order
             * @static
             * @param {fastcity.common.IOrder=} [properties] Properties to set
             * @returns {fastcity.common.Order} Order instance
             */
            Order.create = function create(properties) {
                return new Order(properties);
            };

            /**
             * Encodes the specified Order message. Does not implicitly {@link fastcity.common.Order.verify|verify} messages.
             * @function encode
             * @memberof fastcity.common.Order
             * @static
             * @param {fastcity.common.IOrder} message Order message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Order.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes an Order message from the specified reader or buffer.
             * @function decode
             * @memberof fastcity.common.Order
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {fastcity.common.Order} Order
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Order.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.fastcity.common.Order();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an Order message.
             * @function verify
             * @memberof fastcity.common.Order
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Order.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an Order message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof fastcity.common.Order
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {fastcity.common.Order} Order
             */
            Order.fromObject = function fromObject(object) {
                if (object instanceof $root.fastcity.common.Order)
                    return object;
                return new $root.fastcity.common.Order();
            };

            /**
             * Creates a plain object from an Order message. Also converts values to other types if specified.
             * @function toObject
             * @memberof fastcity.common.Order
             * @static
             * @param {fastcity.common.Order} message Order
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Order.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this Order to JSON.
             * @function toJSON
             * @memberof fastcity.common.Order
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Order.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Status enum.
             * @name fastcity.common.Order.Status
             * @enum {number}
             * @property {number} S_UNKNOWN=0 S_UNKNOWN value
             * @property {number} S_IN_PROGRESS=1 S_IN_PROGRESS value
             * @property {number} S_MAKELINE=2 S_MAKELINE value
             * @property {number} S_ROUTING_STATION=3 S_ROUTING_STATION value
             * @property {number} S_OUT_THE_DOOR=4 S_OUT_THE_DOOR value
             * @property {number} S_COMPLETE=5 S_COMPLETE value
             * @property {number} S_GIFT=6 S_GIFT value
             * @property {number} S_BAD=7 S_BAD value
             * @property {number} S_VOID=8 S_VOID value
             * @property {number} S_BEING_TAKEN=9 S_BEING_TAKEN value
             * @property {number} S_ABANDONED=10 S_ABANDONED value
             * @property {number} S_SUSPENDED=11 S_SUSPENDED value
             * @property {number} S_GIFT_CARD_PURCHASE=12 S_GIFT_CARD_PURCHASE value
             * @property {number} S_GIFT_CARD_VOID=13 S_GIFT_CARD_VOID value
             * @property {number} S_TRANSFERRED=14 S_TRANSFERRED value
             * @property {number} S_HISTORIC_ORDER=15 S_HISTORIC_ORDER value
             * @property {number} S_BEING_ASSIGNED=16 S_BEING_ASSIGNED value
             */
            Order.Status = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "S_UNKNOWN"] = 0;
                values[valuesById[1] = "S_IN_PROGRESS"] = 1;
                values[valuesById[2] = "S_MAKELINE"] = 2;
                values[valuesById[3] = "S_ROUTING_STATION"] = 3;
                values[valuesById[4] = "S_OUT_THE_DOOR"] = 4;
                values[valuesById[5] = "S_COMPLETE"] = 5;
                values[valuesById[6] = "S_GIFT"] = 6;
                values[valuesById[7] = "S_BAD"] = 7;
                values[valuesById[8] = "S_VOID"] = 8;
                values[valuesById[9] = "S_BEING_TAKEN"] = 9;
                values[valuesById[10] = "S_ABANDONED"] = 10;
                values[valuesById[11] = "S_SUSPENDED"] = 11;
                values[valuesById[12] = "S_GIFT_CARD_PURCHASE"] = 12;
                values[valuesById[13] = "S_GIFT_CARD_VOID"] = 13;
                values[valuesById[14] = "S_TRANSFERRED"] = 14;
                values[valuesById[15] = "S_HISTORIC_ORDER"] = 15;
                values[valuesById[16] = "S_BEING_ASSIGNED"] = 16;
                return values;
            })();

            /**
             * PaymentType enum.
             * @name fastcity.common.Order.PaymentType
             * @enum {number}
             * @property {number} T_UNKNOWN=0 T_UNKNOWN value
             * @property {number} T_PAID=1 T_PAID value
             * @property {number} T_BY_CASH_TO_THE_COURIER=2 T_BY_CASH_TO_THE_COURIER value
             * @property {number} T_BY_CARD_TO_THE_COURIER=3 T_BY_CARD_TO_THE_COURIER value
             * @property {number} T_PAID_BY_CASH=4 T_PAID_BY_CASH value
             * @property {number} T_PAID_BY_CARD=5 T_PAID_BY_CARD value
             * @property {number} T_PAID_BY_CHECK=6 T_PAID_BY_CHECK value
             */
            Order.PaymentType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "T_UNKNOWN"] = 0;
                values[valuesById[1] = "T_PAID"] = 1;
                values[valuesById[2] = "T_BY_CASH_TO_THE_COURIER"] = 2;
                values[valuesById[3] = "T_BY_CARD_TO_THE_COURIER"] = 3;
                values[valuesById[4] = "T_PAID_BY_CASH"] = 4;
                values[valuesById[5] = "T_PAID_BY_CARD"] = 5;
                values[valuesById[6] = "T_PAID_BY_CHECK"] = 6;
                return values;
            })();

            return Order;
        })();

        return common;
    })();

    return fastcity;
})();

$root.google = (function() {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    var google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        var protobuf = {};

        protobuf.FileDescriptorSet = (function() {

            /**
             * Properties of a FileDescriptorSet.
             * @memberof google.protobuf
             * @interface IFileDescriptorSet
             * @property {Array.<google.protobuf.IFileDescriptorProto>|null} [file] FileDescriptorSet file
             */

            /**
             * Constructs a new FileDescriptorSet.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorSet.
             * @implements IFileDescriptorSet
             * @constructor
             * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
             */
            function FileDescriptorSet(properties) {
                this.file = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileDescriptorSet file.
             * @member {Array.<google.protobuf.IFileDescriptorProto>} file
             * @memberof google.protobuf.FileDescriptorSet
             * @instance
             */
            FileDescriptorSet.prototype.file = $util.emptyArray;

            /**
             * Creates a new FileDescriptorSet instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet instance
             */
            FileDescriptorSet.create = function create(properties) {
                return new FileDescriptorSet(properties);
            };

            /**
             * Encodes the specified FileDescriptorSet message. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet} message FileDescriptorSet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorSet.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.file != null && message.file.length)
                    for (var i = 0; i < message.file.length; ++i)
                        $root.google.protobuf.FileDescriptorProto.encode(message.file[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a FileDescriptorSet message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorSet.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileDescriptorSet();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.file && message.file.length))
                            message.file = [];
                        message.file.push($root.google.protobuf.FileDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a FileDescriptorSet message.
             * @function verify
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileDescriptorSet.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.file != null && message.hasOwnProperty("file")) {
                    if (!Array.isArray(message.file))
                        return "file: array expected";
                    for (var i = 0; i < message.file.length; ++i) {
                        var error = $root.google.protobuf.FileDescriptorProto.verify(message.file[i]);
                        if (error)
                            return "file." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a FileDescriptorSet message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             */
            FileDescriptorSet.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileDescriptorSet)
                    return object;
                var message = new $root.google.protobuf.FileDescriptorSet();
                if (object.file) {
                    if (!Array.isArray(object.file))
                        throw TypeError(".google.protobuf.FileDescriptorSet.file: array expected");
                    message.file = [];
                    for (var i = 0; i < object.file.length; ++i) {
                        if (typeof object.file[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorSet.file: object expected");
                        message.file[i] = $root.google.protobuf.FileDescriptorProto.fromObject(object.file[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a FileDescriptorSet message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.FileDescriptorSet} message FileDescriptorSet
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileDescriptorSet.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.file = [];
                if (message.file && message.file.length) {
                    object.file = [];
                    for (var j = 0; j < message.file.length; ++j)
                        object.file[j] = $root.google.protobuf.FileDescriptorProto.toObject(message.file[j], options);
                }
                return object;
            };

            /**
             * Converts this FileDescriptorSet to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileDescriptorSet
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileDescriptorSet.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return FileDescriptorSet;
        })();

        protobuf.FileDescriptorProto = (function() {

            /**
             * Properties of a FileDescriptorProto.
             * @memberof google.protobuf
             * @interface IFileDescriptorProto
             * @property {string|null} [name] FileDescriptorProto name
             * @property {string|null} ["package"] FileDescriptorProto package
             * @property {Array.<string>|null} [dependency] FileDescriptorProto dependency
             * @property {Array.<number>|null} [publicDependency] FileDescriptorProto publicDependency
             * @property {Array.<number>|null} [weakDependency] FileDescriptorProto weakDependency
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [messageType] FileDescriptorProto messageType
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] FileDescriptorProto enumType
             * @property {Array.<google.protobuf.IServiceDescriptorProto>|null} [service] FileDescriptorProto service
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] FileDescriptorProto extension
             * @property {google.protobuf.IFileOptions|null} [options] FileDescriptorProto options
             * @property {google.protobuf.ISourceCodeInfo|null} [sourceCodeInfo] FileDescriptorProto sourceCodeInfo
             * @property {string|null} [syntax] FileDescriptorProto syntax
             */

            /**
             * Constructs a new FileDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorProto.
             * @implements IFileDescriptorProto
             * @constructor
             * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
             */
            function FileDescriptorProto(properties) {
                this.dependency = [];
                this.publicDependency = [];
                this.weakDependency = [];
                this.messageType = [];
                this.enumType = [];
                this.service = [];
                this.extension = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.name = "";

            /**
             * FileDescriptorProto package.
             * @member {string} package
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype["package"] = "";

            /**
             * FileDescriptorProto dependency.
             * @member {Array.<string>} dependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.dependency = $util.emptyArray;

            /**
             * FileDescriptorProto publicDependency.
             * @member {Array.<number>} publicDependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.publicDependency = $util.emptyArray;

            /**
             * FileDescriptorProto weakDependency.
             * @member {Array.<number>} weakDependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.weakDependency = $util.emptyArray;

            /**
             * FileDescriptorProto messageType.
             * @member {Array.<google.protobuf.IDescriptorProto>} messageType
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.messageType = $util.emptyArray;

            /**
             * FileDescriptorProto enumType.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.enumType = $util.emptyArray;

            /**
             * FileDescriptorProto service.
             * @member {Array.<google.protobuf.IServiceDescriptorProto>} service
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.service = $util.emptyArray;

            /**
             * FileDescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * FileDescriptorProto options.
             * @member {google.protobuf.IFileOptions|null|undefined} options
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.options = null;

            /**
             * FileDescriptorProto sourceCodeInfo.
             * @member {google.protobuf.ISourceCodeInfo|null|undefined} sourceCodeInfo
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.sourceCodeInfo = null;

            /**
             * FileDescriptorProto syntax.
             * @member {string} syntax
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.syntax = "";

            /**
             * Creates a new FileDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto instance
             */
            FileDescriptorProto.create = function create(properties) {
                return new FileDescriptorProto(properties);
            };

            /**
             * Encodes the specified FileDescriptorProto message. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto} message FileDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message["package"] != null && Object.hasOwnProperty.call(message, "package"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message["package"]);
                if (message.dependency != null && message.dependency.length)
                    for (var i = 0; i < message.dependency.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.dependency[i]);
                if (message.messageType != null && message.messageType.length)
                    for (var i = 0; i < message.messageType.length; ++i)
                        $root.google.protobuf.DescriptorProto.encode(message.messageType[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.enumType != null && message.enumType.length)
                    for (var i = 0; i < message.enumType.length; ++i)
                        $root.google.protobuf.EnumDescriptorProto.encode(message.enumType[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.service != null && message.service.length)
                    for (var i = 0; i < message.service.length; ++i)
                        $root.google.protobuf.ServiceDescriptorProto.encode(message.service[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.extension != null && message.extension.length)
                    for (var i = 0; i < message.extension.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(message.extension[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.FileOptions.encode(message.options, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.sourceCodeInfo != null && Object.hasOwnProperty.call(message, "sourceCodeInfo"))
                    $root.google.protobuf.SourceCodeInfo.encode(message.sourceCodeInfo, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.publicDependency != null && message.publicDependency.length)
                    for (var i = 0; i < message.publicDependency.length; ++i)
                        writer.uint32(/* id 10, wireType 0 =*/80).int32(message.publicDependency[i]);
                if (message.weakDependency != null && message.weakDependency.length)
                    for (var i = 0; i < message.weakDependency.length; ++i)
                        writer.uint32(/* id 11, wireType 0 =*/88).int32(message.weakDependency[i]);
                if (message.syntax != null && Object.hasOwnProperty.call(message, "syntax"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.syntax);
                return writer;
            };

            /**
             * Decodes a FileDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message["package"] = reader.string();
                        break;
                    case 3:
                        if (!(message.dependency && message.dependency.length))
                            message.dependency = [];
                        message.dependency.push(reader.string());
                        break;
                    case 10:
                        if (!(message.publicDependency && message.publicDependency.length))
                            message.publicDependency = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.publicDependency.push(reader.int32());
                        } else
                            message.publicDependency.push(reader.int32());
                        break;
                    case 11:
                        if (!(message.weakDependency && message.weakDependency.length))
                            message.weakDependency = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.weakDependency.push(reader.int32());
                        } else
                            message.weakDependency.push(reader.int32());
                        break;
                    case 4:
                        if (!(message.messageType && message.messageType.length))
                            message.messageType = [];
                        message.messageType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 5:
                        if (!(message.enumType && message.enumType.length))
                            message.enumType = [];
                        message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 6:
                        if (!(message.service && message.service.length))
                            message.service = [];
                        message.service.push($root.google.protobuf.ServiceDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 7:
                        if (!(message.extension && message.extension.length))
                            message.extension = [];
                        message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 8:
                        message.options = $root.google.protobuf.FileOptions.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.decode(reader, reader.uint32());
                        break;
                    case 12:
                        message.syntax = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a FileDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message["package"] != null && message.hasOwnProperty("package"))
                    if (!$util.isString(message["package"]))
                        return "package: string expected";
                if (message.dependency != null && message.hasOwnProperty("dependency")) {
                    if (!Array.isArray(message.dependency))
                        return "dependency: array expected";
                    for (var i = 0; i < message.dependency.length; ++i)
                        if (!$util.isString(message.dependency[i]))
                            return "dependency: string[] expected";
                }
                if (message.publicDependency != null && message.hasOwnProperty("publicDependency")) {
                    if (!Array.isArray(message.publicDependency))
                        return "publicDependency: array expected";
                    for (var i = 0; i < message.publicDependency.length; ++i)
                        if (!$util.isInteger(message.publicDependency[i]))
                            return "publicDependency: integer[] expected";
                }
                if (message.weakDependency != null && message.hasOwnProperty("weakDependency")) {
                    if (!Array.isArray(message.weakDependency))
                        return "weakDependency: array expected";
                    for (var i = 0; i < message.weakDependency.length; ++i)
                        if (!$util.isInteger(message.weakDependency[i]))
                            return "weakDependency: integer[] expected";
                }
                if (message.messageType != null && message.hasOwnProperty("messageType")) {
                    if (!Array.isArray(message.messageType))
                        return "messageType: array expected";
                    for (var i = 0; i < message.messageType.length; ++i) {
                        var error = $root.google.protobuf.DescriptorProto.verify(message.messageType[i]);
                        if (error)
                            return "messageType." + error;
                    }
                }
                if (message.enumType != null && message.hasOwnProperty("enumType")) {
                    if (!Array.isArray(message.enumType))
                        return "enumType: array expected";
                    for (var i = 0; i < message.enumType.length; ++i) {
                        var error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                        if (error)
                            return "enumType." + error;
                    }
                }
                if (message.service != null && message.hasOwnProperty("service")) {
                    if (!Array.isArray(message.service))
                        return "service: array expected";
                    for (var i = 0; i < message.service.length; ++i) {
                        var error = $root.google.protobuf.ServiceDescriptorProto.verify(message.service[i]);
                        if (error)
                            return "service." + error;
                    }
                }
                if (message.extension != null && message.hasOwnProperty("extension")) {
                    if (!Array.isArray(message.extension))
                        return "extension: array expected";
                    for (var i = 0; i < message.extension.length; ++i) {
                        var error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                        if (error)
                            return "extension." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.FileOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo")) {
                    var error = $root.google.protobuf.SourceCodeInfo.verify(message.sourceCodeInfo);
                    if (error)
                        return "sourceCodeInfo." + error;
                }
                if (message.syntax != null && message.hasOwnProperty("syntax"))
                    if (!$util.isString(message.syntax))
                        return "syntax: string expected";
                return null;
            };

            /**
             * Creates a FileDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             */
            FileDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.FileDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object["package"] != null)
                    message["package"] = String(object["package"]);
                if (object.dependency) {
                    if (!Array.isArray(object.dependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.dependency: array expected");
                    message.dependency = [];
                    for (var i = 0; i < object.dependency.length; ++i)
                        message.dependency[i] = String(object.dependency[i]);
                }
                if (object.publicDependency) {
                    if (!Array.isArray(object.publicDependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.publicDependency: array expected");
                    message.publicDependency = [];
                    for (var i = 0; i < object.publicDependency.length; ++i)
                        message.publicDependency[i] = object.publicDependency[i] | 0;
                }
                if (object.weakDependency) {
                    if (!Array.isArray(object.weakDependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.weakDependency: array expected");
                    message.weakDependency = [];
                    for (var i = 0; i < object.weakDependency.length; ++i)
                        message.weakDependency[i] = object.weakDependency[i] | 0;
                }
                if (object.messageType) {
                    if (!Array.isArray(object.messageType))
                        throw TypeError(".google.protobuf.FileDescriptorProto.messageType: array expected");
                    message.messageType = [];
                    for (var i = 0; i < object.messageType.length; ++i) {
                        if (typeof object.messageType[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.messageType: object expected");
                        message.messageType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.messageType[i]);
                    }
                }
                if (object.enumType) {
                    if (!Array.isArray(object.enumType))
                        throw TypeError(".google.protobuf.FileDescriptorProto.enumType: array expected");
                    message.enumType = [];
                    for (var i = 0; i < object.enumType.length; ++i) {
                        if (typeof object.enumType[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.enumType: object expected");
                        message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                    }
                }
                if (object.service) {
                    if (!Array.isArray(object.service))
                        throw TypeError(".google.protobuf.FileDescriptorProto.service: array expected");
                    message.service = [];
                    for (var i = 0; i < object.service.length; ++i) {
                        if (typeof object.service[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.service: object expected");
                        message.service[i] = $root.google.protobuf.ServiceDescriptorProto.fromObject(object.service[i]);
                    }
                }
                if (object.extension) {
                    if (!Array.isArray(object.extension))
                        throw TypeError(".google.protobuf.FileDescriptorProto.extension: array expected");
                    message.extension = [];
                    for (var i = 0; i < object.extension.length; ++i) {
                        if (typeof object.extension[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.extension: object expected");
                        message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.FileDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.FileOptions.fromObject(object.options);
                }
                if (object.sourceCodeInfo != null) {
                    if (typeof object.sourceCodeInfo !== "object")
                        throw TypeError(".google.protobuf.FileDescriptorProto.sourceCodeInfo: object expected");
                    message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.fromObject(object.sourceCodeInfo);
                }
                if (object.syntax != null)
                    message.syntax = String(object.syntax);
                return message;
            };

            /**
             * Creates a plain object from a FileDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.FileDescriptorProto} message FileDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.dependency = [];
                    object.messageType = [];
                    object.enumType = [];
                    object.service = [];
                    object.extension = [];
                    object.publicDependency = [];
                    object.weakDependency = [];
                }
                if (options.defaults) {
                    object.name = "";
                    object["package"] = "";
                    object.options = null;
                    object.sourceCodeInfo = null;
                    object.syntax = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message["package"] != null && message.hasOwnProperty("package"))
                    object["package"] = message["package"];
                if (message.dependency && message.dependency.length) {
                    object.dependency = [];
                    for (var j = 0; j < message.dependency.length; ++j)
                        object.dependency[j] = message.dependency[j];
                }
                if (message.messageType && message.messageType.length) {
                    object.messageType = [];
                    for (var j = 0; j < message.messageType.length; ++j)
                        object.messageType[j] = $root.google.protobuf.DescriptorProto.toObject(message.messageType[j], options);
                }
                if (message.enumType && message.enumType.length) {
                    object.enumType = [];
                    for (var j = 0; j < message.enumType.length; ++j)
                        object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                }
                if (message.service && message.service.length) {
                    object.service = [];
                    for (var j = 0; j < message.service.length; ++j)
                        object.service[j] = $root.google.protobuf.ServiceDescriptorProto.toObject(message.service[j], options);
                }
                if (message.extension && message.extension.length) {
                    object.extension = [];
                    for (var j = 0; j < message.extension.length; ++j)
                        object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.FileOptions.toObject(message.options, options);
                if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo"))
                    object.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.toObject(message.sourceCodeInfo, options);
                if (message.publicDependency && message.publicDependency.length) {
                    object.publicDependency = [];
                    for (var j = 0; j < message.publicDependency.length; ++j)
                        object.publicDependency[j] = message.publicDependency[j];
                }
                if (message.weakDependency && message.weakDependency.length) {
                    object.weakDependency = [];
                    for (var j = 0; j < message.weakDependency.length; ++j)
                        object.weakDependency[j] = message.weakDependency[j];
                }
                if (message.syntax != null && message.hasOwnProperty("syntax"))
                    object.syntax = message.syntax;
                return object;
            };

            /**
             * Converts this FileDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return FileDescriptorProto;
        })();

        protobuf.DescriptorProto = (function() {

            /**
             * Properties of a DescriptorProto.
             * @memberof google.protobuf
             * @interface IDescriptorProto
             * @property {string|null} [name] DescriptorProto name
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [field] DescriptorProto field
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] DescriptorProto extension
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [nestedType] DescriptorProto nestedType
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] DescriptorProto enumType
             * @property {Array.<google.protobuf.DescriptorProto.IExtensionRange>|null} [extensionRange] DescriptorProto extensionRange
             * @property {Array.<google.protobuf.IOneofDescriptorProto>|null} [oneofDecl] DescriptorProto oneofDecl
             * @property {google.protobuf.IMessageOptions|null} [options] DescriptorProto options
             * @property {Array.<google.protobuf.DescriptorProto.IReservedRange>|null} [reservedRange] DescriptorProto reservedRange
             * @property {Array.<string>|null} [reservedName] DescriptorProto reservedName
             */

            /**
             * Constructs a new DescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a DescriptorProto.
             * @implements IDescriptorProto
             * @constructor
             * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
             */
            function DescriptorProto(properties) {
                this.field = [];
                this.extension = [];
                this.nestedType = [];
                this.enumType = [];
                this.extensionRange = [];
                this.oneofDecl = [];
                this.reservedRange = [];
                this.reservedName = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.name = "";

            /**
             * DescriptorProto field.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} field
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.field = $util.emptyArray;

            /**
             * DescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * DescriptorProto nestedType.
             * @member {Array.<google.protobuf.IDescriptorProto>} nestedType
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.nestedType = $util.emptyArray;

            /**
             * DescriptorProto enumType.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.enumType = $util.emptyArray;

            /**
             * DescriptorProto extensionRange.
             * @member {Array.<google.protobuf.DescriptorProto.IExtensionRange>} extensionRange
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extensionRange = $util.emptyArray;

            /**
             * DescriptorProto oneofDecl.
             * @member {Array.<google.protobuf.IOneofDescriptorProto>} oneofDecl
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.oneofDecl = $util.emptyArray;

            /**
             * DescriptorProto options.
             * @member {google.protobuf.IMessageOptions|null|undefined} options
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.options = null;

            /**
             * DescriptorProto reservedRange.
             * @member {Array.<google.protobuf.DescriptorProto.IReservedRange>} reservedRange
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reservedRange = $util.emptyArray;

            /**
             * DescriptorProto reservedName.
             * @member {Array.<string>} reservedName
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reservedName = $util.emptyArray;

            /**
             * Creates a new DescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.DescriptorProto} DescriptorProto instance
             */
            DescriptorProto.create = function create(properties) {
                return new DescriptorProto(properties);
            };

            /**
             * Encodes the specified DescriptorProto message. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto} message DescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.field != null && message.field.length)
                    for (var i = 0; i < message.field.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(message.field[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.nestedType != null && message.nestedType.length)
                    for (var i = 0; i < message.nestedType.length; ++i)
                        $root.google.protobuf.DescriptorProto.encode(message.nestedType[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.enumType != null && message.enumType.length)
                    for (var i = 0; i < message.enumType.length; ++i)
                        $root.google.protobuf.EnumDescriptorProto.encode(message.enumType[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.extensionRange != null && message.extensionRange.length)
                    for (var i = 0; i < message.extensionRange.length; ++i)
                        $root.google.protobuf.DescriptorProto.ExtensionRange.encode(message.extensionRange[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.extension != null && message.extension.length)
                    for (var i = 0; i < message.extension.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(message.extension[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.MessageOptions.encode(message.options, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.oneofDecl != null && message.oneofDecl.length)
                    for (var i = 0; i < message.oneofDecl.length; ++i)
                        $root.google.protobuf.OneofDescriptorProto.encode(message.oneofDecl[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.reservedRange != null && message.reservedRange.length)
                    for (var i = 0; i < message.reservedRange.length; ++i)
                        $root.google.protobuf.DescriptorProto.ReservedRange.encode(message.reservedRange[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.reservedName != null && message.reservedName.length)
                    for (var i = 0; i < message.reservedName.length; ++i)
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.reservedName[i]);
                return writer;
            };

            /**
             * Decodes a DescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        if (!(message.field && message.field.length))
                            message.field = [];
                        message.field.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 6:
                        if (!(message.extension && message.extension.length))
                            message.extension = [];
                        message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        if (!(message.nestedType && message.nestedType.length))
                            message.nestedType = [];
                        message.nestedType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 4:
                        if (!(message.enumType && message.enumType.length))
                            message.enumType = [];
                        message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 5:
                        if (!(message.extensionRange && message.extensionRange.length))
                            message.extensionRange = [];
                        message.extensionRange.push($root.google.protobuf.DescriptorProto.ExtensionRange.decode(reader, reader.uint32()));
                        break;
                    case 8:
                        if (!(message.oneofDecl && message.oneofDecl.length))
                            message.oneofDecl = [];
                        message.oneofDecl.push($root.google.protobuf.OneofDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 7:
                        message.options = $root.google.protobuf.MessageOptions.decode(reader, reader.uint32());
                        break;
                    case 9:
                        if (!(message.reservedRange && message.reservedRange.length))
                            message.reservedRange = [];
                        message.reservedRange.push($root.google.protobuf.DescriptorProto.ReservedRange.decode(reader, reader.uint32()));
                        break;
                    case 10:
                        if (!(message.reservedName && message.reservedName.length))
                            message.reservedName = [];
                        message.reservedName.push(reader.string());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DescriptorProto message.
             * @function verify
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.field != null && message.hasOwnProperty("field")) {
                    if (!Array.isArray(message.field))
                        return "field: array expected";
                    for (var i = 0; i < message.field.length; ++i) {
                        var error = $root.google.protobuf.FieldDescriptorProto.verify(message.field[i]);
                        if (error)
                            return "field." + error;
                    }
                }
                if (message.extension != null && message.hasOwnProperty("extension")) {
                    if (!Array.isArray(message.extension))
                        return "extension: array expected";
                    for (var i = 0; i < message.extension.length; ++i) {
                        var error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                        if (error)
                            return "extension." + error;
                    }
                }
                if (message.nestedType != null && message.hasOwnProperty("nestedType")) {
                    if (!Array.isArray(message.nestedType))
                        return "nestedType: array expected";
                    for (var i = 0; i < message.nestedType.length; ++i) {
                        var error = $root.google.protobuf.DescriptorProto.verify(message.nestedType[i]);
                        if (error)
                            return "nestedType." + error;
                    }
                }
                if (message.enumType != null && message.hasOwnProperty("enumType")) {
                    if (!Array.isArray(message.enumType))
                        return "enumType: array expected";
                    for (var i = 0; i < message.enumType.length; ++i) {
                        var error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                        if (error)
                            return "enumType." + error;
                    }
                }
                if (message.extensionRange != null && message.hasOwnProperty("extensionRange")) {
                    if (!Array.isArray(message.extensionRange))
                        return "extensionRange: array expected";
                    for (var i = 0; i < message.extensionRange.length; ++i) {
                        var error = $root.google.protobuf.DescriptorProto.ExtensionRange.verify(message.extensionRange[i]);
                        if (error)
                            return "extensionRange." + error;
                    }
                }
                if (message.oneofDecl != null && message.hasOwnProperty("oneofDecl")) {
                    if (!Array.isArray(message.oneofDecl))
                        return "oneofDecl: array expected";
                    for (var i = 0; i < message.oneofDecl.length; ++i) {
                        var error = $root.google.protobuf.OneofDescriptorProto.verify(message.oneofDecl[i]);
                        if (error)
                            return "oneofDecl." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.MessageOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.reservedRange != null && message.hasOwnProperty("reservedRange")) {
                    if (!Array.isArray(message.reservedRange))
                        return "reservedRange: array expected";
                    for (var i = 0; i < message.reservedRange.length; ++i) {
                        var error = $root.google.protobuf.DescriptorProto.ReservedRange.verify(message.reservedRange[i]);
                        if (error)
                            return "reservedRange." + error;
                    }
                }
                if (message.reservedName != null && message.hasOwnProperty("reservedName")) {
                    if (!Array.isArray(message.reservedName))
                        return "reservedName: array expected";
                    for (var i = 0; i < message.reservedName.length; ++i)
                        if (!$util.isString(message.reservedName[i]))
                            return "reservedName: string[] expected";
                }
                return null;
            };

            /**
             * Creates a DescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             */
            DescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.DescriptorProto)
                    return object;
                var message = new $root.google.protobuf.DescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.field) {
                    if (!Array.isArray(object.field))
                        throw TypeError(".google.protobuf.DescriptorProto.field: array expected");
                    message.field = [];
                    for (var i = 0; i < object.field.length; ++i) {
                        if (typeof object.field[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.field: object expected");
                        message.field[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.field[i]);
                    }
                }
                if (object.extension) {
                    if (!Array.isArray(object.extension))
                        throw TypeError(".google.protobuf.DescriptorProto.extension: array expected");
                    message.extension = [];
                    for (var i = 0; i < object.extension.length; ++i) {
                        if (typeof object.extension[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.extension: object expected");
                        message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                    }
                }
                if (object.nestedType) {
                    if (!Array.isArray(object.nestedType))
                        throw TypeError(".google.protobuf.DescriptorProto.nestedType: array expected");
                    message.nestedType = [];
                    for (var i = 0; i < object.nestedType.length; ++i) {
                        if (typeof object.nestedType[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.nestedType: object expected");
                        message.nestedType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.nestedType[i]);
                    }
                }
                if (object.enumType) {
                    if (!Array.isArray(object.enumType))
                        throw TypeError(".google.protobuf.DescriptorProto.enumType: array expected");
                    message.enumType = [];
                    for (var i = 0; i < object.enumType.length; ++i) {
                        if (typeof object.enumType[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.enumType: object expected");
                        message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                    }
                }
                if (object.extensionRange) {
                    if (!Array.isArray(object.extensionRange))
                        throw TypeError(".google.protobuf.DescriptorProto.extensionRange: array expected");
                    message.extensionRange = [];
                    for (var i = 0; i < object.extensionRange.length; ++i) {
                        if (typeof object.extensionRange[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.extensionRange: object expected");
                        message.extensionRange[i] = $root.google.protobuf.DescriptorProto.ExtensionRange.fromObject(object.extensionRange[i]);
                    }
                }
                if (object.oneofDecl) {
                    if (!Array.isArray(object.oneofDecl))
                        throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: array expected");
                    message.oneofDecl = [];
                    for (var i = 0; i < object.oneofDecl.length; ++i) {
                        if (typeof object.oneofDecl[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: object expected");
                        message.oneofDecl[i] = $root.google.protobuf.OneofDescriptorProto.fromObject(object.oneofDecl[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.DescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.MessageOptions.fromObject(object.options);
                }
                if (object.reservedRange) {
                    if (!Array.isArray(object.reservedRange))
                        throw TypeError(".google.protobuf.DescriptorProto.reservedRange: array expected");
                    message.reservedRange = [];
                    for (var i = 0; i < object.reservedRange.length; ++i) {
                        if (typeof object.reservedRange[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.reservedRange: object expected");
                        message.reservedRange[i] = $root.google.protobuf.DescriptorProto.ReservedRange.fromObject(object.reservedRange[i]);
                    }
                }
                if (object.reservedName) {
                    if (!Array.isArray(object.reservedName))
                        throw TypeError(".google.protobuf.DescriptorProto.reservedName: array expected");
                    message.reservedName = [];
                    for (var i = 0; i < object.reservedName.length; ++i)
                        message.reservedName[i] = String(object.reservedName[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from a DescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.DescriptorProto} message DescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.field = [];
                    object.nestedType = [];
                    object.enumType = [];
                    object.extensionRange = [];
                    object.extension = [];
                    object.oneofDecl = [];
                    object.reservedRange = [];
                    object.reservedName = [];
                }
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.field && message.field.length) {
                    object.field = [];
                    for (var j = 0; j < message.field.length; ++j)
                        object.field[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.field[j], options);
                }
                if (message.nestedType && message.nestedType.length) {
                    object.nestedType = [];
                    for (var j = 0; j < message.nestedType.length; ++j)
                        object.nestedType[j] = $root.google.protobuf.DescriptorProto.toObject(message.nestedType[j], options);
                }
                if (message.enumType && message.enumType.length) {
                    object.enumType = [];
                    for (var j = 0; j < message.enumType.length; ++j)
                        object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                }
                if (message.extensionRange && message.extensionRange.length) {
                    object.extensionRange = [];
                    for (var j = 0; j < message.extensionRange.length; ++j)
                        object.extensionRange[j] = $root.google.protobuf.DescriptorProto.ExtensionRange.toObject(message.extensionRange[j], options);
                }
                if (message.extension && message.extension.length) {
                    object.extension = [];
                    for (var j = 0; j < message.extension.length; ++j)
                        object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.MessageOptions.toObject(message.options, options);
                if (message.oneofDecl && message.oneofDecl.length) {
                    object.oneofDecl = [];
                    for (var j = 0; j < message.oneofDecl.length; ++j)
                        object.oneofDecl[j] = $root.google.protobuf.OneofDescriptorProto.toObject(message.oneofDecl[j], options);
                }
                if (message.reservedRange && message.reservedRange.length) {
                    object.reservedRange = [];
                    for (var j = 0; j < message.reservedRange.length; ++j)
                        object.reservedRange[j] = $root.google.protobuf.DescriptorProto.ReservedRange.toObject(message.reservedRange[j], options);
                }
                if (message.reservedName && message.reservedName.length) {
                    object.reservedName = [];
                    for (var j = 0; j < message.reservedName.length; ++j)
                        object.reservedName[j] = message.reservedName[j];
                }
                return object;
            };

            /**
             * Converts this DescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.DescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            DescriptorProto.ExtensionRange = (function() {

                /**
                 * Properties of an ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IExtensionRange
                 * @property {number|null} [start] ExtensionRange start
                 * @property {number|null} [end] ExtensionRange end
                 */

                /**
                 * Constructs a new ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents an ExtensionRange.
                 * @implements IExtensionRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                 */
                function ExtensionRange(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExtensionRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.start = 0;

                /**
                 * ExtensionRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.end = 0;

                /**
                 * Creates a new ExtensionRange instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange instance
                 */
                ExtensionRange.create = function create(properties) {
                    return new ExtensionRange(properties);
                };

                /**
                 * Encodes the specified ExtensionRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange} message ExtensionRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExtensionRange.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                    return writer;
                };

                /**
                 * Decodes an ExtensionRange message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExtensionRange.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.start = reader.int32();
                            break;
                        case 2:
                            message.end = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies an ExtensionRange message.
                 * @function verify
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExtensionRange.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.start != null && message.hasOwnProperty("start"))
                        if (!$util.isInteger(message.start))
                            return "start: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates an ExtensionRange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 */
                ExtensionRange.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DescriptorProto.ExtensionRange)
                        return object;
                    var message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                    if (object.start != null)
                        message.start = object.start | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an ExtensionRange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.ExtensionRange} message ExtensionRange
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExtensionRange.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.start = 0;
                        object.end = 0;
                    }
                    if (message.start != null && message.hasOwnProperty("start"))
                        object.start = message.start;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this ExtensionRange to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExtensionRange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExtensionRange;
            })();

            DescriptorProto.ReservedRange = (function() {

                /**
                 * Properties of a ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IReservedRange
                 * @property {number|null} [start] ReservedRange start
                 * @property {number|null} [end] ReservedRange end
                 */

                /**
                 * Constructs a new ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents a ReservedRange.
                 * @implements IReservedRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                 */
                function ReservedRange(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ReservedRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.start = 0;

                /**
                 * ReservedRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.end = 0;

                /**
                 * Creates a new ReservedRange instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange instance
                 */
                ReservedRange.create = function create(properties) {
                    return new ReservedRange(properties);
                };

                /**
                 * Encodes the specified ReservedRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange} message ReservedRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReservedRange.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                    return writer;
                };

                /**
                 * Decodes a ReservedRange message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReservedRange.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.start = reader.int32();
                            break;
                        case 2:
                            message.end = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a ReservedRange message.
                 * @function verify
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReservedRange.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.start != null && message.hasOwnProperty("start"))
                        if (!$util.isInteger(message.start))
                            return "start: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates a ReservedRange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 */
                ReservedRange.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DescriptorProto.ReservedRange)
                        return object;
                    var message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                    if (object.start != null)
                        message.start = object.start | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a ReservedRange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.ReservedRange} message ReservedRange
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReservedRange.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.start = 0;
                        object.end = 0;
                    }
                    if (message.start != null && message.hasOwnProperty("start"))
                        object.start = message.start;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this ReservedRange to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReservedRange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReservedRange;
            })();

            return DescriptorProto;
        })();

        protobuf.FieldDescriptorProto = (function() {

            /**
             * Properties of a FieldDescriptorProto.
             * @memberof google.protobuf
             * @interface IFieldDescriptorProto
             * @property {string|null} [name] FieldDescriptorProto name
             * @property {number|null} [number] FieldDescriptorProto number
             * @property {google.protobuf.FieldDescriptorProto.Label|null} [label] FieldDescriptorProto label
             * @property {google.protobuf.FieldDescriptorProto.Type|null} [type] FieldDescriptorProto type
             * @property {string|null} [typeName] FieldDescriptorProto typeName
             * @property {string|null} [extendee] FieldDescriptorProto extendee
             * @property {string|null} [defaultValue] FieldDescriptorProto defaultValue
             * @property {number|null} [oneofIndex] FieldDescriptorProto oneofIndex
             * @property {string|null} [jsonName] FieldDescriptorProto jsonName
             * @property {google.protobuf.IFieldOptions|null} [options] FieldDescriptorProto options
             */

            /**
             * Constructs a new FieldDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FieldDescriptorProto.
             * @implements IFieldDescriptorProto
             * @constructor
             * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
             */
            function FieldDescriptorProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FieldDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.name = "";

            /**
             * FieldDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.number = 0;

            /**
             * FieldDescriptorProto label.
             * @member {google.protobuf.FieldDescriptorProto.Label} label
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.label = 1;

            /**
             * FieldDescriptorProto type.
             * @member {google.protobuf.FieldDescriptorProto.Type} type
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.type = 1;

            /**
             * FieldDescriptorProto typeName.
             * @member {string} typeName
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.typeName = "";

            /**
             * FieldDescriptorProto extendee.
             * @member {string} extendee
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.extendee = "";

            /**
             * FieldDescriptorProto defaultValue.
             * @member {string} defaultValue
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.defaultValue = "";

            /**
             * FieldDescriptorProto oneofIndex.
             * @member {number} oneofIndex
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.oneofIndex = 0;

            /**
             * FieldDescriptorProto jsonName.
             * @member {string} jsonName
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.jsonName = "";

            /**
             * FieldDescriptorProto options.
             * @member {google.protobuf.IFieldOptions|null|undefined} options
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.options = null;

            /**
             * Creates a new FieldDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto instance
             */
            FieldDescriptorProto.create = function create(properties) {
                return new FieldDescriptorProto(properties);
            };

            /**
             * Encodes the specified FieldDescriptorProto message. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto} message FieldDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.extendee != null && Object.hasOwnProperty.call(message, "extendee"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.extendee);
                if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.number);
                if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.label);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
                if (message.typeName != null && Object.hasOwnProperty.call(message, "typeName"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.typeName);
                if (message.defaultValue != null && Object.hasOwnProperty.call(message, "defaultValue"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.defaultValue);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.FieldOptions.encode(message.options, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.oneofIndex != null && Object.hasOwnProperty.call(message, "oneofIndex"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.oneofIndex);
                if (message.jsonName != null && Object.hasOwnProperty.call(message, "jsonName"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.jsonName);
                return writer;
            };

            /**
             * Decodes a FieldDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.number = reader.int32();
                        break;
                    case 4:
                        message.label = reader.int32();
                        break;
                    case 5:
                        message.type = reader.int32();
                        break;
                    case 6:
                        message.typeName = reader.string();
                        break;
                    case 2:
                        message.extendee = reader.string();
                        break;
                    case 7:
                        message.defaultValue = reader.string();
                        break;
                    case 9:
                        message.oneofIndex = reader.int32();
                        break;
                    case 10:
                        message.jsonName = reader.string();
                        break;
                    case 8:
                        message.options = $root.google.protobuf.FieldOptions.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a FieldDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FieldDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.number != null && message.hasOwnProperty("number"))
                    if (!$util.isInteger(message.number))
                        return "number: integer expected";
                if (message.label != null && message.hasOwnProperty("label"))
                    switch (message.label) {
                    default:
                        return "label: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                    case 18:
                        break;
                    }
                if (message.typeName != null && message.hasOwnProperty("typeName"))
                    if (!$util.isString(message.typeName))
                        return "typeName: string expected";
                if (message.extendee != null && message.hasOwnProperty("extendee"))
                    if (!$util.isString(message.extendee))
                        return "extendee: string expected";
                if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                    if (!$util.isString(message.defaultValue))
                        return "defaultValue: string expected";
                if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                    if (!$util.isInteger(message.oneofIndex))
                        return "oneofIndex: integer expected";
                if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                    if (!$util.isString(message.jsonName))
                        return "jsonName: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.FieldOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates a FieldDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             */
            FieldDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FieldDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.FieldDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.number != null)
                    message.number = object.number | 0;
                switch (object.label) {
                case "LABEL_OPTIONAL":
                case 1:
                    message.label = 1;
                    break;
                case "LABEL_REQUIRED":
                case 2:
                    message.label = 2;
                    break;
                case "LABEL_REPEATED":
                case 3:
                    message.label = 3;
                    break;
                }
                switch (object.type) {
                case "TYPE_DOUBLE":
                case 1:
                    message.type = 1;
                    break;
                case "TYPE_FLOAT":
                case 2:
                    message.type = 2;
                    break;
                case "TYPE_INT64":
                case 3:
                    message.type = 3;
                    break;
                case "TYPE_UINT64":
                case 4:
                    message.type = 4;
                    break;
                case "TYPE_INT32":
                case 5:
                    message.type = 5;
                    break;
                case "TYPE_FIXED64":
                case 6:
                    message.type = 6;
                    break;
                case "TYPE_FIXED32":
                case 7:
                    message.type = 7;
                    break;
                case "TYPE_BOOL":
                case 8:
                    message.type = 8;
                    break;
                case "TYPE_STRING":
                case 9:
                    message.type = 9;
                    break;
                case "TYPE_GROUP":
                case 10:
                    message.type = 10;
                    break;
                case "TYPE_MESSAGE":
                case 11:
                    message.type = 11;
                    break;
                case "TYPE_BYTES":
                case 12:
                    message.type = 12;
                    break;
                case "TYPE_UINT32":
                case 13:
                    message.type = 13;
                    break;
                case "TYPE_ENUM":
                case 14:
                    message.type = 14;
                    break;
                case "TYPE_SFIXED32":
                case 15:
                    message.type = 15;
                    break;
                case "TYPE_SFIXED64":
                case 16:
                    message.type = 16;
                    break;
                case "TYPE_SINT32":
                case 17:
                    message.type = 17;
                    break;
                case "TYPE_SINT64":
                case 18:
                    message.type = 18;
                    break;
                }
                if (object.typeName != null)
                    message.typeName = String(object.typeName);
                if (object.extendee != null)
                    message.extendee = String(object.extendee);
                if (object.defaultValue != null)
                    message.defaultValue = String(object.defaultValue);
                if (object.oneofIndex != null)
                    message.oneofIndex = object.oneofIndex | 0;
                if (object.jsonName != null)
                    message.jsonName = String(object.jsonName);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.FieldDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.FieldOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from a FieldDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.FieldDescriptorProto} message FieldDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FieldDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.extendee = "";
                    object.number = 0;
                    object.label = options.enums === String ? "LABEL_OPTIONAL" : 1;
                    object.type = options.enums === String ? "TYPE_DOUBLE" : 1;
                    object.typeName = "";
                    object.defaultValue = "";
                    object.options = null;
                    object.oneofIndex = 0;
                    object.jsonName = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.extendee != null && message.hasOwnProperty("extendee"))
                    object.extendee = message.extendee;
                if (message.number != null && message.hasOwnProperty("number"))
                    object.number = message.number;
                if (message.label != null && message.hasOwnProperty("label"))
                    object.label = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Label[message.label] : message.label;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Type[message.type] : message.type;
                if (message.typeName != null && message.hasOwnProperty("typeName"))
                    object.typeName = message.typeName;
                if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                    object.defaultValue = message.defaultValue;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.FieldOptions.toObject(message.options, options);
                if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                    object.oneofIndex = message.oneofIndex;
                if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                    object.jsonName = message.jsonName;
                return object;
            };

            /**
             * Converts this FieldDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FieldDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Type enum.
             * @name google.protobuf.FieldDescriptorProto.Type
             * @enum {number}
             * @property {number} TYPE_DOUBLE=1 TYPE_DOUBLE value
             * @property {number} TYPE_FLOAT=2 TYPE_FLOAT value
             * @property {number} TYPE_INT64=3 TYPE_INT64 value
             * @property {number} TYPE_UINT64=4 TYPE_UINT64 value
             * @property {number} TYPE_INT32=5 TYPE_INT32 value
             * @property {number} TYPE_FIXED64=6 TYPE_FIXED64 value
             * @property {number} TYPE_FIXED32=7 TYPE_FIXED32 value
             * @property {number} TYPE_BOOL=8 TYPE_BOOL value
             * @property {number} TYPE_STRING=9 TYPE_STRING value
             * @property {number} TYPE_GROUP=10 TYPE_GROUP value
             * @property {number} TYPE_MESSAGE=11 TYPE_MESSAGE value
             * @property {number} TYPE_BYTES=12 TYPE_BYTES value
             * @property {number} TYPE_UINT32=13 TYPE_UINT32 value
             * @property {number} TYPE_ENUM=14 TYPE_ENUM value
             * @property {number} TYPE_SFIXED32=15 TYPE_SFIXED32 value
             * @property {number} TYPE_SFIXED64=16 TYPE_SFIXED64 value
             * @property {number} TYPE_SINT32=17 TYPE_SINT32 value
             * @property {number} TYPE_SINT64=18 TYPE_SINT64 value
             */
            FieldDescriptorProto.Type = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "TYPE_DOUBLE"] = 1;
                values[valuesById[2] = "TYPE_FLOAT"] = 2;
                values[valuesById[3] = "TYPE_INT64"] = 3;
                values[valuesById[4] = "TYPE_UINT64"] = 4;
                values[valuesById[5] = "TYPE_INT32"] = 5;
                values[valuesById[6] = "TYPE_FIXED64"] = 6;
                values[valuesById[7] = "TYPE_FIXED32"] = 7;
                values[valuesById[8] = "TYPE_BOOL"] = 8;
                values[valuesById[9] = "TYPE_STRING"] = 9;
                values[valuesById[10] = "TYPE_GROUP"] = 10;
                values[valuesById[11] = "TYPE_MESSAGE"] = 11;
                values[valuesById[12] = "TYPE_BYTES"] = 12;
                values[valuesById[13] = "TYPE_UINT32"] = 13;
                values[valuesById[14] = "TYPE_ENUM"] = 14;
                values[valuesById[15] = "TYPE_SFIXED32"] = 15;
                values[valuesById[16] = "TYPE_SFIXED64"] = 16;
                values[valuesById[17] = "TYPE_SINT32"] = 17;
                values[valuesById[18] = "TYPE_SINT64"] = 18;
                return values;
            })();

            /**
             * Label enum.
             * @name google.protobuf.FieldDescriptorProto.Label
             * @enum {number}
             * @property {number} LABEL_OPTIONAL=1 LABEL_OPTIONAL value
             * @property {number} LABEL_REQUIRED=2 LABEL_REQUIRED value
             * @property {number} LABEL_REPEATED=3 LABEL_REPEATED value
             */
            FieldDescriptorProto.Label = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "LABEL_OPTIONAL"] = 1;
                values[valuesById[2] = "LABEL_REQUIRED"] = 2;
                values[valuesById[3] = "LABEL_REPEATED"] = 3;
                return values;
            })();

            return FieldDescriptorProto;
        })();

        protobuf.OneofDescriptorProto = (function() {

            /**
             * Properties of an OneofDescriptorProto.
             * @memberof google.protobuf
             * @interface IOneofDescriptorProto
             * @property {string|null} [name] OneofDescriptorProto name
             * @property {google.protobuf.IOneofOptions|null} [options] OneofDescriptorProto options
             */

            /**
             * Constructs a new OneofDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an OneofDescriptorProto.
             * @implements IOneofDescriptorProto
             * @constructor
             * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
             */
            function OneofDescriptorProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OneofDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.name = "";

            /**
             * OneofDescriptorProto options.
             * @member {google.protobuf.IOneofOptions|null|undefined} options
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.options = null;

            /**
             * Creates a new OneofDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto instance
             */
            OneofDescriptorProto.create = function create(properties) {
                return new OneofDescriptorProto(properties);
            };

            /**
             * Encodes the specified OneofDescriptorProto message. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto} message OneofDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.OneofOptions.encode(message.options, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an OneofDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.OneofDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.options = $root.google.protobuf.OneofOptions.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OneofDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OneofDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.OneofOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an OneofDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             */
            OneofDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.OneofDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.OneofDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.OneofDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.OneofOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an OneofDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.OneofDescriptorProto} message OneofDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OneofDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.OneofOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this OneofDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OneofDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OneofDescriptorProto;
        })();

        protobuf.EnumDescriptorProto = (function() {

            /**
             * Properties of an EnumDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumDescriptorProto
             * @property {string|null} [name] EnumDescriptorProto name
             * @property {Array.<google.protobuf.IEnumValueDescriptorProto>|null} [value] EnumDescriptorProto value
             * @property {google.protobuf.IEnumOptions|null} [options] EnumDescriptorProto options
             */

            /**
             * Constructs a new EnumDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumDescriptorProto.
             * @implements IEnumDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
             */
            function EnumDescriptorProto(properties) {
                this.value = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.name = "";

            /**
             * EnumDescriptorProto value.
             * @member {Array.<google.protobuf.IEnumValueDescriptorProto>} value
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.value = $util.emptyArray;

            /**
             * EnumDescriptorProto options.
             * @member {google.protobuf.IEnumOptions|null|undefined} options
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.options = null;

            /**
             * Creates a new EnumDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto instance
             */
            EnumDescriptorProto.create = function create(properties) {
                return new EnumDescriptorProto(properties);
            };

            /**
             * Encodes the specified EnumDescriptorProto message. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto} message EnumDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.value != null && message.value.length)
                    for (var i = 0; i < message.value.length; ++i)
                        $root.google.protobuf.EnumValueDescriptorProto.encode(message.value[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.EnumOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an EnumDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        if (!(message.value && message.value.length))
                            message.value = [];
                        message.value.push($root.google.protobuf.EnumValueDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        message.options = $root.google.protobuf.EnumOptions.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an EnumDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.value != null && message.hasOwnProperty("value")) {
                    if (!Array.isArray(message.value))
                        return "value: array expected";
                    for (var i = 0; i < message.value.length; ++i) {
                        var error = $root.google.protobuf.EnumValueDescriptorProto.verify(message.value[i]);
                        if (error)
                            return "value." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.EnumOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an EnumDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             */
            EnumDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.EnumDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.value) {
                    if (!Array.isArray(object.value))
                        throw TypeError(".google.protobuf.EnumDescriptorProto.value: array expected");
                    message.value = [];
                    for (var i = 0; i < object.value.length; ++i) {
                        if (typeof object.value[i] !== "object")
                            throw TypeError(".google.protobuf.EnumDescriptorProto.value: object expected");
                        message.value[i] = $root.google.protobuf.EnumValueDescriptorProto.fromObject(object.value[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.EnumDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.EnumOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.EnumDescriptorProto} message EnumDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.value = [];
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.value && message.value.length) {
                    object.value = [];
                    for (var j = 0; j < message.value.length; ++j)
                        object.value[j] = $root.google.protobuf.EnumValueDescriptorProto.toObject(message.value[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.EnumOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this EnumDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumDescriptorProto;
        })();

        protobuf.EnumValueDescriptorProto = (function() {

            /**
             * Properties of an EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumValueDescriptorProto
             * @property {string|null} [name] EnumValueDescriptorProto name
             * @property {number|null} [number] EnumValueDescriptorProto number
             * @property {google.protobuf.IEnumValueOptions|null} [options] EnumValueDescriptorProto options
             */

            /**
             * Constructs a new EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueDescriptorProto.
             * @implements IEnumValueDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
             */
            function EnumValueDescriptorProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumValueDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.name = "";

            /**
             * EnumValueDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.number = 0;

            /**
             * EnumValueDescriptorProto options.
             * @member {google.protobuf.IEnumValueOptions|null|undefined} options
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.options = null;

            /**
             * Creates a new EnumValueDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto instance
             */
            EnumValueDescriptorProto.create = function create(properties) {
                return new EnumValueDescriptorProto(properties);
            };

            /**
             * Encodes the specified EnumValueDescriptorProto message. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto} message EnumValueDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.number);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.EnumValueOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an EnumValueDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumValueDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.number = reader.int32();
                        break;
                    case 3:
                        message.options = $root.google.protobuf.EnumValueOptions.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an EnumValueDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumValueDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.number != null && message.hasOwnProperty("number"))
                    if (!$util.isInteger(message.number))
                        return "number: integer expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.EnumValueOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an EnumValueDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             */
            EnumValueDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumValueDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.EnumValueDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.number != null)
                    message.number = object.number | 0;
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.EnumValueDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.EnumValueOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumValueDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.EnumValueDescriptorProto} message EnumValueDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumValueDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.number = 0;
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.number != null && message.hasOwnProperty("number"))
                    object.number = message.number;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.EnumValueOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this EnumValueDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumValueDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumValueDescriptorProto;
        })();

        protobuf.ServiceDescriptorProto = (function() {

            /**
             * Properties of a ServiceDescriptorProto.
             * @memberof google.protobuf
             * @interface IServiceDescriptorProto
             * @property {string|null} [name] ServiceDescriptorProto name
             * @property {Array.<google.protobuf.IMethodDescriptorProto>|null} [method] ServiceDescriptorProto method
             * @property {google.protobuf.IServiceOptions|null} [options] ServiceDescriptorProto options
             */

            /**
             * Constructs a new ServiceDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceDescriptorProto.
             * @implements IServiceDescriptorProto
             * @constructor
             * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
             */
            function ServiceDescriptorProto(properties) {
                this.method = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.name = "";

            /**
             * ServiceDescriptorProto method.
             * @member {Array.<google.protobuf.IMethodDescriptorProto>} method
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.method = $util.emptyArray;

            /**
             * ServiceDescriptorProto options.
             * @member {google.protobuf.IServiceOptions|null|undefined} options
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.options = null;

            /**
             * Creates a new ServiceDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto instance
             */
            ServiceDescriptorProto.create = function create(properties) {
                return new ServiceDescriptorProto(properties);
            };

            /**
             * Encodes the specified ServiceDescriptorProto message. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto} message ServiceDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.method != null && message.method.length)
                    for (var i = 0; i < message.method.length; ++i)
                        $root.google.protobuf.MethodDescriptorProto.encode(message.method[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.ServiceOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ServiceDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ServiceDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        if (!(message.method && message.method.length))
                            message.method = [];
                        message.method.push($root.google.protobuf.MethodDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        message.options = $root.google.protobuf.ServiceOptions.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ServiceDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.method != null && message.hasOwnProperty("method")) {
                    if (!Array.isArray(message.method))
                        return "method: array expected";
                    for (var i = 0; i < message.method.length; ++i) {
                        var error = $root.google.protobuf.MethodDescriptorProto.verify(message.method[i]);
                        if (error)
                            return "method." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.ServiceOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates a ServiceDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             */
            ServiceDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.ServiceDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.ServiceDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.method) {
                    if (!Array.isArray(object.method))
                        throw TypeError(".google.protobuf.ServiceDescriptorProto.method: array expected");
                    message.method = [];
                    for (var i = 0; i < object.method.length; ++i) {
                        if (typeof object.method[i] !== "object")
                            throw TypeError(".google.protobuf.ServiceDescriptorProto.method: object expected");
                        message.method[i] = $root.google.protobuf.MethodDescriptorProto.fromObject(object.method[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.ServiceDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.ServiceOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from a ServiceDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.ServiceDescriptorProto} message ServiceDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.method = [];
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.method && message.method.length) {
                    object.method = [];
                    for (var j = 0; j < message.method.length; ++j)
                        object.method[j] = $root.google.protobuf.MethodDescriptorProto.toObject(message.method[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.ServiceOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this ServiceDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServiceDescriptorProto;
        })();

        protobuf.MethodDescriptorProto = (function() {

            /**
             * Properties of a MethodDescriptorProto.
             * @memberof google.protobuf
             * @interface IMethodDescriptorProto
             * @property {string|null} [name] MethodDescriptorProto name
             * @property {string|null} [inputType] MethodDescriptorProto inputType
             * @property {string|null} [outputType] MethodDescriptorProto outputType
             * @property {google.protobuf.IMethodOptions|null} [options] MethodDescriptorProto options
             * @property {boolean|null} [clientStreaming] MethodDescriptorProto clientStreaming
             * @property {boolean|null} [serverStreaming] MethodDescriptorProto serverStreaming
             */

            /**
             * Constructs a new MethodDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a MethodDescriptorProto.
             * @implements IMethodDescriptorProto
             * @constructor
             * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
             */
            function MethodDescriptorProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MethodDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.name = "";

            /**
             * MethodDescriptorProto inputType.
             * @member {string} inputType
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.inputType = "";

            /**
             * MethodDescriptorProto outputType.
             * @member {string} outputType
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.outputType = "";

            /**
             * MethodDescriptorProto options.
             * @member {google.protobuf.IMethodOptions|null|undefined} options
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.options = null;

            /**
             * MethodDescriptorProto clientStreaming.
             * @member {boolean} clientStreaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.clientStreaming = false;

            /**
             * MethodDescriptorProto serverStreaming.
             * @member {boolean} serverStreaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.serverStreaming = false;

            /**
             * Creates a new MethodDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto instance
             */
            MethodDescriptorProto.create = function create(properties) {
                return new MethodDescriptorProto(properties);
            };

            /**
             * Encodes the specified MethodDescriptorProto message. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto} message MethodDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.inputType != null && Object.hasOwnProperty.call(message, "inputType"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.inputType);
                if (message.outputType != null && Object.hasOwnProperty.call(message, "outputType"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.outputType);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.MethodOptions.encode(message.options, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.clientStreaming != null && Object.hasOwnProperty.call(message, "clientStreaming"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.clientStreaming);
                if (message.serverStreaming != null && Object.hasOwnProperty.call(message, "serverStreaming"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.serverStreaming);
                return writer;
            };

            /**
             * Decodes a MethodDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MethodDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.inputType = reader.string();
                        break;
                    case 3:
                        message.outputType = reader.string();
                        break;
                    case 4:
                        message.options = $root.google.protobuf.MethodOptions.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.clientStreaming = reader.bool();
                        break;
                    case 6:
                        message.serverStreaming = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a MethodDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MethodDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.inputType != null && message.hasOwnProperty("inputType"))
                    if (!$util.isString(message.inputType))
                        return "inputType: string expected";
                if (message.outputType != null && message.hasOwnProperty("outputType"))
                    if (!$util.isString(message.outputType))
                        return "outputType: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.MethodOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                    if (typeof message.clientStreaming !== "boolean")
                        return "clientStreaming: boolean expected";
                if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                    if (typeof message.serverStreaming !== "boolean")
                        return "serverStreaming: boolean expected";
                return null;
            };

            /**
             * Creates a MethodDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             */
            MethodDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MethodDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.MethodDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.inputType != null)
                    message.inputType = String(object.inputType);
                if (object.outputType != null)
                    message.outputType = String(object.outputType);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.MethodDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.MethodOptions.fromObject(object.options);
                }
                if (object.clientStreaming != null)
                    message.clientStreaming = Boolean(object.clientStreaming);
                if (object.serverStreaming != null)
                    message.serverStreaming = Boolean(object.serverStreaming);
                return message;
            };

            /**
             * Creates a plain object from a MethodDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.MethodDescriptorProto} message MethodDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MethodDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.inputType = "";
                    object.outputType = "";
                    object.options = null;
                    object.clientStreaming = false;
                    object.serverStreaming = false;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.inputType != null && message.hasOwnProperty("inputType"))
                    object.inputType = message.inputType;
                if (message.outputType != null && message.hasOwnProperty("outputType"))
                    object.outputType = message.outputType;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.MethodOptions.toObject(message.options, options);
                if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                    object.clientStreaming = message.clientStreaming;
                if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                    object.serverStreaming = message.serverStreaming;
                return object;
            };

            /**
             * Converts this MethodDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MethodDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MethodDescriptorProto;
        })();

        protobuf.FileOptions = (function() {

            /**
             * Properties of a FileOptions.
             * @memberof google.protobuf
             * @interface IFileOptions
             * @property {string|null} [javaPackage] FileOptions javaPackage
             * @property {string|null} [javaOuterClassname] FileOptions javaOuterClassname
             * @property {boolean|null} [javaMultipleFiles] FileOptions javaMultipleFiles
             * @property {boolean|null} [javaGenerateEqualsAndHash] FileOptions javaGenerateEqualsAndHash
             * @property {boolean|null} [javaStringCheckUtf8] FileOptions javaStringCheckUtf8
             * @property {google.protobuf.FileOptions.OptimizeMode|null} [optimizeFor] FileOptions optimizeFor
             * @property {string|null} [goPackage] FileOptions goPackage
             * @property {boolean|null} [ccGenericServices] FileOptions ccGenericServices
             * @property {boolean|null} [javaGenericServices] FileOptions javaGenericServices
             * @property {boolean|null} [pyGenericServices] FileOptions pyGenericServices
             * @property {boolean|null} [deprecated] FileOptions deprecated
             * @property {boolean|null} [ccEnableArenas] FileOptions ccEnableArenas
             * @property {string|null} [objcClassPrefix] FileOptions objcClassPrefix
             * @property {string|null} [csharpNamespace] FileOptions csharpNamespace
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FileOptions uninterpretedOption
             */

            /**
             * Constructs a new FileOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FileOptions.
             * @implements IFileOptions
             * @constructor
             * @param {google.protobuf.IFileOptions=} [properties] Properties to set
             */
            function FileOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileOptions javaPackage.
             * @member {string} javaPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaPackage = "";

            /**
             * FileOptions javaOuterClassname.
             * @member {string} javaOuterClassname
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaOuterClassname = "";

            /**
             * FileOptions javaMultipleFiles.
             * @member {boolean} javaMultipleFiles
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaMultipleFiles = false;

            /**
             * FileOptions javaGenerateEqualsAndHash.
             * @member {boolean} javaGenerateEqualsAndHash
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaGenerateEqualsAndHash = false;

            /**
             * FileOptions javaStringCheckUtf8.
             * @member {boolean} javaStringCheckUtf8
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaStringCheckUtf8 = false;

            /**
             * FileOptions optimizeFor.
             * @member {google.protobuf.FileOptions.OptimizeMode} optimizeFor
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.optimizeFor = 1;

            /**
             * FileOptions goPackage.
             * @member {string} goPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.goPackage = "";

            /**
             * FileOptions ccGenericServices.
             * @member {boolean} ccGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.ccGenericServices = false;

            /**
             * FileOptions javaGenericServices.
             * @member {boolean} javaGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaGenericServices = false;

            /**
             * FileOptions pyGenericServices.
             * @member {boolean} pyGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.pyGenericServices = false;

            /**
             * FileOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.deprecated = false;

            /**
             * FileOptions ccEnableArenas.
             * @member {boolean} ccEnableArenas
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.ccEnableArenas = false;

            /**
             * FileOptions objcClassPrefix.
             * @member {string} objcClassPrefix
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.objcClassPrefix = "";

            /**
             * FileOptions csharpNamespace.
             * @member {string} csharpNamespace
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.csharpNamespace = "";

            /**
             * FileOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new FileOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions=} [properties] Properties to set
             * @returns {google.protobuf.FileOptions} FileOptions instance
             */
            FileOptions.create = function create(properties) {
                return new FileOptions(properties);
            };

            /**
             * Encodes the specified FileOptions message. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions} message FileOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.javaPackage != null && Object.hasOwnProperty.call(message, "javaPackage"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.javaPackage);
                if (message.javaOuterClassname != null && Object.hasOwnProperty.call(message, "javaOuterClassname"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.javaOuterClassname);
                if (message.optimizeFor != null && Object.hasOwnProperty.call(message, "optimizeFor"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.optimizeFor);
                if (message.javaMultipleFiles != null && Object.hasOwnProperty.call(message, "javaMultipleFiles"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.javaMultipleFiles);
                if (message.goPackage != null && Object.hasOwnProperty.call(message, "goPackage"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.goPackage);
                if (message.ccGenericServices != null && Object.hasOwnProperty.call(message, "ccGenericServices"))
                    writer.uint32(/* id 16, wireType 0 =*/128).bool(message.ccGenericServices);
                if (message.javaGenericServices != null && Object.hasOwnProperty.call(message, "javaGenericServices"))
                    writer.uint32(/* id 17, wireType 0 =*/136).bool(message.javaGenericServices);
                if (message.pyGenericServices != null && Object.hasOwnProperty.call(message, "pyGenericServices"))
                    writer.uint32(/* id 18, wireType 0 =*/144).bool(message.pyGenericServices);
                if (message.javaGenerateEqualsAndHash != null && Object.hasOwnProperty.call(message, "javaGenerateEqualsAndHash"))
                    writer.uint32(/* id 20, wireType 0 =*/160).bool(message.javaGenerateEqualsAndHash);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 23, wireType 0 =*/184).bool(message.deprecated);
                if (message.javaStringCheckUtf8 != null && Object.hasOwnProperty.call(message, "javaStringCheckUtf8"))
                    writer.uint32(/* id 27, wireType 0 =*/216).bool(message.javaStringCheckUtf8);
                if (message.ccEnableArenas != null && Object.hasOwnProperty.call(message, "ccEnableArenas"))
                    writer.uint32(/* id 31, wireType 0 =*/248).bool(message.ccEnableArenas);
                if (message.objcClassPrefix != null && Object.hasOwnProperty.call(message, "objcClassPrefix"))
                    writer.uint32(/* id 36, wireType 2 =*/290).string(message.objcClassPrefix);
                if (message.csharpNamespace != null && Object.hasOwnProperty.call(message, "csharpNamespace"))
                    writer.uint32(/* id 37, wireType 2 =*/298).string(message.csharpNamespace);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a FileOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FileOptions} FileOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.javaPackage = reader.string();
                        break;
                    case 8:
                        message.javaOuterClassname = reader.string();
                        break;
                    case 10:
                        message.javaMultipleFiles = reader.bool();
                        break;
                    case 20:
                        message.javaGenerateEqualsAndHash = reader.bool();
                        break;
                    case 27:
                        message.javaStringCheckUtf8 = reader.bool();
                        break;
                    case 9:
                        message.optimizeFor = reader.int32();
                        break;
                    case 11:
                        message.goPackage = reader.string();
                        break;
                    case 16:
                        message.ccGenericServices = reader.bool();
                        break;
                    case 17:
                        message.javaGenericServices = reader.bool();
                        break;
                    case 18:
                        message.pyGenericServices = reader.bool();
                        break;
                    case 23:
                        message.deprecated = reader.bool();
                        break;
                    case 31:
                        message.ccEnableArenas = reader.bool();
                        break;
                    case 36:
                        message.objcClassPrefix = reader.string();
                        break;
                    case 37:
                        message.csharpNamespace = reader.string();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a FileOptions message.
             * @function verify
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                    if (!$util.isString(message.javaPackage))
                        return "javaPackage: string expected";
                if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                    if (!$util.isString(message.javaOuterClassname))
                        return "javaOuterClassname: string expected";
                if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                    if (typeof message.javaMultipleFiles !== "boolean")
                        return "javaMultipleFiles: boolean expected";
                if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                    if (typeof message.javaGenerateEqualsAndHash !== "boolean")
                        return "javaGenerateEqualsAndHash: boolean expected";
                if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                    if (typeof message.javaStringCheckUtf8 !== "boolean")
                        return "javaStringCheckUtf8: boolean expected";
                if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                    switch (message.optimizeFor) {
                    default:
                        return "optimizeFor: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                    if (!$util.isString(message.goPackage))
                        return "goPackage: string expected";
                if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                    if (typeof message.ccGenericServices !== "boolean")
                        return "ccGenericServices: boolean expected";
                if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                    if (typeof message.javaGenericServices !== "boolean")
                        return "javaGenericServices: boolean expected";
                if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                    if (typeof message.pyGenericServices !== "boolean")
                        return "pyGenericServices: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                    if (typeof message.ccEnableArenas !== "boolean")
                        return "ccEnableArenas: boolean expected";
                if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                    if (!$util.isString(message.objcClassPrefix))
                        return "objcClassPrefix: string expected";
                if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                    if (!$util.isString(message.csharpNamespace))
                        return "csharpNamespace: string expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a FileOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileOptions} FileOptions
             */
            FileOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileOptions)
                    return object;
                var message = new $root.google.protobuf.FileOptions();
                if (object.javaPackage != null)
                    message.javaPackage = String(object.javaPackage);
                if (object.javaOuterClassname != null)
                    message.javaOuterClassname = String(object.javaOuterClassname);
                if (object.javaMultipleFiles != null)
                    message.javaMultipleFiles = Boolean(object.javaMultipleFiles);
                if (object.javaGenerateEqualsAndHash != null)
                    message.javaGenerateEqualsAndHash = Boolean(object.javaGenerateEqualsAndHash);
                if (object.javaStringCheckUtf8 != null)
                    message.javaStringCheckUtf8 = Boolean(object.javaStringCheckUtf8);
                switch (object.optimizeFor) {
                case "SPEED":
                case 1:
                    message.optimizeFor = 1;
                    break;
                case "CODE_SIZE":
                case 2:
                    message.optimizeFor = 2;
                    break;
                case "LITE_RUNTIME":
                case 3:
                    message.optimizeFor = 3;
                    break;
                }
                if (object.goPackage != null)
                    message.goPackage = String(object.goPackage);
                if (object.ccGenericServices != null)
                    message.ccGenericServices = Boolean(object.ccGenericServices);
                if (object.javaGenericServices != null)
                    message.javaGenericServices = Boolean(object.javaGenericServices);
                if (object.pyGenericServices != null)
                    message.pyGenericServices = Boolean(object.pyGenericServices);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.ccEnableArenas != null)
                    message.ccEnableArenas = Boolean(object.ccEnableArenas);
                if (object.objcClassPrefix != null)
                    message.objcClassPrefix = String(object.objcClassPrefix);
                if (object.csharpNamespace != null)
                    message.csharpNamespace = String(object.csharpNamespace);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a FileOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.FileOptions} message FileOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.javaPackage = "";
                    object.javaOuterClassname = "";
                    object.optimizeFor = options.enums === String ? "SPEED" : 1;
                    object.javaMultipleFiles = false;
                    object.goPackage = "";
                    object.ccGenericServices = false;
                    object.javaGenericServices = false;
                    object.pyGenericServices = false;
                    object.javaGenerateEqualsAndHash = false;
                    object.deprecated = false;
                    object.javaStringCheckUtf8 = false;
                    object.ccEnableArenas = false;
                    object.objcClassPrefix = "";
                    object.csharpNamespace = "";
                }
                if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                    object.javaPackage = message.javaPackage;
                if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                    object.javaOuterClassname = message.javaOuterClassname;
                if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                    object.optimizeFor = options.enums === String ? $root.google.protobuf.FileOptions.OptimizeMode[message.optimizeFor] : message.optimizeFor;
                if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                    object.javaMultipleFiles = message.javaMultipleFiles;
                if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                    object.goPackage = message.goPackage;
                if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                    object.ccGenericServices = message.ccGenericServices;
                if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                    object.javaGenericServices = message.javaGenericServices;
                if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                    object.pyGenericServices = message.pyGenericServices;
                if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                    object.javaGenerateEqualsAndHash = message.javaGenerateEqualsAndHash;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                    object.javaStringCheckUtf8 = message.javaStringCheckUtf8;
                if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                    object.ccEnableArenas = message.ccEnableArenas;
                if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                    object.objcClassPrefix = message.objcClassPrefix;
                if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                    object.csharpNamespace = message.csharpNamespace;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this FileOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * OptimizeMode enum.
             * @name google.protobuf.FileOptions.OptimizeMode
             * @enum {number}
             * @property {number} SPEED=1 SPEED value
             * @property {number} CODE_SIZE=2 CODE_SIZE value
             * @property {number} LITE_RUNTIME=3 LITE_RUNTIME value
             */
            FileOptions.OptimizeMode = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "SPEED"] = 1;
                values[valuesById[2] = "CODE_SIZE"] = 2;
                values[valuesById[3] = "LITE_RUNTIME"] = 3;
                return values;
            })();

            return FileOptions;
        })();

        protobuf.MessageOptions = (function() {

            /**
             * Properties of a MessageOptions.
             * @memberof google.protobuf
             * @interface IMessageOptions
             * @property {boolean|null} [messageSetWireFormat] MessageOptions messageSetWireFormat
             * @property {boolean|null} [noStandardDescriptorAccessor] MessageOptions noStandardDescriptorAccessor
             * @property {boolean|null} [deprecated] MessageOptions deprecated
             * @property {boolean|null} [mapEntry] MessageOptions mapEntry
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MessageOptions uninterpretedOption
             */

            /**
             * Constructs a new MessageOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MessageOptions.
             * @implements IMessageOptions
             * @constructor
             * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
             */
            function MessageOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageOptions messageSetWireFormat.
             * @member {boolean} messageSetWireFormat
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.messageSetWireFormat = false;

            /**
             * MessageOptions noStandardDescriptorAccessor.
             * @member {boolean} noStandardDescriptorAccessor
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.noStandardDescriptorAccessor = false;

            /**
             * MessageOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.deprecated = false;

            /**
             * MessageOptions mapEntry.
             * @member {boolean} mapEntry
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.mapEntry = false;

            /**
             * MessageOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new MessageOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
             * @returns {google.protobuf.MessageOptions} MessageOptions instance
             */
            MessageOptions.create = function create(properties) {
                return new MessageOptions(properties);
            };

            /**
             * Encodes the specified MessageOptions message. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions} message MessageOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.messageSetWireFormat != null && Object.hasOwnProperty.call(message, "messageSetWireFormat"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.messageSetWireFormat);
                if (message.noStandardDescriptorAccessor != null && Object.hasOwnProperty.call(message, "noStandardDescriptorAccessor"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.noStandardDescriptorAccessor);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                if (message.mapEntry != null && Object.hasOwnProperty.call(message, "mapEntry"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.mapEntry);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a MessageOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.MessageOptions} MessageOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MessageOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.messageSetWireFormat = reader.bool();
                        break;
                    case 2:
                        message.noStandardDescriptorAccessor = reader.bool();
                        break;
                    case 3:
                        message.deprecated = reader.bool();
                        break;
                    case 7:
                        message.mapEntry = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a MessageOptions message.
             * @function verify
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                    if (typeof message.messageSetWireFormat !== "boolean")
                        return "messageSetWireFormat: boolean expected";
                if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                    if (typeof message.noStandardDescriptorAccessor !== "boolean")
                        return "noStandardDescriptorAccessor: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                    if (typeof message.mapEntry !== "boolean")
                        return "mapEntry: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a MessageOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MessageOptions} MessageOptions
             */
            MessageOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MessageOptions)
                    return object;
                var message = new $root.google.protobuf.MessageOptions();
                if (object.messageSetWireFormat != null)
                    message.messageSetWireFormat = Boolean(object.messageSetWireFormat);
                if (object.noStandardDescriptorAccessor != null)
                    message.noStandardDescriptorAccessor = Boolean(object.noStandardDescriptorAccessor);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.mapEntry != null)
                    message.mapEntry = Boolean(object.mapEntry);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a MessageOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.MessageOptions} message MessageOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.messageSetWireFormat = false;
                    object.noStandardDescriptorAccessor = false;
                    object.deprecated = false;
                    object.mapEntry = false;
                }
                if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                    object.messageSetWireFormat = message.messageSetWireFormat;
                if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                    object.noStandardDescriptorAccessor = message.noStandardDescriptorAccessor;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                    object.mapEntry = message.mapEntry;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this MessageOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.MessageOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MessageOptions;
        })();

        protobuf.FieldOptions = (function() {

            /**
             * Properties of a FieldOptions.
             * @memberof google.protobuf
             * @interface IFieldOptions
             * @property {google.protobuf.FieldOptions.CType|null} [ctype] FieldOptions ctype
             * @property {boolean|null} [packed] FieldOptions packed
             * @property {google.protobuf.FieldOptions.JSType|null} [jstype] FieldOptions jstype
             * @property {boolean|null} [lazy] FieldOptions lazy
             * @property {boolean|null} [deprecated] FieldOptions deprecated
             * @property {boolean|null} [weak] FieldOptions weak
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FieldOptions uninterpretedOption
             * @property {string|null} [".fastcity.admin.gmrpcResponse"] FieldOptions .fastcity.admin.gmrpcResponse
             * @property {boolean|null} [".fastcity.admin.gmrpcIsBroadcast"] FieldOptions .fastcity.admin.gmrpcIsBroadcast
             */

            /**
             * Constructs a new FieldOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FieldOptions.
             * @implements IFieldOptions
             * @constructor
             * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
             */
            function FieldOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FieldOptions ctype.
             * @member {google.protobuf.FieldOptions.CType} ctype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.ctype = 0;

            /**
             * FieldOptions packed.
             * @member {boolean} packed
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.packed = false;

            /**
             * FieldOptions jstype.
             * @member {google.protobuf.FieldOptions.JSType} jstype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.jstype = 0;

            /**
             * FieldOptions lazy.
             * @member {boolean} lazy
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.lazy = false;

            /**
             * FieldOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.deprecated = false;

            /**
             * FieldOptions weak.
             * @member {boolean} weak
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.weak = false;

            /**
             * FieldOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * FieldOptions .fastcity.admin.gmrpcResponse.
             * @member {string} .fastcity.admin.gmrpcResponse
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".fastcity.admin.gmrpcResponse"] = "";

            /**
             * FieldOptions .fastcity.admin.gmrpcIsBroadcast.
             * @member {boolean} .fastcity.admin.gmrpcIsBroadcast
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".fastcity.admin.gmrpcIsBroadcast"] = false;

            /**
             * Creates a new FieldOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
             * @returns {google.protobuf.FieldOptions} FieldOptions instance
             */
            FieldOptions.create = function create(properties) {
                return new FieldOptions(properties);
            };

            /**
             * Encodes the specified FieldOptions message. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions} message FieldOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.ctype != null && Object.hasOwnProperty.call(message, "ctype"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ctype);
                if (message.packed != null && Object.hasOwnProperty.call(message, "packed"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.packed);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                if (message.lazy != null && Object.hasOwnProperty.call(message, "lazy"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.lazy);
                if (message.jstype != null && Object.hasOwnProperty.call(message, "jstype"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.jstype);
                if (message.weak != null && Object.hasOwnProperty.call(message, "weak"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.weak);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                if (message[".fastcity.admin.gmrpcResponse"] != null && Object.hasOwnProperty.call(message, ".fastcity.admin.gmrpcResponse"))
                    writer.uint32(/* id 10201, wireType 2 =*/81610).string(message[".fastcity.admin.gmrpcResponse"]);
                if (message[".fastcity.admin.gmrpcIsBroadcast"] != null && Object.hasOwnProperty.call(message, ".fastcity.admin.gmrpcIsBroadcast"))
                    writer.uint32(/* id 10202, wireType 0 =*/81616).bool(message[".fastcity.admin.gmrpcIsBroadcast"]);
                return writer;
            };

            /**
             * Decodes a FieldOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FieldOptions} FieldOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.ctype = reader.int32();
                        break;
                    case 2:
                        message.packed = reader.bool();
                        break;
                    case 6:
                        message.jstype = reader.int32();
                        break;
                    case 5:
                        message.lazy = reader.bool();
                        break;
                    case 3:
                        message.deprecated = reader.bool();
                        break;
                    case 10:
                        message.weak = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    case 10201:
                        message[".fastcity.admin.gmrpcResponse"] = reader.string();
                        break;
                    case 10202:
                        message[".fastcity.admin.gmrpcIsBroadcast"] = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a FieldOptions message.
             * @function verify
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FieldOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.ctype != null && message.hasOwnProperty("ctype"))
                    switch (message.ctype) {
                    default:
                        return "ctype: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.packed != null && message.hasOwnProperty("packed"))
                    if (typeof message.packed !== "boolean")
                        return "packed: boolean expected";
                if (message.jstype != null && message.hasOwnProperty("jstype"))
                    switch (message.jstype) {
                    default:
                        return "jstype: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.lazy != null && message.hasOwnProperty("lazy"))
                    if (typeof message.lazy !== "boolean")
                        return "lazy: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.weak != null && message.hasOwnProperty("weak"))
                    if (typeof message.weak !== "boolean")
                        return "weak: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".fastcity.admin.gmrpcResponse"] != null && message.hasOwnProperty(".fastcity.admin.gmrpcResponse"))
                    if (!$util.isString(message[".fastcity.admin.gmrpcResponse"]))
                        return ".fastcity.admin.gmrpcResponse: string expected";
                if (message[".fastcity.admin.gmrpcIsBroadcast"] != null && message.hasOwnProperty(".fastcity.admin.gmrpcIsBroadcast"))
                    if (typeof message[".fastcity.admin.gmrpcIsBroadcast"] !== "boolean")
                        return ".fastcity.admin.gmrpcIsBroadcast: boolean expected";
                return null;
            };

            /**
             * Creates a FieldOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FieldOptions} FieldOptions
             */
            FieldOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FieldOptions)
                    return object;
                var message = new $root.google.protobuf.FieldOptions();
                switch (object.ctype) {
                case "STRING":
                case 0:
                    message.ctype = 0;
                    break;
                case "CORD":
                case 1:
                    message.ctype = 1;
                    break;
                case "STRING_PIECE":
                case 2:
                    message.ctype = 2;
                    break;
                }
                if (object.packed != null)
                    message.packed = Boolean(object.packed);
                switch (object.jstype) {
                case "JS_NORMAL":
                case 0:
                    message.jstype = 0;
                    break;
                case "JS_STRING":
                case 1:
                    message.jstype = 1;
                    break;
                case "JS_NUMBER":
                case 2:
                    message.jstype = 2;
                    break;
                }
                if (object.lazy != null)
                    message.lazy = Boolean(object.lazy);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.weak != null)
                    message.weak = Boolean(object.weak);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".fastcity.admin.gmrpcResponse"] != null)
                    message[".fastcity.admin.gmrpcResponse"] = String(object[".fastcity.admin.gmrpcResponse"]);
                if (object[".fastcity.admin.gmrpcIsBroadcast"] != null)
                    message[".fastcity.admin.gmrpcIsBroadcast"] = Boolean(object[".fastcity.admin.gmrpcIsBroadcast"]);
                return message;
            };

            /**
             * Creates a plain object from a FieldOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.FieldOptions} message FieldOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FieldOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.ctype = options.enums === String ? "STRING" : 0;
                    object.packed = false;
                    object.deprecated = false;
                    object.lazy = false;
                    object.jstype = options.enums === String ? "JS_NORMAL" : 0;
                    object.weak = false;
                    object[".fastcity.admin.gmrpcResponse"] = "";
                    object[".fastcity.admin.gmrpcIsBroadcast"] = false;
                }
                if (message.ctype != null && message.hasOwnProperty("ctype"))
                    object.ctype = options.enums === String ? $root.google.protobuf.FieldOptions.CType[message.ctype] : message.ctype;
                if (message.packed != null && message.hasOwnProperty("packed"))
                    object.packed = message.packed;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.lazy != null && message.hasOwnProperty("lazy"))
                    object.lazy = message.lazy;
                if (message.jstype != null && message.hasOwnProperty("jstype"))
                    object.jstype = options.enums === String ? $root.google.protobuf.FieldOptions.JSType[message.jstype] : message.jstype;
                if (message.weak != null && message.hasOwnProperty("weak"))
                    object.weak = message.weak;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".fastcity.admin.gmrpcResponse"] != null && message.hasOwnProperty(".fastcity.admin.gmrpcResponse"))
                    object[".fastcity.admin.gmrpcResponse"] = message[".fastcity.admin.gmrpcResponse"];
                if (message[".fastcity.admin.gmrpcIsBroadcast"] != null && message.hasOwnProperty(".fastcity.admin.gmrpcIsBroadcast"))
                    object[".fastcity.admin.gmrpcIsBroadcast"] = message[".fastcity.admin.gmrpcIsBroadcast"];
                return object;
            };

            /**
             * Converts this FieldOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.FieldOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FieldOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * CType enum.
             * @name google.protobuf.FieldOptions.CType
             * @enum {number}
             * @property {number} STRING=0 STRING value
             * @property {number} CORD=1 CORD value
             * @property {number} STRING_PIECE=2 STRING_PIECE value
             */
            FieldOptions.CType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STRING"] = 0;
                values[valuesById[1] = "CORD"] = 1;
                values[valuesById[2] = "STRING_PIECE"] = 2;
                return values;
            })();

            /**
             * JSType enum.
             * @name google.protobuf.FieldOptions.JSType
             * @enum {number}
             * @property {number} JS_NORMAL=0 JS_NORMAL value
             * @property {number} JS_STRING=1 JS_STRING value
             * @property {number} JS_NUMBER=2 JS_NUMBER value
             */
            FieldOptions.JSType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "JS_NORMAL"] = 0;
                values[valuesById[1] = "JS_STRING"] = 1;
                values[valuesById[2] = "JS_NUMBER"] = 2;
                return values;
            })();

            return FieldOptions;
        })();

        protobuf.OneofOptions = (function() {

            /**
             * Properties of an OneofOptions.
             * @memberof google.protobuf
             * @interface IOneofOptions
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] OneofOptions uninterpretedOption
             */

            /**
             * Constructs a new OneofOptions.
             * @memberof google.protobuf
             * @classdesc Represents an OneofOptions.
             * @implements IOneofOptions
             * @constructor
             * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
             */
            function OneofOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OneofOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.OneofOptions
             * @instance
             */
            OneofOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new OneofOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
             * @returns {google.protobuf.OneofOptions} OneofOptions instance
             */
            OneofOptions.create = function create(properties) {
                return new OneofOptions(properties);
            };

            /**
             * Encodes the specified OneofOptions message. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions} message OneofOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an OneofOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.OneofOptions} OneofOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.OneofOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OneofOptions message.
             * @function verify
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OneofOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an OneofOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.OneofOptions} OneofOptions
             */
            OneofOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.OneofOptions)
                    return object;
                var message = new $root.google.protobuf.OneofOptions();
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an OneofOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.OneofOptions} message OneofOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OneofOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this OneofOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.OneofOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OneofOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OneofOptions;
        })();

        protobuf.EnumOptions = (function() {

            /**
             * Properties of an EnumOptions.
             * @memberof google.protobuf
             * @interface IEnumOptions
             * @property {boolean|null} [allowAlias] EnumOptions allowAlias
             * @property {boolean|null} [deprecated] EnumOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumOptions uninterpretedOption
             */

            /**
             * Constructs a new EnumOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumOptions.
             * @implements IEnumOptions
             * @constructor
             * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
             */
            function EnumOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumOptions allowAlias.
             * @member {boolean} allowAlias
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.allowAlias = false;

            /**
             * EnumOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.deprecated = false;

            /**
             * EnumOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new EnumOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
             * @returns {google.protobuf.EnumOptions} EnumOptions instance
             */
            EnumOptions.create = function create(properties) {
                return new EnumOptions(properties);
            };

            /**
             * Encodes the specified EnumOptions message. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions} message EnumOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.allowAlias != null && Object.hasOwnProperty.call(message, "allowAlias"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.allowAlias);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an EnumOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumOptions} EnumOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2:
                        message.allowAlias = reader.bool();
                        break;
                    case 3:
                        message.deprecated = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an EnumOptions message.
             * @function verify
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                    if (typeof message.allowAlias !== "boolean")
                        return "allowAlias: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an EnumOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumOptions} EnumOptions
             */
            EnumOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumOptions)
                    return object;
                var message = new $root.google.protobuf.EnumOptions();
                if (object.allowAlias != null)
                    message.allowAlias = Boolean(object.allowAlias);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.EnumOptions} message EnumOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.allowAlias = false;
                    object.deprecated = false;
                }
                if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                    object.allowAlias = message.allowAlias;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this EnumOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumOptions;
        })();

        protobuf.EnumValueOptions = (function() {

            /**
             * Properties of an EnumValueOptions.
             * @memberof google.protobuf
             * @interface IEnumValueOptions
             * @property {boolean|null} [deprecated] EnumValueOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumValueOptions uninterpretedOption
             */

            /**
             * Constructs a new EnumValueOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueOptions.
             * @implements IEnumValueOptions
             * @constructor
             * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
             */
            function EnumValueOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumValueOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.deprecated = false;

            /**
             * EnumValueOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new EnumValueOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions instance
             */
            EnumValueOptions.create = function create(properties) {
                return new EnumValueOptions(properties);
            };

            /**
             * Encodes the specified EnumValueOptions message. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions} message EnumValueOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an EnumValueOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumValueOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.deprecated = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an EnumValueOptions message.
             * @function verify
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumValueOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an EnumValueOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             */
            EnumValueOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumValueOptions)
                    return object;
                var message = new $root.google.protobuf.EnumValueOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumValueOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.EnumValueOptions} message EnumValueOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumValueOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults)
                    object.deprecated = false;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this EnumValueOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumValueOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumValueOptions;
        })();

        protobuf.ServiceOptions = (function() {

            /**
             * Properties of a ServiceOptions.
             * @memberof google.protobuf
             * @interface IServiceOptions
             * @property {boolean|null} [deprecated] ServiceOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] ServiceOptions uninterpretedOption
             */

            /**
             * Constructs a new ServiceOptions.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceOptions.
             * @implements IServiceOptions
             * @constructor
             * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
             */
            function ServiceOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.deprecated = false;

            /**
             * ServiceOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new ServiceOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
             * @returns {google.protobuf.ServiceOptions} ServiceOptions instance
             */
            ServiceOptions.create = function create(properties) {
                return new ServiceOptions(properties);
            };

            /**
             * Encodes the specified ServiceOptions message. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions} message ServiceOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 33, wireType 0 =*/264).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ServiceOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ServiceOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 33:
                        message.deprecated = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ServiceOptions message.
             * @function verify
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ServiceOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             */
            ServiceOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.ServiceOptions)
                    return object;
                var message = new $root.google.protobuf.ServiceOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a ServiceOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.ServiceOptions} message ServiceOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults)
                    object.deprecated = false;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this ServiceOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.ServiceOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServiceOptions;
        })();

        protobuf.MethodOptions = (function() {

            /**
             * Properties of a MethodOptions.
             * @memberof google.protobuf
             * @interface IMethodOptions
             * @property {boolean|null} [deprecated] MethodOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MethodOptions uninterpretedOption
             */

            /**
             * Constructs a new MethodOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MethodOptions.
             * @implements IMethodOptions
             * @constructor
             * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
             */
            function MethodOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MethodOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.deprecated = false;

            /**
             * MethodOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new MethodOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
             * @returns {google.protobuf.MethodOptions} MethodOptions instance
             */
            MethodOptions.create = function create(properties) {
                return new MethodOptions(properties);
            };

            /**
             * Encodes the specified MethodOptions message. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions} message MethodOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 33, wireType 0 =*/264).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a MethodOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.MethodOptions} MethodOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MethodOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 33:
                        message.deprecated = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a MethodOptions message.
             * @function verify
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MethodOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a MethodOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MethodOptions} MethodOptions
             */
            MethodOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MethodOptions)
                    return object;
                var message = new $root.google.protobuf.MethodOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a MethodOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.MethodOptions} message MethodOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MethodOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults)
                    object.deprecated = false;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this MethodOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.MethodOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MethodOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MethodOptions;
        })();

        protobuf.UninterpretedOption = (function() {

            /**
             * Properties of an UninterpretedOption.
             * @memberof google.protobuf
             * @interface IUninterpretedOption
             * @property {Array.<google.protobuf.UninterpretedOption.INamePart>|null} [name] UninterpretedOption name
             * @property {string|null} [identifierValue] UninterpretedOption identifierValue
             * @property {number|Long|null} [positiveIntValue] UninterpretedOption positiveIntValue
             * @property {number|Long|null} [negativeIntValue] UninterpretedOption negativeIntValue
             * @property {number|null} [doubleValue] UninterpretedOption doubleValue
             * @property {Uint8Array|null} [stringValue] UninterpretedOption stringValue
             * @property {string|null} [aggregateValue] UninterpretedOption aggregateValue
             */

            /**
             * Constructs a new UninterpretedOption.
             * @memberof google.protobuf
             * @classdesc Represents an UninterpretedOption.
             * @implements IUninterpretedOption
             * @constructor
             * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
             */
            function UninterpretedOption(properties) {
                this.name = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UninterpretedOption name.
             * @member {Array.<google.protobuf.UninterpretedOption.INamePart>} name
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.name = $util.emptyArray;

            /**
             * UninterpretedOption identifierValue.
             * @member {string} identifierValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.identifierValue = "";

            /**
             * UninterpretedOption positiveIntValue.
             * @member {number|Long} positiveIntValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.positiveIntValue = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UninterpretedOption negativeIntValue.
             * @member {number|Long} negativeIntValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.negativeIntValue = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UninterpretedOption doubleValue.
             * @member {number} doubleValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.doubleValue = 0;

            /**
             * UninterpretedOption stringValue.
             * @member {Uint8Array} stringValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.stringValue = $util.newBuffer([]);

            /**
             * UninterpretedOption aggregateValue.
             * @member {string} aggregateValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.aggregateValue = "";

            /**
             * Creates a new UninterpretedOption instance using the specified properties.
             * @function create
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption instance
             */
            UninterpretedOption.create = function create(properties) {
                return new UninterpretedOption(properties);
            };

            /**
             * Encodes the specified UninterpretedOption message. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption} message UninterpretedOption message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UninterpretedOption.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && message.name.length)
                    for (var i = 0; i < message.name.length; ++i)
                        $root.google.protobuf.UninterpretedOption.NamePart.encode(message.name[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.identifierValue != null && Object.hasOwnProperty.call(message, "identifierValue"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.identifierValue);
                if (message.positiveIntValue != null && Object.hasOwnProperty.call(message, "positiveIntValue"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.positiveIntValue);
                if (message.negativeIntValue != null && Object.hasOwnProperty.call(message, "negativeIntValue"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.negativeIntValue);
                if (message.doubleValue != null && Object.hasOwnProperty.call(message, "doubleValue"))
                    writer.uint32(/* id 6, wireType 1 =*/49).double(message.doubleValue);
                if (message.stringValue != null && Object.hasOwnProperty.call(message, "stringValue"))
                    writer.uint32(/* id 7, wireType 2 =*/58).bytes(message.stringValue);
                if (message.aggregateValue != null && Object.hasOwnProperty.call(message, "aggregateValue"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.aggregateValue);
                return writer;
            };

            /**
             * Decodes an UninterpretedOption message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UninterpretedOption.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UninterpretedOption();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2:
                        if (!(message.name && message.name.length))
                            message.name = [];
                        message.name.push($root.google.protobuf.UninterpretedOption.NamePart.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        message.identifierValue = reader.string();
                        break;
                    case 4:
                        message.positiveIntValue = reader.uint64();
                        break;
                    case 5:
                        message.negativeIntValue = reader.int64();
                        break;
                    case 6:
                        message.doubleValue = reader.double();
                        break;
                    case 7:
                        message.stringValue = reader.bytes();
                        break;
                    case 8:
                        message.aggregateValue = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UninterpretedOption message.
             * @function verify
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UninterpretedOption.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name")) {
                    if (!Array.isArray(message.name))
                        return "name: array expected";
                    for (var i = 0; i < message.name.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.NamePart.verify(message.name[i]);
                        if (error)
                            return "name." + error;
                    }
                }
                if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                    if (!$util.isString(message.identifierValue))
                        return "identifierValue: string expected";
                if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                    if (!$util.isInteger(message.positiveIntValue) && !(message.positiveIntValue && $util.isInteger(message.positiveIntValue.low) && $util.isInteger(message.positiveIntValue.high)))
                        return "positiveIntValue: integer|Long expected";
                if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                    if (!$util.isInteger(message.negativeIntValue) && !(message.negativeIntValue && $util.isInteger(message.negativeIntValue.low) && $util.isInteger(message.negativeIntValue.high)))
                        return "negativeIntValue: integer|Long expected";
                if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                    if (typeof message.doubleValue !== "number")
                        return "doubleValue: number expected";
                if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                    if (!(message.stringValue && typeof message.stringValue.length === "number" || $util.isString(message.stringValue)))
                        return "stringValue: buffer expected";
                if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                    if (!$util.isString(message.aggregateValue))
                        return "aggregateValue: string expected";
                return null;
            };

            /**
             * Creates an UninterpretedOption message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             */
            UninterpretedOption.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.UninterpretedOption)
                    return object;
                var message = new $root.google.protobuf.UninterpretedOption();
                if (object.name) {
                    if (!Array.isArray(object.name))
                        throw TypeError(".google.protobuf.UninterpretedOption.name: array expected");
                    message.name = [];
                    for (var i = 0; i < object.name.length; ++i) {
                        if (typeof object.name[i] !== "object")
                            throw TypeError(".google.protobuf.UninterpretedOption.name: object expected");
                        message.name[i] = $root.google.protobuf.UninterpretedOption.NamePart.fromObject(object.name[i]);
                    }
                }
                if (object.identifierValue != null)
                    message.identifierValue = String(object.identifierValue);
                if (object.positiveIntValue != null)
                    if ($util.Long)
                        (message.positiveIntValue = $util.Long.fromValue(object.positiveIntValue)).unsigned = true;
                    else if (typeof object.positiveIntValue === "string")
                        message.positiveIntValue = parseInt(object.positiveIntValue, 10);
                    else if (typeof object.positiveIntValue === "number")
                        message.positiveIntValue = object.positiveIntValue;
                    else if (typeof object.positiveIntValue === "object")
                        message.positiveIntValue = new $util.LongBits(object.positiveIntValue.low >>> 0, object.positiveIntValue.high >>> 0).toNumber(true);
                if (object.negativeIntValue != null)
                    if ($util.Long)
                        (message.negativeIntValue = $util.Long.fromValue(object.negativeIntValue)).unsigned = false;
                    else if (typeof object.negativeIntValue === "string")
                        message.negativeIntValue = parseInt(object.negativeIntValue, 10);
                    else if (typeof object.negativeIntValue === "number")
                        message.negativeIntValue = object.negativeIntValue;
                    else if (typeof object.negativeIntValue === "object")
                        message.negativeIntValue = new $util.LongBits(object.negativeIntValue.low >>> 0, object.negativeIntValue.high >>> 0).toNumber();
                if (object.doubleValue != null)
                    message.doubleValue = Number(object.doubleValue);
                if (object.stringValue != null)
                    if (typeof object.stringValue === "string")
                        $util.base64.decode(object.stringValue, message.stringValue = $util.newBuffer($util.base64.length(object.stringValue)), 0);
                    else if (object.stringValue.length)
                        message.stringValue = object.stringValue;
                if (object.aggregateValue != null)
                    message.aggregateValue = String(object.aggregateValue);
                return message;
            };

            /**
             * Creates a plain object from an UninterpretedOption message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.UninterpretedOption} message UninterpretedOption
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UninterpretedOption.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.name = [];
                if (options.defaults) {
                    object.identifierValue = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.positiveIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.positiveIntValue = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.negativeIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.negativeIntValue = options.longs === String ? "0" : 0;
                    object.doubleValue = 0;
                    if (options.bytes === String)
                        object.stringValue = "";
                    else {
                        object.stringValue = [];
                        if (options.bytes !== Array)
                            object.stringValue = $util.newBuffer(object.stringValue);
                    }
                    object.aggregateValue = "";
                }
                if (message.name && message.name.length) {
                    object.name = [];
                    for (var j = 0; j < message.name.length; ++j)
                        object.name[j] = $root.google.protobuf.UninterpretedOption.NamePart.toObject(message.name[j], options);
                }
                if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                    object.identifierValue = message.identifierValue;
                if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                    if (typeof message.positiveIntValue === "number")
                        object.positiveIntValue = options.longs === String ? String(message.positiveIntValue) : message.positiveIntValue;
                    else
                        object.positiveIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.positiveIntValue) : options.longs === Number ? new $util.LongBits(message.positiveIntValue.low >>> 0, message.positiveIntValue.high >>> 0).toNumber(true) : message.positiveIntValue;
                if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                    if (typeof message.negativeIntValue === "number")
                        object.negativeIntValue = options.longs === String ? String(message.negativeIntValue) : message.negativeIntValue;
                    else
                        object.negativeIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.negativeIntValue) : options.longs === Number ? new $util.LongBits(message.negativeIntValue.low >>> 0, message.negativeIntValue.high >>> 0).toNumber() : message.negativeIntValue;
                if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                    object.doubleValue = options.json && !isFinite(message.doubleValue) ? String(message.doubleValue) : message.doubleValue;
                if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                    object.stringValue = options.bytes === String ? $util.base64.encode(message.stringValue, 0, message.stringValue.length) : options.bytes === Array ? Array.prototype.slice.call(message.stringValue) : message.stringValue;
                if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                    object.aggregateValue = message.aggregateValue;
                return object;
            };

            /**
             * Converts this UninterpretedOption to JSON.
             * @function toJSON
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UninterpretedOption.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            UninterpretedOption.NamePart = (function() {

                /**
                 * Properties of a NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @interface INamePart
                 * @property {string} namePart NamePart namePart
                 * @property {boolean} isExtension NamePart isExtension
                 */

                /**
                 * Constructs a new NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @classdesc Represents a NamePart.
                 * @implements INamePart
                 * @constructor
                 * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                 */
                function NamePart(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * NamePart namePart.
                 * @member {string} namePart
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.namePart = "";

                /**
                 * NamePart isExtension.
                 * @member {boolean} isExtension
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.isExtension = false;

                /**
                 * Creates a new NamePart instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart instance
                 */
                NamePart.create = function create(properties) {
                    return new NamePart(properties);
                };

                /**
                 * Encodes the specified NamePart message. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart} message NamePart message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NamePart.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.namePart);
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isExtension);
                    return writer;
                };

                /**
                 * Decodes a NamePart message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NamePart.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UninterpretedOption.NamePart();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.namePart = reader.string();
                            break;
                        case 2:
                            message.isExtension = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    if (!message.hasOwnProperty("namePart"))
                        throw $util.ProtocolError("missing required 'namePart'", { instance: message });
                    if (!message.hasOwnProperty("isExtension"))
                        throw $util.ProtocolError("missing required 'isExtension'", { instance: message });
                    return message;
                };

                /**
                 * Verifies a NamePart message.
                 * @function verify
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NamePart.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (!$util.isString(message.namePart))
                        return "namePart: string expected";
                    if (typeof message.isExtension !== "boolean")
                        return "isExtension: boolean expected";
                    return null;
                };

                /**
                 * Creates a NamePart message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 */
                NamePart.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.UninterpretedOption.NamePart)
                        return object;
                    var message = new $root.google.protobuf.UninterpretedOption.NamePart();
                    if (object.namePart != null)
                        message.namePart = String(object.namePart);
                    if (object.isExtension != null)
                        message.isExtension = Boolean(object.isExtension);
                    return message;
                };

                /**
                 * Creates a plain object from a NamePart message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.NamePart} message NamePart
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NamePart.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.namePart = "";
                        object.isExtension = false;
                    }
                    if (message.namePart != null && message.hasOwnProperty("namePart"))
                        object.namePart = message.namePart;
                    if (message.isExtension != null && message.hasOwnProperty("isExtension"))
                        object.isExtension = message.isExtension;
                    return object;
                };

                /**
                 * Converts this NamePart to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NamePart.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return NamePart;
            })();

            return UninterpretedOption;
        })();

        protobuf.SourceCodeInfo = (function() {

            /**
             * Properties of a SourceCodeInfo.
             * @memberof google.protobuf
             * @interface ISourceCodeInfo
             * @property {Array.<google.protobuf.SourceCodeInfo.ILocation>|null} [location] SourceCodeInfo location
             */

            /**
             * Constructs a new SourceCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a SourceCodeInfo.
             * @implements ISourceCodeInfo
             * @constructor
             * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
             */
            function SourceCodeInfo(properties) {
                this.location = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SourceCodeInfo location.
             * @member {Array.<google.protobuf.SourceCodeInfo.ILocation>} location
             * @memberof google.protobuf.SourceCodeInfo
             * @instance
             */
            SourceCodeInfo.prototype.location = $util.emptyArray;

            /**
             * Creates a new SourceCodeInfo instance using the specified properties.
             * @function create
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo instance
             */
            SourceCodeInfo.create = function create(properties) {
                return new SourceCodeInfo(properties);
            };

            /**
             * Encodes the specified SourceCodeInfo message. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo} message SourceCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SourceCodeInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.location != null && message.location.length)
                    for (var i = 0; i < message.location.length; ++i)
                        $root.google.protobuf.SourceCodeInfo.Location.encode(message.location[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a SourceCodeInfo message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SourceCodeInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.SourceCodeInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.location && message.location.length))
                            message.location = [];
                        message.location.push($root.google.protobuf.SourceCodeInfo.Location.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SourceCodeInfo message.
             * @function verify
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SourceCodeInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.location != null && message.hasOwnProperty("location")) {
                    if (!Array.isArray(message.location))
                        return "location: array expected";
                    for (var i = 0; i < message.location.length; ++i) {
                        var error = $root.google.protobuf.SourceCodeInfo.Location.verify(message.location[i]);
                        if (error)
                            return "location." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SourceCodeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             */
            SourceCodeInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.SourceCodeInfo)
                    return object;
                var message = new $root.google.protobuf.SourceCodeInfo();
                if (object.location) {
                    if (!Array.isArray(object.location))
                        throw TypeError(".google.protobuf.SourceCodeInfo.location: array expected");
                    message.location = [];
                    for (var i = 0; i < object.location.length; ++i) {
                        if (typeof object.location[i] !== "object")
                            throw TypeError(".google.protobuf.SourceCodeInfo.location: object expected");
                        message.location[i] = $root.google.protobuf.SourceCodeInfo.Location.fromObject(object.location[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SourceCodeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.SourceCodeInfo} message SourceCodeInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SourceCodeInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.location = [];
                if (message.location && message.location.length) {
                    object.location = [];
                    for (var j = 0; j < message.location.length; ++j)
                        object.location[j] = $root.google.protobuf.SourceCodeInfo.Location.toObject(message.location[j], options);
                }
                return object;
            };

            /**
             * Converts this SourceCodeInfo to JSON.
             * @function toJSON
             * @memberof google.protobuf.SourceCodeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SourceCodeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            SourceCodeInfo.Location = (function() {

                /**
                 * Properties of a Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @interface ILocation
                 * @property {Array.<number>|null} [path] Location path
                 * @property {Array.<number>|null} [span] Location span
                 * @property {string|null} [leadingComments] Location leadingComments
                 * @property {string|null} [trailingComments] Location trailingComments
                 * @property {Array.<string>|null} [leadingDetachedComments] Location leadingDetachedComments
                 */

                /**
                 * Constructs a new Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @classdesc Represents a Location.
                 * @implements ILocation
                 * @constructor
                 * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                 */
                function Location(properties) {
                    this.path = [];
                    this.span = [];
                    this.leadingDetachedComments = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Location path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.path = $util.emptyArray;

                /**
                 * Location span.
                 * @member {Array.<number>} span
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.span = $util.emptyArray;

                /**
                 * Location leadingComments.
                 * @member {string} leadingComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leadingComments = "";

                /**
                 * Location trailingComments.
                 * @member {string} trailingComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.trailingComments = "";

                /**
                 * Location leadingDetachedComments.
                 * @member {Array.<string>} leadingDetachedComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leadingDetachedComments = $util.emptyArray;

                /**
                 * Creates a new Location instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location instance
                 */
                Location.create = function create(properties) {
                    return new Location(properties);
                };

                /**
                 * Encodes the specified Location message. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation} message Location message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Location.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.path != null && message.path.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.path.length; ++i)
                            writer.int32(message.path[i]);
                        writer.ldelim();
                    }
                    if (message.span != null && message.span.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (var i = 0; i < message.span.length; ++i)
                            writer.int32(message.span[i]);
                        writer.ldelim();
                    }
                    if (message.leadingComments != null && Object.hasOwnProperty.call(message, "leadingComments"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.leadingComments);
                    if (message.trailingComments != null && Object.hasOwnProperty.call(message, "trailingComments"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.trailingComments);
                    if (message.leadingDetachedComments != null && message.leadingDetachedComments.length)
                        for (var i = 0; i < message.leadingDetachedComments.length; ++i)
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.leadingDetachedComments[i]);
                    return writer;
                };

                /**
                 * Decodes a Location message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Location.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.SourceCodeInfo.Location();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.path && message.path.length))
                                message.path = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.path.push(reader.int32());
                            } else
                                message.path.push(reader.int32());
                            break;
                        case 2:
                            if (!(message.span && message.span.length))
                                message.span = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.span.push(reader.int32());
                            } else
                                message.span.push(reader.int32());
                            break;
                        case 3:
                            message.leadingComments = reader.string();
                            break;
                        case 4:
                            message.trailingComments = reader.string();
                            break;
                        case 6:
                            if (!(message.leadingDetachedComments && message.leadingDetachedComments.length))
                                message.leadingDetachedComments = [];
                            message.leadingDetachedComments.push(reader.string());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a Location message.
                 * @function verify
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Location.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.path != null && message.hasOwnProperty("path")) {
                        if (!Array.isArray(message.path))
                            return "path: array expected";
                        for (var i = 0; i < message.path.length; ++i)
                            if (!$util.isInteger(message.path[i]))
                                return "path: integer[] expected";
                    }
                    if (message.span != null && message.hasOwnProperty("span")) {
                        if (!Array.isArray(message.span))
                            return "span: array expected";
                        for (var i = 0; i < message.span.length; ++i)
                            if (!$util.isInteger(message.span[i]))
                                return "span: integer[] expected";
                    }
                    if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                        if (!$util.isString(message.leadingComments))
                            return "leadingComments: string expected";
                    if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                        if (!$util.isString(message.trailingComments))
                            return "trailingComments: string expected";
                    if (message.leadingDetachedComments != null && message.hasOwnProperty("leadingDetachedComments")) {
                        if (!Array.isArray(message.leadingDetachedComments))
                            return "leadingDetachedComments: array expected";
                        for (var i = 0; i < message.leadingDetachedComments.length; ++i)
                            if (!$util.isString(message.leadingDetachedComments[i]))
                                return "leadingDetachedComments: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a Location message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 */
                Location.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.SourceCodeInfo.Location)
                        return object;
                    var message = new $root.google.protobuf.SourceCodeInfo.Location();
                    if (object.path) {
                        if (!Array.isArray(object.path))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.path: array expected");
                        message.path = [];
                        for (var i = 0; i < object.path.length; ++i)
                            message.path[i] = object.path[i] | 0;
                    }
                    if (object.span) {
                        if (!Array.isArray(object.span))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.span: array expected");
                        message.span = [];
                        for (var i = 0; i < object.span.length; ++i)
                            message.span[i] = object.span[i] | 0;
                    }
                    if (object.leadingComments != null)
                        message.leadingComments = String(object.leadingComments);
                    if (object.trailingComments != null)
                        message.trailingComments = String(object.trailingComments);
                    if (object.leadingDetachedComments) {
                        if (!Array.isArray(object.leadingDetachedComments))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.leadingDetachedComments: array expected");
                        message.leadingDetachedComments = [];
                        for (var i = 0; i < object.leadingDetachedComments.length; ++i)
                            message.leadingDetachedComments[i] = String(object.leadingDetachedComments[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Location message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.Location} message Location
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Location.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.path = [];
                        object.span = [];
                        object.leadingDetachedComments = [];
                    }
                    if (options.defaults) {
                        object.leadingComments = "";
                        object.trailingComments = "";
                    }
                    if (message.path && message.path.length) {
                        object.path = [];
                        for (var j = 0; j < message.path.length; ++j)
                            object.path[j] = message.path[j];
                    }
                    if (message.span && message.span.length) {
                        object.span = [];
                        for (var j = 0; j < message.span.length; ++j)
                            object.span[j] = message.span[j];
                    }
                    if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                        object.leadingComments = message.leadingComments;
                    if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                        object.trailingComments = message.trailingComments;
                    if (message.leadingDetachedComments && message.leadingDetachedComments.length) {
                        object.leadingDetachedComments = [];
                        for (var j = 0; j < message.leadingDetachedComments.length; ++j)
                            object.leadingDetachedComments[j] = message.leadingDetachedComments[j];
                    }
                    return object;
                };

                /**
                 * Converts this Location to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Location.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Location;
            })();

            return SourceCodeInfo;
        })();

        protobuf.GeneratedCodeInfo = (function() {

            /**
             * Properties of a GeneratedCodeInfo.
             * @memberof google.protobuf
             * @interface IGeneratedCodeInfo
             * @property {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>|null} [annotation] GeneratedCodeInfo annotation
             */

            /**
             * Constructs a new GeneratedCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a GeneratedCodeInfo.
             * @implements IGeneratedCodeInfo
             * @constructor
             * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
             */
            function GeneratedCodeInfo(properties) {
                this.annotation = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GeneratedCodeInfo annotation.
             * @member {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>} annotation
             * @memberof google.protobuf.GeneratedCodeInfo
             * @instance
             */
            GeneratedCodeInfo.prototype.annotation = $util.emptyArray;

            /**
             * Creates a new GeneratedCodeInfo instance using the specified properties.
             * @function create
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo instance
             */
            GeneratedCodeInfo.create = function create(properties) {
                return new GeneratedCodeInfo(properties);
            };

            /**
             * Encodes the specified GeneratedCodeInfo message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo} message GeneratedCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GeneratedCodeInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.annotation != null && message.annotation.length)
                    for (var i = 0; i < message.annotation.length; ++i)
                        $root.google.protobuf.GeneratedCodeInfo.Annotation.encode(message.annotation[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a GeneratedCodeInfo message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GeneratedCodeInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.GeneratedCodeInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.annotation && message.annotation.length))
                            message.annotation = [];
                        message.annotation.push($root.google.protobuf.GeneratedCodeInfo.Annotation.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GeneratedCodeInfo message.
             * @function verify
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GeneratedCodeInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.annotation != null && message.hasOwnProperty("annotation")) {
                    if (!Array.isArray(message.annotation))
                        return "annotation: array expected";
                    for (var i = 0; i < message.annotation.length; ++i) {
                        var error = $root.google.protobuf.GeneratedCodeInfo.Annotation.verify(message.annotation[i]);
                        if (error)
                            return "annotation." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GeneratedCodeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             */
            GeneratedCodeInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.GeneratedCodeInfo)
                    return object;
                var message = new $root.google.protobuf.GeneratedCodeInfo();
                if (object.annotation) {
                    if (!Array.isArray(object.annotation))
                        throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: array expected");
                    message.annotation = [];
                    for (var i = 0; i < object.annotation.length; ++i) {
                        if (typeof object.annotation[i] !== "object")
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: object expected");
                        message.annotation[i] = $root.google.protobuf.GeneratedCodeInfo.Annotation.fromObject(object.annotation[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a GeneratedCodeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.GeneratedCodeInfo} message GeneratedCodeInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GeneratedCodeInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.annotation = [];
                if (message.annotation && message.annotation.length) {
                    object.annotation = [];
                    for (var j = 0; j < message.annotation.length; ++j)
                        object.annotation[j] = $root.google.protobuf.GeneratedCodeInfo.Annotation.toObject(message.annotation[j], options);
                }
                return object;
            };

            /**
             * Converts this GeneratedCodeInfo to JSON.
             * @function toJSON
             * @memberof google.protobuf.GeneratedCodeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GeneratedCodeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            GeneratedCodeInfo.Annotation = (function() {

                /**
                 * Properties of an Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @interface IAnnotation
                 * @property {Array.<number>|null} [path] Annotation path
                 * @property {string|null} [sourceFile] Annotation sourceFile
                 * @property {number|null} [begin] Annotation begin
                 * @property {number|null} [end] Annotation end
                 */

                /**
                 * Constructs a new Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @classdesc Represents an Annotation.
                 * @implements IAnnotation
                 * @constructor
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                 */
                function Annotation(properties) {
                    this.path = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Annotation path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.path = $util.emptyArray;

                /**
                 * Annotation sourceFile.
                 * @member {string} sourceFile
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.sourceFile = "";

                /**
                 * Annotation begin.
                 * @member {number} begin
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.begin = 0;

                /**
                 * Annotation end.
                 * @member {number} end
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.end = 0;

                /**
                 * Creates a new Annotation instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation instance
                 */
                Annotation.create = function create(properties) {
                    return new Annotation(properties);
                };

                /**
                 * Encodes the specified Annotation message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} message Annotation message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Annotation.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.path != null && message.path.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.path.length; ++i)
                            writer.int32(message.path[i]);
                        writer.ldelim();
                    }
                    if (message.sourceFile != null && Object.hasOwnProperty.call(message, "sourceFile"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.sourceFile);
                    if (message.begin != null && Object.hasOwnProperty.call(message, "begin"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.begin);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.end);
                    return writer;
                };

                /**
                 * Decodes an Annotation message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Annotation.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.path && message.path.length))
                                message.path = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.path.push(reader.int32());
                            } else
                                message.path.push(reader.int32());
                            break;
                        case 2:
                            message.sourceFile = reader.string();
                            break;
                        case 3:
                            message.begin = reader.int32();
                            break;
                        case 4:
                            message.end = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies an Annotation message.
                 * @function verify
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Annotation.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.path != null && message.hasOwnProperty("path")) {
                        if (!Array.isArray(message.path))
                            return "path: array expected";
                        for (var i = 0; i < message.path.length; ++i)
                            if (!$util.isInteger(message.path[i]))
                                return "path: integer[] expected";
                    }
                    if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                        if (!$util.isString(message.sourceFile))
                            return "sourceFile: string expected";
                    if (message.begin != null && message.hasOwnProperty("begin"))
                        if (!$util.isInteger(message.begin))
                            return "begin: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates an Annotation message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 */
                Annotation.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.GeneratedCodeInfo.Annotation)
                        return object;
                    var message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                    if (object.path) {
                        if (!Array.isArray(object.path))
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.Annotation.path: array expected");
                        message.path = [];
                        for (var i = 0; i < object.path.length; ++i)
                            message.path[i] = object.path[i] | 0;
                    }
                    if (object.sourceFile != null)
                        message.sourceFile = String(object.sourceFile);
                    if (object.begin != null)
                        message.begin = object.begin | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an Annotation message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.Annotation} message Annotation
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Annotation.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.path = [];
                    if (options.defaults) {
                        object.sourceFile = "";
                        object.begin = 0;
                        object.end = 0;
                    }
                    if (message.path && message.path.length) {
                        object.path = [];
                        for (var j = 0; j < message.path.length; ++j)
                            object.path[j] = message.path[j];
                    }
                    if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                        object.sourceFile = message.sourceFile;
                    if (message.begin != null && message.hasOwnProperty("begin"))
                        object.begin = message.begin;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this Annotation to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Annotation.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Annotation;
            })();

            return GeneratedCodeInfo;
        })();

        return protobuf;
    })();

    return google;
})();

module.exports = $root;
