import { fastcity } from 'protocol/lib/bundle.pb';

export const REQUEST_FAILURE_REASON = {
    CREDENTIALS: 'login-credentials-error',
    ADMIN_IS_BLOCKED: 'login-admin-is-blocked',
    SERVER: 'request-server-error',
    INVALID_REQUEST: 'request-invalid-request',
    NO_ACCESS: 'request-no-access',
    CANNOT_SEND_EMAIL: 'request-cannot-send-email',
    UNKNOWN: 'request-problems-with-the-request'
};

export const GRANULARITY = {
    // HOUR: 'hour',
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month'
};

export const PERIOD = {
    // DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year'
};

export const CHART_TYPE = {
    DELIVERY_NUMBER: 'delivery number',
    DELIVERY_TIME: 'delivery time'
};

export const EXTERNAL_AUTH_TOKEN_SOURCE = fastcity.admin.ExternalAuthTokenSource;
