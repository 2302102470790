import { getContext, put, takeLatest, select } from 'redux-saga/effects';
import { get, pick, isArray, intersection, isEmpty } from 'lodash';

import { SERVICE_NAMES, FC_REQUESTS } from 'constants/index';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import { getLoggerFromContext } from '../utils/getLoggerFromContextSaga';

const prepareCourierData = (data) => {
    return {
        isDeleted: false,
        isSynchronized: false,
        isConnected: false,
        groupId: 0,
        ...data
    };
};

// WATCHER
export function* courierSaga() {
    yield takeLatest(actions.getCourierList, getCourierList);
    yield takeLatest(actions.getCurrentAdminSuccess, getCourierList); // update couriers data on got current admin data
    yield takeLatest(actions.setCouriersFilters, handleSetCourierFilterList); // update data on set new filters
    yield takeLatest(actions.createCourier, createCourier);
    yield takeLatest(actions.updateCourier, updateCourier);
}

// It is impossible to request courier list only by external_sub_company_id
// because external_sub_company_id is unique only for specific company and is not unique for all companies
export function* getCourierList({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);
    const logger = yield getLoggerFromContext('Courier saga');

    try {
        const companyId = get(payload, 'companyId');

        const courierIdRaw = get(payload, 'courierId', []);
        const courierId = (isArray(courierIdRaw) ? courierIdRaw : [courierIdRaw]).filter((e) => e !== null);

        const externalSubCompanyIdRaw = get(payload, 'externalSubCompanyId', []);
        const externalSubCompanyId = (isArray(externalSubCompanyIdRaw)
            ? externalSubCompanyIdRaw
            : [externalSubCompanyIdRaw]
        ).filter((e) => e !== null);

        if ((!companyId || isEmpty(externalSubCompanyId)) && isEmpty(courierId)) {
            logger.log('It is impossible to request courier list with params: %O', payload);
            yield put(actions.getCourierListFailure('It is impossible to request courier list with this params'));
            return;
        }

        const requestParams = companyId
            ? {
                  companyId,
                  externalSubCompanyId
              }
            : {
                  courierId
              };

        const res = yield fastCityService.request(FC_REQUESTS.GET_COURIER_LIST, requestParams);
        const couriers = get(res, 'body.courier', []);
        const preparedData = couriers.map((c) => prepareCourierData(c));

        yield put(actions.getCourierListSuccess(preparedData));
    } catch (err) {
        yield put(actions.getCourierListFailure(`${err}`));
    }
}

// message CreateCourier
// {
//     string external_courier_id = 1;
//     uint64 company_id = 2;
//     string external_sub_company_id = 3;
//     string name = 4;
//     string phone = 5;
//     uint64 group_id = 6;
//     string password = 7;
// }

// - external_courier_id - оригинальный идентификатор курьера. Уникален в связке(external_courier_id, company_id, external_sub_company_id);
// - company_id - идентификатор компании на сервере к которой привязан курьер. Он уникален;
// - external_sub_company_id - подидентификатор представительства(ресторана) компании к которой привязан курьер. Уникален в связке(company_id, external_sub_company_id);
// - group_id - идентификатор группы к которой привязан курьер. Если группы нет, то 0;

export function* createCourier({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const courier = pick(payload, [
            'externalCourierId',
            'companyId',
            'externalSubCompanyId',
            'name',
            'phone',
            'password',
            'generateExternalCourierId'
        ]);

        if (!courier.companyId) {
            throw new Error('Unable to create courier wtihout company');
        }

        if (!courier.groupId) {
            courier.groupId = 0;
        }

        const res = yield fastCityService.request(FC_REQUESTS.CREATE_COURIER, courier);
        const preparedData = prepareCourierData(res.body);

        yield put(actions.createCourierSuccess(preparedData));
    } catch (err) {
        yield put(actions.createCourierFailure(`${err}`));
    }
}

export function* updateCourier({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const courier = pick(payload, ['courierId', 'externalCourierId', 'groupId']);

        if (!courier.courierId) {
            throw new Error('Unable to update courier without courierId');
        }

        if (!courier.groupId) {
            courier.groupId = 0;
        }

        const res = yield fastCityService.request(FC_REQUESTS.UPDATE_COURIER, payload);
        const preparedData = prepareCourierData(res.body);

        yield put(actions.updateCourierSuccess(preparedData));
    } catch (err) {
        yield put(actions.updateCourierFailure(`${err}`));
    }
}

export function* handleChangedCourierIdList(msg) {
    const logger = yield getLoggerFromContext('Courier saga');
    try {
        const filters = yield select(selectors.couriersSelectors.couriersFilters);

        if (!filters.companyId || !filters.externalSubCompanyId) {
            return;
        }

        if (
            msg?.body?.companyId !== filters.companyId ||
            msg?.body?.externalSubCompanyId !== filters.externalSubCompanyId
        ) {
            return;
        }

        // courier of selected sub company
        const currentFilteredCouriers = yield select(
            selectors.couriersSelectors.filteredByCompanyAndExternalSubCompanyOnly
        );

        const changedCourierIdList = get(msg, 'body.courierId', []);

        const currentFilteredCouriersIds = currentFilteredCouriers.map((c) => c.courierId);
        const courierIdsToRequest = intersection(changedCourierIdList, currentFilteredCouriersIds);
        logger.log('Got changed courier id list, try to update couriers: %O', courierIdsToRequest);

        if (!isEmpty(courierIdsToRequest)) {
            yield put(actions.getCourierList({ courierId: courierIdsToRequest }));
        }

        // Update current order list page
        const { page, perPage } = yield select(selectors.ordersSelectors.pagination);
        yield put(actions.getOrderListPage({ page, perPage }));
    } catch (err) {
        logger.error('Handle change courier id list message error: %O', err);
    }
}

function* handleSetCourierFilterList({ payload }) {
    if (!payload.externalSubCompanyId) {
        return;
    }

    const filters = yield select(selectors.couriersFilters);

    const companyId = get(payload, 'companyId', filters.companyId);

    if (!companyId) {
        return;
    }

    yield put(actions.getCourierList({ companyId, externalSubCompanyId: payload.externalSubCompanyId }));
}
