import { getContext, fork, call, delay, takeEvery, takeLatest, take, cancel, all, put } from 'redux-saga/effects';
import { SERVICE_NAMES, FC_WS_MSG } from 'constants/index';
import * as actions from 'store/actions';
import { getLoggerFromContext } from '../utils/getLoggerFromContextSaga';
import { eventChannelFactory } from '../utils/eventChannelFactory';
import { WSEventsToActionsDispatch } from '../utils/WSEventsToActionsDispatchSaga';
import { logon } from './logonSaga';
import { adminSaga, handleAdminData, getCurrentAdmin } from './adminSaga';
import { companySaga } from './companySaga';
import { courierSaga, handleChangedCourierIdList } from './courierSaga';
import { locationSaga, handleChangedLastLocation } from './locationSaga';
import { subCompanySaga } from './subCompanySaga';
import { orderSaga, handleChangedOrderIdList } from './orderSaga';
import { resetPassword, activate } from './resetPasswordSaga';
import { deliveryStatSaga } from './deliveryStatSaga';
import { subCompanyZoneSaga } from './subCompanyZoneSaga';
import { availableOrderStatusesSaga } from './availableOrderStatusesSaga';
import { externalAuthTokenSaga } from './externalAuthTokenSaga';
import { bindedExternalAuthTokenSaga } from './bindedExternalAuthTokensSaga';
import { getServerVersion } from './serverVersionSaga';

const container = {
    postponed: null
};

export function* fastCitySaga() {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);
    const logger = yield getLoggerFromContext('fastCitySaga');

    if (!fastCityService) {
        logger.error("fastCityWebsocketService instance wasn't gotten out of context. Stop saga worker");
        return;
    }

    const createDefaultEventChannel = eventChannelFactory(fastCityService);

    yield fork(WSEventsToActionsDispatch, createDefaultEventChannel, [
        { event: fastCityService.WSEvent.Open, actionCreator: actions.WSConnectionOpen },
        { event: fastCityService.WSEvent.Close, actionCreator: actions.WSConnectionClose },
        { event: fastCityService.WSEvent.Error, actionCreator: actions.WSConnectionOnError }
    ]);

    // WS and LOGIN watchers
    yield takeLatest(actions.WSConnectionOpen, wsOnOpenHandler, fastCityService, logger);
    yield takeLatest(actions.login, postponeActionAndOpenWS, fastCityService, logger);
    yield takeLatest(actions.logout, closeWSConnection, fastCityService, logger);
    yield takeLatest(actions.loginFailure, closeWSConnection, fastCityService, logger);
    yield takeLatest(actions.loginSuccess, runFastCityWSMessageListeners, fastCityService, logger);
    yield takeLatest(actions.loginSuccess, getCurrentAdmin);
    yield takeLatest(actions.loginSuccess, getServerVersion);

    yield takeLatest(actions.resetPassword, postponeActionAndOpenWS, fastCityService, logger);
    yield takeLatest(actions.resetPasswordFailure, closeWSConnection, fastCityService, logger);
    yield takeLatest(actions.resetPasswordSuccess, closeWSConnection, fastCityService, logger);

    yield takeLatest(actions.activate, postponeActionAndOpenWS, fastCityService, logger);
    yield takeLatest(actions.activateSuccess, closeWSConnection, fastCityService, logger);
    yield takeLatest(actions.activateFailure, closeWSConnection, fastCityService, logger);

    // OTHER WATCHERS
    yield all([
        companySaga(),
        adminSaga(),
        subCompanySaga(),
        courierSaga(),
        locationSaga(),
        orderSaga(),
        deliveryStatSaga(),
        subCompanyZoneSaga(),
        availableOrderStatusesSaga(),
        externalAuthTokenSaga(),
        bindedExternalAuthTokenSaga()
    ]);
}

function* postponeActionAndOpenWS(fastCityService, logger, action) {
    container.postponed = action;

    if (!fastCityService.isOpening && !fastCityService.isOpened) {
        logger.log('WS connection is not opened, try to open');
        yield openWSConnection(fastCityService, logger);
    } else {
        logger.log('WS connection has opened yet, try to handle postponed action');
        yield wsOnOpenHandler(fastCityService, logger);
    }
}

function* wsOnOpenHandler(fastCityService, logger) {
    const postponedAction = container.postponed;

    logger.log('Pick postponed action data from context: %O', postponedAction?.type);

    if (postponedAction?.type === actions.login.type && postponedAction?.payload) {
        logger.log('WS connection established, try to logon');
        yield logon(postponedAction?.payload);
        return;
    }

    if (postponedAction?.type === actions.resetPassword.type && postponedAction?.payload) {
        logger.log('WS connection established, try to reset password');
        yield resetPassword(postponedAction?.payload);
        return;
    }

    if (postponedAction?.type === actions.activate.type && postponedAction?.payload) {
        logger.log('WS connection established, try to activate');
        yield activate(postponedAction?.payload);
        return;
    }

    logger.log('WS connection established, but no options to re-logon. Close');
    yield closeWSConnection(fastCityService, logger);
}

function* openWSConnection(fastCityService, logger) {
    if (fastCityService.isOpening || fastCityService.isOpened) {
        return;
    }

    try {
        yield call([fastCityService, fastCityService.connect]);
    } catch (err) {
        logger.error('Open fastCity WS connection error: %O', err);
        yield call([fastCityService, fastCityService.disconnect], 1000);
    }
}

function* closeWSConnection(fastCityService, logger) {
    container.postponed = null;

    try {
        yield call([fastCityService, fastCityService.disconnect], 1000);
    } catch (err) {
        logger.error('Close fastCity WS connection error: %O', err);
    }
}

function* runFastCityWSMessageListeners(fastCityService, logger) {
    try {
        const WSMessageListenersTask = yield fork(function* () {
            logger.log('WS connection: OPEN. Run message listeners');

            const createEventChannelWithClosing = eventChannelFactory(fastCityService, fastCityService.WSEvent.Close);

            const [
                adminDataChannel,
                changedLastLocationChannel,
                changedCourierIdListChannel,
                changedOrderIdListChannel
            ] = yield all([
                call(createEventChannelWithClosing, FC_WS_MSG.ADMIN),
                call(createEventChannelWithClosing, FC_WS_MSG.CHANGED_LAST_LOCATION),
                call(createEventChannelWithClosing, FC_WS_MSG.CHANGED_COURIER_ID_LIST),
                call(createEventChannelWithClosing, FC_WS_MSG.CHANGED_ORDER_ID_LIST)
            ]);

            yield takeEvery(adminDataChannel, handleAdminData);
            yield takeEvery(changedLastLocationChannel, handleChangedLastLocation);
            yield takeEvery(changedCourierIdListChannel, handleChangedCourierIdList);
            yield takeEvery(changedOrderIdListChannel, handleChangedOrderIdList);
        });

        yield fork(function* () {
            yield take(actions.WSConnectionClose);
            yield put(actions.logout());

            logger.log('WS connection: CLOSE. Stop ws message listeners');
            yield cancel(WSMessageListenersTask);
        });
    } catch (err) {
        logger.error('runFastCityWSMessageListeners saga error: %O', err);

        yield call([fastCityService, fastCityService.disconnect], 1000);
        yield delay(3000);
        yield call([fastCityService, fastCityService.connect]);
    }
}
