import { useRoutes } from 'react-router-dom';
import { useHandleActivationRoute } from 'hooks/useHandleActivationRoute';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AdminRoutes from './AdminRoutes';
import CompanyAdminRoutes from './CompanyAdminRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    // handle routes.activate route
    useHandleActivationRoute();

    return useRoutes([AdminRoutes, CompanyAdminRoutes, MainRoutes, LoginRoutes]);
}
