import { createReducer, createEntityAdapter } from '@reduxjs/toolkit';

import * as actions from 'store/actions';
import { REQUEST_STATUS } from 'constants/index';

export const externalAuthTokenAdapter = createEntityAdapter({
    selectId: (externalAuthToken) => externalAuthToken.externalAuthTokenId
});

const initialState = {
    externalAuthTokenList: externalAuthTokenAdapter.getInitialState(),
    externalAuthTokenListStatus: REQUEST_STATUS.INIT,
    updateStatus: REQUEST_STATUS.INIT,
    createStatus: REQUEST_STATUS.INIT,
    deleteStatus: REQUEST_STATUS.INIT
};

export const externalAuthTokenReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(actions.getExternalAuthTokenList, (state) => {
            state.externalAuthTokenListStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.getExternalAuthTokenListSuccess, (state, action) => {
            state.externalAuthTokenList = externalAuthTokenAdapter.setAll(state.externalAuthTokenList, action.payload);
            state.externalAuthTokenListStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.getExternalAuthTokenListFailure, (state) => {
            state.externalAuthTokenListStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.createExternalAuthToken, (state) => {
            state.createStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.createExternalAuthTokenSuccess, (state, action) => {
            state.externalAuthTokenList = externalAuthTokenAdapter.addOne(state.externalAuthTokenList, action.payload);
            state.createStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.createExternalAuthTokenFailure, (state) => {
            state.createStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.createExternalAuthTokenReset, (state) => {
            state.createStatus = REQUEST_STATUS.INIT;
        })
        .addCase(actions.updateExternalAuthToken, (state) => {
            state.updateStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.updateExternalAuthTokenSuccess, (state, action) => {
            state.externalAuthTokenList = externalAuthTokenAdapter.upsertOne(
                state.externalAuthTokenList,
                action.payload
            );
            state.updateStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.updateExternalAuthTokenFailure, (state) => {
            state.updateStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.updateExternalAuthTokenReset, (state) => {
            state.updateStatus = REQUEST_STATUS.INIT;
        })
        .addCase(actions.deleteExternalAuthToken, (state) => {
            state.deleteStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.deleteExternalAuthTokenSuccess, (state, action) => {
            state.list = externalAuthTokenAdapter.removeOne(state.externalAuthTokenList, action.payload);
            state.deleteStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.deleteExternalAuthTokenFailure, (state) => {
            state.deleteStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.deleteExternalAuthTokenReset, (state) => {
            state.deleteStatus = REQUEST_STATUS.INIT;
        })
);
