import { APP_VERSION } from 'environment/app-version';

const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/map',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 6,
    outlinedFilled: true,
    theme: 'light', // light, dark
    presetColor: 'theme2', // default, theme1, theme2, theme3, theme4, theme5, theme6
    i18n: 'en', // 'en' - English, 'ru' - Russian
    rtlLayout: false,
    apiEndpoint: process.env.REACT_APP_WS_ENDPOINT,
    httpApiEndpoint: process.env.REACT_APP_HTTP_ENDPOINT,
    defaultMapType: Number(process.env.REACT_APP_DEFAULT_MAP_TYPE ?? 0), // '0' - Google map, '1' - Yandex map

    //сейчас руками проставляется версия, удалить как девопс настроит CI который будет менять версия
    appVersion: 1.5 || APP_VERSION
};

export default config;
